import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaFileInvoice, FaPrint} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../assets/signature/signature logo.png"
import Logo_Batik from "../../assets/signature/logo-batik.png"
import "bulma/css/bulma.css";
import { useMutation } from "react-query";
import "../../index.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
// Modal Role
import { ScaleLoader } from "react-spinners";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import toRupiah from '@develoka/angka-rupiah-js';
import LoaderHome from "../Loader/LoaderHome"
import axios from "axios";

export default function Login() {
  const navigate = useNavigate()
  const [dataManage, setDatamanage] = useState(false)
  const [pages, setPages] = useState();
  const token = localStorage.getItem("token");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(100);
  const [ascending, setAscending] = useState(0);
  const [getDataPhysicalCheck, setGetDataPhysicalCheckdataPhysicalCheck] = useState([]);
  const [getDataPhysicalCheckMedAttention, setGetDataPhysicalCheckdataMedAttention] = useState([]);
  const [getDataBiodata, setGetDataBiodata] = useState([])
  const [getDataNurse, setGetDataNurse] = useState([]);
  const [getDataNurseSecond, setGetDataNurseSecond] = useState([]);
  const [getDataFloor, setGetDataFloor] = useState([]);
  const [getDataRoom, setGetDataRoom] = useState([]);
  const [rows, setRows] = useState(1);
  const [loading, setLoading] = useState(false);
  // Untuk Close Proops Data
  const [propsData, setProopsData] = useState()
  const [getCode, setGetCode] = useState([]);
  const {id} = useParams()
  const myRef = useRef(null);

   // List data kirim ke modal proops update
  const [modalRoleUpdate, setModalRoleUpdate] = useState(false);
  const [nameTreatment, setNameTreatment] = useState()
  const [idModal, setIdModal] = useState();
  const [id_doctorModal, setIdDoctor] = useState();
  const [id_roomModal, setIdRoom] = useState();
  const [tooth_numberModal, setToothNumber] = useState();
  const [qtyModal, setQty] = useState();
  const [estimate_priceModal, setEstimatePrice] = useState();
  const [descriptionModal, setDescription] = useState();
  const [getAllResponse, setGetAllResponse] = useState(false)
  const [getDateInvoice, setGetDataInvoiceDate] = useState([])

  const [form, setForm] = useState({
    username: "",
    date_check: "",
    height: "",
    weight: "",
    blood_type: "",
    body_temperature: "",
    blood_pressure: "",
    med_allergy: "",
    med_consuming: "",
    surgery_history: "",
    cardiac_disease: "",
    hemophillia: "",
    diabetes: "",
    hepatitis: "",
    gastritis: "",
    special_condition: "",
    date_birth: ""
  });

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };
  
  const fetchDataRef = useRef();

  const fetchData = async () => {
    try {
      setLoading(true);

      const [physicalCheckResponse, biodataRecord] = await axios.all([
        API.get(`emr-service/phy-checks/patient/${id}`, fetchParams),
        API.get(`transaction-service/patients/${id}`, fetchParams)
      ]);

      if (physicalCheckResponse.status === 200 && biodataRecord.status === 200) {
        setGetDataPhysicalCheckdataPhysicalCheck(physicalCheckResponse.data.dataPhysicalCheck);
        setGetDataPhysicalCheckdataMedAttention(physicalCheckResponse.data.dataMedAttention);
        setGetDataBiodata(biodataRecord.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  };

  useEffect(() => {
    fetchDataRef.current = fetchData;
    // Panggil fetchData saat komponen pertama kali dimuat
    fetchDataRef.current();
  }, []);


  useEffect(() => {
    setForm({
      ...form, 
      height: `${getDataPhysicalCheck}` === null ? "" : `${getDataPhysicalCheck?.height}`,
      weight: `${getDataPhysicalCheck}` === null ? "" :  `${getDataPhysicalCheck?.weight}`,
      blood_type: `${getDataPhysicalCheck}` === null ? "" :  `${getDataPhysicalCheck?.blood_type}`,
      body_temperature: `${getDataPhysicalCheck}` === null ? "" :  `${getDataPhysicalCheck?.body_temperature}`,
      blood_pressure: `${getDataPhysicalCheck}` === null ? "" :  `${getDataPhysicalCheck?.blood_pressure}`,
      date_check: `${getDataPhysicalCheck}` === null ? "" :  `${getDataPhysicalCheck?.date_check}`,
    });
  }, [getDataPhysicalCheck])

  useEffect(() => {
    setForm({
      ...form, 
      med_allergy: `${getDataPhysicalCheckMedAttention}` === null ? "" : `${getDataPhysicalCheckMedAttention?.med_allergy}`,
      med_consuming: `${getDataPhysicalCheckMedAttention}` === null ? "" : `${getDataPhysicalCheckMedAttention?.med_consuming}`,
      surgery_history: `${getDataPhysicalCheckMedAttention}` === null ? "" : `${getDataPhysicalCheckMedAttention?.surgery_history}`,
      cardiac_disease: `${getDataPhysicalCheckMedAttention}` === null ? "" : `${getDataPhysicalCheckMedAttention?.cardiac_disease}`,
      hemophillia: `${getDataPhysicalCheckMedAttention}` === null ? "" : `${getDataPhysicalCheckMedAttention?.hemophillia}`,
      diabetes: `${getDataPhysicalCheckMedAttention}` === null ? "" : `${getDataPhysicalCheckMedAttention?.diabetes}`,
      hepatitis: `${getDataPhysicalCheckMedAttention}` === null ? "" : `${getDataPhysicalCheckMedAttention?.hepatitis}`,
      gastritis: `${getDataPhysicalCheckMedAttention}` === null ? "" : `${getDataPhysicalCheckMedAttention?.gastritis}`,
      special_condition: `${getDataPhysicalCheckMedAttention}` === null ? "" : `${getDataPhysicalCheckMedAttention?.special_condition}`
    });
  }, [getDataPhysicalCheckMedAttention])

  useEffect(() => {
    setForm({
      ...form, 
      date_birth: `${getDataBiodata}` === null ? "" : `${getDataBiodata?.date_birth}`
    });
  }, [getDataBiodata])


  function formatToRupiah(angka) {
    const formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    });
  
    // Format angka ke format mata uang Rupiah
    const formattedString = formatter.format(angka);
  
    // Menghilangkan simbol "RP" (simbol mata uang) dari hasil format
    const rupiahWithoutSymbol = formattedString.replace('Rp', '');
  
    return rupiahWithoutSymbol.trim(); // Menghilangkan spasi yang mungkin ada
  }
  
  // useEffect(() => {
  //   if (getDataFee && getDataMedicalInvoiceById && getDataMedicalInvoiceByIdPreceptions) {
      
  //     let totalFee = 0;
  //     getDataFee.forEach((user) => {
  //       totalFee += user.price;
  //     });
  
  //     // Menghitung total harga dari getDataMedicalInvoiceById
  //     let totalMedicalInvoice = 0;
  //     getDataMedicalInvoiceById.forEach((user) => {
  //       totalMedicalInvoice += user.price;
  //     });
  
  //      // Menghitung total harga dari getDataMedicalInvoiceById
  //      let totalMedicalInvoicePreceptions = 0;
  //      getDataMedicalInvoiceByIdPreceptions.forEach((user) => {
  //       totalMedicalInvoicePreceptions += user.price;
  //      });
      
  //     // Menghitung total harga keseluruhan
  //     const totalHargaKeseluruhan = totalFee + totalMedicalInvoice + totalMedicalInvoicePreceptions;
  
  //     // Memformat total harga keseluruhan ke format Rupiah tanpa simbol "RP"
  //     const hargaDalamRupiah = formatToRupiah(totalHargaKeseluruhan);
  
  //     // Memperbarui elemen dengan ID "totalPrice" dengan total harga dalam format Rupiah tanpa "RP"
  //     const totalElement = document.getElementById('totalPrice');
  //     totalElement.innerText = hargaDalamRupiah;
  //   }
  // }, [getDataFee, getDataMedicalInvoiceById, getDataMedicalInvoiceByIdPreceptions]);

  // Menghitung total harga dari getDataFee
  
  let newDate = new Date()
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();


  const handlePrint = useReactToPrint({
    content: () => myRef.current
  });

  return (
    <Row style={{minHeight:"100vh"}}>
     {loading && <LoaderHome />}
      <Col xl='12' sm='6' style={{ }}>
        {/* TOMBOL PRINT */}
        <div className="mb-2" style={{display:"flex", justifyContent:"end"}}>
          <button onClick={() => handlePrint()} style={{display:"flex", justifyContent:"",alignItems:"center", border:"none", backgroundColor:"#00409F",borderRadius:"3px", fontSize:"15px", padding:"5px 15px", color:"white"}}><div style={{display:"", height:"", alignItems:"center", marginRight:"5px"}}><FaPrint /></div>Print</button>
        </div>

        <form ref={myRef} style={{ width: "100%" }}>
          <div style={{padding:"0px 30px", backgroundColor:"white"}}>
            <div style={{ display: "flex", backgroundColor: "white", opacity: ".9", borderBottom:"4px solid black", paddingBottom:"10px", paddingTop:"20px"}}>
              <div style={{ flex: "30%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img src={Logo_Signature} alt="logo-signature" style={{ width: "35%" }} />
              </div> 
      
              <div style={{ flex: "50%", display:"flex", alignItems:"center",paddingLeft:"20px"}}>
                <div>
                  <div style={{ display: "", justifyContent: "center", fontFamily: "sans-serif", fontWeight: "bolder", fontSize: "20px", textAlign:"center" }}>
                    REKAM MEDIS PASIEN
                  </div>
                  <div style={{display:"", justifyContent:"center", fontFamily:"sans-serif",fontWeight:"", fontSize:"10px", textAlign:"center"}}>
                    Foresta Business Loft Signature Unit 1, Jl. BSD Raya Utama, Tangerang Regency
                  </div>
                </div>
              </div> 
            
              <div style={{ flex: "20%", display:"", alignItems:"center",paddingLeft:"20px"}}>
                    
              </div> 
            </div>
          </div>

          <div style={{ backgroundColor: "white",fontFamily:"sans-serif"}}>
            <div style={{ padding: "5px 30px" }}>

              <div style={{display:"flex"}}>
                <u style={{ fontWeight: "bold" }}>Biodata</u>
              </div>
              
              <div className="" style={{display: "flex",  height:"2vh"}}>
                <div style={{flex:"25%", display:"flex",alignItems:"center",fontSize:"12px", fontWeight:"bold"}}>
                  NAMA LENGKAP
                </div>
                <div style={{ flex: "75%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%"}}>:</p>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%", fontSize: "12px" }}>{getDataBiodata === null? "-" : getDataBiodata?.first_name } {getDataBiodata === null? "" : getDataBiodata?.last_name}</p>
                </div>
              </div>

              <div className="" style={{display: "flex",  height:"2vh"}}>
                <div style={{flex:"25%", display:"flex",alignItems:"center",fontSize:"12px", fontWeight:"bold"}}>
                  NIK
                </div>
                <div style={{ flex: "75%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%"}}>:</p>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%", fontSize: "12px" }}>{getDataBiodata === null? "-" : getDataBiodata?.nic_address }</p>
                </div>
              </div>

              <div className="" style={{display: "flex",  height:"2vh"}}>
                <div style={{flex:"25%", display:"flex",alignItems:"center",fontSize:"12px", fontWeight:"bold"}}>
                  JENIS KELAMIN
                </div>
                <div style={{ flex: "75%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%"}}>:</p>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%", fontSize: "12px" }}>{getDataBiodata === null? "-" : getDataBiodata?.gender }</p>
                </div>
              </div>

              <div className="" style={{display: "flex",  height:"2vh"}}>
                <div style={{flex:"25%", display:"flex",alignItems:"center",fontSize:"12px", fontWeight:"bold"}}>
                  TANGGAL LAHIR
                </div>
                <div style={{ flex: "75%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%"}}>:</p>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%", fontSize: "12px" }}>
                  {getDataBiodata === null || getDataBiodata?.date_birth === null ? "-" : form?.date_birth.split("-")[2]}&nbsp;
                  {getDataBiodata === null || getDataBiodata?.date_birth === null ? "-" :
                  form?.date_birth.split("-")[1] === "01" ? "Januari" : "" ||
                  form?.date_birth.split("-")[1] === "02" ? "Februari" : "" ||
                  form?.date_birth.split("-")[1] === "03" ? "Maret" : "" ||
                  form?.date_birth.split("-")[1] === "04" ? "April" : "" ||  
                  form?.date_birth.split("-")[1] === "05" ? "Mei" : "" ||
                  form?.date_birth.split("-")[1] === "06" ? "Juni" : "" ||
                  form?.date_birth.split("-")[1] === "07" ? "Juli" : "" ||
                  form?.date_birth.split("-")[1] === "08" ? "Agustus" : "" ||
                  form?.date_birth.split("-")[1] === "09" ? "September" : "" ||
                  form?.date_birth.split("-")[1] === "10" ? "Oktober" : "" ||
                  form?.date_birth.split("-")[1] === "11" ? "November" : "" ||
                  form?.date_birth.split("-")[1] === "12" ? "Desember" : ""}&nbsp;
                  {getDataBiodata === null || getDataBiodata?.date_birth === null ? "-" :  form?.date_birth.split("-")[0]}
                  
                  </p>
                </div>
              </div>

              {/* <div className="" style={{display: "flex",  height:"2vh"}}>
                <div style={{flex:"25%", display:"flex",alignItems:"center",fontSize:"12px", fontWeight:"bold"}}>
                  UMUR
                </div>
                <div style={{ flex: "75%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%"}}>:</p>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%", fontSize: "12px" }}>{}</p>
                </div>
              </div> */}

              <div className="" style={{display: "flex",  height:"2vh"}}>
                <div style={{flex:"25%", display:"flex",alignItems:"center",fontSize:"12px", fontWeight:"bold"}}>
                  ALAMAT
                </div>
                <div style={{ flex: "75%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%"}}>:</p>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%", fontSize: "12px" }}>{getDataBiodata === null? "-" : getDataBiodata?.residence_address }</p>
                </div>
              </div>

              <div className="" style={{display: "flex",  height:"2vh"}}>
                <div style={{flex:"25%", display:"flex",alignItems:"center",fontSize:"12px", fontWeight:"bold"}}>
                  NO. TELP
                </div>
                <div style={{ flex: "75%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%"}}>:</p>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%", fontSize: "12px" }}>{getDataBiodata === null? "-" : getDataBiodata?.phone_number}</p>
                </div>
              </div>
              
              <div className="mt-1" style={{border:"1px solid black"}}>
                <div style={{border:"1px solid black", display:"flex", paddingLeft:"5px"}}>
                  <u style={{ fontWeight: "bold" }}>Data Medis</u>
                  <div style={{ fontWeight: "", marginLeft: "5px", fontSize: "12px", display: "flex", alignItems: "center" }}>Pengecekan Terakhir :
                  {getDataPhysicalCheck === null || getDataPhysicalCheck?.date_check === null ? "-" : form?.date_check.split("-")[2]}&nbsp;
                  {getDataPhysicalCheck === null || getDataPhysicalCheck?.date_check === null ? "-" :
                  form?.date_check.split("-")[1] === "01" ? "Januari" : "" ||
                  form?.date_check.split("-")[1] === "02" ? "Februari" : "" ||
                  form?.date_check.split("-")[1] === "03" ? "Maret" : "" ||
                  form?.date_check.split("-")[1] === "04" ? "April" : "" ||  
                  form?.date_check.split("-")[1] === "05" ? "Mei" : "" ||
                  form?.date_check.split("-")[1] === "06" ? "Juni" : "" ||
                  form?.date_check.split("-")[1] === "07" ? "Juli" : "" ||
                  form?.date_check.split("-")[1] === "08" ? "Agustus" : "" ||
                  form?.date_check.split("-")[1] === "09" ? "September" : "" ||
                  form?.date_check.split("-")[1] === "10" ? "Oktober" : "" ||
                  form?.date_check.split("-")[1] === "11" ? "November" : "" ||
                  form?.date_check.split("-")[1] === "12" ? "Desember" : ""}&nbsp;
                  {form === null || form?.date_check === null ? "-" :  form?.date_check.split("-")[0]}
                  
                  </div> 
                </div>

                <div style={{ border: "1px solid black", display: "flex" }}>
                  <div style={{ flex:"50%", display:"flex", justifyContent:"center", fontSize:"12px",borderRight:"1px solid black"}}>
                    PHYSICAL CHECK
                  </div>
                  <div style={{ flex:"50%", display:"flex", justifyContent:"center", fontSize:"12px", }}>
                    MEDICAL CHECK
                  </div>
                </div>


                <div style={{ border: "1px solid black", display: "flex" }}>
                  <div style={{ flex:"50%", display:"", justifyContent:"", fontSize:"11px", paddingLeft:"10px", borderRight:"1px solid black"}}>
                    
                    <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Tinggi Badan 
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.height === null? "-" : getDataPhysicalCheck?.height} Cm</p>
                      </div>
                    </div>

                    <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Berat Badan 
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.weight === null? "-" : getDataPhysicalCheck?.weight} Kg</p>
                      </div>
                    </div>

                    <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Golongan Darah 
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.blood_type === null? "-" : getDataPhysicalCheck?.blood_type}</p>
                      </div>
                    </div>

                    <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Suhu Badan 
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.body_temperature === null? "-" : getDataPhysicalCheck?.body_temperature} &deg;c</p>
                      </div>
                    </div>

                    <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Tekanan Darah 
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.blood_pressure === null ? "" :  getDataPhysicalCheck?.blood_pressure}</p>
                      </div>
                    </div>

                  </div>

                  <div style={{ flex:"50%", display:"", justifyContent:"", fontSize:"11px", paddingLeft:"10px"}}>
                    
                  <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Alergi
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheckMedAttention === null || getDataPhysicalCheckMedAttention?.med_allergy === null? "-" : getDataPhysicalCheckMedAttention?.med_allergy}</p>
                      </div>
                    </div>

                    <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Riwayat Operasi
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheckMedAttention === null || getDataPhysicalCheckMedAttention?.surgery_history === null? "-" : getDataPhysicalCheckMedAttention?.surgery_history}</p>
                      </div>
                    </div>

                    <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Haemopilia
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheckMedAttention === null || getDataPhysicalCheckMedAttention?.hemophillia === null? "-" : getDataPhysicalCheckMedAttention?.hemophillia}</p>
                      </div>
                    </div>

                    <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Hepatitis
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheckMedAttention === null || getDataPhysicalCheckMedAttention?.hepatitis === null? "-" : getDataPhysicalCheckMedAttention?.hepatitis}</p>
                      </div>
                    </div>

                    <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Konsumsi Obat
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheckMedAttention === null || getDataPhysicalCheckMedAttention?.med_consuming === null? "-" : getDataPhysicalCheckMedAttention?.med_consuming}</p>
                      </div>
                    </div>

                    <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Penyakit Jantung
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheckMedAttention === null || getDataPhysicalCheckMedAttention?.cardiac_disease === null? "-" : getDataPhysicalCheckMedAttention?.cardiac_disease}</p>
                      </div>
                    </div>

                    <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Diabetes
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheckMedAttention === null || getDataPhysicalCheckMedAttention?.diabetes === null? "-" : getDataPhysicalCheckMedAttention?.diabetes}</p>
                      </div>
                    </div>

                    <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Gastritis
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheckMedAttention === null || getDataPhysicalCheckMedAttention?.gastritis === null? "-" : getDataPhysicalCheckMedAttention?.gastritis}</p>
                      </div>
                    </div>

                    <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Kondisi Khusus
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheckMedAttention === null || getDataPhysicalCheckMedAttention?.special_condition === null? "-" : getDataPhysicalCheckMedAttention?.special_condition}</p>
                      </div>
                    </div>
                    
                  </div>
                </div>

              </div>

            </div>
          </div>

          <div style={{ backgroundColor: "white", opacity: ".9", fontFamily:"sans-serif"}}>
            <div style={{ padding: "5px 30px" }}>

              <div className="mt-1" style={{border:"1px solid black"}}>
                <div style={{border:"1px solid black", display:"flex", paddingLeft:"5px"}}>
                  <u style={{ fontWeight: "bold" }}>Riwayat Perawatan</u>
                </div>

              <div style={{ border: "1px solid black", display: "flex" }}>
                <div style={{ display: "flex", justifyContent: "center", fontSize: "11px", paddingLeft: "10px" , transform:"scale(0.8)", transformOrigin:"left"}}>
                  <Col className="mt-2" xl='12' sm='12' style={{ padding: "0px 0px", fontSize: "12px" }}>
                  {/* Gigi Atas Dewasa */}
                  <div style={{ display: "flex", }}> 
                    <div style={{flex:"50%", display:"flex", justifyContent:"end", paddingRight:"30px"}}>
                
                    <div style={{ width: "42px", margin: "5px" }}>
                      <div style={{textAlign:"center"}}>18</div>
                        <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                          <g>
                          {/* Atas */}
                          <polygon
                          
                          data-nomor-gigi=" 18 " 
                          
                          
                          points="0,0 40,0 30,10 10,10"
                          style={{ fill: 'white', stroke: 'black' }} 
                          />
                            
                          {/* Bawah */}
                          <polygon
                          
                              data-nomor-gigi=" 18 "          
                              
                          
                          points="10,30 30,30 40,40 0,40"
                          style={{ fill: 'white', stroke: 'black' }}
                          />
                          
                          {/* Kanan */}
                          <polygon
                          
                              data-nomor-gigi=" 18 "        
                              
                                
                          points="30,10 40,0 40,40 30,30"
                          style={{ fill: 'white', stroke: 'black' }}
                          />
                          
                          {/* Kiri */}
                          <polygon
                          
                              data-nomor-gigi=" 18 "   
                              
                                
                          points="0,0 10,10 10,30 0,40"
                          style={{ fill: 'white', stroke: 'black' }}
                          />
            
                          {/* Center */}
                          <polygon
                          
                              data-nomor-gigi=" 18 "  
                              
                                
                          points="10,10 30,10 30,30 10,30"
                          style={{ fill: 'white', stroke: 'black' }}
                          />
                        </g>
                      </svg>
                      </div>
                      
                        <div style={{ width: "42px", margin: "5px" }}>
                        <div style={{textAlign:"center"}}>17</div>
                        <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                          <g>
                          {/* Atas */}
                          <polygon
                          
                          data-nomor-gigi=" 17 " 
                          
                          
                          points="0,0 40,0 30,10 10,10"
                          style={{ fill: 'white', stroke: 'black' }} 
                          />
                            
                          {/* Bawah */}
                          <polygon
                          
                              data-nomor-gigi=" 17 "          
                              
                          
                          points="10,30 30,30 40,40 0,40"
                          style={{ fill: 'white', stroke: 'black' }}
                          />
                          
                          {/* Kanan */}
                          <polygon
                          
                              data-nomor-gigi=" 17 "        
                              
                                
                          points="30,10 40,0 40,40 30,30"
                          style={{ fill: 'white', stroke: 'black' }}
                          />
                          
                          {/* Kiri */}
                          <polygon
                          
                              data-nomor-gigi=" 17 "   
                              
                                
                          points="0,0 10,10 10,30 0,40"
                          style={{ fill: 'white', stroke: 'black' }}
                          />
            
                          {/* Center */}
                          <polygon
                          
                              data-nomor-gigi=" 17 "  
                              
                                
                          points="10,10 30,10 30,30 10,30"
                          style={{ fill: 'white', stroke: 'black' }}
                          />
                        </g>
                      </svg>
            
                      </div>
            
                      <div style={{ width: "42px", margin: "5px" }}>
                      <div style={{textAlign:"center"}}>16</div>
                      <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                          <g>
                          {/* Atas */}
                          <polygon
                          
                          data-nomor-gigi=" 16 " 
                          
                          
                          points="0,0 40,0 30,10 10,10"
                          style={{ fill: 'white', stroke: 'black' }} 
                          />
                            
                          {/* Bawah */}
                          <polygon
                          
                              data-nomor-gigi=" 16 "          
                              
                          
                          points="10,30 30,30 40,40 0,40"
                          style={{ fill: 'white', stroke: 'black' }}
                          />
                          
                          {/* Kanan */}
                          <polygon
                          
                              data-nomor-gigi=" 16 "        
                              
                                
                          points="30,10 40,0 40,40 30,30"
                          style={{ fill: 'white', stroke: 'black' }}
                          />
                          
                          {/* Kiri */}
                          <polygon
                          
                              data-nomor-gigi=" 16 "   
                              
                                
                          points="0,0 10,10 10,30 0,40"
                          style={{ fill: 'white', stroke: 'black' }}
                          />
            
                          {/* Center */}
                          <polygon
                          
                              data-nomor-gigi=" 16 "  
                              
                                
                          points="10,10 30,10 30,30 10,30"
                          style={{ fill: 'white', stroke: 'black' }}
                          />
                        </g>
                      </svg>
                      </div>
            
                      <div style={{ width: "42px", margin: "5px" }}>
                      <div style={{textAlign:"center"}}>15</div>
                      <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                          <g>
                          {/* Atas */}
                          <polygon
                          
                          data-nomor-gigi=" 15 " 
                          
                          
                          points="0,0 40,0 30,10 10,10"
                          style={{ fill: 'white', stroke: 'black' }} 
                          />
                            
                          {/* Bawah */}
                          <polygon
                          
                              data-nomor-gigi=" 15 "          
                              
                          
                          points="10,30 30,30 40,40 0,40"
                          style={{ fill: 'white', stroke: 'black' }}
                          />
                          
                          {/* Kanan */}
                          <polygon
                          
                              data-nomor-gigi=" 15 "        
                              
                                
                          points="30,10 40,0 40,40 30,30"
                          style={{ fill: 'white', stroke: 'black' }}
                          />
                          
                          {/* Kiri */}
                          <polygon
                          
                              data-nomor-gigi=" 15 "   
                              
                                
                          points="0,0 10,10 10,30 0,40"
                          style={{ fill: 'white', stroke: 'black' }}
                          />
            
                          {/* Center */}
                          <polygon
                          
                              data-nomor-gigi=" 15 "  
                              
                                
                          points="10,10 30,10 30,30 10,30"
                          style={{ fill: 'white', stroke: 'black' }}
                          />
                        </g>
                      </svg>
                      </div>
            
                      <div style={{ width: "42px", margin: "5px" }}>
                      <div style={{textAlign:"center"}}>14</div>
                      <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                          <g>
                          {/* Atas */}
                          <polygon
                          
                          data-nomor-gigi=" 14 " 
                          
                          
                          points="0,0 40,0 30,10 10,10"
                          style={{ fill: 'white', stroke: 'black' }} 
                          />
                            
                          {/* Bawah */}
                          <polygon
                          
                              data-nomor-gigi=" 14 "          
                              
                          
                          points="10,30 30,30 40,40 0,40"
                          style={{ fill: 'white', stroke: 'black' }}
                          />
                          
                          {/* Kanan */}
                          <polygon
                          
                              data-nomor-gigi=" 14 "        
                              
                                
                          points="30,10 40,0 40,40 30,30"
                          style={{ fill: 'white', stroke: 'black' }}
                          />
                          
                          {/* Kiri */}
                          <polygon
                          
                              data-nomor-gigi=" 14 "   
                              
                                
                          points="0,0 10,10 10,30 0,40"
                          style={{ fill: 'white', stroke: 'black' }}
                          />
            
                          {/* Center */}
                          <polygon
                          
                              data-nomor-gigi=" 14 "  
                              
                                
                          points="10,10 30,10 30,30 10,30"
                          style={{ fill: 'white', stroke: 'black' }}
                          />
                        </g>
                      </svg>
            
                      </div>
                      
                      <div style={{ width: "42px", margin: "5px" }}>
                      <div style={{ textAlign: "center" }}>13</div>
                        <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                        <g>
                          {/* Top  */}
                          <polygon
                            
                              data-nomor-gigi=" 13 "
                              
                              
                            points="0,0 40,0 30,20 0,20"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                              
                          {/* Botton */}
                          <polygon
                            
                            data-nomor-gigi=" 13 "
                            
                              
                            points="0,20 30,20 100,100 0,100"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                            
                          {/* Right */}
                          <polygon
                            
                              data-nomor-gigi=" 13 "
                              
                              
                            points="30,20 40,0 40,40 35,30"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
            
                          {/* Left     */}
                          <polygon
                            
                              data-nomor-gigi=" 13 "
                              
                              
                            points="0,0 5,10 10,20 0,40"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                        </g>
                      </svg>
                      </div>
                      
                      <div style={{ width: "42px", margin: "5px" }}>
                      <div style={{ textAlign: "center" }}>12</div>
            
                      <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                        <g>
                          <polygon
                          
                            data-nomor-gigi=" 12 "
                            
                            
                            points="0,0 40,0 30,20 0,20"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                            data-nomor-gigi=" 12 "
                            
                            
                            points="0,20 30,20 100,100 0,100"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                              data-nomor-gigi=" 12 "
                              
                              
                            points="30,20 40,0 40,40 35,30"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                              data-nomor-gigi=" 12 "
                              
                              
                            points="0,0 5,10 10,20 0,40"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                        </g>
                      </svg>
                      </div>
                      
                      <div style={{ width: "42px", margin: "5px" }}>
                      <div style={{ textAlign: "center" }}>11</div>
            
                      <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                        <g>
                          <polygon
                            
                            data-nomor-gigi=" 11 "
                            
                            
                            points="0,0 40,0 30,20 0,20"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                            data-nomor-gigi=" 11 "
                            
                            
                            points="0,20 30,20 100,100 0,100"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                            data-nomor-gigi=" 11 "
                            
                            
                            points="30,20 40,0 40,40 35,30"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                            data-nomor-gigi=" 11 "
                            
                            
                            points="0,0 5,10 10,20 0,40"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                        </g>
                      </svg>
                    </div>
                    </div>
            
                    <div style={{flex:"50%", display:"flex", justifyContent:"start", paddingLeft:"30px"}}>
                
                    <div style={{ width: "42px", margin: "5px" }}>
                <div style={{ textAlign: "center" }}>21</div>
            
                <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                  <g>
                    <polygon
                      
                      data-nomor-gigi=" 21 "
                      
                      
                      points="0,0 40,0 30,20 0,20"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                      
                      data-nomor-gigi=" 21 "
                      
                      
                      points="0,20 30,20 100,100 0,100"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                    
                      data-nomor-gigi=" 21 "
                      
                      
                      points="30,20 40,0 40,40 35,30"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                      
                      data-nomor-gigi=" 21 "
                      
                      
                      points="0,0 5,10 10,20 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                  </g>
                </svg>
                </div>
                
                <div style={{ width: "42px", margin: "5px" }}>
                <div style={{ textAlign: "center" }}>22</div>
            
                <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                  <g>
                    <polygon
                      
                      data-nomor-gigi=" 22 "
                      
                      
                      points="0,0 40,0 30,20 0,20"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                      
                      data-nomor-gigi=" 22 "
                      
                      
                      points="0,20 30,20 100,100 0,100"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                      
                      data-nomor-gigi=" 22 "
                      
                      
                      points="30,20 40,0 40,40 35,30"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                      
                      data-nomor-gigi=" 22 "
                      
                      
                      points="0,0 5,10 10,20 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                  </g>
                </svg>
                </div>
                
                <div style={{ width: "42px", margin: "5px" }}>
                <div style={{ textAlign: "center" }}>23</div>
            
                <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                  <g>
                    <polygon
                    
                      data-nomor-gigi=" 23 "
                      
                      
                      points="0,0 40,0 30,20 0,20"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                      
                      data-nomor-gigi=" 23 "
                      
                      
                      points="0,20 30,20 100,100 0,100"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                    
                      data-nomor-gigi=" 23 "
                      
                      
                      points="30,20 40,0 40,40 35,30"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                      
                      data-nomor-gigi=" 23 "
                      
                      
                      points="0,0 5,10 10,20 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                  </g>
                </svg>
              </div> 
                      
                <div style={{ width: "42px", margin: "5px" }}>
                <div style={{textAlign:"center"}}>24</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                    
            <g>
            <polygon
            
            data-nomor-gigi=" 24 "
            
            
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'white', stroke: 'black' }} 
            />
                  <polygon
                    
                      data-nomor-gigi=" 24 "
                      
                      
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                    
                      data-nomor-gigi=" 24 "
                      
                      
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                    
                      data-nomor-gigi=" 24 "
                      
                      
            points="0,0 10,10 10,30 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                      
                      data-nomor-gigi=" 24 "
                      
                      
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
            </g>
              </svg>
            
                </div>
            
                <div style={{ width: "42px", margin: "5px" }}>
                <div style={{textAlign:"center"}}>25</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                    
            <g>
            <polygon
            
            data-nomor-gigi=" 25 "
            
            
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'white', stroke: 'black' }} 
            />
                  <polygon
                      
                      data-nomor-gigi=" 25 "
                      
                      
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                      
                      data-nomor-gigi=" 25 "
                      
                      
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                    
                      data-nomor-gigi=" 25 "
                      
                      
            points="0,0 10,10 10,30 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                      
                      data-nomor-gigi=" 25 "
                      
                      
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
            </g>
              </svg>
            
                </div>
            
                <div style={{ width: "42px", margin: "5px" }}>
                <div style={{textAlign:"center"}}>26</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                    
            <g>
            <polygon
            
            data-nomor-gigi=" 26 "
            
            
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'white', stroke: 'black' }} 
            />
                  <polygon
                      
                      data-nomor-gigi=" 26 "
                      
                      
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                      
                      data-nomor-gigi=" 26 "
                      
                      
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                      
                      data-nomor-gigi=" 26 "
                      
                      
            points="0,0 10,10 10,30 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                      
                      data-nomor-gigi=" 26 "
                      
                      
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
            </g>
              </svg>
            
                </div>
            
                <div style={{ width: "42px", margin: "5px" }}>
                <div style={{textAlign:"center"}}>27</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                    
            <g>
            <polygon
            
            data-nomor-gigi=" 27 "
            
            
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'white', stroke: 'black' }} 
            />
                  <polygon
                      
                      data-nomor-gigi=" 27 "
                      
                      
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                    
                      data-nomor-gigi=" 27 "
                      
                      
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                      
                      data-nomor-gigi=" 27 "
                      
                      
            points="0,0 10,10 10,30 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                      
                      data-nomor-gigi=" 27 "
                      
                      
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
            </g>
              </svg>
            
                </div>
            
                <div style={{ width: "42px", margin: "5px" }}>
                <div style={{textAlign:"center"}}>28</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                    
            <g>
            <polygon
            
            data-nomor-gigi=" 28 "
            
            
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'white', stroke: 'black' }} 
            />
                  <polygon
                      
                      data-nomor-gigi=" 28 "
                      
                      
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                    
                      data-nomor-gigi=" 28 "
                      
                      
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                    
                      data-nomor-gigi=" 28 "
                      
                      
            points="0,0 10,10 10,30 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                      
                      data-nomor-gigi=" 28 "
                      
                      
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
            </g>
              </svg>
            
                </div>
                          
              </div>
                
                  </div>
                  
                  {/* Gigi Atas Anak */}
                  <div style={{display:"flex"}}> 
                    <div style={{flex:"50%", display:"flex", justifyContent:"end", paddingRight:"30px"}}>
            
                      <div style={{ width: "42px", margin: "5px" }}>
                      <div style={{textAlign:"center"}}>55</div>
                        <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                          
              <g>
                <polygon
                
                  data-nomor-gigi=" 55 "
                  
                  
                  points="0,0 40,0 30,10 10,10"
                  style={{ fill: 'white', stroke: 'black' }} 
                />
                        <polygon
                            
                            data-nomor-gigi=" 55 "
                            
                            
                  points="10,30 30,30 40,40 0,40"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                        <polygon
                            
                            data-nomor-gigi=" 55 "
                            
                            
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                        <polygon
                            
                            data-nomor-gigi=" 55 "
                            
                            
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                        <polygon
                            
                            data-nomor-gigi=" 55 "
                            
                            
                  points="10,10 30,10 30,30 10,30"
                  style={{ fill: 'white', stroke: 'black' }}
                />
              </g>
                    </svg>
            
                      </div>
            
                      <div style={{ width: "42px", margin: "5px" }}>
                      <div style={{textAlign:"center"}}>54</div>
                        <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                          
              <g>
                <polygon
                  
                  data-nomor-gigi=" 54 "
                  
                  
                  points="0,0 40,0 30,10 10,10"
                  style={{ fill: 'white', stroke: 'black' }} 
                />
                        <polygon
                            
                            data-nomor-gigi=" 54 "
                            
                            
                  points="10,30 30,30 40,40 0,40"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                        <polygon
                          
                            data-nomor-gigi=" 54 "
                            
                            
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                        <polygon
                          
                            data-nomor-gigi=" 54 "
                            
                            
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                        <polygon
                            
                            data-nomor-gigi=" 54 "
                            
                            
                  points="10,10 30,10 30,30 10,30"
                  style={{ fill: 'white', stroke: 'black' }}
                />
              </g>
                    </svg>
            
                      </div>
                      
                      <div style={{ width: "42px", margin: "5px" }}>
                      <div style={{ textAlign: "center" }}>53</div>
            
                      <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                        <g>
                          <polygon
                            
                            data-nomor-gigi=" 53 "
                            
                            
                            points="0,0 40,0 30,20 0,20"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                            data-nomor-gigi=" 53 "
                            
                            
                            points="0,20 30,20 100,100 0,100"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                            data-nomor-gigi=" 53 "
                            
                            
                            points="30,20 40,0 40,40 35,30"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                            data-nomor-gigi=" 53 "
                            
                            
                            points="0,0 5,10 10,20 0,40"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                        </g>
                      </svg>
                      </div>
                      
                      <div style={{ width: "42px", margin: "5px" }}>
                      <div style={{ textAlign: "center" }}>52</div>
            
                      <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                        <g>
                          <polygon
                            
                            data-nomor-gigi=" 52 "
                            
                            
                            points="0,0 40,0 30,20 0,20"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                            data-nomor-gigi=" 52 "
                            
                            
                            points="0,20 30,20 100,100 0,100"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                            data-nomor-gigi=" 52 "
                            
                            
                            points="30,20 40,0 40,40 35,30"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                            data-nomor-gigi=" 52 "
                            
                            
                            points="0,0 5,10 10,20 0,40"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                        </g>
                      </svg>
                      </div>
                      
                      <div style={{ width: "42px", margin: "5px" }}>
                      <div style={{ textAlign: "center" }}>51</div>
            
                      <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                        <g>
                          <polygon
                            
                            data-nomor-gigi=" 51 "
                            
                            
                            points="0,0 40,0 30,20 0,20"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                            data-nomor-gigi=" 51 "
                            
                            
                            points="0,20 30,20 100,100 0,100"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                            data-nomor-gigi=" 51 "
                            
                            
                            points="30,20 40,0 40,40 35,30"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                            data-nomor-gigi=" 51 "
                            
                            
                            points="0,0 5,10 10,20 0,40"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                        </g>
                      </svg>
                    </div>
                    </div>
            
                    <div style={{flex:"50%", display:"flex", justifyContent:"start", paddingLeft:"30px"}}>
                
                    <div style={{ width: "42px", margin: "5px" }}>
                <div style={{ textAlign: "center" }}>61</div>
            
                <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                  <g>
                    <polygon
                      
                      data-nomor-gigi=" 61 "
                      
                      
                      points="0,0 40,0 30,20 0,20"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                      
                      data-nomor-gigi=" 61 "
                      
                      
                      points="0,20 30,20 100,100 0,100"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                      
                      data-nomor-gigi=" 61 "
                      
                      
                      points="30,20 40,0 40,40 35,30"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                      
                      data-nomor-gigi=" 61 "
                      
                      
                      points="0,0 5,10 10,20 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                  </g>
                </svg>
                </div>
                
                <div style={{ width: "42px", margin: "5px" }}>
                <div style={{ textAlign: "center" }}>62</div>
            
                <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                  <g>
                    <polygon
                      
                      data-nomor-gigi=" 62 "
                      
                      
                      points="0,0 40,0 30,20 0,20"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                      
                        data-nomor-gigi=" 62 "
                        
                        
                      points="0,20 30,20 100,100 0,100"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                      
                        data-nomor-gigi=" 62 "
                        
                        
                      points="30,20 40,0 40,40 35,30"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                      
                        data-nomor-gigi=" 62 "
                        
                        
                      points="0,0 5,10 10,20 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                  </g>
                </svg>
                </div>
                
                <div style={{ width: "42px", margin: "5px" }}>
                <div style={{ textAlign: "center" }}>63</div>
            
                <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                  <g>
                    <polygon
                      
                        data-nomor-gigi=" 63 "
                        
                        
                      points="0,0 40,0 30,20 0,20"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                      
                      data-nomor-gigi=" 63 "
                      
                      
                      points="0,20 30,20 100,100 0,100"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                      
                      data-nomor-gigi=" 63 "
                      
                      
                      points="30,20 40,0 40,40 35,30"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                      
                      data-nomor-gigi=" 63 "
                      
                      
                      points="0,0 5,10 10,20 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                  </g>
                </svg>
              </div> 
            
                <div style={{ width: "42px", margin: "5px" }}>
                <div style={{textAlign:"center"}}>64</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                    
            <g>
            <polygon
            
            data-nomor-gigi=" 64 "
            
            
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'white', stroke: 'black' }} 
            />
                  <polygon
                      
                      data-nomor-gigi=" 64 "
                      
                      
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                      
                      data-nomor-gigi=" 64 "
                      
                      
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                      
                      data-nomor-gigi=" 64 "
                      
                      
            points="0,0 10,10 10,30 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                      
                      data-nomor-gigi=" 64 "
                      
                      
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
            </g>
              </svg>
            
                </div>
            
                <div style={{ width: "42px", margin: "5px" }}>
                <div style={{textAlign:"center"}}>65</div>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                    
            <g>
            <polygon
            
            data-nomor-gigi=" 65 "
            
            
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'white', stroke: 'black' }} 
            />
                  <polygon
                      
                      data-nomor-gigi=" 65 "
                      
                      
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                      
                      data-nomor-gigi=" 65 "
                      
                      
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                      
                      data-nomor-gigi=" 65 "
                      
                      
            points="0,0 10,10 10,30 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                      
                      data-nomor-gigi=" 65 "
                      
                      
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
            </g>
              </svg>
            
                </div>
            
              
                
              
                    </div>
                  </div>
                  
                  {/* Gigi Bawah Anak */}
                  <div className="mt-2" style={{display:"flex"}}> 
                    <div style={{flex:"50%", display:"flex", justifyContent:"end", paddingRight:"30px"}}>
            
                      <div style={{ width: "42px", margin: "5px" }}>
                        <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                          
              <g>
                <polygon
                  
                  data-nomor-gigi=" 85 "
                  
                  
                  points="0,0 40,0 30,10 10,10"
                  style={{ fill: 'white', stroke: 'black' }} 
                />
                        <polygon
                            
                            data-nomor-gigi=" 85 "
                            
                            
                  points="10,30 30,30 40,40 0,40"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                        <polygon
                            
                            data-nomor-gigi=" 85 "
                            
                            
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                        <polygon
                            
                            data-nomor-gigi=" 85 "
                            
                            
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                        <polygon
                            
                            data-nomor-gigi=" 85 "
                            
                            
                  points="10,10 30,10 30,30 10,30"
                  style={{ fill: 'white', stroke: 'black' }}
                />
              </g>
                    </svg>
                    <div style={{textAlign:"center"}}>85</div>
            
                      </div>
            
                      <div style={{ width: "42px", margin: "5px" }}>
                        <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                          
              <g>
                <polygon
                  
                  data-nomor-gigi=" 84 "
                  
                  
                  points="0,0 40,0 30,10 10,10"
                  style={{ fill: 'white', stroke: 'black' }} 
                />
                        <polygon
                            
                            data-nomor-gigi=" 84 "
                            
                            
                  points="10,30 30,30 40,40 0,40"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                        <polygon
                            
                            data-nomor-gigi=" 84 "
                            
                            
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                        <polygon
                            
                            data-nomor-gigi=" 84 "
                            
                            
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                        <polygon
                          
                            data-nomor-gigi=" 84 "
                          
                            
                  points="10,10 30,10 30,30 10,30"
                  style={{ fill: 'white', stroke: 'black' }}
                />
              </g>
                    </svg>
                    <div style={{textAlign:"center"}}>84</div>
            
                      </div>
                      
                      <div style={{ width: "42px", margin: "5px" }}>
                      <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                        <g>
                          <polygon
                            
                            data-nomor-gigi=" 83 "
                            
                            
                            points="0,0 40,0 30,20 0,20"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                            data-nomor-gigi=" 83 "
                              
                            
                              
                            points="0,20 30,20 100,100 0,100"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                            data-nomor-gigi=" 83 "
                            
                            
                              
                            points="30,20 40,0 40,40 35,30"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                            data-nomor-gigi=" 83 "
                            
                            
                            
                            points="0,0 5,10 10,20 0,40"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                        </g>
                        </svg>
                        <div style={{ textAlign: "center" }}>83</div>
                      </div>
                      
                      <div style={{ width: "42px", margin: "5px" }}>
                      <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                        <g>
                          <polygon
                            
                            data-nomor-gigi=" 82 "
                            
                            
                              
                            points="0,0 40,0 30,20 0,20"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                            data-nomor-gigi=" 82 "
                            
                            
                              
                            points="0,20 30,20 100,100 0,100"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                            data-nomor-gigi=" 82 "
                            
                            
                              
                            points="30,20 40,0 40,40 35,30"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                            data-nomor-gigi=" 82 "
                            
                            
                              
                            points="0,0 5,10 10,20 0,40"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                        </g>
                        </svg>
                        <div style={{ textAlign: "center" }}>82</div>
            
                      </div>
                      
                      <div style={{ width: "42px", margin: "5px" }}>
                      <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                        <g>
                          <polygon
                            
                            data-nomor-gigi=" 81 "
                            
                            
                              
                            points="0,0 40,0 30,20 0,20"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                            data-nomor-gigi=" 81 "
                            
                            
                              
                            points="0,20 30,20 100,100 0,100"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                            data-nomor-gigi=" 81 "
                            
                            
                              
                            points="30,20 40,0 40,40 35,30"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                            data-nomor-gigi=" 81 "
                            
                            
                              
                            points="0,0 5,10 10,20 0,40"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                        </g>
                        </svg>
                        <div style={{ textAlign: "center" }}>81</div>
                    </div>
                    </div>
            
                    <div style={{flex:"50%", display:"flex", justifyContent:"start", paddingLeft:"30px"}}>
                
                    <div style={{ width: "42px", margin: "5px" }}>
            
                <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                  <g>
                    <polygon
                    
                      data-nomor-gigi=" 71 "
                            
                            
                              
                      points="0,0 40,0 30,20 0,20"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                    
                      data-nomor-gigi=" 71 "
                            
                            
                      points="0,20 30,20 100,100 0,100"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                    
                      data-nomor-gigi=" 71 "
                            
                            
                      points="30,20 40,0 40,40 35,30"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                      
                      data-nomor-gigi=" 71 "
                              
                              
                      points="0,0 5,10 10,20 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                  </g>
                </svg>
                <div style={{ textAlign: "center" }}>71</div>
                </div>
                
                <div style={{ width: "42px", margin: "5px" }}>
                <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                  <g>
                    <polygon
                      
                      data-nomor-gigi=" 72 "
                        
                              
                      points="0,0 40,0 30,20 0,20"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                    
                      data-nomor-gigi=" 72 "
                      
                            
                      points="0,20 30,20 100,100 0,100"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                      
                      data-nomor-gigi=" 72 "
                        
                              
                      points="30,20 40,0 40,40 35,30"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                      
                      data-nomor-gigi=" 72 "
                        
                              
                      points="0,0 5,10 10,20 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                  </g>
                        </svg>
                        <div style={{ textAlign: "center" }}>72</div>
            
                </div>
                
                <div style={{ width: "42px", margin: "5px" }}>
                <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                  <g>
                    <polygon
                      
                      data-nomor-gigi=" 73 "
                            
                              
                      points="0,0 40,0 30,20 0,20"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                      
                      data-nomor-gigi=" 73 "
                            
                              
                      points="0,20 30,20 100,100 0,100"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                      
                      data-nomor-gigi=" 73 "
                            
                              
                      points="30,20 40,0 40,40 35,30"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                      
                      data-nomor-gigi=" 73 "
                            
                              
                      points="0,0 5,10 10,20 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                  </g>
                </svg>
                <div style={{ textAlign: "center" }}>73</div>
              </div> 
            
                <div style={{ width: "42px", margin: "5px" }}>
                <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                    
            <g>
            <polygon
            
            data-nomor-gigi=" 74 "
                    
                    
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'white', stroke: 'black' }} 
            />
                  <polygon
                    
                      data-nomor-gigi=" 74 "
                            
                            
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                    
                      data-nomor-gigi=" 74 "
                            
                            
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                    
                    data-nomor-gigi=" 74 "
                            
                            
            points="0,0 10,10 10,30 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                    
                      data-nomor-gigi=" 74 "
                            
                            
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
            </g>
              </svg>
              <div style={{textAlign:"center"}}>74</div>
            
                </div>
            
                <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                    
            <g>
            <polygon
          
            data-nomor-gigi=" 75 "
                  
                  
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'white', stroke: 'black' }} 
            />
                  <polygon
                      
                      data-nomor-gigi=" 75 "
                              
                              
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                    
                      data-nomor-gigi=" 75 "
                            
                            
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                    
                      data-nomor-gigi=" 75 "
                            
                            
            points="0,0 10,10 10,30 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                    
                      data-nomor-gigi=" 75 "
                            
                            
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
            </g>
              </svg>
              <div style={{textAlign:"center"}}>75</div>
            
                </div>  
              
                    </div>
                  </div>
                  
                  {/* Gigi Bawah Dewasa */}
                  <div style={{display:"flex"}}> 
                    <div style={{flex:"50%", display:"flex", justifyContent:"end", paddingRight:"30px"}}>
                
                      <div style={{ width: "42px", margin: "5px" }}>
                        <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                          
              <g>
                <polygon
                  
                  data-nomor-gigi=" 48 "
                        
                          
                  points="0,0 40,0 30,10 10,10"
                  style={{ fill: 'white', stroke: 'black' }} 
                />
                        <polygon
                            
                            data-nomor-gigi=" 48 "
                                  
                                    
                  points="10,30 30,30 40,40 0,40"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                        <polygon
                            
                            data-nomor-gigi=" 48 "
                                  
                                    
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                        <polygon
                            
                              data-nomor-gigi=" 48 "
                                    
                                    
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                        <polygon
                          
                          data-nomor-gigi=" 48 "
                                
                                  
                  points="10,10 30,10 30,30 10,30"
                  style={{ fill: 'white', stroke: 'black' }}
                />
              </g>
                    </svg>
                    <div style={{textAlign:"center"}}>48</div>
            
                      </div>
            
                      <div style={{ width: "42px", margin: "5px" }}>
                        <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                          
              <g>
                <polygon
                  
                    data-nomor-gigi=" 47 "
                        
                          
                  points="0,0 40,0 30,10 10,10"
                  style={{ fill: 'white', stroke: 'black' }} 
                />
                        <polygon
                              
                              data-nomor-gigi=" 47 "
                                  
                                      
                  points="10,30 30,30 40,40 0,40"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                        <polygon
                            
                              data-nomor-gigi=" 47 "
                                  
                                    
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                        <polygon
                                
                                data-nomor-gigi=" 47 "
                                    
                                        
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                        <polygon
                              
                                data-nomor-gigi=" 47 "
                                    
                                      
                  points="10,10 30,10 30,30 10,30"
                  style={{ fill: 'white', stroke: 'black' }}
                />
              </g>
                    </svg>
                    <div style={{textAlign:"center"}}>47</div>
            
                      </div>
            
                      <div style={{ width: "42px", margin: "5px" }}>
                        <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                          
              <g>
                <polygon
                    
                    data-nomor-gigi=" 46 "
                          
                            
                  points="0,0 40,0 30,10 10,10"
                  style={{ fill: 'white', stroke: 'black' }} 
                />
                        <polygon
                          
                            data-nomor-gigi=" 46 "
                                
                                  
                  points="10,30 30,30 40,40 0,40"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                        <polygon
                              
                              data-nomor-gigi=" 46 "
                                    
                                      
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                        <polygon
                            
                              data-nomor-gigi=" 46 "
                                  
                                    
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                        <polygon
                            
                              data-nomor-gigi=" 46 "
                                  
                                    
                  points="10,10 30,10 30,30 10,30"
                  style={{ fill: 'white', stroke: 'black' }}
                />
              </g>
                    </svg>
                    <div style={{textAlign:"center"}}>46</div>
            
                      </div>
            
                      <div style={{ width: "42px", margin: "5px" }}>
                        <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                          
              <g>
                <polygon
                    
                    data-nomor-gigi=" 45 "
                          
                            
                  points="0,0 40,0 30,10 10,10"
                  style={{ fill: 'white', stroke: 'black' }} 
                />
                        <polygon
                          
                            data-nomor-gigi=" 45 "
                                
                                  
                  points="10,30 30,30 40,40 0,40"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                        <polygon
                            
                              data-nomor-gigi=" 45 "
                                  
                                    
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                        <polygon
                            
                            data-nomor-gigi=" 45 "
                                  
                                    
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                        <polygon
                            
                            data-nomor-gigi=" 45 "
                                  
                                    
                  points="10,10 30,10 30,30 10,30"
                  style={{ fill: 'white', stroke: 'black' }}
                />
              </g>
                    </svg>
                    <div style={{textAlign:"center"}}>45</div>
            
                      </div>
            
                      <div style={{ width: "42px", margin: "5px" }}>
                        <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                          
              <g>
                <polygon
                  
                    data-nomor-gigi=" 44 "
                        
                          
                  points="0,0 40,0 30,10 10,10"
                  style={{ fill: 'white', stroke: 'black' }} 
                />
                        <polygon
                          
                            data-nomor-gigi=" 44 "
                                
                                  
                  points="10,30 30,30 40,40 0,40"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                        <polygon
                            
                              data-nomor-gigi=" 44 "
                                  
                                    
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                        <polygon
                            
                            data-nomor-gigi=" 44 "
                                  
                                    
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'white', stroke: 'black' }}
                />
                        <polygon
                          
                          data-nomor-gigi=" 44 "
                                
                                  
                  points="10,10 30,10 30,30 10,30"
                  style={{ fill: 'white', stroke: 'black' }}
                />
              </g>
                    </svg>
                    <div style={{textAlign:"center"}}>44</div>
            
                      </div>
                      
                      <div style={{ width: "42px", margin: "5px" }}>
            
                      <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                        <g>
                          <polygon
                            
                              data-nomor-gigi=" 43 "
                                  
                                    
                            points="0,0 40,0 30,20 0,20"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                            data-nomor-gigi=" 43 "
                                  
                                    
                            points="0,20 30,20 100,100 0,100"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                              data-nomor-gigi=" 43 "
                                  
                                    
                            points="30,20 40,0 40,40 35,30"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                              data-nomor-gigi=" 43 "
                                  
                                    
                            points="0,0 5,10 10,20 0,40"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                        </g>
                        </svg>
                        <div style={{ textAlign: "center" }}>43</div>
            
                      </div>
                      
                      <div style={{ width: "42px", margin: "5px" }}>
            
                      <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                        <g>
                          <polygon
                            
                              data-nomor-gigi=" 42 "
                                    
                                    
                            points="0,0 40,0 30,20 0,20"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                            data-nomor-gigi=" 42 "
                                    
                                    
                            points="0,20 30,20 100,100 0,100"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                            data-nomor-gigi=" 42 "
                                    
                                    
                            points="30,20 40,0 40,40 35,30"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                          
                            data-nomor-gigi=" 42 "
                                  
                                  
                            points="0,0 5,10 10,20 0,40"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                        </g>
                        </svg>
                        <div style={{ textAlign: "center" }}>42</div>
            
                      </div>
                      
                      <div style={{ width: "42px", margin: "5px" }}>
            
                      <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                        <g>
                          <polygon
                            
                              data-nomor-gigi=" 41 "
                                    
                                    
                            points="0,0 40,0 30,20 0,20"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                              data-nomor-gigi=" 41 "
                                    
                                    
                            points="0,20 30,20 100,100 0,100"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                            data-nomor-gigi=" 41 "
                                  
                                    
                            points="30,20 40,0 40,40 35,30"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                          <polygon
                            
                              data-nomor-gigi=" 41 "
                                    
                                    
                            points="0,0 5,10 10,20 0,40"
                            style={{ fill: 'white', stroke: 'black' }}
                          />
                        </g>
                        </svg>
                        <div style={{ textAlign: "center" }}>41</div>
            
                    </div>
                    </div>
            
                    <div style={{flex:"50%", display:"flex", justifyContent:"start", paddingLeft:"30px"}}>
                
                    <div style={{ width: "42px", margin: "5px" }}>
            
                <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                  <g>
                    <polygon
                      
                      data-nomor-gigi=" 31 "
                            
                              
                      points="0,0 40,0 30,20 0,20"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                    
                      data-nomor-gigi=" 31 "
                            
                            
                      points="0,20 30,20 100,100 0,100"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                    
                      data-nomor-gigi=" 31 "
                            
                            
                      points="30,20 40,0 40,40 35,30"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                    
                      data-nomor-gigi=" 31 "
                            
                            
                      points="0,0 5,10 10,20 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                  </g>
                        </svg>
                        <div style={{ textAlign: "center" }}>31</div>
            
                </div>
                
                <div style={{ width: "42px", margin: "5px" }}>
            
                <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                  <g>
                    <polygon
                      
                      data-nomor-gigi=" 32 "
                            
                              
                      points="0,0 40,0 30,20 0,20"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                    
                      data-nomor-gigi=" 32 "
                          
                            
                      points="0,20 30,20 100,100 0,100"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                    
                      data-nomor-gigi=" 32 "
                          
                            
                      points="30,20 40,0 40,40 35,30"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                      
                      data-nomor-gigi=" 32 "
                            
                              
                      points="0,0 5,10 10,20 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                  </g>
                        </svg>
                        <div style={{ textAlign: "center" }}>32</div>
            
                </div>
                
                <div style={{ width: "42px", margin: "5px" }}>
            
                <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                  <g>
                    <polygon
                      
                      data-nomor-gigi=" 33 "
                            
                              
                      points="0,0 40,0 30,20 0,20"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                      
                      data-nomor-gigi=" 33 "
                            
                              
                      points="0,20 30,20 100,100 0,100"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                      
                        data-nomor-gigi=" 33 "
                            
                              
                      points="30,20 40,0 40,40 35,30"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                    <polygon
                      
                        data-nomor-gigi=" 33 "
                            
                              
                      points="0,0 5,10 10,20 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                    />
                  </g>
                        </svg>
                        <div style={{ textAlign: "center" }}>33</div>
            
              </div> 
            
                <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                    
            <g>
            <polygon
            
            data-nomor-gigi=" 34 "
                    
                    
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'white', stroke: 'black' }} 
            />
                  <polygon
                    
                      data-nomor-gigi=" 34 "
                            
                            
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                      
                      data-nomor-gigi=" 34 "
                              
                              
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                      
                      data-nomor-gigi=" 34 "
                              
                              
            points="0,0 10,10 10,30 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                    
                      data-nomor-gigi=" 34 "
                            
                            
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
            </g>
              </svg>
              <div style={{textAlign:"center"}}>34</div>
            
                </div>
            
                <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                    
            <g>
            <polygon
          
            data-nomor-gigi=" 35 "
                  
                  
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'white', stroke: 'black' }} 
            />
                  <polygon
                    
                      data-nomor-gigi=" 35 "
                            
                            
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                    
                      data-nomor-gigi=" 35 "
                            
                            
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                    
                      data-nomor-gigi=" 35 "
                            
                            
            points="0,0 10,10 10,30 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                    
                      data-nomor-gigi=" 35 "
                            
                            
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
            </g>
              </svg>
              <div style={{textAlign:"center"}}>35</div>
            
                </div>
            
                <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                    
            <g>
            <polygon
          
            data-nomor-gigi=" 36 "
                
                  
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'white', stroke: 'black' }} 
            />
                  <polygon
                      
                      data-nomor-gigi=" 36 "
                            
                              
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                      
                      data-nomor-gigi=" 36 "
                            
                              
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                      
                      data-nomor-gigi=" 36 "
                            
                              
            points="0,0 10,10 10,30 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                    
                      data-nomor-gigi=" 36 "
                          
                            
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
            </g>
              </svg>
              <div style={{textAlign:"center"}}>36</div>
            
                </div>
            
                <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                    
            <g>
            <polygon
            
            data-nomor-gigi=" 37 "
                    
                    
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'white', stroke: 'black' }} 
            />
                  <polygon
                      
                      data-nomor-gigi=" 37 "
                              
                              
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                      
                      data-nomor-gigi=" 37 "
                              
                              
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                      
                      data-nomor-gigi=" 37 "
                              
                              
            points="0,0 10,10 10,30 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                    
                      data-nomor-gigi=" 37 "
                              
                            
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
            </g>
              </svg>
              <div style={{textAlign:"center"}}>37</div>
            
                </div>
            
                <div style={{ width: "42px", margin: "5px" }}>
                  <svg className="" width={42} height={42} style={{ border: "1px solid black" }}>
                    
            <g>
            <polygon
          
            data-nomor-gigi=" 38 "
                    
                  
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'white', stroke: 'black' }} 
            />
                  <polygon
                    
                      data-nomor-gigi=" 38 "
                              
                            
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                      
                        data-nomor-gigi=" 38 "
                                
                              
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                        
                        data-nomor-gigi=" 38 "
                                
                                
            points="0,0 10,10 10,30 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            />
                  <polygon
                      
                        data-nomor-gigi=" 38 "
                                
                              
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'white', stroke: 'black' }}
            />
            </g>
              </svg>
              <div style={{textAlign:"center"}}>38</div>
            
                </div>
              </div>
                
                  </div>
                  </Col>
                </div>
              </div>
                
                <div style={{border:"1px solid black", display:"", justifyContent:"", fontSize:"11px", paddingLeft:"10px"}}>
                  
                <div style={{ display: "flex", height: "22px" }}>
                    <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                      Alergi
                    </div>
                    <div style={{ flex: "65%", display:"flex"  }}>
                      <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                        <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>-</p>
                    </div>
                  </div>

                  <div style={{ display: "flex", height: "22px" }}>
                    <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                      Riwayat Operasi
                    </div>
                    <div style={{ flex: "65%", display:"flex"  }}>
                      <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                        <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>-</p>
                    </div>
                  </div>

                  <div style={{ display: "flex", height: "22px" }}>
                    <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                      Haemopilia
                    </div>
                    <div style={{ flex: "65%", display:"flex"  }}>
                      <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                        <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>-</p>
                    </div>
                  </div>

                  <div style={{ display: "flex", height: "22px" }}>
                    <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                      Hepatitis
                    </div>
                    <div style={{ flex: "65%", display:"flex"  }}>
                      <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                        <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>-</p>
                    </div>
                  </div>

                  <div style={{ display: "flex", height: "22px" }}>
                    <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                      Konsumsi Obat
                    </div>
                    <div style={{ flex: "65%", display:"flex"  }}>
                      <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                        <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>-</p>
                    </div>
                  </div>

                  <div style={{ display: "flex", height: "22px" }}>
                    <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                      Penyakit Jantung
                    </div>
                    <div style={{ flex: "65%", display:"flex"  }}>
                      <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                        <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>-</p>
                    </div>
                  </div>

                  <div style={{ display: "flex", height: "22px" }}>
                    <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                      Diabetes
                    </div>
                    <div style={{ flex: "65%", display:"flex"  }}>
                      <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                        <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>-</p>
                    </div>
                  </div>

                  <div style={{ display: "flex", height: "22px" }}>
                    <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                      Gastritis
                    </div>
                    <div style={{ flex: "65%", display:"flex"  }}>
                      <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                        <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>-</p>
                    </div>
                  </div>

                  <div style={{ display: "flex", height: "22px" }}>
                    <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                      Kondisi Khusus
                    </div>
                    <div style={{ flex: "65%", display:"flex"  }}>
                      <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                        <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>-</p>
                    </div>
                  </div>
                  
                </div>

              </div>

            </div>
          </div>

          

        </form>
      </Col>
    </Row>
   
  );
}
