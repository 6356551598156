import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaClosedCaptioning, FaTimes} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../../assets/signature/signature logo.png"
import Logo_Batik from "../../../assets/signature/logo-batik.png"
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
import "../Styled.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
import { FadeLoader, HashLoader, ScaleLoader } from "react-spinners";
import LoaderAction from "../../Loader/LoaderAction"

export default function ModalRoleAdd(props) {
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(1);
  const token = localStorage.getItem("token");
  // Untuk Close Proops Data
  const [propsData, setProopsData] = useState()
  const [loading, setLoading] = useState(false);
  const [getData, setGetData] = useState([]);
  const [getDataSpecialist, setGetDataSpecialist] = useState([]);
  const [getCode, setGetCode] = useState([]);
  const [getDataAppointmentById, setGetDataAppointmentById] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const [form, setForm] = useState({
    id_specialist: "",
   });


  const GetResponseDataByIdUser = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/user-service/privileges/users/${props.id}`,fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetData(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataSpecialist = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`user-service/specialists?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`,fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataSpecialist(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

   const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  
  useEffect(() => {
    GetResponseDataByIdUser()
    GetResponseDataSpecialist()
  }, [])

  useEffect(() => {
  if (getData && getData.specialists) {
    setForm({
        ...form,
        id_specialist: getData.specialists.length === 0 ? "" : getData.specialists[0].id
    });
  }
  },[getData]);

  const handleSubmit = useMutation(async (e) => {
    try {
      e.preventDefault();
      setLoading(true)
    
      // Insert data for login process
      const response = await API.post(`/user-service/privileges/users/specialists/store`,
        {
          id_user: `${props.id}`,
          id_specialist: form?.id_specialist,
        }, fetchParams);
  
        // Checking process
        if (response?.status === 200) {
          swal({
            title: 'Success',
            text: response.data.message,
            icon: 'success',
            timer: 5000,
            buttons: false
          })
          props.GetResponseData()
          props.onHide()
          setLoading(false)  
        }
        }catch (error) {
          setLoading(false)
          swal({
            title: 'Failed',
            text: `${error.response.data.message}`,
            icon: 'error',
            timer: 3000,
            buttons: false
          });
        }
  });

  const handleSubmitDelete = useMutation(async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      const payload = {
        specialists: [{ id_specialist: form?.id_specialist }],
      };
  
      // permintaan DELETE dengan payload
      const response = await API.delete(`/user-service/privileges/users/specialists/${props.id}`, {
        data: payload,
        ...fetchParams, 
      });

      // Periksa respons
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false,
        });
        props.GetResponseData()
        props.onHide()
        setLoading(false)
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response?.data?.message || 'An error occurred'}`,
        icon: 'error',
        timer: 3000,
        buttons: false,
      });
      setLoading(false);
    }
  });

  return (
  <div>
    {loading && <LoaderAction/>}
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{ fontFamily: "sans-serif", border: "none" }}>  
      <div style={{width: "100%",display:"flex",padding:"10px 0px", backgroundColor:"#29B8FF"}}>
        <div style={{flex:"92%", fontSize:"20px",display:"flex",alignItems:"center", paddingLeft:"10px", color:"white", fontWeight:"600"}}>
          User Spesialist
        </div> 
        <div  style={{flex:"8%",fontSize:"30px",display:"flex",alignItems:"center",justifyContent:"center", color:"white"}}>
          <FaTimes onClick={() => setProopsData(props.onHide)} style={{cursor:"pointer"}}/>
        </div> 
      </div>
      <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px",border:"none",backgroundImage:"transparent" }}>
      <Form onSubmit={(e) => handleSubmit.mutate(e)} className="mt-3" >
                           
      <div className="mt-3" style={{ display: "flex"}}>
        <div style={{flex:"20%", display:"flex",alignItems:"center"}}>
          Pilih Spesialist
        </div>
        <div style={{ flex: "80%", display:"flex"}}>
          <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
          <select className="form-select" aria-label="Default select example"  onChange={handleChange} value={form?.id_specialist} disabled={getData && getData.specialists && getData.specialists.length === 0 ? false:true}  name="id_specialist" style={{ textAlign:"",cursor: getData && getData.specialists && getData.specialists.length === 0 ? "pointer" : "",  }}>
              <option value="" hidden>Select</option>
              {getDataSpecialist.map((user,index) => (
                <option value={user?.id} style={{ textAlign: "" }}>{user?.name}</option>                         
              ))}
          </select>
        </div>
      </div>
      {getData && getData.specialists && getData.specialists.length === 0 ? 
        <div style={{ padding: "0px 0px", marginTop: "0px", display:"flex", justifyContent:"end" }}>
          <div>
            <Button className="mt-4" onClick={(e) => handleSubmit.mutate(e)} color='primary' style={{ padding: "8px 20px", fontSize: "12px", borderRadius: "5px"}}>
              Simpan
            </Button>
          </div>
        </div>
        :
        <div style={{ padding: "0px 0px", marginTop: "0px", display:"flex", justifyContent:"end" }}>
          <div>
          <Button className="mt-4" onClick={(e) => handleSubmitDelete.mutate(e)} style={{ backgroundColor: "red", color: "white", padding: "8px 20px", fontSize: "12px", borderRadius: "5px" }}>
            Delete
          </Button>
          </div>
        </div>
      }
      </Form>
        
      </Modal.Body>
      {/* <Modal.Footer>
        <div style={{ display: "flex" }}>
          <Button onClick={props.onHide} style={{marginRight:"8px"}}>Close</Button>
        </div>
      </Modal.Footer> */}      
      </Modal>
    </div>
   
    );
}
  