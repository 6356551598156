import { useState } from "react";
import {  useParams } from "react-router-dom";
import "bulma/css/bulma.css";
import "../Styled.css"

export default function ToochNumber({ form, setForm, onclikTooch, handlePolygonClick }) {
  document.title = "SDC Apps";
  const token = localStorage.getItem("token");
  // modal role add 
  const { id, code } = useParams()
  const [infoGigi, setInfoGigi] = useState("XX-X")

  return (
    <div style={{flex:"50%", display:"flex", justifyContent:"start", paddingLeft:""}}>
      
        <div style={{ width: "42px", margin: "1px" }}>
    {form.tooch_number.includes("21-une") || form.tooch_number.includes("21-non") || form.tooch_number.includes("21-pre") || form.tooch_number.includes("21-ano") || form.tooch_number.includes("21-ipx-poc") || form.tooch_number.includes("21-mis-prd-acr") || form.tooch_number.includes("21-mis-fld-acr") ?
      <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
        {form.tooch_number.includes("21-une") &&
          <div style={{ position: "absolute", color: "black" }}>UNE</div>
        }
        {form.tooch_number.includes("21-non") &&
          <div style={{ position: "absolute", color: "black" }}>NON</div>
        }      
        {form.tooch_number.includes("21-pre") &&
          <div style={{ position: "absolute", color: "black" }}>PRE</div>
        }      
        {form.tooch_number.includes("21-ano") &&
          <div style={{ position: "absolute", color: "black" }}>ANO</div>
        }
        {form.tooch_number.includes("21-ipx-poc") &&
          <div style={{ position: "absolute", color: "black" }}>IPX</div>
        }
        {form.tooch_number.includes("21-mis-prd-acr") &&
          <div style={{ position: "absolute", color: "black" }}>PRD</div>
        } 
        {form.tooch_number.includes("21-mis-fld-acr") &&
          <div style={{ position: "absolute", color: "black" }}>FLD</div>
        }      
      </div>
      :
      <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
      </div>
    }

          {form.tooch_number.includes("21-meb-22-miss-pon-meb-23-meb")
            ||form.tooch_number.includes("21-meb-22-miss-pon-meb-23-miss-pon-meb-24-meb")
            ||form.tooch_number.includes("21-meb-22-miss-pon-meb-23-miss-pon-meb-24-miss-pon-meb-25-meb")
            ||form.tooch_number.includes("21-meb-22-miss-pon-meb-23-miss-pon-meb-24-miss-pon-meb-25-miss-pon-meb-26-meb")
            ||form.tooch_number.includes("21-meb-22-miss-pon-meb-23-miss-pon-meb-24-miss-pon-meb-25-miss-pon-meb-26-miss-pon-meb-27-meb")
            ||form.tooch_number.includes("21-meb-22-miss-pon-meb-23-miss-pon-meb-24-miss-pon-meb-25-miss-pon-meb-26-miss-pon-meb-27-miss-pon-meb-28-meb") ?
              <>
            {/* meb 18 - 16 */}
            {form.tooch_number.includes("21-meb-22-miss-pon-meb-23-meb") &&
              <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">
                  {/* Garis ke samping dan ke bawah (L-shape) */}
                  <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
                  <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                  <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                </svg>
              </div>
            }
                                    
            {/* meb 18 - 15 */}
            {form.tooch_number.includes("21-meb-22-miss-pon-meb-23-miss-pon-meb-24-meb") &&
              <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">
                {/* Garis ke samping dan ke bawah (L-shape) */}
                <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              </svg>
            </div>
            }
            
            {/* meb 18 - 14 */} 
            {form.tooch_number.includes("21-meb-22-miss-pon-meb-23-miss-pon-meb-24-miss-pon-meb-25-meb") &&      
            <div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
            <svg width="100%" height="20vh">

              {/* Garis ke samping dan ke bawah (L-shape) */}
              <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            </svg>
            </div> 
            }
            
            {/* meb 18 - 13 */}
            {form.tooch_number.includes("21-meb-22-miss-pon-meb-23-miss-pon-meb-24-miss-pon-meb-25-miss-pon-meb-26-meb") &&      
             <div style={{ width: "259px", textAlign: "center", position: "absolute", height:"10vh" }}>
             <svg width="100%" height="20vh">

                {/* Garis ke samping dan ke bawah (L-shape) */}
                <line x1="20" y1="1.5" x2="241" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="241" y1="0" x2="241" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              </svg>
            </div> 
              }

            {/* meb 18 - 12 */}
            {form.tooch_number.includes("21-meb-22-miss-pon-meb-23-miss-pon-meb-24-miss-pon-meb-25-miss-pon-meb-26-miss-pon-meb-27-meb") &&          
           <div style={{ width: "302px", textAlign: "center", position: "absolute", height:"10vh" }}>
           <svg width="100%" height="20vh">

              {/* Garis ke samping dan ke bawah (L-shape) */}
              <line x1="20" y1="1.5" x2="285" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="285" y1="0" x2="285" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            </svg>
          </div>
            }

          {/* meb 18 - 11 */}
          {form.tooch_number.includes("21-meb-22-miss-pon-meb-23-miss-pon-meb-24-miss-pon-meb-25-miss-pon-meb-26-miss-pon-meb-27-miss-pon-meb-28-meb") &&        
           <div style={{ width: "345px", textAlign: "center", position: "absolute", height:"10vh" }}>
           <svg width="100%" height="20vh">
                {/* Garis ke samping dan ke bawah (L-shape) */}
                <line x1="20" y1="1.5" x2="329" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="329" y1="0" x2="329" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              </svg>
            </div>
            }          
              </>
                :
              <></>
            }
      
          {form.tooch_number.includes("21-pob-22-miss-pon-pob-23-pob")
            ||form.tooch_number.includes("21-pob-22-miss-pon-pob-23-miss-pon-pob-24-pob")
            ||form.tooch_number.includes("21-pob-22-miss-pon-pob-23-miss-pon-pob-24-miss-pon-pob-25-pob")
            ||form.tooch_number.includes("21-pob-22-miss-pon-pob-23-miss-pon-pob-24-miss-pon-pob-25-miss-pon-pob-26-pob")
            ||form.tooch_number.includes("21-pob-22-miss-pon-pob-23-miss-pon-pob-24-miss-pon-pob-25-miss-pon-pob-26-miss-pon-pob-27-pob")
            ||form.tooch_number.includes("21-pob-22-miss-pon-pob-23-miss-pon-pob-24-miss-pon-pob-25-miss-pon-pob-26-miss-pon-pob-27-miss-pon-pob-28-pob") ?
              <>
            {/* meb 18 - 16 */}
            {form.tooch_number.includes("21-pob-22-miss-pon-pob-23-pob") &&
              <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">

                  {/* Garis ke samping dan ke bawah (L-shape) */}
                  <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
                  <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                  <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                </svg>
              </div>
            }
                                    
            {/* meb 18 - 15 */}
            {form.tooch_number.includes("21-pob-22-miss-pon-pob-23-miss-pon-pob-24-pob") &&
              <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">

                {/* Garis ke samping dan ke bawah (L-shape) */}
                <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              </svg>
            </div>
            }
            
            {/* meb 18 - 14 */} 
            {form.tooch_number.includes("21-pob-22-miss-pon-pob-23-miss-pon-pob-24-miss-pon-pob-25-pob") &&      
            <div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
            <svg width="100%" height="20vh">

              {/* Garis ke samping dan ke bawah (L-shape) */}
              <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            </svg>
            </div> 
            }
            
            {/* meb 18 - 13 */}
            {form.tooch_number.includes("21-pob-22-miss-pon-pob-23-miss-pon-pob-24-miss-pon-pob-25-miss-pon-pob-26-pob") &&      
              
<div style={{ width: "259px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">

                {/* Garis ke samping dan ke bawah (L-shape) */}
                <line x1="20" y1="1.5" x2="241" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="241" y1="0" x2="241" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              </svg>
            </div> 
              }

            {/* meb 18 - 12 */}
            {form.tooch_number.includes("21-pob-22-miss-pon-pob-23-miss-pon-pob-24-miss-pon-pob-25-miss-pon-pob-26-miss-pon-pob-27-pob") &&          
          <div style={{ width: "302px", textAlign: "center", position: "absolute", height:"10vh" }}>
          <svg width="100%" height="20vh">

              {/* Garis ke samping dan ke bawah (L-shape) */}
              <line x1="20" y1="1.5" x2="285" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="285" y1="0" x2="285" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            </svg>
          </div>
            }

          {/* meb 18 - 11 */}
          {form.tooch_number.includes("21-pob-22-miss-pon-pob-23-miss-pon-pob-24-miss-pon-pob-25-miss-pon-pob-26-miss-pon-pob-27-miss-pon-pob-28-pob") &&        
            <div style={{ width: "345px", textAlign: "center", position: "absolute", height:"10vh" }}>
            <svg width="100%" height="20vh">
                {/* Garis ke samping dan ke bawah (L-shape) */}
                <line x1="20" y1="1.5" x2="329" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="329" y1="0" x2="329" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              </svg>
            </div>
            }          
              </>
                :
              <></>
            }

    <div style={{ textAlign: "center" }}>21</div>
    <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("21-fmc") || form.tooch_number.includes("21-poc") || form.tooch_number.includes("21-ipx-poc") || form.tooch_number.match(/\b21-pob\b/) || form.tooch_number.match(/\b21-miss-pon-pob\b/) || form.tooch_number.match(/\b21-meb\b/) || form.tooch_number.match(/\b21-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
    {form.tooch_number.includes("21-poc") || form.tooch_number.includes("21-ipx-poc") || form.tooch_number.match(/\b21-pob\b/) || form.tooch_number.match(/\b21-miss-pon-pob\b/) ?
      <g>
        {/* Atas */}
        {form.tooch_number.includes("21L-fis") || form.tooch_number.includes("21L-amf") || form.tooch_number.includes("21L-cof") || form.tooch_number.includes("21L-car") ?
          <>
            {form.tooch_number.includes("21L-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="21L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 21L"
                  points="0,0 40,0 30,20 10,20"
                  style={{ fill: 'url(#21L-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("21-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />   
              </svg>
            }

            {form.tooch_number.includes("21L-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 21L"
                points="0,0 40,0 30,20 10,20"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("21-Labial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("21L-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="21L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 21L"
                  points="0,0 40,0 30,20 10,20"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("21-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("21L-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 21L"
                points="0,0 40,0 30,20 10,20"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("21-Labial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          
          </>
          :
          <svg width="40" height="40">
            <defs>
              <pattern id="21L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 21L"
              points="0,0 40,0 30,20 10,20"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("21-Labial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {/* Bawah */}
        {form.tooch_number.includes("21P-fis") || form.tooch_number.includes("21P-amf") || form.tooch_number.includes("21P-cof") || form.tooch_number.includes("21P-car") ?
          <>
            {form.tooch_number.includes("21P-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="21P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>

                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 21P"
                  points="30,20 40,40 0,40 10,20"
                  style={{ fill: 'url(#21P-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("21-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("21P-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 21P"
                points="30,20 40,40 0,40 10,20"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("21-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("21P-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="21P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 21P"
                  points="30,20 40,40 0,40 10,20"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("21-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("21P-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 21P"
                points="30,20 40,40 0,40 10,20"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("21-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          </>
          :
          <svg width="40" height="40">
            <defs>
              <pattern id="21P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 21P"
              points="30,20 40,40 0,40 10,20"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("21-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

          {/* Kanan */}
          {form.tooch_number.includes("21D-fis") || form.tooch_number.includes("21D-amf") || form.tooch_number.includes("21D-cof") || form.tooch_number.includes("21D-car") ?
          <>
            {form.tooch_number.includes("21D-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="21D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 21D"
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: 'url(#21D-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("21-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("21D-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 21D"
                points="30,20 40,0 40,40 35,30"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("21-Distal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("21D-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="21D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 21D"
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("21-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }
          
            {form.tooch_number.includes("21D-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 21D"
                points="30,20 40,0 40,40 35,30"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("21-Distal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          
          </>
          :
          <svg width="40" height="40">
            <defs>
              <pattern id="21D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 21D"
              points="30,20 40,0 40,40 35,30"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("21-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
          }

        {/* Kiri */}
        {form.tooch_number.includes("21M-fis") || form.tooch_number.includes("21M-amf") || form.tooch_number.includes("21M-cof") || form.tooch_number.includes("21M-car") ?
            <>
              {form.tooch_number.includes("21M-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="21M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 21M"
                    points="0,0 5,10 10,20 0,40"
                    style={{ fill: 'url(#21M-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("21-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("21M-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 21M"
                  points="0,0 5,10 10,20 0,40"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("21-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("21M-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="21M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 21M"
                    points="0,0 5,10 10,20 0,40"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("21-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("21M-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 21M"
                  points="0,0 5,10 10,20 0,40"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("21-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

            </>
            :
            <svg width="40" height="40">
              <defs>
                <pattern id="21M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                  <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                </pattern>
              </defs>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 21M"
                points="0,0 5,10 10,20 0,40"
                style={{ fill: '#007610', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("21-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            </svg>
          }

        {/* cfr */}
        {form.tooch_number.includes("21-cfr") &&
          <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
            #
          </text>
        }

        {/* rrx */}
        {form.tooch_number.includes("21-rrx") &&    
        <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 21-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
          v
        </text>
        }
              
        {/* mis */}
        {form.tooch_number.includes("21-mis") &&    
        <>  
          <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
          <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
        </> 
        }
                
      </g>
        :
      <g>
        {/* Atas */}
        {form.tooch_number.includes("21L-fis") || form.tooch_number.includes("21L-amf") || form.tooch_number.includes("21L-cof") || form.tooch_number.includes("21L-car") ?
          <>
            {form.tooch_number.includes("21L-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="21L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 21L"
                  points="0,0 40,0 30,20 10,20"
                  style={{ fill: 'url(#21L-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("21-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("21L-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 21L"
                points="0,0 40,0 30,20 10,20"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("21-Labial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("21L-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="21L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 21L"
                  points="0,0 40,0 30,20 10,20"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("21-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("21L-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 21L"
                points="0,0 40,0 30,20 10,20"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("21-Labial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          
          </>
          :
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 21L"
            points="0,0 40,0 30,20 10,20"
            style={{ fill: 'white', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("21-Labial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {/* Bawah */}
        {form.tooch_number.includes("21P-fis") || form.tooch_number.includes("21P-amf") || form.tooch_number.includes("21P-cof") || form.tooch_number.includes("21P-car") ?
          <>
            {form.tooch_number.includes("21P-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="21P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>

                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 21P"
                  points="30,20 40,40 0,40 10,20"
                  style={{ fill: 'url(#21P-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("21-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("21P-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 21P"
                points="30,20 40,40 0,40 10,20"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("21-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("21P-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="21P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 21P"
                  points="30,20 40,40 0,40 10,20"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("21-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("21P-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 21P"
                points="30,20 40,40 0,40 10,20"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("21-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          </>
          :
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 21P"
            points="30,20 40,40 0,40 10,20"
            style={{ fill: 'white', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("21-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {/* Kanan */}
          {form.tooch_number.includes("21D-fis") || form.tooch_number.includes("21D-amf") || form.tooch_number.includes("21D-cof") || form.tooch_number.includes("21D-car") ?
          <>
            {form.tooch_number.includes("21D-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="21D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 21D"
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: 'url(#21D-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("21-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("21D-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 21D"
                points="30,20 40,0 40,40 35,30"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("21-Distal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("21D-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="21D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 21D"
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("21-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }
          
            {form.tooch_number.includes("21D-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 21D"
                points="30,20 40,0 40,40 35,30"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("21-Distal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          
          </>
          :
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 21D"
            points="30,20 40,0 40,40 35,30"
            style={{ fill: 'white', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("21-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

      {/* Kiri */}
        {form.tooch_number.includes("21M-fis") || form.tooch_number.includes("21M-amf") || form.tooch_number.includes("21M-cof") || form.tooch_number.includes("21M-car") ?
        <>
          {form.tooch_number.includes("21M-fis") &&
            <svg width="40" height="40">
              <defs>
                <pattern id="21M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                  <rect width="8" height="8" fill="#ffb8c6" />
                  <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                </pattern>
              </defs>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 21M"
                points="0,0 5,10 10,20 0,40"
                style={{ fill: 'url(#21M-fis)', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("21-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            </svg>
          }

          {form.tooch_number.includes("21M-amf") &&
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 21M"
              points="0,0 5,10 10,20 0,40"
              style={{ fill: 'black', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("21-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          }

          {form.tooch_number.includes("21M-cof") &&
            <svg width="40" height="40">
              <defs>
                <pattern id="21M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                  <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                </pattern>
              </defs>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 21M"
                points="0,0 5,10 10,20 0,40"
                style={{ fill: '#007610', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("21-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            </svg>
          }

          {form.tooch_number.includes("21M-car") &&
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 21M"
              points="0,0 5,10 10,20 0,40"
              style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
              onMouseEnter={() => setInfoGigi("21-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          }

        </>
        :
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 21M"
          points="0,0 5,10 10,20 0,40"
          style={{ fill: 'white', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("21-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

        {/* cfr */}
        {form.tooch_number.includes("21-cfr") &&
          <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
            #
          </text>
        }
        
        {form.tooch_number.includes("21-rrx") &&    
        <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 21-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
          v
        </text>
        }
        
        {form.tooch_number.includes("21-mis") &&    
        <>  
          <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
          <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
        </> 
        }
      </g>
    }    
          
    </svg>
        
    {form.tooch_number.includes("21-nvt") &&   
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
      <svg width={42} height={42}>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 21"
          points="21,2 41,32 1,32" 
          style={{ fill: 'white', stroke: 'black', strokeWidth: 3 }}
          onMouseEnter={() => setInfoGigi('21')}
          onMouseLeave={() => setInfoGigi('XX-X')}
        />
      </svg> 
    </div>
    }  

    {form.tooch_number.includes("21-rct") &&   
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
    <svg width={42} height={42}>
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 21"
        points="21,2 41,32 1,32" 
        style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
        onMouseEnter={() => setInfoGigi('21')}
        onMouseLeave={() => setInfoGigi('XX-X')}
      />
      </svg>
    </div>
    } 
        
    {form.tooch_number.includes("21B-amf-rct") || form.tooch_number.includes("21P-amf-rct") || form.tooch_number.includes("21M-amf-rct") || form.tooch_number.includes("21D-amf-rct") || form.tooch_number.includes("21L-amf-rct") ||
    form.tooch_number.includes("21B-cof-rct") || form.tooch_number.includes("21P-cof-rct") || form.tooch_number.includes("21M-cof-rct") || form.tooch_number.includes("21D-cof-rct") || form.tooch_number.includes("21L-cof-rct") ||
    form.tooch_number.includes("21-fmc-rct") ||
    form.tooch_number.includes("21-poc-rct") ?
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
    <svg width={42} height={42}>
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 21B"
        points="21,2 41,32 1,32" 
        style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
        onMouseEnter={() => setInfoGigi('21-Bukal')}
        onMouseLeave={() => setInfoGigi('XX-X')}
      />
      </svg>
    </div>
      :
    <></>
    } 

        </div>   
        
        <div style={{ width: "42px", margin: "1px" }}>
    {form.tooch_number.includes("22-une") || form.tooch_number.includes("22-non") || form.tooch_number.includes("22-pre") || form.tooch_number.includes("22-ano") || form.tooch_number.includes("22-ipx-poc") || form.tooch_number.includes("22-mis-prd-acr") || form.tooch_number.includes("22-mis-fld-acr") ?
      <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
        {form.tooch_number.includes("22-une") &&
          <div style={{ position: "absolute", color: "black" }}>UNE</div>
        }
        {form.tooch_number.includes("22-non") &&
          <div style={{ position: "absolute", color: "black" }}>NON</div>
        }      
        {form.tooch_number.includes("22-pre") &&
          <div style={{ position: "absolute", color: "black" }}>PRE</div>
        }      
        {form.tooch_number.includes("22-ano") &&
          <div style={{ position: "absolute", color: "black" }}>ANO</div>
        }
        {form.tooch_number.includes("22-ipx-poc") &&
          <div style={{ position: "absolute", color: "black" }}>IPX</div>
        }
        {form.tooch_number.includes("22-mis-prd-acr") &&
          <div style={{ position: "absolute", color: "black" }}>PRD</div>
        } 
        {form.tooch_number.includes("22-mis-fld-acr") &&
          <div style={{ position: "absolute", color: "black" }}>FLD</div>
        }      
      </div>
      :
      <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
      </div>
    }

          {form.tooch_number.includes("22-meb-23-miss-pon-meb-24-meb")
            ||form.tooch_number.includes("22-meb-23-miss-pon-meb-24-miss-pon-meb-25-meb")
            ||form.tooch_number.includes("22-meb-23-miss-pon-meb-24-miss-pon-meb-25-miss-pon-meb-26-meb")
            ||form.tooch_number.includes("22-meb-23-miss-pon-meb-24-miss-pon-meb-25-miss-pon-meb-26-miss-pon-meb-27-meb")
            ||form.tooch_number.includes("22-meb-23-miss-pon-meb-24-miss-pon-meb-25-miss-pon-meb-26-miss-pon-meb-27-miss-pon-meb-28-meb") ?
              <>
            {/* meb 22 - 24 */}
            {form.tooch_number.includes("22-meb-23-miss-pon-meb-24-meb") &&
              <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">

                  {/* Garis ke samping dan ke bawah (L-shape) */}
                  <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
                  <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                  <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                </svg>
              </div>
            }
                                    
            {/* meb 22 - 25 */}
            {form.tooch_number.includes("22-meb-23-miss-pon-meb-24-miss-pon-meb-25-meb") &&
              <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">


                {/* Garis ke samping dan ke bawah (L-shape) */}
                <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              </svg>
            </div>
            }
            
            {/* meb 22 - 26 */} 
            {form.tooch_number.includes("22-meb-23-miss-pon-meb-24-miss-pon-meb-25-miss-pon-meb-26-meb") &&      
           <div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
           <svg width="100%" height="20vh">
              {/* Garis ke samping dan ke bawah (L-shape) */}
              <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            </svg>
            </div> 
            }
            
            {/* meb 22 - 27 */}
            {form.tooch_number.includes("22-meb-23-miss-pon-meb-24-miss-pon-meb-25-miss-pon-meb-26-miss-pon-meb-27-meb") &&      
              <div style={{ width: "259px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">

                {/* Garis ke samping dan ke bawah (L-shape) */}
                <line x1="20" y1="1.5" x2="241" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="241" y1="0" x2="241" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              </svg>
            </div> 
              }

            {/* meb 22 - 28 */}
            {form.tooch_number.includes("22-meb-23-miss-pon-meb-24-miss-pon-meb-25-miss-pon-meb-26-miss-pon-meb-27-miss-pon-meb-28-meb") &&          
            <div style={{ width: "302px", textAlign: "center", position: "absolute", height:"10vh" }}>
            <svg width="100%" height="20vh">

              {/* Garis ke samping dan ke bawah (L-shape) */}
              <line x1="20" y1="1.5" x2="285" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="285" y1="0" x2="285" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            </svg>
          </div>
            }

              
              </>
                :
              <></>
            }
      
      {form.tooch_number.includes("22-pob-23-miss-pon-pob-24-pob")
            ||form.tooch_number.includes("22-pob-23-miss-pon-pob-24-miss-pon-pob-25-pob")
            ||form.tooch_number.includes("22-pob-23-miss-pon-pob-24-miss-pon-pob-25-miss-pon-pob-26-pob")
            ||form.tooch_number.includes("22-pob-23-miss-pon-pob-24-miss-pon-pob-25-miss-pon-pob-26-miss-pon-pob-27-pob")
            ||form.tooch_number.includes("22-pob-23-miss-pon-pob-24-miss-pon-pob-25-miss-pon-pob-26-miss-pon-pob-27-miss-pon-pob-28-pob") ?
              <>
            {/* pob 22 - 24 */}
            {form.tooch_number.includes("22-pob-23-miss-pon-pob-24-pob") &&
            <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
            <svg width="100%" height="20vh">

                  {/* Garis ke samping dan ke bawah (L-shape) */}
                  <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
                  <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                  <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                </svg>
              </div>
            }
                                    
            {/* pob 22 - 25 */}
            {form.tooch_number.includes("22-pob-23-miss-pon-pob-24-miss-pon-pob-25-pob") &&
             <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
             <svg width="100%" height="20vh">
                {/* Garis ke samping dan ke bawah (L-shape) */}
                <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              </svg>
            </div>
            }
            
            {/* pob 22 - 26 */} 
            {form.tooch_number.includes("22-pob-23-miss-pon-pob-24-miss-pon-pob-25-miss-pon-pob-26-pob") &&      
            <div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
            <svg width="100%" height="20vh">

              {/* Garis ke samping dan ke bawah (L-shape) */}
              <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            </svg>
            </div> 
            }
            
            {/* pob 22 - 27 */}
            {form.tooch_number.includes("22-pob-23-miss-pon-pob-24-miss-pon-pob-25-miss-pon-pob-26-miss-pon-pob-27-pob") &&      
             
<div style={{ width: "259px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">

                {/* Garis ke samping dan ke bawah (L-shape) */}
                <line x1="20" y1="1.5" x2="241" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="241" y1="0" x2="241" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              </svg>
            </div> 
              }

            {/* pob 22 - 28 */}
            {form.tooch_number.includes("22-pob-23-miss-pon-pob-24-miss-pon-pob-25-miss-pon-pob-26-miss-pon-pob-27-miss-pon-pob-28-pob") &&          
           <div style={{ width: "302px", textAlign: "center", position: "absolute", height:"10vh" }}>
           <svg width="100%" height="20vh">

              {/* Garis ke samping dan ke bawah (L-shape) */}
              <line x1="20" y1="1.5" x2="285" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="285" y1="0" x2="285" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            </svg>
          </div>
            }

              
              </>
                :
              <></>
            }

    <div style={{ textAlign: "center" }}>22</div>
    <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("22-fmc") || form.tooch_number.includes("22-poc") || form.tooch_number.includes("22-ipx-poc") || form.tooch_number.match(/\b22-pob\b/) || form.tooch_number.match(/\b22-miss-pon-pob\b/) || form.tooch_number.match(/\b22-meb\b/) || form.tooch_number.match(/\b22-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
    {form.tooch_number.includes("22-poc") || form.tooch_number.includes("22-ipx-poc") || form.tooch_number.match(/\b22-pob\b/) || form.tooch_number.match(/\b22-miss-pon-pob\b/) ?
      <g>
        {/* Atas */}
        {form.tooch_number.includes("22L-fis") || form.tooch_number.includes("22L-amf") || form.tooch_number.includes("22L-cof") || form.tooch_number.includes("22L-car") ?
          <>
            {form.tooch_number.includes("22L-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="22L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 22L"
                  points="0,0 40,0 30,20 10,20"
                  style={{ fill: 'url(#22L-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("22-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />   
              </svg>
            }

            {form.tooch_number.includes("22L-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 22L"
                points="0,0 40,0 30,20 10,20"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("22-Labial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("22L-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="22L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 22L"
                  points="0,0 40,0 30,20 10,20"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("22-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("22L-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 22L"
                points="0,0 40,0 30,20 10,20"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("22-Labial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          
          </>
          :
          <svg width="40" height="40">
            <defs>
              <pattern id="22L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 22L"
              points="0,0 40,0 30,20 10,20"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("22-Labial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {/* Bawah */}
        {form.tooch_number.includes("22P-fis") || form.tooch_number.includes("22P-amf") || form.tooch_number.includes("22P-cof") || form.tooch_number.includes("22P-car") ?
          <>
            {form.tooch_number.includes("22P-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="22P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>

                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 22P"
                  points="30,20 40,40 0,40 10,20"
                  style={{ fill: 'url(#22P-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("22-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("22P-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 22P"
                points="30,20 40,40 0,40 10,20"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("22-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("22P-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="22P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 22P"
                  points="30,20 40,40 0,40 10,20"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("22-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("22P-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 22P"
                points="30,20 40,40 0,40 10,20"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("22-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          </>
          :
          <svg width="40" height="40">
            <defs>
              <pattern id="22P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 22P"
              points="30,20 40,40 0,40 10,20"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("22-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

          {/* Kanan */}
          {form.tooch_number.includes("22D-fis") || form.tooch_number.includes("22D-amf") || form.tooch_number.includes("22D-cof") || form.tooch_number.includes("22D-car") ?
          <>
            {form.tooch_number.includes("22D-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="22D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 22D"
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: 'url(#22D-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("22-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("22D-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 22D"
                points="30,20 40,0 40,40 35,30"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("22-Distal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("22D-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="22D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 22D"
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("22-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }
          
            {form.tooch_number.includes("22D-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 22D"
                points="30,20 40,0 40,40 35,30"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("22-Distal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          
          </>
          :
          <svg width="40" height="40">
            <defs>
              <pattern id="22D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 22D"
              points="30,20 40,0 40,40 35,30"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("22-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
          }

        {/* Kiri */}
        {form.tooch_number.includes("22M-fis") || form.tooch_number.includes("22M-amf") || form.tooch_number.includes("22M-cof") || form.tooch_number.includes("22M-car") ?
            <>
              {form.tooch_number.includes("22M-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="22M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 22M"
                    points="0,0 5,10 10,20 0,40"
                    style={{ fill: 'url(#22M-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("22-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("22M-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 22M"
                  points="0,0 5,10 10,20 0,40"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("22-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("22M-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="22M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 22M"
                    points="0,0 5,10 10,20 0,40"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("22-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("22M-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 22M"
                  points="0,0 5,10 10,20 0,40"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("22-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

            </>
            :
            <svg width="40" height="40">
              <defs>
                <pattern id="22M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                  <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                </pattern>
              </defs>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 22M"
                points="0,0 5,10 10,20 0,40"
                style={{ fill: '#007610', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("22-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            </svg>
          }

        {/* cfr */}
        {form.tooch_number.includes("22-cfr") &&
          <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
            #
          </text>
        }

        {/* rrx */}
        {form.tooch_number.includes("22-rrx") &&    
        <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 22-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
          v
        </text>
        }
              
        {/* mis */}
        {form.tooch_number.includes("22-mis") &&    
        <>  
          <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
          <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
        </> 
        }
                
      </g>
        :
      <g>
        {/* Atas */}
        {form.tooch_number.includes("22L-fis") || form.tooch_number.includes("22L-amf") || form.tooch_number.includes("22L-cof") || form.tooch_number.includes("22L-car") ?
          <>
            {form.tooch_number.includes("22L-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="22L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 22L"
                  points="0,0 40,0 30,20 10,20"
                  style={{ fill: 'url(#22L-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("22-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("22L-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 22L"
                points="0,0 40,0 30,20 10,20"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("22-Labial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("22L-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="22L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 22L"
                  points="0,0 40,0 30,20 10,20"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("22-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("22L-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 22L"
                points="0,0 40,0 30,20 10,20"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("22-Labial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          
          </>
          :
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 22L"
            points="0,0 40,0 30,20 10,20"
            style={{ fill: 'white', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("22-Labial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {/* Bawah */}
        {form.tooch_number.includes("22P-fis") || form.tooch_number.includes("22P-amf") || form.tooch_number.includes("22P-cof") || form.tooch_number.includes("22P-car") ?
          <>
            {form.tooch_number.includes("22P-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="22P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>

                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 22P"
                  points="30,20 40,40 0,40 10,20"
                  style={{ fill: 'url(#22P-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("22-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("22P-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 22P"
                points="30,20 40,40 0,40 10,20"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("22-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("22P-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="22P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 22P"
                  points="30,20 40,40 0,40 10,20"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("22-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("22P-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 22P"
                points="30,20 40,40 0,40 10,20"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("22-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          </>
          :
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 22P"
            points="30,20 40,40 0,40 10,20"
            style={{ fill: 'white', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("22-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {/* Kanan */}
          {form.tooch_number.includes("22D-fis") || form.tooch_number.includes("22D-amf") || form.tooch_number.includes("22D-cof") || form.tooch_number.includes("22D-car") ?
          <>
            {form.tooch_number.includes("22D-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="22D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 22D"
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: 'url(#22D-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("22-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("22D-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 22D"
                points="30,20 40,0 40,40 35,30"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("22-Distal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("22D-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="22D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 22D"
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("22-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }
          
            {form.tooch_number.includes("22D-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 22D"
                points="30,20 40,0 40,40 35,30"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("22-Distal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          
          </>
          :
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 22D"
            points="30,20 40,0 40,40 35,30"
            style={{ fill: 'white', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("22-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

      {/* Kiri */}
        {form.tooch_number.includes("22M-fis") || form.tooch_number.includes("22M-amf") || form.tooch_number.includes("22M-cof") || form.tooch_number.includes("22M-car") ?
        <>
          {form.tooch_number.includes("22M-fis") &&
            <svg width="40" height="40">
              <defs>
                <pattern id="22M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                  <rect width="8" height="8" fill="#ffb8c6" />
                  <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                </pattern>
              </defs>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 22M"
                points="0,0 5,10 10,20 0,40"
                style={{ fill: 'url(#22M-fis)', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("22-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            </svg>
          }

          {form.tooch_number.includes("22M-amf") &&
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 22M"
              points="0,0 5,10 10,20 0,40"
              style={{ fill: 'black', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("22-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          }

          {form.tooch_number.includes("22M-cof") &&
            <svg width="40" height="40">
              <defs>
                <pattern id="22M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                  <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                </pattern>
              </defs>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 22M"
                points="0,0 5,10 10,20 0,40"
                style={{ fill: '#007610', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("22-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            </svg>
          }

          {form.tooch_number.includes("22M-car") &&
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 22M"
              points="0,0 5,10 10,20 0,40"
              style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
              onMouseEnter={() => setInfoGigi("22-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          }

        </>
        :
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 22M"
          points="0,0 5,10 10,20 0,40"
          style={{ fill: 'white', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("22-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

        {/* cfr */}
        {form.tooch_number.includes("22-cfr") &&
          <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
            #
          </text>
        }
        
        {form.tooch_number.includes("22-rrx") &&    
        <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 22-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
          v
        </text>
        }
        
        {form.tooch_number.includes("22-mis") &&    
        <>  
          <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
          <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
        </> 
        }
      </g>
    }    
          
    </svg>
        
    {form.tooch_number.includes("22-nvt") &&   
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
      <svg width={42} height={42}>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 22"
          points="21,2 41,32 1,32" 
          style={{ fill: 'white', stroke: 'black', strokeWidth: 3 }}
          onMouseEnter={() => setInfoGigi('22')}
          onMouseLeave={() => setInfoGigi('XX-X')}
        />
      </svg> 
    </div>
    }  

    {form.tooch_number.includes("22-rct") &&   
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
    <svg width={42} height={42}>
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 22"
        points="21,2 41,32 1,32" 
        style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
        onMouseEnter={() => setInfoGigi('22')}
        onMouseLeave={() => setInfoGigi('XX-X')}
      />
      </svg>
    </div>
    } 
        
    {form.tooch_number.includes("22B-amf-rct") || form.tooch_number.includes("22P-amf-rct") || form.tooch_number.includes("22M-amf-rct") || form.tooch_number.includes("22D-amf-rct") || form.tooch_number.includes("22L-amf-rct") ||
    form.tooch_number.includes("22B-cof-rct") || form.tooch_number.includes("22P-cof-rct") || form.tooch_number.includes("22M-cof-rct") || form.tooch_number.includes("22D-cof-rct") || form.tooch_number.includes("22L-cof-rct") ||
    form.tooch_number.includes("22-fmc-rct") ||
    form.tooch_number.includes("22-poc-rct") ?
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
    <svg width={42} height={42}>
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 22B"
        points="21,2 41,32 1,32" 
        style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
        onMouseEnter={() => setInfoGigi('22-Bukal')}
        onMouseLeave={() => setInfoGigi('XX-X')}
      />
      </svg>
    </div>
      :
    <></>
    } 
        </div>   
        
        <div style={{ width: "42px", margin: "1px" }}>
    {form.tooch_number.includes("23-une") || form.tooch_number.includes("23-non") || form.tooch_number.includes("23-pre") || form.tooch_number.includes("23-ano") || form.tooch_number.includes("23-ipx-poc") || form.tooch_number.includes("23-mis-prd-acr") || form.tooch_number.includes("23-mis-fld-acr") ?
      <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
        {form.tooch_number.includes("23-une") &&
          <div style={{ position: "absolute", color: "black" }}>UNE</div>
        }
        {form.tooch_number.includes("23-non") &&
          <div style={{ position: "absolute", color: "black" }}>NON</div>
        }      
        {form.tooch_number.includes("23-pre") &&
          <div style={{ position: "absolute", color: "black" }}>PRE</div>
        }      
        {form.tooch_number.includes("23-ano") &&
          <div style={{ position: "absolute", color: "black" }}>ANO</div>
        }
        {form.tooch_number.includes("23-ipx-poc") &&
          <div style={{ position: "absolute", color: "black" }}>IPX</div>
        }
        {form.tooch_number.includes("23-mis-prd-acr") &&
          <div style={{ position: "absolute", color: "black" }}>PRD</div>
        } 
        {form.tooch_number.includes("23-mis-fld-acr") &&
          <div style={{ position: "absolute", color: "black" }}>FLD</div>
        }      
      </div>
      :
      <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
      </div>
    }

          {form.tooch_number.includes("23-meb-24-miss-pon-meb-25-meb")
            ||form.tooch_number.includes("23-meb-24-miss-pon-meb-25-miss-pon-meb-26-meb")
            ||form.tooch_number.includes("23-meb-24-miss-pon-meb-25-miss-pon-meb-26-miss-pon-meb-27-meb")
            ||form.tooch_number.includes("23-meb-24-miss-pon-meb-25-miss-pon-meb-26-miss-pon-meb-27-miss-pon-meb-28-meb") ?
              <>
            {/* meb 18 - 16 */}
            {form.tooch_number.includes("23-meb-24-miss-pon-meb-25-meb") &&
              <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">

                  {/* Garis ke samping dan ke bawah (L-shape) */}
                  <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
                  <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                  <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                </svg>
              </div>
            }
                                    
            {/* meb 18 - 15 */}
            {form.tooch_number.includes("23-meb-24-miss-pon-meb-25-miss-pon-meb-26-meb") &&
              <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">
                {/* Garis ke samping dan ke bawah (L-shape) */}
                <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              </svg>
            </div>
            }
            
            {/* meb 18 - 14 */} 
            {form.tooch_number.includes("23-meb-24-miss-pon-meb-25-miss-pon-meb-26-miss-pon-meb-27-meb") &&      
            <div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
            <svg width="100%" height="20vh">
              {/* Garis ke samping dan ke bawah (L-shape) */}
              <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            </svg>
            </div> 
            }
            
            {/* meb 18 - 13 */}
            {form.tooch_number.includes("23-meb-24-miss-pon-meb-25-miss-pon-meb-26-miss-pon-meb-27-miss-pon-meb-28-meb") &&      
              <div style={{ width: "259px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">

                {/* Garis ke samping dan ke bawah (L-shape) */}
                <line x1="20" y1="1.5" x2="241" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="241" y1="0" x2="241" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              </svg>
            </div> 
              }   
              </>
                :
              <></>
      }
      
      {form.tooch_number.includes("23-pob-24-miss-pon-pob-25-pob")
            ||form.tooch_number.includes("23-pob-24-miss-pon-pob-25-miss-pon-pob-26-pob")
            ||form.tooch_number.includes("23-pob-24-miss-pon-pob-25-miss-pon-pob-26-miss-pon-pob-27-pob")
            ||form.tooch_number.includes("23-pob-24-miss-pon-pob-25-miss-pon-pob-26-miss-pon-pob-27-miss-pon-pob-28-pob") ?
              <>
            {/* pob 18 - 16 */}
            {form.tooch_number.includes("23-pob-24-miss-pon-pob-25-pob") &&
              <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">

                  {/* Garis ke samping dan ke bawah (L-shape) */}
                  <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
                  <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                  <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                </svg>
              </div>
            }
                                    
            {/* pob 18 - 15 */}
            {form.tooch_number.includes("23-pob-24-miss-pon-pob-25-miss-pon-pob-26-pob") &&
              <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">
                {/* Garis ke samping dan ke bawah (L-shape) */}
                <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              </svg>
            </div>
            }
            
            {/* pob 18 - 14 */} 
            {form.tooch_number.includes("23-pob-24-miss-pon-pob-25-miss-pon-pob-26-miss-pon-pob-27-pob") &&      
            <div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
            <svg width="100%" height="20vh">

              {/* Garis ke samping dan ke bawah (L-shape) */}
              <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            </svg>
            </div> 
            }
            
            {/* pob 18 - 13 */}
            {form.tooch_number.includes("23-pob-24-miss-pon-pob-25-miss-pon-pob-26-miss-pon-pob-27-miss-pon-pob-28-pob") &&      
              <div style={{ width: "259px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">

                {/* Garis ke samping dan ke bawah (L-shape) */}
                <line x1="20" y1="1.5" x2="241" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="241" y1="0" x2="241" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              </svg>
            </div> 
              }   
              </>
                :
              <></>
            }
      

    <div style={{ textAlign: "center" }}>23</div>
    <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("23-fmc") || form.tooch_number.includes("23-poc") || form.tooch_number.includes("23-ipx-poc") || form.tooch_number.match(/\b23-pob\b/) || form.tooch_number.match(/\b23-miss-pon-pob\b/) || form.tooch_number.match(/\b23-meb\b/) || form.tooch_number.match(/\b23-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
    {form.tooch_number.includes("23-poc") || form.tooch_number.includes("23-ipx-poc") || form.tooch_number.match(/\b23-pob\b/) || form.tooch_number.match(/\b23-miss-pon-pob\b/) ?
      <g>
        {/* Atas */}
        {form.tooch_number.includes("23L-fis") || form.tooch_number.includes("23L-amf") || form.tooch_number.includes("23L-cof") || form.tooch_number.includes("23L-car") ?
          <>
            {form.tooch_number.includes("23L-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="23L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 23L"
                  points="0,0 40,0 30,20 10,20"
                  style={{ fill: 'url(#23L-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("23-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />   
              </svg>
            }

            {form.tooch_number.includes("23L-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 23L"
                points="0,0 40,0 30,20 10,20"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("23-Labial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("23L-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="23L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 23L"
                  points="0,0 40,0 30,20 10,20"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("23-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("23L-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 23L"
                points="0,0 40,0 30,20 10,20"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("23-Labial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          
          </>
          :
          <svg width="40" height="40">
            <defs>
              <pattern id="23L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 23L"
              points="0,0 40,0 30,20 10,20"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("23-Labial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {/* Bawah */}
        {form.tooch_number.includes("23P-fis") || form.tooch_number.includes("23P-amf") || form.tooch_number.includes("23P-cof") || form.tooch_number.includes("23P-car") ?
          <>
            {form.tooch_number.includes("23P-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="23P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>

                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 23P"
                  points="30,20 40,40 0,40 10,20"
                  style={{ fill: 'url(#23P-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("23-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("23P-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 23P"
                points="30,20 40,40 0,40 10,20"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("23-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("23P-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="23P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 23P"
                  points="30,20 40,40 0,40 10,20"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("23-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("23P-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 23P"
                points="30,20 40,40 0,40 10,20"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("23-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          </>
          :
          <svg width="40" height="40">
            <defs>
              <pattern id="23P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 23P"
              points="30,20 40,40 0,40 10,20"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("23-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

          {/* Kanan */}
          {form.tooch_number.includes("23D-fis") || form.tooch_number.includes("23D-amf") || form.tooch_number.includes("23D-cof") || form.tooch_number.includes("23D-car") ?
          <>
            {form.tooch_number.includes("23D-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="23D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 23D"
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: 'url(#23D-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("23-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("23D-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 23D"
                points="30,20 40,0 40,40 35,30"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("23-Distal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("23D-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="23D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 23D"
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("23-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }
          
            {form.tooch_number.includes("23D-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 23D"
                points="30,20 40,0 40,40 35,30"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("23-Distal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          
          </>
          :
          <svg width="40" height="40">
            <defs>
              <pattern id="23D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 23D"
              points="30,20 40,0 40,40 35,30"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("23-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
          }

        {/* Kiri */}
        {form.tooch_number.includes("23M-fis") || form.tooch_number.includes("23M-amf") || form.tooch_number.includes("23M-cof") || form.tooch_number.includes("23M-car") ?
            <>
              {form.tooch_number.includes("23M-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="23M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 23M"
                    points="0,0 5,10 10,20 0,40"
                    style={{ fill: 'url(#23M-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("23-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("23M-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 23M"
                  points="0,0 5,10 10,20 0,40"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("23-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("23M-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="23M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 23M"
                    points="0,0 5,10 10,20 0,40"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("23-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("23M-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 23M"
                  points="0,0 5,10 10,20 0,40"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("23-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

            </>
            :
            <svg width="40" height="40">
              <defs>
                <pattern id="23M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                  <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                </pattern>
              </defs>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 23M"
                points="0,0 5,10 10,20 0,40"
                style={{ fill: '#007610', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("23-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            </svg>
          }

        {/* cfr */}
        {form.tooch_number.includes("23-cfr") &&
          <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
            #
          </text>
        }

        {/* rrx */}
        {form.tooch_number.includes("23-rrx") &&    
        <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 23-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
          v
        </text>
        }
              
        {/* mis */}
        {form.tooch_number.includes("23-mis") &&    
        <>  
          <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
          <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
        </> 
        }
                
      </g>
        :
      <g>
        {/* Atas */}
        {form.tooch_number.includes("23L-fis") || form.tooch_number.includes("23L-amf") || form.tooch_number.includes("23L-cof") || form.tooch_number.includes("23L-car") ?
          <>
            {form.tooch_number.includes("23L-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="23L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 23L"
                  points="0,0 40,0 30,20 10,20"
                  style={{ fill: 'url(#23L-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("23-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("23L-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 23L"
                points="0,0 40,0 30,20 10,20"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("23-Labial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("23L-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="23L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 23L"
                  points="0,0 40,0 30,20 10,20"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("23-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("23L-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 23L"
                points="0,0 40,0 30,20 10,20"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("23-Labial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          
          </>
          :
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 23L"
            points="0,0 40,0 30,20 10,20"
            style={{ fill: 'white', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("23-Labial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {/* Bawah */}
        {form.tooch_number.includes("23P-fis") || form.tooch_number.includes("23P-amf") || form.tooch_number.includes("23P-cof") || form.tooch_number.includes("23P-car") ?
          <>
            {form.tooch_number.includes("23P-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="23P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>

                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 23P"
                  points="30,20 40,40 0,40 10,20"
                  style={{ fill: 'url(#23P-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("23-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("23P-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 23P"
                points="30,20 40,40 0,40 10,20"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("23-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("23P-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="23P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 23P"
                  points="30,20 40,40 0,40 10,20"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("23-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("23P-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 23P"
                points="30,20 40,40 0,40 10,20"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("23-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          </>
          :
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 23P"
            points="30,20 40,40 0,40 10,20"
            style={{ fill: 'white', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("23-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {/* Kanan */}
          {form.tooch_number.includes("23D-fis") || form.tooch_number.includes("23D-amf") || form.tooch_number.includes("23D-cof") || form.tooch_number.includes("23D-car") ?
          <>
            {form.tooch_number.includes("23D-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="23D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 23D"
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: 'url(#23D-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("23-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("23D-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 23D"
                points="30,20 40,0 40,40 35,30"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("23-Distal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("23D-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="23D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 23D"
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("23-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }
          
            {form.tooch_number.includes("23D-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 23D"
                points="30,20 40,0 40,40 35,30"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("23-Distal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          
          </>
          :
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 23D"
            points="30,20 40,0 40,40 35,30"
            style={{ fill: 'white', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("23-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

      {/* Kiri */}
        {form.tooch_number.includes("23M-fis") || form.tooch_number.includes("23M-amf") || form.tooch_number.includes("23M-cof") || form.tooch_number.includes("23M-car") ?
        <>
          {form.tooch_number.includes("23M-fis") &&
            <svg width="40" height="40">
              <defs>
                <pattern id="23M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                  <rect width="8" height="8" fill="#ffb8c6" />
                  <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                </pattern>
              </defs>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 23M"
                points="0,0 5,10 10,20 0,40"
                style={{ fill: 'url(#23M-fis)', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("23-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            </svg>
          }

          {form.tooch_number.includes("23M-amf") &&
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 23M"
              points="0,0 5,10 10,20 0,40"
              style={{ fill: 'black', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("23-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          }

          {form.tooch_number.includes("23M-cof") &&
            <svg width="40" height="40">
              <defs>
                <pattern id="23M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                  <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                </pattern>
              </defs>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 23M"
                points="0,0 5,10 10,20 0,40"
                style={{ fill: '#007610', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("23-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            </svg>
          }

          {form.tooch_number.includes("23M-car") &&
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 23M"
              points="0,0 5,10 10,20 0,40"
              style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
              onMouseEnter={() => setInfoGigi("23-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          }

        </>
        :
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 23M"
          points="0,0 5,10 10,20 0,40"
          style={{ fill: 'white', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("23-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

        {/* cfr */}
        {form.tooch_number.includes("23-cfr") &&
          <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
            #
          </text>
        }
        
        {form.tooch_number.includes("23-rrx") &&    
        <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 23-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
          v
        </text>
        }
        
        {form.tooch_number.includes("23-mis") &&    
        <>  
          <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
          <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
        </> 
        }
      </g>
    }    
          
    </svg>
        
    {form.tooch_number.includes("23-nvt") &&   
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
      <svg width={42} height={42}>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 23"
          points="21,2 41,32 1,32" 
          style={{ fill: 'white', stroke: 'black', strokeWidth: 3 }}
          onMouseEnter={() => setInfoGigi('23')}
          onMouseLeave={() => setInfoGigi('XX-X')}
        />
      </svg> 
    </div>
    }  

    {form.tooch_number.includes("23-rct") &&   
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
    <svg width={42} height={42}>
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 23"
        points="21,2 41,32 1,32" 
        style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
        onMouseEnter={() => setInfoGigi('23')}
        onMouseLeave={() => setInfoGigi('XX-X')}
      />
      </svg>
    </div>
    } 
        
    {form.tooch_number.includes("23B-amf-rct") || form.tooch_number.includes("23P-amf-rct") || form.tooch_number.includes("23M-amf-rct") || form.tooch_number.includes("23D-amf-rct") || form.tooch_number.includes("23L-amf-rct") ||
    form.tooch_number.includes("23B-cof-rct") || form.tooch_number.includes("23P-cof-rct") || form.tooch_number.includes("23M-cof-rct") || form.tooch_number.includes("23D-cof-rct") || form.tooch_number.includes("23L-cof-rct") ||
    form.tooch_number.includes("23-fmc-rct") ||
    form.tooch_number.includes("23-poc-rct") ?
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
    <svg width={42} height={42}>
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 23B"
        points="21,2 41,32 1,32" 
        style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
        onMouseEnter={() => setInfoGigi('23-Bukal')}
        onMouseLeave={() => setInfoGigi('XX-X')}
      />
      </svg>
    </div>
      :
    <></>
    } 

        </div>   
            
        <div style={{ width: "42px", margin: "1px" }}>
    {form.tooch_number.includes("24-une") || form.tooch_number.includes("24-non") || form.tooch_number.includes("24-pre") || form.tooch_number.includes("24-ano") || form.tooch_number.includes("24-ipx-poc") || form.tooch_number.includes("24-mis-prd-acr") || form.tooch_number.includes("24-mis-fld-acr") ?
      <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
        {form.tooch_number.includes("24-une") &&
          <div style={{ position: "absolute", color: "black" }}>UNE</div>
        }
        {form.tooch_number.includes("24-non") &&
          <div style={{ position: "absolute", color: "black" }}>NON</div>
        }      
        {form.tooch_number.includes("24-pre") &&
          <div style={{ position: "absolute", color: "black" }}>PRE</div>
        }      
        {form.tooch_number.includes("24-ano") &&
          <div style={{ position: "absolute", color: "black" }}>ANO</div>
        }
        {form.tooch_number.includes("24-ipx-poc") &&
          <div style={{ position: "absolute", color: "black" }}>IPX</div>
        }
        {form.tooch_number.includes("24-mis-prd-acr") &&
          <div style={{ position: "absolute", color: "black" }}>PRD</div>
        } 
        {form.tooch_number.includes("24-mis-fld-acr") &&
          <div style={{ position: "absolute", color: "black" }}>FLD</div>
        }      
      </div>
      :
      <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
      </div>
    }

    {form.tooch_number.includes("24-meb-25-miss-pon-meb-26-meb")
    ||form.tooch_number.includes("24-meb-25-miss-pon-meb-26-miss-pon-meb-27-meb")
    ||form.tooch_number.includes("24-meb-25-miss-pon-meb-26-miss-pon-meb-27-miss-pon-meb-28-meb") ?
    <>
    {/* meb 24 - 26 */}
    {form.tooch_number.includes("24-meb-25-miss-pon-meb-26-meb") &&
      <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
      <svg width="100%" height="20vh">

          {/* Garis ke samping dan ke bawah (L-shape) */}
          <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
          <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
          <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        </svg>
      </div>
    }
                            
    {/* meb 24 - 27 */}
    {form.tooch_number.includes("24-meb-25-miss-pon-meb-26-miss-pon-meb-27-meb") &&
      <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
      <svg width="100%" height="20vh">
        {/* Garis ke samping dan ke bawah (L-shape) */}
        <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
      </svg>
    </div>
    }

    {/* meb 24 - 28 */} 
    {form.tooch_number.includes("24-meb-25-miss-pon-meb-26-miss-pon-meb-27-miss-pon-meb-28-meb") &&      
    <div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
    <svg width="100%" height="20vh">
      {/* Garis ke samping dan ke bawah (L-shape) */}
      <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
      <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
      <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    </svg>
    </div> 
    }
        
      </>
        :
      <></>
      }
      
      {form.tooch_number.includes("24-pob-25-miss-pon-pob-26-pob")
    ||form.tooch_number.includes("24-pob-25-miss-pon-pob-26-miss-pon-pob-27-pob")
    ||form.tooch_number.includes("24-pob-25-miss-pon-pob-26-miss-pon-pob-27-miss-pon-pob-28-pob") ?
    <>
    {/* pob 24 - 26 */}
    {form.tooch_number.includes("24-pob-25-miss-pon-pob-26-pob") &&
      <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
      <svg width="100%" height="20vh">

          {/* Garis ke samping dan ke bawah (L-shape) */}
          <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
          <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
          <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        </svg>
      </div>
    }
                            
    {/* pob 24 - 27 */}
    {form.tooch_number.includes("24-pob-25-miss-pon-pob-26-miss-pon-pob-27-pob") &&
     <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
     <svg width="100%" height="20vh">


        {/* Garis ke samping dan ke bawah (L-shape) */}
        <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
      </svg>
    </div>
    }

    {/* pob 24 - 28 */} 
    {form.tooch_number.includes("24-pob-25-miss-pon-pob-26-miss-pon-pob-27-miss-pon-pob-28-pob") &&      
    
<div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">

      {/* Garis ke samping dan ke bawah (L-shape) */}
      <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
      <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
      <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    </svg>
    </div> 
    }
        
      </>
        :
      <></>
    }
          
      <div style={{ textAlign: "center" }}>24</div>
      <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("24-fmc") || form.tooch_number.includes("24-poc") || form.tooch_number.includes("24-ipx-poc") || form.tooch_number.match(/\b24-pob\b/) || form.tooch_number.match(/\b24-miss-pon-pob\b/) || form.tooch_number.match(/\b24-meb\b/) || form.tooch_number.match(/\b24-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
        {form.tooch_number.includes("24-poc") || form.tooch_number.includes("24-ipx-poc") || form.tooch_number.match(/\b24-pob\b/) || form.tooch_number.match(/\b24-miss-pon-pob\b/) ?
          <g>
            {/* Atas */}
            {form.tooch_number.includes("24B-fis") || form.tooch_number.includes("24B-amf") || form.tooch_number.includes("24B-cof") || form.tooch_number.includes("24B-car") ?
              <>
                {form.tooch_number.includes("24B-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="24B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 24B"
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'url(#24B-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("24-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("24B-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 24B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("24-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("24B-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="24B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 24B"
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("24-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
      
                {form.tooch_number.includes("24B-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 24B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("24-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
              
              </>
              :
            <svg width="40" height="40">
              <defs>
                <pattern id="24B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                  <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                </pattern>
              </defs>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 24B"
                points="0,0 40,0 30,10 10,10"
                style={{ fill: '#007610', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("24-Bukal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            </svg>
            }

            {/* Bawah */}
            {form.tooch_number.includes("24P-fis") || form.tooch_number.includes("24P-amf") || form.tooch_number.includes("24P-cof") || form.tooch_number.includes("24P-car") ?
              <>
                {form.tooch_number.includes("24P-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="24P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>

                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 24P"
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'url(#24P-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("24-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("24P-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 24P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("24-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("24P-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="24P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 24P"
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("24-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }

                {form.tooch_number.includes("24P-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 24P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("24-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
              </>
              :
              <svg width="40" height="40">
                <defs>
                  <pattern id="24P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 24P"
                  points="10,30 30,30 40,40 0,40"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("24-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {/* Kanan */}
              {form.tooch_number.includes("24D-fis") || form.tooch_number.includes("24D-amf") || form.tooch_number.includes("24D-cof") || form.tooch_number.includes("24D-car") ?
              <>
                {form.tooch_number.includes("24D-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="24D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 24D"
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'url(#24D-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("24-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }

                {form.tooch_number.includes("24D-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 24D"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("24-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }

                {form.tooch_number.includes("24D-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="24D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 24D"
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("24-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
              
                {form.tooch_number.includes("24D-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 24D"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("24-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
              
              </>
              :
              <svg width="40" height="40">
                <defs>
                  <pattern id="24D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 24D"
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("24-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {/* Kiri */}
            {form.tooch_number.includes("24M-fis") || form.tooch_number.includes("24M-amf") || form.tooch_number.includes("24M-cof") || form.tooch_number.includes("24M-car") ?
            <>
              {form.tooch_number.includes("24M-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="24M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 24M"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: 'url(#24M-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("24-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("24M-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 24M"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("24-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("24M-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="24M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 24M"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("24-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("24M-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 24M"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("24-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

            </>
            :
            <svg width="40" height="40">
              <defs>
                <pattern id="24M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                  <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                </pattern>
              </defs>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 24M"
                points="0,0 10,10 10,30 0,40"
                style={{ fill: '#007610', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("24-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            </svg>
            }

            {/* center */}
            {form.tooch_number.includes("24O-fis") || form.tooch_number.includes("24O-amf") || form.tooch_number.includes("24O-cof") || form.tooch_number.includes("24O-car") ?
              <>
                {form.tooch_number.includes("24O-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="24O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 24O"
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'url(#24O-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("24-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("24O-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 24O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("24-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("24O-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="24O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 24O"
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("24-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }

                {form.tooch_number.includes("24O-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 24O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("24-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }

              </>
              :
              <svg width="40" height="40">
                <defs>
                  <pattern id="24O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 24O"
                  points="10,10 30,10 30,30 10,30"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("24-Oklusal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {/* cfr */}
            {form.tooch_number.includes("24-cfr") &&
              <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
                #
              </text>
            }

            {/* rrx */}
            {form.tooch_number.includes("24-rrx") &&    
            <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 24-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
              v
            </text>
            }
                  
            {/* mis */}
            {form.tooch_number.includes("24-mis") &&    
            <>  
              <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
              <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
            </> 
            }
                    
          </g>
            :
          <g>
            {/* Atas */}
            {form.tooch_number.includes("24B-fis") || form.tooch_number.includes("24B-amf") || form.tooch_number.includes("24B-cof") || form.tooch_number.includes("24B-car") ?
              <>
                {form.tooch_number.includes("24B-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="24B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 24B"
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'url(#24B-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("24-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("24B-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 24B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("24-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("24B-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="24B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 24B"
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("24-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
      
                {form.tooch_number.includes("24B-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 24B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("24-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
              
              </>
              :
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 24B"
                points="0,0 40,0 30,10 10,10"
                style={{ fill: 'white', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("24-Bukal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {/* Bawah */}
            {form.tooch_number.includes("24P-fis") || form.tooch_number.includes("24P-amf") || form.tooch_number.includes("24P-cof") || form.tooch_number.includes("24P-car") ?
              <>
                {form.tooch_number.includes("24P-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="24P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>

                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 24P"
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'url(#24P-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("24-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("24P-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 24P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("24-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("24P-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="24P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 24P"
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("24-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }

                {form.tooch_number.includes("24P-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 24P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("24-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
              </>
              :
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 24P"
                points="10,30 30,30 40,40 0,40"
                style={{ fill: 'white', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("24-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {/* Kanan */}
            {form.tooch_number.includes("24D-fis") || form.tooch_number.includes("24D-amf") || form.tooch_number.includes("24D-cof") || form.tooch_number.includes("24D-car") ?
            <>
              {form.tooch_number.includes("24D-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="24D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 24D"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: 'url(#24D-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("24-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("24D-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 24D"
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("24-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("24D-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="24D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 24D"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("24-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }
            
              {form.tooch_number.includes("24D-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 24D"
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("24-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }
            
            </>
            :
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 24D"
              points="30,10 40,0 40,40 30,30"
              style={{ fill: 'white', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("24-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
            }

            {/* Kiri */}
            {form.tooch_number.includes("24M-fis") || form.tooch_number.includes("24M-amf") || form.tooch_number.includes("24M-cof") || form.tooch_number.includes("24M-car") ?
            <>
              {form.tooch_number.includes("24M-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="24M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 24M"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: 'url(#24M-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("24-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("24M-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 24M"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("24-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("24M-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="24M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 24M"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("24-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("24M-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 24M"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("24-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

            </>
            :
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 24M"
              points="0,0 10,10 10,30 0,40"
              style={{ fill: 'white', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("24-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
            }

            {/* center */}
            {form.tooch_number.includes("24O-fis") || form.tooch_number.includes("24O-amf") || form.tooch_number.includes("24O-cof") || form.tooch_number.includes("24O-car") ?
              <>
                {form.tooch_number.includes("24O-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="24O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 24O"
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'url(#24O-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("24-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("24O-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 24O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("24-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("24O-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="24O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 24O"
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("24-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }

                {form.tooch_number.includes("24O-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 24O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("24-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }

              </>
              :
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 24O"
                points="10,10 30,10 30,30 10,30"
                style={{ fill: 'white', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("24-Oklusal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {/* cfr */}
            {form.tooch_number.includes("24-cfr") &&
              <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
                #
              </text>
            }
            
            {form.tooch_number.includes("24-rrx") &&    
            <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 24-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
              v
            </text>
            }
            
            {form.tooch_number.includes("24-mis") &&    
            <>  
              <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
              <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
            </> 
            }
          </g>
        }      
      </svg>
            
      {form.tooch_number.includes("24-nvt") &&   
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
        <svg width={42} height={42}>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 24"
            points="21,2 41,32 1,32" 
            style={{ fill: 'white', stroke: 'black', strokeWidth: 3 }}
            onMouseEnter={() => setInfoGigi('24')}
            onMouseLeave={() => setInfoGigi('XX-X')}
          />
        </svg> 
      </div>
      }  
      
      {form.tooch_number.includes("24-rct") &&   
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
      <svg width={42} height={42}>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 24"
          points="21,2 41,32 1,32" 
          style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
          onMouseEnter={() => setInfoGigi('24')}
          onMouseLeave={() => setInfoGigi('XX-X')}
        />
        </svg>
      </div>
      } 
            
      {form.tooch_number.includes("24B-amf-rct") || form.tooch_number.includes("24P-amf-rct") || form.tooch_number.includes("24M-amf-rct") || form.tooch_number.includes("24D-amf-rct") || form.tooch_number.includes("24O-amf-rct") ||
        form.tooch_number.includes("24B-cof-rct") || form.tooch_number.includes("24P-cof-rct") || form.tooch_number.includes("24M-cof-rct") || form.tooch_number.includes("24D-cof-rct") || form.tooch_number.includes("24O-cof-rct") ||
        form.tooch_number.includes("24-fmc-rct") ||
        form.tooch_number.includes("24-poc-rct") ?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
        <svg width={42} height={42}>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 24B"
            points="21,2 41,32 1,32" 
            style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
            onMouseEnter={() => setInfoGigi('24-Bukal')}
            onMouseLeave={() => setInfoGigi('XX-X')}
          />
          </svg>
        </div>
          :
        <></>
      } 

        </div>

        <div style={{ width: "42px", margin: "1px" }}>
    {form.tooch_number.includes("25-une") || form.tooch_number.includes("25-non") || form.tooch_number.includes("25-pre") || form.tooch_number.includes("25-ano") || form.tooch_number.includes("25-ipx-poc") || form.tooch_number.includes("25-mis-prd-acr") || form.tooch_number.includes("25-mis-fld-acr") ?
      <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
        {form.tooch_number.includes("25-une") &&
          <div style={{ position: "absolute", color: "black" }}>UNE</div>
        }
        {form.tooch_number.includes("25-non") &&
          <div style={{ position: "absolute", color: "black" }}>NON</div>
        }      
        {form.tooch_number.includes("25-pre") &&
          <div style={{ position: "absolute", color: "black" }}>PRE</div>
        }      
        {form.tooch_number.includes("25-ano") &&
          <div style={{ position: "absolute", color: "black" }}>ANO</div>
        }
        {form.tooch_number.includes("25-ipx-poc") &&
          <div style={{ position: "absolute", color: "black" }}>IPX</div>
        }
        {form.tooch_number.includes("25-mis-prd-acr") &&
          <div style={{ position: "absolute", color: "black" }}>PRD</div>
        } 
        {form.tooch_number.includes("25-mis-fld-acr") &&
          <div style={{ position: "absolute", color: "black" }}>FLD</div>
        }      
      </div>
      :
      <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
      </div>
    }

    {form.tooch_number.includes("25-meb-26-miss-pon-meb-27-meb")
    ||form.tooch_number.includes("25-meb-26-miss-pon-meb-27-miss-pon-meb-28-meb") ?
    <>
    {/* meb 25 - 27 */}
    {form.tooch_number.includes("25-meb-26-miss-pon-meb-27-meb") &&
      <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
      <svg width="100%" height="20vh">
          {/* Garis ke samping dan ke bawah (L-shape) */}
          <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
          <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
          <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        </svg>
      </div>
    }
                            
    {/* meb 25 - 28 */}
    {form.tooch_number.includes("25-meb-26-miss-pon-meb-27-miss-pon-meb-28-meb") &&
      <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
      <svg width="100%" height="20vh">

        {/* Garis ke samping dan ke bawah (L-shape) */}
        <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
      </svg>
    </div>
    }     
      </>
        :
      <></>
      }
      
    {form.tooch_number.includes("25-pob-26-miss-pon-pob-27-pob")
    ||form.tooch_number.includes("25-pob-26-miss-pon-pob-27-miss-pon-pob-28-pob") ?
    <>
    {/* pob 25 - 27 */}
    {form.tooch_number.includes("25-pob-26-miss-pon-pob-27-pob") &&
      <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
      <svg width="100%" height="20vh">
          {/* Garis ke samping dan ke bawah (L-shape) */}
          <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
          <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
          <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        </svg>
      </div>
    }
                            
    {/* pob 25 - 28 */}
    {form.tooch_number.includes("25-pob-26-miss-pon-pob-27-miss-pon-pob-28-pob") &&
     <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
     <svg width="100%" height="20vh">

        {/* Garis ke samping dan ke bawah (L-shape) */}
        <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
      </svg>
    </div>
    }
        
      </>
        :
      <></>
      }
          
      <div style={{ textAlign: "center" }}>25</div>
      <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("25-fmc") || form.tooch_number.includes("25-poc") || form.tooch_number.includes("25-ipx-poc") || form.tooch_number.match(/\b25-pob\b/) || form.tooch_number.match(/\b25-miss-pon-pob\b/) || form.tooch_number.match(/\b25-meb\b/) || form.tooch_number.match(/\b25-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
        {form.tooch_number.includes("25-poc") || form.tooch_number.includes("25-ipx-poc") || form.tooch_number.match(/\b25-pob\b/) || form.tooch_number.match(/\b25-miss-pon-pob\b/) ?
          <g>
            {/* Atas */}
            {form.tooch_number.includes("25B-fis") || form.tooch_number.includes("25B-amf") || form.tooch_number.includes("25B-cof") || form.tooch_number.includes("25B-car") ?
              <>
                {form.tooch_number.includes("25B-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="25B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 25B"
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'url(#25B-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("25-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("25B-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 25B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("25-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("25B-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="25B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 25B"
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("25-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
      
                {form.tooch_number.includes("25B-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 25B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("25-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
              
              </>
              :
            <svg width="40" height="40">
              <defs>
                <pattern id="25B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                  <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                </pattern>
              </defs>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 25B"
                points="0,0 40,0 30,10 10,10"
                style={{ fill: '#007610', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("25-Bukal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            </svg>
            }

            {/* Bawah */}
            {form.tooch_number.includes("25P-fis") || form.tooch_number.includes("25P-amf") || form.tooch_number.includes("25P-cof") || form.tooch_number.includes("25P-car") ?
              <>
                {form.tooch_number.includes("25P-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="25P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>

                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 25P"
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'url(#25P-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("25-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("25P-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 25P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("25-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("25P-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="25P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 25P"
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("25-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }

                {form.tooch_number.includes("25P-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 25P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("25-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
              </>
              :
              <svg width="40" height="40">
                <defs>
                  <pattern id="25P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 25P"
                  points="10,30 30,30 40,40 0,40"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("25-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {/* Kanan */}
              {form.tooch_number.includes("25D-fis") || form.tooch_number.includes("25D-amf") || form.tooch_number.includes("25D-cof") || form.tooch_number.includes("25D-car") ?
              <>
                {form.tooch_number.includes("25D-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="25D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 25D"
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'url(#25D-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("25-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }

                {form.tooch_number.includes("25D-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 25D"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("25-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }

                {form.tooch_number.includes("25D-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="25D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 25D"
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("25-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
              
                {form.tooch_number.includes("25D-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 25D"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("25-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
              
              </>
              :
              <svg width="40" height="40">
                <defs>
                  <pattern id="25D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 25D"
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("25-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {/* Kiri */}
            {form.tooch_number.includes("25M-fis") || form.tooch_number.includes("25M-amf") || form.tooch_number.includes("25M-cof") || form.tooch_number.includes("25M-car") ?
            <>
              {form.tooch_number.includes("25M-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="25M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 25M"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: 'url(#25M-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("25-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("25M-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 25M"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("25-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("25M-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="25M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 25M"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("25-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("25M-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 25M"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("25-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

            </>
            :
            <svg width="40" height="40">
              <defs>
                <pattern id="25M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                  <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                </pattern>
              </defs>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 25M"
                points="0,0 10,10 10,30 0,40"
                style={{ fill: '#007610', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("25-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            </svg>
            }

            {/* center */}
            {form.tooch_number.includes("25O-fis") || form.tooch_number.includes("25O-amf") || form.tooch_number.includes("25O-cof") || form.tooch_number.includes("25O-car") ?
              <>
                {form.tooch_number.includes("25O-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="25O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 25O"
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'url(#25O-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("25-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("25O-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 25O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("25-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("25O-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="25O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 25O"
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("25-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }

                {form.tooch_number.includes("25O-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 25O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("25-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }

              </>
              :
              <svg width="40" height="40">
                <defs>
                  <pattern id="25O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 25O"
                  points="10,10 30,10 30,30 10,30"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("25-Oklusal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {/* cfr */}
            {form.tooch_number.includes("25-cfr") &&
              <text x="50%" y="25" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
                #
              </text>
            }

            {/* rrx */}
            {form.tooch_number.includes("25-rrx") &&    
            <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 25-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
              v
            </text>
            }
                  
            {/* mis */}
            {form.tooch_number.includes("25-mis") &&    
            <>  
              <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
              <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
            </> 
            }
                    
          </g>
            :
          <g>
            {/* Atas */}
            {form.tooch_number.includes("25B-fis") || form.tooch_number.includes("25B-amf") || form.tooch_number.includes("25B-cof") || form.tooch_number.includes("25B-car") ?
              <>
                {form.tooch_number.includes("25B-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="25B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 25B"
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'url(#25B-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("25-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("25B-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 25B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("25-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("25B-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="25B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 25B"
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("25-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
      
                {form.tooch_number.includes("25B-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 25B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("25-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
              
              </>
              :
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 25B"
                points="0,0 40,0 30,10 10,10"
                style={{ fill: 'white', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("25-Bukal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {/* Bawah */}
            {form.tooch_number.includes("25P-fis") || form.tooch_number.includes("25P-amf") || form.tooch_number.includes("25P-cof") || form.tooch_number.includes("25P-car") ?
              <>
                {form.tooch_number.includes("25P-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="25P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>

                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 25P"
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'url(#25P-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("25-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("25P-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 25P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("25-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("25P-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="25P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 25P"
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("25-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }

                {form.tooch_number.includes("25P-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 25P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("25-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
              </>
              :
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 25P"
                points="10,30 30,30 40,40 0,40"
                style={{ fill: 'white', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("25-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {/* Kanan */}
            {form.tooch_number.includes("25D-fis") || form.tooch_number.includes("25D-amf") || form.tooch_number.includes("25D-cof") || form.tooch_number.includes("25D-car") ?
            <>
              {form.tooch_number.includes("25D-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="25D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 25D"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: 'url(#25D-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("25-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("25D-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 25D"
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("25-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("25D-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="25D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 25D"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("25-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }
            
              {form.tooch_number.includes("25D-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 25D"
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("25-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }
            
            </>
            :
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 25D"
              points="30,10 40,0 40,40 30,30"
              style={{ fill: 'white', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("25-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
            }

            {/* Kiri */}
            {form.tooch_number.includes("25M-fis") || form.tooch_number.includes("25M-amf") || form.tooch_number.includes("25M-cof") || form.tooch_number.includes("25M-car") ?
            <>
              {form.tooch_number.includes("25M-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="25M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 25M"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: 'url(#25M-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("25-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("25M-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 25M"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("25-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("25M-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="25M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 25M"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("25-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("25M-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 25M"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("25-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

            </>
            :
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 25M"
              points="0,0 10,10 10,30 0,40"
              style={{ fill: 'white', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("25-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
            }

            {/* center */}
            {form.tooch_number.includes("25O-fis") || form.tooch_number.includes("25O-amf") || form.tooch_number.includes("25O-cof") || form.tooch_number.includes("25O-car") ?
              <>
                {form.tooch_number.includes("25O-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="25O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 25O"
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'url(#25O-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("25-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("25O-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 25O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("25-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("25O-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="25O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 25O"
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("25-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }

                {form.tooch_number.includes("25O-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 25O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("25-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }

              </>
              :
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 25O"
                points="10,10 30,10 30,30 10,30"
                style={{ fill: 'white', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("25-Oklusal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {/* cfr */}
            {form.tooch_number.includes("25-cfr") &&
              <text x="50%" y="25" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
                #
              </text>
            }
            
            {form.tooch_number.includes("25-rrx") &&    
            <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 25-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
              v
            </text>
            }
            
            {form.tooch_number.includes("25-mis") &&    
            <>  
              <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
              <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
            </> 
            }
          </g>
        }      
      </svg>
            
      {form.tooch_number.includes("25-nvt") &&   
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
        <svg width={42} height={42}>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 25"
            points="21,2 41,32 1,32" 
            style={{ fill: 'white', stroke: 'black', strokeWidth: 3 }}
            onMouseEnter={() => setInfoGigi('25')}
            onMouseLeave={() => setInfoGigi('XX-X')}
          />
        </svg> 
      </div>
      }  
      
      {form.tooch_number.includes("25-rct") &&   
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
      <svg width={42} height={42}>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 25"
          points="21,2 41,32 1,32" 
          style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
          onMouseEnter={() => setInfoGigi('25')}
          onMouseLeave={() => setInfoGigi('XX-X')}
        />
        </svg>
      </div>
      } 
            
      {form.tooch_number.includes("25B-amf-rct") || form.tooch_number.includes("25P-amf-rct") || form.tooch_number.includes("25M-amf-rct") || form.tooch_number.includes("25D-amf-rct") || form.tooch_number.includes("25O-amf-rct") ||
        form.tooch_number.includes("25B-cof-rct") || form.tooch_number.includes("25P-cof-rct") || form.tooch_number.includes("25M-cof-rct") || form.tooch_number.includes("25D-cof-rct") || form.tooch_number.includes("25O-cof-rct") ||
        form.tooch_number.includes("25-fmc-rct") ||
        form.tooch_number.includes("25-poc-rct") ?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
        <svg width={42} height={42}>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 25B"
            points="21,2 41,32 1,32" 
            style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
            onMouseEnter={() => setInfoGigi('25-Bukal')}
            onMouseLeave={() => setInfoGigi('XX-X')}
          />
          </svg>
        </div>
          :
        <></>
      } 

        </div>

        <div style={{ width: "42px", margin: "1px" }}>
        {form.tooch_number.includes("26-une") || form.tooch_number.includes("26-non") || form.tooch_number.includes("26-pre") || form.tooch_number.includes("26-ano") || form.tooch_number.includes("26-ipx-poc") || form.tooch_number.includes("26-mis-prd-acr") || form.tooch_number.includes("26-mis-fld-acr") ?
      <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
        {form.tooch_number.includes("26-une") &&
          <div style={{ position: "absolute", color: "black" }}>UNE</div>
        }
        {form.tooch_number.includes("26-non") &&
          <div style={{ position: "absolute", color: "black" }}>NON</div>
        }      
        {form.tooch_number.includes("26-pre") &&
          <div style={{ position: "absolute", color: "black" }}>PRE</div>
        }      
        {form.tooch_number.includes("26-ano") &&
          <div style={{ position: "absolute", color: "black" }}>ANO</div>
        }
        {form.tooch_number.includes("26-ipx-poc") &&
          <div style={{ position: "absolute", color: "black" }}>IPX</div>
        }
        {form.tooch_number.includes("26-mis-prd-acr") &&
          <div style={{ position: "absolute", color: "black" }}>PRD</div>
        } 
        {form.tooch_number.includes("26-mis-fld-acr") &&
          <div style={{ position: "absolute", color: "black" }}>FLD</div>
        }      
      </div>
      :
      <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
      </div>
    }

    {form.tooch_number.includes("26-meb-27-miss-pon-meb-28-meb") ?
    <>
    {/* meb 26 - 28 */}
    {form.tooch_number.includes("26-meb-27-miss-pon-meb-28-meb") &&
     <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
     <svg width="100%" height="20vh">

          {/* Garis ke samping dan ke bawah (L-shape) */}
          <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
          <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
          <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        </svg>
      </div>
    }
      
      </>
        :
      <></>
      }
      
    {form.tooch_number.includes("26-pob-27-miss-pon-pob-28-pob") ?
    <>
    {/* pob 26 - 28 */}
    {form.tooch_number.includes("26-pob-27-miss-pon-pob-28-pob") &&
      <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
      <svg width="100%" height="20vh">

          {/* Garis ke samping dan ke bawah (L-shape) */}
          <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
          <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
          <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        </svg>
      </div>
    }
      
      </>
        :
      <></>
      }
          
      <div style={{ textAlign: "center" }}>26</div>
      <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("26-fmc") || form.tooch_number.includes("26-poc") || form.tooch_number.includes("26-ipx-poc") || form.tooch_number.match(/\b26-pob\b/) || form.tooch_number.match(/\b26-miss-pon-pob\b/) || form.tooch_number.match(/\b26-meb\b/) || form.tooch_number.match(/\b26-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
        {form.tooch_number.includes("26-poc") || form.tooch_number.includes("26-ipx-poc") || form.tooch_number.match(/\b26-pob\b/) || form.tooch_number.match(/\b26-miss-pon-pob\b/) ?
          <g>
            {/* Atas */}
            {form.tooch_number.includes("26B-fis") || form.tooch_number.includes("26B-amf") || form.tooch_number.includes("26B-cof") || form.tooch_number.includes("26B-car") ?
              <>
                {form.tooch_number.includes("26B-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="26B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 26B"
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'url(#26B-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("26-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("26B-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 26B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("26-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("26B-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="26B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 26B"
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("26-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
      
                {form.tooch_number.includes("26B-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 26B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("26-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
              
              </>
              :
            <svg width="40" height="40">
              <defs>
                <pattern id="26B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                  <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                </pattern>
              </defs>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 26B"
                points="0,0 40,0 30,10 10,10"
                style={{ fill: '#007610', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("26-Bukal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            </svg>
            }

            {/* Bawah */}
            {form.tooch_number.includes("26P-fis") || form.tooch_number.includes("26P-amf") || form.tooch_number.includes("26P-cof") || form.tooch_number.includes("26P-car") ?
              <>
                {form.tooch_number.includes("26P-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="26P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>

                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 26P"
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'url(#26P-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("26-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("26P-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 26P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("26-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("26P-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="26P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 26P"
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("26-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }

                {form.tooch_number.includes("26P-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 26P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("26-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
              </>
              :
              <svg width="40" height="40">
                <defs>
                  <pattern id="26P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 26P"
                  points="10,30 30,30 40,40 0,40"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("26-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {/* Kanan */}
              {form.tooch_number.includes("26D-fis") || form.tooch_number.includes("26D-amf") || form.tooch_number.includes("26D-cof") || form.tooch_number.includes("26D-car") ?
              <>
                {form.tooch_number.includes("26D-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="26D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 26D"
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'url(#26D-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("26-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }

                {form.tooch_number.includes("26D-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 26D"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("26-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }

                {form.tooch_number.includes("26D-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="26D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 26D"
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("26-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
              
                {form.tooch_number.includes("26D-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 26D"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("26-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
              
              </>
              :
              <svg width="40" height="40">
                <defs>
                  <pattern id="26D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 26D"
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("26-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {/* Kiri */}
            {form.tooch_number.includes("26M-fis") || form.tooch_number.includes("26M-amf") || form.tooch_number.includes("26M-cof") || form.tooch_number.includes("26M-car") ?
            <>
              {form.tooch_number.includes("26M-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="26M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 26M"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: 'url(#26M-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("26-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("26M-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 26M"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("26-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("26M-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="26M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 26M"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("26-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("26M-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 26M"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("26-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

            </>
            :
            <svg width="40" height="40">
              <defs>
                <pattern id="26M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                  <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                </pattern>
              </defs>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 26M"
                points="0,0 10,10 10,30 0,40"
                style={{ fill: '#007610', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("26-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            </svg>
            }

            {/* center */}
            {form.tooch_number.includes("26O-fis") || form.tooch_number.includes("26O-amf") || form.tooch_number.includes("26O-cof") || form.tooch_number.includes("26O-car") ?
              <>
                {form.tooch_number.includes("26O-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="26O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 26O"
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'url(#26O-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("26-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("26O-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 26O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("26-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("26O-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="26O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 26O"
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("26-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }

                {form.tooch_number.includes("26O-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 26O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("26-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }

              </>
              :
              <svg width="40" height="40">
                <defs>
                  <pattern id="26O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 26O"
                  points="10,10 30,10 30,30 10,30"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("26-Oklusal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {/* cfr */}
            {form.tooch_number.includes("26-cfr") &&
              <text x="50%" y="26" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
                #
              </text>
            }

            {/* rrx */}
            {form.tooch_number.includes("26-rrx") &&    
            <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 26-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
              v
            </text>
            }
                  
            {/* mis */}
            {form.tooch_number.includes("26-mis") &&    
            <>  
              <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
              <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
            </> 
            }
                    
          </g>
            :
          <g>
            {/* Atas */}
            {form.tooch_number.includes("26B-fis") || form.tooch_number.includes("26B-amf") || form.tooch_number.includes("26B-cof") || form.tooch_number.includes("26B-car") ?
              <>
                {form.tooch_number.includes("26B-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="26B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 26B"
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'url(#26B-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("26-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("26B-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 26B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("26-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("26B-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="26B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 26B"
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("26-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
      
                {form.tooch_number.includes("26B-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 26B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("26-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
              
              </>
              :
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 26B"
                points="0,0 40,0 30,10 10,10"
                style={{ fill: 'white', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("26-Bukal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {/* Bawah */}
            {form.tooch_number.includes("26P-fis") || form.tooch_number.includes("26P-amf") || form.tooch_number.includes("26P-cof") || form.tooch_number.includes("26P-car") ?
              <>
                {form.tooch_number.includes("26P-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="26P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>

                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 26P"
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'url(#26P-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("26-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("26P-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 26P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("26-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("26P-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="26P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 26P"
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("26-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }

                {form.tooch_number.includes("26P-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 26P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("26-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
              </>
              :
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 26P"
                points="10,30 30,30 40,40 0,40"
                style={{ fill: 'white', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("26-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {/* Kanan */}
            {form.tooch_number.includes("26D-fis") || form.tooch_number.includes("26D-amf") || form.tooch_number.includes("26D-cof") || form.tooch_number.includes("26D-car") ?
            <>
              {form.tooch_number.includes("26D-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="26D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 26D"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: 'url(#26D-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("26-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("26D-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 26D"
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("26-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("26D-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="26D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 26D"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("26-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }
            
              {form.tooch_number.includes("26D-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 26D"
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("26-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }
            
            </>
            :
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 26D"
              points="30,10 40,0 40,40 30,30"
              style={{ fill: 'white', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("26-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
            }

            {/* Kiri */}
            {form.tooch_number.includes("26M-fis") || form.tooch_number.includes("26M-amf") || form.tooch_number.includes("26M-cof") || form.tooch_number.includes("26M-car") ?
            <>
              {form.tooch_number.includes("26M-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="26M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 26M"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: 'url(#26M-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("26-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("26M-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 26M"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("26-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("26M-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="26M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 26M"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("26-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("26M-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 26M"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("26-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

            </>
            :
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 26M"
              points="0,0 10,10 10,30 0,40"
              style={{ fill: 'white', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("26-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
            }

            {/* center */}
            {form.tooch_number.includes("26O-fis") || form.tooch_number.includes("26O-amf") || form.tooch_number.includes("26O-cof") || form.tooch_number.includes("26O-car") ?
              <>
                {form.tooch_number.includes("26O-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="26O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 26O"
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'url(#26O-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("26-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("26O-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 26O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("26-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("26O-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="26O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 26O"
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("26-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }

                {form.tooch_number.includes("26O-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 26O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("26-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }

              </>
              :
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 26O"
                points="10,10 30,10 30,30 10,30"
                style={{ fill: 'white', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("26-Oklusal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {/* cfr */}
            {form.tooch_number.includes("26-cfr") &&
              <text x="50%" y="26" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
                #
              </text>
            }
            
            {form.tooch_number.includes("26-rrx") &&    
            <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 26-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
              v
            </text>
            }
            
            {form.tooch_number.includes("26-mis") &&    
            <>  
              <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
              <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
            </> 
            }
          </g>
        }      
      </svg>
            
      {form.tooch_number.includes("26-nvt") &&   
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
        <svg width={42} height={42}>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 26"
            points="21,2 41,32 1,32" 
            style={{ fill: 'white', stroke: 'black', strokeWidth: 3 }}
            onMouseEnter={() => setInfoGigi('26')}
            onMouseLeave={() => setInfoGigi('XX-X')}
          />
        </svg> 
      </div>
      }  
      
      {form.tooch_number.includes("26-rct") &&   
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
      <svg width={42} height={42}>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 26"
          points="21,2 41,32 1,32" 
          style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
          onMouseEnter={() => setInfoGigi('26')}
          onMouseLeave={() => setInfoGigi('XX-X')}
        />
        </svg>
      </div>
      } 
            
      {form.tooch_number.includes("26B-amf-rct") || form.tooch_number.includes("26P-amf-rct") || form.tooch_number.includes("26M-amf-rct") || form.tooch_number.includes("26D-amf-rct") || form.tooch_number.includes("26O-amf-rct") ||
        form.tooch_number.includes("26B-cof-rct") || form.tooch_number.includes("26P-cof-rct") || form.tooch_number.includes("26M-cof-rct") || form.tooch_number.includes("26D-cof-rct") || form.tooch_number.includes("26O-cof-rct") ||
        form.tooch_number.includes("26-fmc-rct") ||
        form.tooch_number.includes("26-poc-rct") ?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
        <svg width={42} height={42}>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 26B"
            points="21,2 41,32 1,32" 
            style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
            onMouseEnter={() => setInfoGigi('26-Bukal')}
            onMouseLeave={() => setInfoGigi('XX-X')}
          />
          </svg>
        </div>
          :
        <></>
      } 

        </div>
                  
        <div style={{ width: "42px", margin: "1px" }}>
      {form.tooch_number.includes("27-une") || form.tooch_number.includes("27-non") || form.tooch_number.includes("27-pre") || form.tooch_number.includes("27-ano") || form.tooch_number.includes("27-ipx-poc") || form.tooch_number.includes("27-mis-prd-acr") || form.tooch_number.includes("27-mis-fld-acr") ?
      <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
        {form.tooch_number.includes("27-une") &&
          <div style={{ position: "absolute", color: "black" }}>UNE</div>
        }
        {form.tooch_number.includes("27-non") &&
          <div style={{ position: "absolute", color: "black" }}>NON</div>
        }      
        {form.tooch_number.includes("27-pre") &&
          <div style={{ position: "absolute", color: "black" }}>PRE</div>
        }      
        {form.tooch_number.includes("27-ano") &&
          <div style={{ position: "absolute", color: "black" }}>ANO</div>
        }
        {form.tooch_number.includes("27-ipx-poc") &&
          <div style={{ position: "absolute", color: "black" }}>IPX</div>
        }
        {form.tooch_number.includes("27-mis-prd-acr") &&
          <div style={{ position: "absolute", color: "black" }}>PRD</div>
        } 
        {form.tooch_number.includes("27-mis-fld-acr") &&
          <div style={{ position: "absolute", color: "black" }}>FLD</div>
        }      
      </div>
      :
      <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
      </div>
    }

          
      <div style={{ textAlign: "center" }}>27</div>
      <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("27-fmc") || form.tooch_number.includes("27-poc") || form.tooch_number.includes("27-ipx-poc") || form.tooch_number.match(/\b27-pob\b/) || form.tooch_number.match(/\b27-miss-pon-pob\b/) || form.tooch_number.match(/\b27-meb\b/) || form.tooch_number.match(/\b27-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
        {form.tooch_number.includes("27-poc") || form.tooch_number.includes("27-ipx-poc") || form.tooch_number.match(/\b27-pob\b/) || form.tooch_number.match(/\b27-miss-pon-pob\b/) ?
          <g>
            {/* Atas */}
            {form.tooch_number.includes("27B-fis") || form.tooch_number.includes("27B-amf") || form.tooch_number.includes("27B-cof") || form.tooch_number.includes("27B-car") ?
              <>
                {form.tooch_number.includes("27B-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="27B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 27B"
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'url(#27B-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("27-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("27B-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 27B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("27-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("27B-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="27B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 27B"
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("27-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
      
                {form.tooch_number.includes("27B-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 27B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("27-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
              
              </>
              :
            <svg width="40" height="40">
              <defs>
                <pattern id="27B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                  <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                </pattern>
              </defs>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 27B"
                points="0,0 40,0 30,10 10,10"
                style={{ fill: '#007610', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("27-Bukal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            </svg>
            }

            {/* Bawah */}
            {form.tooch_number.includes("27P-fis") || form.tooch_number.includes("27P-amf") || form.tooch_number.includes("27P-cof") || form.tooch_number.includes("27P-car") ?
              <>
                {form.tooch_number.includes("27P-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="27P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>

                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 27P"
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'url(#27P-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("27-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("27P-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 27P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("27-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("27P-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="27P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 27P"
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("27-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }

                {form.tooch_number.includes("27P-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 27P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("27-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
              </>
              :
              <svg width="40" height="40">
                <defs>
                  <pattern id="27P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 27P"
                  points="10,30 30,30 40,40 0,40"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("27-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {/* Kanan */}
              {form.tooch_number.includes("27D-fis") || form.tooch_number.includes("27D-amf") || form.tooch_number.includes("27D-cof") || form.tooch_number.includes("27D-car") ?
              <>
                {form.tooch_number.includes("27D-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="27D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 27D"
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'url(#27D-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("27-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }

                {form.tooch_number.includes("27D-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 27D"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("27-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }

                {form.tooch_number.includes("27D-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="27D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 27D"
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("27-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
              
                {form.tooch_number.includes("27D-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 27D"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("27-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
              
              </>
              :
              <svg width="40" height="40">
                <defs>
                  <pattern id="27D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 27D"
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("27-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {/* Kiri */}
            {form.tooch_number.includes("27M-fis") || form.tooch_number.includes("27M-amf") || form.tooch_number.includes("27M-cof") || form.tooch_number.includes("27M-car") ?
            <>
              {form.tooch_number.includes("27M-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="27M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 27M"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: 'url(#27M-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("27-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("27M-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 27M"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("27-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("27M-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="27M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 27M"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("27-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("27M-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 27M"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("27-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

            </>
            :
            <svg width="40" height="40">
              <defs>
                <pattern id="27M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                  <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                </pattern>
              </defs>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 27M"
                points="0,0 10,10 10,30 0,40"
                style={{ fill: '#007610', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("27-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            </svg>
            }

            {/* center */}
            {form.tooch_number.includes("27O-fis") || form.tooch_number.includes("27O-amf") || form.tooch_number.includes("27O-cof") || form.tooch_number.includes("27O-car") ?
              <>
                {form.tooch_number.includes("27O-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="27O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 27O"
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'url(#27O-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("27-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("27O-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 27O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("27-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("27O-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="27O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 27O"
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("27-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }

                {form.tooch_number.includes("27O-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 27O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("27-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }

              </>
              :
              <svg width="40" height="40">
                <defs>
                  <pattern id="27O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 27O"
                  points="10,10 30,10 30,30 10,30"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("27-Oklusal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {/* cfr */}
            {form.tooch_number.includes("27-cfr") &&
              <text x="50%" y="27" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
                #
              </text>
            }

            {/* rrx */}
            {form.tooch_number.includes("27-rrx") &&    
            <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 27-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
              v
            </text>
            }
                  
            {/* mis */}
            {form.tooch_number.includes("27-mis") &&    
            <>  
              <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
              <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
            </> 
            }
                    
          </g>
            :
          <g>
            {/* Atas */}
            {form.tooch_number.includes("27B-fis") || form.tooch_number.includes("27B-amf") || form.tooch_number.includes("27B-cof") || form.tooch_number.includes("27B-car") ?
              <>
                {form.tooch_number.includes("27B-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="27B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 27B"
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'url(#27B-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("27-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("27B-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 27B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("27-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("27B-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="27B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 27B"
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("27-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
      
                {form.tooch_number.includes("27B-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 27B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("27-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
              
              </>
              :
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 27B"
                points="0,0 40,0 30,10 10,10"
                style={{ fill: 'white', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("27-Bukal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {/* Bawah */}
            {form.tooch_number.includes("27P-fis") || form.tooch_number.includes("27P-amf") || form.tooch_number.includes("27P-cof") || form.tooch_number.includes("27P-car") ?
              <>
                {form.tooch_number.includes("27P-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="27P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>

                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 27P"
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'url(#27P-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("27-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("27P-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 27P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("27-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("27P-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="27P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 27P"
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("27-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }

                {form.tooch_number.includes("27P-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 27P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("27-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
              </>
              :
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 27P"
                points="10,30 30,30 40,40 0,40"
                style={{ fill: 'white', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("27-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {/* Kanan */}
            {form.tooch_number.includes("27D-fis") || form.tooch_number.includes("27D-amf") || form.tooch_number.includes("27D-cof") || form.tooch_number.includes("27D-car") ?
            <>
              {form.tooch_number.includes("27D-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="27D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 27D"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: 'url(#27D-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("27-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("27D-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 27D"
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("27-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("27D-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="27D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 27D"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("27-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }
            
              {form.tooch_number.includes("27D-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 27D"
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("27-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }
            
            </>
            :
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 27D"
              points="30,10 40,0 40,40 30,30"
              style={{ fill: 'white', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("27-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
            }

            {/* Kiri */}
            {form.tooch_number.includes("27M-fis") || form.tooch_number.includes("27M-amf") || form.tooch_number.includes("27M-cof") || form.tooch_number.includes("27M-car") ?
            <>
              {form.tooch_number.includes("27M-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="27M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 27M"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: 'url(#27M-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("27-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("27M-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 27M"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("27-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("27M-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="27M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 27M"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("27-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("27M-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 27M"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("27-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

            </>
            :
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 27M"
              points="0,0 10,10 10,30 0,40"
              style={{ fill: 'white', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("27-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
            }

            {/* center */}
            {form.tooch_number.includes("27O-fis") || form.tooch_number.includes("27O-amf") || form.tooch_number.includes("27O-cof") || form.tooch_number.includes("27O-car") ?
              <>
                {form.tooch_number.includes("27O-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="27O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 27O"
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'url(#27O-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("27-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("27O-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 27O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("27-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("27O-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="27O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 27O"
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("27-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }

                {form.tooch_number.includes("27O-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 27O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("27-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }

              </>
              :
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 27O"
                points="10,10 30,10 30,30 10,30"
                style={{ fill: 'white', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("27-Oklusal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {/* cfr */}
            {form.tooch_number.includes("27-cfr") &&
              <text x="50%" y="27" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
                #
              </text>
            }
            
            {form.tooch_number.includes("27-rrx") &&    
            <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 27-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
              v
            </text>
            }
            
            {form.tooch_number.includes("27-mis") &&    
            <>  
              <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
              <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
            </> 
            }
          </g>
        }      
      </svg>
            
      {form.tooch_number.includes("27-nvt") &&   
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
        <svg width={42} height={42}>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 27"
            points="21,2 41,32 1,32" 
            style={{ fill: 'white', stroke: 'black', strokeWidth: 3 }}
            onMouseEnter={() => setInfoGigi('27')}
            onMouseLeave={() => setInfoGigi('XX-X')}
          />
        </svg> 
      </div>
      }  
      
      {form.tooch_number.includes("27-rct") &&   
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
      <svg width={42} height={42}>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 27"
          points="21,2 41,32 1,32" 
          style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
          onMouseEnter={() => setInfoGigi('27')}
          onMouseLeave={() => setInfoGigi('XX-X')}
        />
        </svg>
      </div>
      } 
            
      {form.tooch_number.includes("27B-amf-rct") || form.tooch_number.includes("27P-amf-rct") || form.tooch_number.includes("27M-amf-rct") || form.tooch_number.includes("27D-amf-rct") || form.tooch_number.includes("27O-amf-rct") ||
        form.tooch_number.includes("27B-cof-rct") || form.tooch_number.includes("27P-cof-rct") || form.tooch_number.includes("27M-cof-rct") || form.tooch_number.includes("27D-cof-rct") || form.tooch_number.includes("27O-cof-rct") ||
        form.tooch_number.includes("27-fmc-rct") ||
        form.tooch_number.includes("27-poc-rct") ?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
        <svg width={42} height={42}>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 27B"
            points="21,2 41,32 1,32" 
            style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
            onMouseEnter={() => setInfoGigi('27-Bukal')}
            onMouseLeave={() => setInfoGigi('XX-X')}
          />
          </svg>
        </div>
          :
        <></>
      } 

        </div>

        <div style={{ width: "42px", margin: "1px" }}>
    {form.tooch_number.includes("28-une") || form.tooch_number.includes("28-non") || form.tooch_number.includes("28-pre") || form.tooch_number.includes("28-ano") || form.tooch_number.includes("28-ipx-poc") || form.tooch_number.includes("28-mis-prd-acr") || form.tooch_number.includes("28-mis-fld-acr") ?
      <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
        {form.tooch_number.includes("28-une") &&
          <div style={{ position: "absolute", color: "black" }}>UNE</div>
        }
        {form.tooch_number.includes("28-non") &&
          <div style={{ position: "absolute", color: "black" }}>NON</div>
        }      
        {form.tooch_number.includes("28-pre") &&
          <div style={{ position: "absolute", color: "black" }}>PRE</div>
        }      
        {form.tooch_number.includes("28-ano") &&
          <div style={{ position: "absolute", color: "black" }}>ANO</div>
        }
        {form.tooch_number.includes("28-ipx-poc") &&
          <div style={{ position: "absolute", color: "black" }}>IPX</div>
        }
        {form.tooch_number.includes("28-mis-prd-acr") &&
          <div style={{ position: "absolute", color: "black" }}>PRD</div>
        } 
        {form.tooch_number.includes("28-mis-fld-acr") &&
          <div style={{ position: "absolute", color: "black" }}>FLD</div>
        }      
      </div>
      :
      <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
      </div>
    }
          
      <div style={{ textAlign: "center" }}>28</div>
      <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("28-fmc") || form.tooch_number.includes("28-poc") || form.tooch_number.includes("28-ipx-poc") || form.tooch_number.match(/\b28-pob\b/) || form.tooch_number.match(/\b28-miss-pon-pob\b/) || form.tooch_number.match(/\b28-meb\b/) || form.tooch_number.match(/\b28-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
        {form.tooch_number.includes("28-poc") || form.tooch_number.includes("28-ipx-poc") || form.tooch_number.match(/\b28-pob\b/) || form.tooch_number.match(/\b28-miss-pon-pob\b/) ?
          <g>
            {/* Atas */}
            {form.tooch_number.includes("28B-fis") || form.tooch_number.includes("28B-amf") || form.tooch_number.includes("28B-cof") || form.tooch_number.includes("28B-car") ?
              <>
                {form.tooch_number.includes("28B-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="28B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 28B"
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'url(#28B-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("28-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("28B-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 28B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("28-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("28B-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="28B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 28B"
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("28-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
      
                {form.tooch_number.includes("28B-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 28B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("28-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
              
              </>
              :
            <svg width="40" height="40">
              <defs>
                <pattern id="28B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                  <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                </pattern>
              </defs>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 28B"
                points="0,0 40,0 30,10 10,10"
                style={{ fill: '#007610', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("28-Bukal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            </svg>
            }

            {/* Bawah */}
            {form.tooch_number.includes("28P-fis") || form.tooch_number.includes("28P-amf") || form.tooch_number.includes("28P-cof") || form.tooch_number.includes("28P-car") ?
              <>
                {form.tooch_number.includes("28P-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="28P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>

                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 28P"
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'url(#28P-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("28-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("28P-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 28P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("28-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("28P-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="28P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 28P"
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("28-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }

                {form.tooch_number.includes("28P-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 28P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("28-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
              </>
              :
              <svg width="40" height="40">
                <defs>
                  <pattern id="28P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 28P"
                  points="10,30 30,30 40,40 0,40"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("28-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {/* Kanan */}
              {form.tooch_number.includes("28D-fis") || form.tooch_number.includes("28D-amf") || form.tooch_number.includes("28D-cof") || form.tooch_number.includes("28D-car") ?
              <>
                {form.tooch_number.includes("28D-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="28D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 28D"
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'url(#28D-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("28-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }

                {form.tooch_number.includes("28D-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 28D"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("28-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }

                {form.tooch_number.includes("28D-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="28D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 28D"
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("28-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
              
                {form.tooch_number.includes("28D-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 28D"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("28-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
              
              </>
              :
              <svg width="40" height="40">
                <defs>
                  <pattern id="28D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 28D"
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("28-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {/* Kiri */}
            {form.tooch_number.includes("28M-fis") || form.tooch_number.includes("28M-amf") || form.tooch_number.includes("28M-cof") || form.tooch_number.includes("28M-car") ?
            <>
              {form.tooch_number.includes("28M-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="28M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 28M"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: 'url(#28M-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("28-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("28M-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 28M"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("28-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("28M-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="28M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 28M"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("28-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("28M-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 28M"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("28-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

            </>
            :
            <svg width="40" height="40">
              <defs>
                <pattern id="28M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                  <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                </pattern>
              </defs>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 28M"
                points="0,0 10,10 10,30 0,40"
                style={{ fill: '#007610', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("28-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            </svg>
            }

            {/* center */}
            {form.tooch_number.includes("28O-fis") || form.tooch_number.includes("28O-amf") || form.tooch_number.includes("28O-cof") || form.tooch_number.includes("28O-car") ?
              <>
                {form.tooch_number.includes("28O-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="28O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 28O"
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'url(#28O-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("28-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("28O-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 28O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("28-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("28O-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="28O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 28O"
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("28-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }

                {form.tooch_number.includes("28O-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 28O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("28-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }

              </>
              :
              <svg width="40" height="40">
                <defs>
                  <pattern id="28O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 28O"
                  points="10,10 30,10 30,30 10,30"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("28-Oklusal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {/* cfr */}
            {form.tooch_number.includes("28-cfr") &&
              <text x="50%" y="28" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
                #
              </text>
            }

            {/* rrx */}
            {form.tooch_number.includes("28-rrx") &&    
            <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 28-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
              v
            </text>
            }
                  
            {/* mis */}
            {form.tooch_number.includes("28-mis") &&    
            <>  
              <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
              <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
            </> 
            }
                    
          </g>
            :
          <g>
            {/* Atas */}
            {form.tooch_number.includes("28B-fis") || form.tooch_number.includes("28B-amf") || form.tooch_number.includes("28B-cof") || form.tooch_number.includes("28B-car") ?
              <>
                {form.tooch_number.includes("28B-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="28B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 28B"
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'url(#28B-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("28-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("28B-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 28B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("28-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("28B-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="28B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 28B"
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("28-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
      
                {form.tooch_number.includes("28B-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 28B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("28-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
              
              </>
              :
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 28B"
                points="0,0 40,0 30,10 10,10"
                style={{ fill: 'white', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("28-Bukal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {/* Bawah */}
            {form.tooch_number.includes("28P-fis") || form.tooch_number.includes("28P-amf") || form.tooch_number.includes("28P-cof") || form.tooch_number.includes("28P-car") ?
              <>
                {form.tooch_number.includes("28P-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="28P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>

                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 28P"
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'url(#28P-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("28-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("28P-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 28P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("28-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("28P-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="28P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 28P"
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("28-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }

                {form.tooch_number.includes("28P-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 28P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("28-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
              </>
              :
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 28P"
                points="10,30 30,30 40,40 0,40"
                style={{ fill: 'white', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("28-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {/* Kanan */}
            {form.tooch_number.includes("28D-fis") || form.tooch_number.includes("28D-amf") || form.tooch_number.includes("28D-cof") || form.tooch_number.includes("28D-car") ?
            <>
              {form.tooch_number.includes("28D-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="28D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 28D"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: 'url(#28D-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("28-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("28D-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 28D"
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("28-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("28D-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="28D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 28D"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("28-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }
            
              {form.tooch_number.includes("28D-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 28D"
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("28-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }
            
            </>
            :
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 28D"
              points="30,10 40,0 40,40 30,30"
              style={{ fill: 'white', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("28-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
            }

            {/* Kiri */}
            {form.tooch_number.includes("28M-fis") || form.tooch_number.includes("28M-amf") || form.tooch_number.includes("28M-cof") || form.tooch_number.includes("28M-car") ?
            <>
              {form.tooch_number.includes("28M-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="28M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 28M"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: 'url(#28M-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("28-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("28M-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 28M"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("28-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("28M-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="28M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 28M"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("28-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("28M-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 28M"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("28-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

            </>
            :
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 28M"
              points="0,0 10,10 10,30 0,40"
              style={{ fill: 'white', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("28-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
            }

            {/* center */}
            {form.tooch_number.includes("28O-fis") || form.tooch_number.includes("28O-amf") || form.tooch_number.includes("28O-cof") || form.tooch_number.includes("28O-car") ?
              <>
                {form.tooch_number.includes("28O-fis") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="28O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                        <rect width="8" height="8" fill="#ffb8c6" />
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 28O"
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'url(#28O-fis)', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("28-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }
        
                {form.tooch_number.includes("28O-amf") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 28O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: 'black', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("28-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }
      
                {form.tooch_number.includes("28O-cof") &&
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="28O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 28O"
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("28-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                }

                {form.tooch_number.includes("28O-car") &&
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 28O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                    onMouseEnter={() => setInfoGigi("28-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                }

              </>
              :
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 28O"
                points="10,10 30,10 30,30 10,30"
                style={{ fill: 'white', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("28-Oklusal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {/* cfr */}
            {form.tooch_number.includes("28-cfr") &&
              <text x="50%" y="28" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
                #
              </text>
            }
            
            {form.tooch_number.includes("28-rrx") &&    
            <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 28-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
              v
            </text>
            }
            
            {form.tooch_number.includes("28-mis") &&    
            <>  
              <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
              <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
            </> 
            }
          </g>
        }      
      </svg>
            
      {form.tooch_number.includes("28-nvt") &&   
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
        <svg width={42} height={42}>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 28"
            points="21,2 41,32 1,32" 
            style={{ fill: 'white', stroke: 'black', strokeWidth: 3 }}
            onMouseEnter={() => setInfoGigi('28')}
            onMouseLeave={() => setInfoGigi('XX-X')}
          />
        </svg> 
      </div>
      }  
      
      {form.tooch_number.includes("28-rct") &&   
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
      <svg width={42} height={42}>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 28"
          points="21,2 41,32 1,32" 
          style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
          onMouseEnter={() => setInfoGigi('28')}
          onMouseLeave={() => setInfoGigi('XX-X')}
        />
        </svg>
      </div>
      } 
            
      {form.tooch_number.includes("28B-amf-rct") || form.tooch_number.includes("28P-amf-rct") || form.tooch_number.includes("28M-amf-rct") || form.tooch_number.includes("28D-amf-rct") || form.tooch_number.includes("28O-amf-rct") ||
        form.tooch_number.includes("28B-cof-rct") || form.tooch_number.includes("28P-cof-rct") || form.tooch_number.includes("28M-cof-rct") || form.tooch_number.includes("28D-cof-rct") || form.tooch_number.includes("28O-cof-rct") ||
        form.tooch_number.includes("28-fmc-rct") ||
        form.tooch_number.includes("28-poc-rct") ?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
        <svg width={42} height={42}>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 28B"
            points="21,2 41,32 1,32" 
            style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
            onMouseEnter={() => setInfoGigi('28-Bukal')}
            onMouseLeave={() => setInfoGigi('XX-X')}
          />
          </svg>
        </div>
          :
        <></>
      } 

        </div>
      
          </div>
  );
}
