import { useState } from "react";
import {  useParams } from "react-router-dom";
import "bulma/css/bulma.css";
import "../Styled.css"

export default function ToochNumber({ form, setForm, onclikTooch, handlePolygonClick }) {
  document.title = "SDC Apps";
  const token = localStorage.getItem("token");
  // modal role add 
  const { id, code } = useParams()
  const [infoGigi, setInfoGigi] = useState("XX-X")
  return (
    <div style={{flex:"50%", display:"flex", justifyContent:"end"}}>

    <div style={{ width: "42px", margin: "1px" }}>

    {form.tooch_number.includes("48O-amf-rct") || form.tooch_number.includes("48O-cof-rct") ||form.tooch_number.includes("48-nvt") || form.tooch_number.includes("48-rct") || form.tooch_number.includes("48B-amf-rct")
|| form.tooch_number.includes("48P-amf-rct") || form.tooch_number.includes("48M-amf-rct") || form.tooch_number.includes("48D-amf-rct")
|| form.tooch_number.includes("48L-amf-rct") ||
form.tooch_number.includes("48B-cof-rct") || form.tooch_number.includes("48P-cof-rct") || form.tooch_number.includes("48M-cof-rct")
|| form.tooch_number.includes("48D-cof-rct") || form.tooch_number.includes("48L-cof-rct") ||
form.tooch_number.includes("48-fmc-rct") ||
form.tooch_number.includes("48-poc-rct") ?
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%"}}>
<svg width={42} height={42}>
<polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 48B"
  points="21,2 41,32 1,32" 
  style={{ fill: form.tooch_number.includes("48-nvt") ? 'white' : 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('48-Bukal')}
  onMouseLeave={() => setInfoGigi('XX-X')}
/>
</svg>
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingTop: "13px" }}>
</div>
        } 

<svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("48-fmc") || form.tooch_number.includes("48-poc") || form.tooch_number.includes("48-ipx-poc") || form.tooch_number.match(/\b48-pob\b/) || form.tooch_number.match(/\b48-miss-pon-pob\b/) || form.tooch_number.match(/\b48-meb\b/) || form.tooch_number.match(/\b48-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
{form.tooch_number.includes("48-poc") || form.tooch_number.includes("48-ipx-poc") || form.tooch_number.match(/\b48-pob\b/) || form.tooch_number.match(/\b48-miss-pon-pob\b/) ?
<g>
{/* Atas */}
{form.tooch_number.includes("48L-fis") || form.tooch_number.includes("48L-amf") || form.tooch_number.includes("48L-cof") || form.tooch_number.includes("48L-car") ?
  <>
    {form.tooch_number.includes("48L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="48L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 48L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#48L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("48-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("48L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 48L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("48-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("48L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="48L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 48L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("48-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("48L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 48L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("48-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
<svg width="40" height="40">
  <defs>
    <pattern id="48L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
    </pattern>
  </defs>
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 48L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: '#007610', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("48-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
</svg>
}

{/* Bawah */}
{form.tooch_number.includes("48B-fis") || form.tooch_number.includes("48B-amf") || form.tooch_number.includes("48B-cof") || form.tooch_number.includes("48B-car") ?
  <>
    {form.tooch_number.includes("48B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="48B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 48B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#48B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("48-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("48B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 48B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("48-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("48B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="48B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 48B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("48-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("48B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 48B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("48-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="48B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 48B"
      points="10,30 30,30 40,40 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("48-Bukal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kanan */}
{form.tooch_number.includes("48M-fis") || form.tooch_number.includes("48M-amf") || form.tooch_number.includes("48M-cof") || form.tooch_number.includes("48M-car") ?
  <>
    {form.tooch_number.includes("48M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="48M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 48M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#48M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("48-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("48M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 48M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("48-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("48M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="48M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 48M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("48-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("48M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 48M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("48-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="48M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 48M"
      points="30,10 40,0 40,40 30,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("48-Mesial")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kiri */}
{form.tooch_number.includes("48D-fis") || form.tooch_number.includes("48D-amf") || form.tooch_number.includes("48D-cof") || form.tooch_number.includes("48D-car") ?
  <>
    {form.tooch_number.includes("48D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="48D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 48D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#48D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("48-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("48D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 48D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("48-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("48D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="48D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 48D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("48-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("48D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 48D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("48-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="48D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 48D"
      points="0,0 10,10 10,30 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("48-Distal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* center */}
{form.tooch_number.includes("48O-fis") || form.tooch_number.includes("48O-amf") || form.tooch_number.includes("48O-cof") || form.tooch_number.includes("48O-car") ?
  <>
    {form.tooch_number.includes("48O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="48O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 48O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#48O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("48-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("48O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 48O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("48-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("48O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="48O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 48O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("48-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("48O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 48O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("48-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="48O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 48O"
      points="10,10 30,10 30,30 10,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("48-Oklusal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* cfr */}
{form.tooch_number.includes("48-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{/* rrx */}
{form.tooch_number.includes("48-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100">
  v
</text>
}
      
{/* mis */}
{form.tooch_number.includes("48-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
        
</g>
:
<g>
{/* Atas */}
{form.tooch_number.includes("48L-fis") || form.tooch_number.includes("48L-amf") || form.tooch_number.includes("48L-cof") || form.tooch_number.includes("48L-car") ?
  <>
    {form.tooch_number.includes("48L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="48L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 48L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#48L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("48-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("48L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 48L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("48-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("48L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="48L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 48L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("48-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("48L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 48L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("48-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 48L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("48-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Bawah */}
{form.tooch_number.includes("48B-fis") || form.tooch_number.includes("48B-amf") || form.tooch_number.includes("48B-cof") || form.tooch_number.includes("48B-car") ?
  <>
    {form.tooch_number.includes("48B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="48B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 48B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#48B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("48-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("48B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 48B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("48-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("48B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="48B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 48B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("48-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("48B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 48B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("48-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 48B"
    points="10,30 30,30 40,40 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("48-Bukal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kanan */}
{form.tooch_number.includes("48M-fis") || form.tooch_number.includes("48M-amf") || form.tooch_number.includes("48M-cof") || form.tooch_number.includes("48M-car") ?
  <>
    {form.tooch_number.includes("48M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="48M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 48M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#48M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("48-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("48M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 48M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("48-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("48M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="48M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 48M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("48-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("48M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 48M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("48-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 48M"
    points="30,10 40,0 40,40 30,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("48-Mesial")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kiri */}
{form.tooch_number.includes("48D-fis") || form.tooch_number.includes("48D-amf") || form.tooch_number.includes("48D-cof") || form.tooch_number.includes("48D-car") ?
  <>
    {form.tooch_number.includes("48D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="48D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 48D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#48D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("48-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("48D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 48D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("48-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("48D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="48D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 48D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("48-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("48D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 48D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("48-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 48D"
    points="0,0 10,10 10,30 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("48-Distal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* center */}
{form.tooch_number.includes("48O-fis") || form.tooch_number.includes("48O-amf") || form.tooch_number.includes("48O-cof") || form.tooch_number.includes("48O-car") ?
  <>
    {form.tooch_number.includes("48O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="48O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 48O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#48O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("48-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("48O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 48O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("48-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("48O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="48O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 48O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("48-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("48O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 48O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("48-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 48O"
    points="10,10 30,10 30,30 10,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("48-Oklusal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* cfr */}
{form.tooch_number.includes("48-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{form.tooch_number.includes("48-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100">
  v
</text>
}

{form.tooch_number.includes("48-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
</g>
}      
</svg>

{form.tooch_number.includes("48-pob-47-miss-pon-pob-46-pob")
||form.tooch_number.includes("48-pob-47-miss-pon-pob-46-miss-pon-pob-45-pob")
||form.tooch_number.includes("48-pob-47-miss-pon-pob-46-miss-pon-pob-45-miss-pon-pob-44-pob")
||form.tooch_number.includes("48-pob-47-miss-pon-pob-46-miss-pon-pob-45-miss-pon-pob-44-miss-pon-pob-43-pob")
||form.tooch_number.includes("48-pob-47-miss-pon-pob-46-miss-pon-pob-45-miss-pon-pob-44-miss-pon-pob-43-miss-pon-pob-42-pob")
||form.tooch_number.includes("48-pob-47-miss-pon-pob-46-miss-pon-pob-45-miss-pon-pob-44-miss-pon-pob-43-miss-pon-pob-42-miss-pon-pob-41-pob")?
<>
{/* meb 85 - 57 */}
{form.tooch_number.includes("48-pob-47-miss-pon-pob-46-pob") &&
<div style={{ width: "130px", textAlign: "center", position: "absolute" }}>
              <svg width="100%" height="100%">

                      <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div>
}
                
{/* meb 85 - 53 */}
{form.tooch_number.includes("48-pob-47-miss-pon-pob-46-miss-pon-pob-45-pob") &&
<div style={{ width: "173px", textAlign: "center", position: "absolute" }}>
              <svg width="100%" height="100%">
                      <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div>
}

{/* meb 85 - 52 */} 
{form.tooch_number.includes("48-pob-47-miss-pon-pob-46-miss-pon-pob-45-miss-pon-pob-44-pob") &&      
<div style={{ width: "216px", textAlign: "center", position: "absolute" }}>
              <svg width="100%" height="100%">

                      <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div> 
}
            
{/* meb 85 - 52 */} 
{form.tooch_number.includes("48-pob-47-miss-pon-pob-46-miss-pon-pob-45-miss-pon-pob-44-miss-pon-pob-43-pob") &&      
<div style={{ width: "259px", textAlign: "center", position: "absolute" }}>
              <svg width="100%" height="100%">
<g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="-24" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="-24" y1="0" x2="-24" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div> 
}
            
{/* meb 85 - 52 */} 
{form.tooch_number.includes("48-pob-47-miss-pon-pob-46-miss-pon-pob-45-miss-pon-pob-44-miss-pon-pob-43-miss-pon-pob-42-pob") &&      
<div style={{ width: "302px", textAlign: "center", position: "absolute" }}>
              <svg width="100%" height="100%">

<g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="-68" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="-68" y1="0" x2="-68" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div> 
}

{/* meb 85 - 52 */} 
{form.tooch_number.includes("48-pob-47-miss-pon-pob-46-miss-pon-pob-45-miss-pon-pob-44-miss-pon-pob-43-miss-pon-pob-42-miss-pon-pob-41-pob") &&      
<div style={{ width: "345px", textAlign: "center", position: "absolute" }}>
              <svg width="100%" height="100%">
<g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="-112" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="-112" y1="0" x2="-112" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div> 
}

</>
:
<></>
}
        


{form.tooch_number.includes("48-meb-47-miss-pon-meb-46-meb")
  ||form.tooch_number.includes("48-meb-47-miss-pon-meb-46-miss-pon-meb-45-meb")
  ||form.tooch_number.includes("48-meb-47-miss-pon-meb-46-miss-pon-meb-45-miss-pon-meb-44-meb")
  ||form.tooch_number.includes("48-meb-47-miss-pon-meb-46-miss-pon-meb-45-miss-pon-meb-44-miss-pon-meb-43-meb")
  ||form.tooch_number.includes("48-meb-47-miss-pon-meb-46-miss-pon-meb-45-miss-pon-meb-44-miss-pon-meb-43-miss-pon-meb-42-meb")
  ||form.tooch_number.includes("48-meb-47-miss-pon-meb-46-miss-pon-meb-45-miss-pon-meb-44-miss-pon-meb-43-miss-pon-meb-42-miss-pon-meb-41-meb")?
<>
  {/* meb 85 - 57 */}
  {form.tooch_number.includes("48-meb-47-miss-pon-meb-46-meb") &&
 <div style={{ width: "130px", textAlign: "center", position: "absolute" }}>
 <svg width="100%" height="100%">
                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div>
  }
                  
  {/* meb 85 - 53 */}
  {form.tooch_number.includes("48-meb-47-miss-pon-meb-46-miss-pon-meb-45-meb") &&
  <div style={{ width: "173px", textAlign: "center", position: "absolute" }}>
  <svg width="100%" height="100%">
                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div>
  }
  
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("48-meb-47-miss-pon-meb-46-miss-pon-meb-45-miss-pon-meb-44-meb") &&      
  <div style={{ width: "216px", textAlign: "center", position: "absolute" }}>
  <svg width="100%" height="100%">

                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
              
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("48-meb-47-miss-pon-meb-46-miss-pon-meb-45-miss-pon-meb-44-miss-pon-meb-43-meb") &&      
 <div style={{ width: "259px", textAlign: "center", position: "absolute" }}>
 <svg width="100%" height="100%">
  <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="-24" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="-24" y1="0" x2="-24" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
              
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("48-meb-47-miss-pon-meb-46-miss-pon-meb-45-miss-pon-meb-44-miss-pon-meb-43-miss-pon-meb-42-meb") &&      
  <div style={{ width: "302px", textAlign: "center", position: "absolute" }}>
  <svg width="100%" height="100%">
  <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="-68" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="-68" y1="0" x2="-68" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
  
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("48-meb-47-miss-pon-meb-46-miss-pon-meb-45-miss-pon-meb-44-miss-pon-meb-43-miss-pon-meb-42-miss-pon-meb-41-meb") &&      
  <div style={{ width: "345px", textAlign: "center", position: "absolute" }}>
  <svg width="100%" height="100%">
  <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="-112" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="-112" y1="0" x2="-112" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
  
  </>
  :
  <></>
}
       
        
<div style={{ textAlign: "center" }}>48</div>

{form.tooch_number.includes("48-une") || form.tooch_number.includes("48-non") || form.tooch_number.includes("48-pre") || form.tooch_number.includes("48-ano") || form.tooch_number.includes("48-ipx-poc") || form.tooch_number.includes("48-mis-prd-acr") || form.tooch_number.includes("48-mis-fld-acr") ?
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
{form.tooch_number.includes("48-une") &&
<div style={{ position: "absolute", color: "black" }}>UNE</div>
}
{form.tooch_number.includes("48-non") &&
<div style={{ position: "absolute", color: "black" }}>NON</div>
}      
{form.tooch_number.includes("48-pre") &&
<div style={{ position: "absolute", color: "black" }}>PRE</div>
}      
{form.tooch_number.includes("48-ano") &&
<div style={{ position: "absolute", color: "black" }}>ANO</div>
}
{form.tooch_number.includes("48-ipx-poc") &&
<div style={{ position: "absolute", color: "black" }}>IPX</div>
}
{form.tooch_number.includes("48-mis-prd-acr") &&
<div style={{ position: "absolute", color: "black" }}>PRD</div>
} 
{form.tooch_number.includes("48-mis-fld-acr") &&
<div style={{ position: "absolute", color: "black" }}>FLD</div>
}      
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
</div>
}
      </div>
      
<div style={{ width: "42px", margin: "1px" }}>

{form.tooch_number.includes("47O-amf-rct") || form.tooch_number.includes("47O-cof-rct") ||form.tooch_number.includes("47-nvt") || form.tooch_number.includes("47-rct") || form.tooch_number.includes("47B-amf-rct")
|| form.tooch_number.includes("47P-amf-rct") || form.tooch_number.includes("47M-amf-rct") || form.tooch_number.includes("47D-amf-rct")
|| form.tooch_number.includes("47L-amf-rct") ||
form.tooch_number.includes("47B-cof-rct") || form.tooch_number.includes("47P-cof-rct") || form.tooch_number.includes("47M-cof-rct")
|| form.tooch_number.includes("47D-cof-rct") || form.tooch_number.includes("47L-cof-rct") ||
form.tooch_number.includes("47-fmc-rct") ||
form.tooch_number.includes("47-poc-rct") ?
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%"}}>
<svg width={42} height={42}>
<polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 47B"
  points="21,2 41,32 1,32" 
  style={{ fill: form.tooch_number.includes("47-nvt") ? 'white' : 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('47-Bukal')}
  onMouseLeave={() => setInfoGigi('XX-X')}
/>
</svg>
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingTop: "13px" }}>
</div>
        } 

<svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("47-fmc") || form.tooch_number.includes("47-poc") || form.tooch_number.includes("47-ipx-poc") || form.tooch_number.match(/\b47-pob\b/) || form.tooch_number.match(/\b47-miss-pon-pob\b/) || form.tooch_number.match(/\b47-meb\b/) || form.tooch_number.match(/\b47-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
{form.tooch_number.includes("47-poc") || form.tooch_number.includes("47-ipx-poc") || form.tooch_number.match(/\b47-pob\b/) || form.tooch_number.match(/\b47-miss-pon-pob\b/) ?
<g>
{/* Atas */}
{form.tooch_number.includes("47L-fis") || form.tooch_number.includes("47L-amf") || form.tooch_number.includes("47L-cof") || form.tooch_number.includes("47L-car") ?
  <>
    {form.tooch_number.includes("47L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="47L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 47L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#47L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("47-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("47L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 47L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("47-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("47L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="47L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 47L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("47-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("47L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 47L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("47-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
<svg width="40" height="40">
  <defs>
    <pattern id="47L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
    </pattern>
  </defs>
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 47L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: '#007610', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("47-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
</svg>
}

{/* Bawah */}
{form.tooch_number.includes("47B-fis") || form.tooch_number.includes("47B-amf") || form.tooch_number.includes("47B-cof") || form.tooch_number.includes("47B-car") ?
  <>
    {form.tooch_number.includes("47B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="47B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 47B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#47B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("47-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("47B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 47B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("47-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("47B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="47B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 47B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("47-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("47B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 47B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("47-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="47B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 47B"
      points="10,30 30,30 40,40 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("47-Bukal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kanan */}
{form.tooch_number.includes("47M-fis") || form.tooch_number.includes("47M-amf") || form.tooch_number.includes("47M-cof") || form.tooch_number.includes("47M-car") ?
  <>
    {form.tooch_number.includes("47M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="47M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 47M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#47M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("47-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("47M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 47M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("47-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("47M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="47M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 47M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("47-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("47M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 47M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("47-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="47M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 47M"
      points="30,10 40,0 40,40 30,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("47-Mesial")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kiri */}
{form.tooch_number.includes("47D-fis") || form.tooch_number.includes("47D-amf") || form.tooch_number.includes("47D-cof") || form.tooch_number.includes("47D-car") ?
  <>
    {form.tooch_number.includes("47D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="47D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 47D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#47D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("47-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("47D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 47D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("47-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("47D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="47D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 47D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("47-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("47D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 47D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("47-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="47D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 47D"
      points="0,0 10,10 10,30 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("47-Distal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* center */}
{form.tooch_number.includes("47O-fis") || form.tooch_number.includes("47O-amf") || form.tooch_number.includes("47O-cof") || form.tooch_number.includes("47O-car") ?
  <>
    {form.tooch_number.includes("47O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="47O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 47O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#47O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("47-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("47O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 47O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("47-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("47O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="47O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 47O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("47-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("47O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 47O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("47-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="47O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 47O"
      points="10,10 30,10 30,30 10,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("47-Oklusal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* cfr */}
{form.tooch_number.includes("47-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{/* rrx */}
{form.tooch_number.includes("47-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100">
  v
</text>
}
      
{/* mis */}
{form.tooch_number.includes("47-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
        
</g>
:
<g>
{/* Atas */}
{form.tooch_number.includes("47L-fis") || form.tooch_number.includes("47L-amf") || form.tooch_number.includes("47L-cof") || form.tooch_number.includes("47L-car") ?
  <>
    {form.tooch_number.includes("47L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="47L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 47L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#47L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("47-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("47L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 47L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("47-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("47L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="47L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 47L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("47-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("47L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 47L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("47-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 47L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("47-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Bawah */}
{form.tooch_number.includes("47B-fis") || form.tooch_number.includes("47B-amf") || form.tooch_number.includes("47B-cof") || form.tooch_number.includes("47B-car") ?
  <>
    {form.tooch_number.includes("47B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="47B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 47B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#47B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("47-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("47B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 47B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("47-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("47B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="47B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 47B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("47-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("47B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 47B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("47-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 47B"
    points="10,30 30,30 40,40 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("47-Bukal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kanan */}
{form.tooch_number.includes("47M-fis") || form.tooch_number.includes("47M-amf") || form.tooch_number.includes("47M-cof") || form.tooch_number.includes("47M-car") ?
  <>
    {form.tooch_number.includes("47M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="47M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 47M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#47M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("47-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("47M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 47M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("47-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("47M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="47M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 47M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("47-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("47M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 47M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("47-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 47M"
    points="30,10 40,0 40,40 30,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("47-Mesial")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kiri */}
{form.tooch_number.includes("47D-fis") || form.tooch_number.includes("47D-amf") || form.tooch_number.includes("47D-cof") || form.tooch_number.includes("47D-car") ?
  <>
    {form.tooch_number.includes("47D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="47D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 47D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#47D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("47-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("47D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 47D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("47-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("47D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="47D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 47D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("47-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("47D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 47D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("47-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 47D"
    points="0,0 10,10 10,30 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("47-Distal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* center */}
{form.tooch_number.includes("47O-fis") || form.tooch_number.includes("47O-amf") || form.tooch_number.includes("47O-cof") || form.tooch_number.includes("47O-car") ?
  <>
    {form.tooch_number.includes("47O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="47O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 47O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#47O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("47-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("47O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 47O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("47-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("47O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="47O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 47O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("47-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("47O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 47O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("47-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 47O"
    points="10,10 30,10 30,30 10,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("47-Oklusal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* cfr */}
{form.tooch_number.includes("47-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{form.tooch_number.includes("47-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100">
  v
</text>
}

{form.tooch_number.includes("47-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
</g>
}      
</svg>

{form.tooch_number.includes("47-pob-46-miss-pon-pob-45-pob")
  ||form.tooch_number.includes("47-pob-46-miss-pon-pob-45-miss-pon-pob-44-pob")
  ||form.tooch_number.includes("47-pob-46-miss-pon-pob-45-miss-pon-pob-44-miss-pon-pob-43-pob")
  ||form.tooch_number.includes("47-pob-46-miss-pon-pob-45-miss-pon-pob-44-miss-pon-pob-43-miss-pon-pob-42-pob")
  ||form.tooch_number.includes("47-pob-46-miss-pon-pob-45-miss-pon-pob-44-miss-pon-pob-43-miss-pon-pob-42-miss-pon-pob-41-pob")?
  <>
  {/* meb 85 - 57 */}
  {form.tooch_number.includes("47-pob-46-miss-pon-pob-45-pob") &&
  <div style={{ width: "130px", textAlign: "center", position: "absolute" }}>
  <svg width="100%" height="100%">

                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div>
  }
                  
  {/* meb 85 - 53 */}
  {form.tooch_number.includes("47-pob-46-miss-pon-pob-45-miss-pon-pob-44-pob") &&
 <div style={{ width: "173px", textAlign: "center", position: "absolute" }}>
 <svg width="100%" height="100%">


                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div>
  }
  
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("47-pob-46-miss-pon-pob-45-miss-pon-pob-44-miss-pon-pob-43-pob") &&      
  <div style={{ width: "216px", textAlign: "center", position: "absolute" }}>
  <svg width="100%" height="100%">
                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
              
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("47-pob-46-miss-pon-pob-45-miss-pon-pob-44-miss-pon-pob-43-miss-pon-pob-42-pob") &&      
  <div style={{ width: "259px", textAlign: "center", position: "absolute" }}>
  <svg width="100%" height="100%">
  <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="-24" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="-24" y1="0" x2="-24" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
              
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("47-pob-46-miss-pon-pob-45-miss-pon-pob-44-miss-pon-pob-43-miss-pon-pob-42-miss-pon-pob-41-pob") &&      
  <div style={{ width: "302px", textAlign: "center", position: "absolute" }}>
  <svg width="100%" height="100%">

  <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="-68" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="-68" y1="0" x2="-68" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
  
  </>
  :
  <></>
  }
          
  
  {form.tooch_number.includes("47-meb-46-miss-pon-meb-45-meb")
    ||form.tooch_number.includes("47-meb-46-miss-pon-meb-45-miss-pon-meb-44-meb")
    ||form.tooch_number.includes("47-meb-46-miss-pon-meb-45-miss-pon-meb-44-miss-pon-meb-43-meb")
    ||form.tooch_number.includes("47-meb-46-miss-pon-meb-45-miss-pon-meb-44-miss-pon-meb-43-miss-pon-meb-42-meb")
    ||form.tooch_number.includes("47-meb-46-miss-pon-meb-45-miss-pon-meb-44-miss-pon-meb-43-miss-pon-meb-42-miss-pon-meb-41-meb")?
    <>
    {/* meb 85 - 57 */}
    {form.tooch_number.includes("47-meb-46-miss-pon-meb-45-meb") &&
   <div style={{ width: "130px", textAlign: "center", position: "absolute" }}>
   <svg width="100%" height="100%">
                          <g transform="rotate(180 108.5 6)">
    {/* Garis ke samping dan ke bawah (L-shape) */}
    <line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
     </g>
    </svg>
    </div>
    }
                    
    {/* meb 85 - 53 */}
    {form.tooch_number.includes("47-meb-46-miss-pon-meb-45-miss-pon-meb-44-meb") &&
   <div style={{ width: "173px", textAlign: "center", position: "absolute" }}>
   <svg width="100%" height="100%">

                          <g transform="rotate(180 108.5 6)">
    {/* Garis ke samping dan ke bawah (L-shape) */}
    <line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
     </g>
    </svg>
    </div>
    }
    
    {/* meb 85 - 52 */} 
    {form.tooch_number.includes("47-meb-46-miss-pon-meb-45-miss-pon-meb-44-miss-pon-meb-43-meb") &&      
    <div style={{ width: "216px", textAlign: "center", position: "absolute" }}>
    <svg width="100%" height="100%">

                          <g transform="rotate(180 108.5 6)">
    {/* Garis ke samping dan ke bawah (L-shape) */}
    <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
     </g>
    </svg>
    </div> 
    }
                
    {/* meb 85 - 52 */} 
    {form.tooch_number.includes("47-meb-46-miss-pon-meb-45-miss-pon-meb-44-miss-pon-meb-43-miss-pon-meb-42-meb") &&      
    <div style={{ width: "259px", textAlign: "center", position: "absolute" }}>
    <svg width="100%" height="100%">

    <g transform="rotate(180 108.5 6)">
    {/* Garis ke samping dan ke bawah (L-shape) */}
    <line x1="-24" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="-24" y1="0" x2="-24" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
     </g>
    </svg>
    </div> 
    }
                
    {/* meb 85 - 52 */} 
    {form.tooch_number.includes("47-meb-46-miss-pon-meb-45-miss-pon-meb-44-miss-pon-meb-43-miss-pon-meb-42-miss-pon-meb-41-meb") &&      
    <div style={{ width: "302px", textAlign: "center", position: "absolute" }}>
    <svg width="100%" height="100%">

    <g transform="rotate(180 108.5 6)">
    {/* Garis ke samping dan ke bawah (L-shape) */}
    <line x1="-68" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="-68" y1="0" x2="-68" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
     </g>
    </svg>
    </div> 
    }
    
    </>
    :
    <></>
    }
        
<div style={{ textAlign: "center" }}>47</div>

{form.tooch_number.includes("47-une") || form.tooch_number.includes("47-non") || form.tooch_number.includes("47-pre") || form.tooch_number.includes("47-ano") || form.tooch_number.includes("47-ipx-poc") || form.tooch_number.includes("47-mis-prd-acr") || form.tooch_number.includes("47-mis-fld-acr") ?
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
{form.tooch_number.includes("47-une") &&
<div style={{ position: "absolute", color: "black" }}>UNE</div>
}
{form.tooch_number.includes("47-non") &&
<div style={{ position: "absolute", color: "black" }}>NON</div>
}      
{form.tooch_number.includes("47-pre") &&
<div style={{ position: "absolute", color: "black" }}>PRE</div>
}      
{form.tooch_number.includes("47-ano") &&
<div style={{ position: "absolute", color: "black" }}>ANO</div>
}
{form.tooch_number.includes("47-ipx-poc") &&
<div style={{ position: "absolute", color: "black" }}>IPX</div>
}
{form.tooch_number.includes("47-mis-prd-acr") &&
<div style={{ position: "absolute", color: "black" }}>PRD</div>
} 
{form.tooch_number.includes("47-mis-fld-acr") &&
<div style={{ position: "absolute", color: "black" }}>FLD</div>
}      
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
</div>
}
</div>

<div style={{ width: "42px", margin: "1px" }}>

{form.tooch_number.includes("46O-amf-rct") || form.tooch_number.includes("46O-cof-rct") || form.tooch_number.includes("46-nvt") || form.tooch_number.includes("46-rct") || form.tooch_number.includes("46B-amf-rct")
|| form.tooch_number.includes("46P-amf-rct") || form.tooch_number.includes("46M-amf-rct") || form.tooch_number.includes("46D-amf-rct")
|| form.tooch_number.includes("46L-amf-rct") ||
form.tooch_number.includes("46B-cof-rct") || form.tooch_number.includes("46P-cof-rct") || form.tooch_number.includes("46M-cof-rct")
|| form.tooch_number.includes("46D-cof-rct") || form.tooch_number.includes("46L-cof-rct") ||
form.tooch_number.includes("46-fmc-rct") ||
form.tooch_number.includes("46-poc-rct") ?
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%"}}>
<svg width={42} height={42}>
<polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 46B"
  points="21,2 41,32 1,32" 
  style={{ fill: form.tooch_number.includes("46-nvt") ? 'white' : 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('46-Bukal')}
  onMouseLeave={() => setInfoGigi('XX-X')}
/>
</svg>
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingTop: "13px" }}>
</div>
        } 

<svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("46-fmc") || form.tooch_number.includes("46-poc") || form.tooch_number.includes("46-ipx-poc") || form.tooch_number.match(/\b46-pob\b/) || form.tooch_number.match(/\b46-miss-pon-pob\b/) || form.tooch_number.match(/\b46-meb\b/) || form.tooch_number.match(/\b46-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
{form.tooch_number.includes("46-poc") || form.tooch_number.includes("46-ipx-poc") || form.tooch_number.match(/\b46-pob\b/) || form.tooch_number.match(/\b46-miss-pon-pob\b/) ?
<g>
{/* Atas */}
{form.tooch_number.includes("46L-fis") || form.tooch_number.includes("46L-amf") || form.tooch_number.includes("46L-cof") || form.tooch_number.includes("46L-car") ?
  <>
    {form.tooch_number.includes("46L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="46L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 46L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#46L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("46-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("46L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 46L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("46-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("46L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="46L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 46L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("46-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("46L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 46L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("46-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
<svg width="40" height="40">
  <defs>
    <pattern id="46L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
    </pattern>
  </defs>
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 46L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: '#007610', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("46-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
</svg>
}

{/* Bawah */}
{form.tooch_number.includes("46B-fis") || form.tooch_number.includes("46B-amf") || form.tooch_number.includes("46B-cof") || form.tooch_number.includes("46B-car") ?
  <>
    {form.tooch_number.includes("46B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="46B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 46B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#46B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("46-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("46B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 46B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("46-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("46B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="46B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 46B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("46-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("46B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 46B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("46-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="46B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 46B"
      points="10,30 30,30 40,40 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("46-Bukal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kanan */}
{form.tooch_number.includes("46M-fis") || form.tooch_number.includes("46M-amf") || form.tooch_number.includes("46M-cof") || form.tooch_number.includes("46M-car") ?
  <>
    {form.tooch_number.includes("46M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="46M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 46M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#46M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("46-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("46M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 46M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("46-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("46M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="46M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 46M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("46-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("46M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 46M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("46-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="46M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 46M"
      points="30,10 40,0 40,40 30,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("46-Mesial")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kiri */}
{form.tooch_number.includes("46D-fis") || form.tooch_number.includes("46D-amf") || form.tooch_number.includes("46D-cof") || form.tooch_number.includes("46D-car") ?
  <>
    {form.tooch_number.includes("46D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="46D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 46D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#46D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("46-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("46D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 46D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("46-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("46D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="46D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 46D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("46-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("46D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 46D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("46-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="46D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 46D"
      points="0,0 10,10 10,30 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("46-Distal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* center */}
{form.tooch_number.includes("46O-fis") || form.tooch_number.includes("46O-amf") || form.tooch_number.includes("46O-cof") || form.tooch_number.includes("46O-car") ?
  <>
    {form.tooch_number.includes("46O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="46O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 46O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#46O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("46-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("46O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 46O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("46-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("46O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="46O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 46O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("46-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("46O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 46O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("46-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="46O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 46O"
      points="10,10 30,10 30,30 10,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("46-Oklusal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* cfr */}
{form.tooch_number.includes("46-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{/* rrx */}
{form.tooch_number.includes("46-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100">
  v
</text>
}
      
{/* mis */}
{form.tooch_number.includes("46-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
        
</g>
:
<g>
{/* Atas */}
{form.tooch_number.includes("46L-fis") || form.tooch_number.includes("46L-amf") || form.tooch_number.includes("46L-cof") || form.tooch_number.includes("46L-car") ?
  <>
    {form.tooch_number.includes("46L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="46L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 46L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#46L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("46-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("46L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 46L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("46-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("46L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="46L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 46L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("46-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("46L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 46L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("46-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 46L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("46-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Bawah */}
{form.tooch_number.includes("46B-fis") || form.tooch_number.includes("46B-amf") || form.tooch_number.includes("46B-cof") || form.tooch_number.includes("46B-car") ?
  <>
    {form.tooch_number.includes("46B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="46B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 46B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#46B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("46-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("46B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 46B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("46-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("46B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="46B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 46B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("46-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("46B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 46B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("46-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 46B"
    points="10,30 30,30 40,40 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("46-Bukal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kanan */}
{form.tooch_number.includes("46M-fis") || form.tooch_number.includes("46M-amf") || form.tooch_number.includes("46M-cof") || form.tooch_number.includes("46M-car") ?
  <>
    {form.tooch_number.includes("46M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="46M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 46M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#46M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("46-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("46M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 46M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("46-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("46M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="46M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 46M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("46-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("46M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 46M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("46-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 46M"
    points="30,10 40,0 40,40 30,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("46-Mesial")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kiri */}
{form.tooch_number.includes("46D-fis") || form.tooch_number.includes("46D-amf") || form.tooch_number.includes("46D-cof") || form.tooch_number.includes("46D-car") ?
  <>
    {form.tooch_number.includes("46D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="46D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 46D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#46D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("46-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("46D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 46D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("46-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("46D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="46D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 46D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("46-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("46D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 46D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("46-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 46D"
    points="0,0 10,10 10,30 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("46-Distal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* center */}
{form.tooch_number.includes("46O-fis") || form.tooch_number.includes("46O-amf") || form.tooch_number.includes("46O-cof") || form.tooch_number.includes("46O-car") ?
  <>
    {form.tooch_number.includes("46O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="46O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 46O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#46O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("46-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("46O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 46O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("46-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("46O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="46O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 46O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("46-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("46O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 46O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("46-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 46O"
    points="10,10 30,10 30,30 10,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("46-Oklusal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* cfr */}
{form.tooch_number.includes("46-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{form.tooch_number.includes("46-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100">
  v
</text>
}

{form.tooch_number.includes("46-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
</g>
}      
</svg>

{form.tooch_number.includes("46-pob-45-miss-pon-pob-44-pob")
  ||form.tooch_number.includes("46-pob-45-miss-pon-pob-44-miss-pon-pob-43-pob")
  ||form.tooch_number.includes("46-pob-45-miss-pon-pob-44-miss-pon-pob-43-miss-pon-pob-42-pob")
  ||form.tooch_number.includes("46-pob-45-miss-pon-pob-44-miss-pon-pob-43-miss-pon-pob-42-miss-pon-pob-41-pob")?
  <>
  {/* meb 85 - 57 */}
  {form.tooch_number.includes("46-pob-45-miss-pon-pob-44-pob") &&
 <div style={{ width: "130px", textAlign: "center", position: "absolute" }}>
 <svg width="100%" height="100%">

                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div>
  }
                  
  {/* meb 85 - 53 */}
  {form.tooch_number.includes("46-pob-45-miss-pon-pob-44-miss-pon-pob-43-pob") &&
  <div style={{ width: "173px", textAlign: "center", position: "absolute" }}>
  <svg width="100%" height="100%">

                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div>
  }
  
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("46-pob-45-miss-pon-pob-44-miss-pon-pob-43-miss-pon-pob-42-pob") &&      
  <div style={{ width: "216px", textAlign: "center", position: "absolute" }}>
  <svg width="100%" height="100%">

                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
              
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("46-pob-45-miss-pon-pob-44-miss-pon-pob-43-miss-pon-pob-42-miss-pon-pob-41-pob") &&      
 <div style={{ width: "259px", textAlign: "center", position: "absolute" }}>
 <svg width="100%" height="100%">
  <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="-24" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="-24" y1="0" x2="-24" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
  
  </>
  :
  <></>
  }
          
  
  {form.tooch_number.includes("46-meb-45-miss-pon-meb-44-meb")
    ||form.tooch_number.includes("46-meb-45-miss-pon-meb-44-miss-pon-meb-43-meb")
    ||form.tooch_number.includes("46-meb-45-miss-pon-meb-44-miss-pon-meb-43-miss-pon-meb-42-meb")
    ||form.tooch_number.includes("46-meb-45-miss-pon-meb-44-miss-pon-meb-43-miss-pon-meb-42-miss-pon-meb-41-meb")?
    <>
    {/* meb 85 - 57 */}
    {form.tooch_number.includes("46-meb-45-miss-pon-meb-44-meb") &&
   <div style={{ width: "130px", textAlign: "center", position: "absolute" }}>
   <svg width="100%" height="100%">

                          <g transform="rotate(180 108.5 6)">
    {/* Garis ke samping dan ke bawah (L-shape) */}
    <line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
     </g>
    </svg>
    </div>
    }
                    
    {/* meb 85 - 53 */}
    {form.tooch_number.includes("46-meb-45-miss-pon-meb-44-miss-pon-meb-43-meb") &&
   <div style={{ width: "173px", textAlign: "center", position: "absolute" }}>
   <svg width="100%" height="100%">

                          <g transform="rotate(180 108.5 6)">
    {/* Garis ke samping dan ke bawah (L-shape) */}
    <line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
     </g>
    </svg>
    </div>
    }
    
    {/* meb 85 - 52 */} 
    {form.tooch_number.includes("46-meb-45-miss-pon-meb-44-miss-pon-meb-43-miss-pon-meb-42-meb") &&      
   <div style={{ width: "216px", textAlign: "center", position: "absolute" }}>
   <svg width="100%" height="100%">
                          <g transform="rotate(180 108.5 6)">
    {/* Garis ke samping dan ke bawah (L-shape) */}
    <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
     </g>
    </svg>
    </div> 
    }
                
    {/* meb 85 - 52 */} 
    {form.tooch_number.includes("46-meb-45-miss-pon-meb-44-miss-pon-meb-43-miss-pon-meb-42-miss-pon-meb-41-meb") &&      
    <div style={{ width: "259px", textAlign: "center", position: "absolute" }}>
    <svg width="100%" height="100%">

    <g transform="rotate(180 108.5 6)">
    {/* Garis ke samping dan ke bawah (L-shape) */}
    <line x1="-24" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="-24" y1="0" x2="-24" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
     </g>
    </svg>
    </div> 
    }
    
    </>
    :
    <></>
    }
        
<div style={{ textAlign: "center" }}>46</div>

{form.tooch_number.includes("46-une") || form.tooch_number.includes("46-non") || form.tooch_number.includes("46-pre") || form.tooch_number.includes("46-ano") || form.tooch_number.includes("46-ipx-poc") || form.tooch_number.includes("46-mis-prd-acr") || form.tooch_number.includes("46-mis-fld-acr") ?
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
{form.tooch_number.includes("46-une") &&
<div style={{ position: "absolute", color: "black" }}>UNE</div>
}
{form.tooch_number.includes("46-non") &&
<div style={{ position: "absolute", color: "black" }}>NON</div>
}      
{form.tooch_number.includes("46-pre") &&
<div style={{ position: "absolute", color: "black" }}>PRE</div>
}      
{form.tooch_number.includes("46-ano") &&
<div style={{ position: "absolute", color: "black" }}>ANO</div>
}
{form.tooch_number.includes("46-ipx-poc") &&
<div style={{ position: "absolute", color: "black" }}>IPX</div>
}
{form.tooch_number.includes("46-mis-prd-acr") &&
<div style={{ position: "absolute", color: "black" }}>PRD</div>
} 
{form.tooch_number.includes("46-mis-fld-acr") &&
<div style={{ position: "absolute", color: "black" }}>FLD</div>
}      
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
</div>
}
</div>

<div style={{ width: "42px", margin: "1px" }}>

{form.tooch_number.includes("45O-amf-rct") || form.tooch_number.includes("45O-cof-rct") ||form.tooch_number.includes("45-nvt") || form.tooch_number.includes("45-rct") || form.tooch_number.includes("45B-amf-rct")
|| form.tooch_number.includes("45P-amf-rct") || form.tooch_number.includes("45M-amf-rct") || form.tooch_number.includes("45D-amf-rct")
|| form.tooch_number.includes("45L-amf-rct") ||
form.tooch_number.includes("45B-cof-rct") || form.tooch_number.includes("45P-cof-rct") || form.tooch_number.includes("45M-cof-rct")
|| form.tooch_number.includes("45D-cof-rct") || form.tooch_number.includes("45L-cof-rct") ||
form.tooch_number.includes("45-fmc-rct") ||
form.tooch_number.includes("45-poc-rct") ?
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%"}}>
<svg width={42} height={42}>
<polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 45B"
  points="21,2 41,32 1,32" 
  style={{ fill: form.tooch_number.includes("45-nvt") ? 'white' : 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('45-Bukal')}
  onMouseLeave={() => setInfoGigi('XX-X')}
/>
</svg>
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingTop: "13px" }}>
</div>
        } 

<svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("45-fmc") || form.tooch_number.includes("45-poc") || form.tooch_number.includes("45-ipx-poc") || form.tooch_number.match(/\b45-pob\b/) || form.tooch_number.match(/\b45-miss-pon-pob\b/) || form.tooch_number.match(/\b45-meb\b/) || form.tooch_number.match(/\b45-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
{form.tooch_number.includes("45-poc") || form.tooch_number.includes("45-ipx-poc") || form.tooch_number.match(/\b45-pob\b/) || form.tooch_number.match(/\b45-miss-pon-pob\b/) ?
<g>
{/* Atas */}
{form.tooch_number.includes("45L-fis") || form.tooch_number.includes("45L-amf") || form.tooch_number.includes("45L-cof") || form.tooch_number.includes("45L-car") ?
  <>
    {form.tooch_number.includes("45L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="45L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 45L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#45L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("45-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("45L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 45L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("45-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("45L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="45L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 45L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("45-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("45L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 45L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("45-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
<svg width="40" height="40">
  <defs>
    <pattern id="45L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
    </pattern>
  </defs>
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 45L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: '#007610', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("45-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
</svg>
}

{/* Bawah */}
{form.tooch_number.includes("45B-fis") || form.tooch_number.includes("45B-amf") || form.tooch_number.includes("45B-cof") || form.tooch_number.includes("45B-car") ?
  <>
    {form.tooch_number.includes("45B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="45B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 45B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#45B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("45-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("45B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 45B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("45-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("45B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="45B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 45B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("45-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("45B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 45B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("45-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="45B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 45B"
      points="10,30 30,30 40,40 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("45-Bukal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kanan */}
{form.tooch_number.includes("45M-fis") || form.tooch_number.includes("45M-amf") || form.tooch_number.includes("45M-cof") || form.tooch_number.includes("45M-car") ?
  <>
    {form.tooch_number.includes("45M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="45M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 45M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#45M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("45-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("45M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 45M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("45-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("45M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="45M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 45M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("45-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("45M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 45M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("45-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="45M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 45M"
      points="30,10 40,0 40,40 30,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("45-Mesial")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kiri */}
{form.tooch_number.includes("45D-fis") || form.tooch_number.includes("45D-amf") || form.tooch_number.includes("45D-cof") || form.tooch_number.includes("45D-car") ?
  <>
    {form.tooch_number.includes("45D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="45D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 45D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#45D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("45-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("45D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 45D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("45-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("45D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="45D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 45D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("45-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("45D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 45D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("45-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="45D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 45D"
      points="0,0 10,10 10,30 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("45-Distal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* center */}
{form.tooch_number.includes("45O-fis") || form.tooch_number.includes("45O-amf") || form.tooch_number.includes("45O-cof") || form.tooch_number.includes("45O-car") ?
  <>
    {form.tooch_number.includes("45O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="45O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 45O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#45O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("45-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("45O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 45O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("45-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("45O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="45O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 45O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("45-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("45O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 45O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("45-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="45O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 45O"
      points="10,10 30,10 30,30 10,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("45-Oklusal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* cfr */}
{form.tooch_number.includes("45-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{/* rrx */}
{form.tooch_number.includes("45-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100">
  v
</text>
}
      
{/* mis */}
{form.tooch_number.includes("45-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
        
</g>
:
<g>
{/* Atas */}
{form.tooch_number.includes("45L-fis") || form.tooch_number.includes("45L-amf") || form.tooch_number.includes("45L-cof") || form.tooch_number.includes("45L-car") ?
  <>
    {form.tooch_number.includes("45L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="45L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 45L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#45L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("45-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("45L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 45L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("45-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("45L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="45L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 45L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("45-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("45L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 45L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("45-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 45L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("45-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Bawah */}
{form.tooch_number.includes("45B-fis") || form.tooch_number.includes("45B-amf") || form.tooch_number.includes("45B-cof") || form.tooch_number.includes("45B-car") ?
  <>
    {form.tooch_number.includes("45B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="45B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 45B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#45B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("45-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("45B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 45B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("45-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("45B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="45B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 45B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("45-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("45B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 45B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("45-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 45B"
    points="10,30 30,30 40,40 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("45-Bukal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kanan */}
{form.tooch_number.includes("45M-fis") || form.tooch_number.includes("45M-amf") || form.tooch_number.includes("45M-cof") || form.tooch_number.includes("45M-car") ?
  <>
    {form.tooch_number.includes("45M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="45M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 45M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#45M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("45-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("45M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 45M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("45-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("45M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="45M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 45M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("45-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("45M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 45M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("45-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 45M"
    points="30,10 40,0 40,40 30,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("45-Mesial")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kiri */}
{form.tooch_number.includes("45D-fis") || form.tooch_number.includes("45D-amf") || form.tooch_number.includes("45D-cof") || form.tooch_number.includes("45D-car") ?
  <>
    {form.tooch_number.includes("45D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="45D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 45D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#45D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("45-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("45D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 45D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("45-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("45D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="45D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 45D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("45-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("45D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 45D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("45-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 45D"
    points="0,0 10,10 10,30 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("45-Distal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* center */}
{form.tooch_number.includes("45O-fis") || form.tooch_number.includes("45O-amf") || form.tooch_number.includes("45O-cof") || form.tooch_number.includes("45O-car") ?
  <>
    {form.tooch_number.includes("45O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="45O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 45O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#45O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("45-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("45O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 45O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("45-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("45O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="45O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 45O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("45-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("45O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 45O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("45-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 45O"
    points="10,10 30,10 30,30 10,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("45-Oklusal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* cfr */}
{form.tooch_number.includes("45-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{form.tooch_number.includes("45-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100">
  v
</text>
}

{form.tooch_number.includes("45-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
</g>
}      
</svg>

{form.tooch_number.includes("45-pob-44-miss-pon-pob-43-pob")
  ||form.tooch_number.includes("45-pob-44-miss-pon-pob-43-miss-pon-pob-42-pob")
  ||form.tooch_number.includes("45-pob-44-miss-pon-pob-43-miss-pon-pob-42-miss-pon-pob-41-pob")?
  <>
                  
  {/* meb 85 - 53 */}
  {form.tooch_number.includes("45-pob-44-miss-pon-pob-43-pob") &&
 <div style={{ width: "130px", textAlign: "center", position: "absolute" }}>
 <svg width="100%" height="100%">

                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div>
  }
  
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("45-pob-44-miss-pon-pob-43-miss-pon-pob-42-pob") &&      
  <div style={{ width: "173px", textAlign: "center", position: "absolute" }}>
  <svg width="100%" height="100%">

                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
              
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("45-pob-44-miss-pon-pob-43-miss-pon-pob-42-miss-pon-pob-41-pob") &&      
  <div style={{ width: "216px", textAlign: "center", position: "absolute" }}>
  <svg width="100%" height="100%">

  <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
  
  </>
  :
  <></>
  }
          
  
  {form.tooch_number.includes("45-meb-44-miss-pon-meb-43-meb")
    ||form.tooch_number.includes("45-meb-44-miss-pon-meb-43-miss-pon-meb-42-meb")
    ||form.tooch_number.includes("45-meb-44-miss-pon-meb-43-miss-pon-meb-42-miss-pon-meb-41-meb")?
    <>
                    
    {/* meb 85 - 53 */}
    {form.tooch_number.includes("45-meb-44-miss-pon-meb-43-meb") &&
    <div style={{ width: "130px", textAlign: "center", position: "absolute" }}>
    <svg width="100%" height="100%">
                          <g transform="rotate(180 108.5 6)">
    {/* Garis ke samping dan ke bawah (L-shape) */}
    <line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
     </g>
    </svg>
    </div>
    }
    
    {/* meb 85 - 52 */} 
    {form.tooch_number.includes("45-meb-44-miss-pon-meb-43-miss-pon-meb-42-meb") &&      
   <div style={{ width: "173px", textAlign: "center", position: "absolute" }}>
   <svg width="100%" height="100%">

                          <g transform="rotate(180 108.5 6)">
    {/* Garis ke samping dan ke bawah (L-shape) */}
    <line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
     </g>
    </svg>
    </div> 
    }
                
    {/* meb 85 - 52 */} 
    {form.tooch_number.includes("45-meb-44-miss-pon-meb-43-miss-pon-meb-42-miss-pon-meb-41-meb") &&      
    <div style={{ width: "216px", textAlign: "center", position: "absolute" }}>
    <svg width="100%" height="100%">

    <g transform="rotate(180 108.5 6)">
    {/* Garis ke samping dan ke bawah (L-shape) */}
    <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
     </g>
    </svg>
    </div> 
    }
    
    </>
    :
    <></>
    }
        
<div style={{ textAlign: "center" }}>45</div>

{form.tooch_number.includes("45-une") || form.tooch_number.includes("45-non") || form.tooch_number.includes("45-pre") || form.tooch_number.includes("45-ano") || form.tooch_number.includes("45-ipx-poc") || form.tooch_number.includes("45-mis-prd-acr") || form.tooch_number.includes("45-mis-fld-acr") ?
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
{form.tooch_number.includes("45-une") &&
<div style={{ position: "absolute", color: "black" }}>UNE</div>
}
{form.tooch_number.includes("45-non") &&
<div style={{ position: "absolute", color: "black" }}>NON</div>
}      
{form.tooch_number.includes("45-pre") &&
<div style={{ position: "absolute", color: "black" }}>PRE</div>
}      
{form.tooch_number.includes("45-ano") &&
<div style={{ position: "absolute", color: "black" }}>ANO</div>
}
{form.tooch_number.includes("45-ipx-poc") &&
<div style={{ position: "absolute", color: "black" }}>IPX</div>
}
{form.tooch_number.includes("45-mis-prd-acr") &&
<div style={{ position: "absolute", color: "black" }}>PRD</div>
} 
{form.tooch_number.includes("45-mis-fld-acr") &&
<div style={{ position: "absolute", color: "black" }}>FLD</div>
}      
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
</div>
}
</div>

<div style={{ width: "42px", margin: "1px" }}>


{form.tooch_number.includes("44O-amf-rct") || form.tooch_number.includes("44O-cof-rct") ||form.tooch_number.includes("44-nvt") || form.tooch_number.includes("44-rct") || form.tooch_number.includes("44B-amf-rct")
|| form.tooch_number.includes("44P-amf-rct") || form.tooch_number.includes("44M-amf-rct") || form.tooch_number.includes("44D-amf-rct")
|| form.tooch_number.includes("44L-amf-rct") ||
form.tooch_number.includes("44B-cof-rct") || form.tooch_number.includes("44P-cof-rct") || form.tooch_number.includes("44M-cof-rct")
|| form.tooch_number.includes("44D-cof-rct") || form.tooch_number.includes("44L-cof-rct") ||
form.tooch_number.includes("44-fmc-rct") ||
form.tooch_number.includes("44-poc-rct") ?
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%"}}>
<svg width={42} height={42}>
<polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 44B"
  points="21,2 41,32 1,32" 
  style={{ fill: form.tooch_number.includes("44-nvt") ? 'white' : 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('44-Bukal')}
  onMouseLeave={() => setInfoGigi('XX-X')}
/>
</svg>
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingTop: "13px" }}>
</div>
        } 

<svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("44-fmc") || form.tooch_number.includes("44-poc") || form.tooch_number.includes("44-ipx-poc") || form.tooch_number.match(/\b44-pob\b/) || form.tooch_number.match(/\b44-miss-pon-pob\b/) || form.tooch_number.match(/\b44-meb\b/) || form.tooch_number.match(/\b44-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
{form.tooch_number.includes("44-poc") || form.tooch_number.includes("44-ipx-poc") || form.tooch_number.match(/\b44-pob\b/) || form.tooch_number.match(/\b44-miss-pon-pob\b/) ?
<g>
{/* Atas */}
{form.tooch_number.includes("44L-fis") || form.tooch_number.includes("44L-amf") || form.tooch_number.includes("44L-cof") || form.tooch_number.includes("44L-car") ?
  <>
    {form.tooch_number.includes("44L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="44L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 44L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#44L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("44-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("44L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 44L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("44-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("44L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="44L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 44L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("44-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("44L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 44L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("44-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
<svg width="40" height="40">
  <defs>
    <pattern id="44L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
    </pattern>
  </defs>
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 44L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: '#007610', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("44-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
</svg>
}

{/* Bawah */}
{form.tooch_number.includes("44B-fis") || form.tooch_number.includes("44B-amf") || form.tooch_number.includes("44B-cof") || form.tooch_number.includes("44B-car") ?
  <>
    {form.tooch_number.includes("44B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="44B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 44B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#44B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("44-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("44B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 44B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("44-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("44B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="44B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 44B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("44-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("44B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 44B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("44-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="44B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 44B"
      points="10,30 30,30 40,40 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("44-Bukal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kanan */}
{form.tooch_number.includes("44M-fis") || form.tooch_number.includes("44M-amf") || form.tooch_number.includes("44M-cof") || form.tooch_number.includes("44M-car") ?
  <>
    {form.tooch_number.includes("44M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="44M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 44M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#44M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("44-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("44M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 44M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("44-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("44M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="44M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 44M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("44-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("44M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 44M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("44-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="44M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 44M"
      points="30,10 40,0 40,40 30,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("44-Mesial")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kiri */}
{form.tooch_number.includes("44D-fis") || form.tooch_number.includes("44D-amf") || form.tooch_number.includes("44D-cof") || form.tooch_number.includes("44D-car") ?
  <>
    {form.tooch_number.includes("44D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="44D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 44D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#44D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("44-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("44D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 44D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("44-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("44D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="44D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 44D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("44-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("44D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 44D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("44-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="44D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 44D"
      points="0,0 10,10 10,30 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("44-Distal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* center */}
{form.tooch_number.includes("44O-fis") || form.tooch_number.includes("44O-amf") || form.tooch_number.includes("44O-cof") || form.tooch_number.includes("44O-car") ?
  <>
    {form.tooch_number.includes("44O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="44O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 44O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#44O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("44-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("44O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 44O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("44-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("44O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="44O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 44O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("44-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("44O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 44O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("44-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="44O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 44O"
      points="10,10 30,10 30,30 10,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("44-Oklusal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* cfr */}
{form.tooch_number.includes("44-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{/* rrx */}
{form.tooch_number.includes("44-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100">
  v
</text>
}
      
{/* mis */}
{form.tooch_number.includes("44-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
        
</g>
:
<g>
{/* Atas */}
{form.tooch_number.includes("44L-fis") || form.tooch_number.includes("44L-amf") || form.tooch_number.includes("44L-cof") || form.tooch_number.includes("44L-car") ?
  <>
    {form.tooch_number.includes("44L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="44L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 44L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#44L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("44-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("44L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 44L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("44-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("44L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="44L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 44L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("44-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("44L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 44L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("44-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 44L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("44-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Bawah */}
{form.tooch_number.includes("44B-fis") || form.tooch_number.includes("44B-amf") || form.tooch_number.includes("44B-cof") || form.tooch_number.includes("44B-car") ?
  <>
    {form.tooch_number.includes("44B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="44B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 44B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#44B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("44-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("44B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 44B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("44-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("44B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="44B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 44B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("44-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("44B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 44B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("44-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 44B"
    points="10,30 30,30 40,40 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("44-Bukal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kanan */}
{form.tooch_number.includes("44M-fis") || form.tooch_number.includes("44M-amf") || form.tooch_number.includes("44M-cof") || form.tooch_number.includes("44M-car") ?
  <>
    {form.tooch_number.includes("44M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="44M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 44M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#44M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("44-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("44M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 44M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("44-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("44M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="44M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 44M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("44-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("44M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 44M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("44-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 44M"
    points="30,10 40,0 40,40 30,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("44-Mesial")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kiri */}
{form.tooch_number.includes("44D-fis") || form.tooch_number.includes("44D-amf") || form.tooch_number.includes("44D-cof") || form.tooch_number.includes("44D-car") ?
  <>
    {form.tooch_number.includes("44D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="44D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 44D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#44D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("44-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("44D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 44D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("44-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("44D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="44D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 44D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("44-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("44D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 44D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("44-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 44D"
    points="0,0 10,10 10,30 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("44-Distal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* center */}
{form.tooch_number.includes("44O-fis") || form.tooch_number.includes("44O-amf") || form.tooch_number.includes("44O-cof") || form.tooch_number.includes("44O-car") ?
  <>
    {form.tooch_number.includes("44O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="44O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 44O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#44O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("44-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("44O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 44O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("44-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("44O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="44O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 44O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("44-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("44O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 44O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("44-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 44O"
    points="10,10 30,10 30,30 10,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("44-Oklusal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* cfr */}
{form.tooch_number.includes("44-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{form.tooch_number.includes("44-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100">
  v
</text>
}

{form.tooch_number.includes("44-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
</g>
}      
</svg>

{form.tooch_number.includes("44-pob-43-miss-pon-pob-42-pob")
  ||form.tooch_number.includes("44-pob-43-miss-pon-pob-42-miss-pon-pob-41-pob")?
  <>
                  
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("44-pob-43-miss-pon-pob-42-pob") &&      
 <div style={{ width: "130px", textAlign: "center", position: "absolute" }}>
 <svg width="100%" height="100%">

                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
              
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("44-pob-43-miss-pon-pob-42-miss-pon-pob-41-pob") &&      
 <div style={{ width: "173px", textAlign: "center", position: "absolute" }}>
 <svg width="100%" height="100%">

  <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
  
  </>
  :
  <></>
  }
          
  

{form.tooch_number.includes("44-meb-43-miss-pon-meb-42-meb")
  ||form.tooch_number.includes("44-meb-43-miss-pon-meb-42-miss-pon-meb-41-meb")?
  <>
                  
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("44-meb-43-miss-pon-meb-42-meb") &&      
  <div style={{ width: "130px", textAlign: "center", position: "absolute" }}>
  <svg width="100%" height="100%">

                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
              
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("44-meb-43-miss-pon-meb-42-miss-pon-meb-41-meb") &&      
  <div style={{ width: "173px", textAlign: "center", position: "absolute" }}>
  <svg width="100%" height="100%">

  <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
  
  </>
  :
  <></>
  }
          
  
        
<div style={{ textAlign: "center" }}>44</div>

{form.tooch_number.includes("44-une") || form.tooch_number.includes("44-non") || form.tooch_number.includes("44-pre") || form.tooch_number.includes("44-ano") || form.tooch_number.includes("44-ipx-poc") || form.tooch_number.includes("44-mis-prd-acr") || form.tooch_number.includes("44-mis-fld-acr") ?
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
{form.tooch_number.includes("44-une") &&
<div style={{ position: "absolute", color: "black" }}>UNE</div>
}
{form.tooch_number.includes("44-non") &&
<div style={{ position: "absolute", color: "black" }}>NON</div>
}      
{form.tooch_number.includes("44-pre") &&
<div style={{ position: "absolute", color: "black" }}>PRE</div>
}      
{form.tooch_number.includes("44-ano") &&
<div style={{ position: "absolute", color: "black" }}>ANO</div>
}
{form.tooch_number.includes("44-ipx-poc") &&
<div style={{ position: "absolute", color: "black" }}>IPX</div>
}
{form.tooch_number.includes("44-mis-prd-acr") &&
<div style={{ position: "absolute", color: "black" }}>PRD</div>
} 
{form.tooch_number.includes("44-mis-fld-acr") &&
<div style={{ position: "absolute", color: "black" }}>FLD</div>
}      
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
</div>
}
</div>
      
<div style={{ width: "42px", margin: "1px" }}>

{form.tooch_number.includes("43-nvt") || form.tooch_number.includes("43-rct") || form.tooch_number.includes("43B-amf-rct")
|| form.tooch_number.includes("43P-amf-rct") || form.tooch_number.includes("43M-amf-rct") || form.tooch_number.includes("43D-amf-rct")
|| form.tooch_number.includes("43L-amf-rct") ||
form.tooch_number.includes("43B-cof-rct") || form.tooch_number.includes("43P-cof-rct") || form.tooch_number.includes("43M-cof-rct")
|| form.tooch_number.includes("43D-cof-rct") || form.tooch_number.includes("43L-cof-rct") ||
form.tooch_number.includes("43-fmc-rct") ||
form.tooch_number.includes("43-poc-rct") ?
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%"}}>
<svg width={42} height={42}>
<polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 43B"
  points="21,2 41,32 1,32" 
  style={{ fill: form.tooch_number.includes("43-nvt") ? 'white' : 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('43-Bukal')}
  onMouseLeave={() => setInfoGigi('XX-X')}
/>
</svg>
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingTop: "13px" }}>
</div>
        } 
<svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("43-fmc") || form.tooch_number.includes("43-poc") || form.tooch_number.includes("43-ipx-poc") || form.tooch_number.match(/\b43-pob\b/) || form.tooch_number.match(/\b43-miss-pon-pob\b/) || form.tooch_number.match(/\b43-meb\b/) || form.tooch_number.match(/\b43-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
{form.tooch_number.includes("43-poc") || form.tooch_number.includes("43-ipx-poc") || form.tooch_number.match(/\b43-pob\b/) || form.tooch_number.match(/\b43-miss-pon-pob\b/) ?
<g>
 {/* Atas */}
 {form.tooch_number.includes("43P-fis") || form.tooch_number.includes("43P-amf") || form.tooch_number.includes("43P-cof") || form.tooch_number.includes("43P-car") ?
   <>
     {form.tooch_number.includes("43P-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="43P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 43P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: 'url(#43P-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("43-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />   
       </svg>
     }

     {form.tooch_number.includes("43P-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 43P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("43-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("43P-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="43P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 43P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("43-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("43P-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 43P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("43-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="43P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 43P"
       points="0,0 40,0 30,20 10,20"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("43-Palatal")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Bawah */}
 {form.tooch_number.includes("43L-fis") || form.tooch_number.includes("43L-amf") || form.tooch_number.includes("43L-cof") || form.tooch_number.includes("43L-car") ?
   <>
     {form.tooch_number.includes("43L-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="43L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>

         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 43L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: 'url(#43L-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("43-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("43L-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 43L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("43-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("43L-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="43L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 43L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("43-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("43L-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 43L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("43-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="43L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 43L"
       points="30,20 40,40 0,40 10,20"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("43-Labial")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Kanan */}
 {form.tooch_number.includes("43M-fis") || form.tooch_number.includes("43M-amf") || form.tooch_number.includes("43M-cof") || form.tooch_number.includes("43M-car") ?
   <>
     {form.tooch_number.includes("43M-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="43M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 43M"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: 'url(#43M-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("43-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("43M-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 43M"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("43-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("43M-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="43M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 43M"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("43-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }
   
     {form.tooch_number.includes("43M-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 43M"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("43-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="43M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 43M"
       points="30,20 40,0 40,40 35,30"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("43-Mesial")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Kiri */}
 {form.tooch_number.includes("43D-fis") || form.tooch_number.includes("43D-amf") || form.tooch_number.includes("43D-cof") || form.tooch_number.includes("43D-car") ?
   <>
     {form.tooch_number.includes("43D-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="43D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 43D"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: 'url(#43D-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("43-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("43D-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 43D"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("43-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("43D-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="43D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 43D"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("43-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("43D-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 43D"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("43-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="43D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 43D"
       points="0,0 5,10 10,20 0,40"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("43-Distal")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* cfr */}
 {form.tooch_number.includes("43-cfr") &&
   <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
     #
   </text>
 }

 {/* rrx */}
 {form.tooch_number.includes("43-rrx") &&    
 <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100">
   v
 </text>
 }
       
 {/* mis */}
 {form.tooch_number.includes("43-mis") &&    
 <>  
   <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
   <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
 </> 
 }
         
</g>
 :
<g>
 {/* Atas */}
 {form.tooch_number.includes("43P-fis") || form.tooch_number.includes("43P-amf") || form.tooch_number.includes("43P-cof") || form.tooch_number.includes("43P-car") ?
   <>
     {form.tooch_number.includes("43P-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="43P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 43P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: 'url(#43P-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("43-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("43P-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 43P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("43-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("43P-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="43P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 43P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("43-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("43P-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 43P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("43-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 43P"
     points="0,0 40,0 30,20 10,20"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("43-Palatal")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Bawah */}
 {form.tooch_number.includes("43L-fis") || form.tooch_number.includes("43L-amf") || form.tooch_number.includes("43L-cof") || form.tooch_number.includes("43L-car") ?
   <>
     {form.tooch_number.includes("43L-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="43L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>

         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 43L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: 'url(#43L-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("43-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("43L-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 43L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("43-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("43L-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="43L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 43L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("43-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("43L-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 43L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("43-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 43L"
     points="30,20 40,40 0,40 10,20"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("43-Labial")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Kanan */}
 {form.tooch_number.includes("43M-fis") || form.tooch_number.includes("43M-amf") || form.tooch_number.includes("43M-cof") || form.tooch_number.includes("43M-car") ?
   <>
     {form.tooch_number.includes("43M-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="43M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 43M"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: 'url(#43M-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("43-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("43M-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 43M"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("43-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("43M-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="43M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 43M"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("43-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }
   
     {form.tooch_number.includes("43M-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 43M"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("43-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 43M"
     points="30,20 40,0 40,40 35,30"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("43-Mesial")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Kiri */}
 {form.tooch_number.includes("43D-fis") || form.tooch_number.includes("43D-amf") || form.tooch_number.includes("43D-cof") || form.tooch_number.includes("43D-car") ?
   <>
     {form.tooch_number.includes("43D-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="43D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 43D"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: 'url(#43D-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("43-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("43D-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 43D"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("43-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("43D-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="43D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 43D"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("43-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("43D-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 43D"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("43-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 43D"
     points="0,0 5,10 10,20 0,40"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("43-Distal")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* cfr */}
 {form.tooch_number.includes("43-cfr") &&
   <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
     #
   </text>
 }
 
 {form.tooch_number.includes("43-rrx") &&    
 <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100">
   v
 </text>
 }
 
 {form.tooch_number.includes("43-mis") &&    
 <>  
   <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
   <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
 </> 
 }
</g>
}    
   
</svg>

{form.tooch_number.includes("43-pob-42-miss-pon-pob-41-pob")?
  <>
              
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("43-pob-42-miss-pon-pob-41-pob") &&      
 <div style={{ width: "130px", textAlign: "center", position: "absolute" }}>
 <svg width="100%" height="100%">

  <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
  
  </>
  :
  <></>
}

{form.tooch_number.includes("43-meb-42-miss-pon-meb-41-meb")?
  <>
              
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("43-meb-42-miss-pon-meb-41-meb") &&      
  <div style={{ width: "130px", textAlign: "center", position: "absolute" }}>
  <svg width="100%" height="100%">

  <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
  
  </>
  :
  <></>
}

<div style = {{ textAlign: "center" }}> 43 </div>


{form.tooch_number.includes("43-une") || form.tooch_number.includes("43-non") || form.tooch_number.includes("43-pre") || form.tooch_number.includes("43-ano") || form.tooch_number.includes("43-ipx-poc") || form.tooch_number.includes("43-mis-prd-acr") || form.tooch_number.includes("43-mis-fld-acr") ?
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
 {form.tooch_number.includes("43-une") &&
   <div style={{ position: "absolute", color: "black" }}>UNE</div>
 }
 {form.tooch_number.includes("43-non") &&
   <div style={{ position: "absolute", color: "black" }}>NON</div>
 }      
 {form.tooch_number.includes("43-pre") &&
   <div style={{ position: "absolute", color: "black" }}>PRE</div>
 }      
 {form.tooch_number.includes("43-ano") &&
   <div style={{ position: "absolute", color: "black" }}>ANO</div>
 }
 {form.tooch_number.includes("43-ipx-poc") &&
   <div style={{ position: "absolute", color: "black" }}>IPX</div>
 }
 {form.tooch_number.includes("43-mis-prd-acr") &&
   <div style={{ position: "absolute", color: "black" }}>PRD</div>
 } 
 {form.tooch_number.includes("43-mis-fld-acr") &&
   <div style={{ position: "absolute", color: "black" }}>FLD</div>
 }      
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
</div>
}



 </div> 
      
 <div style={{ width: "42px", margin: "1px" }}>

 {form.tooch_number.includes("42-nvt") || form.tooch_number.includes("42-rct") || form.tooch_number.includes("42B-amf-rct")
|| form.tooch_number.includes("42P-amf-rct") || form.tooch_number.includes("42M-amf-rct") || form.tooch_number.includes("42D-amf-rct")
|| form.tooch_number.includes("42L-amf-rct") ||
form.tooch_number.includes("42B-cof-rct") || form.tooch_number.includes("42P-cof-rct") || form.tooch_number.includes("42M-cof-rct")
|| form.tooch_number.includes("42D-cof-rct") || form.tooch_number.includes("42L-cof-rct") ||
form.tooch_number.includes("42-fmc-rct") ||
form.tooch_number.includes("42-poc-rct") ?
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%"}}>
<svg width={42} height={42}>
<polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 42B"
  points="21,2 41,32 1,32" 
  style={{ fill: form.tooch_number.includes("42-nvt") ? 'white' : 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('42-Bukal')}
  onMouseLeave={() => setInfoGigi('XX-X')}
/>
</svg>
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingTop: "13px" }}>
</div>
        } 
<svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("42-fmc") || form.tooch_number.includes("42-poc") || form.tooch_number.includes("42-ipx-poc") || form.tooch_number.match(/\b42-pob\b/) || form.tooch_number.match(/\b42-miss-pon-pob\b/) || form.tooch_number.match(/\b42-meb\b/) || form.tooch_number.match(/\b42-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
{form.tooch_number.includes("42-poc") || form.tooch_number.includes("42-ipx-poc") || form.tooch_number.match(/\b42-pob\b/) || form.tooch_number.match(/\b42-miss-pon-pob\b/) ?
<g>
 {/* Atas */}
 {form.tooch_number.includes("42P-fis") || form.tooch_number.includes("42P-amf") || form.tooch_number.includes("42P-cof") || form.tooch_number.includes("42P-car") ?
   <>
     {form.tooch_number.includes("42P-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="42P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 42P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: 'url(#42P-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("42-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />   
       </svg>
     }

     {form.tooch_number.includes("42P-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 42P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("42-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("42P-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="42P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 42P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("42-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("42P-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 42P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("42-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="42P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 42P"
       points="0,0 40,0 30,20 10,20"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("42-Palatal")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Bawah */}
 {form.tooch_number.includes("42L-fis") || form.tooch_number.includes("42L-amf") || form.tooch_number.includes("42L-cof") || form.tooch_number.includes("42L-car") ?
   <>
     {form.tooch_number.includes("42L-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="42L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>

         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 42L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: 'url(#42L-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("42-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("42L-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 42L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("42-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("42L-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="42L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 42L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("42-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("42L-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 42L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("42-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="42L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 42L"
       points="30,20 40,40 0,40 10,20"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("42-Labial")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Kanan */}
 {form.tooch_number.includes("42M-fis") || form.tooch_number.includes("42M-amf") || form.tooch_number.includes("42M-cof") || form.tooch_number.includes("42M-car") ?
   <>
     {form.tooch_number.includes("42M-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="42M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 42M"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: 'url(#42M-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("42-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("42M-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 42M"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("42-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("42M-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="42M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 42M"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("42-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }
   
     {form.tooch_number.includes("42M-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 42M"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("42-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="42M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 42M"
       points="30,20 40,0 40,40 35,30"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("42-Mesial")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Kiri */}
 {form.tooch_number.includes("42D-fis") || form.tooch_number.includes("42D-amf") || form.tooch_number.includes("42D-cof") || form.tooch_number.includes("42D-car") ?
   <>
     {form.tooch_number.includes("42D-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="42D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 42D"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: 'url(#42D-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("42-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("42D-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 42D"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("42-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("42D-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="42D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 42D"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("42-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("42D-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 42D"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("42-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="42D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 42D"
       points="0,0 5,10 10,20 0,40"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("42-Distal")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* cfr */}
 {form.tooch_number.includes("42-cfr") &&
   <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
     #
   </text>
 }

 {/* rrx */}
 {form.tooch_number.includes("42-rrx") &&    
 <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100">
   v
 </text>
 }
       
 {/* mis */}
 {form.tooch_number.includes("42-mis") &&    
 <>  
   <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
   <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
 </> 
 }
         
</g>
 :
<g>
 {/* Atas */}
 {form.tooch_number.includes("42P-fis") || form.tooch_number.includes("42P-amf") || form.tooch_number.includes("42P-cof") || form.tooch_number.includes("42P-car") ?
   <>
     {form.tooch_number.includes("42P-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="42P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 42P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: 'url(#42P-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("42-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("42P-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 42P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("42-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("42P-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="42P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 42P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("42-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("42P-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 42P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("42-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 42P"
     points="0,0 40,0 30,20 10,20"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("42-Palatal")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Bawah */}
 {form.tooch_number.includes("42L-fis") || form.tooch_number.includes("42L-amf") || form.tooch_number.includes("42L-cof") || form.tooch_number.includes("42L-car") ?
   <>
     {form.tooch_number.includes("42L-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="42L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>

         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 42L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: 'url(#42L-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("42-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("42L-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 42L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("42-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("42L-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="42L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 42L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("42-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("42L-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 42L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("42-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 42L"
     points="30,20 40,40 0,40 10,20"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("42-Labial")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Kanan */}
 {form.tooch_number.includes("42M-fis") || form.tooch_number.includes("42M-amf") || form.tooch_number.includes("42M-cof") || form.tooch_number.includes("42M-car") ?
   <>
     {form.tooch_number.includes("42M-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="42M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 42M"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: 'url(#42M-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("42-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("42M-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 42M"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("42-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("42M-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="42M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 42M"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("42-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }
   
     {form.tooch_number.includes("42M-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 42M"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("42-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 42M"
     points="30,20 40,0 40,40 35,30"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("42-Mesial")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Kiri */}
 {form.tooch_number.includes("42D-fis") || form.tooch_number.includes("42D-amf") || form.tooch_number.includes("42D-cof") || form.tooch_number.includes("42D-car") ?
   <>
     {form.tooch_number.includes("42D-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="42D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 42D"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: 'url(#42D-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("42-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("42D-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 42D"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("42-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("42D-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="42D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 42D"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("42-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("42D-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 42D"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("42-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 42D"
     points="0,0 5,10 10,20 0,40"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("42-Distal")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* cfr */}
 {form.tooch_number.includes("42-cfr") &&
   <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
     #
   </text>
 }
 
 {form.tooch_number.includes("42-rrx") &&    
 <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100">
   v
 </text>
 }
 
 {form.tooch_number.includes("42-mis") &&    
 <>  
   <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
   <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
 </> 
 }
</g>
}    
   
</svg>


<div style = {{ textAlign: "center" }}> 42 </div>


{form.tooch_number.includes("42-une") || form.tooch_number.includes("42-non") || form.tooch_number.includes("42-pre") || form.tooch_number.includes("42-ano") || form.tooch_number.includes("42-ipx-poc") || form.tooch_number.includes("42-mis-prd-acr") || form.tooch_number.includes("42-mis-fld-acr") ?
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
 {form.tooch_number.includes("42-une") &&
   <div style={{ position: "absolute", color: "black" }}>UNE</div>
 }
 {form.tooch_number.includes("42-non") &&
   <div style={{ position: "absolute", color: "black" }}>NON</div>
 }      
 {form.tooch_number.includes("42-pre") &&
   <div style={{ position: "absolute", color: "black" }}>PRE</div>
 }      
 {form.tooch_number.includes("42-ano") &&
   <div style={{ position: "absolute", color: "black" }}>ANO</div>
 }
 {form.tooch_number.includes("42-ipx-poc") &&
   <div style={{ position: "absolute", color: "black" }}>IPX</div>
 }
 {form.tooch_number.includes("42-mis-prd-acr") &&
   <div style={{ position: "absolute", color: "black" }}>PRD</div>
 } 
 {form.tooch_number.includes("42-mis-fld-acr") &&
   <div style={{ position: "absolute", color: "black" }}>FLD</div>
 }      
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
</div>
}



      </div> 
      
 <div style={{ width: "42px", margin: "1px" }}>

 {form.tooch_number.includes("41-nvt") || form.tooch_number.includes("41-rct") || form.tooch_number.includes("41B-amf-rct")
|| form.tooch_number.includes("41P-amf-rct") || form.tooch_number.includes("41M-amf-rct") || form.tooch_number.includes("41D-amf-rct")
|| form.tooch_number.includes("41L-amf-rct") ||
form.tooch_number.includes("41B-cof-rct") || form.tooch_number.includes("41P-cof-rct") || form.tooch_number.includes("41M-cof-rct")
|| form.tooch_number.includes("41D-cof-rct") || form.tooch_number.includes("41L-cof-rct") ||
form.tooch_number.includes("41-fmc-rct") ||
form.tooch_number.includes("41-poc-rct") ?
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%"}}>
<svg width={42} height={42}>
<polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 41B"
  points="21,2 41,32 1,32" 
  style={{ fill: form.tooch_number.includes("41-nvt") ? 'white' : 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('41-Bukal')}
  onMouseLeave={() => setInfoGigi('XX-X')}
/>
</svg>
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingTop: "13px" }}>
</div>
        } 
<svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("41-fmc") || form.tooch_number.includes("41-poc") || form.tooch_number.includes("41-ipx-poc") || form.tooch_number.match(/\b41-pob\b/) || form.tooch_number.match(/\b41-miss-pon-pob\b/) || form.tooch_number.match(/\b41-meb\b/) || form.tooch_number.match(/\b41-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
{form.tooch_number.includes("41-poc") || form.tooch_number.includes("41-ipx-poc") || form.tooch_number.match(/\b41-pob\b/) || form.tooch_number.match(/\b41-miss-pon-pob\b/) ?
<g>
 {/* Atas */}
 {form.tooch_number.includes("41P-fis") || form.tooch_number.includes("41P-amf") || form.tooch_number.includes("41P-cof") || form.tooch_number.includes("41P-car") ?
   <>
     {form.tooch_number.includes("41P-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="41P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 41P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: 'url(#41P-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("41-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />   
       </svg>
     }

     {form.tooch_number.includes("41P-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 41P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("41-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("41P-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="41P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 41P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("41-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("41P-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 41P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("41-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="41P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 41P"
       points="0,0 40,0 30,20 10,20"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("41-Palatal")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Bawah */}
 {form.tooch_number.includes("41L-fis") || form.tooch_number.includes("41L-amf") || form.tooch_number.includes("41L-cof") || form.tooch_number.includes("41L-car") ?
   <>
     {form.tooch_number.includes("41L-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="41L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>

         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 41L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: 'url(#41L-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("41-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("41L-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 41L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("41-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("41L-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="41L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 41L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("41-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("41L-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 41L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("41-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="41L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 41L"
       points="30,20 40,40 0,40 10,20"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("41-Labial")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Kanan */}
 {form.tooch_number.includes("41M-fis") || form.tooch_number.includes("41M-amf") || form.tooch_number.includes("41M-cof") || form.tooch_number.includes("41M-car") ?
   <>
     {form.tooch_number.includes("41M-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="41M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 41M"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: 'url(#41M-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("41-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("41M-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 41M"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("41-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("41M-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="41M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 41M"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("41-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }
   
     {form.tooch_number.includes("41M-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 41M"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("41-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="41M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 41M"
       points="30,20 40,0 40,40 35,30"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("41-Mesial")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Kiri */}
 {form.tooch_number.includes("41D-fis") || form.tooch_number.includes("41D-amf") || form.tooch_number.includes("41D-cof") || form.tooch_number.includes("41D-car") ?
   <>
     {form.tooch_number.includes("41D-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="41D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 41D"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: 'url(#41D-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("41-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("41D-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 41D"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("41-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("41D-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="41D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 41D"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("41-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("41D-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 41D"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("41-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="41D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 41D"
       points="0,0 5,10 10,20 0,40"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("41-Distal")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* cfr */}
 {form.tooch_number.includes("41-cfr") &&
   <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
     #
   </text>
 }

 {/* rrx */}
 {form.tooch_number.includes("41-rrx") &&    
 <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100">
   v
 </text>
 }
       
 {/* mis */}
 {form.tooch_number.includes("41-mis") &&    
 <>  
   <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
   <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
 </> 
 }
         
</g>
 :
<g>
 {/* Atas */}
 {form.tooch_number.includes("41P-fis") || form.tooch_number.includes("41P-amf") || form.tooch_number.includes("41P-cof") || form.tooch_number.includes("41P-car") ?
   <>
     {form.tooch_number.includes("41P-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="41P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 41P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: 'url(#41P-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("41-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("41P-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 41P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("41-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("41P-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="41P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 41P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("41-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("41P-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 41P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("41-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 41P"
     points="0,0 40,0 30,20 10,20"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("41-Palatal")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Bawah */}
 {form.tooch_number.includes("41L-fis") || form.tooch_number.includes("41L-amf") || form.tooch_number.includes("41L-cof") || form.tooch_number.includes("41L-car") ?
   <>
     {form.tooch_number.includes("41L-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="41L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>

         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 41L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: 'url(#41L-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("41-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("41L-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 41L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("41-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("41L-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="41L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 41L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("41-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("41L-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 41L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("41-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 41L"
     points="30,20 40,40 0,40 10,20"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("41-Labial")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Kanan */}
 {form.tooch_number.includes("41M-fis") || form.tooch_number.includes("41M-amf") || form.tooch_number.includes("41M-cof") || form.tooch_number.includes("41M-car") ?
   <>
     {form.tooch_number.includes("41M-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="41M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 41M"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: 'url(#41M-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("41-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("41M-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 41M"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("41-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("41M-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="41M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 41M"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("41-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }
   
     {form.tooch_number.includes("41M-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 41M"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("41-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 41M"
     points="30,20 40,0 40,40 35,30"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("41-Mesial")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Kiri */}
 {form.tooch_number.includes("41D-fis") || form.tooch_number.includes("41D-amf") || form.tooch_number.includes("41D-cof") || form.tooch_number.includes("41D-car") ?
   <>
     {form.tooch_number.includes("41D-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="41D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 41D"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: 'url(#41D-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("41-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("41D-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 41D"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("41-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("41D-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="41D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 41D"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("41-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("41D-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 41D"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("41-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 41D"
     points="0,0 5,10 10,20 0,40"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("41-Distal")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* cfr */}
 {form.tooch_number.includes("41-cfr") &&
   <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
     #
   </text>
 }
 
 {form.tooch_number.includes("41-rrx") &&    
 <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100">
   v
 </text>
 }
 
 {form.tooch_number.includes("41-mis") &&    
 <>  
   <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
   <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
 </> 
 }
</g>
}    
   
</svg>


<div style = {{ textAlign: "center" }}> 41 </div>


{form.tooch_number.includes("41-une") || form.tooch_number.includes("41-non") || form.tooch_number.includes("41-pre") || form.tooch_number.includes("41-ano") || form.tooch_number.includes("41-ipx-poc") || form.tooch_number.includes("41-mis-prd-acr") || form.tooch_number.includes("41-mis-fld-acr") ?
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
 {form.tooch_number.includes("41-une") &&
   <div style={{ position: "absolute", color: "black" }}>UNE</div>
 }
 {form.tooch_number.includes("41-non") &&
   <div style={{ position: "absolute", color: "black" }}>NON</div>
 }      
 {form.tooch_number.includes("41-pre") &&
   <div style={{ position: "absolute", color: "black" }}>PRE</div>
 }      
 {form.tooch_number.includes("41-ano") &&
   <div style={{ position: "absolute", color: "black" }}>ANO</div>
 }
 {form.tooch_number.includes("41-ipx-poc") &&
   <div style={{ position: "absolute", color: "black" }}>IPX</div>
 }
 {form.tooch_number.includes("41-mis-prd-acr") &&
   <div style={{ position: "absolute", color: "black" }}>PRD</div>
 } 
 {form.tooch_number.includes("41-mis-fld-acr") &&
   <div style={{ position: "absolute", color: "black" }}>FLD</div>
 }      
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
</div>
}



 </div> 
    </div>
  );
}
