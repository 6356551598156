import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Form,Button} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../../config/api";
import {FaTimes} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../../assets/signature/signature logo.png"
import Logo_Batik from "../../../assets/signature/logo-batik.png"
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
import "../Styled.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
import LoaderHome from "../../Loader/LoaderHome"

export default function ModalRoleAdd(props) {
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(100);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const token = localStorage.getItem("token");
  const [getDataDokter, setGetDataDokter] = useState([]);
  // Untuk Close Proops Data
  const [propsData, setProopsData] = useState()
  const [getData, setGetData] = useState([]);
  const [getDataRoom, setGetDataRoom] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const GetResponseData = async () => {
    const response = await API.get(`/transaction-service/treatment-categories?page=${page}&limit=${limit}&ascending=${ascending}`,
    fetchParams)
    setGetData(response.data.data)
  }

  const GetResponseDataRoom = async () => {
    const response = await API.get(`/transaction-service/rooms?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`,
    fetchParams)
    setGetDataRoom(response.data.data)
  }

  useEffect(() => {
    GetResponseData()
    GetResponseDataRoom()
  }, [])

  const [form, setForm] = useState({
    name_treatment: "",
    id_category: "",
    id_room_cost_lab: "",
    cost_lab: "",
    fee_persentage: "",
    code_icd9: "",
    name_icd9: ""
  });

  const { name_treatment,id_category, id_room_cost_lab, cost_lab, fee_persentage, code_icd9, name_icd9 } = form;

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = useMutation(async (e) => {
    try {
      e.preventDefault();
      setLoading(true)
      // Insert data for login process
      const response = await API.post("/transaction-service/treatments/store", {
        name: form?.name_treatment,
        id_category: form?.id_category,
        id_room_cost_lab: form?.id_room_cost_lab,
        cost_lab: form?.cost_lab,
        fee_persentage: form?.fee_persentage,
        code_icd9: form?.code_icd9,
        name_icd9: form?.name_icd9
      }, fetchParams);
  
      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        })
        props.GetResponseData()
        props.onHide()
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  });

  const [isChecked, setIsChecked] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false); 

  // Fungsi untuk mengubah status switch ketika diklik
  const toggleSwitch = () => {
    setIsChecked(prevState => !prevState);
  };

  const toggleSwitch2 = () => {
    setIsChecked2(prevState2 => !prevState2);
  };

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{fontFamily:"sans-serif",border:"none"}}>
      {loading && <LoaderHome />}
      <div style={{ width: "100%", display: "flex", padding: "10px 0px", backgroundColor: "#29B8FF" }}>
        <div style={{flex:"92%", fontSize:"20px",display:"flex",alignItems:"center", paddingLeft:"10px", color:"white", fontWeight:"600"}}>
          Tambah Treatment
        </div> 
        <div  style={{flex:"8%",fontSize:"30px",display:"flex",alignItems:"center",justifyContent:"center", color:"white"}}>
          <FaTimes onClick={() => setProopsData(props.onHide)} style={{cursor:"pointer"}}/>
        </div> 
      </div>
      <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px",border:"none" }}>
      <Form onSubmit={(e) => handleSubmit.mutate(e)} className="mt-1" >
        <div style={{display:"flex", justifyContent:"start"}}> 
          <div className="mr-2" style={{display:"flex", justifyContent:"start"}}> 
            <div className="mr-1" style={{display:"flex", alignItems:"center", fontSize:"15px", 
              fontWeight:"bold", fontStyle:"revert"}}>
              Biaya Lab
            </div>
            <div style={{display:"flex", alignItems:"center"}}>
              <label className="toggle-switch">
                <input type="checkbox" checked={isChecked} onChange={toggleSwitch} />
                <span className="switch"></span>
              </label>
            </div>
          </div>

          <div style={{display:"flex", justifyContent:"start"}}> 
            <div className="mr-1" style={{display:"flex", alignItems:"center", fontSize:"15px", 
              fontWeight:"bold", fontStyle:"revert"}}>
              Fee Dokter
            </div>
            <div style={{display:"flex", alignItems:"center"}}>
              <label className="toggle-switch">
                <input type="checkbox" checked={isChecked2} onChange={toggleSwitch2} />
                <span className="switch"></span>
              </label>
            </div>
          </div>
        </div>

        <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Nama
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
              <input autoFofcus type='text' value={name_treatment} name="name_treatment" onChange={handleChange} 
                style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", 
                  borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
            </div>
          </div>
        </div>

        <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Code ICD-9
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
              <input autoFofcus type='text' value={code_icd9} name="code_icd9" onChange={handleChange} 
                style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", 
                  borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
            </div>
          </div>
        </div>

        <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Name ICD-9
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
              <input autoFofcus type='text' value={name_icd9} name="name_icd9" onChange={handleChange} 
                style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", 
                  borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
            </div>
          </div>
        </div>

        <div className="mt-3" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Kategori
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <select className="form-select" aria-label="Default select example" onChange={handleChange} value={id_category}  
              name="id_category" style={{ textAlign:"", cursor:"pointer"}}>
                <option value="" hidden>Select</option>
                {getData.map((user,index) => (
                  <option value={user?.id} style={{ textAlign: "" }}>{user?.name}</option>                         
                ))}
            </select>
          </div>
        </div>

        {isChecked === false ? 
          <div>
          </div> 
          :
          <div>
            <div className="mt-3" style={{ display: "flex"}}>
              <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
                Pilih Ruangan Lab
              </div>
              <div style={{ flex: "60%", display:"flex"}}>
                <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
                <select className="form-select" aria-label="Default select example" onChange={handleChange} value={id_room_cost_lab}
                  name="id_room_cost_lab" style={{ textAlign:"", cursor:"pointer"}}>
                    <option value="" hidden>Select</option>
                    {getDataRoom.map((user, index) => (
                      <option key={index} value={user.id}>{user.name}</option>
                    ))}
                </select>
              </div>
            </div>

            <div className="mt-3" style={{ display: "flex"}}>
              <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
                Biaya Lab
              </div>
              <div style={{ flex: "60%", display:"flex"}}>
                <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
                <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
                  <input autoFofcus type='number' value={cost_lab} name="cost_lab" onChange={handleChange} style={{width:"100%",
                  height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", 
                  outline:"none"}}/>
                </div>
              </div>
            </div>
          </div>
          }

        {isChecked2 === false ? 
          <div>
          </div> 
          :
          <div>
            <div className="mt-3" style={{ display: "flex"}}>
              <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
                 Fee Doctor
              </div>
              <div style={{ flex: "60%", display:"flex"}}>
                <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
                <select className="form-select" aria-label="Default select example" onChange={handleChange} value={fee_persentage}
                  name="fee_persentage" style={{textAlign:"", cursor:"pointer"}}>
                    <option value="" hidden>Select</option>
                    <option value={10}>10%</option>
                    <option value={20}>20%</option>
                    <option value={40}>40%</option>
                    <option value={45}>45%</option>
                    <option value={50}>50%</option>
                    <option value={55}>55%</option>
                </select>
              </div>
            </div>
          </div>
          }

          <div style={{ padding: "0px 0px", marginTop: "0px", display:"flex", justifyContent:"end" }}>
            <div>
              <Button className="mt-4" type='submit' color='primary' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px"}}>
                Tambahkan
              </Button>
            </div>
          </div>
        </Form>
      </Modal.Body>
      {/* <Modal.Footer>
        <div style={{ display: "flex" }}>
          <Button onClick={props.onHide} style={{marginRight:"8px"}}>Close</Button>
        </div>
      </Modal.Footer> */}
    </Modal>
    );
}
  