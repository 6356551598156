import { useMutation, useQuery } from "react-query";
import React, { useEffect, useState } from "react";
import { FaUserPlus } from "react-icons/fa";
import { API, setAuthToken } from "../../config/api";
import { useNavigate } from "react-router";

export default function Update({ id, setModalShow2 }) {
  let navigate = useNavigate();
  const token = localStorage.getItem("token");

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  let { data: user1 } = useQuery("user2", async () => {
    const response = await API.get(`user-service/roles/${id}`, fetchParams);
    return response.data;
  });
  
  const [form, setForm] = useState({
    name_role: "",
    icon_name: ""
  });
  useEffect(() => {
    if (user1) {
      setForm({
        ...form,
        name_role: user1?.name,
        icon_name: user1?.icon_name
      });
    }
  }, [user1]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = useMutation(async (e) => {
    try {
      // e.preventDefault();
      await API.patch("/users", form);
      alert("berhasil mengubah data");
      setModalShow2(false);
      // navigate("/");
    } catch (error) {
      console.log(error);
    }
   
  });
  return (
    <>
      <div className="w-full h-full bg-slate-500 opacity-50 fixed z-20"></div>
      <div className="bg-slate-200 centered w-[90%] md:w-[35rem] z-50 rounded-lg px-4 py-8 ">
        <div className="text-2xl font-bold mb-3 text-dark-600 flex gap-2 items-center">
          <FaUserPlus size={30} /> Ubah Data
        </div>
        <form className="mt-6" onSubmit={(e) => handleSubmit.mutate(e)}>
          <label htmlFor="name">
            Name
          </label>
          <input id="name" type="text" name="name" placeholder="name role" onChange={handleChange} value={form.nik} required className="bg-gray-300 border border-gray-300 text-gray-900 text-md rounded-lg focus:outline-orange-600 w-full px-4 py-2 mb-3" />
          
          <label htmlFor="icon_name">
            Nama Icon
          </label>
          <input id="icon_name" type="text" name="icon_name" placeholder="Masukan Nama Lengkap" onChange={handleChange} value={form.name} required className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:outline-orange-600 w-full px-4 py-2 mb-3"/>

          <button type="submit" className="w-full m-auto py-2 bg-blue-600 hover:bg-blue-600 rounded-md font-bold text-white mb-3">
            Simpan
          </button>
          <button className="w-full m-auto py-2 bg-white border border-blue-600 rounded-md font-bold text-dark-600 hover:bg-transparent" onClick={() => setModalShow2(false)}>
            Kembali
          </button>
        </form>
      </div>
    </>
  );
}
