import React, { useState } from 'react';
import './Login.css'; // Anda perlu membuat file CSS terpisah untuk styling dropdown.
import { FaCalendarCheck, FaPhone, FaTimes, FaWhatsapp } from 'react-icons/fa';
import { Button } from 'reactstrap'
import { useNavigate } from 'react-router-dom';

function Dropdown() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCall, setIsOpenCall] = useState(false);
  const navigate = useNavigate()

  const toggleDropdown = () => {
    setIsOpenCall(false);
    setIsOpen(true);
  };

  const toggleDropdownCall = () => {
    setIsOpen(false);
    setIsOpenCall(true);
  };

  const closeBotton = () => {
    setIsOpen(false);
    setIsOpenCall(false)
  };

  
  const toRegister = () => {
    navigate("/register-pasien-online")
  }

  const toLogin = () => {
    navigate("/")
  }

  const menujuWhatsApp = () => {
    // Ganti nomor WhatsApp di bawah ini dengan nomor yang Anda inginkan
    const nomorWhatsApp = '+6281284759191';

    // Membuat URL WhatsApp dengan nomor yang telah diberikan
    const urlWhatsApp = `https:/wa.me/${nomorWhatsApp}`;

    // Buka URL WhatsApp pada tab atau jendela baru
    window.open(urlWhatsApp, '_blank');
  }

  return (
      <div style={{display:""}} >
      <div style={{display: isOpen || isOpenCall? "flex":""}}>
        <div style={{flex:"10%"}}>
          <div onClick={toggleDropdown} style={{padding:"5px 5px", backgroundColor:"#1B86FF", color:"white" , opacity:"0.9", borderBottom:"2px solid #ECECEC", width:"80px", cursor:"pointer", borderTopLeftRadius:"5px"}}>
            <div style={{display:"flex", justifyContent:"center", fontSize:"30px"}}>
                <FaCalendarCheck />
            </div>
            <div style={{display:"flex", justifyContent:"center", fontSize:"10px", marginTop:"5px"}}>
                Buat 
            </div>
            <div style={{display:"flex", justifyContent:"center", fontSize:"10px"}}>
                Appointment
            </div>
          </div>
          
          <div  onClick={toggleDropdownCall}   style={{padding:"5px 5px", backgroundColor:"#1B86FF", color:"white" , opacity:"0.9",  width:"80px", cursor:"pointer", borderBottomLeftRadius:"5px"}}>
            <div style={{display:"flex", justifyContent:"center", fontSize:"30px"}}>
                <FaPhone/>
            </div>
            <div style={{display:"flex", justifyContent:"center", fontSize:"10px", marginTop:"5px"}}>
                Hubungi  
            </div>
            <div style={{display:"flex", justifyContent:"center", fontSize:"10px"}}>
            Kami
            </div>
          </div>
        </div>
        
        {isOpen && (
          <>
            <div style={{ flex: "85%", backgroundColor:"white", padding:"10px 20px", boxShadow:"1px 1px 10px #C6C6C6", width:"450px" }}>
            <div style={{display:"flex", justifyContent:"center", fontFamily:"sans-serif", fontWeight:"bold", fontSize:"20px", color:"#096CFF"}}>
              <div style={{marginRight:"0px", textAlign:"center"}}>
                Selamat Datang Di Signature Dental Care
              </div>
              </div>
              
              <div className='mt-4' style={{display:"flex", justifyContent:"center", fontFamily:"sans-serif", fontWeight:"bold", fontSize:"15px", color:"#A1A1A1"}}>
              <div style={{marginRight:"0px"}}>
                Silahkan Daftar Untuk Membuat Appointment
              </div>
            </div>

            <div style={{display:"flex", justifyContent:"center",}}>
            <div style={{display:"flex", justifyContent:"center", marginRight:"10px"}}>
              <div onClick={toRegister} className="mt-4" type='' color='' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px", width:"100px", display:"flex", justifyContent:"center", backgroundColor:"#005EEC", color:"white", cursor:"pointer"}}>
                <div>
                  Daftar
                </div>
              </div>
            </div>
            {/* 
            <div style={{display:"flex", justifyContent:"center"}}>
              <div onClick={toLogin} className="mt-4" type='' color='' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px", width:"100px", display:"flex", justifyContent:"center", backgroundColor:"white", color:"black", cursor:"pointer", border:"1px solid #9A9A9A"}}>
                <div>
                  Login
                </div>
              </div>
            </div> */}
                
            </div>
          </div>
          <div style={{ flex: "5%",  backgroundColor:"white", padding:"10px", }}>
            <div style={{display:"flex", justifyContent:"center", fontFamily:"sans-serif", fontWeight:"bold", fontSize:"20px", color:"#096CFF"}}>
              <div onClick={closeBotton} >
                <FaTimes style={{fontSize:"14px", color:"black", cursor:"pointer",}}/>
              </div>
            </div>    
        </div>
          </>
        )}

        {isOpenCall && (
          <>
            <div style={{ flex: "85%", backgroundColor:"white", padding:"10px 10px", boxShadow:"1px 1px 10px #C6C6C6" , width:"450px" }}>
            <div style={{display:"flex", justifyContent:"center", fontFamily:"sans-serif", fontWeight:"bold", fontSize:"20px", color:"#096CFF"}}>
              <div style={{marginRight:"0px"}}>
                HUBUNGI KAMI
              </div>
              </div>
              
              <div className='mt-4' style={{display:"flex", justifyContent:"center", fontFamily:"sans-serif", fontWeight:"bold", fontSize:"15px", color:"#A1A1A1"}}>
              <div style={{marginRight:"0px", display:"flex", justifyContent:"center", textAlign:"center"}}>
                Untuk informasi lebih lanjut dan menjawab segala pertanyaan anda, Bisa hubungi melalui Call Center atau Whatsapp kami berikut ini:
              </div>
            </div>

            <div className='mt-4' style={{display:"flex", justifyContent:"center", fontFamily:"sans-serif", fontWeight:"bold", fontSize:"20px", color:""}}>
              <div style={{marginRight:"0px", display:"flex", justifyContent:"center", textAlign:"center"}}>
                  0812-8475-9191
              </div>
            </div>
              
            <div style={{display:"flex", justifyContent:"center"}}>
              <div onClick={menujuWhatsApp} className="mt-4" type='' color='' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px", width:"100px", display:"flex", justifyContent:"center", backgroundColor:"#00F200", color:"white", cursor:"pointer"}}>
                <div style={{marginRight:"5px"}}>
                  <FaWhatsapp style={{fontSize:"15px"}} />
                </div>
                <div>
                  WhatsApp
                </div>
              </div>
            </div>
              
          </div>
          <div style={{ flex: "5%",  backgroundColor:"white", padding:"10px", }}>
            <div style={{display:"flex", justifyContent:"center", fontFamily:"sans-serif", fontWeight:"bold", fontSize:"20px", color:"#096CFF"}}>
              <div onClick={closeBotton} >
                <FaTimes style={{fontSize:"14px", color:"black", cursor:"pointer",}}/>
              </div>
            </div>    
        </div>
          </>
        
        )}

      </div>    
          
    </div>
  );
}

export default Dropdown;
