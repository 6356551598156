import { useState } from "react";
import {  useParams } from "react-router-dom";
import "bulma/css/bulma.css";
import "../Styled.css"

export default function ToochNumber({ form, setForm, onclikTooch, handlePolygonClick }) {
  document.title = "SDC Apps";
  const token = localStorage.getItem("token");
  // modal role add 
  const { id, code } = useParams()
  const [infoGigi, setInfoGigi] = useState("XX-X")

  return (
    <div style={{flex:"50%", display:"flex", justifyContent:"end", paddingRight:""}}>

    <div style={{ width: "42px", margin: "1px" }}>
  {form.tooch_number.includes("55-une") || form.tooch_number.includes("55-non") || form.tooch_number.includes("55-pre") || form.tooch_number.includes("55-ano") || form.tooch_number.includes("55-ipx-poc") || form.tooch_number.includes("55-mis-prd-acr") || form.tooch_number.includes("55-mis-fld-acr") ?
  <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
  {form.tooch_number.includes("55-une") &&
  <div style={{ position: "absolute", color: "black" }}>UNE</div>
  }
  {form.tooch_number.includes("55-non") &&
  <div style={{ position: "absolute", color: "black" }}>NON</div>
  }      
  {form.tooch_number.includes("55-pre") &&
  <div style={{ position: "absolute", color: "black" }}>PRE</div>
  }      
  {form.tooch_number.includes("55-ano") &&
  <div style={{ position: "absolute", color: "black" }}>ANO</div>
  }
  {form.tooch_number.includes("55-ipx-poc") &&
  <div style={{ position: "absolute", color: "black" }}>IPX</div>
  }
  {form.tooch_number.includes("55-mis-prd-acr") &&
  <div style={{ position: "absolute", color: "black" }}>PRD</div>
  } 
  {form.tooch_number.includes("55-mis-fld-acr") &&
  <div style={{ position: "absolute", color: "black" }}>FLD</div>
  }      
  </div>
  :
  <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
  </div>
  }
    
  {form.tooch_number.includes("55-pob-54-miss-pon-pob-53-pob")
  ||form.tooch_number.includes("55-pob-54-miss-pon-pob-53-miss-pon-pob-52-pob")
  ||form.tooch_number.includes("55-pob-54-miss-pon-pob-53-miss-pon-pob-52-miss-pon-pob-51-pob") ?
  <>
  {/* meb 55 - 57 */}
  {form.tooch_number.includes("55-pob-54-miss-pon-pob-53-pob") &&
  <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
  <svg width="100%" height="20vh">

  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  </svg>
  </div>
  }
                    
  {/* meb 55 - 53 */}
  {form.tooch_number.includes("55-pob-54-miss-pon-pob-53-miss-pon-pob-52-pob") &&
  <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
  <svg width="100%" height="20vh">

  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  </svg>
  </div>
  }

  {/* meb 55 - 52 */} 
  {form.tooch_number.includes("55-pob-54-miss-pon-pob-53-miss-pon-pob-52-miss-pon-pob-51-pob") &&      
  <div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
  <svg width="100%" height="20vh">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  </svg>
  </div> 
  }

  </>
  :
  <></>
  }


  {form.tooch_number.includes("55-meb-54-miss-pon-meb-53-meb")
  ||form.tooch_number.includes("55-meb-54-miss-pon-meb-53-miss-pon-meb-52-meb")
  ||form.tooch_number.includes("55-meb-54-miss-pon-meb-53-miss-pon-meb-52-miss-pon-meb-51-meb") ?
  <>
  {/* meb 55 - 57 */}
  {form.tooch_number.includes("55-meb-54-miss-pon-meb-53-meb") &&
  <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
  <svg width="100%" height="20vh">

  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  </svg>
  </div>
  }
                    
  {/* meb 55 - 53 */}
  {form.tooch_number.includes("55-meb-54-miss-pon-meb-53-miss-pon-meb-52-meb") &&
  <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
  <svg width="100%" height="20vh">

  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  </svg>
  </div>
  }

  {/* meb 55 - 52 */} 
  {form.tooch_number.includes("55-meb-54-miss-pon-meb-53-miss-pon-meb-52-miss-pon-meb-51-meb") &&      
 <div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
 <svg width="100%" height="20vh">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  </svg>
  </div> 
  }

  </>
  :
  <></>
  }


  <div style={{ textAlign: "center" }}>55</div>
  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("55-fmc") || form.tooch_number.includes("55-poc") || form.tooch_number.includes("55-ipx-poc") || form.tooch_number.match(/\b55-pob\b/) || form.tooch_number.match(/\b55-miss-pon-pob\b/) || form.tooch_number.match(/\b55-meb\b/) || form.tooch_number.match(/\b55-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
  {form.tooch_number.includes("55-poc") || form.tooch_number.includes("55-ipx-poc") || form.tooch_number.match(/\b55-pob\b/) || form.tooch_number.match(/\b55-miss-pon-pob\b/) ?
  <g>
    {/* Atas */}
    {form.tooch_number.includes("55B-fis") || form.tooch_number.includes("55B-amf") || form.tooch_number.includes("55B-cof") || form.tooch_number.includes("55B-car") ?
      <>
        {form.tooch_number.includes("55B-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="55B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 55B"
              points="0,0 40,0 30,10 10,10"
              style={{ fill: 'url(#55B-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("55-Bukal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("55B-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 55B"
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("55-Bukal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("55B-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="55B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 55B"
              points="0,0 40,0 30,10 10,10"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("55-Bukal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("55B-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 55B"
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("55-Bukal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      
      </>
      :
    <svg width="40" height="40">
      <defs>
        <pattern id="55B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
        </pattern>
      </defs>
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 55B"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: '#007610', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("55-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    </svg>
    }

    {/* Bawah */}
    {form.tooch_number.includes("55P-fis") || form.tooch_number.includes("55P-amf") || form.tooch_number.includes("55P-cof") || form.tooch_number.includes("55P-car") ?
      <>
        {form.tooch_number.includes("55P-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="55P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>

            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 55P"
              points="10,30 30,30 40,40 0,40"
              style={{ fill: 'url(#55P-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("55-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("55P-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 55P"
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("55-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("55P-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="55P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 55P"
              points="10,30 30,30 40,40 0,40"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("55-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("55P-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 55P"
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("55-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      </>
      :
      <svg width="40" height="40">
        <defs>
          <pattern id="55P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 55P"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("55-Palatal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {/* Kanan */}
    {form.tooch_number.includes("55M-fis") || form.tooch_number.includes("55M-amf") || form.tooch_number.includes("55M-cof") || form.tooch_number.includes("55M-car") ?
      <>
        {form.tooch_number.includes("55M-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="55M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 55M"
              points="30,10 40,0 40,40 30,30"
              style={{ fill: 'url(#55M-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("55-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("55M-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 55M"
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("55-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("55M-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="55M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 55M"
              points="30,10 40,0 40,40 30,30"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("55-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }
      
        {form.tooch_number.includes("55M-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 55M"
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("55-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      
      </>
      :
      <svg width="40" height="40">
        <defs>
          <pattern id="55M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 55M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("55-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {/* Kiri */}
    {form.tooch_number.includes("55D-fis") || form.tooch_number.includes("55D-amf") || form.tooch_number.includes("55D-cof") || form.tooch_number.includes("55D-car") ?
      <>
        {form.tooch_number.includes("55D-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="55D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 55D"
              points="0,0 10,10 10,30 0,40"
              style={{ fill: 'url(#55D-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("55-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("55D-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 55D"
            points="0,0 10,10 10,30 0,40"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("55-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("55D-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="55D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 55D"
              points="0,0 10,10 10,30 0,40"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("55-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("55D-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 55D"
            points="0,0 10,10 10,30 0,40"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("55-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

      </>
      :
      <svg width="40" height="40">
        <defs>
          <pattern id="55D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 55D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("55-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {/* center */}
    {form.tooch_number.includes("55O-fis") || form.tooch_number.includes("55O-amf") || form.tooch_number.includes("55O-cof") || form.tooch_number.includes("55O-car") ?
      <>
        {form.tooch_number.includes("55O-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="55O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 55O"
              points="10,10 30,10 30,30 10,30"
              style={{ fill: 'url(#55O-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("55-Oklusal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("55O-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 55O"
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("55-Oklusal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("55O-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="55O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 55O"
              points="10,10 30,10 30,30 10,30"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("55-Oklusal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("55O-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 55O"
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("55-Oklusal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

      </>
      :
      <svg width="40" height="40">
        <defs>
          <pattern id="55O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 55O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("55-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {/* cfr */}
    {form.tooch_number.includes("55-cfr") &&
      <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
        #
      </text>
    }

    {/* rrx */}
    {form.tooch_number.includes("55-rrx") &&    
    <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 55-rrx" onClick={onclikTooch} style={{cursor:"pointer"}}>
      v
    </text>
    }
          
    {/* mis */}
    {form.tooch_number.includes("55-mis") &&    
    <>  
      <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
      <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
    </> 
    }
            
  </g>
    :
  <g>
    {/* Atas */}
    {form.tooch_number.includes("55B-fis") || form.tooch_number.includes("55B-amf") || form.tooch_number.includes("55B-cof") || form.tooch_number.includes("55B-car") ?
      <>
        {form.tooch_number.includes("55B-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="55B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 55B"
              points="0,0 40,0 30,10 10,10"
              style={{ fill: 'url(#55B-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("55-Bukal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("55B-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 55B"
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("55-Bukal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("55B-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="55B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 55B"
              points="0,0 40,0 30,10 10,10"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("55-Bukal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("55B-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 55B"
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("55-Bukal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      
      </>
      :
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 55B"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("55-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {/* Bawah */}
    {form.tooch_number.includes("55P-fis") || form.tooch_number.includes("55P-amf") || form.tooch_number.includes("55P-cof") || form.tooch_number.includes("55P-car") ?
      <>
        {form.tooch_number.includes("55P-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="55P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>

            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 55P"
              points="10,30 30,30 40,40 0,40"
              style={{ fill: 'url(#55P-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("55-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("55P-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 55P"
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("55-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("55P-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="55P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 55P"
              points="10,30 30,30 40,40 0,40"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("55-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("55P-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 55P"
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("55-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      </>
      :
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 55P"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("55-Palatal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {/* Kanan */}
    {form.tooch_number.includes("55M-fis") || form.tooch_number.includes("55M-amf") || form.tooch_number.includes("55M-cof") || form.tooch_number.includes("55M-car") ?
      <>
        {form.tooch_number.includes("55M-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="55M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 55M"
              points="30,10 40,0 40,40 30,30"
              style={{ fill: 'url(#55M-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("55-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("55M-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 55M"
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("55-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("55M-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="55M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 55M"
              points="30,10 40,0 40,40 30,30"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("55-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }
      
        {form.tooch_number.includes("55M-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 55M"
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("55-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      
      </>
      :
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 55M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("55-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {/* Kiri */}
    {form.tooch_number.includes("55D-fis") || form.tooch_number.includes("55D-amf") || form.tooch_number.includes("55D-cof") || form.tooch_number.includes("55D-car") ?
      <>
        {form.tooch_number.includes("55D-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="55D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 55D"
              points="0,0 10,10 10,30 0,40"
              style={{ fill: 'url(#55D-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("55-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("55D-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 55D"
            points="0,0 10,10 10,30 0,40"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("55-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("55D-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="55D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 55D"
              points="0,0 10,10 10,30 0,40"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("55-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("55D-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 55D"
            points="0,0 10,10 10,30 0,40"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("55-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

      </>
      :
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 55D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("55-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {/* center */}
    {form.tooch_number.includes("55O-fis") || form.tooch_number.includes("55O-amf") || form.tooch_number.includes("55O-cof") || form.tooch_number.includes("55O-car") ?
      <>
        {form.tooch_number.includes("55O-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="55O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 55O"
              points="10,10 30,10 30,30 10,30"
              style={{ fill: 'url(#55O-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("55-Oklusal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("55O-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 55O"
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("55-Oklusal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("55O-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="55O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 55O"
              points="10,10 30,10 30,30 10,30"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("55-Oklusal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("55O-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 55O"
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("55-Oklusal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

      </>
      :
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 55O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("55-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {/* cfr */}
    {form.tooch_number.includes("55-cfr") &&
      <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
        #
      </text>
    }
    
    {form.tooch_number.includes("55-rrx") &&    
    <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 55-rrx" onClick={onclikTooch} style={{cursor:"pointer"}}>
      v
    </text>
    }
    
    {form.tooch_number.includes("55-mis") &&    
    <>  
      <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
      <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
    </> 
    }
  </g>
  }      
  </svg>
    
  {form.tooch_number.includes("55-nvt") &&   
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
  <svg width={42} height={42}>
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 55"
    points="21,2 41,32 1,32" 
    style={{ fill: 'white', stroke: 'black', strokeWidth: 3 }}
    onMouseEnter={() => setInfoGigi('55')}
    onMouseLeave={() => setInfoGigi('XX-X')}
  />
  </svg> 
  </div>
  }  

  {form.tooch_number.includes("55-rct") &&   
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
  <svg width={42} height={42}>
  <polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 55"
  points="21,2 41,32 1,32" 
  style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('55')}
  onMouseLeave={() => setInfoGigi('XX-X')}
  />
  </svg>
  </div>
  } 
    
  {form.tooch_number.includes("55B-amf-rct") || form.tooch_number.includes("55P-amf-rct") || form.tooch_number.includes("55M-amf-rct") || form.tooch_number.includes("55D-amf-rct") || form.tooch_number.includes("55O-amf-rct") ||
  form.tooch_number.includes("55B-cof-rct") || form.tooch_number.includes("55P-cof-rct") || form.tooch_number.includes("55M-cof-rct") || form.tooch_number.includes("55D-cof-rct") || form.tooch_number.includes("55O-cof-rct") ||
  form.tooch_number.includes("55-fmc-rct") ||
  form.tooch_number.includes("55-poc-rct") ?
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
  <svg width={42} height={42}>
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 55B"
    points="21,2 41,32 1,32" 
    style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
    onMouseEnter={() => setInfoGigi('55-Bukal')}
    onMouseLeave={() => setInfoGigi('XX-X')}
  />
  </svg>
  </div>
  :
  <></>
  } 

    </div>

    <div style={{ width: "42px", margin: "1px" }}>
  {form.tooch_number.includes("54-une") || form.tooch_number.includes("54-non") || form.tooch_number.includes("54-pre") || form.tooch_number.includes("54-ano") || form.tooch_number.includes("54-ipx-poc") || form.tooch_number.includes("54-mis-prd-acr") || form.tooch_number.includes("54-mis-fld-acr") ?
  <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
  {form.tooch_number.includes("54-une") &&
  <div style={{ position: "absolute", color: "black" }}>UNE</div>
  }
  {form.tooch_number.includes("54-non") &&
  <div style={{ position: "absolute", color: "black" }}>NON</div>
  }      
  {form.tooch_number.includes("54-pre") &&
  <div style={{ position: "absolute", color: "black" }}>PRE</div>
  }      
  {form.tooch_number.includes("54-ano") &&
  <div style={{ position: "absolute", color: "black" }}>ANO</div>
  }
  {form.tooch_number.includes("54-ipx-poc") &&
  <div style={{ position: "absolute", color: "black" }}>IPX</div>
  }
  {form.tooch_number.includes("54-mis-prd-acr") &&
  <div style={{ position: "absolute", color: "black" }}>PRD</div>
  } 
  {form.tooch_number.includes("54-mis-fld-acr") &&
  <div style={{ position: "absolute", color: "black" }}>FLD</div>
  }      
  </div>
  :
  <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
  </div>
  }
    
  {form.tooch_number.includes("54-pob-53-miss-pon-pob-52-pob")
  ||form.tooch_number.includes("54-pob-53-miss-pon-pob-52-miss-pon-pob-51-pob") ?
  <>
  {/* meb 54 - 57 */}
  {form.tooch_number.includes("54-pob-53-miss-pon-pob-52-pob") &&
 <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
 <svg width="100%" height="20vh">

  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  </svg>
  </div>
  }
                    
  {/* meb 54 - 53 */}
  {form.tooch_number.includes("54-pob-53-miss-pon-pob-52-miss-pon-pob-51-pob") &&
  <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
  <svg width="100%" height="20vh">

  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  </svg>
  </div>
  }

  </>
  :
  <></>
  }


  {form.tooch_number.includes("54-meb-53-miss-pon-meb-52-meb")
  ||form.tooch_number.includes("54-meb-53-miss-pon-meb-52-miss-pon-meb-51-meb") ?
  <>
  {/* meb 54 - 57 */}
  {form.tooch_number.includes("54-meb-53-miss-pon-meb-52-meb") &&
  <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
  <svg width="100%" height="20vh">

  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  </svg>
  </div>
  }
                    
  {/* meb 54 - 53 */}
  {form.tooch_number.includes("54-meb-53-miss-pon-meb-52-miss-pon-meb-51-meb") &&
  <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
  <svg width="100%" height="20vh">

  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  </svg>
  </div>
  }

  {/* meb 54 - 52 */} 
  {form.tooch_number.includes("54-meb-54-miss-pon-meb-53-miss-pon-meb-52-miss-pon-meb-51-meb") &&      
  <div style={{ width: "100%", textAlign: "center", position:"absolute" }}>
  <svg width="100%" height="100%">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  </svg>
  </div> 
  }

  </>
  :
  <></>
  }


  <div style={{ textAlign: "center" }}>54</div>
  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("54-fmc") || form.tooch_number.includes("54-poc") || form.tooch_number.includes("54-ipx-poc") || form.tooch_number.match(/\b54-pob\b/) || form.tooch_number.match(/\b54-miss-pon-pob\b/) || form.tooch_number.match(/\b54-meb\b/) || form.tooch_number.match(/\b54-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
  {form.tooch_number.includes("54-poc") || form.tooch_number.includes("54-ipx-poc") || form.tooch_number.match(/\b54-pob\b/) || form.tooch_number.match(/\b54-miss-pon-pob\b/) ?
  <g>
    {/* Atas */}
    {form.tooch_number.includes("54B-fis") || form.tooch_number.includes("54B-amf") || form.tooch_number.includes("54B-cof") || form.tooch_number.includes("54B-car") ?
      <>
        {form.tooch_number.includes("54B-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="54B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 54B"
              points="0,0 40,0 30,10 10,10"
              style={{ fill: 'url(#54B-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("54-Bukal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("54B-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 54B"
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("54-Bukal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("54B-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="54B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 54B"
              points="0,0 40,0 30,10 10,10"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("54-Bukal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("54B-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 54B"
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("54-Bukal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      
      </>
      :
    <svg width="40" height="40">
      <defs>
        <pattern id="54B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
        </pattern>
      </defs>
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 54B"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: '#007610', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("54-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    </svg>
    }

    {/* Bawah */}
    {form.tooch_number.includes("54P-fis") || form.tooch_number.includes("54P-amf") || form.tooch_number.includes("54P-cof") || form.tooch_number.includes("54P-car") ?
      <>
        {form.tooch_number.includes("54P-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="54P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>

            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 54P"
              points="10,30 30,30 40,40 0,40"
              style={{ fill: 'url(#54P-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("54-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("54P-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 54P"
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("54-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("54P-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="54P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 54P"
              points="10,30 30,30 40,40 0,40"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("54-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("54P-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 54P"
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("54-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      </>
      :
      <svg width="40" height="40">
        <defs>
          <pattern id="54P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 54P"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("54-Palatal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {/* Kanan */}
    {form.tooch_number.includes("54M-fis") || form.tooch_number.includes("54M-amf") || form.tooch_number.includes("54M-cof") || form.tooch_number.includes("54M-car") ?
      <>
        {form.tooch_number.includes("54M-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="54M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 54M"
              points="30,10 40,0 40,40 30,30"
              style={{ fill: 'url(#54M-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("54-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("54M-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 54M"
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("54-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("54M-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="54M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 54M"
              points="30,10 40,0 40,40 30,30"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("54-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }
      
        {form.tooch_number.includes("54M-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 54M"
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("54-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      
      </>
      :
      <svg width="40" height="40">
        <defs>
          <pattern id="54M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 54M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("54-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {/* Kiri */}
    {form.tooch_number.includes("54D-fis") || form.tooch_number.includes("54D-amf") || form.tooch_number.includes("54D-cof") || form.tooch_number.includes("54D-car") ?
      <>
        {form.tooch_number.includes("54D-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="54D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 54D"
              points="0,0 10,10 10,30 0,40"
              style={{ fill: 'url(#54D-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("54-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("54D-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 54D"
            points="0,0 10,10 10,30 0,40"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("54-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("54D-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="54D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 54D"
              points="0,0 10,10 10,30 0,40"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("54-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("54D-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 54D"
            points="0,0 10,10 10,30 0,40"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("54-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

      </>
      :
      <svg width="40" height="40">
        <defs>
          <pattern id="54D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 54D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("54-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {/* center */}
    {form.tooch_number.includes("54O-fis") || form.tooch_number.includes("54O-amf") || form.tooch_number.includes("54O-cof") || form.tooch_number.includes("54O-car") ?
      <>
        {form.tooch_number.includes("54O-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="54O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 54O"
              points="10,10 30,10 30,30 10,30"
              style={{ fill: 'url(#54O-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("54-Oklusal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("54O-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 54O"
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("54-Oklusal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("54O-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="54O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 54O"
              points="10,10 30,10 30,30 10,30"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("54-Oklusal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("54O-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 54O"
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("54-Oklusal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

      </>
      :
      <svg width="40" height="40">
        <defs>
          <pattern id="54O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 54O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("54-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {/* cfr */}
    {form.tooch_number.includes("54-cfr") &&
      <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
        #
      </text>
    }

    {/* rrx */}
    {form.tooch_number.includes("54-rrx") &&    
    <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 54-rrx" onClick={onclikTooch} style={{cursor:"pointer"}}>
      v
    </text>
    }
          
    {/* mis */}
    {form.tooch_number.includes("54-mis") &&    
    <>  
      <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
      <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
    </> 
    }
            
  </g>
    :
  <g>
    {/* Atas */}
    {form.tooch_number.includes("54B-fis") || form.tooch_number.includes("54B-amf") || form.tooch_number.includes("54B-cof") || form.tooch_number.includes("54B-car") ?
      <>
        {form.tooch_number.includes("54B-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="54B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 54B"
              points="0,0 40,0 30,10 10,10"
              style={{ fill: 'url(#54B-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("54-Bukal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("54B-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 54B"
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("54-Bukal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("54B-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="54B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 54B"
              points="0,0 40,0 30,10 10,10"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("54-Bukal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("54B-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 54B"
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("54-Bukal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      
      </>
      :
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 54B"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("54-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {/* Bawah */}
    {form.tooch_number.includes("54P-fis") || form.tooch_number.includes("54P-amf") || form.tooch_number.includes("54P-cof") || form.tooch_number.includes("54P-car") ?
      <>
        {form.tooch_number.includes("54P-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="54P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>

            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 54P"
              points="10,30 30,30 40,40 0,40"
              style={{ fill: 'url(#54P-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("54-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("54P-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 54P"
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("54-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("54P-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="54P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 54P"
              points="10,30 30,30 40,40 0,40"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("54-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("54P-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 54P"
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("54-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      </>
      :
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 54P"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("54-Palatal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {/* Kanan */}
    {form.tooch_number.includes("54M-fis") || form.tooch_number.includes("54M-amf") || form.tooch_number.includes("54M-cof") || form.tooch_number.includes("54M-car") ?
      <>
        {form.tooch_number.includes("54M-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="54M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 54M"
              points="30,10 40,0 40,40 30,30"
              style={{ fill: 'url(#54M-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("54-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("54M-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 54M"
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("54-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("54M-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="54M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 54M"
              points="30,10 40,0 40,40 30,30"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("54-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }
      
        {form.tooch_number.includes("54M-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 54M"
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("54-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      
      </>
      :
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 54M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("54-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {/* Kiri */}
    {form.tooch_number.includes("54D-fis") || form.tooch_number.includes("54D-amf") || form.tooch_number.includes("54D-cof") || form.tooch_number.includes("54D-car") ?
      <>
        {form.tooch_number.includes("54D-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="54D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 54D"
              points="0,0 10,10 10,30 0,40"
              style={{ fill: 'url(#54D-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("54-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("54D-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 54D"
            points="0,0 10,10 10,30 0,40"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("54-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("54D-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="54D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 54D"
              points="0,0 10,10 10,30 0,40"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("54-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("54D-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 54D"
            points="0,0 10,10 10,30 0,40"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("54-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

      </>
      :
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 54D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("54-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {/* center */}
    {form.tooch_number.includes("54O-fis") || form.tooch_number.includes("54O-amf") || form.tooch_number.includes("54O-cof") || form.tooch_number.includes("54O-car") ?
      <>
        {form.tooch_number.includes("54O-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="54O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 54O"
              points="10,10 30,10 30,30 10,30"
              style={{ fill: 'url(#54O-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("54-Oklusal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("54O-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 54O"
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("54-Oklusal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("54O-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="54O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 54O"
              points="10,10 30,10 30,30 10,30"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("54-Oklusal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("54O-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 54O"
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("54-Oklusal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

      </>
      :
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 54O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("54-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {/* cfr */}
    {form.tooch_number.includes("54-cfr") &&
      <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
        #
      </text>
    }
    
    {form.tooch_number.includes("54-rrx") &&    
    <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 54-rrx" onClick={onclikTooch} style={{cursor:"pointer"}}>
      v
    </text>
    }
    
    {form.tooch_number.includes("54-mis") &&    
    <>  
      <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
      <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
    </> 
    }
  </g>
  }      
  </svg>
    
  {form.tooch_number.includes("54-nvt") &&   
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
  <svg width={42} height={42}>
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 54"
    points="21,2 41,32 1,32" 
    style={{ fill: 'white', stroke: 'black', strokeWidth: 3 }}
    onMouseEnter={() => setInfoGigi('54')}
    onMouseLeave={() => setInfoGigi('XX-X')}
  />
  </svg> 
  </div>
  }  

  {form.tooch_number.includes("54-rct") &&   
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
  <svg width={42} height={42}>
  <polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 54"
  points="21,2 41,32 1,32" 
  style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('54')}
  onMouseLeave={() => setInfoGigi('XX-X')}
  />
  </svg>
  </div>
  } 
    
  {form.tooch_number.includes("54B-amf-rct") || form.tooch_number.includes("54P-amf-rct") || form.tooch_number.includes("54M-amf-rct") || form.tooch_number.includes("54D-amf-rct") || form.tooch_number.includes("54O-amf-rct") ||
  form.tooch_number.includes("54B-cof-rct") || form.tooch_number.includes("54P-cof-rct") || form.tooch_number.includes("54M-cof-rct") || form.tooch_number.includes("54D-cof-rct") || form.tooch_number.includes("54O-cof-rct") ||
  form.tooch_number.includes("54-fmc-rct") ||
  form.tooch_number.includes("54-poc-rct") ?
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
  <svg width={42} height={42}>
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 54B"
    points="21,2 41,32 1,32" 
    style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
    onMouseEnter={() => setInfoGigi('54-Bukal')}
    onMouseLeave={() => setInfoGigi('XX-X')}
  />
  </svg>
  </div>
  :
  <></>
  } 

    </div>
      
    <div style={{ width: "42px", margin: "1px" }}>
  {form.tooch_number.includes("53-une") || form.tooch_number.includes("53-non") || form.tooch_number.includes("53-pre") || form.tooch_number.includes("53-ano") || form.tooch_number.includes("53-ipx-poc") || form.tooch_number.includes("53-mis-prd-acr") || form.tooch_number.includes("53-mis-fld-acr") ?
  <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
  {form.tooch_number.includes("53-une") &&
  <div style={{ position: "absolute", color: "black" }}>UNE</div>
  }
  {form.tooch_number.includes("53-non") &&
  <div style={{ position: "absolute", color: "black" }}>NON</div>
  }      
  {form.tooch_number.includes("53-pre") &&
  <div style={{ position: "absolute", color: "black" }}>PRE</div>
  }      
  {form.tooch_number.includes("53-ano") &&
  <div style={{ position: "absolute", color: "black" }}>ANO</div>
  }
  {form.tooch_number.includes("53-ipx-poc") &&
  <div style={{ position: "absolute", color: "black" }}>IPX</div>
  }
  {form.tooch_number.includes("53-mis-prd-acr") &&
  <div style={{ position: "absolute", color: "black" }}>PRD</div>
  } 
  {form.tooch_number.includes("53-mis-fld-acr") &&
  <div style={{ position: "absolute", color: "black" }}>FLD</div>
  }      
  </div>
  :
  <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
  </div>
  }

  {form.tooch_number.includes("53-pob-52-miss-pon-pob-51-pob") ?
  <>
  {/* meb 53 - 51 */}
  {form.tooch_number.includes("53-pob-52-miss-pon-pob-51-pob") &&
  <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
  <svg width="100%" height="20vh">


    {/* Garis ke samping dan ke bawah (L-shape) */}
    <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  </svg>
  </div>
  }
                            
  </>
  :
  <></>
  }

  {form.tooch_number.includes("53-meb-52-miss-pon-meb-51-meb") ?
  <>
  {/* meb 53 - 51 */}
  {form.tooch_number.includes("53-meb-52-miss-pon-meb-51-meb") &&
  <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
  <svg width="100%" height="20vh">


      {/* Garis ke samping dan ke bawah (L-shape) */}
      <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
      <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
      <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    </svg>
  </div>
  }                           
  </>
  :
  <></>
  }

  <div style={{ textAlign: "center" }}>53</div>
  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("53-fmc") || form.tooch_number.includes("53-poc") || form.tooch_number.includes("53-ipx-poc") || form.tooch_number.match(/\b53-pob\b/) || form.tooch_number.match(/\b53-miss-pon-pob\b/) || form.tooch_number.match(/\b53-meb\b/) || form.tooch_number.match(/\b53-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
  {form.tooch_number.includes("53-poc") || form.tooch_number.includes("53-ipx-poc") || form.tooch_number.match(/\b53-pob\b/) || form.tooch_number.match(/\b53-miss-pon-pob\b/) ?
  <g>
  {/* Atas */}
  {form.tooch_number.includes("53L-fis") || form.tooch_number.includes("53L-amf") || form.tooch_number.includes("53L-cof") || form.tooch_number.includes("53L-car") ?
  <>
    {form.tooch_number.includes("53L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="53L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 53L"
          points="0,0 40,0 30,20 10,20"
          style={{ fill: 'url(#53L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("53-Labial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />   
      </svg>
    }

    {form.tooch_number.includes("53L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 53L"
        points="0,0 40,0 30,20 10,20"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("53-Labial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("53L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="53L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 53L"
          points="0,0 40,0 30,20 10,20"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("53-Labial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("53L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 53L"
        points="0,0 40,0 30,20 10,20"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("53-Labial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="53L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 53L"
      points="0,0 40,0 30,20 10,20"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("53-Labial")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
  }

  {/* Bawah */}
  {form.tooch_number.includes("53P-fis") || form.tooch_number.includes("53P-amf") || form.tooch_number.includes("53P-cof") || form.tooch_number.includes("53P-car") ?
  <>
    {form.tooch_number.includes("53P-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="53P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 53P"
          points="30,20 40,40 0,40 10,20"
          style={{ fill: 'url(#53P-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("53-Palatal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("53P-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 53P"
        points="30,20 40,40 0,40 10,20"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("53-Palatal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("53P-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="53P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 53P"
          points="30,20 40,40 0,40 10,20"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("53-Palatal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("53P-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 53P"
        points="30,20 40,40 0,40 10,20"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("53-Palatal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="53P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 53P"
      points="30,20 40,40 0,40 10,20"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("53-Palatal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
  }

  {/* Kanan */}
  {form.tooch_number.includes("53M-fis") || form.tooch_number.includes("53M-amf") || form.tooch_number.includes("53M-cof") || form.tooch_number.includes("53M-car") ?
  <>
    {form.tooch_number.includes("53M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="53M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 53M"
          points="30,20 40,0 40,40 35,30"
          style={{ fill: 'url(#53M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("53-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("53M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 53M"
        points="30,20 40,0 40,40 35,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("53-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("53M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="53M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 53M"
          points="30,20 40,0 40,40 35,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("53-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("53M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 53M"
        points="30,20 40,0 40,40 35,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("53-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="53M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 53M"
      points="30,20 40,0 40,40 35,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("53-Mesial")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
  }

  {/* Kiri */}
  {form.tooch_number.includes("53D-fis") || form.tooch_number.includes("53D-amf") || form.tooch_number.includes("53D-cof") || form.tooch_number.includes("53D-car") ?
  <>
    {form.tooch_number.includes("53D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="53D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 53D"
          points="0,0 5,10 10,20 0,40"
          style={{ fill: 'url(#53D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("53-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("53D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 53D"
        points="0,0 5,10 10,20 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("53-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("53D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="53D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 53D"
          points="0,0 5,10 10,20 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("53-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("53D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 53D"
        points="0,0 5,10 10,20 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("53-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="53D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 53D"
      points="0,0 5,10 10,20 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("53-Distal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
  }

  {/* cfr */}
  {form.tooch_number.includes("53-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
  }

  {/* rrx */}
  {form.tooch_number.includes("53-rrx") &&    
  <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 53-rrx" onClick={onclikTooch} style={{cursor:"pointer"}}>
  v
  </text>
  }
      
  {/* mis */}
  {form.tooch_number.includes("53-mis") &&    
  <>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  </> 
  }
        
  </g>
  :
  <g>
  {/* Atas */}
  {form.tooch_number.includes("53L-fis") || form.tooch_number.includes("53L-amf") || form.tooch_number.includes("53L-cof") || form.tooch_number.includes("53L-car") ?
  <>
    {form.tooch_number.includes("53L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="53L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 53L"
          points="0,0 40,0 30,20 10,20"
          style={{ fill: 'url(#53L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("53-Labial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("53L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 53L"
        points="0,0 40,0 30,20 10,20"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("53-Labial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("53L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="53L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 53L"
          points="0,0 40,0 30,20 10,20"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("53-Labial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("53L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 53L"
        points="0,0 40,0 30,20 10,20"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("53-Labial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 53L"
    points="0,0 40,0 30,20 10,20"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("53-Labial")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
  }

  {/* Bawah */}
  {form.tooch_number.includes("53P-fis") || form.tooch_number.includes("53P-amf") || form.tooch_number.includes("53P-cof") || form.tooch_number.includes("53P-car") ?
  <>
    {form.tooch_number.includes("53P-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="53P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 53P"
          points="30,20 40,40 0,40 10,20"
          style={{ fill: 'url(#53P-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("53-Palatal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("53P-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 53P"
        points="30,20 40,40 0,40 10,20"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("53-Palatal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("53P-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="53P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 53P"
          points="30,20 40,40 0,40 10,20"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("53-Palatal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("53P-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 53P"
        points="30,20 40,40 0,40 10,20"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("53-Palatal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 53P"
    points="30,20 40,40 0,40 10,20"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("53-Palatal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
  }

  {/* Kanan */}
  {form.tooch_number.includes("53M-fis") || form.tooch_number.includes("53M-amf") || form.tooch_number.includes("53M-cof") || form.tooch_number.includes("53M-car") ?
  <>
    {form.tooch_number.includes("53M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="53M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 53M"
          points="30,20 40,0 40,40 35,30"
          style={{ fill: 'url(#53M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("53-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("53M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 53M"
        points="30,20 40,0 40,40 35,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("53-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("53M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="53M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 53M"
          points="30,20 40,0 40,40 35,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("53-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("53M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 53M"
        points="30,20 40,0 40,40 35,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("53-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 53M"
    points="30,20 40,0 40,40 35,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("53-Mesial")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
  }

  {/* Kiri */}
  {form.tooch_number.includes("53D-fis") || form.tooch_number.includes("53D-amf") || form.tooch_number.includes("53D-cof") || form.tooch_number.includes("53D-car") ?
  <>
    {form.tooch_number.includes("53D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="53D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 53D"
          points="0,0 5,10 10,20 0,40"
          style={{ fill: 'url(#53D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("53-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("53D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 53D"
        points="0,0 5,10 10,20 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("53-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("53D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="53D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 53D"
          points="0,0 5,10 10,20 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("53-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("53D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 53D"
        points="0,0 5,10 10,20 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("53-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 53D"
    points="0,0 5,10 10,20 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("53-Distal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
  }

  {/* cfr */}
  {form.tooch_number.includes("53-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
  }

  {form.tooch_number.includes("53-rrx") &&    
  <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 53-rrx" onClick={onclikTooch} style={{cursor:"pointer"}}>
  v
  </text>
  }

  {form.tooch_number.includes("53-mis") &&    
  <>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  </> 
  }
  </g>
  }    

  </svg>

  {form.tooch_number.includes("53-nvt") &&   
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
  <svg width={42} height={42}>
  <polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 53"
  points="21,2 41,32 1,32" 
  style={{ fill: 'white', stroke: 'black', strokeWidth: 3 }}
  onMouseEnter={() => setInfoGigi('53')}
  onMouseLeave={() => setInfoGigi('XX-X')}
  />
  </svg> 
  </div>
  }  

  {form.tooch_number.includes("53-rct") &&   
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
  <svg width={42} height={42}>
  <polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 53"
  points="21,2 41,32 1,32" 
  style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('53')}
  onMouseLeave={() => setInfoGigi('XX-X')}
  />
  </svg>
  </div>
  } 

  {form.tooch_number.includes("53B-amf-rct") || form.tooch_number.includes("53P-amf-rct") || form.tooch_number.includes("53M-amf-rct") || form.tooch_number.includes("53D-amf-rct") || form.tooch_number.includes("53L-amf-rct") ||
  form.tooch_number.includes("53B-cof-rct") || form.tooch_number.includes("53P-cof-rct") || form.tooch_number.includes("53M-cof-rct") || form.tooch_number.includes("53D-cof-rct") || form.tooch_number.includes("53L-cof-rct") ||
  form.tooch_number.includes("53-fmc-rct") ||
  form.tooch_number.includes("53-poc-rct") ?
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
  <svg width={42} height={42}>
  <polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 53B"
  points="21,2 41,32 1,32" 
  style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('53-Bukal')}
  onMouseLeave={() => setInfoGigi('XX-X')}
  />
  </svg>
  </div>
  :
  <></>
  } 

    </div> 
          
    <div style={{ width: "42px", margin: "1px" }}>
  {form.tooch_number.includes("52-une") || form.tooch_number.includes("52-non") || form.tooch_number.includes("52-pre") || form.tooch_number.includes("52-ano") || form.tooch_number.includes("52-ipx-poc") || form.tooch_number.includes("52-mis-prd-acr") || form.tooch_number.includes("52-mis-fld-acr") ?
  <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
    {form.tooch_number.includes("52-une") &&
      <div style={{ position: "absolute", color: "black" }}>UNE</div>
    }
    {form.tooch_number.includes("52-non") &&
      <div style={{ position: "absolute", color: "black" }}>NON</div>
    }      
    {form.tooch_number.includes("52-pre") &&
      <div style={{ position: "absolute", color: "black" }}>PRE</div>
    }      
    {form.tooch_number.includes("52-ano") &&
      <div style={{ position: "absolute", color: "black" }}>ANO</div>
    }
    {form.tooch_number.includes("52-ipx-poc") &&
      <div style={{ position: "absolute", color: "black" }}>IPX</div>
    }
    {form.tooch_number.includes("52-mis-prd-acr") &&
      <div style={{ position: "absolute", color: "black" }}>PRD</div>
    } 
    {form.tooch_number.includes("52-mis-fld-acr") &&
      <div style={{ position: "absolute", color: "black" }}>FLD</div>
    }      
  </div>
  :
  <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
  </div>
  }


  <div style={{ textAlign: "center" }}>52</div>
  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("52-fmc") || form.tooch_number.includes("52-poc") || form.tooch_number.includes("52-ipx-poc") || form.tooch_number.match(/\b52-pob\b/) || form.tooch_number.match(/\b52-miss-pon-pob\b/) || form.tooch_number.match(/\b52-meb\b/) || form.tooch_number.match(/\b52-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
  {form.tooch_number.includes("52-poc") || form.tooch_number.includes("52-ipx-poc") || form.tooch_number.match(/\b52-pob\b/) || form.tooch_number.match(/\b52-miss-pon-pob\b/) ?
  <g>
    {/* Atas */}
    {form.tooch_number.includes("52L-fis") || form.tooch_number.includes("52L-amf") || form.tooch_number.includes("52L-cof") || form.tooch_number.includes("52L-car") ?
      <>
        {form.tooch_number.includes("52L-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="52L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 52L"
              points="0,0 40,0 30,20 10,20"
              style={{ fill: 'url(#52L-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("52-Labial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />   
          </svg>
        }

        {form.tooch_number.includes("52L-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 52L"
            points="0,0 40,0 30,20 10,20"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("52-Labial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("52L-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="52L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 52L"
              points="0,0 40,0 30,20 10,20"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("52-Labial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("52L-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 52L"
            points="0,0 40,0 30,20 10,20"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("52-Labial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      
      </>
      :
      <svg width="40" height="40">
        <defs>
          <pattern id="52L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 52L"
          points="0,0 40,0 30,20 10,20"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("52-Labial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {/* Bawah */}
    {form.tooch_number.includes("52P-fis") || form.tooch_number.includes("52P-amf") || form.tooch_number.includes("52P-cof") || form.tooch_number.includes("52P-car") ?
      <>
        {form.tooch_number.includes("52P-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="52P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>

            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 52P"
              points="30,20 40,40 0,40 10,20"
              style={{ fill: 'url(#52P-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("52-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("52P-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 52P"
            points="30,20 40,40 0,40 10,20"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("52-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("52P-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="52P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 52P"
              points="30,20 40,40 0,40 10,20"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("52-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("52P-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 52P"
            points="30,20 40,40 0,40 10,20"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("52-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      </>
      :
      <svg width="40" height="40">
        <defs>
          <pattern id="52P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 52P"
          points="30,20 40,40 0,40 10,20"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("52-Palatal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {/* Kanan */}
    {form.tooch_number.includes("52M-fis") || form.tooch_number.includes("52M-amf") || form.tooch_number.includes("52M-cof") || form.tooch_number.includes("52M-car") ?
      <>
        {form.tooch_number.includes("52M-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="52M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 52M"
              points="30,20 40,0 40,40 35,30"
              style={{ fill: 'url(#52M-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("52-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("52M-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 52M"
            points="30,20 40,0 40,40 35,30"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("52-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("52M-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="52M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 52M"
              points="30,20 40,0 40,40 35,30"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("52-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }
      
        {form.tooch_number.includes("52M-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 52M"
            points="30,20 40,0 40,40 35,30"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("52-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      
      </>
      :
      <svg width="40" height="40">
        <defs>
          <pattern id="52M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 52M"
          points="30,20 40,0 40,40 35,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("52-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {/* Kiri */}
    {form.tooch_number.includes("52D-fis") || form.tooch_number.includes("52D-amf") || form.tooch_number.includes("52D-cof") || form.tooch_number.includes("52D-car") ?
      <>
        {form.tooch_number.includes("52D-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="52D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 52D"
              points="0,0 5,10 10,20 0,40"
              style={{ fill: 'url(#52D-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("52-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("52D-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 52D"
            points="0,0 5,10 10,20 0,40"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("52-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("52D-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="52D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 52D"
              points="0,0 5,10 10,20 0,40"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("52-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("52D-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 52D"
            points="0,0 5,10 10,20 0,40"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("52-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

      </>
      :
      <svg width="40" height="40">
        <defs>
          <pattern id="52D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 52D"
          points="0,0 5,10 10,20 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("52-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {/* cfr */}
    {form.tooch_number.includes("52-cfr") &&
      <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
        #
      </text>
    }

    {/* rrx */}
    {form.tooch_number.includes("52-rrx") &&    
    <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 52-rrx" onClick={onclikTooch} style={{cursor:"pointer"}}>
      v
    </text>
    }
          
    {/* mis */}
    {form.tooch_number.includes("52-mis") &&    
    <>  
      <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
      <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
    </> 
    }
            
  </g>
    :
  <g>
    {/* Atas */}
    {form.tooch_number.includes("52L-fis") || form.tooch_number.includes("52L-amf") || form.tooch_number.includes("52L-cof") || form.tooch_number.includes("52L-car") ?
      <>
        {form.tooch_number.includes("52L-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="52L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 52L"
              points="0,0 40,0 30,20 10,20"
              style={{ fill: 'url(#52L-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("52-Labial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("52L-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 52L"
            points="0,0 40,0 30,20 10,20"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("52-Labial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("52L-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="52L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 52L"
              points="0,0 40,0 30,20 10,20"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("52-Labial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("52L-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 52L"
            points="0,0 40,0 30,20 10,20"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("52-Labial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      
      </>
      :
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 52L"
        points="0,0 40,0 30,20 10,20"
        style={{ fill: 'white', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("52-Labial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {/* Bawah */}
    {form.tooch_number.includes("52P-fis") || form.tooch_number.includes("52P-amf") || form.tooch_number.includes("52P-cof") || form.tooch_number.includes("52P-car") ?
      <>
        {form.tooch_number.includes("52P-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="52P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>

            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 52P"
              points="30,20 40,40 0,40 10,20"
              style={{ fill: 'url(#52P-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("52-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("52P-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 52P"
            points="30,20 40,40 0,40 10,20"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("52-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("52P-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="52P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 52P"
              points="30,20 40,40 0,40 10,20"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("52-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("52P-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 52P"
            points="30,20 40,40 0,40 10,20"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("52-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      </>
      :
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 52P"
        points="30,20 40,40 0,40 10,20"
        style={{ fill: 'white', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("52-Palatal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {/* Kanan */}
    {form.tooch_number.includes("52M-fis") || form.tooch_number.includes("52M-amf") || form.tooch_number.includes("52M-cof") || form.tooch_number.includes("52M-car") ?
      <>
        {form.tooch_number.includes("52M-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="52M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 52M"
              points="30,20 40,0 40,40 35,30"
              style={{ fill: 'url(#52M-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("52-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("52M-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 52M"
            points="30,20 40,0 40,40 35,30"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("52-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("52M-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="52M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 52M"
              points="30,20 40,0 40,40 35,30"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("52-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }
      
        {form.tooch_number.includes("52M-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 52M"
            points="30,20 40,0 40,40 35,30"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("52-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      
      </>
      :
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 52M"
        points="30,20 40,0 40,40 35,30"
        style={{ fill: 'white', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("52-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {/* Kiri */}
    {form.tooch_number.includes("52D-fis") || form.tooch_number.includes("52D-amf") || form.tooch_number.includes("52D-cof") || form.tooch_number.includes("52D-car") ?
      <>
        {form.tooch_number.includes("52D-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="52D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 52D"
              points="0,0 5,10 10,20 0,40"
              style={{ fill: 'url(#52D-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("52-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("52D-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 52D"
            points="0,0 5,10 10,20 0,40"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("52-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("52D-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="52D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 52D"
              points="0,0 5,10 10,20 0,40"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("52-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("52D-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 52D"
            points="0,0 5,10 10,20 0,40"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("52-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

      </>
      :
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 52D"
        points="0,0 5,10 10,20 0,40"
        style={{ fill: 'white', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("52-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {/* cfr */}
    {form.tooch_number.includes("52-cfr") &&
      <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
        #
      </text>
    }
    
    {form.tooch_number.includes("52-rrx") &&    
    <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 52-rrx" onClick={onclikTooch} style={{cursor:"pointer"}}>
      v
    </text>
    }
    
    {form.tooch_number.includes("52-mis") &&    
    <>  
      <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
      <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
    </> 
    }
  </g>
  }    
      
  </svg>
    
  {form.tooch_number.includes("52-nvt") &&   
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
  <svg width={42} height={42}>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 52"
      points="21,2 41,32 1,32" 
      style={{ fill: 'white', stroke: 'black', strokeWidth: 3 }}
      onMouseEnter={() => setInfoGigi('52')}
      onMouseLeave={() => setInfoGigi('XX-X')}
    />
  </svg> 
  </div>
  }  

  {form.tooch_number.includes("52-rct") &&   
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
  <svg width={42} height={42}>
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 52"
    points="21,2 41,32 1,32" 
    style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
    onMouseEnter={() => setInfoGigi('52')}
    onMouseLeave={() => setInfoGigi('XX-X')}
  />
  </svg>
  </div>
  } 
    
  {form.tooch_number.includes("52B-amf-rct") || form.tooch_number.includes("52P-amf-rct") || form.tooch_number.includes("52M-amf-rct") || form.tooch_number.includes("52D-amf-rct") || form.tooch_number.includes("52L-amf-rct") ||
  form.tooch_number.includes("52B-cof-rct") || form.tooch_number.includes("52P-cof-rct") || form.tooch_number.includes("52M-cof-rct") || form.tooch_number.includes("52D-cof-rct") || form.tooch_number.includes("52L-cof-rct") ||
  form.tooch_number.includes("52-fmc-rct") ||
  form.tooch_number.includes("52-poc-rct") ?
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
  <svg width={42} height={42}>
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 52B"
    points="21,2 41,32 1,32" 
    style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
    onMouseEnter={() => setInfoGigi('52-Bukal')}
    onMouseLeave={() => setInfoGigi('XX-X')}
  />
  </svg>
  </div>
  :
  <></>
  } 

    </div> 
          
    <div style={{ width: "42px", margin: "1px" }}>
  {form.tooch_number.includes("51-une") || form.tooch_number.includes("51-non") || form.tooch_number.includes("51-pre") || form.tooch_number.includes("51-ano") || form.tooch_number.includes("51-ipx-poc") || form.tooch_number.includes("51-mis-prd-acr") || form.tooch_number.includes("51-mis-fld-acr") ?
  <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
    {form.tooch_number.includes("51-une") &&
      <div style={{ position: "absolute", color: "black" }}>UNE</div>
    }
    {form.tooch_number.includes("51-non") &&
      <div style={{ position: "absolute", color: "black" }}>NON</div>
    }      
    {form.tooch_number.includes("51-pre") &&
      <div style={{ position: "absolute", color: "black" }}>PRE</div>
    }      
    {form.tooch_number.includes("51-ano") &&
      <div style={{ position: "absolute", color: "black" }}>ANO</div>
    }
    {form.tooch_number.includes("51-ipx-poc") &&
      <div style={{ position: "absolute", color: "black" }}>IPX</div>
    }
    {form.tooch_number.includes("51-mis-prd-acr") &&
      <div style={{ position: "absolute", color: "black" }}>PRD</div>
    } 
    {form.tooch_number.includes("51-mis-fld-acr") &&
      <div style={{ position: "absolute", color: "black" }}>FLD</div>
    }      
  </div>
  :
  <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
  </div>
  }

  <div style={{ textAlign: "center" }}>51</div>
  <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("51-fmc") || form.tooch_number.includes("51-poc") || form.tooch_number.includes("51-ipx-poc") || form.tooch_number.match(/\b51-pob\b/) || form.tooch_number.match(/\b51-miss-pon-pob\b/) || form.tooch_number.match(/\b51-meb\b/) || form.tooch_number.match(/\b51-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
  {form.tooch_number.includes("51-poc") || form.tooch_number.includes("51-ipx-poc") || form.tooch_number.match(/\b51-pob\b/) || form.tooch_number.match(/\b51-miss-pon-pob\b/) ?
  <g>
    {/* Atas */}
    {form.tooch_number.includes("51L-fis") || form.tooch_number.includes("51L-amf") || form.tooch_number.includes("51L-cof") || form.tooch_number.includes("51L-car") ?
      <>
        {form.tooch_number.includes("51L-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="51L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 51L"
              points="0,0 40,0 30,20 10,20"
              style={{ fill: 'url(#51L-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("51-Labial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />   
          </svg>
        }

        {form.tooch_number.includes("51L-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 51L"
            points="0,0 40,0 30,20 10,20"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("51-Labial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("51L-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="51L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 51L"
              points="0,0 40,0 30,20 10,20"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("51-Labial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("51L-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 51L"
            points="0,0 40,0 30,20 10,20"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("51-Labial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      
      </>
      :
      <svg width="40" height="40">
        <defs>
          <pattern id="51L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 51L"
          points="0,0 40,0 30,20 10,20"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("51-Labial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {/* Bawah */}
    {form.tooch_number.includes("51P-fis") || form.tooch_number.includes("51P-amf") || form.tooch_number.includes("51P-cof") || form.tooch_number.includes("51P-car") ?
      <>
        {form.tooch_number.includes("51P-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="51P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>

            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 51P"
              points="30,20 40,40 0,40 10,20"
              style={{ fill: 'url(#51P-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("51-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("51P-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 51P"
            points="30,20 40,40 0,40 10,20"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("51-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("51P-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="51P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 51P"
              points="30,20 40,40 0,40 10,20"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("51-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("51P-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 51P"
            points="30,20 40,40 0,40 10,20"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("51-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      </>
      :
      <svg width="40" height="40">
        <defs>
          <pattern id="51P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 51P"
          points="30,20 40,40 0,40 10,20"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("51-Palatal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {/* Kanan */}
    {form.tooch_number.includes("51M-fis") || form.tooch_number.includes("51M-amf") || form.tooch_number.includes("51M-cof") || form.tooch_number.includes("51M-car") ?
      <>
        {form.tooch_number.includes("51M-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="51M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 51M"
              points="30,20 40,0 40,40 35,30"
              style={{ fill: 'url(#51M-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("51-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("51M-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 51M"
            points="30,20 40,0 40,40 35,30"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("51-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("51M-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="51M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 51M"
              points="30,20 40,0 40,40 35,30"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("51-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }
      
        {form.tooch_number.includes("51M-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 51M"
            points="30,20 40,0 40,40 35,30"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("51-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      
      </>
      :
      <svg width="40" height="40">
        <defs>
          <pattern id="51M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 51M"
          points="30,20 40,0 40,40 35,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("51-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {/* Kiri */}
    {form.tooch_number.includes("51D-fis") || form.tooch_number.includes("51D-amf") || form.tooch_number.includes("51D-cof") || form.tooch_number.includes("51D-car") ?
      <>
        {form.tooch_number.includes("51D-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="51D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 51D"
              points="0,0 5,10 10,20 0,40"
              style={{ fill: 'url(#51D-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("51-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("51D-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 51D"
            points="0,0 5,10 10,20 0,40"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("51-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("51D-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="51D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 51D"
              points="0,0 5,10 10,20 0,40"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("51-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("51D-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 51D"
            points="0,0 5,10 10,20 0,40"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("51-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

      </>
      :
      <svg width="40" height="40">
        <defs>
          <pattern id="51D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 51D"
          points="0,0 5,10 10,20 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("51-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {/* cfr */}
    {form.tooch_number.includes("51-cfr") &&
      <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
        #
      </text>
    }

    {/* rrx */}
    {form.tooch_number.includes("51-rrx") &&    
    <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 51-rrx" onClick={onclikTooch} style={{cursor:"pointer"}}>
      v
    </text>
    }
          
    {/* mis */}
    {form.tooch_number.includes("51-mis") &&    
    <>  
      <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
      <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
    </> 
    }
            
  </g>
    :
  <g>
    {/* Atas */}
    {form.tooch_number.includes("51L-fis") || form.tooch_number.includes("51L-amf") || form.tooch_number.includes("51L-cof") || form.tooch_number.includes("51L-car") ?
      <>
        {form.tooch_number.includes("51L-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="51L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 51L"
              points="0,0 40,0 30,20 10,20"
              style={{ fill: 'url(#51L-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("51-Labial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("51L-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 51L"
            points="0,0 40,0 30,20 10,20"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("51-Labial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("51L-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="51L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 51L"
              points="0,0 40,0 30,20 10,20"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("51-Labial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("51L-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 51L"
            points="0,0 40,0 30,20 10,20"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("51-Labial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      
      </>
      :
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 51L"
        points="0,0 40,0 30,20 10,20"
        style={{ fill: 'white', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("51-Labial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {/* Bawah */}
    {form.tooch_number.includes("51P-fis") || form.tooch_number.includes("51P-amf") || form.tooch_number.includes("51P-cof") || form.tooch_number.includes("51P-car") ?
      <>
        {form.tooch_number.includes("51P-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="51P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>

            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 51P"
              points="30,20 40,40 0,40 10,20"
              style={{ fill: 'url(#51P-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("51-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("51P-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 51P"
            points="30,20 40,40 0,40 10,20"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("51-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("51P-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="51P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 51P"
              points="30,20 40,40 0,40 10,20"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("51-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("51P-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 51P"
            points="30,20 40,40 0,40 10,20"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("51-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      </>
      :
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 51P"
        points="30,20 40,40 0,40 10,20"
        style={{ fill: 'white', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("51-Palatal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {/* Kanan */}
    {form.tooch_number.includes("51M-fis") || form.tooch_number.includes("51M-amf") || form.tooch_number.includes("51M-cof") || form.tooch_number.includes("51M-car") ?
      <>
        {form.tooch_number.includes("51M-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="51M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 51M"
              points="30,20 40,0 40,40 35,30"
              style={{ fill: 'url(#51M-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("51-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("51M-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 51M"
            points="30,20 40,0 40,40 35,30"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("51-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("51M-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="51M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 51M"
              points="30,20 40,0 40,40 35,30"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("51-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }
      
        {form.tooch_number.includes("51M-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 51M"
            points="30,20 40,0 40,40 35,30"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("51-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      
      </>
      :
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 51M"
        points="30,20 40,0 40,40 35,30"
        style={{ fill: 'white', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("51-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {/* Kiri */}
    {form.tooch_number.includes("51D-fis") || form.tooch_number.includes("51D-amf") || form.tooch_number.includes("51D-cof") || form.tooch_number.includes("51D-car") ?
      <>
        {form.tooch_number.includes("51D-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="51D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 51D"
              points="0,0 5,10 10,20 0,40"
              style={{ fill: 'url(#51D-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("51-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("51D-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 51D"
            points="0,0 5,10 10,20 0,40"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("51-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("51D-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="51D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 51D"
              points="0,0 5,10 10,20 0,40"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("51-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("51D-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 51D"
            points="0,0 5,10 10,20 0,40"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("51-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

      </>
      :
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 51D"
        points="0,0 5,10 10,20 0,40"
        style={{ fill: 'white', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("51-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {/* cfr */}
    {form.tooch_number.includes("51-cfr") &&
      <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
        #
      </text>
    }
    
    {form.tooch_number.includes("51-rrx") &&    
    <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 51-rrx" onClick={onclikTooch} style={{cursor:"pointer"}}>
      v
    </text>
    }
    
    {form.tooch_number.includes("51-mis") &&    
    <>  
      <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
      <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
    </> 
    }
  </g>
  }    
      
  </svg>
    
  {form.tooch_number.includes("51-nvt") &&   
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
  <svg width={42} height={42}>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 51"
      points="21,2 41,32 1,32" 
      style={{ fill: 'white', stroke: 'black', strokeWidth: 3 }}
      onMouseEnter={() => setInfoGigi('51')}
      onMouseLeave={() => setInfoGigi('XX-X')}
    />
  </svg> 
  </div>
  }  

  {form.tooch_number.includes("51-rct") &&   
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
  <svg width={42} height={42}>
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 51"
    points="21,2 41,32 1,32" 
    style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
    onMouseEnter={() => setInfoGigi('51')}
    onMouseLeave={() => setInfoGigi('XX-X')}
  />
  </svg>
  </div>
  } 
    
  {form.tooch_number.includes("51B-amf-rct") || form.tooch_number.includes("51P-amf-rct") || form.tooch_number.includes("51M-amf-rct") || form.tooch_number.includes("51D-amf-rct") || form.tooch_number.includes("51L-amf-rct") ||
  form.tooch_number.includes("51B-cof-rct") || form.tooch_number.includes("51P-cof-rct") || form.tooch_number.includes("51M-cof-rct") || form.tooch_number.includes("51D-cof-rct") || form.tooch_number.includes("51L-cof-rct") ||
  form.tooch_number.includes("51-fmc-rct") ||
  form.tooch_number.includes("51-poc-rct") ?
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
  <svg width={42} height={42}>
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 51B"
    points="21,2 41,32 1,32" 
    style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
    onMouseEnter={() => setInfoGigi('51-Bukal')}
    onMouseLeave={() => setInfoGigi('XX-X')}
  />
  </svg>
  </div>
  :
  <></>
  } 

    </div> 
  </div>
  );
}
