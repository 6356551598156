import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import swal from "sweetalert";
import { useMediaQuery } from 'react-responsive'
import Logo_Signature from "../../assets/signature/signature logo.png"
import Logo_Batik from "../../assets/signature/logo-batik.png"
import BackgroundBatik from "../../assets/signature/background-appoinment.jpg"
import SignatureCanvas from 'react-signature-canvas'
import { FaHandHoldingMedical, FaReply, FaSearch, FaSync, FaUndoAlt } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import { Alert } from "react-bootstrap";
import { useMutation } from "react-query";
import { API } from "../../config/api";
import { useParams } from 'react-router-dom';
import LoaderHome from "../Loader/LoaderHome"

// function MyVerticallyCenteredModal(props) {
//   const navigate = useNavigate()
//   const registerBaru = () => {      
//     navigate("/");
//   };

//   return (
//     <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
//       <div className="mb-" style={{ display: "flex", backgroundColor: "#E6E6E6", opacity: ".9", borderBottom:"5px solid #804D00", borderTopLeftRadius:"5px", borderTopRightRadius:"5px", padding:"0px 10px", height:"10vh"}}>
//           <div style={{ flex: "5%", fontSize: "18px", textAlign: "right", display: "flex", alignItems: "center", fontWeight: "600", borderRight: "1px solid #DBDBDB",color: "#804D00" }}>
//             Preview Identitas
//           </div> 
//           <div style={{ flex: "50%", fontSize: "15px", textAlign: "right", display: "flex", alignItems: "center", fontWeight: "600", color:"#804D00"}}>
//             <img src={Logo_Signature} alt="logo-signature" style={{ display: "flex", width: "40px",height:"6vh", marginBottom:"0px"}}/>
//             PT. SIGNATURE ANUGERAH SENTOSA
//         </div>    
//         <Modal.Header closeButton style={{backgroundColor:""}}>
//           {/* <Modal.Title id="contained-modal-title-vcenter">
//             Pilih Status Pasien
//           </Modal.Title> */}
        
//         </Modal.Header>
//       </div>
//       <Modal.Body style={{backgroundColor:"", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px"}}>
//         <div style={{ display: "flex", backgroundColor: "white", opacity: ".9", marginBottom:"5px" }}>
//           <div style={{flex:"50%", padding: "0px 30px 0px 10px" }}>
//             <div style={{ display: "flex", height:"22px" }}>
//               <div style={{flex:"40%", height:"100%", display:"flex", alignItems:"center", fontSize:"15px",color: "#804D00", fontWeight:"600"}}>
//                 Data Pasien
//               </div>
//             </div>
//           </div>
//           <div style={{flex:"50%", paddingRight: "30px",  }}>
//             <div style={{ display: "flex", height:"22px" }}>
//               <div style={{flex:"40%", dheight:"100%", display:"flex", alignItems:"center", fontSize:"15px",color: "#804D00",fontWeight:"600"}}>
//                 Data Keluarga
//               </div>
//             </div>
//           </div>
//         </div>

//         <div style={{ display: "flex", backgroundColor: "white", opacity: ".9" }}>
//           <div style={{flex:"50%", padding: "0px 30px 0px 10px" }}>
//             <div style={{ display: "flex", height:"22px" }}>
//               <div style={{flex:"40%", height:"100%", display:"flex", alignItems:"center", fontSize:"11px",color: "#804D00"}}>
//                 No. Rekan Medis
//               </div>
//               <div style={{ flex: "60%", display:"flex"  }}>
//                 <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
//                 <p style={{ fontSize:"11px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>SDC000152-122SF</p>
//               </div>
//             </div>
//           </div>
//           <div style={{flex:"50%", paddingRight: "30px",  }}>
//             <div style={{ display: "flex", height:"22px" }}>
//               <div style={{flex:"40%", dheight:"100%", display:"flex", alignItems:"center", fontSize:"11px",color: "#804D00"}}>
//                 Nama Lengkap
//               </div>
//               <div style={{ flex: "60%", display:"flex"  }}>
//                 <p style={{ marginRight: "10px",height:"100%", height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
//                 <p style={{ fontSize:"11px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>Arjuna Sri Rangkas</p>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div style={{ display: "flex", backgroundColor: "white", opacity: ".9" }}>
//           <div style={{flex:"50%", padding: "0px 30px 0px 10px" }}>
//             <div style={{ display: "flex", height:"22px" }}>
//               <div style={{flex:"40%", height:"100%", display:"flex", alignItems:"center", fontSize:"11px",color: "#804D00"}}>
//                 Nama Pasien
//               </div>
//               <div style={{ flex: "60%", display:"flex"  }}>
//                 <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
//                 <p style={{ fontSize:"11px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>Dwiki Wantara</p>
//               </div>
//             </div>
//           </div>
//           <div style={{flex:"50%", paddingRight: "30px",  }}>
//             <div style={{ display: "flex", height:"22px" }}>
//               <div style={{flex:"40%", dheight:"100%", display:"flex", alignItems:"center", fontSize:"11px",color: "#804D00"}}>
//                 Jenis Kelamin
//               </div>
//               <div style={{ flex: "60%", display:"flex"  }}>
//                 <p style={{ marginRight: "10px",height:"100%", height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
//                 <p style={{ fontSize:"11px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>Laki-Laki</p>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div style={{ display: "flex", backgroundColor: "white", opacity: ".9" }}>
//           <div style={{flex:"50%", padding: "0px 30px 0px 10px" }}>
//             <div style={{ display: "flex", height:"22px" }}>
//               <div style={{flex:"40%", height:"100%", display:"flex", alignItems:"center", fontSize:"11px",color: "#804D00"}}>
//                 Jenis Kelamin
//               </div>
//               <div style={{ flex: "60%", display:"flex"  }}>
//                 <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
//                 <p style={{ fontSize:"11px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>Laki-Laki</p>
//               </div>
//             </div>
//           </div>
//           <div style={{flex:"50%", paddingRight: "30px",  }}>
//             <div style={{ display: "flex", height:"22px" }}>
//               <div style={{flex:"40%", dheight:"100%", display:"flex", alignItems:"center", fontSize:"11px",color: "#804D00"}}>
//                 Hubungan Pasien
//               </div>
//               <div style={{ flex: "60%", display:"flex"  }}>
//                 <p style={{ marginRight: "10px",height:"100%", height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
//                 <p style={{ fontSize:"11px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>Saudara Kandung</p>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div style={{ display: "flex", backgroundColor: "white", opacity: ".9" }}>
//           <div style={{flex:"50%", padding: "0px 30px 0px 10px" }}>
//             <div style={{ display: "flex", height:"22px" }}>
//               <div style={{flex:"40%", height:"100%", display:"flex", alignItems:"center", fontSize:"11px",color: "#804D00"}}>
//                 Tempat/Tanggal Lahir
//               </div>
//               <div style={{ flex: "60%", display:"flex"  }}>
//                 <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
//                 <p style={{ fontSize:"11px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>Tangerang, 11-02-1998</p>
//               </div>
//             </div>
//           </div>
//           <div style={{flex:"50%", paddingRight: "30px",  }}>
//             <div style={{ display: "flex", height:"22px" }}>
//               <div style={{flex:"40%", dheight:"100%", display:"flex", alignItems:"center", fontSize:"11px",color: "#804D00"}}>
//                 Alamat Domisili
//               </div>
//               <div style={{ flex: "60%", display:"flex"  }}>
//                 <p style={{ marginRight: "10px",height:"100%", height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
//                 <p style={{ fontSize:"11px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>JL. Delta, Tangerang</p>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div style={{ display: "flex", backgroundColor: "white", opacity: ".9" }}>
//           <div style={{flex:"50%", padding: "0px 30px 0px 10px" }}>
//             <div style={{ display: "flex", height:"22px" }}>
//               <div style={{flex:"40%", height:"100%", display:"flex", alignItems:"center", fontSize:"11px",color: "#804D00"}}>
//                 Usia
//               </div>
//               <div style={{ flex: "60%", display:"flex"  }}>
//                 <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
//                 <p style={{ fontSize:"11px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>24 Tahun</p>
//               </div>
//             </div>
//           </div>
//           <div style={{flex:"50%", paddingRight: "30px",  }}>
//             <div style={{ display: "flex", height:"22px" }}>
//               <div style={{flex:"40%", dheight:"100%", display:"flex", alignItems:"center", fontSize:"11px",color: "#804D00"}}>
//                 Alamat KTP
//               </div>
//               <div style={{ flex: "60%", display:"flex"  }}>
//                 <p style={{ marginRight: "10px",height:"100%", height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
//                 <p style={{ fontSize:"11px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>JL. Delta, Tangerang</p>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div style={{ display: "flex", backgroundColor: "white", opacity: ".9" }}>
//           <div style={{flex:"50%", padding: "0px 30px 0px 10px" }}>
//             <div style={{ display: "flex", height:"22px" }}>
//               <div style={{flex:"40%", height:"100%", display:"flex", alignItems:"center", fontSize:"11px",color: "#804D00"}}>
//                 Agama
//               </div>
//               <div style={{ flex: "60%", display:"flex"  }}>
//                 <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
//                 <p style={{ fontSize:"11px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>Islam</p>
//               </div>
//             </div>
//           </div>
//           <div style={{flex:"50%", paddingRight: "30px",  }}>
//             <div style={{ display: "flex", height:"22px" }}>
//               <div style={{flex:"40%", dheight:"100%", display:"flex", alignItems:"center", fontSize:"11px",color: "#804D00"}}>
//                 No. Telp/HP
//               </div>
//               <div style={{ flex: "60%", display:"flex"  }}>
//                 <p style={{ marginRight: "10px",height:"100%", height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
//                 <p style={{ fontSize:"11px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>08130027921</p>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div style={{ display: "flex", backgroundColor: "white", opacity: ".9" }}>
//           <div style={{flex:"50%", padding: "0px 30px 0px 10px" }}>
//             <div style={{ display: "flex", height:"22px" }}>
//               <div style={{flex:"40%", height:"100%", display:"flex", alignItems:"center", fontSize:"11px",color: "#804D00"}}>
//                 Alamat Domisili
//               </div>
//               <div style={{ flex: "60%", display:"flex"  }}>
//                 <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
//                 <p style={{ fontSize:"11px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>JL. Samudra, Tangerang Selatan</p>
//               </div>
//             </div>
//           </div>
//           <div style={{flex:"50%", paddingRight: "30px",  }}>
//             <div style={{ display: "flex", height:"22px" }}>
//               <div style={{flex:"40%", dheight:"100%", display:"flex", alignItems:"center", fontSize:"11px",color: "#804D00"}}>
//                 Pekerjaan
//               </div>
//               <div style={{ flex: "60%", display:"flex"  }}>
//                 <p style={{ marginRight: "10px",height:"100%", height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
//                 <p style={{ fontSize:"11px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>Karyawan Swasta</p>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div style={{ display: "flex", backgroundColor: "white", opacity: ".9", width:"50%" }}>
//           <div style={{flex:"50%", padding: "0px 30px 0px 10px" }}>
//             <div style={{ display: "flex", height:"22px" }}>
//               <div style={{flex:"40%", height:"100%", display:"flex", alignItems:"center", fontSize:"11px",color: "#804D00"}}>
//                 Alamat KTP
//               </div>
//               <div style={{ flex: "60%", display:"flex"  }}>
//                 <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
//                 <p style={{ fontSize:"11px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>JL. Samudra, Tangerang Selatan</p>
//               </div>
//             </div>
//           </div>
          
//         </div>

//         <div style={{ display: "flex", backgroundColor: "white", opacity: ".9", width:"50%" }}>
//           <div style={{flex:"50%", padding: "0px 30px 0px 10px" }}>
//             <div style={{ display: "flex", height:"22px" }}>
//               <div style={{flex:"40%", height:"100%", display:"flex", alignItems:"center", fontSize:"11px",color: "#804D00"}}>
//                 No. Telp/HP
//               </div>
//               <div style={{ flex: "60%", display:"flex"  }}>
//                 <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
//                 <p style={{ fontSize:"11px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>082119576122</p>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div style={{ display: "flex", backgroundColor: "white", opacity: ".9", width:"50%" }}>
//           <div style={{flex:"50%", padding: "0px 30px 0px 10px" }}>
//             <div style={{ display: "flex", height:"22px" }}>
//               <div style={{flex:"40%", height:"100%", display:"flex", alignItems:"center", fontSize:"11px",color: "#804D00"}}>
//                 Kewarnegaraan
//               </div>
//               <div style={{ flex: "60%", display:"flex"  }}>
//                 <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
//                 <p style={{ fontSize:"11px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>Indonesia</p>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div style={{ display: "flex", backgroundColor: "white", opacity: ".9", width:"50%" }}>
//           <div style={{flex:"50%", padding: "0px 30px 0px 10px" }}>
//             <div style={{ display: "flex", height:"22px" }}>
//               <div style={{flex:"40%", height:"100%", display:"flex", alignItems:"center", fontSize:"11px",color: "#804D00"}}>
//                 Status
//               </div>
//               <div style={{ flex: "60%", display:"flex"  }}>
//                 <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
//                 <p style={{ fontSize:"11px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>Belum Kawin</p>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div style={{ display: "flex", backgroundColor: "white", opacity: ".9", width:"50%" }}>
//           <div style={{flex:"50%", padding: "0px 30px 0px 10px" }}>
//             <div style={{ display: "flex", height:"22px" }}>
//               <div style={{flex:"40%", height:"100%", display:"flex", alignItems:"center", fontSize:"11px",color: "#804D00"}}>
//                 Pendidikan
//               </div>
//               <div style={{ flex: "60%", display:"flex"  }}>
//                 <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
//                 <p style={{ fontSize:"11px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>SLTA</p>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div style={{ display: "flex", backgroundColor: "white", opacity: ".9", width:"50%" }}>
//           <div style={{flex:"50%", padding: "0px 30px 0px 10px" }}>
//             <div style={{ display: "flex", height:"22px" }}>
//               <div style={{flex:"40%", height:"100%", display:"flex", alignItems:"center", fontSize:"11px",color: "#804D00"}}>
//                 Pekerjaan
//               </div>
//               <div style={{ flex: "60%", display:"flex"  }}>
//                 <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
//                 <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center", width: ""}}>Karyawan Swasta</p>
//               </div>
//             </div>
//           </div>
//         </div>
        
//       </Modal.Body>
//       <Modal.Footer>
//         <div style={{ display: "flex" }}>
//           {/* <Button onClick={props.onHide} style={{marginRight:"8px", height:}}>Close</Button> */}
//           <div style={{ width:"150px"}}>
//             <Button onClick={registerBaru} type='submit' color='primary' style={{ fontSize:"15px", borderRadius:"3px", height:"100%", width:"100%"}}>
//               Daftar
//             </Button>
//           </div>
          
//         </div>
//       </Modal.Footer>
//     </Modal>
//     );
// }

export default function Login() {
  let navigate = useNavigate();
  document.title = "SDC Apps";
  const [state, dispatch] = useContext(UserContext);
  const [message, setMessage] = useState(null);
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)'})
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)'})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)'})
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const token = localStorage.getItem("token");
  const [idDelete, setIdDelete] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { code, id } = useParams();
  const [getData, setGetData] = useState([]);
  const [getDataTreatment, setGetDataTreatment] = useState([]);
  const [getDataAgreement, setGetDataAgreement] = useState([]);
  const [getCode, setGetCode] = useState([]);
  const { getCode2, setGetCode2 } = useState("");
  const [imageName, setImageName] = useState([])
  const [imageContent, setImageContent] = useState([])
  const [preview, setPreview] = useState(null);
  const [idRoleDokter, setIdRoleDokter] = useState("");
  const [nameRoleDoctor, setNameRoleDoktor] = useState("Doctor");
  const [getDataDokter, setGetDataDokter] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10000000);
  const [ascending, setAscending] = useState(0);
  // console.log(canvasRef)
  let [cBase64, setBase64] = useState();
  let [cBase64name, setBase64Name] = useState();
  let canvasRef;
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };
  
  const sweetAlert = () => {
    swal({
      title: 'Login Success',
      text: ' ',
      icon: 'success',
      timer: 3000,
      buttons: false
    });
  }

  const [form, setForm] = useState({
    // store appoinment
    anamnesa: "",
    generate_code: `${getCode2}`,
    date_appoinment: "",
    hours_appoinment: "",
    minute_appoinment: "",
    id_dokter: "",
    image_name : imageName,
    image_content: imageContent,
    id_treatment: [],

    // store consent
    question_allergy: "",
    question_medicine: "",
    question_condition: "",
    id_agreement: ""
  });

  // console.log(form?.image_name)

  const {
      // store appoinment
      anamnesa,
      generate_code,
      date_appoinment,
      hours_appoinment,
      minute_appoinment,
      id_dokter,
      id_treatment,
  
      // store consent
      question_allergy,
      question_medicine,
      question_condition,
      id_agreement
  } = form;

  const [formTreatment, setFormTreatment] = useState({
    treatment: [],
  });
  
  const [formAgreement, setFormAgreement] = useState({
    agreement: [],
  });
  // console.log(formTreatment.treatment)

  const handleChangeCheckbox = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { treatment } = formTreatment;
    // console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setFormTreatment({
        treatment: [...treatment, {"id_treatment" : `${value}`}],
      });
    }
  
    // Case 2  : The user unchecks the box
    else {
      setFormTreatment({
        treatment: treatment.filter(e => {return e.id_treatment !== `${value}`} ),

      });
    }
  };

  const handleChangeCheckboxAgreement = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { agreement } = formAgreement;
    // console.log(`${value} is ${checked}`);
    // Case 1 : The user checks the box
    if (checked) {
      setFormAgreement({
        agreement: [...agreement, {"id_agreement" : `${value}`, "agree" : `${value? 1 : 0}`}],
      });
    }
  
    // Case 2  : The user unchecks the box
    else {
      setFormAgreement({
        agreement: agreement.filter(e => {return e.id_agreement !== `${value}`} ),
      });
    }
  };

  const handleChange = async (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.type === "file" ? e.target.files : e.target.type === "date" ? e.target.value : e.target.value,
    });

    // Create image url for preview
    if (e.target.type === "file") {
      const file = e.target.files[0]
      console.log(file)
      const base64 = await convertBase64(file)
      setPreview(base64)
      setImageName(e.target.files[0].name)
      setImageContent(base64.split(',')[1])
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const postUser = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
    // Data body
    // const body = JSON.stringify(form);
    const responseAppoinment = await API.post(`transaction-service/appointments/store`,
    {
      id_patient: `${id}`,
      code: `${getCode?.number}`,
      date: form?.date_appoinment,
      id_doctor: `${form?.id_dokter}`,
      time: `${form?.hours_appoinment}:${form?.minute_appoinment}:00`,
      anamnesa: `${form?.anamnesa}`,
      treatments: formTreatment?.treatment
    },fetchParams)

    const responseConsent = await API.post(`transaction-service/consents/store`,
    {
      id_patient: `${id}`,
      question_allergy: `${form?.question_allergy}`,
      question_medicine: `${form?.question_medicine}`,
      question_condition: `${form?.question_condition}`,
      agreements: formAgreement?.agreement,
      filename: "signature.png",
      signature: cBase64
    },fetchParams)

    if (responseConsent?.status === 200 && responseAppoinment?.status === 200) {
      swal({
        title: 'Success',
        text: "Berhasil Membuat Appoinment Pasien",
        icon: 'success',
        timer: 3000,
        buttons: false
      });
      navigate("/patients");
    }
    setLoading(false)
  } catch (error) {
    swal({
      title: 'Failed',
      text: error.message,
      icon: 'error',
      buttons: false
    });
    setLoading(false)
  }
  }

  const generateCode = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.post(`user-service/master-codes/generate`,
        {
          type: "appointment",
          object_code: `${code}`
        },
      fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetCode(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataDokter = async () => {
    const response = await API.get(`user-service/users?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&id_role=${idRoleDokter}&name_role=${nameRoleDoctor}`,fetchParams)
    setGetDataDokter(response.data.data)
  }

  const GetResponseData = async () => {
    const response = await API.get(`transaction-service/patients/${id}`,fetchParams)
    setGetData(response.data.data)
  }

  // const GetResponseDataTreatment = async () => {
  //   const response = await API.get(`transaction-service/treatments?page=${1}&limit=${1000}&ascending=${1}`,fetchParams)
  //   setGetDataTreatment(response.data.data)
  // }

  const GetResponseDataAgreement = async () => {
    const response = await API.get(`/transaction-service/agreements?page=${1}&limit=${1000}&ascending=${1}`,fetchParams)
    setGetDataAgreement(response.data.data)
  }

  useEffect(() => {
    GetResponseDataDokter()
    GetResponseData() 
    // GetResponseDataTreatment() 
    GetResponseDataAgreement()
  }, [])
  
  // const [dokete, setDokete] = useState([])
  // console.log(dokete)
  // const valueDokter = (id, firstname) => {
  //   setDokete(id)
  // }
 
  return (
    <div className='auth-wrapper auth-cover' >
    {loading && <LoaderHome/>}
      {isTabletOrMobile ?
    <Row>
    {/* <MyVerticallyCenteredModal
      show={modalShow}
      onHide={() => setModalShow(false)}
    /> */}
    <Col xl='12' sm='12' style={{ backgroundImage: `url(${BackgroundBatik})`, fontFamily:"sans-serif" }}>
    <form onSubmit={(e) =>postUser}>
      <div style={{paddingTop:"20px",paddingBottom:"20px", backgroundColor: "white", opacity: ".9", borderTopLeftRadius:"5px", borderTopRightRadius:"5px",borderBottom:"5px solid #804D00",}}>
        <div style={{fontSize:"30px",display: "flex",justifyContent:"center", alignItems: "center", fontWeight: "600", color:"#804D00" }}>
            Buat Appoinment
        </div>  
      </div>
      {/* <div style={{display:"flex", backgroundColor: "white", opacity: ".9", borderBottom:"5px solid #804D00",paddingTop:"30px", paddingBottom:"10px"}}>
        <div style={{ flex: "50%"}}>
                  
          <div style={{display:"flex", paddingLeft:"50px", paddingRight:"30px" }}>
            <div style={{flex:"30%"}}>
              Nama Lengkap
            </div>
            <div style={{ flex: "70%", display:"flex"}}>
              <p style={{marginRight:"10px"}}>:</p>
              <input autoFofcus type='text' value={`${getData?.first_name} ${getData?.last_name} ` } style={{width:"100%", border:"none", borderBottom:"1px solid #804D00", outline:"none", height:"28px",backgroundColor:"#F2F2F2"}} disabled/>
            </div>    
          </div>

          <div style={{display:"flex", paddingLeft:"50px", paddingRight:"30px" }}>
            <div style={{flex:"30%"}}>
              Pasien Code
            </div>
            <div style={{ flex: "70%", display:"flex"}}>
              <p style={{marginRight:"10px"}}>:</p>
              <input autoFofcus type='text' value={getData?.code}  style={{width:"100%", border:"none", borderBottom:"1px solid #804D00", outline:"none", height:"28px",backgroundColor:"#F2F2F2"}} disabled/>
            </div>    
          </div>
        </div>
                
        <div style={{flex:"50%"}}>
          <div style={{display:"flex", paddingLeft:"30px", paddingRight:"50px" }}>
            <div style={{flex:"30%"}}>
              Jenis Kelamin
            </div>
            <div style={{ flex: "70%", display:"flex"}}>
              <p style={{marginRight:"10px"}}>:</p>
              <input autoFofcus type='text' value={getData?.gender} style={{width:"100%", border:"none", borderBottom:"1px solid #804D00", outline:"none", height:"28px",backgroundColor:"#F2F2F2"}} disabled/>
            </div>    
          </div>

          <div style={{display:"flex", paddingLeft:"30px", paddingRight:"50px" }}>
            <div style={{flex:"30%"}}>
              Tgl Lahir
            </div>
            <div style={{ flex: "70%", display:"flex"}}>
              <p style={{marginRight:"10px"}}>:</p>
              <input autoFofcus type='text'
                value={`${getData.date_birth}`.split('-')[2] + (` `) + (
                `${getData.date_birth}`.split('-')[1] === "01" ? "Januari" : "" ||
                `${getData.date_birth}`.split('-')[1] === "02" ? "Februari" : "" ||
                `${getData.date_birth}`.split('-')[1] === "03" ? "Maret" : "" ||
                `${getData.date_birth}`.split('-')[1] === "04" ? "April" : "" ||  
                `${getData.date_birth}`.split('-')[1] === "05" ? "Mei" : "" ||
                `${getData.date_birth}`.split('-')[1] === "06" ? "Juni" : "" ||
                `${getData.date_birth}`.split('-')[1] === "07" ? "Juli" : "" ||
                `${getData.date_birth}`.split('-')[1] === "08" ? "Agustus" : "" ||
                `${getData.date_birth}`.split('-')[1] === "09" ? "September" : "" ||
                `${getData.date_birth}`.split('-')[1] === "10" ? "Oktober" : "" ||
                `${getData.date_birth}`.split('-')[1] === "11" ? "November" : "" ||
                `${getData.date_birth}`.split('-')[1] === "12" ? "Desember" : "") + (` `) +
                `${getData.date_birth}`.split('-')[0]
                }
                style={{ width: "100%", border: "none", borderBottom: "1px solid #804D00", outline: "none", height: "28px", backgroundColor: "#F2F2F2" }} disabled />
            </div>    
          </div>      
      </div>
    </div>
  */}
    <div style={{ backgroundColor: "white", opacity: ".9", borderBottom:"5px solid #804D00"}}>
    <p style={{  opacity: ".9",fontSize: "20px", fontWeight: "700", color: "#804D00", backgroundColor:"white", padding:"10px 10px 0px 10px"}}>Detail Perawatan Gigi</p>
      <div style={{ padding: "10px 30px" }}>
                  
        <div className="mt-4" style={{ display: "flex" }}>
          <div style={{flex:"5%"}}>
            <p>Anamnesa</p>
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px" }}>:</p>
            <div style={{width:"100%"}}>
              <input type="text" value={anamnesa} name="anamnesa" onChange={handleChange}  style={{width:"100%", border:"none", borderBottom:"1px solid #804D00", outline:"none", height:"20px"}}/>
              <div style={{fontSize:"8px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>keluhan pasien</div>
            </div>
            </div>
        </div>
                
        <div className="mt-3" style={{ display: "flex"}}>
          <div style={{flex:"5%",display:"flex", alignItems:"center"}}>
            Pilih Dokter
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <select className="form-select" aria-label="Default select example" onChange={handleChange}  name="id_dokter" style={{ textAlign:"", cursor:"pointer"}}>
              <option value="" hidden>Select Dokter</option>
              <option value="cde75ac2-de57-4f3b-a5f1-779fe24c11a0" style={{textAlign:"", height:"4vh"}}>drg Virena</option>         
            </select>
          </div>
      </div>
        <div className="mt-4" style={{ display: "flex" }}>
          <div style={{flex:"5%"}}>
            <p>Generate Code</p>
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px" }}>:</p>
            <div style={{width:"100%", marginRight:"10px"}}>
              <input autoFofcus type='text' value={getCode?.number} name="generate_code" onChange={handleChange} type="text" style={{width:"100%", border:"none", borderBottom:"1px solid #804D00", outline:"none", height:"20px"}}/>
              <div style={{fontSize:"8px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Tekan tombol disamping untuk mendapatkan code appoinment</div>
            </div>
            <div onClick={generateCode} style={{height:"70%", marginRight:"5px", paddingTop:"0px",backgroundColor:"white",padding:"0px 15px", boxShadow:"1px 1px 3px #8B8B8B", borderRadius:"3px", cursor:"pointer"}}>
              <FaSync style={{ fontSize: "15px", marginRight: "0px", marginTop: "0px", display: "flex", alignItems: "center", height:"100%", color:"#3D64FF" }} />
            </div>
            </div>
        </div>
        
        <div className="mt-4" style={{ display: "flex"}}>
          <div style={{flex:"5%"}}>
            <p>Tanggal Dan Waktu Appoinment</p>
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{marginRight:"5px", height:"100%"}}>:</p>
            <input type="date" name="date_appoinment" onChange={handleChange} style={{border:"none", borderBottom:"1px solid #804D00", outline:"none",display:"flex", alignItems:"end", height:"20px", textAlign:"center", marginRight:"10px", width:"50%" }}/>
      
            <select onChange={handleChange}  name="hours_appoinment" style={{ display:"flex", alignItems:"end",textAlign:"center", cursor:"pointer", height:"20px",border:"none", borderBottom:"1px solid #804D00", outline:"none", borderRadius:"none",marginRight:"10px",appearance:"none", width:"15%"}}>
              <option value="" hidden>Jam</option>
              {/* <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option> */}
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              {/* <option value="22">22</option>
              <option value="23">23</option>   */}
            </select> 
                      
            <p style={{height:"100%", marginRight:"10px"}}>:</p>
                      
            <select onChange={handleChange}  name="minute_appoinment" style={{display:"flex", alignItems:"end", textAlign:"center", cursor:"pointer",height:"20px",border:"none", borderBottom:"1px solid #804D00", outline:"none", borderRadius:"none",appearance:"none",width:"20%"}}>
              <option value="" hidden>Menit</option>
              <option value="00">00</option>
              {/* <option value="10">10</option>
              <option value="20">20</option> */}
              <option value="30">30</option>
              {/* <option value="40">40</option>
              <option value="50">50</option>         */}
            </select>
                      
          </div>
        </div>
                  
        {/* <div className="mt-3" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex", alignItems:""}}>
            Jenis Perawatan Gigi
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
          <p style={{ marginRight: "10px", display: "flex", alignItems: "", height: "100%" }}>:</p>
        <div>
          {getDataTreatment.map((user,index) => (
            <div key={index} className="mb-3" style={{display:"flex", width:"100%", cursor:"pointer"}}>
              <input autoFofcus type='checkbox' value={user?.id} name="id_treatment" onChange={handleChangeCheckbox} style={{display:"flex", alignItems:"center",marginRight:"5px", border: "none", borderBottom: "1px solid #804D00", cursor:"pointer" }} />
              <div style={{display:"flex", alignItems:"center"}}>
                {user?.name}
              </div>
            </div>
          ))}
          </div>
        </div>
      </div> */}
                  
          
      </div>
    </div>

    <div style={{ backgroundColor: "white", opacity: ".9", borderBottom: "5px solid #804D00"}}>
    <p style={{ opacity: ".9",fontSize: "20px", fontWeight: "700", color: "#804D00", backgroundColor:"white", padding:"10px 10px" }}>Form Consent</p>
      <div style={{ padding: "10px 30px" }}>
            
        <div className="mt-1" style={{ display: "flex"}}>
          <div style={{flex:"5%"}}>
            <p>Apakah Anda Memiliki Alergi?</p>
          </div>
          <div style={{ flex: "40%", display:"flex"}}>
            <p style={{marginRight:"10px"}}>:</p>
            <input autoFofcus type='text' value={question_allergy} name="question_allergy" onChange={handleChange} style={{width:"100%", border:"none", borderBottom:"1px solid #804D00", outline:"none", height:"20px"}}/>
          </div>
        </div>
                  
        {/* <div className="mt-3" style={{ display: "flex"}}>
          <div style={{flex:"5%",display:"flex", alignItems:"center"}}>
            Golongan Darah
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <select className="form-select" aria-label="Default select example" onChange={handleChange}  name="font_size_title" style={{ textAlign:"", cursor:"pointer"}}>
              <option value="" hidden>Select</option>
              <option value="A" style={{ textAlign: "" }}>A</option>
              <option value="A+" style={{textAlign:""}}>A+</option>
              <option value="A-" style={{textAlign:""}}>A-</option>
              <option value="B" style={{textAlign:""}}>B</option>
              <option value="B+" style={{textAlign:""}}>B+</option>
              <option value="B-" style={{textAlign:""}}>B-</option>
              <option value="AB" style={{textAlign:""}}>AB</option>
              <option value="AB+" style={{textAlign:""}}>AB+</option>
              <option value="AB-" style={{textAlign:""}}>AB-</option>
              <option value="O" style={{textAlign:""}}>O</option>
              <option value="O+" style={{textAlign:""}}>O+</option>
              <option value="O-" style={{ textAlign: "" }}>O-</option>
              <option value="" style={{textAlign:""}}>Other</option>           
            </select>
          </div>
        </div> */}
                  
        <div className="mt-4" style={{ display: "flex"}}>
          <div style={{flex:"5%"}}>
            <p>Apakah saat ini anda sedang mengkonsumsi obat?</p>
          </div>
          <div style={{ flex: "40%", display:"flex"}}>
            <p style={{marginRight:"10px"}}>:</p>
            <input autoFofcus type='text' value={question_medicine} name="question_medicine" onChange={handleChange}  style={{width:"100%", border:"none", borderBottom:"1px solid #804D00", outline:"none", height:"20px"}}/>
          </div>
        </div>
                  
        <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%"}}>
            <p>Apakah anda memiliki kondisi medis yang harus kami ketahui? (Jantung, diabetes, dan lain-lain)</p>
          </div>
          <div style={{ flex: "40%", display:"flex"}}>
            <p style={{marginRight:"10px"}}>:</p>
            <input autoFofcus type='text' value={question_condition} name="question_condition" onChange={handleChange}  style={{width:"100%", border:"none", borderBottom:"1px solid #804D00", outline:"none", height:"20px"}}/>
          </div>
        </div>
                  
          {getDataAgreement.map((user,index) => (
            <div className="mt-4" style={{ display: "flex" }}>
              <div style={{ display: "flex", marginRight:"10px", paddingTop:"1px" }}>
                <div>
                  <input  type='checkbox' value={user?.id} name="agreement" onChange={handleChangeCheckboxAgreement} style={{cursor:"pointer"}} />
                </div>
              </div>
              <div className="" style={{ display: "flex",  width: "100%", cursor: "pointer" }}>
                <div style={{display:"flex", alignItems:""}}>
                  {user?.name}
                </div>
              </div>
            </div>
            ))}    
                  
              
        {/* <div className="mt-4" style={{ display: "flex" }}>
          <div style={{flex:"5%"}}>
            <p>Obat Yang Sedang Dikonsumsi</p>
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{marginRight:"10px"}}>:</p>
            <input style={{width:"100%", border:"none", borderBottom:"1px solid #804D00", outline:"none", height:"20px"}}/>
          </div>
        </div>
        <div className="mt-1" style={{ display: "flex" }}>
          <div style={{flex:"5%"}}>
            <p>Pernah Operasi</p>
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{marginRight:"10px"}}>:</p>
            <input style={{width:"100%", border:"none", borderBottom:"1px solid #804D00", outline:"none", height:"20px"}}/>
          </div>
        </div>
        <div className="mt-4" style={{ display: "flex" }}>
          <div style={{flex:"5%"}}>
            <p>Perhatian Khusus</p>
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{marginRight:"10px"}}>:</p>
            <input style={{width:"100%", border:"none", borderBottom:"1px solid #804D00", outline:"none", height:"20px"}}/>
          </div>
                  </div> */}
      <div style={{padding: "10px 0px"}}>
        <div className="" style={{ display: "", marginTop:"50px"  }}>
        <div style={{ flex: "50%"}}>
          <div style={{ display:"flex", justifyContent:"center"}}>
                <p>PETUGAS</p>
              </div>
              <div style={{ display: "flex", justifyContent: "center", margin:"10px 0px" }}>
                <div style={{ display:"flex", marginLeft:"25px", borderRight:"1px solid #D8D8D8", borderLeft:"1px solid #D8D8D8"}}>
                  <SignatureCanvas penColor='black' canvasProps={{ width: 180, height: 150, className: 'sigCanvas' }} />
                </div>
                <div style={{paddingLeft:"10px", display:"flex"}}>
                  <FaUndoAlt  style={{cursor:"pointer"}}/>
                </div>
              </div>
              <div style={{ display:"flex", justifyContent:"center"}}>
                <p>{`( Tanda Tangan Digital )`}</p>
              </div>
          </div>
          <div style={{ flex: "50%", marginTop:"20px"}}>
          <div style={{ display:"flex", justifyContent:"center"}}>
                <p>PASIEN / WALI PASIEN</p>
              </div>
              <div style={{ display: "flex", justifyContent: "center", margin:"10px 0px" }}>
                <div style={{ display:"flex", marginLeft:"25px", borderRight:"1px solid #D8D8D8", borderLeft:"1px solid #D8D8D8"}}>
                  <SignatureCanvas penColor='black' canvasProps={{ width: 180, height: 150, className: 'sigCanvas' }} />
                </div>
                <div style={{paddingLeft:"10px", display:"flex"}}>
                  <FaUndoAlt  style={{cursor:"pointer"}}/>
                </div>
              </div>
              <div style={{ display:"flex", justifyContent:"center"}}>
                <p>{`( Tanda Tangan Digital )`}</p>
              </div>
          </div>
        </div>
                  </div>
                  
          <div style={{display:"flex",justifyContent:"center", padding: "0px 0px", marginTop: "40px", marginBottom: "50px"}}>
          <div style={{width:"200px"}}>
            <Button onClick={postUser} type='submit' color='primary' block style={{ fontSize:"15px", borderRadius:"3px", height:"45px"}}>
              Buat Appointment
            </Button>
          </div>
        </div>
              
      </div>
    </div>
  
    {/* <div style={{ backgroundColor: "white", opacity: ".9", height:"430px", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px"}}>
      <div style={{padding: "10px 0px"}}>
        <div className="" style={{ display: "flex", marginTop:"50px"  }}>
        <div style={{ flex: "50%"}}>
          <div style={{ display:"flex", justifyContent:"center"}}>
                <p>PETUGAS</p>
              </div>
              <div style={{ display: "flex", justifyContent: "center", margin:"10px 0px" }}>
                <div style={{ display:"flex", marginLeft:"25px", borderRight:"1px solid #D8D8D8", borderLeft:"1px solid #D8D8D8"}}>
                  <SignatureCanvas penColor='black' canvasProps={{ width: 180, height: 150, className: 'sigCanvas' }} />
                </div>
                <div style={{paddingLeft:"10px", display:"flex"}}>
                  <FaUndoAlt  style={{cursor:"pointer"}}/>
                </div>
              </div>
              <div style={{ display:"flex", justifyContent:"center"}}>
                <p>{`( Tanda Tangan Digital )`}</p>
              </div>
          </div>
          <div style={{ flex: "50%"}}>
          <div style={{ display:"flex", justifyContent:"center"}}>
                <p>PASIEN / WALI PASIEN</p>
              </div>
              <div style={{ display: "flex", justifyContent: "center", margin:"10px 0px" }}>
                <div style={{ display:"flex", marginLeft:"25px", borderRight:"1px solid #D8D8D8", borderLeft:"1px solid #D8D8D8"}}>
                  <SignatureCanvas penColor='black' canvasProps={{ width: 180, height: 150, className: 'sigCanvas' }} />
                </div>
                <div style={{paddingLeft:"10px", display:"flex"}}>
                  <FaUndoAlt  style={{cursor:"pointer"}}/>
                </div>
              </div>
              <div style={{ display:"flex", justifyContent:"center"}}>
                <p>{`( Tanda Tangan Digital )`}</p>
              </div>
          </div>
        </div>
      </div>
        
      
    </div> */}
    </form>
  </Col>
    </Row>
        :
    <Row>
    {/* <MyVerticallyCenteredModal
      show={modalShow}
      onHide={() => setModalShow(false)}
    /> */}
    <Col xl='12' sm='12' style={{ backgroundImage: `url(${BackgroundBatik})`, padding: "50px 300px 50px 300px", fontFamily:"sans-serif" }}>
    <form onSubmit={(e) =>postUser}>
      <div style={{paddingTop:"50px", backgroundColor: "white", opacity: ".9", borderTopLeftRadius:"5px", borderTopRightRadius:"5px"}}>
        <div style={{fontSize:"30px",display: "flex",justifyContent:"center", alignItems: "center", fontWeight: "600", color:"#804D00" }}>
            Buat Appoinment
        </div>  
      </div>
      <div style={{display:"flex", backgroundColor: "white", opacity: ".9", borderBottom:"5px solid #804D00",paddingTop:"30px", paddingBottom:"10px"}}>
        <div style={{ flex: "50%"}}>
                  
          <div style={{display:"flex", paddingLeft:"50px", paddingRight:"30px" }}>
            <div style={{flex:"30%"}}>
              Nama Lengkap
            </div>
            <div style={{ flex: "70%", display:"flex"}}>
              <p style={{marginRight:"10px"}}>:</p>
              <input autoFofcus type='text' value={`${getData?.first_name} ${getData?.last_name} ` } style={{width:"100%", border:"none", borderBottom:"1px solid #804D00", outline:"none", height:"28px",backgroundColor:"#F2F2F2"}} disabled/>
            </div>    
          </div>

                  
          <div style={{display:"flex", paddingLeft:"50px", paddingRight:"30px" }}>
            <div style={{flex:"30%"}}>
              Pasien Code
            </div>
            <div style={{ flex: "70%", display:"flex"}}>
              <p style={{marginRight:"10px"}}>:</p>
              <input autoFofcus type='text' value={getData?.code}  style={{width:"100%", border:"none", borderBottom:"1px solid #804D00", outline:"none", height:"28px",backgroundColor:"#F2F2F2"}} disabled/>
            </div>    
          </div>
        </div>
                
        <div style={{flex:"50%"}}>
          <div style={{display:"flex", paddingLeft:"30px", paddingRight:"50px" }}>
            <div style={{flex:"30%"}}>
              Jenis Kelamin
            </div>
            <div style={{ flex: "70%", display:"flex"}}>
              <p style={{marginRight:"10px"}}>:</p>
              <input autoFofcus type='text' value={getData?.gender} style={{width:"100%", border:"none", borderBottom:"1px solid #804D00", outline:"none", height:"28px",backgroundColor:"#F2F2F2"}} disabled/>
            </div>    
          </div>

          <div style={{display:"flex", paddingLeft:"30px", paddingRight:"50px" }}>
            <div style={{flex:"30%"}}>
              Tgl Lahir
            </div>
            <div style={{ flex: "70%", display:"flex"}}>
              <p style={{marginRight:"10px"}}>:</p>
              <input autoFofcus type='text'
                value={`${getData.date_birth}`.split('-')[2] + (` `) + (
                `${getData.date_birth}`.split('-')[1] === "01" ? "Januari" : "" ||
                `${getData.date_birth}`.split('-')[1] === "02" ? "Februari" : "" ||
                `${getData.date_birth}`.split('-')[1] === "03" ? "Maret" : "" ||
                `${getData.date_birth}`.split('-')[1] === "04" ? "April" : "" ||  
                `${getData.date_birth}`.split('-')[1] === "05" ? "Mei" : "" ||
                `${getData.date_birth}`.split('-')[1] === "06" ? "Juni" : "" ||
                `${getData.date_birth}`.split('-')[1] === "07" ? "Juli" : "" ||
                `${getData.date_birth}`.split('-')[1] === "08" ? "Agustus" : "" ||
                `${getData.date_birth}`.split('-')[1] === "09" ? "September" : "" ||
                `${getData.date_birth}`.split('-')[1] === "10" ? "Oktober" : "" ||
                `${getData.date_birth}`.split('-')[1] === "11" ? "November" : "" ||
                `${getData.date_birth}`.split('-')[1] === "12" ? "Desember" : "") + (` `) +
                `${getData.date_birth}`.split('-')[0]
                }
                style={{ width: "100%", border: "none", borderBottom: "1px solid #804D00", outline: "none", height: "28px", backgroundColor: "#F2F2F2" }} disabled />
            </div>    
          </div>      
      </div>
    </div>

    <div style={{ backgroundColor: "white", opacity: ".9", borderBottom:"5px solid #804D00"}}>
    <p style={{  opacity: ".9",fontSize: "20px", fontWeight: "700", color: "#804D00", backgroundColor:"white", padding:"10px 10px 0px 10px"}}>Detail Perawatan Gigi</p>
      <div style={{ padding: "10px 30px" }}>
                  
        <div className="mt-4" style={{ display: "flex" }}>
          <div style={{flex:"5%"}}>
            <p>Anamnesa</p>
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px" }}>:</p>
            <div style={{width:"100%"}}>
              <input type="text" value={anamnesa} name="anamnesa" onChange={handleChange}  style={{width:"100%", border:"none", borderBottom:"1px solid #804D00", outline:"none", height:"20px"}}/>
              <div style={{fontSize:"8px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>keluhan pasien</div>
            </div>
          
            </div>
        </div>
                  
        <div className="mt-3" style={{ display: "flex"}}>
         <div style={{flex:"5%",display:"flex", alignItems:"center"}}>
           Pilih Dokter
         </div>
         <div style={{ flex: "60%", display:"flex"}}>
           <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
           <select className="form-select" aria-label="Default select example" onChange={handleChange}  name="id_dokter" style={{ textAlign:"", cursor:"pointer"}}>
             <option value="" hidden>Select Dokter</option>
             {getDataDokter.map((user,index) => (
               <option value={user?.id} style={{textAlign:""}}>{user?.firstname} {user?.lastname}</option>
              ))}         
           </select>
         </div>
      </div>
                  
                   

          
        <div className="mt-4" style={{ display: "flex" }}>
          <div style={{flex:"5%"}}>
            <p>Generate Code</p>
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px" }}>:</p>
            <div style={{width:"60%", marginRight:"10px"}}>
              <input autoFofcus type='text' value={getCode?.number} name="generate_code" onChange={handleChange} type="text" style={{width:"100%", border:"none", borderBottom:"1px solid #804D00", outline:"none", height:"20px"}}/>
              <div style={{fontSize:"8px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Tekan tombol disamping untuk mendapatkan code appoinment</div>
            </div>
            <div onClick={generateCode} style={{height:"70%", marginRight:"5px", paddingTop:"0px",backgroundColor:"white",padding:"0px 10px", boxShadow:"1px 1px 3px #8B8B8B", borderRadius:"3px", cursor:"pointer"}}>
              <FaSync style={{ fontSize: "15px", marginRight: "0px", marginTop: "0px", display: "flex", alignItems: "center", height:"100%", color:"#3D64FF" }} />
            </div>
            </div>
        </div>
        
        <div className="mt-4" style={{ display: "flex"}}>
          <div style={{flex:"5%"}}>
            <p>Tanggal Dan Waktu Appoinment</p>
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{marginRight:"5px", height:"100%"}}>:</p>
                      <input type="date" name="date_appoinment" onChange={handleChange} style={{border:"none", borderBottom:"1px solid #804D00", outline:"none",display:"flex", alignItems:"end", height:"20px", textAlign:"center", marginRight:"10px", width:"32%" }}/>
       
            <select onChange={handleChange}  name="hours_appoinment" style={{ display:"flex", alignItems:"end",textAlign:"center", cursor:"pointer", height:"20px",border:"none", borderBottom:"1px solid #804D00", outline:"none", borderRadius:"none",marginRight:"10px",appearance:"none", width:"15%"}}>
              <option value="" hidden>Jam</option>
              {/* <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option> */}
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              {/* <option value="22">22</option>
              <option value="23">23</option>   */}
            </select> 
                      
            <p style={{height:"100%", marginRight:"10px"}}>:</p>
                      
            <select onChange={handleChange}  name="minute_appoinment" style={{display:"flex", alignItems:"end", textAlign:"center", cursor:"pointer",height:"20px",border:"none", borderBottom:"1px solid #804D00", outline:"none", borderRadius:"none",appearance:"none",width:"15%"}}>
              <option value="" hidden>Menit</option>
              <option value="00">00</option>
              {/* <option value="10">10</option>
              <option value="20">20</option> */}
              <option value="30">30</option>
              {/* <option value="40">40</option>
              <option value="50">50</option>         */}
            </select>
                      
          </div>
        </div>
                  
        {/* <div className="mt-3" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex", alignItems:""}}>
            Jenis Perawatan Gigi
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
          <p style={{ marginRight: "10px", display: "flex", alignItems: "", height: "100%" }}>:</p>
        <div>
          {getDataTreatment.map((user,index) => (
            <div key={index} className="mb-3" style={{display:"flex", width:"100%", cursor:"pointer"}}>
              <input autoFofcus type='checkbox' value={user?.id} name="id_treatment" onChange={handleChangeCheckbox} style={{display:"flex", alignItems:"center",marginRight:"5px", border: "none", borderBottom: "1px solid #804D00", cursor:"pointer" }} />
              <div style={{display:"flex", alignItems:"center"}}>
                {user?.name}
              </div>
            </div>
          ))}
          </div>
        </div>
      </div> */}
      </div>
    </div>

    <div style={{ backgroundColor: "white", opacity: ".9", borderBottom: "5px solid #804D00"}}>
    <p style={{ opacity: ".9",fontSize: "20px", fontWeight: "700", color: "#804D00", backgroundColor:"white", padding:"10px 10px" }}>Form Consent</p>
      <div style={{ padding: "10px 30px" }}>
            
        <div className="mt-1" style={{ display: "flex"}}>
          <div style={{flex:"5%"}}>
            <p>Apakah Anda Memiliki Alergi?</p>
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{marginRight:"10px"}}>:</p>
            <input autoFofcus type='text' value={question_allergy} name="question_allergy" onChange={handleChange} style={{width:"100%", border:"none", borderBottom:"1px solid #804D00", outline:"none", height:"20px"}}/>
          </div>
        </div>
                  
        {/* <div className="mt-3" style={{ display: "flex"}}>
          <div style={{flex:"5%",display:"flex", alignItems:"center"}}>
            Golongan Darah
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <select className="form-select" aria-label="Default select example" onChange={handleChange}  name="font_size_title" style={{ textAlign:"", cursor:"pointer"}}>
              <option value="" hidden>Select</option>
              <option value="A" style={{ textAlign: "" }}>A</option>
              <option value="A+" style={{textAlign:""}}>A+</option>
              <option value="A-" style={{textAlign:""}}>A-</option>
              <option value="B" style={{textAlign:""}}>B</option>
              <option value="B+" style={{textAlign:""}}>B+</option>
              <option value="B-" style={{textAlign:""}}>B-</option>
              <option value="AB" style={{textAlign:""}}>AB</option>
              <option value="AB+" style={{textAlign:""}}>AB+</option>
              <option value="AB-" style={{textAlign:""}}>AB-</option>
              <option value="O" style={{textAlign:""}}>O</option>
              <option value="O+" style={{textAlign:""}}>O+</option>
              <option value="O-" style={{ textAlign: "" }}>O-</option>
              <option value="" style={{textAlign:""}}>Other</option>           
            </select>
          </div>
        </div> */}
                  
        <div className="mt-4" style={{ display: "flex"}}>
          <div style={{flex:"5%"}}>
            <p>Apakah saat ini anda sedang mengkonsumsi obat?</p>
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{marginRight:"10px"}}>:</p>
            <input autoFofcus type='text' value={question_medicine} name="question_medicine" onChange={handleChange}  style={{width:"100%", border:"none", borderBottom:"1px solid #804D00", outline:"none", height:"20px"}}/>
          </div>
        </div>
                  
        <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%"}}>
            <p>Apakah anda memiliki kondisi medis yang harus kami ketahui? (Jantung, diabetes, dan lain-lain)</p>
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{marginRight:"10px"}}>:</p>
            <input autoFofcus type='text' value={question_condition} name="question_condition" onChange={handleChange}  style={{width:"100%", border:"none", borderBottom:"1px solid #804D00", outline:"none", height:"20px"}}/>
          </div>
        </div>
                  
          {getDataAgreement.map((user,index) => (
            <div className="mt-4" style={{ display: "flex" }}>
              <div style={{ display: "flex", marginRight:"10px", paddingTop:"1px" }}>
                <div>
                  <input  type='checkbox' value={user?.id} name="agreement" onChange={handleChangeCheckboxAgreement} style={{cursor:"pointer"}} />
                </div>
              </div>
              <div className="" style={{ display: "flex",  width: "100%", cursor: "pointer" }}>
                <div style={{display:"flex", alignItems:""}}>
                  {user?.name}
                </div>
              </div>
            </div>
            ))}    
                  
               
        {/* <div className="mt-4" style={{ display: "flex" }}>
          <div style={{flex:"5%"}}>
            <p>Obat Yang Sedang Dikonsumsi</p>
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{marginRight:"10px"}}>:</p>
            <input style={{width:"100%", border:"none", borderBottom:"1px solid #804D00", outline:"none", height:"20px"}}/>
          </div>
        </div>
        <div className="mt-1" style={{ display: "flex" }}>
          <div style={{flex:"5%"}}>
            <p>Pernah Operasi</p>
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{marginRight:"10px"}}>:</p>
            <input style={{width:"100%", border:"none", borderBottom:"1px solid #804D00", outline:"none", height:"20px"}}/>
          </div>
        </div>
        <div className="mt-4" style={{ display: "flex" }}>
          <div style={{flex:"5%"}}>
            <p>Perhatian Khusus</p>
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{marginRight:"10px"}}>:</p>
            <input style={{width:"100%", border:"none", borderBottom:"1px solid #804D00", outline:"none", height:"20px"}}/>
          </div>
                  </div> */}
                   <div style={{padding: "10px 0px"}}>
        <div className="" style={{ display: "flex", marginTop:"50px"  }}>
        <div style={{ flex: "50%"}}>
          <div style={{ display:"flex", justifyContent:"center"}}>
                <p>PETUGAS</p>
              </div>
              <div style={{ display: "flex", justifyContent: "center", margin:"10px 0px" }}>
                <div style={{ display:"flex", marginLeft:"25px", borderRight:"1px solid #D8D8D8", borderLeft:"1px solid #D8D8D8"}}>
                  <SignatureCanvas penColor='black' canvasProps={{ width: 180, height: 150, className: 'sigCanvas' }} />
                </div>
                <div style={{paddingLeft:"10px", display:"flex"}}>
                  <FaUndoAlt  style={{cursor:"pointer"}}/>
                </div>
              </div>
              <div style={{ display:"flex", justifyContent:"center"}}>
                <p>{`( Tanda Tangan Digital )`}</p>
              </div>
          </div>
          <div style={{ flex: "50%"}}>
          <div style={{ display:"flex", justifyContent:"center"}}>
                <p>PASIEN / WALI PASIEN</p>
              </div>
              <div style={{ display: "flex", justifyContent: "center", margin:"10px 0px" }}>
                <div style={{ display:"flex", marginLeft:"25px", borderRight:"1px solid #D8D8D8", borderLeft:"1px solid #D8D8D8"}}>
                  <SignatureCanvas
                    canvasProps={{ width: 180, height: 150, className: 'sigCanvas' }}
                    ref={ref => (canvasRef = ref)}
                    onEnd={e => {
                      // console.log(canvasRef.isEmpty());
                      // setBase64(canvasRef.toDataURL().split(',')[1]);

                      setBase64(canvasRef.toDataURL());
                    }}
                />
                          </div>
                <div style={{paddingLeft:"10px", display:"flex"}}>
                  <FaUndoAlt  style={{cursor:"pointer"}}/>
                </div>
              </div>
              <div style={{ display:"flex", justifyContent:"center"}}>
                <p>{`( Tanda Tangan Digital )`}</p>
              </div>
          </div>
        </div>
      </div>
                  
          <div style={{display:"flex",justifyContent:"end", padding: "0px 0px", marginTop: "40px", marginBottom: "50px"}}>
          <div style={{width:"200px"}}>
            <Button onClick={postUser} type='submit' color='primary' block style={{ fontSize:"15px", borderRadius:"3px", height:"45px"}}>
              Buat Appointment
            </Button>
          </div>
        </div>
              
      </div>
    </div>
   
    {/* <div style={{ backgroundColor: "white", opacity: ".9", height:"430px", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px"}}>
      <div style={{padding: "10px 0px"}}>
        <div className="" style={{ display: "flex", marginTop:"50px"  }}>
        <div style={{ flex: "50%"}}>
          <div style={{ display:"flex", justifyContent:"center"}}>
                <p>PETUGAS</p>
              </div>
              <div style={{ display: "flex", justifyContent: "center", margin:"10px 0px" }}>
                <div style={{ display:"flex", marginLeft:"25px", borderRight:"1px solid #D8D8D8", borderLeft:"1px solid #D8D8D8"}}>
                  <SignatureCanvas penColor='black' canvasProps={{ width: 180, height: 150, className: 'sigCanvas' }} />
                </div>
                <div style={{paddingLeft:"10px", display:"flex"}}>
                  <FaUndoAlt  style={{cursor:"pointer"}}/>
                </div>
              </div>
              <div style={{ display:"flex", justifyContent:"center"}}>
                <p>{`( Tanda Tangan Digital )`}</p>
              </div>
          </div>
          <div style={{ flex: "50%"}}>
          <div style={{ display:"flex", justifyContent:"center"}}>
                <p>PASIEN / WALI PASIEN</p>
              </div>
              <div style={{ display: "flex", justifyContent: "center", margin:"10px 0px" }}>
                <div style={{ display:"flex", marginLeft:"25px", borderRight:"1px solid #D8D8D8", borderLeft:"1px solid #D8D8D8"}}>
                  <SignatureCanvas penColor='black' canvasProps={{ width: 180, height: 150, className: 'sigCanvas' }} />
                </div>
                <div style={{paddingLeft:"10px", display:"flex"}}>
                  <FaUndoAlt  style={{cursor:"pointer"}}/>
                </div>
              </div>
              <div style={{ display:"flex", justifyContent:"center"}}>
                <p>{`( Tanda Tangan Digital )`}</p>
              </div>
          </div>
        </div>
      </div>
        
       
    </div> */}
    </form>
  </Col>
        </Row>
      }
    </div>
  );
}
