import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaTimes} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../../assets/signature/signature logo.png"
import Logo_Batik from "../../../assets/signature/logo-batik.png"
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
import "../Styled.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
import LoaderHome from "../../Loader/LoaderHome"

export default function ModalRoleUpdate(props) {
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const token = localStorage.getItem("token");
  const [propsData, setProopsData] = useState()
  const [idRoleDokter, setIdRoleDokter] = useState("");
  const [nameRoleDoctor, setNameRoleDoktor] = useState("Doctor");
  const [getDataDokter, setGetDataDokter] = useState([]);
  const [getDataRoom, setGetDataRoom] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getDataPayment, setGetDataPayment] = useState([]);
  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const [form, setForm] = useState({
    // nameTreatment: "",
    qty: "",
    estimate_price: "",
    discount: "", 
    price_after_discount: "",
    discount_persentase: 0
  });

  useEffect(() => {
    setForm({
      ...form, 
      // nameTreatment: `${props.nameTreatment}`,
      qty: `${props.qtyModal}`,
      estimate_price: parseInt(`${props.estimate_priceModal}`),
      discount: parseInt(`${props.priceDiscount}`),
      price_after_discount: parseInt(`${props.priceAfterDiscount}`)
    });  
  }, [props]);

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    setForm({
      ...form,
      [name]: name === 'discount_persentase' ? parseInt(value) : value,
    });
  };
  
  const handleSubmit = useMutation(async (e) => {
    try {
      e.preventDefault();
      setLoading(true)
      // Insert data for login process
      const response = await API.put(`emr-service/medical-invoices/${props.idModal}`, {
        qty: form.qty,
        price: form.estimate_price,
        discount: form?.discount === 0 || form?.discount === "0" ? null : form?.discount,
        price_after_discount: (form?.estimate_price - form?.discount)
      }, fetchParams);
  
      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 3000,
          buttons: false
        });
        props.fetchData()
        props.onHide()
        setLoading(false)        
      }
    } catch (error) {
      // setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }

  });

  const [isChecked, setIsChecked] = useState(false); 
  const toggleSwitch = () => {
    setIsChecked(prevState => !prevState);
  };

  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered style={{ fontFamily: "sans-serif", border: "none" }}>
      {loading && <LoaderHome />}
      
      <div style={{width: "100%",display:"flex",padding:"10px 0px", backgroundColor:"#29B8FF"}}>
        <div style={{flex:"92%", fontSize:"20px",display:"flex",alignItems:"center", paddingLeft:"10px", color:"white", fontWeight:"600"}}>
          Tambah Discount
        </div> 
        <div  style={{flex:"8%",fontSize:"30px",display:"flex",alignItems:"center",justifyContent:"center", color:"white"}}>
          <FaTimes onClick={() => setProopsData(props.onHide)} style={{cursor:"pointer"}}/>
        </div> 
      </div>
      <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px",border:"none" }}>
      <Form onSubmit={(e) => handleSubmit.mutate(e)} className="mt-3" >

      <div style={{display:"flex", justifyContent:"start"}}> 
        <div className="mr-1" style={{display:"flex", alignItems:"center", fontSize:"15px", fontWeight:"bold", fontStyle:"revert"}}>
          Rupiah
        </div>
        <div style={{display:"flex", alignItems:"center"}}>
          <label className="toggle-switch">
            <input type="checkbox" checked={isChecked} onChange={toggleSwitch} />
            <span className="switch"></span>
          </label>
        </div>
      </div>

      {isChecked === false ? 
      <div>
        <div className="mt-3" style={{ display: "flex"}}>
        <div style={{flex:"20%", display:"flex",alignItems:"center"}}>
          Persentase
        </div>
        <div style={{ flex: "80%", display:"flex"}}>
          <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
          <select className="form-select" aria-label="Default select example" onChange={handleChange} name="discount" style={{ textAlign:"", cursor:"pointer"}}>
              <option value="" hidden>Select</option>
              <option value={form?.estimate_price*0.01}>1%</option>
              <option value={form?.estimate_price*0.02}>2%</option>
              <option value={form?.estimate_price*0.03}>3%</option>
              <option value={form?.estimate_price*0.04}>4%</option>
              <option value={form?.estimate_price*0.05}>5%</option>
              <option value={form?.estimate_price*0.06}>6%</option>
              <option value={form?.estimate_price*0.07}>7%</option>
              <option value={form?.estimate_price*0.08}>8%</option>
              <option value={form?.estimate_price*0.09}>9%</option>
              <option value={form?.estimate_price*0.10}>10%</option>
              <option value={form?.estimate_price*0.11}>11%</option>
              <option value={form?.estimate_price*0.12}>12%</option>
              <option value={form?.estimate_price*0.13}>13%</option>
              <option value={form?.estimate_price*0.14}>14%</option>
              <option value={form?.estimate_price*0.15}>15%</option>
              <option value={form?.estimate_price*0.16}>16%</option>
              <option value={form?.estimate_price*0.17}>17%</option>
              <option value={form?.estimate_price*0.18}>18%</option>
              <option value={form?.estimate_price*0.19}>19%</option>
              <option value={form?.estimate_price*0.20}>20%</option>
              <option value={form?.estimate_price}>Free</option>
          </select>
        </div>
        </div>
      </div>
      :
      <div>
        <div className="mt-2" style={{ display: "flex"}}>
        <div style={{flex:"20%", display:"flex",alignItems:"center"}}>
          Rupiah
        </div>
        <div style={{ flex: "80%", display:"flex"}}>
          <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
          <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
            <input autoFofcus type='number' min={form?.estimate_price*0.01} max={form?.estimate_price*0.20}  value={form?.discount} name="discount" onChange={handleChange} style={{backgroundColor:"white",width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
          </div>
        </div>
      </div>
        {form?.discount > form?.estimate_price*0.20 && 
          <div className="mt-2" style={{display:"flex"}}>
            <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center", color:"red"}}>
              Discount Melebihi Batas!
            </p>
          </div>
        }
        {form?.discount === null ? 
        ""
        :
        form?.discount < form?.estimate_price*0.01 ?
          <div className="mt-2" style={{display:"flex"}}>
            <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center", color:"red"}}>
              Discount Kurang Dari Batas!
            </p>
          </div>
        :
        ""
        }
        
      </div>
      }

      <div style={{ padding: "0px 0px", marginTop: "0px", display:"flex", justifyContent:"end" }}>
        <div>
          <Button className="mt-2" type='submit' color='primary' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px"}}>
            Simpan
          </Button>
        </div>
      </div>

      {/* {form?.discount > form?.estimate_price*0.20 ? 
      <div style={{ padding: "0px 0px", marginTop: "0px", display:"flex", justifyContent:"end" }}>
        <div>
          <Button className="mt-2" disabled color='secondary' style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px"}}>
            Simpan
          </Button>
        </div>
      </div>
      :
      form?.discount < form?.estimate_price*0.01 ? 
      <div style={{ padding: "0px 0px", marginTop: "0px", display:"flex", justifyContent:"end" }}>
        <div>
          <Button className="mt-2" disabled color='secondary' style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px"}}>
            Simpan
          </Button>
        </div>
      </div>
      :
      <div style={{ padding: "0px 0px", marginTop: "0px", display:"flex", justifyContent:"end" }}>
        <div>
          <Button className="mt-2" type='submit' color='primary' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px"}}>
            Simpan
          </Button>
        </div>
      </div>
      } */}
     
    </Form>
    </Modal.Body>
  </Modal>
  );
}
