import React from 'react';
import './Login.css'; // Anda perlu membuat file CSS terpisah untuk styling dropdown.
import { FaCalendarCheck, FaPhone, FaTimes, FaWhatsapp } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import swal from "sweetalert";
import { useMediaQuery } from 'react-responsive'
import Logo_Signature from "../../assets/signature/signature logo.png"
import Logo_Batik from "../../assets/signature/logo-batik.png"
import BackgroundBatik from "../../assets/signature/background-appoinment.jpg"
import SignatureCanvas from 'react-signature-canvas'
import { FaHandHoldingMedical, FaReply, FaSearch, FaSync, FaUndoAlt } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import { Alert } from "react-bootstrap";
import { useMutation } from "react-query";
import { API } from "../../config/api";
import { useParams } from 'react-router-dom';
import LoaderHome from "../Loader/LoaderHome"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment-timezone';
import { setDefaultTimeZone } from "date-fns-tz";
import "./Add.css"
import { ScaleLoader } from "react-spinners";
import {  Dropdown } from "react-bootstrap";
import { Link } from 'react-router-dom';
import masgan from "../../assets/masgan.png";
import { FaUserAlt, FaSignOutAlt, FaList, FaPhoneAlt } from "react-icons/fa";
// ** Third Party Components
import 'chart.js/auto'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FaAccessibleIcon, FaAdjust, FaMailBulk, FaRssSquare, FaStackExchange, FaStackpath, FaStarOfLife, FaTransgender, FaUser } from 'react-icons/fa';

export default function Dropdow(proops) {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCall, setIsOpenCall] = useState(false);
  const navigate = useNavigate()

  const toggleDropdown = () => {
    setIsOpenCall(false);
    setIsOpen(true);
  };

  const toggleDropdownCall = () => {
    setIsOpen(false);
    setIsOpenCall(true);
  };

  const closeBotton = () => {
    setIsOpen(false);
    setIsOpenCall(false)
  };

  
  const toRegister = () => {
    navigate("/register-pasien-online")
  }

  const toLogin = () => {
    navigate("/")
  }

  const menujuWhatsApp = () => {
    // Ganti nomor WhatsApp di bawah ini dengan nomor yang Anda inginkan
    const nomorWhatsApp = '+6281284759191';

    // Membuat URL WhatsApp dengan nomor yang telah diberikan
    const urlWhatsApp = `wa.me/${nomorWhatsApp}`;

    // Buka URL WhatsApp pada tab atau jendela baru
    window.open(urlWhatsApp, '_blank');
  }

  document.title = "SDC Apps";
  const [state, dispatch] = useContext(UserContext);
  const [message, setMessage] = useState(null);
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)'})
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)'})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)'})
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const token = localStorage.getItem("token");
  const [idDelete, setIdDelete] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { code, id } = useParams();
  const [getData, setGetData] = useState([]);
  const [getDataTreatment, setGetDataTreatment] = useState([]);
  const [getDataAgreement, setGetDataAgreement] = useState([]);
  const [getCode, setGetCode] = useState([]);
  const { getCode2, setGetCode2 } = useState("");
  const [imageName, setImageName] = useState([])
  const [imageContent, setImageContent] = useState([])
  const [preview, setPreview] = useState(null);
  const [idRoleDokter, setIdRoleDokter] = useState("");
  const [nameRoleDoctor, setNameRoleDoktor] = useState("Doctor");
  const [getDataDokter, setGetDataDokter] = useState([]);
  const [getDataPasien, setGetDataPasien] = useState([]);

  const [getDataDokterschedule, setGetDataDokterSchedule] = useState([]);
  const [tanggalTerpilih, setTanggalTerpilih] = useState(""); // State untuk menyimpan tanggal yang dipilih
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(1000);
  const [ascending, setAscending] = useState(0);
  // console.log(canvasRef)
  let [cBase64, setBase64] = useState();
  let [cBase64name, setBase64Name] = useState();
  let canvasRef;
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const storageItems = JSON.parse(localStorage.getItem('user_data'));

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const [startDate, setStartDate] = useState(null); 
  
  const inputDate = new Date(startDate);
  
  // Dapatkan tahun (4 digit)
  const year = inputDate.getFullYear().toString();
  
  // Dapatkan bulan (01-12)
  const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
  
  // Dapatkan hari (01-31)
  const day = inputDate.getDate().toString().padStart(2, '0');
  
  // Format tanggal dalam format "yyyy/mm/dd"
  const formattedDate = `${year}/${month}/${day}`;

  const [allowedDates, setAllowedDates] = useState([]); // State untuk menyimpan tanggal-tanggal yang diperbolehkan
  const [allowedTimePeriods, setAllowedTimePeriods] = useState([]);


  // Untuk mendapatkan starttimenya
  const [startDateTime, setStartDateTime] = useState(null); 

  const [form, setForm] = useState({
    // store appoinment
    anamnesa: "",
    generate_code: `${getCode2}`,
    date_dokter_scedule: "",
    hours_appoinment: "",
    minute_appoinment: "",
    id_dokter: "",
    id_patient: `${storageItems?.id}`
  });

  const initialValues = {
    // store appoinment
    anamnesa: "",
    generate_code: `${getCode2}`,
    date_dokter_scedule: "",
    hours_appoinment: "",
    minute_appoinment: "",
    id_dokter: "",
    id_patient: `${storageItems?.id}`
  };

  const {
      // store appoinment
      anamnesa,
      id_patient,
      generate_code,
      date_appoinment,
      hours_appoinment,
      minute_appoinment,
      id_dokter,
      id_treatment,
  } = form;

  const GetResponseDatapasien = async () => {
    try {
      // e.preventDefault();
      // setLoading(true)
      const response = await API.get(`transaction-service/patient-relations/${storageItems?.id}?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&id_role=${idRoleDokter}&name_role=${nameRoleDoctor}`,fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataPasien(response.data.data)
        // setLoading(false)
      }
    } catch (error) {
      // setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseData = async () => {
    try {
      // e.preventDefault();
      // setLoading(true)
      const response = await API.get(`user-service/patient-users?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&id_role=${idRoleDokter}&name_role=${nameRoleDoctor}`,fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetDataDokter(response.data.data)
        // setLoading(false)
      }
    } catch (error) {
      // setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataScedules = async () => {
    try {
      // e.preventDefault();
      // setLoading(true)
      const response = await API.get(`user-service/doctor-schedules/${form?.id_dokter}?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&id_role=${idRoleDokter}&name_role=${nameRoleDoctor}`,fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetDataDokterSchedule(response.data.data)
        // setLoading(false)
      }
    } catch (error) {
      // setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  useEffect(() => {
    if (form?.id_dokter) {
      GetResponseDataScedules()
      generateCode()
    }
  }, [form?.id_dokter])
  

  // useEffect(() => {
  //   GetResponseData()
  //   GetResponseDatapasien()
  // }, [])

  const handleChange = async (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.type === "file" ? e.target.files : e.target.type === "date" ? e.target.value : e.target.value,
    });

    // Create image url for preview
    if (e.target.type === "file") {
      const file = e.target.files[0]
      // console.log(file)
      const base64 = await convertBase64(file)
      setPreview(base64)
      setImageName(e.target.files[0].name)
      setImageContent(base64.split(',')[1])
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const postUser = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
    // Data body
    // const body = JSON.stringify(form);
    const responseAppoinment = await API.post(`transaction-service/patient-appointments/store`,
    {
      id_patient: `${form?.id_patient}`,
      id_doctor: `${form?.id_dokter}`,
      code: `${getCode?.number}`,
      date: formattedDate,
      time: startDateTime,
      anamnesa: `${form?.anamnesa}`,
    },fetchParams)

    if (responseAppoinment?.status === 200) {
      swal({
        title: 'Success',
        text: "Berhasil Membuat Appoinment Pasien",
        icon: 'success',
        timer: 3000,
        buttons: false
      });
      setForm(initialValues)
      setGetDataDokterSchedule([])
      setGetCode([])
      setStartDate(null)
      navigate("/dashboard-pasien-online")
      setIsOffcanvasOpen(false); // Menutup Offcanvas
      proops.GetResponseData()
    }
    setLoading(false)
  } catch (error) {
    swal({
      title: 'Failed',
      text: `${error.response.data.message}`,
      icon: 'error',
      buttons: false
    });
    setLoading(false)
  }
  }

  const generateCode = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.post(`user-service/patient-master-codes/generate`,
        {
          type: "appointment",
          object_code : storageItems?.code,
          id_patient : storageItems?.id
        },
      fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetCode(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

 
// console.log(allowedTimePeriods)
  
useEffect(() => {
  // Ambil semua tanggal dari getDataDokterSchedule
  const allDates = getDataDokterschedule.reduce((dates, item) => {
    dates.push(item.ci_date, item.co_date);
    return dates;
  }, []);

  // Menghilangkan tanggal yang duplikat
  const uniqueDates = [...new Set(allDates)];

  // Format tanggal ke struktur data yang sesuai dengan komponen DatePicker
  const formattedDates = uniqueDates.map((date) => {
    return {
      value: date,
      label: date,
    };
  });

  // Set state dengan tanggal yang diizinkan
  setAllowedDates(formattedDates);
  }, [getDataDokterschedule]);

  useEffect(() => {
    if (startDate) {
      const selectedDate = new Date(startDate);
      selectedDate.setDate(selectedDate.getDate() + 1); // Mengurangkan satu hari
  
      const formattedDate = selectedDate.toISOString().slice(0, 10); // Format kembali menjadi string
  
      const timePeriodsForSelectedDate = getDataDokterschedule
        .filter((item) => item.ci_date === formattedDate)
        .map((item) => item.time_period)
        .flat();
  
      // Set state dengan time_period yang diizinkan
      setAllowedTimePeriods(timePeriodsForSelectedDate);
    }
  }, [getDataDokterschedule, startDate]);

  const navigateHome = () => {
    navigate("/dashboard-pasien-online")
  }

  return (
  <Col xl='12' sm='12' style={{ backgroundColor:"#0071A2", display:"flex",width:"100%" , height:"12.5vh"}}>
    <div style={{flex:"50%", display:"flex", justifyContent:"center", alignItems:"center", borderRight:"1px solid #C1C1C1"}}>
     {[false].map((expand) => (
        <Navbar key={expand} expand={expand}  style={{backgroundColor:"#0071A2"}} >
            <Navbar.Toggle  onFocus={(e) => e.target.blur()}  aria-controls={`offcanvasNavbar-expand-${expand}`} style={{display:"", justifyContent: "center", border:"1px solid #0071A2"}}>
              <div style={{display:"flex", justifyContent:"center", fontSize:"20px", color:"white"}}>
                <FaCalendarCheck/>
              </div>
              <div className='mt-2' style={{display:"flex", justifyContent:"center", fontSize:"10px", marginTop:"0px", color:"white"}}>
                Buat Appointment
              </div>
            </Navbar.Toggle>
            <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="start"
            style={{backgroundColor:""}}
            >
            <Offcanvas.Header closeButton style={{backgroundColor:"#0071E2", color:"white"}}>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} style={{color:"white"}}>
                Apps
              </Offcanvas.Title>
            </Offcanvas.Header>
            <>
              <div style={{  backgroundColor:"white", padding:"10px 10px " }}> 
              <form onSubmit={(e) =>postUser}>
              <div style={{paddingTop:"20px",paddingBottom:"20px", backgroundColor: "white",borderTopLeftRadius:"5px", borderTopRightRadius:"5px",borderBottom:"5px solid #1B86FF",}}>
                <div style={{fontSize:"30px",display: "flex",justifyContent:"center", alignItems: "center", fontWeight: "600", color:"#1B86FF" }}>
                    Buat Appoinment
                </div>  
              </div>
          
              <div style={{ backgroundColor: "white"}}>
                <div style={{ padding: "0px 10px" }}>
                <div className="mt-3" style={{ display: "flex" }}>
                  <div style={{ width: "100%" }}>
                    <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Keluhan Pasien</div>
                        <input type="text" placeholder='Masukan Keluhan Anda' value={anamnesa} name="anamnesa" onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"none", height:"35px", borderRadius:"5px", paddingLeft:"5px"}}/>
                      {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
                    </div>
                  </div>
                  
           
                            

                  <div className="mt-3" style={{ display: ""}}>
                    <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Pilih Pasien</div>
                    <select className="form-select" aria-label="Default select example" onMouseEnter={GetResponseDatapasien} onChange={handleChange}  name="id_patient" style={{ textAlign:"", cursor:"pointer"}}>
                      <option value={storageItems?.id}>{storageItems?.first_name} {storageItems?.last_name}</option>
                        {getDataPasien.map((user,index) => (
                          <option value={user?.id} style={{textAlign:""}}>{user?.first_name} {user?.last_name}</option>
                      ))}         
                    </select>          
                </div>
                            
                  <div className="mt-3" style={{ display: ""}}>
                    <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Pilih Dokter</div>

                    <select className="form-select" aria-label="Default select example" value={form?.id_dokter} onMouseEnter={GetResponseData} onChange={handleChange}  name="id_dokter" style={{ textAlign:"", cursor:"pointer"}}>
                      <option value="" hidden>Pilih Dokter</option>
                      {getDataDokter.map((user,index) => (
                        <option value={user?.id} style={{textAlign:""}}>{user?.firstname} {user?.lastname}</option>
                        ))}         
                    </select>
                  
                              </div>
                              
                              {/* <div className="mt-4" style={{ display: "flex"}}> 
                <div style={{flex:"50%", display: "flex" }}>
                  <DatePicker
                  className="datePickerDropdownBotton"
                  placeholderText="Tanggal"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  filterDate={(date) =>
                    allowedDates.some(
                      (allowedDate) =>
                        allowedDate.value === date.toISOString().slice(0, 10)
                    )
                  }
                  /> 
              </div> 

              <div style={{flex:"50%", display: "flex"}}>
                  <select
                    onChange={(e) => setStartDateTime(e.target.value)}
                    class="selectPickerMobileDropdownBotton"         
                    name="hours_appointment"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                      cursor: "pointer",
                      height:"40px",
                      // height: "10px",
                    borderRadius:"4px",
                      outline: "none",
                      border:"1px solid rgb(198, 198, 198)",
                      // borderRadius: "none",
                      marginRight: "0px",
                      appearance: "none",
                      width: "100%",
                      color:"#494949"
                    }}
                    // value={selectedTimePeriod}
                  >
                    <option value="" hidden >Waktu</option>
                    {allowedTimePeriods.map((timePeriod) => (
                      <option key={timePeriod} value={timePeriod}>
                        {timePeriod.split(":")[0]}:{timePeriod.split(":")[1]}
                      </option>
                    ))}
                  </select>                
                </div>                    
                            
                    </div> */}

              {form?.id_dokter && (
                <div className="mt-4" style={{ display: "flex"}}> 
                <div style={{flex:"50%", display: "flex" }}>
                  <DatePicker
                  className="datePickerDropdownBotton"
                  placeholderText="Tanggal"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  filterDate={(date) =>
                    allowedDates.some(
                      (allowedDate) => {
                        // Mengurangkan satu hari dari tanggal yang sedang diuji
                        const previousDay = new Date(date);
                        previousDay.setDate(date.getDate() + 1);
                  
                        return allowedDate.value === previousDay.toISOString().slice(0, 10);
                      }
                    )
                  }
                  /> 
              </div> 

              <div style={{flex:"50%", display: "flex"}}>
                  <select
                    onChange={(e) => setStartDateTime(e.target.value)}
                    class="selectPickerDropdownBotton"         
                    name="hours_appointment"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                      cursor: "pointer",
                      height:"40px",
                      // height: "10px",
                    borderRadius:"4px",
                      outline: "none",
                      border:"1px solid rgb(198, 198, 198)",
                      // borderRadius: "none",
                      marginRight: "0px",
                      appearance: "none",
                      width: "100%",
                      color:"#494949"
                    }}
                    // value={selectedTimePeriod}
                  >
                    <option value="" hidden >Waktu</option>
                    {allowedTimePeriods.map((timePeriod) => (
                      <option key={timePeriod} value={timePeriod}>
                        {timePeriod.split(":")[0]}:{timePeriod.split(":")[1]}
                      </option>
                    ))}
                  </select>                
                </div>                    
                            
                </div>
        
                  )}
                          
              </div>
            </div>
               </form>
               
      
            <div style={{display:"flex", justifyContent:"center",}}>
            <div style={{display:"flex", justifyContent:"center", marginRight:"10px"}}>
              <div onClick={postUser} className="mt-4" type='' color='' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px", width:"150px", display:"flex", justifyContent:"center", backgroundColor:"#005EEC", color:"white", cursor:"pointer"}}>
                <div>
                  Simpan
                </div>
              </div>
            </div>
            {/* 
            <div style={{display:"flex", justifyContent:"center"}}>
              <div onClick={toLogin} className="mt-4" type='' color='' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px", width:"100px", display:"flex", justifyContent:"center", backgroundColor:"white", color:"black", cursor:"pointer", border:"1px solid #9A9A9A"}}>
                <div>
                  Login
                </div>
              </div>
            </div> */}
                
            </div>
          </div>  
          {/* <div style={{ flex: "5%",  backgroundColor:"white", padding:"10px", }}>
            <div style={{display:"flex", justifyContent:"center", fontFamily:"sans-serif", fontWeight:"bold", fontSize:"20px", color:"#096CFF"}}>
              <div onClick={closeBotton} >
                <FaTimes style={{fontSize:"14px", color:"black", cursor:"pointer",}}/>
              </div>
            </div>    
        </div> */}
          </>
            </Navbar.Offcanvas>
     
        </Navbar>
        ))}
      </div>
      
     <div style={{flex:"50%", display:"flex", justifyContent:"center", alignItems:"center"}}>
      {[false].map((expand) => (
      <Navbar key={expand} expand={expand}  style={{backgroundColor:"#0071A2"}} >
           <Navbar.Toggle onFocus={(e) => e.target.blur()}  aria-controls={`offcanvasNavbar-expand-${expand}`} style={{  display: "", justifyContent: "center", border:"none"}}>
              <div style={{display:"flex", justifyContent:"center", fontSize:"20px", color:"white"}}>
                  <FaPhone/>
              </div>
              <div className='mt-2' style={{display:"flex", justifyContent:"center", fontSize:"10px", color:"white"}}>
                  Hubungi Kami
              </div>
           </Navbar.Toggle>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
              style={{backgroundColor:""}}
            >
              <Offcanvas.Header closeButton style={{backgroundColor:"#0071E2", color:"white"}}>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} style={{color:"white"}}>
                  Call Center
                </Offcanvas.Title>
              </Offcanvas.Header>      
              <>
            <div style={{backgroundColor:"white", padding:"10px 10px" }}>
            <div style={{display:"flex", justifyContent:"center", fontFamily:"sans-serif", fontWeight:"bold", fontSize:"20px", color:"#096CFF"}}>
              <div style={{marginRight:"0px"}}>
                HUBUNGI KAMI
              </div>
              </div>
              
              <div className='mt-4' style={{display:"flex", justifyContent:"center", fontFamily:"sans-serif", fontWeight:"bold", fontSize:"15px", color:"#A1A1A1"}}>
              <div style={{marginRight:"0px", display:"flex", justifyContent:"center", textAlign:"center"}}>
                Untuk informasi lebih lanjut dan menjawab segala pertanyaan anda, Bisa hubungi melalui Call Center atau Whatsapp kami berikut ini:
              </div>
            </div>

            <div className='mt-4' style={{display:"flex", justifyContent:"center", fontFamily:"sans-serif", fontWeight:"bold", fontSize:"20px", color:""}}>
              <div style={{marginRight:"0px", display:"flex", justifyContent:"center", textAlign:"center"}}>
                  0812-8475-9191
              </div>
            </div>
              
            <div style={{display:"flex", justifyContent:"center"}}>
              <div onClick={menujuWhatsApp} className="mt-4" type='' color='' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px", width:"100px", display:"flex", justifyContent:"center", backgroundColor:"#00F200", color:"white", cursor:"pointer"}}>
                <div style={{marginRight:"5px"}}>
                  <FaWhatsapp style={{fontSize:"15px"}} />
                </div>
                <div>
                  WhatsApp
                </div>
              </div>
            </div>
              
          </div>
        
          </>
          </Navbar.Offcanvas>
      </Navbar>
      ))}
    </div>
   </Col>
        // <div style={{display:"flex", border:"1px solid black"}}>
        //   <div onClick={toggleDropdown} style={{padding:"5px 5px", backgroundColor:"#1B86FF", color:"white" , borderBottom:"2px solid #ECECEC", width:"80px", cursor:"pointer", borderTopRightRadius:"5px"}}>
        //     <div style={{display:"flex", justifyContent:"center", fontSize:"30px"}}>
        //         <FaCalendarCheck />
        //     </div>
        //     <div style={{display:"flex", justifyContent:"center", fontSize:"10px", marginTop:"5px"}}>
        //         Buat 
        //     </div>
        //     <div style={{display:"flex", justifyContent:"center", fontSize:"10px"}}>
        //         Appointment
        //     </div>
        //   </div>
          
        //   <div  onClick={toggleDropdownCall}   style={{padding:"5px 5px", backgroundColor:"#1B86FF", color:"white" ,  width:"80px", cursor:"pointer", borderBottomRightRadius:"5px"}}>
        //     <div style={{display:"flex", justifyContent:"center", fontSize:"30px"}}>
        //         <FaPhone/>
        //     </div>
        //     <div style={{display:"flex", justifyContent:"center", fontSize:"10px", marginTop:"5px"}}>
        //         Hubungi  
        //     </div>
        //     <div style={{display:"flex", justifyContent:"center", fontSize:"10px"}}>
        //     Kami
        //     </div>
        //   </div>
        // </div>

  );
}

