import { useState } from "react";
import {  useParams } from "react-router-dom";
import "bulma/css/bulma.css";
import "../Styled.css"

export default function ToochNumber({ form, setForm, onclikTooch, handlePolygonClick }) {
  document.title = "SDC Apps";
  const token = localStorage.getItem("token");
  // modal role add 
  const { id, code } = useParams()
  const [infoGigi, setInfoGigi] = useState("XX-X")

  return (
    <div style={{flex:"50%", display:"flex", justifyContent:"start"}}>

    <div style={{ width: "42px", margin: "1px" }}>

    {form.tooch_number.includes("71-nvt") || form.tooch_number.includes("71-rct") || form.tooch_number.includes("71B-amf-rct")
|| form.tooch_number.includes("71P-amf-rct") || form.tooch_number.includes("71M-amf-rct") || form.tooch_number.includes("71D-amf-rct")
|| form.tooch_number.includes("71L-amf-rct") ||
form.tooch_number.includes("71B-cof-rct") || form.tooch_number.includes("71P-cof-rct") || form.tooch_number.includes("71M-cof-rct")
|| form.tooch_number.includes("71D-cof-rct") || form.tooch_number.includes("71L-cof-rct") ||
form.tooch_number.includes("71-fmc-rct") ||
form.tooch_number.includes("71-poc-rct") ?
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%"}}>
<svg width={42} height={42}>
<polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 71B"
  points="21,2 41,32 1,32" 
  style={{ fill: form.tooch_number.includes("71-nvt") ? 'white' : 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('71-Bukal')}
  onMouseLeave={() => setInfoGigi('XX-X')}
/>
</svg>
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingTop: "13px" }}>
</div>
        } 
<svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("71-fmc") || form.tooch_number.includes("71-poc") || form.tooch_number.includes("71-ipx-poc") || form.tooch_number.match(/\b71-pob\b/) || form.tooch_number.match(/\b71-miss-pon-pob\b/) || form.tooch_number.match(/\b71-meb\b/) || form.tooch_number.match(/\b71-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
{form.tooch_number.includes("71-poc") || form.tooch_number.includes("71-ipx-poc") || form.tooch_number.match(/\b71-pob\b/) || form.tooch_number.match(/\b71-miss-pon-pob\b/) ?
<g>
 {/* Atas */}
 {form.tooch_number.includes("71P-fis") || form.tooch_number.includes("71P-amf") || form.tooch_number.includes("71P-cof") || form.tooch_number.includes("71P-car") ?
   <>
     {form.tooch_number.includes("71P-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="71P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 71P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: 'url(#71P-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("71-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />   
       </svg>
     }

     {form.tooch_number.includes("71P-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 71P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("71-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("71P-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="71P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 71P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("71-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("71P-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 71P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("71-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="71P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 71P"
       points="0,0 40,0 30,20 10,20"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("71-Palatal")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Bawah */}
 {form.tooch_number.includes("71L-fis") || form.tooch_number.includes("71L-amf") || form.tooch_number.includes("71L-cof") || form.tooch_number.includes("71L-car") ?
   <>
     {form.tooch_number.includes("71L-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="71L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>

         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 71L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: 'url(#71L-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("71-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("71L-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 71L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("71-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("71L-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="71L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 71L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("71-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("71L-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 71L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("71-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="71L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 71L"
       points="30,20 40,40 0,40 10,20"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("71-Labial")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Kanan */}
 {form.tooch_number.includes("71D-fis") || form.tooch_number.includes("71D-amf") || form.tooch_number.includes("71D-cof") || form.tooch_number.includes("71D-car") ?
   <>
     {form.tooch_number.includes("71D-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="71D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 71D"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: 'url(#71D-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("71-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("71D-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 71D"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("71-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("71D-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="71D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 71D"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("71-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }
   
     {form.tooch_number.includes("71D-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 71D"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("71-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="71D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 71D"
       points="30,20 40,0 40,40 35,30"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("71-Distal")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Kiri */}
 {form.tooch_number.includes("71M-fis") || form.tooch_number.includes("71M-amf") || form.tooch_number.includes("71M-cof") || form.tooch_number.includes("71M-car") ?
   <>
     {form.tooch_number.includes("71M-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="71M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 71M"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: 'url(#71M-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("71-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("71M-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 71M"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("71-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("71M-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="71M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 71M"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("71-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("71M-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 71M"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("71-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="71M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 71M"
       points="0,0 5,10 10,20 0,40"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("71-Mesial")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* cfr */}
 {form.tooch_number.includes("71-cfr") &&
   <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
     #
   </text>
 }

 {/* rrx */}
 {form.tooch_number.includes("71-rrx") &&    
 <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100">
   v
 </text>
 }
       
 {/* mis */}
 {form.tooch_number.includes("71-mis") &&    
 <>  
   <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
   <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
 </> 
 }
         
</g>
 :
<g>
 {/* Atas */}
 {form.tooch_number.includes("71P-fis") || form.tooch_number.includes("71P-amf") || form.tooch_number.includes("71P-cof") || form.tooch_number.includes("71P-car") ?
   <>
     {form.tooch_number.includes("71P-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="71P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 71P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: 'url(#71P-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("71-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("71P-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 71P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("71-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("71P-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="71P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 71P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("71-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("71P-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 71P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("71-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 71P"
     points="0,0 40,0 30,20 10,20"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("71-Palatal")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Bawah */}
 {form.tooch_number.includes("71L-fis") || form.tooch_number.includes("71L-amf") || form.tooch_number.includes("71L-cof") || form.tooch_number.includes("71L-car") ?
   <>
     {form.tooch_number.includes("71L-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="71L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>

         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 71L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: 'url(#71L-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("71-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("71L-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 71L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("71-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("71L-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="71L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 71L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("71-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("71L-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 71L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("71-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 71L"
     points="30,20 40,40 0,40 10,20"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("71-Labial")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Kanan */}
 {form.tooch_number.includes("71D-fis") || form.tooch_number.includes("71D-amf") || form.tooch_number.includes("71D-cof") || form.tooch_number.includes("71D-car") ?
   <>
     {form.tooch_number.includes("71D-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="71D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 71D"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: 'url(#71D-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("71-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("71D-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 71D"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("71-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("71D-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="71D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 71D"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("71-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }
   
     {form.tooch_number.includes("71D-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 71D"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("71-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 71D"
     points="30,20 40,0 40,40 35,30"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("71-Distal")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Kiri */}
 {form.tooch_number.includes("71M-fis") || form.tooch_number.includes("71M-amf") || form.tooch_number.includes("71M-cof") || form.tooch_number.includes("71M-car") ?
   <>
     {form.tooch_number.includes("71M-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="71M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 71M"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: 'url(#71M-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("71-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("71M-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 71M"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("71-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("71M-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="71M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 71M"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("71-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("71M-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 71M"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("71-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 71M"
     points="0,0 5,10 10,20 0,40"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("71-Mesial")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* cfr */}
 {form.tooch_number.includes("71-cfr") &&
   <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
     #
   </text>
 }
 
 {form.tooch_number.includes("71-rrx") &&    
 <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100">
   v
 </text>
 }
 
 {form.tooch_number.includes("71-mis") &&    
 <>  
   <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
   <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
 </> 
 }
</g>
}    
   
</svg>


{form.tooch_number.includes("71-pob-72-miss-pon-pob-73-pob")
||form.tooch_number.includes("71-pob-72-miss-pon-pob-73-miss-pon-pob-74-pob")
||form.tooch_number.includes("71-pob-72-miss-pon-pob-73-miss-pon-pob-74-miss-pon-pob-75-pob") ?
<>
{/* meb 85 - 57 */}
{form.tooch_number.includes("71-pob-72-miss-pon-pob-73-pob") &&
<div style={{ width: "130px", textAlign: "center", position: "absolute" }}>
              <svg width="100%" height="100%">

                      <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div>
}
                
{/* meb 85 - 53 */}
{form.tooch_number.includes("71-pob-72-miss-pon-pob-73-miss-pon-pob-74-pob") &&
<div style={{ width: "173px", textAlign: "center", position: "absolute" }}>
              <svg width="100%" height="100%">
                      <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div>
}

{/* meb 85 - 52 */} 
{form.tooch_number.includes("71-pob-72-miss-pon-pob-73-miss-pon-pob-74-miss-pon-pob-75-pob") &&      
<div style={{ width: "216px", textAlign: "center", position: "absolute" }}>
              <svg width="100%" height="100%">

                      <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div> 
}

</>
:
<></>
}


{form.tooch_number.includes("71-meb-72-miss-pon-meb-73-meb")
||form.tooch_number.includes("71-meb-72-miss-pon-meb-73-miss-pon-meb-74-meb")
||form.tooch_number.includes("71-meb-72-miss-pon-meb-73-miss-pon-meb-74-miss-pon-meb-75-meb") ?
<>
{/* meb 85 - 83 */}
{form.tooch_number.includes("71-meb-72-miss-pon-meb-73-meb") &&
<div style={{ width: "130px", textAlign: "center", position: "absolute" }}>
              <svg width="100%" height="100%">

  <g transform="rotate(180 108.5 6)">
    {/* Garis ke samping dan ke bawah (L-shape) */}
    <line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div>
}
                
{/* meb 85 - 53 */}
{form.tooch_number.includes("71-meb-72-miss-pon-meb-73-miss-pon-meb-74-meb") &&
<div style={{ width: "173px", textAlign: "center", position: "absolute" }}>
              <svg width="100%" height="100%">

                      <g transform="rotate(180 108.5 6)">
 {/* Garis ke samping dan ke bawah (L-shape) */}
 <line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>

</svg>
</div>
}

{/* meb 85 - 52 */} 
{form.tooch_number.includes("71-meb-72-miss-pon-meb-73-miss-pon-meb-74-miss-pon-meb-75-meb") &&      
<div style={{ width: "216px", textAlign: "center", position: "absolute" }}>
              <svg width="100%" height="100%">

<g transform="rotate(180 108.5 6)">
  {/* Garis yang sudah dirotasi */}
  <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
</g>
</svg>
</div> 
}

</>
:
<></>
        }
        
<div style = {{ textAlign: "center" }}> 71 </div>


{form.tooch_number.includes("71-une") || form.tooch_number.includes("71-non") || form.tooch_number.includes("71-pre") || form.tooch_number.includes("71-ano") || form.tooch_number.includes("71-ipx-poc") || form.tooch_number.includes("71-mis-prd-acr") || form.tooch_number.includes("71-mis-fld-acr") ?
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
{form.tooch_number.includes("71-une") &&
  <div style={{ position: "absolute", color: "black" }}>UNE</div>
}
{form.tooch_number.includes("71-non") &&
  <div style={{ position: "absolute", color: "black" }}>NON</div>
}      
{form.tooch_number.includes("71-pre") &&
  <div style={{ position: "absolute", color: "black" }}>PRE</div>
}      
{form.tooch_number.includes("71-ano") &&
  <div style={{ position: "absolute", color: "black" }}>ANO</div>
}
{form.tooch_number.includes("71-ipx-poc") &&
  <div style={{ position: "absolute", color: "black" }}>IPX</div>
}
{form.tooch_number.includes("71-mis-prd-acr") &&
  <div style={{ position: "absolute", color: "black" }}>PRD</div>
} 
{form.tooch_number.includes("71-mis-fld-acr") &&
  <div style={{ position: "absolute", color: "black" }}>FLD</div>
}      
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
</div>
}



</div> 

<div style={{ width: "42px", margin: "1px" }}>

{form.tooch_number.includes("72-nvt") || form.tooch_number.includes("72-rct") || form.tooch_number.includes("72B-amf-rct")
|| form.tooch_number.includes("72P-amf-rct") || form.tooch_number.includes("72M-amf-rct") || form.tooch_number.includes("72D-amf-rct")
|| form.tooch_number.includes("72L-amf-rct") ||
form.tooch_number.includes("72B-cof-rct") || form.tooch_number.includes("72P-cof-rct") || form.tooch_number.includes("72M-cof-rct")
|| form.tooch_number.includes("72D-cof-rct") || form.tooch_number.includes("72L-cof-rct") ||
form.tooch_number.includes("72-fmc-rct") ||
form.tooch_number.includes("72-poc-rct") ?
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%"}}>
<svg width={42} height={42}>
<polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 72B"
  points="21,2 41,32 1,32" 
  style={{ fill: form.tooch_number.includes("72-nvt") ? 'white' : 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('72-Bukal')}
  onMouseLeave={() => setInfoGigi('XX-X')}
/>
</svg>
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingTop: "13px" }}>
</div>
        } 
<svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("72-fmc") || form.tooch_number.includes("72-poc") || form.tooch_number.includes("72-ipx-poc") || form.tooch_number.match(/\b72-pob\b/) || form.tooch_number.match(/\b72-miss-pon-pob\b/) || form.tooch_number.match(/\b72-meb\b/) || form.tooch_number.match(/\b72-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
{form.tooch_number.includes("72-poc") || form.tooch_number.includes("72-ipx-poc") || form.tooch_number.match(/\b72-pob\b/) || form.tooch_number.match(/\b72-miss-pon-pob\b/) ?
<g>
 {/* Atas */}
 {form.tooch_number.includes("72P-fis") || form.tooch_number.includes("72P-amf") || form.tooch_number.includes("72P-cof") || form.tooch_number.includes("72P-car") ?
   <>
     {form.tooch_number.includes("72P-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="72P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 72P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: 'url(#72P-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("72-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />   
       </svg>
     }

     {form.tooch_number.includes("72P-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 72P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("72-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("72P-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="72P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 72P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("72-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("72P-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 72P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("72-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="72P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 72P"
       points="0,0 40,0 30,20 10,20"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("72-Palatal")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Bawah */}
 {form.tooch_number.includes("72L-fis") || form.tooch_number.includes("72L-amf") || form.tooch_number.includes("72L-cof") || form.tooch_number.includes("72L-car") ?
   <>
     {form.tooch_number.includes("72L-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="72L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>

         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 72L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: 'url(#72L-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("72-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("72L-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 72L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("72-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("72L-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="72L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 72L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("72-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("72L-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 72L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("72-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="72L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 72L"
       points="30,20 40,40 0,40 10,20"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("72-Labial")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Kanan */}
 {form.tooch_number.includes("72D-fis") || form.tooch_number.includes("72D-amf") || form.tooch_number.includes("72D-cof") || form.tooch_number.includes("72D-car") ?
   <>
     {form.tooch_number.includes("72D-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="72D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 72D"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: 'url(#72D-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("72-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("72D-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 72D"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("72-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("72D-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="72D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 72D"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("72-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }
   
     {form.tooch_number.includes("72D-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 72D"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("72-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="72D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 72D"
       points="30,20 40,0 40,40 35,30"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("72-Distal")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Kiri */}
 {form.tooch_number.includes("72M-fis") || form.tooch_number.includes("72M-amf") || form.tooch_number.includes("72M-cof") || form.tooch_number.includes("72M-car") ?
   <>
     {form.tooch_number.includes("72M-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="72M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 72M"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: 'url(#72M-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("72-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("72M-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 72M"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("72-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("72M-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="72M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 72M"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("72-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("72M-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 72M"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("72-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="72M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 72M"
       points="0,0 5,10 10,20 0,40"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("72-Mesial")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* cfr */}
 {form.tooch_number.includes("72-cfr") &&
   <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
     #
   </text>
 }

 {/* rrx */}
 {form.tooch_number.includes("72-rrx") &&    
 <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100">
   v
 </text>
 }
       
 {/* mis */}
 {form.tooch_number.includes("72-mis") &&    
 <>  
   <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
   <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
 </> 
 }
         
</g>
 :
<g>
 {/* Atas */}
 {form.tooch_number.includes("72P-fis") || form.tooch_number.includes("72P-amf") || form.tooch_number.includes("72P-cof") || form.tooch_number.includes("72P-car") ?
   <>
     {form.tooch_number.includes("72P-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="72P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 72P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: 'url(#72P-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("72-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("72P-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 72P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("72-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("72P-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="72P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 72P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("72-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("72P-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 72P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("72-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 72P"
     points="0,0 40,0 30,20 10,20"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("72-Palatal")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Bawah */}
 {form.tooch_number.includes("72L-fis") || form.tooch_number.includes("72L-amf") || form.tooch_number.includes("72L-cof") || form.tooch_number.includes("72L-car") ?
   <>
     {form.tooch_number.includes("72L-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="72L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>

         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 72L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: 'url(#72L-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("72-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("72L-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 72L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("72-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("72L-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="72L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 72L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("72-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("72L-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 72L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("72-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 72L"
     points="30,20 40,40 0,40 10,20"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("72-Labial")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Kanan */}
 {form.tooch_number.includes("72D-fis") || form.tooch_number.includes("72D-amf") || form.tooch_number.includes("72D-cof") || form.tooch_number.includes("72D-car") ?
   <>
     {form.tooch_number.includes("72D-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="72D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 72D"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: 'url(#72D-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("72-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("72D-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 72D"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("72-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("72D-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="72D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 72D"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("72-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }
   
     {form.tooch_number.includes("72D-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 72D"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("72-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 72D"
     points="30,20 40,0 40,40 35,30"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("72-Distal")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Kiri */}
 {form.tooch_number.includes("72M-fis") || form.tooch_number.includes("72M-amf") || form.tooch_number.includes("72M-cof") || form.tooch_number.includes("72M-car") ?
   <>
     {form.tooch_number.includes("72M-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="72M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 72M"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: 'url(#72M-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("72-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("72M-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 72M"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("72-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("72M-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="72M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 72M"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("72-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("72M-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 72M"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("72-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 72M"
     points="0,0 5,10 10,20 0,40"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("72-Mesial")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* cfr */}
 {form.tooch_number.includes("72-cfr") &&
   <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
     #
   </text>
 }
 
 {form.tooch_number.includes("72-rrx") &&    
 <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100">
   v
 </text>
 }
 
 {form.tooch_number.includes("72-mis") &&    
 <>  
   <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
   <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
 </> 
 }
</g>
}    
   
</svg>

{form.tooch_number.includes("72-pob-73-miss-pon-pob-74-pob")
||form.tooch_number.includes("72-pob-73-miss-pon-pob-74-miss-pon-pob-75-pob")?
<>
{/* meb 85 - 57 */}
{form.tooch_number.includes("72-pob-73-miss-pon-pob-74-pob") &&
<div style={{ width: "130px", textAlign: "center", position: "absolute" }}>
              <svg width="100%" height="100%">

                      <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div>
}
                
{/* meb 85 - 53 */}
{form.tooch_number.includes("72-pob-73-miss-pon-pob-74-miss-pon-pob-75-pob") &&
<div style={{ width: "173px", textAlign: "center", position: "absolute" }}>
              <svg width="100%" height="100%">

                      <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div>
}

</>
:
<></>
}


{form.tooch_number.includes("72-meb-73-miss-pon-meb-74-meb")
||form.tooch_number.includes("72-meb-73-miss-pon-meb-74-miss-pon-meb-75-meb")?
<>
{/* meb 85 - 83 */}
{form.tooch_number.includes("72-meb-73-miss-pon-meb-74-meb") &&
<div style={{ width: "130px", textAlign: "center", position: "absolute" }}>
              <svg width="100%" height="100%">

  <g transform="rotate(180 108.5 6)">
    {/* Garis ke samping dan ke bawah (L-shape) */}
    <line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div>
}
                
{/* meb 85 - 53 */}
{form.tooch_number.includes("72-meb-73-miss-pon-meb-74-miss-pon-meb-75-meb") &&
<div style={{ width: "173px", textAlign: "center", position: "absolute" }}>
              <svg width="100%" height="100%">

                      <g transform="rotate(180 108.5 6)">
 {/* Garis ke samping dan ke bawah (L-shape) */}
 <line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>

</svg>
</div>
}

</>
:
<></>
        }

<div style = {{ textAlign: "center" }}> 72 </div>


{form.tooch_number.includes("72-une") || form.tooch_number.includes("72-non") || form.tooch_number.includes("72-pre") || form.tooch_number.includes("72-ano") || form.tooch_number.includes("72-ipx-poc") || form.tooch_number.includes("72-mis-prd-acr") || form.tooch_number.includes("72-mis-fld-acr") ?
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
{form.tooch_number.includes("72-une") &&
  <div style={{ position: "absolute", color: "black" }}>UNE</div>
}
{form.tooch_number.includes("72-non") &&
  <div style={{ position: "absolute", color: "black" }}>NON</div>
}      
{form.tooch_number.includes("72-pre") &&
  <div style={{ position: "absolute", color: "black" }}>PRE</div>
}      
{form.tooch_number.includes("72-ano") &&
  <div style={{ position: "absolute", color: "black" }}>ANO</div>
}
{form.tooch_number.includes("72-ipx-poc") &&
  <div style={{ position: "absolute", color: "black" }}>IPX</div>
}
{form.tooch_number.includes("72-mis-prd-acr") &&
  <div style={{ position: "absolute", color: "black" }}>PRD</div>
} 
{form.tooch_number.includes("72-mis-fld-acr") &&
  <div style={{ position: "absolute", color: "black" }}>FLD</div>
}      
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
</div>
}



</div> 

<div style={{ width: "42px", margin: "1px" }}>

{form.tooch_number.includes("73-nvt") || form.tooch_number.includes("73-rct") || form.tooch_number.includes("73B-amf-rct")
|| form.tooch_number.includes("73P-amf-rct") || form.tooch_number.includes("73M-amf-rct") || form.tooch_number.includes("73D-amf-rct")
|| form.tooch_number.includes("73L-amf-rct") ||
form.tooch_number.includes("73B-cof-rct") || form.tooch_number.includes("73P-cof-rct") || form.tooch_number.includes("73M-cof-rct")
|| form.tooch_number.includes("73D-cof-rct") || form.tooch_number.includes("73L-cof-rct") ||
form.tooch_number.includes("73-fmc-rct") ||
form.tooch_number.includes("73-poc-rct") ?
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%"}}>
<svg width={42} height={42}>
<polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 73B"
  points="21,2 41,32 1,32" 
  style={{ fill: form.tooch_number.includes("73-nvt") ? 'white' : 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('73-Bukal')}
  onMouseLeave={() => setInfoGigi('XX-X')}
/>
</svg>
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingTop: "13px" }}>
</div>
        } 
<svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("73-fmc") || form.tooch_number.includes("73-poc") || form.tooch_number.includes("73-ipx-poc") || form.tooch_number.match(/\b73-pob\b/) || form.tooch_number.match(/\b73-miss-pon-pob\b/) || form.tooch_number.match(/\b73-meb\b/) || form.tooch_number.match(/\b73-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
{form.tooch_number.includes("73-poc") || form.tooch_number.includes("73-ipx-poc") || form.tooch_number.match(/\b73-pob\b/) || form.tooch_number.match(/\b73-miss-pon-pob\b/) ?
<g>
 {/* Atas */}
 {form.tooch_number.includes("73P-fis") || form.tooch_number.includes("73P-amf") || form.tooch_number.includes("73P-cof") || form.tooch_number.includes("73P-car") ?
   <>
     {form.tooch_number.includes("73P-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="73P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 73P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: 'url(#73P-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("73-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />   
       </svg>
     }

     {form.tooch_number.includes("73P-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 73P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("73-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("73P-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="73P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 73P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("73-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("73P-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 73P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("73-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="73P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 73P"
       points="0,0 40,0 30,20 10,20"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("73-Palatal")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Bawah */}
 {form.tooch_number.includes("73L-fis") || form.tooch_number.includes("73L-amf") || form.tooch_number.includes("73L-cof") || form.tooch_number.includes("73L-car") ?
   <>
     {form.tooch_number.includes("73L-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="73L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>

         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 73L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: 'url(#73L-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("73-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("73L-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 73L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("73-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("73L-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="73L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 73L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("73-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("73L-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 73L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("73-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="73L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 73L"
       points="30,20 40,40 0,40 10,20"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("73-Labial")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Kanan */}
 {form.tooch_number.includes("73D-fis") || form.tooch_number.includes("73D-amf") || form.tooch_number.includes("73D-cof") || form.tooch_number.includes("73D-car") ?
   <>
     {form.tooch_number.includes("73D-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="73D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 73D"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: 'url(#73D-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("73-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("73D-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 73D"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("73-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("73D-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="73D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 73D"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("73-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }
   
     {form.tooch_number.includes("73D-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 73D"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("73-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="73D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 73D"
       points="30,20 40,0 40,40 35,30"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("73-Distal")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Kiri */}
 {form.tooch_number.includes("73M-fis") || form.tooch_number.includes("73M-amf") || form.tooch_number.includes("73M-cof") || form.tooch_number.includes("73M-car") ?
   <>
     {form.tooch_number.includes("73M-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="73M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 73M"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: 'url(#73M-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("73-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("73M-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 73M"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("73-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("73M-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="73M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 73M"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("73-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("73M-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 73M"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("73-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="73M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 73M"
       points="0,0 5,10 10,20 0,40"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("73-Mesial")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* cfr */}
 {form.tooch_number.includes("73-cfr") &&
   <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
     #
   </text>
 }

 {/* rrx */}
 {form.tooch_number.includes("73-rrx") &&    
 <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100">
   v
 </text>
 }
       
 {/* mis */}
 {form.tooch_number.includes("73-mis") &&    
 <>  
   <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
   <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
 </> 
 }
         
</g>
 :
<g>
 {/* Atas */}
 {form.tooch_number.includes("73P-fis") || form.tooch_number.includes("73P-amf") || form.tooch_number.includes("73P-cof") || form.tooch_number.includes("73P-car") ?
   <>
     {form.tooch_number.includes("73P-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="73P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 73P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: 'url(#73P-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("73-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("73P-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 73P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("73-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("73P-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="73P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 73P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("73-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("73P-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 73P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("73-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 73P"
     points="0,0 40,0 30,20 10,20"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("73-Palatal")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Bawah */}
 {form.tooch_number.includes("73L-fis") || form.tooch_number.includes("73L-amf") || form.tooch_number.includes("73L-cof") || form.tooch_number.includes("73L-car") ?
   <>
     {form.tooch_number.includes("73L-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="73L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>

         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 73L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: 'url(#73L-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("73-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("73L-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 73L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("73-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("73L-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="73L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 73L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("73-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("73L-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 73L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("73-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 73L"
     points="30,20 40,40 0,40 10,20"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("73-Labial")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Kanan */}
 {form.tooch_number.includes("73D-fis") || form.tooch_number.includes("73D-amf") || form.tooch_number.includes("73D-cof") || form.tooch_number.includes("73D-car") ?
   <>
     {form.tooch_number.includes("73D-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="73D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 73D"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: 'url(#73D-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("73-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("73D-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 73D"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("73-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("73D-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="73D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 73D"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("73-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }
   
     {form.tooch_number.includes("73D-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 73D"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("73-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 73D"
     points="30,20 40,0 40,40 35,30"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("73-Distal")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Kiri */}
 {form.tooch_number.includes("73M-fis") || form.tooch_number.includes("73M-amf") || form.tooch_number.includes("73M-cof") || form.tooch_number.includes("73M-car") ?
   <>
     {form.tooch_number.includes("73M-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="73M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 73M"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: 'url(#73M-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("73-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("73M-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 73M"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("73-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("73M-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="73M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 73M"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("73-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("73M-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 73M"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("73-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 73M"
     points="0,0 5,10 10,20 0,40"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("73-Mesial")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* cfr */}
 {form.tooch_number.includes("73-cfr") &&
   <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
     #
   </text>
 }
 
 {form.tooch_number.includes("73-rrx") &&    
 <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100">
   v
 </text>
 }
 
 {form.tooch_number.includes("73-mis") &&    
 <>  
   <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
   <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
 </> 
 }
</g>
}    
   
</svg>

{form.tooch_number.includes("73-pob-74-miss-pon-pob-75-pob")?
<>
{/* meb 85 - 57 */}
{form.tooch_number.includes("73-pob-74-miss-pon-pob-75-pob") &&
<div style={{ width: "130px", textAlign: "center", position: "absolute" }}>
              <svg width="100%" height="100%">

                      <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div>
}
              
</>
:
<></>
}


{form.tooch_number.includes("73-meb-74-miss-pon-meb-75-meb")?
<>
{/* meb 85 - 83 */}
{form.tooch_number.includes("73-meb-74-miss-pon-meb-75-meb") &&
<div style={{ width: "130px", textAlign: "center", position: "absolute" }}>
              <svg width="100%" height="100%">

  <g transform="rotate(180 108.5 6)">
    {/* Garis ke samping dan ke bawah (L-shape) */}
    <line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div>
}

</>
:
<></>
}
        
<div style = {{ textAlign: "center" }}> 73 </div>


{form.tooch_number.includes("73-une") || form.tooch_number.includes("73-non") || form.tooch_number.includes("73-pre") || form.tooch_number.includes("73-ano") || form.tooch_number.includes("73-ipx-poc") || form.tooch_number.includes("73-mis-prd-acr") || form.tooch_number.includes("73-mis-fld-acr") ?
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
 {form.tooch_number.includes("73-une") &&
   <div style={{ position: "absolute", color: "black" }}>UNE</div>
 }
 {form.tooch_number.includes("73-non") &&
   <div style={{ position: "absolute", color: "black" }}>NON</div>
 }      
 {form.tooch_number.includes("73-pre") &&
   <div style={{ position: "absolute", color: "black" }}>PRE</div>
 }      
 {form.tooch_number.includes("73-ano") &&
   <div style={{ position: "absolute", color: "black" }}>ANO</div>
 }
 {form.tooch_number.includes("73-ipx-poc") &&
   <div style={{ position: "absolute", color: "black" }}>IPX</div>
 }
 {form.tooch_number.includes("73-mis-prd-acr") &&
   <div style={{ position: "absolute", color: "black" }}>PRD</div>
 } 
 {form.tooch_number.includes("73-mis-fld-acr") &&
   <div style={{ position: "absolute", color: "black" }}>FLD</div>
 }      
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
</div>
}



 </div> 

 <div style={{ width: "42px", margin: "1px" }}>

 {form.tooch_number.includes("74O-amf-rct") || form.tooch_number.includes("74O-cof-rct") ||form.tooch_number.includes("74-nvt") || form.tooch_number.includes("74-rct") || form.tooch_number.includes("74B-amf-rct")
|| form.tooch_number.includes("74P-amf-rct") || form.tooch_number.includes("74M-amf-rct") || form.tooch_number.includes("74D-amf-rct")
|| form.tooch_number.includes("74L-amf-rct") ||
form.tooch_number.includes("74B-cof-rct") || form.tooch_number.includes("74P-cof-rct") || form.tooch_number.includes("74M-cof-rct")
|| form.tooch_number.includes("74D-cof-rct") || form.tooch_number.includes("74L-cof-rct") ||
form.tooch_number.includes("74-fmc-rct") ||
form.tooch_number.includes("74-poc-rct") ?
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%"}}>
<svg width={42} height={42}>
<polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 74B"
  points="21,2 41,32 1,32" 
  style={{ fill: form.tooch_number.includes("74-nvt") ? 'white' : 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('74-Bukal')}
  onMouseLeave={() => setInfoGigi('XX-X')}
/>
</svg>
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingTop: "13px" }}>
</div>
        } 

<svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("74-fmc") || form.tooch_number.includes("74-poc") || form.tooch_number.includes("74-ipx-poc") || form.tooch_number.match(/\b74-pob\b/) || form.tooch_number.match(/\b74-miss-pon-pob\b/) || form.tooch_number.match(/\b74-meb\b/) || form.tooch_number.match(/\b74-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
{form.tooch_number.includes("74-poc") || form.tooch_number.includes("74-ipx-poc") || form.tooch_number.match(/\b74-pob\b/) || form.tooch_number.match(/\b74-miss-pon-pob\b/) ?
<g>
{/* Atas */}
{form.tooch_number.includes("74L-fis") || form.tooch_number.includes("74L-amf") || form.tooch_number.includes("74L-cof") || form.tooch_number.includes("74L-car") ?
  <>
    {form.tooch_number.includes("74L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="74L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 74L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#74L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("74-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("74L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 74L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("74-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("74L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="74L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 74L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("74-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("74L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 74L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("74-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
<svg width="40" height="40">
  <defs>
    <pattern id="74L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
    </pattern>
  </defs>
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 74L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: '#007610', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("74-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
</svg>
}

{/* Bawah */}
{form.tooch_number.includes("74B-fis") || form.tooch_number.includes("74B-amf") || form.tooch_number.includes("74B-cof") || form.tooch_number.includes("74B-car") ?
  <>
    {form.tooch_number.includes("74B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="74B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 74B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#74B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("74-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("74B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 74B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("74-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("74B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="74B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 74B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("74-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("74B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 74B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("74-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="74B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 74B"
      points="10,30 30,30 40,40 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("74-Bukal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kanan */}
{form.tooch_number.includes("74D-fis") || form.tooch_number.includes("74D-amf") || form.tooch_number.includes("74D-cof") || form.tooch_number.includes("74D-car") ?
  <>
    {form.tooch_number.includes("74D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="74D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 74D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#74D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("74-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("74D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 74D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("74-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("74D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="74D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 74D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("74-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("74D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 74D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("74-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="74D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 74D"
      points="30,10 40,0 40,40 30,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("74-Distal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kiri */}
{form.tooch_number.includes("74M-fis") || form.tooch_number.includes("74M-amf") || form.tooch_number.includes("74M-cof") || form.tooch_number.includes("74M-car") ?
  <>
    {form.tooch_number.includes("74M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="74M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 74M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#74M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("74-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("74M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 74M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("74-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("74M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="74M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 74M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("74-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("74M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 74M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("74-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="74M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 74M"
      points="0,0 10,10 10,30 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("74-Mesial")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* center */}
{form.tooch_number.includes("74O-fis") || form.tooch_number.includes("74O-amf") || form.tooch_number.includes("74O-cof") || form.tooch_number.includes("74O-car") ?
  <>
    {form.tooch_number.includes("74O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="74O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 74O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#74O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("74-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("74O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 74O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("74-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("74O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="74O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 74O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("74-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("74O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 74O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("74-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="74O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 74O"
      points="10,10 30,10 30,30 10,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("74-Oklusal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* cfr */}
{form.tooch_number.includes("74-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{/* rrx */}
{form.tooch_number.includes("74-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100">
  v
</text>
}
      
{/* mis */}
{form.tooch_number.includes("74-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
        
</g>
:
<g>
{/* Atas */}
{form.tooch_number.includes("74L-fis") || form.tooch_number.includes("74L-amf") || form.tooch_number.includes("74L-cof") || form.tooch_number.includes("74L-car") ?
  <>
    {form.tooch_number.includes("74L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="74L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 74L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#74L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("74-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("74L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 74L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("74-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("74L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="74L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 74L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("74-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("74L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 74L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("74-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 74L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("74-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Bawah */}
{form.tooch_number.includes("74B-fis") || form.tooch_number.includes("74B-amf") || form.tooch_number.includes("74B-cof") || form.tooch_number.includes("74B-car") ?
  <>
    {form.tooch_number.includes("74B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="74B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 74B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#74B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("74-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("74B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 74B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("74-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("74B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="74B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 74B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("74-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("74B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 74B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("74-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 74B"
    points="10,30 30,30 40,40 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("74-Bukal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kanan */}
{form.tooch_number.includes("74D-fis") || form.tooch_number.includes("74D-amf") || form.tooch_number.includes("74D-cof") || form.tooch_number.includes("74D-car") ?
  <>
    {form.tooch_number.includes("74D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="74D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 74D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#74D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("74-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("74D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 74D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("74-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("74D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="74D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 74D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("74-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("74D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 74D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("74-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 74D"
    points="30,10 40,0 40,40 30,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("74-Distal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kiri */}
{form.tooch_number.includes("74M-fis") || form.tooch_number.includes("74M-amf") || form.tooch_number.includes("74M-cof") || form.tooch_number.includes("74M-car") ?
  <>
    {form.tooch_number.includes("74M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="74M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 74M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#74M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("74-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("74M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 74M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("74-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("74M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="74M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 74M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("74-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("74M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 74M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("74-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 74M"
    points="0,0 10,10 10,30 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("74-Mesial")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* center */}
{form.tooch_number.includes("74O-fis") || form.tooch_number.includes("74O-amf") || form.tooch_number.includes("74O-cof") || form.tooch_number.includes("74O-car") ?
  <>
    {form.tooch_number.includes("74O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="74O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 74O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#74O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("74-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("74O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 74O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("74-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("74O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="74O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 74O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("74-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("74O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 74O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("74-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 74O"
    points="10,10 30,10 30,30 10,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("74-Oklusal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* cfr */}
{form.tooch_number.includes("74-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{form.tooch_number.includes("74-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100">
  v
</text>
}

{form.tooch_number.includes("74-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
</g>
}      
</svg>

{form.tooch_number.includes("74-pob-84-miss-pon-pob-83-pob")
||form.tooch_number.includes("74-pob-84-miss-pon-pob-83-miss-pon-pob-82-pob")
||form.tooch_number.includes("74-pob-84-miss-pon-pob-83-miss-pon-pob-82-miss-pon-pob-81-pob") ?
<>
{/* meb 74 - 57 */}
{form.tooch_number.includes("74-pob-84-miss-pon-pob-83-pob") &&
<div style={{ width: "100%", textAlign: "center", position: "absolute" }}>
                <svg width="100%" height="100%">
                <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
</g>
</svg>
</div>
}
          
{/* meb 74 - 53 */}
{form.tooch_number.includes("74-pob-84-miss-pon-pob-83-miss-pon-pob-82-pob") &&
<div style={{ width: "100%", textAlign: "center", position:"absolute" }}>
                <svg width="100%" height="100%">
                <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
</g>
</svg>
</div>
}

{/* meb 74 - 52 */} 
{form.tooch_number.includes("74-pob-84-miss-pon-pob-83-miss-pon-pob-82-miss-pon-pob-81-pob") &&      
<div style={{ width: "100%", textAlign: "center", position:"absolute" }}>
                <svg width="100%" height="100%">
                <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
</g>
</svg>
</div> 
}

</>
:
<></>
}


{form.tooch_number.includes("74-meb-84-miss-pon-meb-83-meb")
||form.tooch_number.includes("74-meb-84-miss-pon-meb-83-miss-pon-meb-82-meb")
||form.tooch_number.includes("74-meb-84-miss-pon-meb-83-miss-pon-meb-82-miss-pon-meb-81-meb") ?
<>
{/* meb 74 - 57 */}
{form.tooch_number.includes("74-meb-84-miss-pon-meb-83-meb") &&
<div style={{ width: "100%", textAlign: "center", position: "absolute" }}>
                <svg width="100%" height="100%">
                <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
</g>

</svg>
</div>
}
          
{/* meb 74 - 53 */}
{form.tooch_number.includes("74-meb-84-miss-pon-meb-83-miss-pon-meb-82-meb") &&
<div style={{ width: "100%", textAlign: "center", position:"absolute" }}>
                <svg width="100%" height="100%">
                <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
</g>

</svg>
</div>
}

{/* meb 74 - 52 */} 
{form.tooch_number.includes("74-meb-84-miss-pon-meb-83-miss-pon-meb-82-miss-pon-meb-81-meb") &&      
<div style={{ width: "100%", textAlign: "", position:"absolute", border:"1px solid black" }}>
<svg width="100%" height="100%">
<g transform="rotate(180 108.5 6)">
{/* Garis yang sudah dirotasi */}
<line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
</g>
</svg>
</div> 
}

</>
:
<></>
}
<div style={{ textAlign: "center" }}>74</div>

{form.tooch_number.includes("74-une") || form.tooch_number.includes("74-non") || form.tooch_number.includes("74-pre") || form.tooch_number.includes("74-ano") || form.tooch_number.includes("74-ipx-poc") || form.tooch_number.includes("74-mis-prd-acr") || form.tooch_number.includes("74-mis-fld-acr") ?
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
{form.tooch_number.includes("74-une") &&
<div style={{ position: "absolute", color: "black" }}>UNE</div>
}
{form.tooch_number.includes("74-non") &&
<div style={{ position: "absolute", color: "black" }}>NON</div>
}      
{form.tooch_number.includes("74-pre") &&
<div style={{ position: "absolute", color: "black" }}>PRE</div>
}      
{form.tooch_number.includes("74-ano") &&
<div style={{ position: "absolute", color: "black" }}>ANO</div>
}
{form.tooch_number.includes("74-ipx-poc") &&
<div style={{ position: "absolute", color: "black" }}>IPX</div>
}
{form.tooch_number.includes("74-mis-prd-acr") &&
<div style={{ position: "absolute", color: "black" }}>PRD</div>
} 
{form.tooch_number.includes("74-mis-fld-acr") &&
<div style={{ position: "absolute", color: "black" }}>FLD</div>
}      
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
</div>
}
</div>

<div style={{ width: "42px", margin: "1px" }}>

{form.tooch_number.includes("75O-amf-rct") || form.tooch_number.includes("75O-cof-rct") ||form.tooch_number.includes("75-nvt") || form.tooch_number.includes("75-rct") || form.tooch_number.includes("75B-amf-rct")
|| form.tooch_number.includes("75P-amf-rct") || form.tooch_number.includes("75M-amf-rct") || form.tooch_number.includes("75D-amf-rct")
|| form.tooch_number.includes("75L-amf-rct") ||
form.tooch_number.includes("75B-cof-rct") || form.tooch_number.includes("75P-cof-rct") || form.tooch_number.includes("75M-cof-rct")
|| form.tooch_number.includes("75D-cof-rct") || form.tooch_number.includes("75L-cof-rct") ||
form.tooch_number.includes("75-fmc-rct") ||
form.tooch_number.includes("75-poc-rct") ?
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%"}}>
<svg width={42} height={42}>
<polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 75B"
  points="21,2 41,32 1,32" 
  style={{ fill: form.tooch_number.includes("75-nvt") ? 'white' : 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('75-Bukal')}
  onMouseLeave={() => setInfoGigi('XX-X')}
/>
</svg>
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingTop: "13px" }}>
</div>
        } 

<svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("75-fmc") || form.tooch_number.includes("75-poc") || form.tooch_number.includes("75-ipx-poc") || form.tooch_number.match(/\b75-pob\b/) || form.tooch_number.match(/\b75-miss-pon-pob\b/) || form.tooch_number.match(/\b75-meb\b/) || form.tooch_number.match(/\b75-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
{form.tooch_number.includes("75-poc") || form.tooch_number.includes("75-ipx-poc") || form.tooch_number.match(/\b75-pob\b/) || form.tooch_number.match(/\b75-miss-pon-pob\b/) ?
<g>
{/* Atas */}
{form.tooch_number.includes("75L-fis") || form.tooch_number.includes("75L-amf") || form.tooch_number.includes("75L-cof") || form.tooch_number.includes("75L-car") ?
  <>
    {form.tooch_number.includes("75L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="75L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 75L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#75L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("75-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("75L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 75L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("75-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("75L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="75L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 75L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("75-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("75L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 75L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("75-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
<svg width="40" height="40">
  <defs>
    <pattern id="75L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
    </pattern>
  </defs>
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 75L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: '#007610', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("75-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
</svg>
}

{/* Bawah */}
{form.tooch_number.includes("75B-fis") || form.tooch_number.includes("75B-amf") || form.tooch_number.includes("75B-cof") || form.tooch_number.includes("75B-car") ?
  <>
    {form.tooch_number.includes("75B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="75B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 75B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#75B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("75-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("75B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 75B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("75-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("75B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="75B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 75B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("75-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("75B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 75B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("75-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="75B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 75B"
      points="10,30 30,30 40,40 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("75-Bukal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kanan */}
{form.tooch_number.includes("75D-fis") || form.tooch_number.includes("75D-amf") || form.tooch_number.includes("75D-cof") || form.tooch_number.includes("75D-car") ?
  <>
    {form.tooch_number.includes("75D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="75D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 75D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#75D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("75-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("75D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 75D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("75-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("75D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="75D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 75D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("75-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("75D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 75D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("75-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="75D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 75D"
      points="30,10 40,0 40,40 30,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("75-Distal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kiri */}
{form.tooch_number.includes("75M-fis") || form.tooch_number.includes("75M-amf") || form.tooch_number.includes("75M-cof") || form.tooch_number.includes("75M-car") ?
  <>
    {form.tooch_number.includes("75M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="75M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 75M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#75M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("75-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("75M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 75M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("75-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("75M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="75M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 75M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("75-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("75M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 75M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("75-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="75M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 75M"
      points="0,0 10,10 10,30 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("75-Mesial")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* center */}
{form.tooch_number.includes("75O-fis") || form.tooch_number.includes("75O-amf") || form.tooch_number.includes("75O-cof") || form.tooch_number.includes("75O-car") ?
  <>
    {form.tooch_number.includes("75O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="75O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 75O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#75O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("75-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("75O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 75O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("75-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("75O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="75O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 75O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("75-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("75O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 75O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("75-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="75O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 75O"
      points="10,10 30,10 30,30 10,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("75-Oklusal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* cfr */}
{form.tooch_number.includes("75-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{/* rrx */}
{form.tooch_number.includes("75-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100">
  v
</text>
}
      
{/* mis */}
{form.tooch_number.includes("75-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
        
</g>
:
<g>
{/* Atas */}
{form.tooch_number.includes("75L-fis") || form.tooch_number.includes("75L-amf") || form.tooch_number.includes("75L-cof") || form.tooch_number.includes("75L-car") ?
  <>
    {form.tooch_number.includes("75L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="75L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 75L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#75L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("75-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("75L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 75L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("75-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("75L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="75L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 75L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("75-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("75L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 75L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("75-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 75L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("75-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Bawah */}
{form.tooch_number.includes("75B-fis") || form.tooch_number.includes("75B-amf") || form.tooch_number.includes("75B-cof") || form.tooch_number.includes("75B-car") ?
  <>
    {form.tooch_number.includes("75B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="75B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 75B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#75B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("75-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("75B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 75B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("75-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("75B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="75B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 75B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("75-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("75B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 75B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("75-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 75B"
    points="10,30 30,30 40,40 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("75-Bukal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kanan */}
{form.tooch_number.includes("75D-fis") || form.tooch_number.includes("75D-amf") || form.tooch_number.includes("75D-cof") || form.tooch_number.includes("75D-car") ?
  <>
    {form.tooch_number.includes("75D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="75D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 75D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#75D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("75-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("75D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 75D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("75-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("75D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="75D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 75D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("75-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("75D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 75D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("75-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 75D"
    points="30,10 40,0 40,40 30,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("75-Distal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kiri */}
{form.tooch_number.includes("75M-fis") || form.tooch_number.includes("75M-amf") || form.tooch_number.includes("75M-cof") || form.tooch_number.includes("75M-car") ?
  <>
    {form.tooch_number.includes("75M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="75M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 75M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#75M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("75-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("75M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 75M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("75-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("75M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="75M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 75M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("75-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("75M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 75M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("75-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 75M"
    points="0,0 10,10 10,30 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("75-Mesial")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* center */}
{form.tooch_number.includes("75O-fis") || form.tooch_number.includes("75O-amf") || form.tooch_number.includes("75O-cof") || form.tooch_number.includes("75O-car") ?
  <>
    {form.tooch_number.includes("75O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="75O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 75O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#75O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("75-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("75O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 75O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("75-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("75O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="75O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 75O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("75-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("75O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 75O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("75-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 75O"
    points="10,10 30,10 30,30 10,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("75-Oklusal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* cfr */}
{form.tooch_number.includes("75-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{form.tooch_number.includes("75-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black" fontFamily="calibri" fontWeight="100">
  v
</text>
}

{form.tooch_number.includes("75-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
</g>
}      
</svg>

{form.tooch_number.includes("75-pob-84-miss-pon-pob-83-pob")
||form.tooch_number.includes("75-pob-84-miss-pon-pob-83-miss-pon-pob-82-pob")
||form.tooch_number.includes("75-pob-84-miss-pon-pob-83-miss-pon-pob-82-miss-pon-pob-81-pob") ?
<>
{/* meb 75 - 57 */}
{form.tooch_number.includes("75-pob-84-miss-pon-pob-83-pob") &&
<div style={{ width: "100%", textAlign: "center", position: "absolute" }}>
                <svg width="100%" height="100%">
                <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
</g>
</svg>
</div>
}
          
{/* meb 75 - 53 */}
{form.tooch_number.includes("75-pob-84-miss-pon-pob-83-miss-pon-pob-82-pob") &&
<div style={{ width: "100%", textAlign: "center", position:"absolute" }}>
                <svg width="100%" height="100%">
                <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
</g>
</svg>
</div>
}

{/* meb 75 - 52 */} 
{form.tooch_number.includes("75-pob-84-miss-pon-pob-83-miss-pon-pob-82-miss-pon-pob-81-pob") &&      
<div style={{ width: "100%", textAlign: "center", position:"absolute" }}>
                <svg width="100%" height="100%">
                <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
</g>
</svg>
</div> 
}

</>
:
<></>
}


{form.tooch_number.includes("75-meb-84-miss-pon-meb-83-meb")
||form.tooch_number.includes("75-meb-84-miss-pon-meb-83-miss-pon-meb-82-meb")
||form.tooch_number.includes("75-meb-84-miss-pon-meb-83-miss-pon-meb-82-miss-pon-meb-81-meb") ?
<>
{/* meb 75 - 57 */}
{form.tooch_number.includes("75-meb-84-miss-pon-meb-83-meb") &&
<div style={{ width: "100%", textAlign: "center", position: "absolute" }}>
                <svg width="100%" height="100%">
                <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
</g>

</svg>
</div>
}
          
{/* meb 75 - 53 */}
{form.tooch_number.includes("75-meb-84-miss-pon-meb-83-miss-pon-meb-82-meb") &&
<div style={{ width: "100%", textAlign: "center", position:"absolute" }}>
                <svg width="100%" height="100%">
                <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
</g>

</svg>
</div>
}

{/* meb 75 - 52 */} 
{form.tooch_number.includes("75-meb-84-miss-pon-meb-83-miss-pon-meb-82-miss-pon-meb-81-meb") &&      
<div style={{ width: "100%", textAlign: "", position:"absolute", border:"1px solid black" }}>
<svg width="100%" height="100%">
<g transform="rotate(180 108.5 6)">
{/* Garis yang sudah dirotasi */}
<line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
</g>
</svg>
</div> 
}

</>
:
<></>
}
<div style={{ textAlign: "center" }}>75</div>

{form.tooch_number.includes("75-une") || form.tooch_number.includes("75-non") || form.tooch_number.includes("75-pre") || form.tooch_number.includes("75-ano") || form.tooch_number.includes("75-ipx-poc") || form.tooch_number.includes("75-mis-prd-acr") || form.tooch_number.includes("75-mis-fld-acr") ?
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
{form.tooch_number.includes("75-une") &&
<div style={{ position: "absolute", color: "black" }}>UNE</div>
}
{form.tooch_number.includes("75-non") &&
<div style={{ position: "absolute", color: "black" }}>NON</div>
}      
{form.tooch_number.includes("75-pre") &&
<div style={{ position: "absolute", color: "black" }}>PRE</div>
}      
{form.tooch_number.includes("75-ano") &&
<div style={{ position: "absolute", color: "black" }}>ANO</div>
}
{form.tooch_number.includes("75-ipx-poc") &&
<div style={{ position: "absolute", color: "black" }}>IPX</div>
}
{form.tooch_number.includes("75-mis-prd-acr") &&
<div style={{ position: "absolute", color: "black" }}>PRD</div>
} 
{form.tooch_number.includes("75-mis-fld-acr") &&
<div style={{ position: "absolute", color: "black" }}>FLD</div>
}      
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
</div>
}
</div>

    </div>
  );
}
