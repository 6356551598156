import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import swal from "sweetalert";
import { useMediaQuery } from 'react-responsive'
import Logo_Signature from "../../assets/signature/signature logo.png"
import Logo_Batik from "../../assets/signature/logo-batik.png"
import BackgroundBatik from "../../assets/signature/background-batik.jpg"
import SignatureCanvas from 'react-signature-canvas'
import { FaAddressBook, FaAppStoreIos, FaBackspace, FaBackward, FaBell, FaCamera, FaCheckCircle, FaEdit, FaEnvelope, FaEnvelopeOpenText, FaGenderless, FaHandHoldingMedical, FaList, FaListAlt, FaMailBulk, FaMobile, FaMonero, FaMoneyBillAlt, FaMoneyBillWave, FaMoneyCheck, FaPhone, FaPhoneAlt, FaReply, FaReplyAll, FaReplyd, FaSearch, FaSignOutAlt, FaSpinner, FaSync, FaTransgender, FaTrash, FaUndo, FaUndoAlt, FaWatchmanMonitoring } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import { Alert } from "react-bootstrap";
import { useMutation } from "react-query";
import { API } from "../../config/api";
import React, { useRef } from 'react';
import Dropdown from "../Auth/Dropdown";
import "../../index.css"
import LogoDatePicker from "../../assets/signature/logo-date-pick.png";
import NavbarPasienOnline from "../../components/Navbar/NavbarPasienOnline/NavbarDekstop"
import NavbarPasienOnlineRemake from "../../components/Navbar/NavbarPasienOnline/NavbarDekstopRemake"
import NavbarTopHeader from "../../components/Navbar/NavbarPasienOnline/NavbarTopHeader"
import NavbarHeader from "../../components/Navbar/NavbarPasienOnline/NavbarHeader"
import NavbarHeaderRemake from "../../components/Navbar/NavbarPasienOnline/NavbarHeaderToogle"
import DropdownBotton from "../Auth/DropdownBotton";
import FooterBottom from "../../components/Footer/Footer"
import LoaderHome from "../Loader/LoaderHome"

export default function Login() {

  document.title = "SDC Apps";
  const [state, dispatch] = useContext(UserContext);
  const [message, setMessage] = useState(null);
  const [imageName, setImageName] = useState([])
  const [imageContent, setImageContent] = useState([])
  const [preview, setPreview] = useState(null);
  const token = localStorage.getItem("token");
  const storageItems = JSON.parse(localStorage.getItem('user_data'));
  const nama = localStorage.getItem("username")
  const navigate = useNavigate()
  const [getData, setGetData] = useState([]);
  const [pagination,setPagination] = useState([])
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(0);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(1);
  const [rows, setRows] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");
  const [idDelete, setIdDelete] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [show, setShow] = useState(false);
  const [search,setSearch] = useState("")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [dataMap, setDataMap] = useState("");
  const [dataSearch, setDataSearch] = useState("");
  
  // modal role add 
  const [id, setId] = useState();
  const [nameUpdate, setNameUpdate] = useState();
  const [UrlUpdate, setUrlUpdate] = useState();
  const [IconUpdate, setIconUpdate] = useState();
  const [NumberUpdate, setNumberUpdate] = useState();
  const [modalAdd, setModalAdd] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)'})
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)'})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)'})
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const [modalShow, setModalShow] = useState(false);
  const firstNameInputRef = useRef(null);
  const isTabletOrMobileRemake = useMediaQuery({ query: '(max-width: 800px)' })
  const isTabletOrMobileRemakeSeribu = useMediaQuery({ query: '(max-width: 1200px)'})
  
  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const GetResponseData = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`transaction-service/patient-appointments/${storageItems?.id}?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`,fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetData(response.data.data)
        setPage(response.data.pagination.current_page);
        setPages(response.data.pagination.total_pages);
        setRows(response.data.pagination.total);
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    gender: "",
    date_birth: "",
    phone_number: "",
    address: ""
  });

  const handleChange = async (e) => {
    setForm({
      ...form,
      [e.target.name] : e.target.type === "radio"? e.target.value : e.target.value,
    });
  };

  const handleSubmit = useMutation(async () => {
    try {
    setLoading(true)

    const response = await API.post(`/transaction-service/patient-registrations/store-by-patient`,
    {
      id_patient: `${storageItems?.id}`,
      first_name: form.first_name,
      last_name: form.last_name,
      gender: form.gender,
      date_birth: form.date_birth,
      phone_number: form.phone_number,
      address: form.address
    },
    fetchParams)
    
    if (response?.status === 200) {
    setLoading(false)
    swal({
      title: 'Success',
      text: response.data.message,
      icon: 'success',
      timer: 3000,
      buttons: false
    });
      navigate("/dashboard-pasien-online-penampung");
    }
      
    } catch (error) {
      setLoading(false)
    swal({
      title: 'Failed',
      text: `${error.response.data.message}`,
      icon: 'error',
      buttons: false
    });
  }
  });

  const back = () => {
    navigate("/dashboard-pasien-online-penampung");
  };

  const users = [
    {
    id: "Laki-Laki",
    name: "Laki-Laki"
    },
    {
      id: "Wanita",
      name: "Wanita"
      }
  ]

  return (
    <div style={{backgroundColor: "#FDFDFD", minHeight: "100vh" }}>
    {loading && <LoaderHome />}
    
    <NavbarTopHeader />
      
    {isTabletOrMobileRemake? 
    <div style={{ position: "fixed", display: "flex", justifyContent: "center", bottom: "0", zIndex: "1", width:"100%" }}>
    <DropdownBotton GetResponseData={GetResponseData} />
    </div>
      :
    <Col xl="12" style={{ padding: "0px 0px", position: "fixed",width:"90px"}}>
      <div style={{display: "flex", justifyContent: "", marginTop: "150px" }}>
        <Dropdown GetResponseData={GetResponseData}/>  
      </div>
    </Col>  
    }
     
    {isTabletOrMobileRemake ? 
      <NavbarHeaderRemake/>
      :
      <NavbarHeader />    
    }  

      {isTabletOrMobileRemake ? 
      <>
        <div style={{display: "flex",backgroundColor:"#0097D8", height:"17vh"}}></div>
        <div style={{display: "flex", width:"100%"}}>
          <NavbarPasienOnlineRemake/>
        </div>
      </>
      :
      <>
      <div style={{display: "flex",backgroundColor:"#0097D8",height:"20vh" }}></div>
      <div style={{display: "flex", width:"100%"}}>
          <NavbarPasienOnline />  
      </div>
      </>
      }
       <div style={{padding:isTabletOrMobileRemake? "20px 20px 20px 20px" : "20px 100px 20px 100px"}}>
          <Col xl='12' sm='12' style={{ backgroundColor: "white", boxShadow: "1px 1px 5px #BFBFBF",backgroundColor:"white", padding: isTabletOrMobileRemake? "20px 20px 30px 20px" : isTabletOrMobileRemakeSeribu? "20px 200px 30px 200px" : "20px 400px 30px 400px", borderRadius:"5px" }}>
          <div className="mt-2 mb-3" style={{borderBottom:"1px solid #B2B2B2", textAlign:"center", fontSize:"18px", fontWeight:"bold", color:"#6F6F6F"}}> 
            Data Pasien Baru Harus Sesuai Dengan KTP 
          </div>
        <form >
          <div className="mt-2" style={{ display: "flex" }}>
            <div style={{ width: "100%" }}>
              <div style={{fontSize:"15px", color:"#555555", fontFamily:"revert-layer", fontWeight:""}}>Nama Depan</div>
                <input type="text" name="first_name" onChange={handleChange} placeholder="Nama Depan"   style={{width:"100%", border:"1px solid #C9C9C9", outline:"none", height:"40px", borderRadius:"5px", paddingLeft:"5px", backgroundColor:""}}/>
              {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>keluhan pasien</div> */}
            </div>
          </div>
          
          <div className="mt-3" style={{ display: "flex" }}>
            <div style={{ width: "100%" }}>
              <div style={{fontSize:"15px", color:"#555555", fontFamily:"revert-layer", fontWeight:""}}>Nama Belakang</div>
                <input type="text" name="last_name" onChange={handleChange} placeholder="Nama Belakang"  style={{width:"100%", border:"1px solid #C9C9C9", outline:"none", height:"40px", borderRadius:"5px", paddingLeft:"5px", backgroundColor:""}}/>
              {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>keluhan pasien</div> */}
            </div>
          </div>
          
          <div className="mt-3" style={{ display: "flex" }}>
            <div style={{ width: "100%" }}>
              <div style={{fontSize:"15px", color:"#555555", fontFamily:"revert-layer", fontWeight:""}}>Tanggal Lahir</div>
                <input type="date" name="date_birth" onChange={handleChange} style={{width:"100%", border:"1px solid #C9C9C9", outline:"none", height:"40px", borderRadius:"5px", paddingLeft:"5px", backgroundImage: `url(${LogoDatePicker})`,
                  backgroundPosition: 'calc(100% - 10px) center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '25px'}}/>
              {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>keluhan pasien</div> */}
            </div>
              </div>
              

              <div className="mt-3">
                <div>
                  Jenis Kelamin
                </div>
                <div style={{display:"flex"}}> 
                  {users.map((user) => (
                      <>
                       {form.gender === user.id ?
                        <label className='label-custom' style={{marginTop:"0px", backgroundColor:"#46A9FF", color:"white"}}>
                          <input
                            type="radio"
                            name="gender"
                            value={user.id}
                            className="check-btn"
                            onChange={handleChange}
                          />{" "}
                          <span className="text-inner">{user.name}</span>
                        </label>
                        :
                        <label className='label-custom' style={{marginTop:"0px", border:"1px solid #959595"}}>
                          <input
                            type="radio"
                            name="gender"
                            value={user.id}
                            className="check-btn"
                            onChange={handleChange}
                          />{" "}
                          <span className="text-inner">{user.name}</span>
                        </label>
                        }
                      </>
                    ))}
                  </div>
              </div>
              

              <div className="mt-3" style={{ display: "flex" }}>
            <div style={{ width: "100%" }}>
              <div style={{fontSize:"15px", color:"#555555", fontFamily:"revert-layer", fontWeight:""}}>Nomor Telephone</div>
                <input type="text" name="phone_number" onChange={handleChange} placeholder="Nomor Telephone"  style={{width:"100%", border:"1px solid #C9C9C9", outline:"none", height:"40px", borderRadius:"5px", paddingLeft:"5px", backgroundColor:""}}/>
              {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>keluhan pasien</div> */}
            </div>
              </div>
              
              <div className="mt-3" style={{ display: "flex" }}>
            <div style={{ width: "100%" }}>
              <div style={{fontSize:"15px", color:"#555555", fontFamily:"revert-layer", fontWeight:""}}>Alamat</div>
                <input type="text" name="address" onChange={handleChange} placeholder="Alamat"  style={{width:"100%", border:"1px solid #C9C9C9", outline:"none", height:"40px", borderRadius:"5px", paddingLeft:"5px", backgroundColor:""}}/>
              {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>keluhan pasien</div> */}
            </div>
              </div>
              
             

          <div className="" style={{display:"flex",justifyContent:"center", padding: "0px 0px", marginTop: "50px", marginBottom: "20px"}}>
            <div className="mr-4" style={{width:"180px"}}>
              <Button onClick={back} type='submit' color='secondary' block style={{ fontSize:"15px", borderRadius:"3px", }}>
                Kembali
              </Button>
            </div>
            <div style={{ width: "180px" }}>
              <Button onClick={(e) => handleSubmit.mutate(e)} color='primary' block style={{ fontSize:"15px", borderRadius:"3px", }}>
                Tambah
              </Button>
            </div>
          </div>

              
            </form>
           
          </Col>
        </div>
   
      <FooterBottom/>
  </div>
  );
}
