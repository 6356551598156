import { useState } from "react";
import {  useParams } from "react-router-dom";
import "bulma/css/bulma.css";
import "../Styled.css"

export default function ToochNumber({ form, setForm, onclikTooch, handlePolygonClick }) {
  document.title = "SDC Apps";
  const token = localStorage.getItem("token");
  // modal role add 
  const { id, code } = useParams()
  const [infoGigi, setInfoGigi] = useState("XX-X")

  return (
<div style={{flex:"50%", display:"flex", justifyContent:"start", paddingLeft:""}}>

  <div style={{ width: "42px", margin: "1px" }}>

  
{form.tooch_number.includes("31-nvt") || form.tooch_number.includes("31-rct") ||form.tooch_number.includes("31B-amf-rct") || form.tooch_number.includes("31P-amf-rct") || form.tooch_number.includes("31M-amf-rct") || form.tooch_number.includes("31D-amf-rct") || form.tooch_number.includes("31L-amf-rct") ||
form.tooch_number.includes("31B-cof-rct") || form.tooch_number.includes("31P-cof-rct") || form.tooch_number.includes("31M-cof-rct") || form.tooch_number.includes("31D-cof-rct") || form.tooch_number.includes("31L-cof-rct") ||
form.tooch_number.includes("31-fmc-rct") ||
form.tooch_number.includes("31-poc-rct") ?
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%"}}>
<svg width={42} height={42}>
<polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 31B"
  points="21,2 41,32 1,32" 
  style={{ fill: form.tooch_number.includes("31-nvt") ? 'white' : 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('31-Bukal')}
  onMouseLeave={() => setInfoGigi('XX-X')}
/>
</svg>
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingTop: "13px" }}>
</div>
        } 
        
<svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("31-fmc") || form.tooch_number.includes("31-poc") || form.tooch_number.includes("31-ipx-poc") || form.tooch_number.match(/\b31-pob\b/) || form.tooch_number.match(/\b31-miss-pon-pob\b/) || form.tooch_number.match(/\b31-meb\b/) || form.tooch_number.match(/\b31-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
{form.tooch_number.includes("31-poc") || form.tooch_number.includes("31-ipx-poc") || form.tooch_number.match(/\b31-pob\b/) || form.tooch_number.match(/\b31-miss-pon-pob\b/) ?
<g>
 {/* Atas */}
 {form.tooch_number.includes("31P-fis") || form.tooch_number.includes("31P-amf") || form.tooch_number.includes("31P-cof") || form.tooch_number.includes("31P-car") ?
   <>
     {form.tooch_number.includes("31P-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="31P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 31P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: 'url(#31P-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("31-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />   
       </svg>
     }

     {form.tooch_number.includes("31P-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 31P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("31-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("31P-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="31P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 31P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("31-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("31P-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 31P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("31-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="31P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 31P"
       points="0,0 40,0 30,20 10,20"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("31-Palatal")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Bawah */}
 {form.tooch_number.includes("31L-fis") || form.tooch_number.includes("31L-amf") || form.tooch_number.includes("31L-cof") || form.tooch_number.includes("31L-car") ?
   <>
     {form.tooch_number.includes("31L-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="31L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>

         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 31L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: 'url(#31L-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("31-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("31L-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 31L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("31-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("31L-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="31L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 31L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("31-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("31L-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 31L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("31-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="31L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 31L"
       points="30,20 40,40 0,40 10,20"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("31-Labial")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Kanan */}
 {form.tooch_number.includes("31D-fis") || form.tooch_number.includes("31D-amf") || form.tooch_number.includes("31D-cof") || form.tooch_number.includes("31D-car") ?
   <>
     {form.tooch_number.includes("31D-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="31D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 31D"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: 'url(#31D-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("31-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("31D-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 31D"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("31-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("31D-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="31D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 31D"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("31-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }
   
     {form.tooch_number.includes("31D-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 31D"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("31-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="31D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 31D"
       points="30,20 40,0 40,40 35,30"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("31-Distal")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Kiri */}
 {form.tooch_number.includes("31M-fis") || form.tooch_number.includes("31M-amf") || form.tooch_number.includes("31M-cof") || form.tooch_number.includes("31M-car") ?
   <>
     {form.tooch_number.includes("31M-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="31M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 31M"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: 'url(#31M-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("31-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("31M-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 31M"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("31-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("31M-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="31M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 31M"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("31-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("31M-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 31M"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("31-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="31M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 31M"
       points="0,0 5,10 10,20 0,40"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("31-Mesial")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* cfr */}
 {form.tooch_number.includes("31-cfr") &&
   <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
     #
   </text>
 }

 {/* rrx */}
 {form.tooch_number.includes("31-rrx") &&    
 <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 31-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
   v
 </text>
 }
       
 {/* mis */}
 {form.tooch_number.includes("31-mis") &&    
 <>  
   <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
   <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
 </> 
 }
         
</g>
 :
<g>
 {/* Atas */}
 {form.tooch_number.includes("31P-fis") || form.tooch_number.includes("31P-amf") || form.tooch_number.includes("31P-cof") || form.tooch_number.includes("31P-car") ?
   <>
     {form.tooch_number.includes("31P-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="31P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 31P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: 'url(#31P-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("31-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("31P-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 31P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("31-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("31P-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="31P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 31P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("31-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("31P-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 31P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("31-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 31P"
     points="0,0 40,0 30,20 10,20"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("31-Palatal")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Bawah */}
 {form.tooch_number.includes("31L-fis") || form.tooch_number.includes("31L-amf") || form.tooch_number.includes("31L-cof") || form.tooch_number.includes("31L-car") ?
   <>
     {form.tooch_number.includes("31L-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="31L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>

         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 31L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: 'url(#31L-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("31-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("31L-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 31L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("31-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("31L-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="31L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 31L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("31-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("31L-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 31L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("31-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 31L"
     points="30,20 40,40 0,40 10,20"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("31-Labial")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Kanan */}
 {form.tooch_number.includes("31D-fis") || form.tooch_number.includes("31D-amf") || form.tooch_number.includes("31D-cof") || form.tooch_number.includes("31D-car") ?
   <>
     {form.tooch_number.includes("31D-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="31D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 31D"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: 'url(#31D-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("31-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("31D-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 31D"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("31-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("31D-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="31D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 31D"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("31-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }
   
     {form.tooch_number.includes("31D-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 31D"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("31-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 31D"
     points="30,20 40,0 40,40 35,30"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("31-Distal")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Kiri */}
 {form.tooch_number.includes("31M-fis") || form.tooch_number.includes("31M-amf") || form.tooch_number.includes("31M-cof") || form.tooch_number.includes("31M-car") ?
   <>
     {form.tooch_number.includes("31M-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="31M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 31M"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: 'url(#31M-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("31-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("31M-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 31M"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("31-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("31M-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="31M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 31M"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("31-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("31M-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 31M"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("31-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 31M"
     points="0,0 5,10 10,20 0,40"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("31-Mesial")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* cfr */}
 {form.tooch_number.includes("31-cfr") &&
   <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
     #
   </text>
 }
 
 {form.tooch_number.includes("31-rrx") &&    
 <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 31-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
   v
 </text>
 }
 
 {form.tooch_number.includes("31-mis") &&    
 <>  
   <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
   <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
 </> 
 }
</g>
}    
   
</svg>

{form.tooch_number.includes("31-pob-32-miss-pon-pob-33-pob")
  ||form.tooch_number.includes("31-pob-32-miss-pon-pob-33-miss-pon-pob-34-pob")
  ||form.tooch_number.includes("31-pob-32-miss-pon-pob-33-miss-pon-pob-34-miss-pon-pob-35-pob")
  ||form.tooch_number.includes("31-pob-32-miss-pon-pob-33-miss-pon-pob-34-miss-pon-pob-35-miss-pon-pob-36-pob")
  ||form.tooch_number.includes("31-pob-32-miss-pon-pob-33-miss-pon-pob-34-miss-pon-pob-35-miss-pon-pob-36-miss-pon-pob-37-pob")
  ||form.tooch_number.includes("31-pob-32-miss-pon-pob-33-miss-pon-pob-34-miss-pon-pob-35-miss-pon-pob-36-miss-pon-pob-37-miss-pon-pob-38-pob")?
  <>
  {/* meb 85 - 57 */}
  {form.tooch_number.includes("31-pob-32-miss-pon-pob-33-pob") &&
 <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
 <svg width="100%" height="20vh">

                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div>
  }
                  
  {/* meb 85 - 53 */}
  {form.tooch_number.includes("31-pob-32-miss-pon-pob-33-miss-pon-pob-34-pob") &&
  <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">
                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div>
  }
  
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("31-pob-32-miss-pon-pob-33-miss-pon-pob-34-miss-pon-pob-35-pob") &&      
  <div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
  <svg width="100%" height="20vh">

                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
              
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("31-pob-32-miss-pon-pob-33-miss-pon-pob-34-miss-pon-pob-35-miss-pon-pob-36-pob") &&      
 <div style={{ width: "259px", textAlign: "center", position: "absolute", height:"10vh" }}>
 <svg width="100%" height="20vh">

  <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="-24" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="-24" y1="0" x2="-24" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
              
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("31-pob-32-miss-pon-pob-33-miss-pon-pob-34-miss-pon-pob-35-miss-pon-pob-36-miss-pon-pob-37-pob") &&      
 <div style={{ width: "302px", textAlign: "center", position: "absolute", height:"10vh" }}>
 <svg width="100%" height="20vh">

  <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="-68" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="-68" y1="0" x2="-68" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
  
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("31-pob-32-miss-pon-pob-33-miss-pon-pob-34-miss-pon-pob-35-miss-pon-pob-36-miss-pon-pob-37-miss-pon-pob-38-pob") &&      
 <div style={{ width: "345px", textAlign: "center", position: "absolute", height:"10vh" }}>
 <svg width="100%" height="20vh">
  <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="-112" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="-112" y1="0" x2="-112" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
  
  </>
  :
  <></>
  }

{form.tooch_number.includes("31-meb-32-miss-pon-meb-33-meb")
  ||form.tooch_number.includes("31-meb-32-miss-pon-meb-33-miss-pon-meb-34-meb")
  ||form.tooch_number.includes("31-meb-32-miss-pon-meb-33-miss-pon-meb-34-miss-pon-meb-35-meb")
  ||form.tooch_number.includes("31-meb-32-miss-pon-meb-33-miss-pon-meb-34-miss-pon-meb-35-miss-pon-meb-36-meb")
  ||form.tooch_number.includes("31-meb-32-miss-pon-meb-33-miss-pon-meb-34-miss-pon-meb-35-miss-pon-meb-36-miss-pon-meb-37-meb")
  ||form.tooch_number.includes("31-meb-32-miss-pon-meb-33-miss-pon-meb-34-miss-pon-meb-35-miss-pon-meb-36-miss-pon-meb-37-miss-pon-meb-38-meb")?
  <>
  {/* meb 85 - 57 */}
  {form.tooch_number.includes("31-meb-32-miss-pon-meb-33-meb") &&
  <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
  <svg width="100%" height="20vh">

                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div>
  }
                  
  {/* meb 85 - 53 */}
  {form.tooch_number.includes("31-meb-32-miss-pon-meb-33-miss-pon-meb-34-meb") &&
 <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
 <svg width="100%" height="20vh">


                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div>
  }
  
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("31-meb-32-miss-pon-meb-33-miss-pon-meb-34-miss-pon-meb-35-meb") &&      
  <div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
  <svg width="100%" height="20vh">

                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
              
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("31-meb-32-miss-pon-meb-33-miss-pon-meb-34-miss-pon-meb-35-miss-pon-meb-36-meb") &&      
  <div style={{ width: "259px", textAlign: "center", position: "absolute", height:"10vh" }}>
  <svg width="100%" height="20vh">
  <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="-24" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="-24" y1="0" x2="-24" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
              
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("31-meb-32-miss-pon-meb-33-miss-pon-meb-34-miss-pon-meb-35-miss-pon-meb-36-miss-pon-meb-37-meb") &&      
  <div style={{ width: "302px", textAlign: "center", position: "absolute", height:"10vh" }}>
  <svg width="100%" height="20vh">

  <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="-68" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="-68" y1="0" x2="-68" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
  
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("31-meb-32-miss-pon-meb-33-miss-pon-meb-34-miss-pon-meb-35-miss-pon-meb-36-miss-pon-meb-37-miss-pon-meb-38-meb") &&      
  <div style={{ width: "345px", textAlign: "center", position: "absolute", height:"10vh" }}>
  <svg width="100%" height="20vh">
  <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="-112" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="-112" y1="0" x2="-112" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
  
  </>
  :
  <></>
  }        

<div style = {{ textAlign: "center" }}> 31 </div>


{form.tooch_number.includes("31-une") || form.tooch_number.includes("31-non") || form.tooch_number.includes("31-pre") || form.tooch_number.includes("31-ano") || form.tooch_number.includes("31-ipx-poc") || form.tooch_number.includes("31-mis-prd-acr") || form.tooch_number.includes("31-mis-fld-acr") ?
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
  {form.tooch_number.includes("31-une") &&
    <div style={{ position: "absolute", color: "black" }}>UNE</div>
  }
  {form.tooch_number.includes("31-non") &&
    <div style={{ position: "absolute", color: "black" }}>NON</div>
  }      
  {form.tooch_number.includes("31-pre") &&
    <div style={{ position: "absolute", color: "black" }}>PRE</div>
  }      
  {form.tooch_number.includes("31-ano") &&
    <div style={{ position: "absolute", color: "black" }}>ANO</div>
  }
  {form.tooch_number.includes("31-ipx-poc") &&
    <div style={{ position: "absolute", color: "black" }}>IPX</div>
  }
  {form.tooch_number.includes("31-mis-prd-acr") &&
    <div style={{ position: "absolute", color: "black" }}>PRD</div>
  } 
  {form.tooch_number.includes("31-mis-fld-acr") &&
    <div style={{ position: "absolute", color: "black" }}>FLD</div>
  }      
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
</div>
}



  </div> 

  <div style={{ width: "42px", margin: "1px" }}>
 
{form.tooch_number.includes("32-nvt") || form.tooch_number.includes("32-rct") || form.tooch_number.includes("32B-amf-rct")
||form.tooch_number.includes("32P-amf-rct") || form.tooch_number.includes("32M-amf-rct") || form.tooch_number.includes("32D-amf-rct")
||form.tooch_number.includes("32L-amf-rct") || form.tooch_number.includes("32B-cof-rct") || form.tooch_number.includes("32P-cof-rct")
||form.tooch_number.includes("32M-cof-rct") || form.tooch_number.includes("32D-cof-rct") || form.tooch_number.includes("32L-cof-rct") ||
form.tooch_number.includes("32-fmc-rct") || form.tooch_number.includes("32-poc-rct") ?
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%"}}>
<svg width={42} height={42}>
<polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 32B"
  points="21,2 41,32 1,32" 
  style={{ fill: form.tooch_number.includes("32-nvt") ? 'white' : 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('32-Bukal')}
  onMouseLeave={() => setInfoGigi('XX-X')}
/>
</svg>
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingTop: "13px" }}>
</div>
} 
 <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("32-fmc") || form.tooch_number.includes("32-poc") || form.tooch_number.includes("32-ipx-poc") || form.tooch_number.match(/\b32-pob\b/) || form.tooch_number.match(/\b32-miss-pon-pob\b/) || form.tooch_number.match(/\b32-meb\b/) || form.tooch_number.match(/\b32-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
{form.tooch_number.includes("32-poc") || form.tooch_number.includes("32-ipx-poc") || form.tooch_number.match(/\b32-pob\b/) || form.tooch_number.match(/\b32-miss-pon-pob\b/) ?
<g>
 {/* Atas */}
 {form.tooch_number.includes("32P-fis") || form.tooch_number.includes("32P-amf") || form.tooch_number.includes("32P-cof") || form.tooch_number.includes("32P-car") ?
   <>
     {form.tooch_number.includes("32P-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="32P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 32P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: 'url(#32P-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("32-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />   
       </svg>
     }

     {form.tooch_number.includes("32P-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 32P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("32-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("32P-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="32P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 32P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("32-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("32P-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 32P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("32-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="32P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 32P"
       points="0,0 40,0 30,20 10,20"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("32-Palatal")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Bawah */}
 {form.tooch_number.includes("32L-fis") || form.tooch_number.includes("32L-amf") || form.tooch_number.includes("32L-cof") || form.tooch_number.includes("32L-car") ?
   <>
     {form.tooch_number.includes("32L-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="32L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>

         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 32L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: 'url(#32L-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("32-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("32L-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 32L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("32-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("32L-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="32L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 32L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("32-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("32L-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 32L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("32-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="32L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 32L"
       points="30,20 40,40 0,40 10,20"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("32-Labial")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Kanan */}
 {form.tooch_number.includes("32D-fis") || form.tooch_number.includes("32D-amf") || form.tooch_number.includes("32D-cof") || form.tooch_number.includes("32D-car") ?
   <>
     {form.tooch_number.includes("32D-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="32D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 32D"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: 'url(#32D-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("32-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("32D-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 32D"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("32-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("32D-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="32D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 32D"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("32-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }
   
     {form.tooch_number.includes("32D-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 32D"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("32-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="32D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 32D"
       points="30,20 40,0 40,40 35,30"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("32-Distal")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Kiri */}
 {form.tooch_number.includes("32M-fis") || form.tooch_number.includes("32M-amf") || form.tooch_number.includes("32M-cof") || form.tooch_number.includes("32M-car") ?
   <>
     {form.tooch_number.includes("32M-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="32M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 32M"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: 'url(#32M-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("32-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("32M-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 32M"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("32-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("32M-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="32M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 32M"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("32-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("32M-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 32M"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("32-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="32M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 32M"
       points="0,0 5,10 10,20 0,40"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("32-Mesial")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* cfr */}
 {form.tooch_number.includes("32-cfr") &&
   <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
     #
   </text>
 }

 {/* rrx */}
 {form.tooch_number.includes("32-rrx") &&    
 <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 32-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
   v
 </text>
 }
       
 {/* mis */}
 {form.tooch_number.includes("32-mis") &&    
 <>  
   <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
   <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
 </> 
 }
         
</g>
 :
<g>
 {/* Atas */}
 {form.tooch_number.includes("32P-fis") || form.tooch_number.includes("32P-amf") || form.tooch_number.includes("32P-cof") || form.tooch_number.includes("32P-car") ?
   <>
     {form.tooch_number.includes("32P-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="32P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 32P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: 'url(#32P-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("32-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("32P-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 32P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("32-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("32P-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="32P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 32P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("32-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("32P-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 32P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("32-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 32P"
     points="0,0 40,0 30,20 10,20"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("32-Palatal")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Bawah */}
 {form.tooch_number.includes("32L-fis") || form.tooch_number.includes("32L-amf") || form.tooch_number.includes("32L-cof") || form.tooch_number.includes("32L-car") ?
   <>
     {form.tooch_number.includes("32L-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="32L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>

         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 32L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: 'url(#32L-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("32-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("32L-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 32L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("32-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("32L-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="32L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 32L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("32-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("32L-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 32L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("32-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 32L"
     points="30,20 40,40 0,40 10,20"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("32-Labial")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Kanan */}
 {form.tooch_number.includes("32D-fis") || form.tooch_number.includes("32D-amf") || form.tooch_number.includes("32D-cof") || form.tooch_number.includes("32D-car") ?
   <>
     {form.tooch_number.includes("32D-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="32D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 32D"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: 'url(#32D-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("32-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("32D-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 32D"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("32-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("32D-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="32D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 32D"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("32-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }
   
     {form.tooch_number.includes("32D-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 32D"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("32-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 32D"
     points="30,20 40,0 40,40 35,30"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("32-Distal")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Kiri */}
 {form.tooch_number.includes("32M-fis") || form.tooch_number.includes("32M-amf") || form.tooch_number.includes("32M-cof") || form.tooch_number.includes("32M-car") ?
   <>
     {form.tooch_number.includes("32M-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="32M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 32M"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: 'url(#32M-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("32-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("32M-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 32M"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("32-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("32M-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="32M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 32M"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("32-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("32M-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 32M"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("32-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 32M"
     points="0,0 5,10 10,20 0,40"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("32-Mesial")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* cfr */}
 {form.tooch_number.includes("32-cfr") &&
   <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
     #
   </text>
 }
 
 {form.tooch_number.includes("32-rrx") &&    
 <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 32-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
   v
 </text>
 }
 
 {form.tooch_number.includes("32-mis") &&    
 <>  
   <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
   <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
 </> 
 }
</g>
}    
   
</svg>
  
 {form.tooch_number.includes("32-pob-33-miss-pon-pob-34-pob")
  ||form.tooch_number.includes("32-pob-33-miss-pon-pob-34-miss-pon-pob-35-pob")
  ||form.tooch_number.includes("32-pob-33-miss-pon-pob-34-miss-pon-pob-35-miss-pon-pob-36-pob")
  ||form.tooch_number.includes("32-pob-33-miss-pon-pob-34-miss-pon-pob-35-miss-pon-pob-36-miss-pon-pob-37-pob")
  ||form.tooch_number.includes("32-pob-33-miss-pon-pob-34-miss-pon-pob-35-miss-pon-pob-36-miss-pon-pob-37-miss-pon-pob-38-pob")?
  <>
  {/* meb 85 - 57 */}
  {form.tooch_number.includes("32-pob-33-miss-pon-pob-34-pob") &&
 <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
 <svg width="100%" height="20vh">

                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div>
  }
                  
  {/* meb 85 - 53 */}
  {form.tooch_number.includes("32-pob-33-miss-pon-pob-34-miss-pon-pob-35-pob") &&
 <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
 <svg width="100%" height="20vh">


                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div>
  }
  
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("32-pob-33-miss-pon-pob-34-miss-pon-pob-35-miss-pon-pob-36-pob") &&      
  <div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
  <svg width="100%" height="20vh">
                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
              
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("32-pob-33-miss-pon-pob-34-miss-pon-pob-35-miss-pon-pob-36-miss-pon-pob-37-pob") &&      
  <div style={{ width: "259px", textAlign: "center", position: "absolute", height:"10vh" }}>
  <svg width="100%" height="20vh">

  <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="-24" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="-24" y1="0" x2="-24" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
              
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("32-pob-33-miss-pon-pob-34-miss-pon-pob-35-miss-pon-pob-36-miss-pon-pob-37-miss-pon-pob-38-pob") &&      
  <div style={{ width: "302px", textAlign: "center", position: "absolute", height:"10vh" }}>
  <svg width="100%" height="20vh">

  <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="-68" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="-68" y1="0" x2="-68" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
  
  </>
  :
  <></>
  }
          

{form.tooch_number.includes("32-meb-33-miss-pon-meb-34-meb")
||form.tooch_number.includes("32-meb-33-miss-pon-meb-34-miss-pon-meb-35-meb")
||form.tooch_number.includes("32-meb-33-miss-pon-meb-34-miss-pon-meb-35-miss-pon-meb-36-meb")
||form.tooch_number.includes("32-meb-33-miss-pon-meb-34-miss-pon-meb-35-miss-pon-meb-36-miss-pon-meb-37-meb")
||form.tooch_number.includes("32-meb-33-miss-pon-meb-34-miss-pon-meb-35-miss-pon-meb-36-miss-pon-meb-37-miss-pon-meb-38-meb")?
<>
{/* meb 85 - 57 */}
{form.tooch_number.includes("32-meb-33-miss-pon-meb-34-meb") &&
<div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">

                      <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div>
}
                
{/* meb 85 - 53 */}
{form.tooch_number.includes("32-meb-33-miss-pon-meb-34-miss-pon-meb-35-meb") &&
<div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">

                      <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div>
}

{/* meb 85 - 52 */} 
{form.tooch_number.includes("32-meb-33-miss-pon-meb-34-miss-pon-meb-35-miss-pon-meb-36-meb") &&      
<div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">

                      <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div> 
}
            
{/* meb 85 - 52 */} 
{form.tooch_number.includes("32-meb-33-miss-pon-meb-34-miss-pon-meb-35-miss-pon-meb-36-miss-pon-meb-37-meb") &&      
<div style={{ width: "259px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">
<g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="-24" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="-24" y1="0" x2="-24" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div> 
}
            
{/* meb 85 - 52 */} 
{form.tooch_number.includes("32-meb-33-miss-pon-meb-34-miss-pon-meb-35-miss-pon-meb-36-miss-pon-meb-37-miss-pon-meb-38-meb") &&      
<div style={{ width: "302px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">
<g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="-68" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="-68" y1="0" x2="-68" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div> 
}

</>
:
<></>
}
        
 
 <div style = {{ textAlign: "center" }}> 32 </div>
 
 
 {form.tooch_number.includes("32-une") || form.tooch_number.includes("32-non") || form.tooch_number.includes("32-pre") || form.tooch_number.includes("32-ano") || form.tooch_number.includes("32-ipx-poc") || form.tooch_number.includes("32-mis-prd-acr") || form.tooch_number.includes("32-mis-fld-acr") ?
 <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
   {form.tooch_number.includes("32-une") &&
     <div style={{ position: "absolute", color: "black" }}>UNE</div>
   }
   {form.tooch_number.includes("32-non") &&
     <div style={{ position: "absolute", color: "black" }}>NON</div>
   }      
   {form.tooch_number.includes("32-pre") &&
     <div style={{ position: "absolute", color: "black" }}>PRE</div>
   }      
   {form.tooch_number.includes("32-ano") &&
     <div style={{ position: "absolute", color: "black" }}>ANO</div>
   }
   {form.tooch_number.includes("32-ipx-poc") &&
     <div style={{ position: "absolute", color: "black" }}>IPX</div>
   }
   {form.tooch_number.includes("32-mis-prd-acr") &&
     <div style={{ position: "absolute", color: "black" }}>PRD</div>
   } 
   {form.tooch_number.includes("32-mis-fld-acr") &&
     <div style={{ position: "absolute", color: "black" }}>FLD</div>
   }      
 </div>
 :
 <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
 </div>
 }
 
 
 
   </div> 

   <div style={{ width: "42px", margin: "1px" }}>
 
   {form.tooch_number.includes("33-nvt") || form.tooch_number.includes("33-rct") ||form.tooch_number.includes("33B-amf-rct") || form.tooch_number.includes("33P-amf-rct") || form.tooch_number.includes("33M-amf-rct") || form.tooch_number.includes("33D-amf-rct") || form.tooch_number.includes("33L-amf-rct") ||
form.tooch_number.includes("33B-cof-rct") || form.tooch_number.includes("33P-cof-rct") || form.tooch_number.includes("33M-cof-rct") || form.tooch_number.includes("33D-cof-rct") || form.tooch_number.includes("33L-cof-rct") ||
form.tooch_number.includes("33-fmc-rct") ||
form.tooch_number.includes("33-poc-rct") ?
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%"}}>
<svg width={42} height={42}>
<polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 33B"
  points="21,2 41,32 1,32" 
  style={{ fill: form.tooch_number.includes("33-nvt") ? 'white' : 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('33-Bukal')}
  onMouseLeave={() => setInfoGigi('XX-X')}
/>
</svg>
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingTop: "13px" }}>
</div>
        } 
 <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("33-fmc") || form.tooch_number.includes("33-poc") || form.tooch_number.includes("33-ipx-poc") || form.tooch_number.match(/\b33-pob\b/) || form.tooch_number.match(/\b33-miss-pon-pob\b/) || form.tooch_number.match(/\b33-meb\b/) || form.tooch_number.match(/\b33-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
{form.tooch_number.includes("33-poc") || form.tooch_number.includes("33-ipx-poc") || form.tooch_number.match(/\b33-pob\b/) || form.tooch_number.match(/\b33-miss-pon-pob\b/) ?
<g>
 {/* Atas */}
 {form.tooch_number.includes("33P-fis") || form.tooch_number.includes("33P-amf") || form.tooch_number.includes("33P-cof") || form.tooch_number.includes("33P-car") ?
   <>
     {form.tooch_number.includes("33P-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="33P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 33P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: 'url(#33P-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("33-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />   
       </svg>
     }

     {form.tooch_number.includes("33P-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 33P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("33-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("33P-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="33P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 33P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("33-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("33P-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 33P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("33-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="33P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 33P"
       points="0,0 40,0 30,20 10,20"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("33-Palatal")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Bawah */}
 {form.tooch_number.includes("33L-fis") || form.tooch_number.includes("33L-amf") || form.tooch_number.includes("33L-cof") || form.tooch_number.includes("33L-car") ?
   <>
     {form.tooch_number.includes("33L-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="33L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>

         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 33L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: 'url(#33L-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("33-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("33L-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 33L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("33-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("33L-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="33L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 33L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("33-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("33L-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 33L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("33-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="33L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 33L"
       points="30,20 40,40 0,40 10,20"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("33-Labial")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Kanan */}
 {form.tooch_number.includes("33D-fis") || form.tooch_number.includes("33D-amf") || form.tooch_number.includes("33D-cof") || form.tooch_number.includes("33D-car") ?
   <>
     {form.tooch_number.includes("33D-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="33D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 33D"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: 'url(#33D-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("33-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("33D-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 33D"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("33-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("33D-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="33D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 33D"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("33-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }
   
     {form.tooch_number.includes("33D-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 33D"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("33-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="33D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 33D"
       points="30,20 40,0 40,40 35,30"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("33-Distal")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Kiri */}
 {form.tooch_number.includes("33M-fis") || form.tooch_number.includes("33M-amf") || form.tooch_number.includes("33M-cof") || form.tooch_number.includes("33M-car") ?
   <>
     {form.tooch_number.includes("33M-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="33M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 33M"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: 'url(#33M-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("33-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("33M-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 33M"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("33-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("33M-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="33M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 33M"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("33-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("33M-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 33M"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("33-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="33M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 33M"
       points="0,0 5,10 10,20 0,40"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("33-Mesial")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* cfr */}
 {form.tooch_number.includes("33-cfr") &&
   <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
     #
   </text>
 }

 {/* rrx */}
 {form.tooch_number.includes("33-rrx") &&    
 <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 33-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
   v
 </text>
 }
       
 {/* mis */}
 {form.tooch_number.includes("33-mis") &&    
 <>  
   <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
   <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
 </> 
 }
         
</g>
 :
<g>
 {/* Atas */}
 {form.tooch_number.includes("33P-fis") || form.tooch_number.includes("33P-amf") || form.tooch_number.includes("33P-cof") || form.tooch_number.includes("33P-car") ?
   <>
     {form.tooch_number.includes("33P-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="33P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 33P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: 'url(#33P-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("33-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("33P-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 33P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("33-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("33P-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="33P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 33P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("33-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("33P-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 33P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("33-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 33P"
     points="0,0 40,0 30,20 10,20"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("33-Palatal")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Bawah */}
 {form.tooch_number.includes("33L-fis") || form.tooch_number.includes("33L-amf") || form.tooch_number.includes("33L-cof") || form.tooch_number.includes("33L-car") ?
   <>
     {form.tooch_number.includes("33L-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="33L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>

         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 33L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: 'url(#33L-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("33-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("33L-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 33L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("33-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("33L-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="33L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 33L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("33-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("33L-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 33L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("33-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 33L"
     points="30,20 40,40 0,40 10,20"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("33-Labial")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Kanan */}
 {form.tooch_number.includes("33D-fis") || form.tooch_number.includes("33D-amf") || form.tooch_number.includes("33D-cof") || form.tooch_number.includes("33D-car") ?
   <>
     {form.tooch_number.includes("33D-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="33D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 33D"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: 'url(#33D-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("33-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("33D-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 33D"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("33-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("33D-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="33D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 33D"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("33-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }
   
     {form.tooch_number.includes("33D-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 33D"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("33-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 33D"
     points="30,20 40,0 40,40 35,30"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("33-Distal")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Kiri */}
 {form.tooch_number.includes("33M-fis") || form.tooch_number.includes("33M-amf") || form.tooch_number.includes("33M-cof") || form.tooch_number.includes("33M-car") ?
   <>
     {form.tooch_number.includes("33M-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="33M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 33M"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: 'url(#33M-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("33-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("33M-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 33M"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("33-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("33M-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="33M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 33M"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("33-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("33M-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 33M"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("33-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 33M"
     points="0,0 5,10 10,20 0,40"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("33-Mesial")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* cfr */}
 {form.tooch_number.includes("33-cfr") &&
   <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
     #
   </text>
 }
 
 {form.tooch_number.includes("33-rrx") &&    
 <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 33-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
   v
 </text>
 }
 
 {form.tooch_number.includes("33-mis") &&    
 <>  
   <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
   <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
 </> 
 }
</g>
}    
   
</svg>
  
 {form.tooch_number.includes("33-pob-34-miss-pon-pob-35-pob")
  ||form.tooch_number.includes("33-pob-34-miss-pon-pob-35-miss-pon-pob-36-pob")
  ||form.tooch_number.includes("33-pob-34-miss-pon-pob-35-miss-pon-pob-36-miss-pon-pob-37-pob")
  ||form.tooch_number.includes("33-pob-34-miss-pon-pob-35-miss-pon-pob-36-miss-pon-pob-37-miss-pon-pob-38-pob")?
  <>
  {/* meb 85 - 57 */}
  {form.tooch_number.includes("33-pob-34-miss-pon-pob-35-pob") &&
 <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
 <svg width="100%" height="20vh">
                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div>
  }
                  
  {/* meb 85 - 53 */}
  {form.tooch_number.includes("33-pob-34-miss-pon-pob-35-miss-pon-pob-36-pob") &&
  <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
  <svg width="100%" height="20vh">
                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div>
  }
  
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("33-pob-34-miss-pon-pob-35-miss-pon-pob-36-miss-pon-pob-37-pob") &&      
  <div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
  <svg width="100%" height="20vh">
                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
              
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("33-pob-34-miss-pon-pob-35-miss-pon-pob-36-miss-pon-pob-37-miss-pon-pob-38-pob") &&      
 <div style={{ width: "259px", textAlign: "center", position: "absolute", height:"10vh" }}>
 <svg width="100%" height="20vh">

  <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="-24" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="-24" y1="0" x2="-24" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
  
  </>
  :
  <></>
  }
          

{form.tooch_number.includes("33-meb-34-miss-pon-meb-35-meb")
||form.tooch_number.includes("33-meb-34-miss-pon-meb-35-miss-pon-meb-36-meb")
||form.tooch_number.includes("33-meb-34-miss-pon-meb-35-miss-pon-meb-36-miss-pon-meb-37-meb")
||form.tooch_number.includes("33-meb-34-miss-pon-meb-35-miss-pon-meb-36-miss-pon-meb-37-miss-pon-meb-38-meb")?
<>
{/* meb 85 - 57 */}
{form.tooch_number.includes("33-meb-34-miss-pon-meb-35-meb") &&
<div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">


                      <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div>
}
                
{/* meb 85 - 53 */}
{form.tooch_number.includes("33-meb-34-miss-pon-meb-35-miss-pon-meb-36-meb") &&
<div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">

                      <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div>
}

{/* meb 85 - 52 */} 
{form.tooch_number.includes("33-meb-34-miss-pon-meb-35-miss-pon-meb-36-miss-pon-meb-37-meb") &&      
<div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">
                      <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div> 
}
            
{/* meb 85 - 52 */} 
{form.tooch_number.includes("33-meb-34-miss-pon-meb-35-miss-pon-meb-36-miss-pon-meb-37-miss-pon-meb-38-meb") &&      
<div style={{ width: "259px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">
<g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="-24" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="-24" y1="0" x2="-24" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div> 
}

</>
:
<></>
}
        
        
 <div style = {{ textAlign: "center" }}> 33 </div>
 
 
 {form.tooch_number.includes("33-une") || form.tooch_number.includes("33-non") || form.tooch_number.includes("33-pre") || form.tooch_number.includes("33-ano") || form.tooch_number.includes("33-ipx-poc") || form.tooch_number.includes("33-mis-prd-acr") || form.tooch_number.includes("33-mis-fld-acr") ?
 <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
   {form.tooch_number.includes("33-une") &&
     <div style={{ position: "absolute", color: "black" }}>UNE</div>
   }
   {form.tooch_number.includes("33-non") &&
     <div style={{ position: "absolute", color: "black" }}>NON</div>
   }      
   {form.tooch_number.includes("33-pre") &&
     <div style={{ position: "absolute", color: "black" }}>PRE</div>
   }      
   {form.tooch_number.includes("33-ano") &&
     <div style={{ position: "absolute", color: "black" }}>ANO</div>
   }
   {form.tooch_number.includes("33-ipx-poc") &&
     <div style={{ position: "absolute", color: "black" }}>IPX</div>
   }
   {form.tooch_number.includes("33-mis-prd-acr") &&
     <div style={{ position: "absolute", color: "black" }}>PRD</div>
   } 
   {form.tooch_number.includes("33-mis-fld-acr") &&
     <div style={{ position: "absolute", color: "black" }}>FLD</div>
   }      
 </div>
 :
 <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
 </div>
 }
 
 
 
   </div> 

   <div style={{ width: "42px", margin: "1px" }}>

   {form.tooch_number.includes("34O-amf-rct") || form.tooch_number.includes("34O-cof-rct") ||form.tooch_number.includes("34-nvt") || form.tooch_number.includes("34-rct") ||form.tooch_number.includes("34B-amf-rct") || form.tooch_number.includes("34P-amf-rct") || form.tooch_number.includes("34M-amf-rct") || form.tooch_number.includes("34D-amf-rct") || form.tooch_number.includes("34L-amf-rct") ||
form.tooch_number.includes("34B-cof-rct") || form.tooch_number.includes("34P-cof-rct") || form.tooch_number.includes("34M-cof-rct") || form.tooch_number.includes("34D-cof-rct") || form.tooch_number.includes("34L-cof-rct") ||
form.tooch_number.includes("34-fmc-rct") ||
form.tooch_number.includes("34-poc-rct") ?
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%"}}>
<svg width={42} height={42}>
<polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 34B"
  points="21,2 41,32 1,32" 
  style={{ fill: form.tooch_number.includes("34-nvt") ? 'white' : 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('34-Bukal')}
  onMouseLeave={() => setInfoGigi('XX-X')}
/>
</svg>
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingTop: "13px" }}>
</div>
        } 

<svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("34-fmc") || form.tooch_number.includes("34-poc") || form.tooch_number.includes("34-ipx-poc") || form.tooch_number.match(/\b34-pob\b/) || form.tooch_number.match(/\b34-miss-pon-pob\b/) || form.tooch_number.match(/\b34-meb\b/) || form.tooch_number.match(/\b34-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
{form.tooch_number.includes("34-poc") || form.tooch_number.includes("34-ipx-poc") || form.tooch_number.match(/\b34-pob\b/) || form.tooch_number.match(/\b34-miss-pon-pob\b/) ?
<g>
{/* Atas */}
{form.tooch_number.includes("34L-fis") || form.tooch_number.includes("34L-amf") || form.tooch_number.includes("34L-cof") || form.tooch_number.includes("34L-car") ?
  <>
    {form.tooch_number.includes("34L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="34L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 34L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#34L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("34-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("34L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 34L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("34-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("34L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="34L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 34L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("34-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("34L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 34L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("34-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
<svg width="40" height="40">
  <defs>
    <pattern id="34L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
    </pattern>
  </defs>
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 34L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: '#007610', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("34-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
</svg>
}

{/* Bawah */}
{form.tooch_number.includes("34B-fis") || form.tooch_number.includes("34B-amf") || form.tooch_number.includes("34B-cof") || form.tooch_number.includes("34B-car") ?
  <>
    {form.tooch_number.includes("34B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="34B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 34B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#34B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("34-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("34B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 34B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("34-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("34B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="34B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 34B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("34-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("34B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 34B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("34-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="34B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 34B"
      points="10,30 30,30 40,40 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("34-Bukal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kanan */}
{form.tooch_number.includes("34D-fis") || form.tooch_number.includes("34D-amf") || form.tooch_number.includes("34D-cof") || form.tooch_number.includes("34D-car") ?
  <>
    {form.tooch_number.includes("34D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="34D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 34D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#34D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("34-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("34D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 34D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("34-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("34D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="34D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 34D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("34-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("34D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 34D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("34-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="34D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 34D"
      points="30,10 40,0 40,40 30,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("34-Distal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kiri */}
{form.tooch_number.includes("34M-fis") || form.tooch_number.includes("34M-amf") || form.tooch_number.includes("34M-cof") || form.tooch_number.includes("34M-car") ?
  <>
    {form.tooch_number.includes("34M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="34M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 34M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#34M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("34-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("34M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 34M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("34-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("34M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="34M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 34M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("34-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("34M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 34M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("34-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="34M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 34M"
      points="0,0 10,10 10,30 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("34-Mesial")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* center */}
{form.tooch_number.includes("34O-fis") || form.tooch_number.includes("34O-amf") || form.tooch_number.includes("34O-cof") || form.tooch_number.includes("34O-car") ?
  <>
    {form.tooch_number.includes("34O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="34O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 34O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#34O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("34-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("34O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 34O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("34-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("34O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="34O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 34O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("34-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("34O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 34O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("34-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="34O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 34O"
      points="10,10 30,10 30,30 10,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("34-Oklusal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* cfr */}
{form.tooch_number.includes("34-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{/* rrx */}
{form.tooch_number.includes("34-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 34-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
  v
</text>
}
      
{/* mis */}
{form.tooch_number.includes("34-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
        
</g>
:
<g>
{/* Atas */}
{form.tooch_number.includes("34L-fis") || form.tooch_number.includes("34L-amf") || form.tooch_number.includes("34L-cof") || form.tooch_number.includes("34L-car") ?
  <>
    {form.tooch_number.includes("34L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="34L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 34L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#34L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("34-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("34L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 34L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("34-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("34L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="34L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 34L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("34-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("34L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 34L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("34-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 34L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("34-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Bawah */}
{form.tooch_number.includes("34B-fis") || form.tooch_number.includes("34B-amf") || form.tooch_number.includes("34B-cof") || form.tooch_number.includes("34B-car") ?
  <>
    {form.tooch_number.includes("34B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="34B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 34B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#34B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("34-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("34B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 34B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("34-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("34B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="34B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 34B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("34-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("34B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 34B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("34-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 34B"
    points="10,30 30,30 40,40 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("34-Bukal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kanan */}
{form.tooch_number.includes("34D-fis") || form.tooch_number.includes("34D-amf") || form.tooch_number.includes("34D-cof") || form.tooch_number.includes("34D-car") ?
  <>
    {form.tooch_number.includes("34D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="34D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 34D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#34D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("34-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("34D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 34D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("34-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("34D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="34D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 34D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("34-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("34D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 34D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("34-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 34D"
    points="30,10 40,0 40,40 30,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("34-Distal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kiri */}
{form.tooch_number.includes("34M-fis") || form.tooch_number.includes("34M-amf") || form.tooch_number.includes("34M-cof") || form.tooch_number.includes("34M-car") ?
  <>
    {form.tooch_number.includes("34M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="34M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 34M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#34M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("34-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("34M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 34M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("34-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("34M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="34M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 34M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("34-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("34M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 34M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("34-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 34M"
    points="0,0 10,10 10,30 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("34-Mesial")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* center */}
{form.tooch_number.includes("34O-fis") || form.tooch_number.includes("34O-amf") || form.tooch_number.includes("34O-cof") || form.tooch_number.includes("34O-car") ?
  <>
    {form.tooch_number.includes("34O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="34O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 34O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#34O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("34-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("34O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 34O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("34-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("34O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="34O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 34O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("34-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("34O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 34O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("34-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 34O"
    points="10,10 30,10 30,30 10,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("34-Oklusal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* cfr */}
{form.tooch_number.includes("34-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{form.tooch_number.includes("34-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 34-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
  v
</text>
}

{form.tooch_number.includes("34-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
</g>
}      
</svg>

{form.tooch_number.includes("34-pob-35-miss-pon-pob-36-pob")
  ||form.tooch_number.includes("34-pob-35-miss-pon-pob-36-miss-pon-pob-37-pob")
  ||form.tooch_number.includes("34-pob-35-miss-pon-pob-36-miss-pon-pob-37-miss-pon-pob-38-pob")?
  <>
  {/* meb 85 - 57 */}
  {form.tooch_number.includes("34-pob-35-miss-pon-pob-36-pob") &&
 <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
 <svg width="100%" height="20vh">

                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div>
  }
                  
  {/* meb 85 - 53 */}
  {form.tooch_number.includes("34-pob-35-miss-pon-pob-36-miss-pon-pob-37-pob") &&
  <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
  <svg width="100%" height="20vh">

                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div>
  }
  
  {/* meb 85 - 52 */} 
  {form.tooch_number.includes("34-pob-35-miss-pon-pob-36-miss-pon-pob-37-miss-pon-pob-38-pob") &&      
 <div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
 <svg width="100%" height="20vh">

                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
   </g>
  </svg>
  </div> 
  }
         
  
  </>
  :
  <></>
  }
          

{form.tooch_number.includes("34-meb-35-miss-pon-meb-36-meb")
||form.tooch_number.includes("34-meb-35-miss-pon-meb-36-miss-pon-meb-37-meb")
||form.tooch_number.includes("34-meb-35-miss-pon-meb-36-miss-pon-meb-37-miss-pon-meb-38-meb")?
<>
{/* meb 85 - 57 */}
{form.tooch_number.includes("34-meb-35-miss-pon-meb-36-meb") &&
<div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">

                      <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div>
}
                
{/* meb 85 - 53 */}
{form.tooch_number.includes("34-meb-35-miss-pon-meb-36-miss-pon-meb-37-meb") &&
<div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">
                      <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div>
}

{/* meb 85 - 52 */} 
{form.tooch_number.includes("34-meb-35-miss-pon-meb-36-miss-pon-meb-37-miss-pon-meb-38-meb") &&      
<div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">
                      <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div> 
}
       

</>
:
<></>
}
        
        
<div style={{ textAlign: "center" }}>34</div>

{form.tooch_number.includes("34-une") || form.tooch_number.includes("34-non") || form.tooch_number.includes("34-pre") || form.tooch_number.includes("34-ano") || form.tooch_number.includes("34-ipx-poc") || form.tooch_number.includes("34-mis-prd-acr") || form.tooch_number.includes("34-mis-fld-acr") ?
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
{form.tooch_number.includes("34-une") &&
<div style={{ position: "absolute", color: "black" }}>UNE</div>
}
{form.tooch_number.includes("34-non") &&
<div style={{ position: "absolute", color: "black" }}>NON</div>
}      
{form.tooch_number.includes("34-pre") &&
<div style={{ position: "absolute", color: "black" }}>PRE</div>
}      
{form.tooch_number.includes("34-ano") &&
<div style={{ position: "absolute", color: "black" }}>ANO</div>
}
{form.tooch_number.includes("34-ipx-poc") &&
<div style={{ position: "absolute", color: "black" }}>IPX</div>
}
{form.tooch_number.includes("34-mis-prd-acr") &&
<div style={{ position: "absolute", color: "black" }}>PRD</div>
} 
{form.tooch_number.includes("34-mis-fld-acr") &&
<div style={{ position: "absolute", color: "black" }}>FLD</div>
}      
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
</div>
}
  </div>

  <div style={{ width: "42px", margin: "1px" }}>

{form.tooch_number.includes("35O-amf-rct") || form.tooch_number.includes("35O-cof-rct") ||form.tooch_number.includes("35-nvt") || form.tooch_number.includes("35-rct") || form.tooch_number.includes("35B-amf-rct")
|| form.tooch_number.includes("35P-amf-rct") || form.tooch_number.includes("35M-amf-rct") || form.tooch_number.includes("35D-amf-rct")
|| form.tooch_number.includes("35L-amf-rct") ||
form.tooch_number.includes("35B-cof-rct") || form.tooch_number.includes("35P-cof-rct") || form.tooch_number.includes("35M-cof-rct")
|| form.tooch_number.includes("35D-cof-rct") || form.tooch_number.includes("35L-cof-rct") ||
form.tooch_number.includes("35-fmc-rct") ||
form.tooch_number.includes("35-poc-rct") ?
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%"}}>
<svg width={42} height={42}>
<polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 35B"
  points="21,2 41,32 1,32" 
  style={{ fill: form.tooch_number.includes("35-nvt") ? 'white' : 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('35-Bukal')}
  onMouseLeave={() => setInfoGigi('XX-X')}
/>
</svg>
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingTop: "13px" }}>
</div>
        } 

<svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("35-fmc") || form.tooch_number.includes("35-poc") || form.tooch_number.includes("35-ipx-poc") || form.tooch_number.match(/\b35-pob\b/) || form.tooch_number.match(/\b35-miss-pon-pob\b/) || form.tooch_number.match(/\b35-meb\b/) || form.tooch_number.match(/\b35-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
{form.tooch_number.includes("35-poc") || form.tooch_number.includes("35-ipx-poc") || form.tooch_number.match(/\b35-pob\b/) || form.tooch_number.match(/\b35-miss-pon-pob\b/) ?
<g>
{/* Atas */}
{form.tooch_number.includes("35L-fis") || form.tooch_number.includes("35L-amf") || form.tooch_number.includes("35L-cof") || form.tooch_number.includes("35L-car") ?
  <>
    {form.tooch_number.includes("35L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="35L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 35L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#35L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("35-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("35L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 35L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("35-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("35L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="35L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 35L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("35-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("35L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 35L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("35-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
<svg width="40" height="40">
  <defs>
    <pattern id="35L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
    </pattern>
  </defs>
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 35L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: '#007610', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("35-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
</svg>
}

{/* Bawah */}
{form.tooch_number.includes("35B-fis") || form.tooch_number.includes("35B-amf") || form.tooch_number.includes("35B-cof") || form.tooch_number.includes("35B-car") ?
  <>
    {form.tooch_number.includes("35B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="35B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 35B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#35B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("35-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("35B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 35B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("35-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("35B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="35B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 35B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("35-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("35B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 35B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("35-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="35B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 35B"
      points="10,30 30,30 40,40 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("35-Bukal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kanan */}
{form.tooch_number.includes("35D-fis") || form.tooch_number.includes("35D-amf") || form.tooch_number.includes("35D-cof") || form.tooch_number.includes("35D-car") ?
  <>
    {form.tooch_number.includes("35D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="35D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 35D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#35D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("35-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("35D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 35D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("35-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("35D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="35D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 35D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("35-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("35D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 35D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("35-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="35D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 35D"
      points="30,10 40,0 40,40 30,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("35-Distal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kiri */}
{form.tooch_number.includes("35M-fis") || form.tooch_number.includes("35M-amf") || form.tooch_number.includes("35M-cof") || form.tooch_number.includes("35M-car") ?
  <>
    {form.tooch_number.includes("35M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="35M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 35M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#35M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("35-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("35M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 35M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("35-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("35M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="35M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 35M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("35-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("35M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 35M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("35-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="35M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 35M"
      points="0,0 10,10 10,30 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("35-Mesial")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* center */}
{form.tooch_number.includes("35O-fis") || form.tooch_number.includes("35O-amf") || form.tooch_number.includes("35O-cof") || form.tooch_number.includes("35O-car") ?
  <>
    {form.tooch_number.includes("35O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="35O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 35O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#35O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("35-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("35O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 35O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("35-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("35O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="35O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 35O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("35-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("35O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 35O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("35-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="35O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 35O"
      points="10,10 30,10 30,30 10,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("35-Oklusal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* cfr */}
{form.tooch_number.includes("35-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{/* rrx */}
{form.tooch_number.includes("35-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 35-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
  v
</text>
}
      
{/* mis */}
{form.tooch_number.includes("35-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
        
</g>
:
<g>
{/* Atas */}
{form.tooch_number.includes("35L-fis") || form.tooch_number.includes("35L-amf") || form.tooch_number.includes("35L-cof") || form.tooch_number.includes("35L-car") ?
  <>
    {form.tooch_number.includes("35L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="35L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 35L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#35L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("35-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("35L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 35L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("35-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("35L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="35L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 35L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("35-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("35L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 35L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("35-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 35L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("35-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Bawah */}
{form.tooch_number.includes("35B-fis") || form.tooch_number.includes("35B-amf") || form.tooch_number.includes("35B-cof") || form.tooch_number.includes("35B-car") ?
  <>
    {form.tooch_number.includes("35B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="35B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 35B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#35B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("35-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("35B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 35B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("35-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("35B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="35B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 35B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("35-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("35B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 35B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("35-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 35B"
    points="10,30 30,30 40,40 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("35-Bukal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kanan */}
{form.tooch_number.includes("35D-fis") || form.tooch_number.includes("35D-amf") || form.tooch_number.includes("35D-cof") || form.tooch_number.includes("35D-car") ?
  <>
    {form.tooch_number.includes("35D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="35D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 35D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#35D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("35-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("35D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 35D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("35-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("35D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="35D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 35D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("35-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("35D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 35D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("35-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 35D"
    points="30,10 40,0 40,40 30,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("35-Distal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kiri */}
{form.tooch_number.includes("35M-fis") || form.tooch_number.includes("35M-amf") || form.tooch_number.includes("35M-cof") || form.tooch_number.includes("35M-car") ?
  <>
    {form.tooch_number.includes("35M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="35M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 35M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#35M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("35-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("35M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 35M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("35-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("35M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="35M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 35M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("35-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("35M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 35M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("35-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 35M"
    points="0,0 10,10 10,30 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("35-Mesial")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* center */}
{form.tooch_number.includes("35O-fis") || form.tooch_number.includes("35O-amf") || form.tooch_number.includes("35O-cof") || form.tooch_number.includes("35O-car") ?
  <>
    {form.tooch_number.includes("35O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="35O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 35O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#35O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("35-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("35O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 35O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("35-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("35O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="35O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 35O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("35-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("35O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 35O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("35-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 35O"
    points="10,10 30,10 30,30 10,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("35-Oklusal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* cfr */}
{form.tooch_number.includes("35-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{form.tooch_number.includes("35-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 35-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
  v
</text>
}

{form.tooch_number.includes("35-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
</g>
}      
</svg>

  {form.tooch_number.includes("35-pob-36-miss-pon-pob-37-pob")
    ||form.tooch_number.includes("35-pob-36-miss-pon-pob-37-miss-pon-pob-38-pob")?
    <>
    {/* meb 85 - 57 */}
    {form.tooch_number.includes("35-pob-36-miss-pon-pob-37-pob") &&
   <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
   <svg width="100%" height="20vh">

                          <g transform="rotate(180 108.5 6)">
    {/* Garis ke samping dan ke bawah (L-shape) */}
    <line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    </g>
    </svg>
    </div>
    }
                    
    {/* meb 85 - 53 */}
    {form.tooch_number.includes("35-pob-36-miss-pon-pob-37-miss-pon-pob-38-pob") &&
   <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
   <svg width="100%" height="20vh">
                          <g transform="rotate(180 108.5 6)">
    {/* Garis ke samping dan ke bawah (L-shape) */}
    <line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    </g>
    </svg>
    </div>
    }
    
    </>
    :
    <></>
    }
            

  {form.tooch_number.includes("35-meb-36-miss-pon-meb-37-meb")
  ||form.tooch_number.includes("35-meb-36-miss-pon-meb-37-miss-pon-meb-38-meb")?
  <>
  {/* meb 85 - 57 */}
  {form.tooch_number.includes("35-meb-36-miss-pon-meb-37-meb") &&
  <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
  <svg width="100%" height="20vh">

                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  </g>
  </svg>
  </div>
  }
                  
  {/* meb 85 - 53 */}
  {form.tooch_number.includes("35-meb-36-miss-pon-meb-37-miss-pon-meb-38-meb") &&
 <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
 <svg width="100%" height="20vh">
                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  </g>
  </svg>
  </div>
  }

  </>
  :
  <></>
  }
          
          
  <div style={{ textAlign: "center" }}>35</div>

  {form.tooch_number.includes("35-une") || form.tooch_number.includes("35-non") || form.tooch_number.includes("35-pre") || form.tooch_number.includes("35-ano") || form.tooch_number.includes("35-ipx-poc") || form.tooch_number.includes("35-mis-prd-acr") || form.tooch_number.includes("35-mis-fld-acr") ?
  <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
  {form.tooch_number.includes("35-une") &&
  <div style={{ position: "absolute", color: "black" }}>UNE</div>
  }
  {form.tooch_number.includes("35-non") &&
  <div style={{ position: "absolute", color: "black" }}>NON</div>
  }      
  {form.tooch_number.includes("35-pre") &&
  <div style={{ position: "absolute", color: "black" }}>PRE</div>
  }      
  {form.tooch_number.includes("35-ano") &&
  <div style={{ position: "absolute", color: "black" }}>ANO</div>
  }
  {form.tooch_number.includes("35-ipx-poc") &&
  <div style={{ position: "absolute", color: "black" }}>IPX</div>
  }
  {form.tooch_number.includes("35-mis-prd-acr") &&
  <div style={{ position: "absolute", color: "black" }}>PRD</div>
  } 
  {form.tooch_number.includes("35-mis-fld-acr") &&
  <div style={{ position: "absolute", color: "black" }}>FLD</div>
  }      
  </div>
  :
  <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
  </div>
  }
  </div>

  <div style={{ width: "42px", margin: "1px" }}>

  {form.tooch_number.includes("36O-amf-rct") || form.tooch_number.includes("36O-cof-rct") ||form.tooch_number.includes("36-nvt") || form.tooch_number.includes("36-rct") || form.tooch_number.includes("36B-amf-rct")
|| form.tooch_number.includes("36P-amf-rct") || form.tooch_number.includes("36M-amf-rct") || form.tooch_number.includes("36D-amf-rct")
|| form.tooch_number.includes("36L-amf-rct") ||
form.tooch_number.includes("36B-cof-rct") || form.tooch_number.includes("36P-cof-rct") || form.tooch_number.includes("36M-cof-rct")
|| form.tooch_number.includes("36D-cof-rct") || form.tooch_number.includes("36L-cof-rct") ||
form.tooch_number.includes("36-fmc-rct") ||
form.tooch_number.includes("36-poc-rct") ?
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%"}}>
<svg width={42} height={42}>
<polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 36B"
  points="21,2 41,32 1,32" 
  style={{ fill: form.tooch_number.includes("36-nvt") ? 'white' : 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('36-Bukal')}
  onMouseLeave={() => setInfoGigi('XX-X')}
/>
</svg>
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingTop: "13px" }}>
</div>
        } 

<svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("36-fmc") || form.tooch_number.includes("36-poc") || form.tooch_number.includes("36-ipx-poc") || form.tooch_number.match(/\b36-pob\b/) || form.tooch_number.match(/\b36-miss-pon-pob\b/) || form.tooch_number.match(/\b36-meb\b/) || form.tooch_number.match(/\b36-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
{form.tooch_number.includes("36-poc") || form.tooch_number.includes("36-ipx-poc") || form.tooch_number.match(/\b36-pob\b/) || form.tooch_number.match(/\b36-miss-pon-pob\b/) ?
<g>
{/* Atas */}
{form.tooch_number.includes("36L-fis") || form.tooch_number.includes("36L-amf") || form.tooch_number.includes("36L-cof") || form.tooch_number.includes("36L-car") ?
  <>
    {form.tooch_number.includes("36L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="36L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 36L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#36L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("36-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("36L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 36L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("36-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("36L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="36L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 36L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("36-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("36L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 36L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("36-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
<svg width="40" height="40">
  <defs>
    <pattern id="36L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
    </pattern>
  </defs>
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 36L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: '#007610', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("36-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
</svg>
}

{/* Bawah */}
{form.tooch_number.includes("36B-fis") || form.tooch_number.includes("36B-amf") || form.tooch_number.includes("36B-cof") || form.tooch_number.includes("36B-car") ?
  <>
    {form.tooch_number.includes("36B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="36B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 36B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#36B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("36-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("36B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 36B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("36-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("36B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="36B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 36B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("36-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("36B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 36B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("36-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="36B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 36B"
      points="10,30 30,30 40,40 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("36-Bukal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kanan */}
{form.tooch_number.includes("36D-fis") || form.tooch_number.includes("36D-amf") || form.tooch_number.includes("36D-cof") || form.tooch_number.includes("36D-car") ?
  <>
    {form.tooch_number.includes("36D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="36D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 36D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#36D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("36-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("36D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 36D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("36-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("36D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="36D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 36D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("36-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("36D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 36D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("36-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="36D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 36D"
      points="30,10 40,0 40,40 30,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("36-Distal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kiri */}
{form.tooch_number.includes("36M-fis") || form.tooch_number.includes("36M-amf") || form.tooch_number.includes("36M-cof") || form.tooch_number.includes("36M-car") ?
  <>
    {form.tooch_number.includes("36M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="36M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 36M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#36M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("36-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("36M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 36M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("36-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("36M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="36M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 36M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("36-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("36M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 36M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("36-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="36M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 36M"
      points="0,0 10,10 10,30 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("36-Mesial")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* center */}
{form.tooch_number.includes("36O-fis") || form.tooch_number.includes("36O-amf") || form.tooch_number.includes("36O-cof") || form.tooch_number.includes("36O-car") ?
  <>
    {form.tooch_number.includes("36O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="36O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 36O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#36O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("36-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("36O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 36O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("36-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("36O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="36O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 36O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("36-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("36O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 36O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("36-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="36O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 36O"
      points="10,10 30,10 30,30 10,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("36-Oklusal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* cfr */}
{form.tooch_number.includes("36-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{/* rrx */}
{form.tooch_number.includes("36-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 36-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
  v
</text>
}
      
{/* mis */}
{form.tooch_number.includes("36-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
        
</g>
:
<g>
{/* Atas */}
{form.tooch_number.includes("36L-fis") || form.tooch_number.includes("36L-amf") || form.tooch_number.includes("36L-cof") || form.tooch_number.includes("36L-car") ?
  <>
    {form.tooch_number.includes("36L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="36L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 36L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#36L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("36-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("36L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 36L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("36-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("36L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="36L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 36L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("36-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("36L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 36L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("36-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 36L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("36-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Bawah */}
{form.tooch_number.includes("36B-fis") || form.tooch_number.includes("36B-amf") || form.tooch_number.includes("36B-cof") || form.tooch_number.includes("36B-car") ?
  <>
    {form.tooch_number.includes("36B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="36B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 36B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#36B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("36-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("36B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 36B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("36-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("36B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="36B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 36B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("36-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("36B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 36B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("36-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 36B"
    points="10,30 30,30 40,40 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("36-Bukal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kanan */}
{form.tooch_number.includes("36D-fis") || form.tooch_number.includes("36D-amf") || form.tooch_number.includes("36D-cof") || form.tooch_number.includes("36D-car") ?
  <>
    {form.tooch_number.includes("36D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="36D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 36D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#36D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("36-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("36D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 36D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("36-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("36D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="36D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 36D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("36-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("36D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 36D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("36-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 36D"
    points="30,10 40,0 40,40 30,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("36-Distal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kiri */}
{form.tooch_number.includes("36M-fis") || form.tooch_number.includes("36M-amf") || form.tooch_number.includes("36M-cof") || form.tooch_number.includes("36M-car") ?
  <>
    {form.tooch_number.includes("36M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="36M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 36M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#36M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("36-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("36M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 36M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("36-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("36M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="36M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 36M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("36-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("36M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 36M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("36-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 36M"
    points="0,0 10,10 10,30 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("36-Mesial")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* center */}
{form.tooch_number.includes("36O-fis") || form.tooch_number.includes("36O-amf") || form.tooch_number.includes("36O-cof") || form.tooch_number.includes("36O-car") ?
  <>
    {form.tooch_number.includes("36O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="36O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 36O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#36O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("36-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("36O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 36O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("36-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("36O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="36O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 36O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("36-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("36O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 36O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("36-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 36O"
    points="10,10 30,10 30,30 10,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("36-Oklusal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* cfr */}
{form.tooch_number.includes("36-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{form.tooch_number.includes("36-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 36-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
  v
</text>
}

{form.tooch_number.includes("36-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
</g>
}      
</svg>

  {form.tooch_number.includes("36-pob-37-miss-pon-pob-38-pob")?
    <>
    {/* meb 85 - 57 */}
    {form.tooch_number.includes("36-pob-37-miss-pon-pob-38-pob") &&
   <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
   <svg width="100%" height="20vh">

                          <g transform="rotate(180 108.5 6)">
    {/* Garis ke samping dan ke bawah (L-shape) */}
    <line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    </g>
    </svg>
    </div>
    }
    
    </>
    :
    <></>
    }
            

  {form.tooch_number.includes("36-meb-37-miss-pon-meb-38-meb")?
  <>
  {/* meb 85 - 57 */}
  {form.tooch_number.includes("36-meb-37-miss-pon-meb-38-meb") &&
  <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
  <svg width="100%" height="20vh">

                        <g transform="rotate(180 108.5 6)">
  {/* Garis ke samping dan ke bawah (L-shape) */}
  <line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
  </g>
  </svg>
  </div>
  }

  </>
  :
  <></>
  }
          
          
  <div style={{ textAlign: "center" }}>36</div>

  {form.tooch_number.includes("36-une") || form.tooch_number.includes("36-non") || form.tooch_number.includes("36-pre") || form.tooch_number.includes("36-ano") || form.tooch_number.includes("36-ipx-poc") || form.tooch_number.includes("36-mis-prd-acr") || form.tooch_number.includes("36-mis-fld-acr") ?
  <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
  {form.tooch_number.includes("36-une") &&
  <div style={{ position: "absolute", color: "black" }}>UNE</div>
  }
  {form.tooch_number.includes("36-non") &&
  <div style={{ position: "absolute", color: "black" }}>NON</div>
  }      
  {form.tooch_number.includes("36-pre") &&
  <div style={{ position: "absolute", color: "black" }}>PRE</div>
  }      
  {form.tooch_number.includes("36-ano") &&
  <div style={{ position: "absolute", color: "black" }}>ANO</div>
  }
  {form.tooch_number.includes("36-ipx-poc") &&
  <div style={{ position: "absolute", color: "black" }}>IPX</div>
  }
  {form.tooch_number.includes("36-mis-prd-acr") &&
  <div style={{ position: "absolute", color: "black" }}>PRD</div>
  } 
  {form.tooch_number.includes("36-mis-fld-acr") &&
  <div style={{ position: "absolute", color: "black" }}>FLD</div>
  }      
  </div>
  :
  <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
  </div>
  }
  </div>

  <div style={{ width: "42px", margin: "1px" }}>

  {form.tooch_number.includes("37O-amf-rct") || form.tooch_number.includes("37O-cof-rct") ||form.tooch_number.includes("37-nvt") || form.tooch_number.includes("37-rct") || form.tooch_number.includes("37B-amf-rct")
|| form.tooch_number.includes("37P-amf-rct") || form.tooch_number.includes("37M-amf-rct") || form.tooch_number.includes("37D-amf-rct")
|| form.tooch_number.includes("37L-amf-rct") ||
form.tooch_number.includes("37B-cof-rct") || form.tooch_number.includes("37P-cof-rct") || form.tooch_number.includes("37M-cof-rct")
|| form.tooch_number.includes("37D-cof-rct") || form.tooch_number.includes("37L-cof-rct") ||
form.tooch_number.includes("37-fmc-rct") ||
form.tooch_number.includes("37-poc-rct") ?
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%"}}>
<svg width={42} height={42}>
<polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 37B"
  points="21,2 41,32 1,32" 
  style={{ fill: form.tooch_number.includes("37-nvt") ? 'white' : 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('37-Bukal')}
  onMouseLeave={() => setInfoGigi('XX-X')}
/>
</svg>
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingTop: "13px" }}>
</div>
        } 

<svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("37-fmc") || form.tooch_number.includes("37-poc") || form.tooch_number.includes("37-ipx-poc") || form.tooch_number.match(/\b37-pob\b/) || form.tooch_number.match(/\b37-miss-pon-pob\b/) || form.tooch_number.match(/\b37-meb\b/) || form.tooch_number.match(/\b37-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
{form.tooch_number.includes("37-poc") || form.tooch_number.includes("37-ipx-poc") || form.tooch_number.match(/\b37-pob\b/) || form.tooch_number.match(/\b37-miss-pon-pob\b/) ?
<g>
{/* Atas */}
{form.tooch_number.includes("37L-fis") || form.tooch_number.includes("37L-amf") || form.tooch_number.includes("37L-cof") || form.tooch_number.includes("37L-car") ?
  <>
    {form.tooch_number.includes("37L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="37L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 37L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#37L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("37-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("37L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 37L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("37-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("37L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="37L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 37L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("37-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("37L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 37L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("37-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
<svg width="40" height="40">
  <defs>
    <pattern id="37L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
    </pattern>
  </defs>
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 37L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: '#007610', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("37-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
</svg>
}

{/* Bawah */}
{form.tooch_number.includes("37B-fis") || form.tooch_number.includes("37B-amf") || form.tooch_number.includes("37B-cof") || form.tooch_number.includes("37B-car") ?
  <>
    {form.tooch_number.includes("37B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="37B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 37B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#37B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("37-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("37B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 37B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("37-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("37B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="37B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 37B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("37-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("37B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 37B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("37-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="37B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 37B"
      points="10,30 30,30 40,40 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("37-Bukal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kanan */}
{form.tooch_number.includes("37D-fis") || form.tooch_number.includes("37D-amf") || form.tooch_number.includes("37D-cof") || form.tooch_number.includes("37D-car") ?
  <>
    {form.tooch_number.includes("37D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="37D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 37D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#37D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("37-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("37D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 37D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("37-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("37D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="37D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 37D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("37-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("37D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 37D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("37-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="37D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 37D"
      points="30,10 40,0 40,40 30,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("37-Distal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kiri */}
{form.tooch_number.includes("37M-fis") || form.tooch_number.includes("37M-amf") || form.tooch_number.includes("37M-cof") || form.tooch_number.includes("37M-car") ?
  <>
    {form.tooch_number.includes("37M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="37M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 37M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#37M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("37-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("37M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 37M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("37-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("37M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="37M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 37M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("37-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("37M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 37M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("37-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="37M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 37M"
      points="0,0 10,10 10,30 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("37-Mesial")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* center */}
{form.tooch_number.includes("37O-fis") || form.tooch_number.includes("37O-amf") || form.tooch_number.includes("37O-cof") || form.tooch_number.includes("37O-car") ?
  <>
    {form.tooch_number.includes("37O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="37O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 37O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#37O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("37-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("37O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 37O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("37-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("37O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="37O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 37O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("37-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("37O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 37O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("37-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="37O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 37O"
      points="10,10 30,10 30,30 10,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("37-Oklusal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* cfr */}
{form.tooch_number.includes("37-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{/* rrx */}
{form.tooch_number.includes("37-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 37-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
  v
</text>
}
      
{/* mis */}
{form.tooch_number.includes("37-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
        
</g>
:
<g>
{/* Atas */}
{form.tooch_number.includes("37L-fis") || form.tooch_number.includes("37L-amf") || form.tooch_number.includes("37L-cof") || form.tooch_number.includes("37L-car") ?
  <>
    {form.tooch_number.includes("37L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="37L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 37L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#37L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("37-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("37L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 37L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("37-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("37L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="37L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 37L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("37-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("37L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 37L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("37-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 37L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("37-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Bawah */}
{form.tooch_number.includes("37B-fis") || form.tooch_number.includes("37B-amf") || form.tooch_number.includes("37B-cof") || form.tooch_number.includes("37B-car") ?
  <>
    {form.tooch_number.includes("37B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="37B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 37B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#37B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("37-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("37B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 37B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("37-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("37B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="37B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 37B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("37-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("37B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 37B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("37-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 37B"
    points="10,30 30,30 40,40 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("37-Bukal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kanan */}
{form.tooch_number.includes("37D-fis") || form.tooch_number.includes("37D-amf") || form.tooch_number.includes("37D-cof") || form.tooch_number.includes("37D-car") ?
  <>
    {form.tooch_number.includes("37D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="37D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 37D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#37D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("37-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("37D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 37D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("37-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("37D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="37D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 37D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("37-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("37D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 37D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("37-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 37D"
    points="30,10 40,0 40,40 30,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("37-Distal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kiri */}
{form.tooch_number.includes("37M-fis") || form.tooch_number.includes("37M-amf") || form.tooch_number.includes("37M-cof") || form.tooch_number.includes("37M-car") ?
  <>
    {form.tooch_number.includes("37M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="37M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 37M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#37M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("37-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("37M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 37M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("37-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("37M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="37M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 37M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("37-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("37M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 37M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("37-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 37M"
    points="0,0 10,10 10,30 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("37-Mesial")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* center */}
{form.tooch_number.includes("37O-fis") || form.tooch_number.includes("37O-amf") || form.tooch_number.includes("37O-cof") || form.tooch_number.includes("37O-car") ?
  <>
    {form.tooch_number.includes("37O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="37O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 37O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#37O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("37-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("37O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 37O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("37-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("37O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="37O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 37O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("37-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("37O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 37O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("37-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 37O"
    points="10,10 30,10 30,30 10,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("37-Oklusal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* cfr */}
{form.tooch_number.includes("37-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{form.tooch_number.includes("37-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 37-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
  v
</text>
}

{form.tooch_number.includes("37-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
</g>
}      
</svg>

  <div style={{ textAlign: "center" }}>37</div>

  {form.tooch_number.includes("37-une") || form.tooch_number.includes("37-non") || form.tooch_number.includes("37-pre") || form.tooch_number.includes("37-ano") || form.tooch_number.includes("37-ipx-poc") || form.tooch_number.includes("37-mis-prd-acr") || form.tooch_number.includes("37-mis-fld-acr") ?
  <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
  {form.tooch_number.includes("37-une") &&
  <div style={{ position: "absolute", color: "black" }}>UNE</div>
  }
  {form.tooch_number.includes("37-non") &&
  <div style={{ position: "absolute", color: "black" }}>NON</div>
  }      
  {form.tooch_number.includes("37-pre") &&
  <div style={{ position: "absolute", color: "black" }}>PRE</div>
  }      
  {form.tooch_number.includes("37-ano") &&
  <div style={{ position: "absolute", color: "black" }}>ANO</div>
  }
  {form.tooch_number.includes("37-ipx-poc") &&
  <div style={{ position: "absolute", color: "black" }}>IPX</div>
  }
  {form.tooch_number.includes("37-mis-prd-acr") &&
  <div style={{ position: "absolute", color: "black" }}>PRD</div>
  } 
  {form.tooch_number.includes("37-mis-fld-acr") &&
  <div style={{ position: "absolute", color: "black" }}>FLD</div>
  }      
  </div>
  :
  <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
  </div>
  }
  </div>

  <div style={{ width: "42px", margin: "1px" }}>

  {form.tooch_number.includes("38O-amf-rct") || form.tooch_number.includes("38O-cof-rct") ||form.tooch_number.includes("38-nvt") || form.tooch_number.includes("38-rct") || form.tooch_number.includes("38B-amf-rct")
|| form.tooch_number.includes("38P-amf-rct") || form.tooch_number.includes("38M-amf-rct") || form.tooch_number.includes("38D-amf-rct")
|| form.tooch_number.includes("38L-amf-rct") ||
form.tooch_number.includes("38B-cof-rct") || form.tooch_number.includes("38P-cof-rct") || form.tooch_number.includes("38M-cof-rct")
|| form.tooch_number.includes("38D-cof-rct") || form.tooch_number.includes("38L-cof-rct") ||
form.tooch_number.includes("38-fmc-rct") ||
form.tooch_number.includes("38-poc-rct") ?
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%"}}>
<svg width={42} height={42}>
<polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 38B"
  points="21,2 41,32 1,32" 
  style={{ fill: form.tooch_number.includes("38-nvt") ? 'white' : 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('38-Bukal')}
  onMouseLeave={() => setInfoGigi('XX-X')}
/>
</svg>
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingTop: "13px" }}>
</div>
        } 

<svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("38-fmc") || form.tooch_number.includes("38-poc") || form.tooch_number.includes("38-ipx-poc") || form.tooch_number.match(/\b38-pob\b/) || form.tooch_number.match(/\b38-miss-pon-pob\b/) || form.tooch_number.match(/\b38-meb\b/) || form.tooch_number.match(/\b38-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
{form.tooch_number.includes("38-poc") || form.tooch_number.includes("38-ipx-poc") || form.tooch_number.match(/\b38-pob\b/) || form.tooch_number.match(/\b38-miss-pon-pob\b/) ?
<g>
{/* Atas */}
{form.tooch_number.includes("38L-fis") || form.tooch_number.includes("38L-amf") || form.tooch_number.includes("38L-cof") || form.tooch_number.includes("38L-car") ?
  <>
    {form.tooch_number.includes("38L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="38L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 38L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#38L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("38-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("38L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 38L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("38-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("38L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="38L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 38L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("38-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("38L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 38L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("38-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
<svg width="40" height="40">
  <defs>
    <pattern id="38L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
    </pattern>
  </defs>
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 38L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: '#007610', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("38-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
</svg>
}

{/* Bawah */}
{form.tooch_number.includes("38B-fis") || form.tooch_number.includes("38B-amf") || form.tooch_number.includes("38B-cof") || form.tooch_number.includes("38B-car") ?
  <>
    {form.tooch_number.includes("38B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="38B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 38B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#38B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("38-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("38B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 38B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("38-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("38B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="38B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 38B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("38-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("38B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 38B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("38-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="38B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 38B"
      points="10,30 30,30 40,40 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("38-Bukal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kanan */}
{form.tooch_number.includes("38D-fis") || form.tooch_number.includes("38D-amf") || form.tooch_number.includes("38D-cof") || form.tooch_number.includes("38D-car") ?
  <>
    {form.tooch_number.includes("38D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="38D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 38D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#38D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("38-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("38D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 38D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("38-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("38D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="38D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 38D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("38-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("38D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 38D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("38-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="38D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 38D"
      points="30,10 40,0 40,40 30,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("38-Distal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kiri */}
{form.tooch_number.includes("38M-fis") || form.tooch_number.includes("38M-amf") || form.tooch_number.includes("38M-cof") || form.tooch_number.includes("38M-car") ?
  <>
    {form.tooch_number.includes("38M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="38M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 38M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#38M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("38-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("38M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 38M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("38-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("38M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="38M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 38M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("38-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("38M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 38M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("38-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="38M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 38M"
      points="0,0 10,10 10,30 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("38-Mesial")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* center */}
{form.tooch_number.includes("38O-fis") || form.tooch_number.includes("38O-amf") || form.tooch_number.includes("38O-cof") || form.tooch_number.includes("38O-car") ?
  <>
    {form.tooch_number.includes("38O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="38O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 38O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#38O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("38-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("38O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 38O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("38-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("38O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="38O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 38O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("38-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("38O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 38O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("38-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="38O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 38O"
      points="10,10 30,10 30,30 10,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("38-Oklusal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* cfr */}
{form.tooch_number.includes("38-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{/* rrx */}
{form.tooch_number.includes("38-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 38-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
  v
</text>
}
      
{/* mis */}
{form.tooch_number.includes("38-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
        
</g>
:
<g>
{/* Atas */}
{form.tooch_number.includes("38L-fis") || form.tooch_number.includes("38L-amf") || form.tooch_number.includes("38L-cof") || form.tooch_number.includes("38L-car") ?
  <>
    {form.tooch_number.includes("38L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="38L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 38L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#38L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("38-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("38L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 38L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("38-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("38L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="38L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 38L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("38-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("38L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 38L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("38-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 38L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("38-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Bawah */}
{form.tooch_number.includes("38B-fis") || form.tooch_number.includes("38B-amf") || form.tooch_number.includes("38B-cof") || form.tooch_number.includes("38B-car") ?
  <>
    {form.tooch_number.includes("38B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="38B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 38B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#38B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("38-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("38B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 38B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("38-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("38B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="38B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 38B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("38-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("38B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 38B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("38-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 38B"
    points="10,30 30,30 40,40 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("38-Bukal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kanan */}
{form.tooch_number.includes("38D-fis") || form.tooch_number.includes("38D-amf") || form.tooch_number.includes("38D-cof") || form.tooch_number.includes("38D-car") ?
  <>
    {form.tooch_number.includes("38D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="38D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 38D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#38D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("38-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("38D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 38D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("38-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("38D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="38D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 38D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("38-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("38D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 38D"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("38-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 38D"
    points="30,10 40,0 40,40 30,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("38-Distal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kiri */}
{form.tooch_number.includes("38M-fis") || form.tooch_number.includes("38M-amf") || form.tooch_number.includes("38M-cof") || form.tooch_number.includes("38M-car") ?
  <>
    {form.tooch_number.includes("38M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="38M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 38M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#38M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("38-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("38M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 38M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("38-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("38M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="38M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 38M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("38-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("38M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 38M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("38-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 38M"
    points="0,0 10,10 10,30 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("38-Mesial")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* center */}
{form.tooch_number.includes("38O-fis") || form.tooch_number.includes("38O-amf") || form.tooch_number.includes("38O-cof") || form.tooch_number.includes("38O-car") ?
  <>
    {form.tooch_number.includes("38O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="38O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 38O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#38O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("38-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("38O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 38O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("38-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("38O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="38O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 38O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("38-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("38O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 38O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("38-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 38O"
    points="10,10 30,10 30,30 10,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("38-Oklusal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* cfr */}
{form.tooch_number.includes("38-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{form.tooch_number.includes("38-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 38-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
  v
</text>
}

{form.tooch_number.includes("38-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
</g>
}      
</svg>

  <div style={{ textAlign: "center" }}>38</div>

  {form.tooch_number.includes("38-une") || form.tooch_number.includes("38-non") || form.tooch_number.includes("38-pre") || form.tooch_number.includes("38-ano") || form.tooch_number.includes("38-ipx-poc") || form.tooch_number.includes("38-mis-prd-acr") || form.tooch_number.includes("38-mis-fld-acr") ?
  <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
  {form.tooch_number.includes("38-une") &&
  <div style={{ position: "absolute", color: "black" }}>UNE</div>
  }
  {form.tooch_number.includes("38-non") &&
  <div style={{ position: "absolute", color: "black" }}>NON</div>
  }      
  {form.tooch_number.includes("38-pre") &&
  <div style={{ position: "absolute", color: "black" }}>PRE</div>
  }      
  {form.tooch_number.includes("38-ano") &&
  <div style={{ position: "absolute", color: "black" }}>ANO</div>
  }
  {form.tooch_number.includes("38-ipx-poc") &&
  <div style={{ position: "absolute", color: "black" }}>IPX</div>
  }
  {form.tooch_number.includes("38-mis-prd-acr") &&
  <div style={{ position: "absolute", color: "black" }}>PRD</div>
  } 
  {form.tooch_number.includes("38-mis-fld-acr") &&
  <div style={{ position: "absolute", color: "black" }}>FLD</div>
  }      
  </div>
  :
  <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
  </div>
  }
  </div>

</div>
  );
}
