import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaClosedCaptioning, FaTimes} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../../assets/signature/signature logo.png"
import Logo_Batik from "../../../assets/signature/logo-batik.png"
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
import "../Styled.css"
import Swal from "sweetalert2";
import LoaderHome from "../../Loader/LoaderHome"
import swal from "sweetalert";
import React, { useRef } from 'react';
import axios from "axios";
import Select from 'react-select';

export default function ModalRoleAdd(props) {
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(90);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const token = localStorage.getItem("token");
  const [getDataDokter, setGetDataDokter] = useState([]);
  // Untuk Close Proops Data
  const [propsData, setProopsData] = useState()
  const [loading, setLoading] = useState(false);
  const [getOpenAddObat, setGetOpenAddObat] = useState(false);
  const [getData, setGetData] = useState([]);
  const [getDataTreatment, setGetDataTreatment] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const firstNameInputRef = useRef(null);
  const [search,setSearch] = useState("")
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const [form, setForm] = useState({
    id_treatment: "",
  });

  // console.log(form)
  const GetResponseData = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`transaction-service/treatment-one-qtys?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`,fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetData(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataTreatmentOneQty = async () => {
    try {
      // e.preventDefault();
      // setLoading(true)
      const response = await API.get(`/transaction-service/treatments?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`, fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetDataTreatment(response.data.data)
        // setLoading(false)
      }
    } catch (error) {
      // setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  useEffect(() => {
    GetResponseData()
    GetResponseDataTreatmentOneQty()
  }, [])

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  
  const handleInputChange2 = (e) => {
    setForm({
      ...form,
      id_treatment: e.value,
    });
  };

  const deleteById = async (id) => {
    Swal.fire({
      title: 'Apakah Kamu Yakin?',
      text: `Menghapus data ini`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus'
    }).then( async (result) => {
      if(result.isConfirmed) {
        const response =  await API.delete(`transaction-service/treatment-one-qtys/${id}`,fetchParams);
        if (response.data.error == false) {
          GetResponseData()
          swal({
            title: 'Success',
            text: "Your data has been successfully deleted",
            icon: 'success',
            timer: 3000,
            buttons: false
          });
          
        }  
      }
    })
  };

  const openAddField = ()=> {
    setGetOpenAddObat(true)
  }

  const cancelAddField = () => {
    setForm({
      ...form, 
      id_appointment: "",
      id_medical_item: "",
      qty : "",
      description : "",
      price: "",
    });
    setGetOpenAddObat(false)
  }

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true)
    
      // Insert data for login process
      const response = await API.post("transaction-service/treatment-one-qtys/store", {
        id_treatment: form?.id_treatment,
      }, fetchParams);
  
      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        })
        // window.location.reload()
        setForm({
          ...form, 
          id_appointment: "",
          id_medical_item: "",
          qty : "",
          description: "",
        });
        GetResponseData()
        setGetOpenAddObat(false)
        setLoading(false)
      }

    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
      setLoading(false)
    } 
  };

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{fontFamily:"sans-serif",border:"none"}}>
      {loading && <LoaderHome />}
      
      <div style={{ width: "100%", display: "flex", padding: "10px 0px", backgroundColor: "#29B8FF" }}>
        <div style={{flex:"92%", fontSize:"20px",display:"flex",alignItems:"center", paddingLeft:"10px", color:"white", fontWeight:"600"}}>
          List Treatment One Qty
        </div> 
        <div  style={{flex:"8%",fontSize:"30px",display:"flex",alignItems:"center",justifyContent:"center", color:"white"}}>
          <FaTimes onClick={props.onHide} style={{cursor:"pointer"}}/>
        </div> 
      </div>

      <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px",border:"none" }}>
      <Form className="mt-" >
        <Col xl='12' sm='12'> 
          <div>
          <div style={{display:"block", height:"100%", overflowY:"auto",overflowX:"auto"}}>
            <div >
              <table className="table ">
                <thead>
                  <tr style={{backgroundColor:"#E9EFFF"}}>
                    <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>No</th>
                    <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>Nama Treatment</th>
                    {/* <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>Qty</th>
                    <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>Deskripsi</th>
                    <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>Price</th> */}
                    <th style={{fontFamily:"revert",fontSize:"12px",textAlign:"center",  color:"#525252",border:"none", fontFamily:"sans-serif"}}>Setup</th>
                  </tr>
                </thead>
                <tbody>
                {getData.map((user,index) => (
                <tr key={index} style={{fontFamily:"sans-serif", fontSize:"11px", textAlign:"center"}}>
                  <td style={{lineHeight:"2"}}>{page === 1 ? index + 1 : page === 2 ? (index + 1) + 10 : (index + 1) + ( page * 10 )}</td>
                    <td style={{ lineHeight: "2" }}>{user.name}</td>
                    <td style={{lineHeight:"2"}}>
                      <div style={{display:"flex",justifyContent:"center", alignItems:"center", alignContent:"center"}}>
                          <div onClick={() => {deleteById(user?.id)}} style={{ fontSize: "12px", color: "white", backgroundColor: "#B60000", padding: "5px 5px", borderRadius: "3px", cursor: "pointer", border: "none", display:"flex" }}>
                            <FaTrash/>
                          </div>
                      </div>
                  </td> 
                </tr>
              ))}
              {getOpenAddObat && (
                <tr style={{fontFamily:"sans-serif", fontSize:"11px", textAlign:"center"}}>
                <td style={{lineHeight:"3"}}><FaPlus/></td>
                <td style={{ lineHeight: "3", display:"flex", justifyContent:"center" }}>
                  <div style={{width:"50%"}}>
                    <Select
                      // key={selectKey}
                      name="id_treatment"
                      // className="form-select"
                      // aria-label="Default select example"
                      onChange={handleInputChange2}
                      options={getDataTreatment.map(user => ({ value: user.id, label: user.name }))}
                      // value={form?.id_treatment}
                      placeholder="Pilih Treatment"
                    />
                  </div>
                </td>
                {/* <td style={{ lineHeight: "3" }}>
                  <input className="focused" name="qty" min={1} value={form?.qty} onChange={handleChange} style={{width:"100%", border:"1px solid #DCDCDC", textAlign:"center", borderRadius:"5px"}}/>
                </td>
                <td style={{ lineHeight: "3" }}>
                  <input className="focused" name="description"  value={form?.description} onChange={handleChange} style={{width:"100%", border:"1px solid #DCDCDC", textAlign:"center", borderRadius:"5px"}}/>
                </td>
                <td style={{ lineHeight: "3" }}>
                  <input className="focused" name="price"  value={form?.qty === ""? 0 : form?.price*form?.qty} onChange={handleChange} style={{width:"100%", border:"1px solid #DCDCDC", textAlign:"center", borderRadius:"5px"}} disabled/>
                </td> */}
              </tr>
                )
              }            
                </tbody>
              </table>
              
              </div>
            </div>
          </div>
        </Col>
        
        {getOpenAddObat === true? 
        <div style={{display:"flex"}}>
          <div onClick={cancelAddField} className="mr-2" style={{ border: "none", backgroundColor: "#BF0000", color: "white", borderRadius: "3px", fontSize: "12px", padding: "5px 10px", cursor:"pointer" }}>
            <FaTimes style={{ marginRight: "4px", fontSize: "10px" }}/>Cancel
          </div>
          <div onClick={handleSubmit} style={{ border: "none", backgroundColor: "#19BF00", color: "white", borderRadius: "3px", fontSize: "12px", padding: "5px 10px", cursor:"pointer"  }}>
            <FaSave style={{ marginRight: "4px", fontSize: "10px" }}/>Simpan
          </div>
         </div>
          : 
        <div style={{ display: "flex" }}>
          <div onClick={openAddField} style={{ cursor:"pointer", border: "none", backgroundColor: "#667BFF", color: "white", borderRadius: "3px", fontSize: "12px", padding: "5px 10px" }}>
            <FaPlus style={{ marginRight: "5px", fontSize: "10px" }} />Add
          </div>
         </div>
        }
      </Form>
          
        </Modal.Body>
        {/* <Modal.Footer>
          <div style={{ display: "flex" }}>
            <Button onClick={props.onHide} style={{marginRight:"8px"}}>Close</Button>
          </div>
        </Modal.Footer> */}
    </Modal>
    );
}
  