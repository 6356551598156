import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaSpinner, FaTabletAlt, FaTable, FaCheckCircle, FaTimes} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../../assets/signature/signature logo.png"
import Logo_Batik from "../../../assets/signature/logo-batik.png"
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
import Swal from "sweetalert2";
import swal from "sweetalert";
// Modal Role
import { ScaleLoader } from "react-spinners";
import LoaderHome from "../../Loader/LoaderHome"
import LoaderAction from "../../Loader/LoaderAction"

export default function MyVerticallyCenteredModal(props) {

  const navigate = useNavigate()
  const [dataManage, setDatamanage] = useState(false)
  const [pages, setPages] = useState();
  const token = localStorage.getItem("token");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(100);
  const [ascending, setAscending] = useState(0);
  // filter name role users
  const [idRoleDoctor, setIdRoleDoctor] = useState("");
  const [nameRoleDoctor, setNameRoleDoktor] = useState("Doctor");
  const [idRoleNurse, setIdRoleNurse] = useState("");
  const [nameRoleNurse, setNameRoleNurse] = useState("Nurse");
  const [getData, setGetData] = useState([]);
  const [getDataDokter, setGetDataDokter] = useState([]);
  const [getDataNurse, setGetDataNurse] = useState([]);
  const [getDataNurseSecond, setGetDataNurseSecond] = useState([]);
  const [getDataFloor, setGetDataFloor] = useState([]);
  const [getDataRoom, setGetDataRoom] = useState([]);
  const [rows, setRows] = useState(1);
  const [loading, setLoading] = useState(false);
  // Untuk Close Proops Data
  const [propsData, setProopsData] = useState()
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const GetResponseDataNurse = async () => {
    const response = await API.get(`user-service/users?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&id_role=${idRoleNurse}&name_role=${nameRoleNurse}`,fetchParams)
    setGetDataNurse(response.data.data)
  }

  const GetResponseDataFlorr = async () => {
    const response = await API.get(`/transaction-service/rooms?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`,fetchParams)
    setGetDataFloor(response.data.data)
  }

  useEffect(() => {
    GetResponseDataNurse()
    GetResponseDataFlorr()
  },[])

  const [form, setForm] = useState({
    id_doctor: "",
    id_nurse: "",
    id_nurse2: "",
    id_room: ""
  });

  console.log(form)

  const {
    id_doctor,
    id_nurse,
    id_nurse2,
    id_room
  } = form;

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = useMutation(async (e) => {
    try {
      e.preventDefault();
      setLoading(true)
    
      // Insert data for login process
      const response = await API.post("transaction-service/treatment-arrangements/store", {
        id_appointment: `${props?.id}`,
        id_doctor: `${props?.doctoData}`,
        id_nurse: form?.id_nurse,
        id_nurse2: form?.id_nurse2,
        id_room: form?.id_room
      }, fetchParams);
  
      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 3000,
          buttons: false
        })
        props.GetResponseData()
        props.onHide()
        setLoading(false)
      }
    } catch (error) {
      // setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      })
      setLoading(false)
    }
  });

  return (
  <div>
    {loading && <LoaderAction/>}
    <Modal {...props}  size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{fontFamily:"sans-serif"}}>
      <div style={{width: "100%",display:"flex",padding:"10px 0px", backgroundColor:"#29B8FF"}}>
        <div style={{flex:"92%", fontSize:"18px",display:"flex",alignItems:"center", paddingLeft:"10px", color:"white", fontWeight:"600"}}>
          Arrengement
        </div> 
        <div  style={{flex:"8%",fontSize:"30px",display:"flex",alignItems:"center",justifyContent:"center", color:"white"}}>
          <FaTimes onClick={() => setProopsData(props.onHide)} style={{cursor:"pointer"}}/>
        </div> 
      </div>
      <Modal.Body style={{backgroundColor:"", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px"}}>
      <Form onSubmit={(e) => handleSubmit.mutate(e)} className="mt-3" >
          {/* <div className="mt-3" style={{ display: "flex"}}>
            <div style={{flex:"20%", display:"flex", alignItems:"center", fontSize:"15px"}}>
              Pilih Dokter
            </div>
            <div style={{ flex: "80%", display:"flex"}}>
                <p style={{marginRight:"10px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
                <select className="form-select" aria-label="Default select example" name="id_doctor" onChange={handleChange} style={{ textAlign:"", cursor:"pointer", fontSize:"13px"}}>
                  <option value="" hidden>Select</option>
                  {getDataDokter.map((user,index) => (
                    <option value={user?.id} style={{textAlign:""}}>{user?.firstname} {user?.lastname}</option>
                  ))}      
                </select>
              </div>
          </div> */}

          
          <div className="mt-3" style={{ display: "flex"}}>
        <div style={{flex:"20%", display:"flex", alignItems:"center", fontSize:"15px"}}>
          Pilih Perawat 1
        </div>
        <div style={{ flex: "80%", display:"flex"}}>
            <p style={{marginRight:"10px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <select className="form-select" aria-label="Default select example" name="id_nurse" onChange={handleChange} style={{ textAlign:"", cursor:"pointer", fontSize:"13px"}}>
              <option value="" hidden>Select</option>
              {getDataNurse.map((user,index) => (
                <option value={user?.id} style={{textAlign:""}}>{user?.firstname} {user?.lastname}</option>
              ))}      
            </select>
          </div>
          </div>
          
          <div className="mt-3" style={{ display: "flex"}}>
        <div style={{flex:"20%", display:"flex", alignItems:"center", fontSize:"15px"}}>
          Pilih Perawat 2
        </div>
        <div style={{ flex: "80%", display:"flex"}}>
            <p style={{marginRight:"10px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <select className="form-select" aria-label="Default select example" name="id_nurse2" onChange={handleChange} style={{ textAlign:"", cursor:"pointer", fontSize:"13px"}}>
              <option value="" hidden>Select</option>
              {getDataNurse.map((user,index) => (
                <option value={user?.id} style={{textAlign:""}}>{user?.firstname} {user?.lastname}</option>
              ))}      
            </select>
          </div>
          </div>
          
          <div className="mt-3" style={{ display: "flex"}}>
        <div style={{flex:"20%", display:"flex", alignItems:"center", fontSize:"15px"}}>
          Pilih Ruangan
        </div>
        <div style={{ flex: "80%", display:"flex"}}>
            <p style={{marginRight:"10px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <select className="form-select" aria-label="Default select example" name="id_room" onChange={handleChange} style={{ textAlign:"", cursor:"pointer", fontSize:"13px"}}>
              <option value="" hidden>Select</option>
              {getDataFloor.map((user,index) => (
                <option value={user?.id} style={{textAlign:""}}>{user?.name}</option>
              ))}      
            </select>
          </div>
      </div>

          <div style={{ padding: "0px 0px", marginTop: "0px", display:"flex", justifyContent:"end" }}>
            <div>
              <Button className="mt-4" type='submit' color='primary' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px"}}>
                Tambahkan
              </Button>
            </div>
          </div>
        </Form>
        
      </Modal.Body>
      {/* <Modal.Footer>
        <div style={{ display: "flex" }}>
          <Button onClick={props.onHide} style={{marginRight:"8px"}}>Close</Button>
        </div>
      </Modal.Footer> */}
      </Modal>
      </div>
    );
}