import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaClosedCaptioning, FaTimes} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../../assets/signature/signature logo.png"
import Logo_Batik from "../../../assets/signature/logo-batik.png"
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
import "../Styled.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
import { FadeLoader, HashLoader, ScaleLoader } from "react-spinners";
import LoaderAction from "../../Loader/LoaderAction"
import { useRef } from "react";
import axios from "axios";

export default function ModalRoleAdd(props) {
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const [keyword, setKeyword] = useState("");
  const token = localStorage.getItem("token");
  const [getDataRoom, setGetDataRoom] = useState([]);
  const [getDataVendor, setGetDataVendor] = useState([]);
  const [getDataUnit, setGetDataUnit] = useState([]);
  const [getDataCategory, setGetDataCategory] = useState([]);

  // Untuk Close Proops Data
  const [propsData, setProopsData] = useState()
  const [loading, setLoading] = useState(false);

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const fetchDataRef = useRef();

  const fetchData = async () => {
    try {
      setLoading(true);
      const [vendorResponse, unitResponse, categoryResponse] = await axios.all([
        API.get(`transaction-service/inventory-vendors?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`, fetchParams),
        API.get(`transaction-service/inventory-units?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`, fetchParams),
        API.get(`transaction-service/inventory-items?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`, fetchParams),
      ]);

      if (vendorResponse.status === 200 && unitResponse.status === 200 && categoryResponse.status === 200) {
        setGetDataVendor(vendorResponse.data.data);
        setGetDataUnit(unitResponse.data.data);
        setGetDataCategory(categoryResponse.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  };

  useEffect(() => {
    fetchDataRef.current = fetchData;
    // Panggil fetchData saat komponen pertama kali dimuat
    fetchDataRef.current();
  }, []);

  const [form, setForm] = useState({
    // id_room: "",
    id_vendor: "",
    id_unit: "",
    id_category_inventory: "",
    name_inventory: "",
    first_stock_qty: "",
    minimum_stock: "",
    first_stock_expired: "",
    date_stock: "",
    first_stock_price: "",
    note: "",
  });

console.log(form)

  const {
    // id_room,
    id_vendor,
    id_unit,
    id_category_inventory,
    name_inventory,
    first_stock_qty,
    minimum_stock,
    first_stock_expired,
    date_stock,
    first_stock_price,
    note,
  } = form;

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = useMutation(async (e) => {
    try {
      e.preventDefault();
      setLoading(true)
      // Insert data for login process
      const response = await API.post("transaction-service/inventorys/store", {
        // id_room: form?.id_room,
        id_vendor: form?.id_vendor,
        id_unit: form?.id_unit,
        id_category_inventory: form?.id_category_inventory,
        name_inventory: form?.name_inventory,
        first_stock_qty: form?.first_stock_qty,
        minimum_stock: form?.minimum_stock,
        first_stock_expired: form?.first_stock_expired,
        date_stock: form?.date_stock,
        first_stock_price: form?.first_stock_price,
      },fetchParams);
  
      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        })
        props.GetResponseData()
        props.onHide()
        setLoading(false)
      }

    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
      setLoading(false)
    }
  });

  return (
  <div>
    {loading && <LoaderAction/>}
    <Modal {...props}  aria-labelledby="contained-modal-title-vcenter" centered style={{ fontFamily: "sans-serif", border: "none" }}>  
      <div style={{width: "100%",display:"flex",padding:"10px 0px", backgroundColor:"#29B8FF"}}>
        <div style={{flex:"92%", fontSize:"20px",display:"flex",alignItems:"center", paddingLeft:"10px", color:"white", fontWeight:"600"}}>
         Tambah Inventory
        </div> 
        <div  style={{flex:"8%",fontSize:"30px",display:"flex",alignItems:"center",justifyContent:"center", color:"white"}}>
          <FaTimes onClick={() => setProopsData(props.onHide)} style={{cursor:"pointer"}}/>
        </div> 
      </div>
      <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px",border:"none",backgroundImage:"transparent" }}>
      <Form onSubmit={(e) => handleSubmit.mutate(e)} className="mt-3" >
            
        {/* <div className="mt-3" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Pilih Ruangan
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <select className="form-select" aria-label="Default select example" onChange={handleChange} value={id_room}  name="id_room" style={{ textAlign:"", cursor:"pointer"}}>
                <option value="" hidden>Select</option>
                {getDataRoom.map((user,index) => (
                  <option value={user?.id} style={{ textAlign: "" }}>{user?.name}</option>                         
                ))}
            </select>
          </div>
        </div> */}

        <div className="mt-2" style={{display:"flex",justifyContent:"center"}}>
          <div style={{width:"80%"}}>
            <div style={{fontFamily:"sans-serif", fontSize:"12px", fontWeight:"bold"}}>
              Pilih Vendor 
            </div>
            <div>
              <select className="form-select focused" aria-label="Default select example" onChange={handleChange} value={id_vendor}  name="id_vendor" style={{ textAlign:"", cursor:"pointer", border:"1px solid #D1D1D1", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px"}}>
                <option value="" hidden>Select</option>
                {getDataVendor.map((user,index) => (
                  <option value={user?.id} style={{ textAlign: "" }}>{user?.name}</option>                         
                ))}
              </select>        
            </div>
          </div>
        </div>

        <div className="mt-2" style={{display:"flex",justifyContent:"center"}}>
          <div style={{width:"80%"}}>
            <div style={{fontFamily:"sans-serif", fontSize:"12px", fontWeight:"bold"}}>
              Pilih Unit 
            </div>
            <div>
              <select className="form-select focused" aria-label="Default select example" onChange={handleChange} value={id_unit}  name="id_unit" style={{ textAlign:"", cursor:"pointer", border:"1px solid #D1D1D1", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px"}}>
                <option value="" hidden>Select</option>
                {getDataUnit.map((user,index) => (
                  <option value={user?.id} style={{ textAlign: "" }}>{user?.name}</option>                         
                ))}
              </select>        
            </div>
          </div>
        </div>

        <div className="mt-2" style={{display:"flex",justifyContent:"center"}}>
          <div style={{width:"80%"}}>
            <div style={{fontFamily:"sans-serif", fontSize:"12px", fontWeight:"bold"}}>
              Pilih Category 
            </div>
            <div>
              <select className="form-select focused" aria-label="Default select example" onChange={handleChange} value={id_category_inventory}  name="id_category_inventory" style={{ textAlign:"", cursor:"pointer", border:"1px solid #D1D1D1", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px"}}>
                <option value="" hidden>Select</option>
                {getDataCategory.map((user,index) => (
                  <option value={user?.id} style={{ textAlign: "" }}>{user?.name}</option>                         
                ))}
              </select>        
            </div>
          </div>
        </div>

        <div className="mt-2" style={{display:"flex",justifyContent:"center"}}>
          <div style={{width:"80%"}}>
            <div style={{fontFamily:"sans-serif", fontSize:"12px", fontWeight:"bold"}}>
            Nama Barang            
            </div>
            <div>
              <input className="focused" autoFofcus placeholder="Masukan nama barang" type='text' value={name_inventory} name="name_inventory" onChange={handleChange} style={{width:"100%",height:"37.5px", paddingLeft:"0px",border:"1px solid #D1D1D1",borderRadius:"5px"}}/>
            </div>
          </div>
        </div>

        <div className="mt-2" style={{display:"flex",justifyContent:"center"}}>
          <div style={{width:"80%"}}>
            <div style={{fontFamily:"sans-serif", fontSize:"12px", fontWeight:"bold"}}>
            Qty            
            </div>
            <div>
              <input className="focused" autoFofcus placeholder="Masukan jumlah barang" type='number' min={0} value={first_stock_qty} name="first_stock_qty" onChange={handleChange} style={{width:"100%",height:"37.5px", paddingLeft:"0px",border:"1px solid #D1D1D1",borderRadius:"5px"}}/>
            </div>
          </div>
        </div>

        <div className="mt-2" style={{display:"flex",justifyContent:"center"}}>
          <div style={{width:"80%"}}>
            <div style={{fontFamily:"sans-serif", fontSize:"12px", fontWeight:"bold"}}>
            Minimum Stock            
            </div>
            <div>
              <input className="focused" autoFofcus placeholder="Masukan minimum jumlah barang" type='number' min={0} value={minimum_stock} name="minimum_stock" onChange={handleChange} style={{width:"100%",height:"37.5px", paddingLeft:"0px",border:"1px solid #D1D1D1",borderRadius:"5px"}}/>
            </div>
          </div>
        </div>

        <div className="mt-2" style={{display:"flex",justifyContent:"center"}}>
          <div style={{width:"80%"}}>
            <div style={{fontFamily:"sans-serif", fontSize:"12px", fontWeight:"bold"}}>
            Price            
            </div>
            <div>
              <input className="focused" autoFofcus placeholder="Masukan harga" min={0} type='number' value={first_stock_price} name="first_stock_price" onChange={handleChange} style={{width:"100%",height:"37.5px", paddingLeft:"0px",border:"1px solid #D1D1D1",borderRadius:"5px"}}/>
            </div>
          </div>
        </div>

        <div className="mt-2" style={{display:"flex",justifyContent:"center"}}>
          <div style={{width:"80%"}}>
            <div style={{fontFamily:"sans-serif", fontSize:"12px", fontWeight:"bold"}}>
            Note            
            </div>
            <div>
              <input className="focused" autoFofcus placeholder="Catatan" type='text' value={note} name="note" onChange={handleChange} style={{width:"100%",height:"37.5px", paddingLeft:"0px",border:"1px solid #D1D1D1",borderRadius:"5px"}}/>
            </div>
          </div>
        </div>

        <div className="" style={{display:"flex",justifyContent:"center"}}>
          <div style={{width:"80%"}}>
          <Row>
            <Col xl='6' sm='12'>  
              <div className="mt-2">
                  <div style={{fontFamily:"sans-serif", fontSize:"12px", fontWeight:"bold"}}>
                  Expired Date         
                  </div>
                  <div>
                    <input className="expiredDateInventory" type='date' value={first_stock_expired} name="first_stock_expired" onChange={handleChange} style={{width:"100%",height:"37.5px", paddingLeft:"0px",border:"1px solid #D1D1D1",borderRadius:"5px"}}/>
                  </div>
              </div>
            </Col>
            <Col xl='6' sm='12'> 
              <div className="mt-2">
                <div style={{fontFamily:"sans-serif", fontSize:"12px", fontWeight:"bold"}}>
                  Tanggal Masuk Barang          
                  </div>
                  <div>
                    <input className="expiredDateInventory" type='date' value={date_stock} name="date_stock" onChange={handleChange} style={{width:"100%",height:"37.5px", paddingLeft:"0px",border:"1px solid #D1D1D1",borderRadius:"5px"}}/>
                  </div>
                </div> 
                <div className="mt-2" style={{display:"flex",justifyContent:""}}>
              </div>
            </Col>
          </Row>
          </div>
        </div>

        <div className="mt-2" style={{display:"flex",justifyContent:"center"}}>
          <div style={{width:"80%"}}>
            <div style={{ padding: "0px 0px", marginTop: "0px", display:"flex", justifyContent:"end"}}>
              <div>
                <Button  type='submit' color='primary' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px"}}>
                  Tambahkan
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Form>
        
      </Modal.Body>
      {/* <Modal.Footer>
        <div style={{ display: "flex" }}>
          <Button onClick={props.onHide} style={{marginRight:"8px"}}>Close</Button>
        </div>
      </Modal.Footer> */}
   
      
    </Modal>
    </div>
   
    );
}
  