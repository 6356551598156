import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaFileInvoice, FaPrint} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../assets/signature/signature logo.png"
import Logo_Batik from "../../assets/signature/logo-batik.png"
import "bulma/css/bulma.css";
import { useMutation } from "react-query";
import "../../index.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
// Modal Role
import ModalAddMedis from "./ModalAdmin/ModalAdd"
// import ModalUpdateMedis from "./ModalAdminSetup/ModalUpdate"
import { ScaleLoader } from "react-spinners";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import toRupiah from '@develoka/angka-rupiah-js';
import LoaderHome from "../Loader/LoaderHome"
import BackgroundBatik from "../../assets/signature/logo-sidebar-2.jpg"
import BackgroundBatik2 from "../../assets/signature/background-appoinment1.jpg"
import axios from "axios";
import ModalUpdateMedis from "./ModalAdminSetup/ModalUpdate"

export default function Login() {
  // let navigate = useNavigate();
  // useEffect(() => {
  //   if (localStorage.getItem("token") == null || undefined) {
  //       navigate("/");
  //     }
  // }, []);
  document.title = "SDC Apps";
  const navigate = useNavigate()
  const [dataManage, setDatamanage] = useState(false)
  const [pages, setPages] = useState();
  const token = localStorage.getItem("token");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(100);
  const [ascending, setAscending] = useState(0);
  // filter name role users
  const [idRoleDoctor, setIdRoleDoctor] = useState("");
  const [nameRoleDoctor, setNameRoleDoktor] = useState("Doctor");
  const [idRoleNurse, setIdRoleNurse] = useState("");
  const [nameRoleNurse, setNameRoleNurse] = useState("Nurse");
  const [getData, setGetData] = useState([]);
  const [getDataPayment, setGetDataPayment] = useState([]);
  const [getDataAppointmentById, setGetDataAppointmentById] = useState([]);
  const [getDataAppointmentByIdPatient, setGetDataAppointmentByIdPatient] = useState([]);
  const [getDataAppointmentByIdDoctor, setGetDataAppointmentByIdDoctor] = useState([]);
  const [getDataMedicalInvoiceByIdDate, setGetDataMedicalInvoiceByIdDate] = useState([])
  const [getDataMedicalInvoiceById, setGetDataMedicalInvoiceById] = useState([]);
  const [getDataMedicalInvoiceByIdPreceptions, setGetDataMedicalInvoiceByIdPreceptions] = useState([]);
  
  const [getDataFee, setGetDataFee] = useState([]);
  const [getDataTreatmentOneQty, setGetDataTreatmentOneQty] = useState([]);
  const [getDataDetails, setGetDataDetails] = useState([]);
  const [getDataNurse, setGetDataNurse] = useState([]);
  const [getDataNurseSecond, setGetDataNurseSecond] = useState([]);
  const [getDataFloor, setGetDataFloor] = useState([]);
  const [getDataRoom, setGetDataRoom] = useState([]);
  const [rows, setRows] = useState(1);
  const [loading, setLoading] = useState(false);
  // Untuk Close Proops Data
  const [propsData, setProopsData] = useState()
  const [getCode, setGetCode] = useState([]);
  const {id} = useParams()
  const myRef = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
   // List data kirim ke modal proops update
  const [modalRoleUpdate, setModalRoleUpdate] = useState(false);
  const [nameTreatment, setNameTreatment] = useState()
  const [priceDiscount, setDiscount] = useState()
  const [priceAfterDiscount, setPriceAfterDiscount] = useState()
  const [idModal, setIdModal] = useState();
  const [id_doctorModal, setIdDoctor] = useState();
  const [id_roomModal, setIdRoom] = useState();
  const [tooth_numberModal, setToothNumber] = useState();
  const [qtyModal, setQty] = useState();
  const [estimate_priceModal, setEstimatePrice] = useState();
  const [descriptionModal, setDescription] = useState();
  const [getAllResponse, setGetAllResponse] = useState(false)
  
  const handlePrint = useReactToPrint({
    content: () => myRef.current
  });

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };
  
  const [data, setData] = useState([]);
  const fetchDataRef = useRef();

  const fetchData = async () => {
    try {
      setLoading(true);

      // const [feeResponse, appointmentResponse, medicalInvoiceResponse] = await axios.all([
      const [feeResponse, medicalInvoiceResponse, medicalPreceptions, treatmentOneQty] = await axios.all([
        API.get(`transaction-service/fees?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`, fetchParams),
        API.get(`emr-service/medical-invoices/${id}`, fetchParams),
        API.get(`emr-service/medical-prescriptions?id_appointment=${id}`,fetchParams),
        API.get(`transaction-service/treatment-one-qtys?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`, fetchParams)
      ]);

      if (feeResponse.status === 200 &&
        // appointmentResponse.status === 200 &&
        medicalInvoiceResponse.status === 200 &&
        medicalPreceptions.status === 200) {
        setGetDataTreatmentOneQty(treatmentOneQty.data.data)
        setGetDataFee(feeResponse.data.data);
        // setGetDataAppointmentById(appointmentResponse.data.data);
        // setGetDataAppointmentByIdPatient(appointmentResponse.data.data.patient_data);
        // setGetDataAppointmentByIdDoctor(appointmentResponse.data.data.doctor_data.user_detail);
        setGetDataMedicalInvoiceByIdDate(medicalInvoiceResponse.data.data);
        setGetDataMedicalInvoiceById(medicalInvoiceResponse.data.data.medical_treatment_invoices);
        setGetDataMedicalInvoiceByIdPreceptions(medicalPreceptions.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  };

  useEffect(() => {
    fetchDataRef.current = fetchData;
    // Panggil fetchData saat komponen pertama kali dimuat
    fetchDataRef.current();
  }, []);

  const [form, setForm] = useState({
    nama_doktor: "",
    nama_patient: "",
    date_invoice_medis: ""
  });

  useEffect(() => {
    setForm({
      ...form, 
      date_invoice_medis: `${getDataMedicalInvoiceByIdDate?.date_invoice}`,
      nama_doktor: `${getDataMedicalInvoiceByIdDate?.doctor_name}`.toUpperCase() ,
      nama_patient: `${getDataMedicalInvoiceByIdDate?.patient_name}`.toUpperCase() ,
    });
    
  }, [getDataMedicalInvoiceByIdDate])

  
  function formatToRupiah(angka) {
    const formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    });
  
    // Format angka ke format mata uang Rupiah
    const formattedString = formatter.format(angka);
  
    // Menghilangkan simbol "RP" (simbol mata uang) dari hasil format
    const rupiahWithoutSymbol = formattedString.replace('Rp', '');
  
    return rupiahWithoutSymbol.trim(); // Menghilangkan spasi yang mungkin ada
  }
  
  useEffect(() => {
    if (getDataFee && getDataMedicalInvoiceById && getDataMedicalInvoiceByIdPreceptions) {
      
      let totalFee = 0;
      getDataFee.forEach((user) => {
        totalFee += user.price;
      });
  
      // Menghitung total harga dari getDataMedicalInvoiceById
      let totalMedicalInvoice = 0;
      getDataMedicalInvoiceById.forEach((user) => {
        totalMedicalInvoice += 
        user?.price_after_discount === null || user?.price_after_discount === "null" || user?.price_after_discount === undefined || user?.price_after_discount === "undefined" ? 
        user?.price*(getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.id_treatment).length > 0 ? 1 : user?.qty)
        :
        user?.price_after_discount*(getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.id_treatment).length > 0 ? 1 : user?.qty)
      });
  
       // Menghitung total harga dari getDataMedicalInvoiceById
       let totalMedicalInvoicePreceptions = 0;
       getDataMedicalInvoiceByIdPreceptions.forEach((user) => {
        totalMedicalInvoicePreceptions += user.price_item*user.qty;
       });
      
      // Menghitung total harga keseluruhan
      const totalHargaKeseluruhan = totalFee + totalMedicalInvoice + totalMedicalInvoicePreceptions;
  
      // Memformat total harga keseluruhan ke format Rupiah tanpa simbol "RP"
      const hargaDalamRupiah = formatToRupiah(totalHargaKeseluruhan);
  
      // Memperbarui elemen dengan ID "totalPrice" dengan total harga dalam format Rupiah tanpa "RP"
      const totalElement = document.getElementById('totalPrice');
      totalElement.innerText = hargaDalamRupiah;
    }
  }, [getDataFee, getDataMedicalInvoiceById, getDataMedicalInvoiceByIdPreceptions]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = useMutation(async (e) => {
    try {
      e.preventDefault();
      setLoading(true)
    
      // Insert data for login process
      const response = await API.post("transaction-service/sales-invoices/store", {
        // id_appointment: `${props?.id}`,
        // id_patient: `${props?.patientData?.id}`,
        // id_doctor: `${props?.doctoData?.id}`,
        // code: `${getCode?.number}`,
        date: form?.date,
        id_payment: form?.id_payment
      }, fetchParams);
  
      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 3000,
          buttons: false
        })
        setLoading(false)
      }
    } catch (error) {
      // setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      })
      setLoading(false)
    }
  });


  const modalMedisRoleUpdate = (id, qty, price, discount, price_after_discount) => {
    setModalRoleUpdate(true)
    setIdModal(id)
    setQty(qty)
    setEstimatePrice(price)
    setDiscount(discount)
    setPriceAfterDiscount(price_after_discount)
  }

  // Menghitung total harga dari getDataFee
  return (
    <Row style={{border:"1px solid black", }}>
     {loading && <LoaderHome />}
      {modalRoleUpdate && <ModalUpdateMedis  fetchData={fetchDataRef.current}  idModal={idModal} qtyModal={qtyModal} estimate_priceModal={estimate_priceModal} priceDiscount={priceDiscount} priceAfterDiscount={priceAfterDiscount} show={modalRoleUpdate} onHide={() => setModalRoleUpdate(false)} />}
      <Col xl='6' sm='6' style={{ backgroundColor: "", padding: "0px 0px", minHeight:"100vh" ,backgroundImage: `url(${BackgroundBatik})` }}>
      <div style={{display:"flex", justifyContent:"center", padding:"25px", borderRadius:"0px", backgroundColor: "#00409F",boxShadow:"1px 1px 10px solid", fontSize:"20px", fontWeight:"bold", color:"white"}}>Preview Medical Invoice</div>  
      {getDataMedicalInvoiceById.map((user, index) => (
        <div key={index} style={{ backgroundColor: "white", marginTop: "15px", boxShadow: "2px 2px 10px #BFBFBF", borderRadius:"3px"}}>
        <Form onSubmit={(e) => handleSubmit.mutate(e)} style={{ fontFamily: "sans-serif", display: "flex", marginTop: "0px" }} >
          <Col xl='12' sm='12' style={{ padding: "20px 20px 20px 40px", borderRight:"1px solid #E4E4E4" }}>           
            <div className="" style={{ display: "flex"}}>
              <div style={{ flex: "65%", display:"flex"}}>
                <div style={{ width: "100%", outline: "none", padding: "5px 0px" }}>
                  {user?.medical_record.treatment_plan_detail.treatment_data.name}&nbsp;
                  {user?.medical_record.treatment_plan_detail.tooth_number === null || user?.medical_record.treatment_plan_detail.tooth_number === "null" ? "" :
                  getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ?
                  (user?.medical_record.treatment_plan_detail.tooth_number === "18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28  55  54  53  52  51  61  62  63  64  65  85  84  83  82  81  71  72  73  74  75  48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38" && "(RA+RB)") ||
                  (user?.medical_record.treatment_plan_detail.tooth_number === "18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28  48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38" && "(RA+RB)") ||
                  (user?.medical_record.treatment_plan_detail.tooth_number === "55  54  53  52  51  61  62  63  64  65  85  84  83  82  81  71  72  73  74  75" && "(RA+RB)") ||
                  (user?.medical_record.treatment_plan_detail.tooth_number === "18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28  55  54  53  52  51  61  62  63  64  65" && "(RA)") ||
                  (user?.medical_record.treatment_plan_detail.tooth_number === "18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28" && "(RA)") ||
                  (user?.medical_record.treatment_plan_detail.tooth_number === "55  54  53  52  51  61  62  63  64  65" && "(RA)") ||
                  (user?.medical_record.treatment_plan_detail.tooth_number === "85  84  83  82  81  71  72  73  74  75  48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38" && "(RB)") ||
                  (user?.medical_record.treatment_plan_detail.tooth_number === "48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38" && "(RB)") ||
                  (user?.medical_record.treatment_plan_detail.tooth_number === "85  84  83  82  81  71  72  73  74  75" && "(RB)") 
                  :
                  `(${user?.medical_record.treatment_plan_detail.tooth_number})`                          
                  }
                </div>
              </div>
              <div style={{ flex: "35%", display: "flex" }}>
                <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>Rp.</p>
                <div style={{ width: "100%", display:"flex", justifyContent:"end", alignItems:"center"}}>
                  {/* {formatToRupiah(user?.price)} */}
                  {getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? formatToRupiah(user?.price*1) : formatToRupiah(user?.price*user?.qty)}
                </div>
              </div>
            </div>
                
            <div className="" style={{ display: "flex", borderBottom:"1px solid #CECECE"}}>
              <div style={{ flex: "65%", display:"flex"}}>
                <div style={{ width: "100%", outline: "none", padding: "5px 0px" }}>
                  Discount
                </div>
              </div>
              <div style={{ flex: "35%", display: "flex" }}>
                <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>Rp.</p>
                <div style={{ width: "100%", display:"flex", justifyContent:"end", alignItems:"center"}}>
                  {formatToRupiah(user?.discount)} 
                </div>
              </div>
            </div>
                
            <div className="" style={{ display: "flex"}}>
              <div style={{ flex: "65%", display:"flex"}}>
                <div style={{ width: "100%", outline: "none", padding: "5px 0px" }}>
                  Total
                </div>
              </div>
              <div style={{ flex: "35%", display: "flex" }}>
                <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>Rp.</p>
                <div style={{ width: "100%", display:"flex", justifyContent:"end", alignItems:"center"}}>
                {user?.price_after_discount === null || user?.price_after_discount === "null" || user?.price_after_discount === undefined || user?.price_after_discount === "undefined" ? 
                getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? formatToRupiah(user?.price*1) : formatToRupiah(user?.price*user?.qty)
                :
                getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? formatToRupiah(user?.price_after_discount*1)  : formatToRupiah(user?.price_after_discount*user?.qty)
                }
                </div>
              </div>
              </div>
                
              <div className="mt-1" style={{display:"flex", }}>
                <div style={{ width: "100px" }}>
                  <Button onClick={() => modalMedisRoleUpdate(user?.id, user?.qty, user?.price, user?.discount, user?.price_after_discount)} color='warning' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px", color:"#5E5E5E"}}>
                    Discount
                  </Button>
                </div>
              </div>  
              </Col> 
            </Form>
        </div>
         ))}
         
      </Col>    
      <Col xl='6' sm='6' style={{ borderLeft: "50px solid #ECECEC", backgroundColor: "white", padding: "10px 10px" }}>
        {/* TOMBOL PRINT */}
        {/* <button onClick={() => handlePrint()} style={{display:"flex", justifyContent:"",alignItems:"center", border:"none", backgroundColor:"#00B3FF",borderRadius:"3px", fontSize:"12px", padding:"3px 10px"}}><div style={{display:"", height:"", alignItems:"center", marginRight:"5px"}}><FaPrint /></div>Print</button> */}
        <form ref={myRef} style={{width:"100%"}}>
            <div style={{ display: "flex", backgroundColor: "white", opacity: ".9", borderBottom:"4px solid black", paddingBottom:"10px", paddingTop:"50px"}}>
              <div style={{ flex: "30%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img src={Logo_Signature} alt="logo-signature" style={{width:"40%"}}/>
              </div> 
      
              <div style={{ flex: "40%", display:"flex", alignItems:"center",paddingLeft:"20px"}}>
                <div style={{display:"flex", justifyContent:"start", fontFamily:"sans-serif",fontWeight:"bolder", fontSize:"20px"}}>
                  MEDICAL INVOICE
                </div>
              </div> 
            
              <div style={{ flex: "30%", display:"flex", alignItems:"end"}}>
                <div style={{display:"flex", justifyContent:"start", fontFamily:"sans-serif",fontWeight:"bold", fontSize:"10px"}}>
                  No. INVOICE
                {/* <div style={{ marginLeft: "10px", fontWeight: "bold" }}>{getDataAppointmentById?.code}</div>  */}
                </div>
              </div> 
          </div>
        
          <div style={{ backgroundColor: "white", opacity: ".9", fontFamily:"sans-serif"}}>
            <div style={{ padding: "10px 30px" }}>
              <div className="" style={{display: "flex",  height:"2vh"}}>
                <div style={{flex:"25%", display:"flex",alignItems:"center",fontSize:"12px", fontWeight:"bold"}}>
                  TELAH TERIMA DARI
                </div>
                <div style={{ flex: "75%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%"}}>:</p>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%", fontSize: "12px" }}>{form?.nama_patient}</p>
                </div>
              </div>

              <div className="" style={{display: "flex",  height:"2vh"}}>
                <div style={{flex:"25%", display:"flex",alignItems:"center",fontSize:"12px", fontWeight:"bold"}}>
                  NAMA DOKTER
                </div>
                <div style={{ flex: "75%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%"}}>:</p>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%", fontSize: "12px" }}>{form?.nama_doktor}</p>
                </div>
              </div>

              {/* <div className="" style={{display: "flex",  height:"2vh"}}>
                <div style={{flex:"25%", display:"flex",alignItems:"center",fontSize:"12px", fontWeight:"bold"}}>
                  PEMBAYARAN
                </div>
                <div style={{ flex: "75%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%"}}>:</p>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%", fontSize:"12px"}}>TRANSFER</p>
                </div>
              </div>

              <div className="" style={{display: "flex",  height:"2vh"}}>
                <div style={{flex:"25%", display:"flex",alignItems:"center",fontSize:"12px", fontWeight:"bold"}}>
                  BANK
                </div>
                <div style={{ flex: "75%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%"}}>:</p>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%", fontSize:"12px"}}>BCA</p>
                </div>
              </div> */}
             
              <table className="table mt-4">
              <thead>
                <tr style={{backgroundColor:"white", borderTop:"3px solid black", borderBottom:"3px solid black"}}>
                  <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "", border: "none", fontFamily: "sans-serif" }}>NO</th>
                  <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "", border: "none", fontFamily: "sans-serif", }}>KETERANGAN</th>
                  <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "", border: "none", fontFamily: "sans-serif" }}>JUMLAH</th>
                  <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "", border: "none", fontFamily: "sans-serif" }}>HARGA</th>
                </tr>
                </thead>
                <tbody>
                  {getDataFee.map((user,index) => (
                    <tr key={index} style={{ fontFamily: "sans-serif", fontSize: "11px", textAlign: "center" }}>
                      <td style={{ lineHeight: "1", border: "none" }}>{index + 1}</td>
                      <td style={{ lineHeight: "1", border: "none", textAlign: "start" }}>{user?.name.toUpperCase()}</td>
                      <td style={{ lineHeight: "1", border: "none" }}>1</td>
                      <td style={{ lineHeight: "1", border: "none", display: "flex" }}>
                        <div style={{ flex: "10%", display: "flex", justifyContent: "start" }}>
                          Rp
                        </div>
                        <div style={{ flex: "90%", display: "flex", justifyContent: "end" }}>
                          {formatToRupiah(user?.price)}
                        </div>
                      </td>
                    </tr>
                  ))}

                  {getDataMedicalInvoiceById.map((user,index) => (
                      <tr key={index} style={{ fontFamily: "sans-serif", fontSize: "11px", textAlign: "center", }}>
                        <td style={{ lineHeight: "1", border: "none" }}>{getDataFee.length + index + 1}</td>
                        <td style={{ lineHeight: "1", border: "none", textAlign: "start" }}>
                          <div>
                            {user?.medical_record.treatment_plan_detail.treatment_data.name.toUpperCase()}&nbsp;
                            {user?.medical_record.treatment_plan_detail.tooth_number === null || user?.medical_record.treatment_plan_detail.tooth_number === "null" ? "" :
                            getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ?
                            (user?.medical_record.treatment_plan_detail.tooth_number === "18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28  55  54  53  52  51  61  62  63  64  65  85  84  83  82  81  71  72  73  74  75  48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38" && "(RA+RB)") ||
                            (user?.medical_record.treatment_plan_detail.tooth_number === "18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28  48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38" && "(RA+RB)") ||
                            (user?.medical_record.treatment_plan_detail.tooth_number === "55  54  53  52  51  61  62  63  64  65  85  84  83  82  81  71  72  73  74  75" && "(RA+RB)") ||
                            (user?.medical_record.treatment_plan_detail.tooth_number === "18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28  55  54  53  52  51  61  62  63  64  65" && "(RA)") ||
                            (user?.medical_record.treatment_plan_detail.tooth_number === "18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28" && "(RA)") ||
                            (user?.medical_record.treatment_plan_detail.tooth_number === "55  54  53  52  51  61  62  63  64  65" && "(RA)") ||
                            (user?.medical_record.treatment_plan_detail.tooth_number === "85  84  83  82  81  71  72  73  74  75  48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38" && "(RB)") ||
                            (user?.medical_record.treatment_plan_detail.tooth_number === "48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38" && "(RB)") ||
                            (user?.medical_record.treatment_plan_detail.tooth_number === "85  84  83  82  81  71  72  73  74  75" && "(RB)") 
                            :
                            `(${user?.medical_record.treatment_plan_detail.tooth_number})`                          
                            }
                            
                            </div>
                          {user?.medical_record.diagnose === null || user?.medical_record.diagnose === "null"? "" : 
                          <div style={{fontWeight:"800"}}>
                              DIAGNOSA : {user?.medical_record.diagnose.toUpperCase()}
                          </div>
                          }
                        </td>
                        <td style={{ lineHeight: "1", border: "none" }}>{getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? 1 : user?.qty}</td>

                        {user?.discount === null || user?.discount === "null" || user?.discount === undefined || user?.discount === "undefined" || user?.discount === "0" || user?.discount === 0 ? 
                        <td style={{ lineHeight: "1", border:"none", display: "flex"}}>
                          <div style={{flex:"10%",display:"flex", justifyContent:"start" }}>
                            Rp
                          </div>
                          <div style={{flex:"90%",display:"flex", justifyContent:"end"  }}>
                          {user?.price_after_discount === null || user?.price_after_discount === "null" || user?.price_after_discount === undefined || user?.price_after_discount === "undefined" ? 
                            getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? formatToRupiah(user?.price*1) : formatToRupiah(user?.price*user?.qty)
                            :
                            getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? formatToRupiah(user?.price_after_discount*1)  : formatToRupiah(user?.price_after_discount*user?.qty)
                            }                          </div>
                        </td>
                        :
                        <td style={{ lineHeight: "1", border: "none", textAlign: "start" }}>
                          <div style={{display:"flex"}}>
                            <div style={{flex:"10%",display:"flex", justifyContent:"start" }}>
                              Rp
                            </div>
                            <div style={{flex:"90%",display:"flex", justifyContent:"end"  }}>
                              <s>{formatToRupiah(user?.price)}</s>
                            </div>
                          </div>
                          <div style={{display:"flex"}}>
                            <div style={{flex:"10%",display:"flex", justifyContent:"start" }}>
                              Rp
                            </div>
                            <div style={{flex:"90%",display:"flex", justifyContent:"end"  }}>
                            {user?.price_after_discount === null || user?.price_after_discount === "null" || user?.price_after_discount === undefined || user?.price_after_discount === "undefined" ? 
                            getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? formatToRupiah(user?.price*1) : formatToRupiah(user?.price*user?.qty)
                            :
                            getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? formatToRupiah(user?.price_after_discount*1)  : formatToRupiah(user?.price_after_discount*user?.qty)
                            }                            </div>
                          </div>
                        </td>
                        }

                        {/* 
                        <td style={{ lineHeight: "1", border:"none", display: "flex"}}>
                          <div style={{flex:"10%",display:"flex", justifyContent:"start" }}>
                            Rp
                          </div>
                          <div style={{flex:"90%",display:"flex", justifyContent:"end"  }}>
                            {user?.price_after_discount === null || user?.price_after_discount === "null" || user?.price_after_discount === undefined || user?.price_after_discount === "undefined" ? formatToRupiah(user?.price) : formatToRupiah(user?.price_after_discount) }
                          </div>
                        </td> 
                        */}
                      </tr>
                  ))} 

                  {getDataMedicalInvoiceByIdPreceptions.map((user,index) => (
                      <tr key={index} style={{ fontFamily: "sans-serif", fontSize: "11px", textAlign: "center" }}>
                        <td style={{ lineHeight: "1", border: "none" }}>{getDataFee.length + getDataMedicalInvoiceById.length + (index + 1)}</td>
                        <td style={{ lineHeight: "1", border: "none", textAlign: "start" }}>
                          <div>
                          {user?.name_item.toUpperCase()}
                            </div>
                            {user?.description === null ?
                              <></>
                              :
                              <div style={{fontWeight:"800"}}>
                                DESKRIPSI : {user?.description.toUpperCase()}
                              </div>
                            }
                          </td>
                          <td style={{ lineHeight: "1", border: "none" }}>{user?.qty}</td>
                        <td style={{ lineHeight: "1", border: "none", display: "flex" }}>
                          <div style={{ flex: "10%", display: "flex", justifyContent: "start" }}>
                            Rp
                          </div>
                          <div style={{ flex: "90%", display: "flex", justifyContent: "end" }}>
                            {formatToRupiah(user?.price_item*user?.qty)}
                          </div>
                        </td>
                      </tr>
                  ))}  
                </tbody>
              </table>
              <div style={{display:"flex", justifyContent:"end"}}>
                <div style={{flex:"50%"}}>
                  
                </div>
                <div style={{ flex: "50%", borderTop: "3px solid black", borderBottom: "3px solid black", display: "flex", fontSize: "12px", fontWeight: "bold", padding: "5px 5px", borderRight: "none", borderLeft: "none" }}>
                  <div style={{ flex: "50%" }}>
                    TOTAL
                  </div>
                  <div style={{ flex: "50%", display: "flex", alignItems: "center" }}>
                    <div style={{ flex: "10%", display: "flex", justifyContent: "start" }}>
                      Rp
                    </div>
                    <div style={{ flex: "90%", display: "flex", justifyContent: "end" }}>
                      <span id="totalPrice">0</span> 
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-2" style={{display:"flex", justifyContent:"end"}}>
                <div style={{flex:"50%"}}>
                  
                </div>
                <div style={{ flex: "50%", display: "flex", fontSize:"12px", fontWeight:"bold", padding:"5px 5px"}}>
                  <div>
                    TANGERANG, {form?.date_invoice_medis.split('-')[2]}&nbsp;
                      {form?.date_invoice_medis.split('-')[1] === "01" ? "JANUARI" : "" ||
                      form?.date_invoice_medis.split('-')[1] === "02" ? "FEBRUARI" : "" ||
                      form?.date_invoice_medis.split('-')[1] === "03" ? "MARET" : "" ||
                      form?.date_invoice_medis.split('-')[1] === "04" ? "APRIL" : "" ||  
                      form?.date_invoice_medis.split('-')[1] === "05" ? "MEI" : "" ||
                      form?.date_invoice_medis.split('-')[1] === "06" ? "JUNI" : "" ||
                      form?.date_invoice_medis.split('-')[1] === "07" ? "JULI" : "" ||
                      form?.date_invoice_medis.split('-')[1] === "08" ? "AGUSTUS" : "" ||
                      form?.date_invoice_medis.split('-')[1] === "09" ? "SEPTEMBER" : "" ||
                      form?.date_invoice_medis.split('-')[1] === "10" ? "OKTOBER" : "" ||
                      form?.date_invoice_medis.split('-')[1] === "11" ? "NOVEMBER" : "" ||
                      form?.date_invoice_medis.split('-')[1] === "12" ? "DESEMBER" : ""}&nbsp; 
                    {form?.date_invoice_medis.split('-')[0]}
                    
                  </div>
                </div>

              </div>

              <div className="" style={{marginTop:"100PX",display:"flex", justifyContent:"end"}}>
                <div style={{flex:"50%"}}>
                  
                </div>
                <div style={{ flex: "50%", display: "flex", fontSize:"12px", fontWeight:"bold", padding:"5px 5px"}}>
                  <div>
                    {form?.nama_doktor}
                  </div>
                </div>

              </div>
                 
            </div>
          </div>

        </form>
      </Col>
    </Row>
   
  );
}
