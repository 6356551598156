import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaUndoAlt, FaCheckCircle, FaMedkit} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../assets/signature/signature logo.png"
import Logo_Batik from "../../assets/signature/logo-batik.png"
import "bulma/css/bulma.css";
import { useMutation } from "react-query";
import "../../index.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
// Modal Role
import ModalAddMedis from "./ModalAdmin/ModalAdd"
import ModalUpdateMedis from "./ModalAdmin/ModalUpdate"
import { ScaleLoader } from "react-spinners";
import LoaderHome from "../Loader/LoaderHome"
import { FormatRupiah } from "@arismun/format-rupiah"
import { NumberFormat } from 'react-number-format'
import { useParams } from "react-router-dom";
import SignatureCanvas from 'react-signature-canvas'
import toRupiah from '@develoka/angka-rupiah-js';
import LogoFinish from "../../assets/signature/logo-finish.png"


function formatRupiah(amount) {
  return `Rp ${new Intl.NumberFormat('id-ID').format(amount)}`;
}

export default function Login() {
  const { id } = useParams()
  
  // let navigate = useNavigate();
  // useEffect(() => {
  //   if (localStorage.getItem("token") == null || undefined) {
  //       navigate("/");
  //     }
  // }, []);
  document.title = "SDC Apps";
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(1);
  const [search, setSearch] = useState(1);
  const token = localStorage.getItem("token");
  const [getDataDokter, setGetDataDokter] = useState([]);
  // Untuk Close Proops Data
  const [propsData, setProopsData] = useState()
  const [getData, setGetData] = useState([]);
  const [getDataPasien, setGetDataPasien] = useState([]);
  const [getDataAppointmentById, setGetDataAppointmentById] = useState([]);
  const [getDataAppointmentByIdTreatmentPlan, setGetDataAppointmentByIdTreatmentPlan] = useState([]);
  const [getDataAppointmentByIdTreatmentPlanStatusButton, setGetDataAppointmentByIdTreatmentPlanStatusButton] = useState([]);
  const [getDataMedicalRecord, setGetDataMedicalRecord] = useState([]);
  const [getDataAppointmentByIdDoctor, setGetDataAppointmentByIdDoctor] = useState([]);
  const [getDataAppointmentByIdPatient, setGetDataAppointmentByIdPatient] = useState([]);
  const [getDataDetailsApproval, setGetDataDetailsApproval] = useState([]);
  const [getDataTreatmentById, setGetDataTreatmentById] = useState([]);
  
  const [getDataApprovalById, setGetDataApprovalById] = useState([]);
  const [getCode, setGetCodeObat] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getDataTreatment, setGetDataTreatment] = useState([]);
  const [idStoreMedicarRecord, setIdStoreMedicalRecord] = useState([]);
  const [getDataNurse, setGetDataNurse] = useState([]);
  const [idRoleNurse, setIdRoleNurse] = useState("");
  const [nameRoleNurse, setNameRoleNurse] = useState("Nurse");
  const [idRoleDokter, setIdRoleDokter] = useState("");
  const [nameRoleDoctor, setNameRoleDoktor] = useState("Doctor");
  const [getDataRoom, setGetDataRoom] = useState([]);
  const [rows, setRows] = useState(1);
  const [keyword, setKeyword] = useState("");
  // const [formTreatment, setFormTreatment] = useState({ assistants: [] });
  const [inputList, setInputList] = useState([{ id_doctor: "", id_treatment: "", id_room: "", tooth_number: "", qty: "", treatment_price_min: "", treatment_price_max: "", estimate_price: "", description: "", assistants: [null, null, null, null] }]);
  const [dataItem, setGetDataItem] = useState([])
  const [inputListObat, setInputListObat] = useState([{ id_medical_item: "", qty: "", description: "", price: "" }]);
  const [inputListObat2, setInputListObat2] = useState([]);

  // modal role add 
  const [idPatientMedicalRecord, setIdModalMedicalRecordPatient] = useState();
  const [idPatientMedicalRecordName, setIdModalMedicalRecordPatientName] = useState();
  const [modalMedicalRecord, setModalMedicalRecord] = useState(false);
  
  let [cBase64, setBase64] = useState();
  let [cBase64name, setBase64Name] = useState();
  let canvasRef;
  const [getDataFee, setGetDataFee] = useState([]);


  // Menghapus nilai null dalam array assistant di inputList
  const cleanedArrayInputList = inputList.map((obj) => ({
    ...obj,
    assistants: obj.assistants.filter((assistant) => assistant !== null)
  }));

  let fetchParams = {
    headers: { "Authorization": `${token}`, "Content-Type": "application/json" }
  };


  const GetResponseDataFee = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`transaction-service/fees?page=${page}&limit=${limit}&ascending=${0}`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataFee(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }


  const GetResponseDataApproval = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`emr-service/treatment-plans/${getDataApprovalById}`, fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetDataDetailsApproval(response.data.data.details)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataApprovalByTreatmentPlan = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`emr-service/treatment-plans/${getDataAppointmentByIdTreatmentPlan}`, fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetDataDetailsApproval(response.data.data.details)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }


  const GetResponseDataObat = async () => {
    try {
      // e.preventDefault();
      // setLoading(true)
      const response = await API.get(`emr-service/medical-items?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`, fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetDataItem(response.data.data)
        // setLoading(false)
      }
    } catch (error) {
      // setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataByIdAppointment = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`transaction-service/appointments/${id}`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataAppointmentById(response.data.data)
        setGetDataAppointmentByIdPatient(response.data.data.patient_data)
        setGetDataAppointmentByIdDoctor(response.data.data.doctor_data.user_detail)
        setGetDataAppointmentByIdTreatmentPlan(response.data.data.treatment_plan_data === null ? "" : response.data.data.treatment_plan_data.id)
        setGetDataAppointmentByIdTreatmentPlanStatusButton(response.data.data.treatment_plan_data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataDokter = async () => {
    try {
      // e.preventDefault();
      const response = await API.get(`user-service/users?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&id_role=${idRoleDokter}&name_role=${nameRoleDoctor}`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataDokter(response.data.data)
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }
  
  const GetResponseDataNurse = async () => {
    try {
      // e.preventDefault();
      const response = await API.get(`user-service/users?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&id_role=${idRoleNurse}&name_role=${nameRoleNurse}`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataNurse(response.data.data)
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataTreatment = async () => {
    try {
      // e.preventDefault();
      const response = await API.get(`transaction-service/treatments?page=${1}&limit=${10000}&ascending=${1}`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataTreatment(response.data.data)
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataRoom = async () => {
    try {
      // e.preventDefault();
      const response = await API.get(`/transaction-service/rooms?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataRoom(response.data.data)
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const [form, setForm] = useState({
    id_appointment: "",
    id_doctor: "",
    id_patient: "",
    name_patient_firstname: "",
    name_patient_lastname: "",
    name_doctor_firstname: "",
    name_doctor_lastname: "",
    anamnesa: "",
    date_plan: "",
    estimate_price_total: 0, // Inisialisasi dengan nilai 0
    estimate_price_obat: 0,
    time: ""
  });

  function calculateTotalPrice() {
    let totalPrice = 0;
    inputList.forEach((item) => {
      const estimatePrice = parseFloat(item.estimate_price);
      if (!isNaN(estimatePrice)) {
        totalPrice += estimatePrice;
      }
    });
    return totalPrice;
  }

  function calculateTotalPriceObat() {
    let totalPrice = 0;
    inputListObat.forEach((item) => {
      const estimatePrice = parseFloat(item.estimate_price);
      if (!isNaN(estimatePrice)) {
        totalPrice += estimatePrice;
      }
    });
    return totalPrice;
  }
  
  // console.log(form)

  const {
    id_appointment,
    id_doctor,
    id_patient,
    anamnesa,
    date_plan,
    estimate_price_total,
    estimate_price_obat,

    time
  } = form;

  // untuk auto merender ketika trigger 
  useEffect(() => {
    GetResponseDataByIdAppointment()
    GetResponseDataFee()
    GetResponseDataDokter()
    // GetResponseDataTreatment() 
    // GetResponseDataNurse()
    // GetResponseDataRoom()
    // GetResponseDataPatient()
  }, [])
  
  useEffect(() => {
    if (getDataApprovalById.length !== 0) {
      GetResponseDataByIdAppointment()
      GetResponseDataApproval()
    }
  }, [getDataApprovalById])
  
  useEffect(() => {
    if (getDataAppointmentByIdTreatmentPlan.length !== 0) {
      GetResponseDataApprovalByTreatmentPlan()
    }
  }, [getDataAppointmentByIdTreatmentPlan])
  
  useEffect(() => {
    setForm({
      ...form,
      id_appointment: `${id}`,
      id_doctor: `${getDataAppointmentById?.id_doctor}`,
      id_patient: `${getDataAppointmentById?.id_patient}`,
      name_patient_firstname: `${getDataAppointmentByIdPatient?.first_name}`,
      name_patient_lastname: `${getDataAppointmentByIdPatient?.last_name}`,
      name_doctor_firstname: `${getDataAppointmentByIdDoctor?.firstname}`,
      name_doctor_lastname: `${getDataAppointmentByIdDoctor?.lastname}`,
      anamnesa: `${getDataAppointmentById?.anamnesa}`,
      date_plan: `${getDataAppointmentById?.date}`,
      time: `${getDataAppointmentById?.time}`,
    })
  }, [getDataAppointmentById, getDataAppointmentByIdDoctor, getDataAppointmentByIdPatient])
  
  useEffect(() => {
    // Pastikan getDataAppointmentById sudah ada data sebelum mengakses id_doctor
    if (getDataAppointmentById) {
      // Dapatkan id_doctor dari getDataAppointmentById
      const newIdDoctor = getDataAppointmentById?.id_doctor;

      // Update nilai id_doctor pada inputList
      setInputList((prevInputList) => {
        // Copy inputList yang lama dan ubah id_doctor
        const updatedInputList = prevInputList.map((item) => ({
          ...item,
          id_doctor: newIdDoctor
        }));
        return updatedInputList;
      });
    }
  }, [getDataAppointmentById]);
  
  useEffect(() => {
    if (getDataTreatmentById) {
      // Dapatkan treatment_price_min dan treatment_price_max dari getDataTreatmentById
      const newEstimatePricemin = getDataTreatmentById?.treatment_price_min;
      const newEstimatePricemax = getDataTreatmentById?.treatment_price_max;
  
      // Perbarui nilai estimate_price, treatment_price_min, dan treatment_price_max untuk setiap elemen di inputList
      setInputList((prevInputList) => {
        const updatedInputList = prevInputList.map((item, index) => ({
          ...item,
          estimate_price: newEstimatePricemin,
          treatment_price_min: newEstimatePricemin,
          treatment_price_max: newEstimatePricemax,
        }));
        return updatedInputList;
      });
    }
  }, [getDataTreatmentById]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = useMutation(async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      
      // Hitung total harga estimasi
      const totalPrice = calculateTotalPrice();
  
      // Masukkan total harga ke dalam objek form
      const updatedForm = {
        ...form,
        estimate_price_total: totalPrice,
      };
  
      // Insert data for login process
      const response = await API.post("emr-service/treatment-plans/store", {
        ...updatedForm,
        treatments: cleanedArrayInputList,
      }, fetchParams);
  
      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        });
      
        setGetDataApprovalById(response.data.id)
        setLoading(false);
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
      setLoading(false);
    }
  });

  const handleInputChangeObat = (e, index, fieldName) => {
    const value = e.target.value;
  
    // Copy inputListObat agar tidak merusak data asli
    const updatedInputListObat = [...inputListObat];
  
    // Update hanya elemen yang sedang aktif
    updatedInputListObat[index] = {
      ...updatedInputListObat[index],
      [fieldName]: value,
    };
  
    // Jika fieldName adalah 'id_medical_item', perbarui harga obat yang sesuai
    if (fieldName === 'id_medical_item') {
      // Dapatkan harga obat yang sesuai dari dataItem
      const selectedObat = dataItem.find((obat) => obat.id === value);
      if (selectedObat) {
        updatedInputListObat[index].price = selectedObat.price;
      }
    }
  
    // Hitung ulang total harga estimasi obat
    let totalObatPrice = 0;
    updatedInputListObat.forEach((itemObat) => {
      const obatPrice = parseFloat(itemObat.price);
      if (!isNaN(obatPrice)) {
        totalObatPrice += obatPrice;
      }
    });
  
    // Update total harga estimasi obat dalam form
    setForm({
      ...form,
      estimate_price_obat: totalObatPrice,
    });
  
    setInputListObat(updatedInputListObat); // Update inputListObat
  };
  
  

  // handle input change
  const handleInputChange = (e, index, fieldName) => {
    const value = e.target.value;

    // Copy inputList agar tidak merusak data asli
    const updatedInputList = [...inputList];

    // Update hanya elemen yang sedang aktif
    updatedInputList[index] = {
      ...updatedInputList[index],
      [fieldName]: value,
    };
 
    // Update properti tambahan untuk menyimpan nilai-nilai ini secara terpisah
    if (fieldName === 'id_treatment') {
      // Dapatkan treatment_price_min dan treatment_price_max dari treatment yang dipilih
      const selectedTreatment = getDataTreatment.find((treatment) => treatment.id === value);
      if (selectedTreatment) {
        updatedInputList[index].treatment_price_min = selectedTreatment.treatment_price_min;
        updatedInputList[index].treatment_price_max = selectedTreatment.treatment_price_max;
        updatedInputList[index].estimate_price = selectedTreatment.treatment_price_min; // Atur estimate_price sesuai treatment_price_min
      }
    }

    // Hitung ulang estimate_price_total berdasarkan perubahan dalam inputList
    let totalPrice = 0;
    updatedInputList.forEach((item) => {
      const estimatePrice = parseFloat(item.estimate_price);
      if (!isNaN(estimatePrice)) {
        totalPrice += estimatePrice;
      }
    });


    setInputList(updatedInputList); // Update inputList
    setForm({
      ...form,
      estimate_price_total: totalPrice, // Update estimate_price_total
    });
  };


  const [assistantCount, setAssistantCount] = useState(4);
  const handleAssistantChange = (e, index, assistantIndex) => {
    const newList = [...inputList];
    const { name, value } = e.target;

    // Update the selected assistant for the specific index
    newList[index].assistants[assistantIndex] = value;

    // Update state inputList with the updated copy
    setInputList(newList);
  };

  const handleAddClick = () => {
    // Increment the assistant count and add a new item to inputList
    setAssistantCount(assistantCount + 1);
  
    setInputList([
      ...inputList,
      {
        id_doctor: "",
        id_treatment: "",
        id_room: "",
        tooth_number: "",
        qty: "",
        treatment_price_min: "",
        treatment_price_max: "",
        estimate_price: "",
        description: "",
        assistants: Array(assistantCount + 1).fill(null), // Perbarui jumlah asisten
      },
    ]);
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const updatedInputList = [...inputList];
    updatedInputList.splice(index, 1);

    // Hitung ulang total harga estimasi
    let totalPrice = 0;
    updatedInputList.forEach((item) => {
      const estimatePrice = parseFloat(item.estimate_price);
      if (!isNaN(estimatePrice)) {
        totalPrice += estimatePrice;
      }
    });

    // Perbarui total harga estimasi dalam form
    setForm({
      ...form,
      estimate_price_total: totalPrice,
    });

    setInputList(updatedInputList);
  };

  const navigateHome = () => {
    navigate('/appointments')
  }

  const navigateAppointment = () => {
    Swal.fire(
      'Selamat Anda Berhasil Melakukan Treatment Pasien',
      'Silahkan Lanjutkan Proses',
      'success'
    )
    navigate('/appointments')
  }

  

  const RejectTreatment = async (id) => {
    Swal.fire({
      title: `<div>Apakah Kamu Yakin Akan Reject Treatment?</div>`,
      text: ``,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Reject'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await API.post(`emr-service/treatment-plan-details/reject/${id}`,
          {
            filename: "signature_approve.png",
            content: cBase64,
          }
          , fetchParams);
        if (response.data.error == false) {
          // GetResponseData()
          swal({
            title: 'Success',
            text: "Anda Telah Berhasil Reject Treatment",
            icon: 'success',
            timer: 3000,
            buttons: false
          });
          GetResponseDataApprovalByTreatmentPlan()

        }
      }
    })
  };

  const approvedItems = getDataDetailsApproval.filter(item => item.patient_approval === 1);

  const ApproveTreatment = async (id, description) => {
    Swal.fire({
      title: `<div>Klik Approve Untuk Melanjutkan!</div>`,
      text: ``,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Approve'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await API.post(`emr-service/treatment-plan-details/approve/${id}`,
          {
            filename: "signature_approve.png",
            content: cBase64,
          }
          , fetchParams);
        if (response.data.error == false) {
          setIdStoreMedicalRecord(id)
          GetResponseDataApprovalByTreatmentPlan()
          handleSubmitObat(id, description)
          swal({
            title: 'Success',
            text: "Appointment Anda Telah Berhasil Di proses",
            icon: 'success',
            timer: 3000,
            buttons: false
          });
        }
      }
    })
  };

  function formatToRupiah(number) {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0, // Set minimum fraction digits to 0
      maximumFractionDigits: 0, // Set maximum fraction digits to 0
    }).format(number);
  }

  // Calculate the total estimated price for approved items
  const totalEstimatedPrice = approvedItems.reduce(
    (accumulator, item) => accumulator + item.estimate_price,
    0
  );
  
  
  const handleAddClickObat = () => {
    setInputListObat([
      ...inputListObat,
      {
        id_medical_item: "",
        qty: "",
        description: "",
        price: ""
      },
    ]);
  };

  // handle click event of the Remove button
  const handleRemoveClickObat = index => {
    const updatedInputListObat = [...inputListObat];
    updatedInputListObat.splice(index, 1);

    // Hitung ulang total harga estimasi obat
    let totalObatPrice = 0;
    updatedInputListObat.forEach((itemObat) => {
      const obatPrice = parseFloat(itemObat.price);
      if (!isNaN(obatPrice)) {
        totalObatPrice += obatPrice;
      }
    });

    // Perbarui total harga estimasi obat dalam form
    setForm({
      ...form,
      estimate_price_obat: totalObatPrice,
    });

    setInputListObat(updatedInputListObat);
  };

const handleSubmitObat = async (id,description) => {
  try {
    const nonEmptyObatItems = inputListObat.filter(item => item.id_medical_item === "");

    // setLoading(true)
    // Insert data for login process
    const response = await API.post("emr-service/medical-records/store", {
      id_patient: `${getDataAppointmentByIdPatient?.id}`,
      diagnose: description,
      id_treatment_plan_detail: id,
      medical_prescriptions :  inputListObat.length === 0 || inputListObat[0].id_medical_item === "" ? [] : inputListObat
    }, fetchParams);

    // Checking process
    if (response?.status === 200) {
      swal({
        title: 'Success',
        text: response.data.message,
        icon: 'success',
        timer: 5000,
        buttons: false
      })

      setInputListObat([])
      // window.location.reload()
   
      // setLoading(false
    }

  } catch (error) {
    // setLoading(false)
    swal({
      title: 'Failed',
      text: `${error.response.data.message}`,
      icon: 'error',
      timer: 3000,
      buttons: false
    });
  }
 
};


  let totalDataFeePrice = 0;

  // Calculate the total price from getDataFee array
  getDataFee.forEach((user) => {
    totalDataFeePrice += user?.price || 0; 
  });

  const handleSubmitMedicalInvoice = async () => {
    Swal.fire({
      title: `<div>Apakah Anda Telah Selesai Menyelesaikan Treatment?</div>`,
      text: ``,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Selesai'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await API.post("emr-service/medical-invoices/store", {
          id_appointment: `${id}`
        }, fetchParams);

        if (response.data.error == false) {
        Swal.fire(
            'Selamat Anda Berhasil Melakukan Treatment Pasien',
            'Silahkan Lanjutkan Proses',
            'success'
          )
          navigate('/appointments')
        }
      }
    })
  };


  const viewModalMedicalRecord = (id_patient,patient_name) => {
    setIdModalMedicalRecordPatient(id_patient)
    setIdModalMedicalRecordPatientName(patient_name)
    setModalMedicalRecord(true)
  }
  
  return (
    <div style={{ backgroundColor: "white", marginTop: "15px", marginLeft: "10px", marginRight: "10px", boxShadow: "2px 2px 10px #BFBFBF" }}>
      {loading && <LoaderHome />}

      <div style={{ paddingLeft: "0px", width: "100%", borderBottom: "5px solid #EEEEEE", display: "flex", padding: "10px 0px", fontFamily:"sans-serif" }}>
      <div style={{fontSize:"16px",display:"flex", aunlocklignItems:"center", paddingLeft:"10px", color:"#001F8B"}}>
         <div onClick={navigateHome} style={{display:"flex" , marginRight:"10px",fontSize: "11px", color: "white", backgroundColor: "#ABC1FF", padding: "5px 10px", borderRadius: "3px", cursor: "pointer", border: "none", borderTopLeftRadius:"5px", borderBottomLeftRadius:"5px"}}>
          <div style={{display:"flex",alignItems:"center", height:"100%", marginRight:"3px"}}>
            <FaMedkit style={{display:"flex",alignItems:"center", fontSize:"15px"}}/>
          </div>
          <div style={{display:"flex", alignItems:"center", height:"100%", fontSize:"12px"}}>
            Data Medis
          </div>
        </div>
        {/* <div style={{flex:"50%",fontSize:"16px",display:"flex", alignItems:"center", paddingLeft:"10px", color:"#001F8B"}}>
          <FaAddressBook style={{marginRight:"5px"}}/>Treatment Plan
        </div> */}
        
        <div>
          Tambah Treatment Plan 
        </div>
      </div>
    </div>
 
    {getDataAppointmentByIdTreatmentPlanStatusButton === null ? 
    <Form onSubmit={(e) => handleSubmit.mutate(e)} style={{padding:"15px 50px 30px 50px", fontFamily: "sans-serif" }} >
      <div className="mt-4" style={{ display: "flex"}}>
        <div style={{flex:"50%", display:"flex",alignItems:"center", marginRight:"20px"}}>
          <div style={{width: "100%"}}>
            <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Dokter</div>
                <input disabled type="text"  value={form?.name_doctor_firstname + ` ` + form?.name_doctor_lastname} name="id_doctor" onChange={handleChange}  style={{backgroundColor:"#DEDEDE", width:"100%", border:"1px solid #C9C9C9", outline:"none", height:"35px", borderRadius:"5px", paddingLeft:"5px", appearance:"textfield"}}/>
              {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
            </div>
            </div>
            <div style={{flex:"50%", display:"flex",alignItems:"center"}}>
          <div style={{ width: "100%" }}>
            <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Pasien</div>
                <input disabled type="text" value={form?.name_patient_firstname + ` ` + form?.name_patient_lastname} name="id_patient" onChange={handleChange}  style={{ backgroundColor:"#DEDEDE", width:"100%", border:"1px solid #C9C9C9", outline:"none", height:"35px", borderRadius:"5px", paddingLeft:"5px"}}/>
              {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
            </div>
          </div>
        </div>

        <div className="mt-4" style={{ display: "flex"}}>
        <div style={{flex:"50%", display:"flex",alignItems:"center", marginRight:"20px"}}>
          <div style={{width: "100%"}}>
            <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Tanggal Dan Waktu Appointment</div>
            <input autoFofcus type='text' disabled
              value={
              `${form?.date_plan}`.split('-')[2] + ` ` +
              (`${form?.date_plan}`.split('-')[1] === "01" ? "Januari" : "" ||
              `${form?.date_plan}`.split('-')[1] === "02" ? "Februari" : "" ||
              `${form?.date_plan}`.split('-')[1] === "03" ? "Maret" : "" ||
              `${form?.date_plan}`.split('-')[1] === "04" ? "April" : "" ||  
              `${form?.date_plan}`.split('-')[1] === "05" ? "Mei" : "" ||
              `${form?.date_plan}`.split('-')[1] === "06" ? "Juni" : "" ||
              `${form?.date_plan}`.split('-')[1] === "07" ? "Juli" : "" ||
              `${form?.date_plan}`.split('-')[1] === "08" ? "Agustus" : "" ||
              `${form?.date_plan}`.split('-')[1] === "09" ? "September" : "" ||
              `${form?.date_plan}`.split('-')[1] === "10" ? "Oktober" : "" ||
              `${form?.date_plan}`.split('-')[1] === "11" ? "November" : "" ||
              `${form?.date_plan}`.split('-')[1] === "12" ? "Desember" : "" ) + ` ` +
              `${form?.date_plan}`.split('-')[0] + "   |   " +  `${form?.time}`.split(':')[0] +` : `+ `${form?.time}`.split(':')[1]
              }
              name="date_plan" style={{ backgroundColor:"#DEDEDE", width:"100%", border:"1px solid #C9C9C9", outline:"none", height:"35px", borderRadius:"5px", paddingLeft:"5px"}}/>             
            </div>
            </div>
            <div style={{flex:"50%", display:"flex",alignItems:"center"}}>
          <div style={{ width: "100%" }}>
            <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Anamnesa</div>
                <input  type="text" value={form?.anamnesa} name="anamnesa" onChange={handleChange}  style={{ backgroundColor:"", width:"100%", border:"1px solid #C9C9C9", outline:"none", height:"35px", borderRadius:"5px", paddingLeft:"5px"}}/>
              {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Keluhan Pasien</div> */}
            </div>
          </div>
        </div>
            
    <div className="mt-4 mb-2" style={{display:"flex", fontWeight:"bold"}}>Treatment :</div>
      <div className="App">
        {inputList.map((x, i) => {
        return (
      <div className="box">  
      <div className="mt-2" style={{ display: "flex"}}>
      <div style={{flex:"50%", display:"flex",alignItems:"center", marginRight:"20px"}}>
        <div style={{width: "100%"}}>
          <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Dokter</div>
          <select className="form-select" aria-label="Default select example" value={x.id_doctor} onChange={(e) => handleInputChange(e, i, "id_doctor")}  name="id_doctor" style={{ textAlign:"", cursor:"pointer"}}>
            <option value="">Select Dokter</option>
            {getDataDokter.map((user,index) => (
              <option value={user?.id} style={{textAlign:""}}>{user?.firstname} {user?.lastname}</option>
              ))}         
          </select>   
          </div>
          </div>
          <div style={{flex:"50%", display:"flex",alignItems:"center"}}>
        <div style={{ width: "100%" }}>
          <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Treatment</div>
                  <select onMouseEnter={getDataTreatment.length === 0 ? GetResponseDataTreatment : null} className="form-select" aria-label="Default select example"  onChange={(e) => handleInputChange(e, i, "id_treatment")}  name="id_treatment" style={{ textAlign:"", cursor:"pointer"}}>
            <option value="" >Pilih Treatment</option>
              {getDataTreatment.map((user, index) => (
                <option value={user?.id} style={{ textAlign: "" }}>{user?.name}</option>
              ))}         
          </select>
          </div>
        </div>
          </div>
            
            <div className="mt-4" style={{ display: "flex"}}>
        <div style={{flex:"50%", display:"flex",alignItems:"center", marginRight:"20px"}}>
          <div style={{width: "100%"}}>
            <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Ruangan</div>
            <select className="form-select" aria-label="Default select example" onMouseEnter={getDataRoom.length === 0 ? GetResponseDataRoom : null}  onChange={(e) => handleInputChange(e, i, "id_room")}  name="id_room" style={{ textAlign:"", cursor:"pointer"}}>
              <option value="" >Pilih Ruangan </option>
              {getDataRoom.map((user,index) => (
                <option value={user?.id} style={{textAlign:""}}>{user?.name}</option>
              ))}         
            </select> 
            </div>
            </div>
            <div style={{flex:"50%", display:"flex",alignItems:"center"}}>
          <div style={{ width: "100%" }}>
            <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Nomor Gigi</div>
            <input autoFofcus type='text' placeholder="Masukan Nomor Gigi" value={x.tooth_number}  name="tooth_number" onChange={(e) => handleInputChange(e, i, "tooth_number")} style={{ backgroundColor:"", width:"100%", border:"1px solid #C9C9C9", outline:"none", height:"35px", borderRadius:"5px", paddingLeft:"5px"}}/>
            </div>
          </div>
            </div>
            
            <div className="mt-4" style={{ display: "flex"}}>
        <div style={{flex:"50%", display:"flex",alignItems:"center", marginRight:"20px"}}>
          <div style={{width: "100%"}}>
            <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Qty</div>
                <input  type="number"  value={x.qty}  name="qty" placeholder="Masukan satuan" min="1"  onChange={(e) => handleInputChange(e, i, "qty")}  style={{backgroundColor:"", width:"100%", border:"1px solid #C9C9C9", outline:"none", height:"35px", borderRadius:"5px", paddingLeft:"5px"}}/>
              {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
            </div>
            </div>
            <div style={{flex:"50%", display:"flex",alignItems:"center"}}>
          <div style={{ width: "100%" }}>
            <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Diagnosa</div>
              <input  type='text' placeholder="Masukan diagnosa" value={x.description}  name="description" onChange={(e) => handleInputChange(e, i, "description")} style={{backgroundColor:"", width:"100%", border:"1px solid #C9C9C9", outline:"none", height:"35px", borderRadius:"5px", paddingLeft:"5px", appearance:"textfield"}}/>
              {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
            </div>
          </div>
        </div>

        <div className="mt-3" style={{ display: "flex"}}>
            <div style={{ width:"15%", outline:"none", padding:"10px 0px",marginRight:"10px" }}>
              <input disabled  type='number' value={x.treatment_price_min} name="treatment_price_min"  style={{width:"100%",height:"100%",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none", textAlign:"center", display:"flex"}}/>
            <div style={{fontSize:"11px", color:"#898989", fontStyle:"italic", fontWeight:"bold", display:"flex", justifyContent:"center"}}>Min</div>
            </div>
            <div style={{ width:"70%", outline:"none", padding:"10px 0px",marginRight:"10px"}}>
              <input  step={50000}  type='number' placeholder="Masukan Harga"  name="estimate_price" min={x.treatment_price_min} max={x.treatment_price_max}  value={x?.estimate_price}  onChange={(e) => {
                  const newValue = parseFloat(e.target.value);
                  if (newValue >= x.treatment_price_min && newValue <= x.treatment_price_max) {
                    handleInputChange(e, i, "estimate_price");
                  }
                  }}
                    style={{ width: "100%", height: "100%", outline: "none", textAlign: "center",border:"1px solid #C9C9C9", borderRadius:"5px" }} />
                <div style={{fontSize:"11px", color:"#898989", fontStyle:"italic", fontWeight:"bold", display:"flex", justifyContent:"center"}}>Estimasi Harga Dapat Diubah</div>

              </div>
            <div style={{ width:"15%", outline:"none", padding:"10px 0px"}}>
              <input disabled  type='number'  value={x.treatment_price_max}  name="treatment_price_max" style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none",  textAlign:"center"}}/>
            <div style={{fontSize:"11px", color:"#898989", fontStyle:"italic", fontWeight:"bold", display:"flex", justifyContent:"center"}}>Max</div>
          </div>
        </div>

      <Row className="mt-4" style={{ display: "flex" }}>
      <div style={{fontSize:"15px", color:"", fontStyle:"", fontWeight:"bold"}}>Perawat :</div>
        {x.assistants.map((assistant, assistantIndex) => (
          <Col xl='4' className="mt-3" style={{ display: "flex", marginRight:"5px" }} key={assistantIndex}>
              <select
                onMouseEnter={getDataNurse.length === 0? GetResponseDataNurse : null}
                className="form-select"
                aria-label="Default select example"
                name={`assistant-${i}-${assistantIndex}`} // Use a unique name for each dropdown
                value={assistant}
                onChange={(e) => handleAssistantChange(e, i, assistantIndex)}
                style={{ textAlign: "", cursor: "pointer", fontSize: "13px" }}
              >
                <option value="" hidden>Pilih</option>
                {getDataNurse.map((nurse, nurseIndex) => (
                  <option value={nurse?.id} key={nurseIndex}>
                    {nurse?.firstname} {nurse?.lastname}
                  </option>
                ))}
              </select>
        
          </Col>
        ))}
        </Row>
            
        <div className="" style={{ display: "" }}>
          <div className="mt-3">
            {inputList.length !== 1 && <button className="mr10" style={{border:"none", backgroundColor:"red", color:"white", borderRadius:"3px", fontSize:"12px", padding:"5px 10px"}} onClick={() => handleRemoveClick(i)}>Remove</button>}
          </div>
          <div className="mt-3">
            {inputList.length - 1 === i && <button style={{border:"none", backgroundColor:"#667BFF", color:"white", borderRadius:"3px", fontSize:"12px", padding:"5px 10px"}} onClick={handleAddClick}><FaPlus style={{marginRight:"5px", fontSize:"10px"}}/>Add</button>}
          </div>
        </div>
      </div>
      );
      })}
      {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
        </div>
        
    {inputListObat.map((x, i) => {
      return (
      <div className="box mt-4">
          <div style={{fontSize:"15px", color:"", fontStyle:"", fontWeight:"bold"}}>Obat Pasien :</div>
        <div className="mt-3" style={{ display: "flex"}}>
          <div style={{flex:"15%", display:"flex",alignItems:"center"}}>
            Pilih Obat
          </div>
          <div style={{ flex: "85%", display:"flex"}}>
            <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <select className="form-select" aria-label="Default select example" value={x.id_medical_item} onMouseEnter={dataItem.length === 0? GetResponseDataObat : null}  onChange={(e) => handleInputChangeObat(e, i, "id_medical_item")}  name="id_medical_item" style={{ textAlign:"", cursor:"pointer"}}>
              <option value="">Pilih</option>
                {dataItem.map((user, index) => (
                  <option value={user?.id} style={{ textAlign: "" }}>{user?.name}</option>
                ))}         
            </select>
          </div>
        </div>
              
        <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"15%", display:"flex",alignItems:"center"}}>
            Qty
          </div>
          <div style={{ flex: "85%", display:"flex"}}>
            <p style={{ marginRight: "5px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
              <input type='number' placeholder="Masukan Satuan" value={x.qty} min="1"  name="qty" onChange={(e) => handleInputChangeObat(e, i, "qty")} style={{backgroundColor:"", width:"100%", border:"1px solid #C9C9C9", outline:"none", height:"40px", borderRadius:"5px", paddingLeft:"5px"}}/>
            </div>
          </div>
          </div>
          
          <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"15%", display:"flex",alignItems:"center"}}>
            Deskripsi
          </div>
          <div style={{ flex: "85%", display:"flex"}}>
            <p style={{ marginRight: "5px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
              <input autoFofcus type='text' placeholder="Masukan Deskripsi" value={x.description}  name="description" onChange={(e) => handleInputChangeObat(e, i, "description")} style={{backgroundColor:"", width:"100%", border:"1px solid #C9C9C9", outline:"none", height:"40px", borderRadius:"5px", paddingLeft:"5px"}}/>
            </div>
          </div>
        </div>
            
        <div className="" style={{ display: "" }}>
          <div className="mt-3">
            {inputListObat.length !== 1 && <button className="mr10" style={{border:"none", backgroundColor:"red", color:"white", borderRadius:"3px", fontSize:"12px", padding:"5px 10px"}} onClick={() => handleRemoveClickObat(i)}>Remove</button>}
          </div>
          <div className="mt-3">
            {inputListObat.length - 1 === i && <button style={{border:"none", backgroundColor:"#667BFF", color:"white", borderRadius:"3px", fontSize:"12px", padding:"5px 10px"}} onClick={handleAddClickObat}><FaPlus style={{marginRight:"5px", fontSize:"10px"}}/>Add</button>}
          </div>
        </div>
      </div>
      );
    })}
          
      <div className="box mt-4">
      {getDataFee.map((user,index) => (
        <div  key={index} className="" style={{ display: "flex"}}>
        <div style={{flex:"15%", display:"flex",alignItems:"center"}}>
          {user?.name}
        </div>
        <div style={{ flex: "85%", display:"flex"}}>
          <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
          <div style={{ width:"100%", outline:"none", padding:"10px 0px", fontWeight:""}}>
            <FormatRupiah type="text" value={user?.price}/>
                            
          </div>
        </div>
      </div>
          ))}  

          <div className="" style={{ display: "flex"}}>
        <div style={{flex:"15%", display:"flex",alignItems:"center"}}>
          Harga Obat
        </div>
        <div style={{ flex: "85%", display:"flex"}}>
          <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
          <div style={{ width:"100%", outline:"none", padding:"10px 0px", fontWeight:""}}>
            <FormatRupiah type="text" value={`${form?.estimate_price_obat}`}/>
                            
          </div>
        </div>
      </div>
        
      <div className="mt-1" style={{ display: "flex"}}>
        <div style={{flex:"15%", display:"flex",alignItems:"center"}}>
          Harga Treatment
        </div>
        <div style={{ flex: "85%", display:"flex"}}>
          <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
          <div style={{ width:"100%", outline:"none", padding:"10px 0px", fontWeight:""}}>
            <FormatRupiah type="text" value={`${form?.estimate_price_total}`}/>                
          </div>
        </div>
          </div>
          
        <div style={{display:"flex",textAlign:"",justifyContent:"end", borderBottom:"1px solid #C0C0C0", width:"28%"}}>+</div>
          <div className="mt-2" style={{ display: "flex"}}>
        <div style={{flex:"15%", display:"flex",alignItems:"center", fontWeight:"bold"}}>
          Total Harga 
        </div>
        <div style={{ flex: "85%", display:"flex"}}>
          <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
          <div style={{ width:"100%", outline:"none", padding:"10px 0px", fontWeight:"bold"}}>
            <FormatRupiah type="text" value={`${form?.estimate_price_obat + form?.estimate_price_total + totalDataFeePrice}`}/>          </div>
        </div>
      </div>
            
      </div>
          
 
    
      <div style={{ padding: "0px 0px", marginTop: "0px", display:"flex", justifyContent:"center" }}>
        <div style={{width:"20%"}}>
          <Button className="mt-4" type='submit' color='primary' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px"}}>
            Simpan Treatment
          </Button>
        </div>
      </div>
    </Form>    
    :
  <></>
    }

    {getDataDetailsApproval.map((user,index) => (
      <div key={index} style={{ backgroundColor: "white", marginTop: "25px", marginLeft: "10px", marginRight: "10px", boxShadow: "2px 2px 10px #BFBFBF"}}>
        <div style={{ display: "flex", justifyContent: "center", padding: "10px", backgroundColor: "#00DCBF" }}>
          <div style={{display:"flex", justifyContent:"center", outline:"none", padding:"5px 30px", fontWeight:"bold", color:"#757575", backgroundColor:"white", borderRadius:"5px"}}>
            {user?.treatment_data.name}
          </div>
        </div>
        <Form onSubmit={(e) => handleSubmit.mutate(e)} style={{ fontFamily: "sans-serif", display: "flex", marginTop: "0px" }} >
          <Col xl='9' sm='12' style={{ padding: "20px 20px 20px 40px", borderRight:"1px solid #E4E4E4" }}>
            <div className="" style={{ display: "flex"}}>
              <div style={{flex:"20%", display:"flex",alignItems:"center"}}>
              Dokter
              </div>
              <div style={{ flex: "80%", display:"flex"}}>
                <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
                <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
                    <input disabled autoFofcus type='text' value={user?.doctor_name}  onChange={handleChange} style={{ backgroundColor:"white",width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none", fontWeight:"bold"}}/>
                  </div>
              </div>
            </div>
              
            <div className="" style={{ display: "flex"}}>
              <div style={{flex:"20%", display:"flex",alignItems:"center"}}>
                Nomor Gigi
              </div>
              <div style={{ flex: "80%", display:"flex"}}>
                <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
                <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
                    <input disabled autoFofcus type='text' value={user?.tooth_number}  onChange={handleChange} style={{ backgroundColor:"white",width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
                  </div>
              </div>
            </div>
            
            <div className="" style={{ display: "flex"}}>
              <div style={{flex:"20%", display:"flex",alignItems:"center"}}>
                Qty
              </div>
              <div style={{ flex: "80%", display:"flex"}}>
                <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
                <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
                    <input disabled autoFofcus type='text' value={user?.qty} style={{ backgroundColor:"white",width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
                  </div>
              </div>
            </div>
            
            <div className="" style={{ display: "flex"}}>
              <div style={{flex:"20%", display:"flex",alignItems:"center"}}>
              Estimasi Harga
              </div>
              <div style={{ flex: "80%", display:"flex"}}>
                <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
                <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
                    <input disabled autoFofcus type='text' value={toRupiah(`${user?.estimate_price}`,{dot: ',', floatingPoint: 0}).split(",")} style={{ backgroundColor:"white",width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
                  </div>
              </div>
            </div>
            
            <div className="" style={{ display: "flex"}}>
              <div style={{flex:"20%", display:"flex",alignItems:"center"}}>
                Diagnosa
              </div>
              <div style={{ flex: "80%", display:"flex"}}>
                <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
                <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
                    <input disabled autoFofcus type='text' value={user?.description} style={{ backgroundColor:"white",width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
                  </div>
              </div>
              </div>
              
              {/*
              <div className="" style={{ display: "flex"}}>
              <div style={{flex:"20%", display:"flex",alignItems:"center"}}>
                Diagnosa
              </div>
              <div style={{ flex: "80%", display:"flex"}}>
                <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
                <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
                    <input disabled autoFofcus type='text' value="-" style={{ backgroundColor:"white",width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
                  </div>
              </div>
              </div> */}

              {/* {user?.patient_approval === 1 ?
              <div className="mt-4" style={{display:"flex", }}>
                <div style={{ width: "100px" }}>
                  <Button onClick={() => modalMedisRoleUpdate(user?.id, user?.getData.id_patient, user?.id_room, user?.tooth_number, user?.qty, user?.estimate_price, user?.description)} color='primary' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px"}}>
                    Update 
                  </Button>
                </div>
             
                <div className="" style={{width:"150px"}}>
                  <Button  color='secondary' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px"}}>
                    Selesaikan
                  </Button>
                </div>
              </div>  
                : 
                <div className="mt-4" style={{display:"flex", }}>
                <div style={{ width: "100px" }}>
                  <Button  color='primary' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px"}}>
                    Update 
                  </Button>
                </div>
              </div>  
              } */}
              </Col> 
              <Col xl='3' sm='12' style={{ backgroundColor:"", display:"flex", alignItems:"center", justifyContent:"center", color:"black" }}>
            
              {user?.patient_approval === 1 ?  
              <div style={{ display:"", justifyContent: "" }}>
                  <div  className="mt-4" style={{display:"flex",width:"100%"}}>
                      <img src={LogoFinish} style={{minWidth:"150px", maxWidth:"150px"}} />
                  </div>
                  <div className="mt-3" style={{display:"flex", justifyContent:"center", color:"#737373"}}>
                    Sudah Approve
                  </div>

                <div className="" style={{paddingTop:"20px"}}>
                  <Button onClick={() => {RejectTreatment(user?.id)}}  block style={{padding:"7px 20px",fontSize:"12px",borderRadius:"5px", backgroundColor:"#FF3F3F", border:"none"}}>
                    Reject 
                  </Button>
                </div>
                 
              </div>
                  : 
              <div style={{padding:"10px 0px"}}>
                <div style={{ display:"flex", justifyContent:"center"}}>
                      <p>Approval Treatment</p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", margin:"10px 0px" }}>
                      <div style={{ display:"flex", marginLeft:"25px", borderRight:"1px solid #D8D8D8", borderLeft:"1px solid #D8D8D8"}}>
                        <SignatureCanvas
                          canvasProps={{ width: 200, height: 150, className: 'sigCanvas' }}
                          ref={ref => (canvasRef = ref)}
                          onEnd={e => {
                            // console.log(canvasRef.isEmpty());
                            // setBase64(canvasRef.toDataURL().split(',')[1]);
                            setBase64(canvasRef.toDataURL());
                            
                          }}
                      />
                    </div>
                      <div style={{paddingLeft:"10px", display:"flex"}}>
                        <FaUndoAlt  style={{cursor:"pointer"}}/>
                      </div>
                    </div>
                    <div style={{ display:"flex", justifyContent:"center"}}>
                      <p>{`( Tanda Tangan Digital )`}</p>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div>
                    <Button onClick={() => {ApproveTreatment(user?.id, user?.description)}}  block style={{padding:"7px 20px",fontSize:"12px",borderRadius:"5px", backgroundColor:"#FF8B06", border:"none"}}>
                      Approve 
                    </Button>
                  </div>
                </div>  
            
              </div>
              }
        
          </Col>
        </Form>
        
        </div>
    ))}
      
      {getDataAppointmentByIdTreatmentPlanStatusButton === null ? <></> :

        <Form onSubmit={(e) => handleSubmit.mutate(e)} style={{ padding: "15px 10px 30px 10px", fontFamily: "sans-serif" }} >
          {inputListObat.map((x, i) => {
            return (
              <div className="box mt-4">
          
                <div className="mt-3" style={{ display: "flex" }}>
                  <div style={{ flex: "15%", display: "flex", alignItems: "center" }}>
                    Obat Pasien {i+1}
                  </div>
                  <div style={{ flex: "85%", display: "flex" }}>
                    <p style={{ marginRight: "5px", height: "100%", display: "flex", alignItems: "center" }}>:</p>
                    <select disabled className="form-select" aria-label="Default select example" value={x.id_medical_item} onChange={(e) => handleInputChangeObat(e, i, "id_medical_item")} name="id_medical_item" style={{backgroundColor:"#DADADA", textAlign: "", cursor: "pointer" }}>
                      <option value="">-</option>
                      {dataItem.map((user, index) => (
                        <option value={user?.id} style={{ textAlign: "" }}>{user?.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              
                <div className="mt-2" style={{ display: "flex" }}>
                  <div style={{ flex: "15%", display: "flex", alignItems: "center" }}>
                    Qty
                  </div>
                  <div style={{ flex: "85%", display: "flex" }}>
                    <p style={{ marginRight: "5px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
                    <div style={{ width: "100%", outline: "none", padding: "10px 0px" }}>
                      <input disabled type='number' placeholder="-" value={x.qty} min="1" name="qty" onChange={(e) => handleInputChangeObat(e, i, "qty")} style={{backgroundColor:"#DADADA", width: "100%", border: "1px solid #C9C9C9", outline: "none", height: "40px", borderRadius: "5px", paddingLeft: "5px" }} />
                    </div>
                  </div>
                </div>

                <div className="mt-2" style={{ display: "flex"}}>
                  <div style={{flex:"15%", display:"flex",alignItems:"center"}}>
                    Deskripsi
                  </div>
                  <div style={{ flex: "85%", display:"flex"}}>
                    <p style={{ marginRight: "5px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
                    <div  style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
                      <input disabled autoFofcus type='text' placeholder="-" value={x.description}  name="description" onChange={(e) => handleInputChangeObat(e, i, "description")} style={{backgroundColor:"#DADADA", width:"100%", border:"1px solid #C9C9C9", outline:"none", height:"40px", borderRadius:"5px", paddingLeft:"5px"}}/>
                    </div>
                  </div>
                </div>
            
                {/* <div className="" style={{ display: "" }}>
                  <div className="mt-3">
                    {inputListObat.length !== 1 && <button className="mr10" style={{ border: "none", backgroundColor: "red", color: "white", borderRadius: "3px", fontSize: "12px", padding: "5px 10px" }} onClick={() => handleRemoveClickObat(i)}>Remove</button>}
                  </div>
                  <div className="mt-3">
                    {inputListObat.length - 1 === i && <button style={{ border: "none", backgroundColor: "#667BFF", color: "white", borderRadius: "3px", fontSize: "12px", padding: "5px 10px" }} onClick={handleAddClickObat}><FaPlus style={{ marginRight: "5px", fontSize: "10px" }} />Add</button>}
                  </div>
                </div> */}
              </div>
            );
          })}
        </Form>
      }
      
      {getDataAppointmentByIdTreatmentPlanStatusButton === null ? <></> :
          <div className="box mt-1">
          {getDataFee.map((user,index) => (
            <div  key={index} className="" style={{ display: "flex"}}>
            <div style={{flex:"15%", display:"flex",alignItems:"center"}}>
              {user?.name}
            </div>
            <div style={{ flex: "85%", display:"flex"}}>
              <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
              <div style={{ width:"100%", outline:"none", padding:"10px 0px", fontWeight:""}}>
                <FormatRupiah type="text" value={user?.price}/>     
              </div>
            </div>
          </div>
              ))}  
    
              <div className="" style={{ display: "flex"}}>
            <div style={{flex:"15%", display:"flex",alignItems:"center"}}>
              Harga Obat
            </div>
            <div style={{ flex: "85%", display:"flex"}}>
              <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
              <div style={{ width:"100%", outline:"none", padding:"10px 0px", fontWeight:""}}>
                <FormatRupiah type="text" value={`${form?.estimate_price_obat}`}/>
                                
              </div>
            </div>
          </div>
            
          <div className="mt-1" style={{ display: "flex"}}>
            <div style={{flex:"15%", display:"flex",alignItems:"center"}}>
              Harga Treatment
            </div>
            <div style={{ flex: "85%", display:"flex"}}>
              <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
              <div style={{ width:"100%", outline:"none", padding:"10px 0px", fontWeight:""}}>
                <FormatRupiah type="text" value={`${form?.estimate_price_total}`}/>                
              </div>
            </div>
              </div>
              
            <div style={{display:"flex",textAlign:"",justifyContent:"end", borderBottom:"1px solid #C0C0C0", width:"28%"}}>+</div>
              <div className="mt-2" style={{ display: "flex"}}>
            <div style={{flex:"15%", display:"flex",alignItems:"center", fontWeight:"bold"}}>
              Total Harga 
            </div>
            <div style={{ flex: "85%", display:"flex"}}>
              <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
              <div style={{ width:"100%", outline:"none", padding:"10px 0px", fontWeight:"bold"}}>
                <FormatRupiah type="text" value={`${form?.estimate_price_obat + form?.estimate_price_total + totalDataFeePrice}`}/>          </div>
            </div>
          </div>
                
          </div>
      }

      {getDataAppointmentByIdTreatmentPlanStatusButton === null ? <></> :
        <div style={{ padding: "30px 0px", marginTop: "0px", display: "flex", justifyContent: "center" }}>
          <div style={{ width: "20%" }}>
            <Button onClick={handleSubmitMedicalInvoice} className="mt-4" type='' block style={{backgroundColor:"#3F3FFF", padding: "8px 10px", fontSize: "12px", borderRadius: "5px" }}>
              Konfirmasi Treatment
            </Button>
          </div>
        </div>
      }
    </div>
  );
}
