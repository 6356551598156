import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { ScaleLoader } from "react-spinners";

export default function Login() {
  // let navigate = useNavigate();
  // useEffect(() => {
  //   if (localStorage.getItem("token") == null || undefined) {
  //       navigate("/");
  //     }
  // }, []);
  document.title = "SDC Apps";
  const [loading, setLoading] = useState(false);
    
  return (
  <Modal show={loading? loading: "false"} centered style={{ fontFamily: "sans-serif", border:"none"}}>
    <Modal.Body style={{ backgroundColor: "#B6CFFF", height:"100%" }}>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh" }}>
        <div>
          <div style={{ display: "flex", justifyContent: "center", fontSize: "20px" }}>
            <ScaleLoader color={'white'} style={{fontSize:"30px"}}/>
          </div>
          <div style={{ marginTop: "20px", fontWeight: "bold", fontSize: "20px", color: "white" }}>
            Please Wait.....
          </div>
        </div>
      </div>
    </Modal.Body>
  </Modal>
  );
}
