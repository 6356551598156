import { useState } from "react";
import {  useParams } from "react-router-dom";
import "bulma/css/bulma.css";
import "../Styled.css"

export default function ToochNumber({ form, setForm, onclikTooch, handlePolygonClick }) {
  document.title = "SDC Apps";
  const token = localStorage.getItem("token");
  // modal role add 
  const { id, code } = useParams()
  const [infoGigi, setInfoGigi] = useState("XX-X")

  return (
    <div style={{flex:"50%", display:"flex", justifyContent:"end", paddingRight:""}}>
    <div style={{ width: "42px", margin: "1px" }}>

    {form.tooch_number.includes("85O-amf-rct") || form.tooch_number.includes("85O-cof-rct") ||form.tooch_number.includes("85-nvt") || form.tooch_number.includes("85-rct") || form.tooch_number.includes("85B-amf-rct")
|| form.tooch_number.includes("85P-amf-rct") || form.tooch_number.includes("85M-amf-rct") || form.tooch_number.includes("85D-amf-rct")
|| form.tooch_number.includes("85L-amf-rct") ||
form.tooch_number.includes("85B-cof-rct") || form.tooch_number.includes("85P-cof-rct") || form.tooch_number.includes("85M-cof-rct")
|| form.tooch_number.includes("85D-cof-rct") || form.tooch_number.includes("85L-cof-rct") ||
form.tooch_number.includes("85-fmc-rct") ||
form.tooch_number.includes("85-poc-rct") ?
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%"}}>
<svg width={42} height={42}>
<polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 85B"
  points="21,2 41,32 1,32" 
  style={{ fill: form.tooch_number.includes("85-nvt") ? 'white' : 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('85-Bukal')}
  onMouseLeave={() => setInfoGigi('XX-X')}
/>
</svg>
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingTop: "13px" }}>
</div>
        } 

<svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("85-fmc") || form.tooch_number.includes("85-poc") || form.tooch_number.includes("85-ipx-poc") || form.tooch_number.match(/\b85-pob\b/) || form.tooch_number.match(/\b85-miss-pon-pob\b/) || form.tooch_number.match(/\b85-meb\b/) || form.tooch_number.match(/\b85-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
{form.tooch_number.includes("85-poc") || form.tooch_number.includes("85-ipx-poc") || form.tooch_number.match(/\b85-pob\b/) || form.tooch_number.match(/\b85-miss-pon-pob\b/) ?
<g>
{/* Atas */}
{form.tooch_number.includes("85L-fis") || form.tooch_number.includes("85L-amf") || form.tooch_number.includes("85L-cof") || form.tooch_number.includes("85L-car") ?
  <>
    {form.tooch_number.includes("85L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="85L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 85L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#85L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("85-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("85L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 85L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("85-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("85L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="85L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 85L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("85-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("85L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 85L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("85-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
<svg width="40" height="40">
  <defs>
    <pattern id="85L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
    </pattern>
  </defs>
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 85L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: '#007610', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("85-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
</svg>
}

{/* Bawah */}
{form.tooch_number.includes("85B-fis") || form.tooch_number.includes("85B-amf") || form.tooch_number.includes("85B-cof") || form.tooch_number.includes("85B-car") ?
  <>
    {form.tooch_number.includes("85B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="85B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 85B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#85B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("85-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("85B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 85B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("85-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("85B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="85B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 85B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("85-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("85B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 85B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("85-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="85B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 85B"
      points="10,30 30,30 40,40 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("85-Bukal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kanan */}
{form.tooch_number.includes("85M-fis") || form.tooch_number.includes("85M-amf") || form.tooch_number.includes("85M-cof") || form.tooch_number.includes("85M-car") ?
  <>
    {form.tooch_number.includes("85M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="85M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 85M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#85M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("85-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("85M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 85M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("85-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("85M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="85M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 85M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("85-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("85M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 85M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("85-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="85M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 85M"
      points="30,10 40,0 40,40 30,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("85-Mesial")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kiri */}
{form.tooch_number.includes("85D-fis") || form.tooch_number.includes("85D-amf") || form.tooch_number.includes("85D-cof") || form.tooch_number.includes("85D-car") ?
  <>
    {form.tooch_number.includes("85D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="85D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 85D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#85D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("85-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("85D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 85D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("85-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("85D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="85D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 85D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("85-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("85D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 85D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("85-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="85D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 85D"
      points="0,0 10,10 10,30 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("85-Distal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* center */}
{form.tooch_number.includes("85O-fis") || form.tooch_number.includes("85O-amf") || form.tooch_number.includes("85O-cof") || form.tooch_number.includes("85O-car") ?
  <>
    {form.tooch_number.includes("85O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="85O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 85O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#85O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("85-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("85O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 85O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("85-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("85O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="85O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 85O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("85-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("85O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 85O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("85-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="85O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 85O"
      points="10,10 30,10 30,30 10,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("85-Oklusal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* cfr */}
{form.tooch_number.includes("85-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{/* rrx */}
{form.tooch_number.includes("85-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 85-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
  v
</text>
}
      
{/* mis */}
{form.tooch_number.includes("85-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
        
</g>
:
<g>
{/* Atas */}
{form.tooch_number.includes("85L-fis") || form.tooch_number.includes("85L-amf") || form.tooch_number.includes("85L-cof") || form.tooch_number.includes("85L-car") ?
  <>
    {form.tooch_number.includes("85L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="85L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 85L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#85L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("85-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("85L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 85L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("85-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("85L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="85L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 85L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("85-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("85L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 85L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("85-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 85L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("85-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Bawah */}
{form.tooch_number.includes("85B-fis") || form.tooch_number.includes("85B-amf") || form.tooch_number.includes("85B-cof") || form.tooch_number.includes("85B-car") ?
  <>
    {form.tooch_number.includes("85B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="85B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 85B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#85B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("85-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("85B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 85B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("85-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("85B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="85B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 85B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("85-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("85B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 85B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("85-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 85B"
    points="10,30 30,30 40,40 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("85-Bukal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kanan */}
{form.tooch_number.includes("85M-fis") || form.tooch_number.includes("85M-amf") || form.tooch_number.includes("85M-cof") || form.tooch_number.includes("85M-car") ?
  <>
    {form.tooch_number.includes("85M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="85M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 85M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#85M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("85-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("85M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 85M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("85-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("85M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="85M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 85M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("85-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("85M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 85M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("85-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 85M"
    points="30,10 40,0 40,40 30,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("85-Mesial")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kiri */}
{form.tooch_number.includes("85D-fis") || form.tooch_number.includes("85D-amf") || form.tooch_number.includes("85D-cof") || form.tooch_number.includes("85D-car") ?
  <>
    {form.tooch_number.includes("85D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="85D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 85D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#85D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("85-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("85D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 85D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("85-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("85D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="85D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 85D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("85-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("85D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 85D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("85-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 85D"
    points="0,0 10,10 10,30 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("85-Distal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* center */}
{form.tooch_number.includes("85O-fis") || form.tooch_number.includes("85O-amf") || form.tooch_number.includes("85O-cof") || form.tooch_number.includes("85O-car") ?
  <>
    {form.tooch_number.includes("85O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="85O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 85O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#85O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("85-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("85O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 85O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("85-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("85O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="85O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 85O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("85-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("85O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 85O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("85-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 85O"
    points="10,10 30,10 30,30 10,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("85-Oklusal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* cfr */}
{form.tooch_number.includes("85-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{form.tooch_number.includes("85-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 85-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
  v
</text>
}

{form.tooch_number.includes("85-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
</g>
}      
</svg>

{form.tooch_number.includes("85-pob-84-miss-pon-pob-83-pob")
||form.tooch_number.includes("85-pob-84-miss-pon-pob-83-miss-pon-pob-82-pob")
||form.tooch_number.includes("85-pob-84-miss-pon-pob-83-miss-pon-pob-82-miss-pon-pob-81-pob") ?
<>
{/* meb 85 - 57 */}
{form.tooch_number.includes("85-pob-84-miss-pon-pob-83-pob") &&
<div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">

                      <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div>
}
                
{/* meb 85 - 53 */}
{form.tooch_number.includes("85-pob-84-miss-pon-pob-83-miss-pon-pob-82-pob") &&
<div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">
                      <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div>
}

{/* meb 85 - 52 */} 
{form.tooch_number.includes("85-pob-84-miss-pon-pob-83-miss-pon-pob-82-miss-pon-pob-81-pob") &&      
<div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">
                      <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div> 
}

</>
:
<></>
}


{form.tooch_number.includes("85-meb-84-miss-pon-meb-83-meb")
||form.tooch_number.includes("85-meb-84-miss-pon-meb-83-miss-pon-meb-82-meb")
||form.tooch_number.includes("85-meb-84-miss-pon-meb-83-miss-pon-meb-82-miss-pon-meb-81-meb") ?
<>
{/* meb 85 - 83 */}
{form.tooch_number.includes("85-meb-84-miss-pon-meb-83-meb") &&
<div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">

  <g transform="rotate(180 108.5 6)">
    {/* Garis ke samping dan ke bawah (L-shape) */}
    <line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div>
}
                
{/* meb 85 - 53 */}
{form.tooch_number.includes("85-meb-84-miss-pon-meb-83-miss-pon-meb-82-meb") &&
<div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">
                      <g transform="rotate(180 108.5 6)">
 {/* Garis ke samping dan ke bawah (L-shape) */}
 <line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>

</svg>
</div>
}

{/* meb 85 - 52 */} 
{form.tooch_number.includes("85-meb-84-miss-pon-meb-83-miss-pon-meb-82-miss-pon-meb-81-meb") &&      
<div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">

<g transform="rotate(180 108.5 6)">
  {/* Garis yang sudah dirotasi */}
  <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
</g>
</svg>
</div> 
}

</>
:
<></>
}
<div style={{ textAlign: "center" }}>85</div>

{form.tooch_number.includes("85-une") || form.tooch_number.includes("85-non") || form.tooch_number.includes("85-pre") || form.tooch_number.includes("85-ano") || form.tooch_number.includes("85-ipx-poc") || form.tooch_number.includes("85-mis-prd-acr") || form.tooch_number.includes("85-mis-fld-acr") ?
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
{form.tooch_number.includes("85-une") &&
<div style={{ position: "absolute", color: "black" }}>UNE</div>
}
{form.tooch_number.includes("85-non") &&
<div style={{ position: "absolute", color: "black" }}>NON</div>
}      
{form.tooch_number.includes("85-pre") &&
<div style={{ position: "absolute", color: "black" }}>PRE</div>
}      
{form.tooch_number.includes("85-ano") &&
<div style={{ position: "absolute", color: "black" }}>ANO</div>
}
{form.tooch_number.includes("85-ipx-poc") &&
<div style={{ position: "absolute", color: "black" }}>IPX</div>
}
{form.tooch_number.includes("85-mis-prd-acr") &&
<div style={{ position: "absolute", color: "black" }}>PRD</div>
} 
{form.tooch_number.includes("85-mis-fld-acr") &&
<div style={{ position: "absolute", color: "black" }}>FLD</div>
}      
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
</div>
}
  </div>

  <div style={{ width: "42px", margin: "1px" }}>

  {form.tooch_number.includes("84O-amf-rct") || form.tooch_number.includes("84O-cof-rct") ||form.tooch_number.includes("84-nvt") || form.tooch_number.includes("84-rct") || form.tooch_number.includes("84B-amf-rct")
|| form.tooch_number.includes("84P-amf-rct") || form.tooch_number.includes("84M-amf-rct") || form.tooch_number.includes("84D-amf-rct")
|| form.tooch_number.includes("84L-amf-rct") ||
form.tooch_number.includes("84B-cof-rct") || form.tooch_number.includes("84P-cof-rct") || form.tooch_number.includes("84M-cof-rct")
|| form.tooch_number.includes("84D-cof-rct") || form.tooch_number.includes("84L-cof-rct") ||
form.tooch_number.includes("84-fmc-rct") ||
form.tooch_number.includes("84-poc-rct") ?
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%"}}>
<svg width={42} height={42}>
<polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 84B"
  points="21,2 41,32 1,32" 
  style={{ fill: form.tooch_number.includes("84-nvt") ? 'white' : 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('84-Bukal')}
  onMouseLeave={() => setInfoGigi('XX-X')}
/>
</svg>
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingTop: "13px" }}>
</div>
        } 

<svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("84-fmc") || form.tooch_number.includes("84-poc") || form.tooch_number.includes("84-ipx-poc") || form.tooch_number.match(/\b84-pob\b/) || form.tooch_number.match(/\b84-miss-pon-pob\b/) || form.tooch_number.match(/\b84-meb\b/) || form.tooch_number.match(/\b84-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
{form.tooch_number.includes("84-poc") || form.tooch_number.includes("84-ipx-poc") || form.tooch_number.match(/\b84-pob\b/) || form.tooch_number.match(/\b84-miss-pon-pob\b/) ?
<g>
{/* Atas */}
{form.tooch_number.includes("84L-fis") || form.tooch_number.includes("84L-amf") || form.tooch_number.includes("84L-cof") || form.tooch_number.includes("84L-car") ?
  <>
    {form.tooch_number.includes("84L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="84L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 84L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#84L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("84-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("84L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 84L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("84-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("84L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="84L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 84L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("84-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("84L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 84L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("84-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
<svg width="40" height="40">
  <defs>
    <pattern id="84L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
    </pattern>
  </defs>
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 84L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: '#007610', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("84-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
</svg>
}

{/* Bawah */}
{form.tooch_number.includes("84B-fis") || form.tooch_number.includes("84B-amf") || form.tooch_number.includes("84B-cof") || form.tooch_number.includes("84B-car") ?
  <>
    {form.tooch_number.includes("84B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="84B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 84B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#84B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("84-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("84B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 84B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("84-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("84B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="84B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 84B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("84-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("84B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 84B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("84-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="84B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 84B"
      points="10,30 30,30 40,40 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("84-Bukal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kanan */}
{form.tooch_number.includes("84M-fis") || form.tooch_number.includes("84M-amf") || form.tooch_number.includes("84M-cof") || form.tooch_number.includes("84M-car") ?
  <>
    {form.tooch_number.includes("84M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="84M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 84M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#84M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("84-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("84M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 84M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("84-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("84M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="84M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 84M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("84-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("84M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 84M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("84-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="84M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 84M"
      points="30,10 40,0 40,40 30,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("84-Mesial")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* Kiri */}
{form.tooch_number.includes("84D-fis") || form.tooch_number.includes("84D-amf") || form.tooch_number.includes("84D-cof") || form.tooch_number.includes("84D-car") ?
  <>
    {form.tooch_number.includes("84D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="84D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 84D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#84D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("84-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("84D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 84D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("84-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("84D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="84D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 84D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("84-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("84D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 84D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("84-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="84D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 84D"
      points="0,0 10,10 10,30 0,40"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("84-Distal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* center */}
{form.tooch_number.includes("84O-fis") || form.tooch_number.includes("84O-amf") || form.tooch_number.includes("84O-cof") || form.tooch_number.includes("84O-car") ?
  <>
    {form.tooch_number.includes("84O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="84O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 84O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#84O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("84-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("84O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 84O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("84-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("84O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="84O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 84O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("84-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("84O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 84O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("84-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <svg width="40" height="40">
    <defs>
      <pattern id="84O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
      </pattern>
    </defs>
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 84O"
      points="10,10 30,10 30,30 10,30"
      style={{ fill: '#007610', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("84-Oklusal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
  </svg>
}

{/* cfr */}
{form.tooch_number.includes("84-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{/* rrx */}
{form.tooch_number.includes("84-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 84-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
  v
</text>
}
      
{/* mis */}
{form.tooch_number.includes("84-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
        
</g>
:
<g>
{/* Atas */}
{form.tooch_number.includes("84L-fis") || form.tooch_number.includes("84L-amf") || form.tooch_number.includes("84L-cof") || form.tooch_number.includes("84L-car") ?
  <>
    {form.tooch_number.includes("84L-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="84L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 84L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: 'url(#84L-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("84-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("84L-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 84L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("84-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("84L-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="84L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 84L"
          points="0,0 40,0 30,10 10,10"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("84-Lingual")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("84L-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 84L"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("84-Lingual")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 84L"
    points="0,0 40,0 30,10 10,10"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("84-Lingual")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Bawah */}
{form.tooch_number.includes("84B-fis") || form.tooch_number.includes("84B-amf") || form.tooch_number.includes("84B-cof") || form.tooch_number.includes("84B-car") ?
  <>
    {form.tooch_number.includes("84B-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="84B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>

        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 84B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: 'url(#84B-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("84-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("84B-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 84B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("84-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("84B-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="84B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 84B"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("84-Bukal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("84B-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 84B"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("84-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 84B"
    points="10,30 30,30 40,40 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("84-Bukal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kanan */}
{form.tooch_number.includes("84M-fis") || form.tooch_number.includes("84M-amf") || form.tooch_number.includes("84M-cof") || form.tooch_number.includes("84M-car") ?
  <>
    {form.tooch_number.includes("84M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="84M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 84M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'url(#84M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("84-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("84M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 84M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("84-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("84M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="84M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 84M"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("84-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }
  
    {form.tooch_number.includes("84M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 84M"
        points="30,10 40,0 40,40 30,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("84-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }
  
  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 84M"
    points="30,10 40,0 40,40 30,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("84-Mesial")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* Kiri */}
{form.tooch_number.includes("84D-fis") || form.tooch_number.includes("84D-amf") || form.tooch_number.includes("84D-cof") || form.tooch_number.includes("84D-car") ?
  <>
    {form.tooch_number.includes("84D-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="84D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 84D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'url(#84D-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("84-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("84D-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 84D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("84-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("84D-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="84D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 84D"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("84-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("84D-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 84D"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("84-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 84D"
    points="0,0 10,10 10,30 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("84-Distal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* center */}
{form.tooch_number.includes("84O-fis") || form.tooch_number.includes("84O-amf") || form.tooch_number.includes("84O-cof") || form.tooch_number.includes("84O-car") ?
  <>
    {form.tooch_number.includes("84O-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="84O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 84O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: 'url(#84O-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("84-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("84O-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 84O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("84-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("84O-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="84O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 84O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("84-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("84O-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 84O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("84-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

  </>
  :
  <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 84O"
    points="10,10 30,10 30,30 10,30"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("84-Oklusal")}
    onMouseLeave={() => setInfoGigi("XX-X")}
  />
}

{/* cfr */}
{form.tooch_number.includes("84-cfr") &&
  <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
    #
  </text>
}

{form.tooch_number.includes("84-rrx") &&    
<text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 84-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
  v
</text>
}

{form.tooch_number.includes("84-mis") &&    
<>  
  <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
  <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
</> 
}
</g>
}      
</svg>

  {form.tooch_number.includes("84-pob-83-miss-pon-pob-82-pob")
||form.tooch_number.includes("84-pob-83-miss-pon-pob-82-miss-pon-pob-81-pob")?
<>
{/* meb 85 - 57 */}
{form.tooch_number.includes("84-pob-83-miss-pon-pob-82-pob") &&
<div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">
                      <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div>
}
                
{/* meb 85 - 53 */}
{form.tooch_number.includes("84-pob-83-miss-pon-pob-82-miss-pon-pob-81-pob") &&
<div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">

                      <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div>
}

</>
:
<></>
}


{form.tooch_number.includes("84-meb-83-miss-pon-meb-82-meb")
||form.tooch_number.includes("84-meb-83-miss-pon-meb-82-miss-pon-meb-81-meb")?
<>
{/* meb 85 - 83 */}
{form.tooch_number.includes("84-meb-83-miss-pon-meb-82-meb") &&
<div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">
  <g transform="rotate(180 108.5 6)">
    {/* Garis ke samping dan ke bawah (L-shape) */}
    <line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div>
}
                
{/* meb 85 - 53 */}
{form.tooch_number.includes("84-meb-83-miss-pon-meb-82-miss-pon-meb-81-meb") &&
<div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">

                      <g transform="rotate(180 108.5 6)">
 {/* Garis ke samping dan ke bawah (L-shape) */}
 <line x1="63" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="63" y1="0" x2="63" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>

</svg>
</div>
}

</>
:
<></>
}
        
  <div style={{ textAlign: "center" }}>84</div>

  {form.tooch_number.includes("84-une") || form.tooch_number.includes("84-non") || form.tooch_number.includes("84-pre") || form.tooch_number.includes("84-ano") || form.tooch_number.includes("84-ipx-poc") || form.tooch_number.includes("84-mis-prd-acr") || form.tooch_number.includes("84-mis-fld-acr") ?
  <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
  {form.tooch_number.includes("84-une") &&
  <div style={{ position: "absolute", color: "black" }}>UNE</div>
  }
  {form.tooch_number.includes("84-non") &&
  <div style={{ position: "absolute", color: "black" }}>NON</div>
  }      
  {form.tooch_number.includes("84-pre") &&
  <div style={{ position: "absolute", color: "black" }}>PRE</div>
  }      
  {form.tooch_number.includes("84-ano") &&
  <div style={{ position: "absolute", color: "black" }}>ANO</div>
  }
  {form.tooch_number.includes("84-ipx-poc") &&
  <div style={{ position: "absolute", color: "black" }}>IPX</div>
  }
  {form.tooch_number.includes("84-mis-prd-acr") &&
  <div style={{ position: "absolute", color: "black" }}>PRD</div>
  } 
  {form.tooch_number.includes("84-mis-fld-acr") &&
  <div style={{ position: "absolute", color: "black" }}>FLD</div>
  }      
  </div>
  :
  <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
  </div>
  }
  </div>
          
  <div style={{ width: "42px", margin: "1px" }}>

  {form.tooch_number.includes("83-nvt") || form.tooch_number.includes("83-rct") || form.tooch_number.includes("83B-amf-rct")
|| form.tooch_number.includes("83P-amf-rct") || form.tooch_number.includes("83M-amf-rct") || form.tooch_number.includes("83D-amf-rct")
|| form.tooch_number.includes("83L-amf-rct") ||
form.tooch_number.includes("83B-cof-rct") || form.tooch_number.includes("83P-cof-rct") || form.tooch_number.includes("83M-cof-rct")
|| form.tooch_number.includes("83D-cof-rct") || form.tooch_number.includes("83L-cof-rct") ||
form.tooch_number.includes("83-fmc-rct") ||
form.tooch_number.includes("83-poc-rct") ?
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%"}}>
<svg width={42} height={42}>
<polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 83B"
  points="21,2 41,32 1,32" 
  style={{ fill: form.tooch_number.includes("83-nvt") ? 'white' : 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('83-Bukal')}
  onMouseLeave={() => setInfoGigi('XX-X')}
/>
</svg>
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingTop: "13px" }}>
</div>
        } 
<svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("83-fmc") || form.tooch_number.includes("83-poc") || form.tooch_number.includes("83-ipx-poc") || form.tooch_number.match(/\b83-pob\b/) || form.tooch_number.match(/\b83-miss-pon-pob\b/) || form.tooch_number.match(/\b83-meb\b/) || form.tooch_number.match(/\b83-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
{form.tooch_number.includes("83-poc") || form.tooch_number.includes("83-ipx-poc") || form.tooch_number.match(/\b83-pob\b/) || form.tooch_number.match(/\b83-miss-pon-pob\b/) ?
<g>
 {/* Atas */}
 {form.tooch_number.includes("83P-fis") || form.tooch_number.includes("83P-amf") || form.tooch_number.includes("83P-cof") || form.tooch_number.includes("83P-car") ?
   <>
     {form.tooch_number.includes("83P-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="83P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 83P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: 'url(#83P-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("83-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />   
       </svg>
     }

     {form.tooch_number.includes("83P-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 83P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("83-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("83P-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="83P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 83P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("83-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("83P-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 83P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("83-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="83P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 83P"
       points="0,0 40,0 30,20 10,20"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("83-Palatal")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Bawah */}
 {form.tooch_number.includes("83L-fis") || form.tooch_number.includes("83L-amf") || form.tooch_number.includes("83L-cof") || form.tooch_number.includes("83L-car") ?
   <>
     {form.tooch_number.includes("83L-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="83L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>

         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 83L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: 'url(#83L-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("83-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("83L-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 83L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("83-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("83L-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="83L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 83L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("83-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("83L-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 83L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("83-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="83L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 83L"
       points="30,20 40,40 0,40 10,20"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("83-Labial")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Kanan */}
 {form.tooch_number.includes("83M-fis") || form.tooch_number.includes("83M-amf") || form.tooch_number.includes("83M-cof") || form.tooch_number.includes("83M-car") ?
   <>
     {form.tooch_number.includes("83M-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="83M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 83M"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: 'url(#83M-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("83-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("83M-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 83M"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("83-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("83M-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="83M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 83M"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("83-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }
   
     {form.tooch_number.includes("83M-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 83M"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("83-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="83M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 83M"
       points="30,20 40,0 40,40 35,30"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("83-Mesial")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Kiri */}
 {form.tooch_number.includes("83D-fis") || form.tooch_number.includes("83D-amf") || form.tooch_number.includes("83D-cof") || form.tooch_number.includes("83D-car") ?
   <>
     {form.tooch_number.includes("83D-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="83D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 83D"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: 'url(#83D-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("83-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("83D-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 83D"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("83-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("83D-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="83D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 83D"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("83-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("83D-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 83D"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("83-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="83D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 83D"
       points="0,0 5,10 10,20 0,40"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("83-Distal")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* cfr */}
 {form.tooch_number.includes("83-cfr") &&
   <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
     #
   </text>
 }

 {/* rrx */}
 {form.tooch_number.includes("83-rrx") &&    
 <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 83-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
   v
 </text>
 }
       
 {/* mis */}
 {form.tooch_number.includes("83-mis") &&    
 <>  
   <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
   <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
 </> 
 }
         
</g>
 :
<g>
 {/* Atas */}
 {form.tooch_number.includes("83P-fis") || form.tooch_number.includes("83P-amf") || form.tooch_number.includes("83P-cof") || form.tooch_number.includes("83P-car") ?
   <>
     {form.tooch_number.includes("83P-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="83P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 83P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: 'url(#83P-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("83-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("83P-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 83P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("83-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("83P-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="83P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 83P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("83-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("83P-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 83P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("83-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 83P"
     points="0,0 40,0 30,20 10,20"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("83-Palatal")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Bawah */}
 {form.tooch_number.includes("83L-fis") || form.tooch_number.includes("83L-amf") || form.tooch_number.includes("83L-cof") || form.tooch_number.includes("83L-car") ?
   <>
     {form.tooch_number.includes("83L-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="83L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>

         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 83L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: 'url(#83L-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("83-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("83L-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 83L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("83-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("83L-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="83L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 83L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("83-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("83L-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 83L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("83-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 83L"
     points="30,20 40,40 0,40 10,20"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("83-Labial")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Kanan */}
 {form.tooch_number.includes("83M-fis") || form.tooch_number.includes("83M-amf") || form.tooch_number.includes("83M-cof") || form.tooch_number.includes("83M-car") ?
   <>
     {form.tooch_number.includes("83M-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="83M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 83M"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: 'url(#83M-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("83-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("83M-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 83M"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("83-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("83M-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="83M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 83M"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("83-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }
   
     {form.tooch_number.includes("83M-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 83M"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("83-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 83M"
     points="30,20 40,0 40,40 35,30"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("83-Mesial")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Kiri */}
 {form.tooch_number.includes("83D-fis") || form.tooch_number.includes("83D-amf") || form.tooch_number.includes("83D-cof") || form.tooch_number.includes("83D-car") ?
   <>
     {form.tooch_number.includes("83D-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="83D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 83D"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: 'url(#83D-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("83-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("83D-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 83D"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("83-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("83D-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="83D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 83D"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("83-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("83D-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 83D"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("83-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 83D"
     points="0,0 5,10 10,20 0,40"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("83-Distal")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* cfr */}
 {form.tooch_number.includes("83-cfr") &&
   <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
     #
   </text>
 }
 
 {form.tooch_number.includes("83-rrx") &&    
 <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 83-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
   v
 </text>
 }
 
 {form.tooch_number.includes("83-mis") &&    
 <>  
   <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
   <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
 </> 
 }
</g>
}    
   
</svg>

{form.tooch_number.includes("83-pob-82-miss-pon-pob-81-pob")?
<>
{/* meb 85 - 57 */}
{form.tooch_number.includes("83-pob-82-miss-pon-pob-81-pob") &&
<div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">

                      <g transform="rotate(180 108.5 6)">
{/* Garis ke samping dan ke bawah (L-shape) */}
<line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
<line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div>
}
</>
:
<></>
}

{form.tooch_number.includes("83-meb-82-miss-pon-meb-81-meb")?
<>
{/* meb 85 - 83 */}
{form.tooch_number.includes("83-meb-82-miss-pon-meb-81-meb") &&
<div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
              <svg width="100%" height="20vh">

  <g transform="rotate(180 108.5 6)">
    {/* Garis ke samping dan ke bawah (L-shape) */}
    <line x1="108" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="108" y1="0" x2="108" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
 </g>
</svg>
</div>
}
</>
:
<></>
}

<div style = {{ textAlign: "center" }}> 83 </div>


{form.tooch_number.includes("83-une") || form.tooch_number.includes("83-non") || form.tooch_number.includes("83-pre") || form.tooch_number.includes("83-ano") || form.tooch_number.includes("83-ipx-poc") || form.tooch_number.includes("83-mis-prd-acr") || form.tooch_number.includes("83-mis-fld-acr") ?
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
{form.tooch_number.includes("83-une") &&
  <div style={{ position: "absolute", color: "black" }}>UNE</div>
}
{form.tooch_number.includes("83-non") &&
  <div style={{ position: "absolute", color: "black" }}>NON</div>
}      
{form.tooch_number.includes("83-pre") &&
  <div style={{ position: "absolute", color: "black" }}>PRE</div>
}      
{form.tooch_number.includes("83-ano") &&
  <div style={{ position: "absolute", color: "black" }}>ANO</div>
}
{form.tooch_number.includes("83-ipx-poc") &&
  <div style={{ position: "absolute", color: "black" }}>IPX</div>
}
{form.tooch_number.includes("83-mis-prd-acr") &&
  <div style={{ position: "absolute", color: "black" }}>PRD</div>
} 
{form.tooch_number.includes("83-mis-fld-acr") &&
  <div style={{ position: "absolute", color: "black" }}>FLD</div>
}      
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
</div>
}



</div> 
          
<div style={{ width: "42px", margin: "1px" }}>

{form.tooch_number.includes("82-nvt") || form.tooch_number.includes("82-rct") || form.tooch_number.includes("82B-amf-rct")
|| form.tooch_number.includes("82P-amf-rct") || form.tooch_number.includes("82M-amf-rct") || form.tooch_number.includes("82D-amf-rct")
|| form.tooch_number.includes("82L-amf-rct") ||
form.tooch_number.includes("82B-cof-rct") || form.tooch_number.includes("82P-cof-rct") || form.tooch_number.includes("82M-cof-rct")
|| form.tooch_number.includes("82D-cof-rct") || form.tooch_number.includes("82L-cof-rct") ||
form.tooch_number.includes("82-fmc-rct") ||
form.tooch_number.includes("82-poc-rct") ?
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%"}}>
<svg width={42} height={42}>
<polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 82B"
  points="21,2 41,32 1,32" 
  style={{ fill: form.tooch_number.includes("82-nvt") ? 'white' : 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('82-Bukal')}
  onMouseLeave={() => setInfoGigi('XX-X')}
/>
</svg>
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingTop: "13px" }}>
</div>
        } 
<svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("82-fmc") || form.tooch_number.includes("82-poc") || form.tooch_number.includes("82-ipx-poc") || form.tooch_number.match(/\b82-pob\b/) || form.tooch_number.match(/\b82-miss-pon-pob\b/) || form.tooch_number.match(/\b82-meb\b/) || form.tooch_number.match(/\b82-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
{form.tooch_number.includes("82-poc") || form.tooch_number.includes("82-ipx-poc") || form.tooch_number.match(/\b82-pob\b/) || form.tooch_number.match(/\b82-miss-pon-pob\b/) ?
<g>
 {/* Atas */}
 {form.tooch_number.includes("82P-fis") || form.tooch_number.includes("82P-amf") || form.tooch_number.includes("82P-cof") || form.tooch_number.includes("82P-car") ?
   <>
     {form.tooch_number.includes("82P-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="82P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 82P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: 'url(#82P-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("82-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />   
       </svg>
     }

     {form.tooch_number.includes("82P-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 82P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("82-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("82P-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="82P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 82P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("82-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("82P-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 82P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("82-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="82P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 82P"
       points="0,0 40,0 30,20 10,20"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("82-Palatal")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Bawah */}
 {form.tooch_number.includes("82L-fis") || form.tooch_number.includes("82L-amf") || form.tooch_number.includes("82L-cof") || form.tooch_number.includes("82L-car") ?
   <>
     {form.tooch_number.includes("82L-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="82L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>

         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 82L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: 'url(#82L-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("82-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("82L-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 82L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("82-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("82L-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="82L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 82L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("82-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("82L-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 82L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("82-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="82L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 82L"
       points="30,20 40,40 0,40 10,20"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("82-Labial")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Kanan */}
 {form.tooch_number.includes("82M-fis") || form.tooch_number.includes("82M-amf") || form.tooch_number.includes("82M-cof") || form.tooch_number.includes("82M-car") ?
   <>
     {form.tooch_number.includes("82M-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="82M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 82M"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: 'url(#82M-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("82-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("82M-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 82M"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("82-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("82M-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="82M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 82M"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("82-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }
   
     {form.tooch_number.includes("82M-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 82M"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("82-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="82M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 82M"
       points="30,20 40,0 40,40 35,30"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("82-Mesial")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Kiri */}
 {form.tooch_number.includes("82D-fis") || form.tooch_number.includes("82D-amf") || form.tooch_number.includes("82D-cof") || form.tooch_number.includes("82D-car") ?
   <>
     {form.tooch_number.includes("82D-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="82D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 82D"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: 'url(#82D-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("82-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("82D-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 82D"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("82-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("82D-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="82D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 82D"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("82-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("82D-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 82D"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("82-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="82D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 82D"
       points="0,0 5,10 10,20 0,40"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("82-Distal")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* cfr */}
 {form.tooch_number.includes("82-cfr") &&
   <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
     #
   </text>
 }

 {/* rrx */}
 {form.tooch_number.includes("82-rrx") &&    
 <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 82-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
   v
 </text>
 }
       
 {/* mis */}
 {form.tooch_number.includes("82-mis") &&    
 <>  
   <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
   <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
 </> 
 }
         
</g>
 :
<g>
 {/* Atas */}
 {form.tooch_number.includes("82P-fis") || form.tooch_number.includes("82P-amf") || form.tooch_number.includes("82P-cof") || form.tooch_number.includes("82P-car") ?
   <>
     {form.tooch_number.includes("82P-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="82P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 82P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: 'url(#82P-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("82-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("82P-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 82P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("82-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("82P-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="82P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 82P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("82-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("82P-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 82P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("82-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 82P"
     points="0,0 40,0 30,20 10,20"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("82-Palatal")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Bawah */}
 {form.tooch_number.includes("82L-fis") || form.tooch_number.includes("82L-amf") || form.tooch_number.includes("82L-cof") || form.tooch_number.includes("82L-car") ?
   <>
     {form.tooch_number.includes("82L-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="82L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>

         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 82L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: 'url(#82L-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("82-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("82L-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 82L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("82-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("82L-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="82L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 82L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("82-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("82L-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 82L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("82-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 82L"
     points="30,20 40,40 0,40 10,20"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("82-Labial")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Kanan */}
 {form.tooch_number.includes("82M-fis") || form.tooch_number.includes("82M-amf") || form.tooch_number.includes("82M-cof") || form.tooch_number.includes("82M-car") ?
   <>
     {form.tooch_number.includes("82M-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="82M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 82M"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: 'url(#82M-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("82-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("82M-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 82M"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("82-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("82M-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="82M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 82M"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("82-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }
   
     {form.tooch_number.includes("82M-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 82M"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("82-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 82M"
     points="30,20 40,0 40,40 35,30"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("82-Mesial")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Kiri */}
 {form.tooch_number.includes("82D-fis") || form.tooch_number.includes("82D-amf") || form.tooch_number.includes("82D-cof") || form.tooch_number.includes("82D-car") ?
   <>
     {form.tooch_number.includes("82D-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="82D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 82D"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: 'url(#82D-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("82-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("82D-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 82D"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("82-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("82D-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="82D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 82D"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("82-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("82D-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 82D"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("82-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 82D"
     points="0,0 5,10 10,20 0,40"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("82-Distal")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* cfr */}
 {form.tooch_number.includes("82-cfr") &&
   <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
     #
   </text>
 }
 
 {form.tooch_number.includes("82-rrx") &&    
 <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 82-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
   v
 </text>
 }
 
 {form.tooch_number.includes("82-mis") &&    
 <>  
   <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
   <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
 </> 
 }
</g>
}    
   
</svg>


<div style = {{ textAlign: "center" }}> 82 </div>


{form.tooch_number.includes("82-une") || form.tooch_number.includes("82-non") || form.tooch_number.includes("82-pre") || form.tooch_number.includes("82-ano") || form.tooch_number.includes("82-ipx-poc") || form.tooch_number.includes("82-mis-prd-acr") || form.tooch_number.includes("82-mis-fld-acr") ?
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
 {form.tooch_number.includes("82-une") &&
   <div style={{ position: "absolute", color: "black" }}>UNE</div>
 }
 {form.tooch_number.includes("82-non") &&
   <div style={{ position: "absolute", color: "black" }}>NON</div>
 }      
 {form.tooch_number.includes("82-pre") &&
   <div style={{ position: "absolute", color: "black" }}>PRE</div>
 }      
 {form.tooch_number.includes("82-ano") &&
   <div style={{ position: "absolute", color: "black" }}>ANO</div>
 }
 {form.tooch_number.includes("82-ipx-poc") &&
   <div style={{ position: "absolute", color: "black" }}>IPX</div>
 }
 {form.tooch_number.includes("82-mis-prd-acr") &&
   <div style={{ position: "absolute", color: "black" }}>PRD</div>
 } 
 {form.tooch_number.includes("82-mis-fld-acr") &&
   <div style={{ position: "absolute", color: "black" }}>FLD</div>
 }      
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
</div>
}



 </div> 
      
  <div style={{ width: "42px", margin: "1px" }}>

  {form.tooch_number.includes("81-nvt") || form.tooch_number.includes("81-rct") || form.tooch_number.includes("81B-amf-rct")
|| form.tooch_number.includes("81P-amf-rct") || form.tooch_number.includes("81M-amf-rct") || form.tooch_number.includes("81D-amf-rct")
|| form.tooch_number.includes("81L-amf-rct") ||
form.tooch_number.includes("81B-cof-rct") || form.tooch_number.includes("81P-cof-rct") || form.tooch_number.includes("81M-cof-rct")
|| form.tooch_number.includes("81D-cof-rct") || form.tooch_number.includes("81L-cof-rct") ||
form.tooch_number.includes("81-fmc-rct") ||
form.tooch_number.includes("81-poc-rct") ?
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%"}}>
<svg width={42} height={42}>
<polygon
  onClick={onclikTooch}
  data-nomor-gigi=" 81B"
  points="21,2 41,32 1,32" 
  style={{ fill: form.tooch_number.includes("81-nvt") ? 'white' : 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
  onMouseEnter={() => setInfoGigi('81-Bukal')}
  onMouseLeave={() => setInfoGigi('XX-X')}
/>
</svg>
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingTop: "13px" }}>
</div>
        } 
<svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("81-fmc") || form.tooch_number.includes("81-poc") || form.tooch_number.includes("81-ipx-poc") || form.tooch_number.match(/\b81-pob\b/) || form.tooch_number.match(/\b81-miss-pon-pob\b/) || form.tooch_number.match(/\b81-meb\b/) || form.tooch_number.match(/\b81-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
{form.tooch_number.includes("81-poc") || form.tooch_number.includes("81-ipx-poc") || form.tooch_number.match(/\b81-pob\b/) || form.tooch_number.match(/\b81-miss-pon-pob\b/) ?
<g>
 {/* Atas */}
 {form.tooch_number.includes("81P-fis") || form.tooch_number.includes("81P-amf") || form.tooch_number.includes("81P-cof") || form.tooch_number.includes("81P-car") ?
   <>
     {form.tooch_number.includes("81P-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="81P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 81P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: 'url(#81P-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("81-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />   
       </svg>
     }

     {form.tooch_number.includes("81P-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 81P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("81-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("81P-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="81P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 81P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("81-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("81P-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 81P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("81-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="81P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 81P"
       points="0,0 40,0 30,20 10,20"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("81-Palatal")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Bawah */}
 {form.tooch_number.includes("81L-fis") || form.tooch_number.includes("81L-amf") || form.tooch_number.includes("81L-cof") || form.tooch_number.includes("81L-car") ?
   <>
     {form.tooch_number.includes("81L-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="81L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>

         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 81L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: 'url(#81L-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("81-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("81L-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 81L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("81-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("81L-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="81L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 81L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("81-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("81L-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 81L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("81-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="81L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 81L"
       points="30,20 40,40 0,40 10,20"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("81-Labial")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Kanan */}
 {form.tooch_number.includes("81M-fis") || form.tooch_number.includes("81M-amf") || form.tooch_number.includes("81M-cof") || form.tooch_number.includes("81M-car") ?
   <>
     {form.tooch_number.includes("81M-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="81M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 81M"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: 'url(#81M-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("81-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("81M-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 81M"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("81-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("81M-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="81M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 81M"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("81-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }
   
     {form.tooch_number.includes("81M-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 81M"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("81-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="81M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 81M"
       points="30,20 40,0 40,40 35,30"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("81-Mesial")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* Kiri */}
 {form.tooch_number.includes("81D-fis") || form.tooch_number.includes("81D-amf") || form.tooch_number.includes("81D-cof") || form.tooch_number.includes("81D-car") ?
   <>
     {form.tooch_number.includes("81D-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="81D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 81D"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: 'url(#81D-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("81-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("81D-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 81D"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("81-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("81D-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="81D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 81D"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("81-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("81D-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 81D"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("81-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

   </>
   :
   <svg width="40" height="40">
     <defs>
       <pattern id="81D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
         <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
       </pattern>
     </defs>
     <polygon
       onClick={onclikTooch}
       data-nomor-gigi=" 81D"
       points="0,0 5,10 10,20 0,40"
       style={{ fill: '#007610', stroke: 'black' }}
       onMouseEnter={() => setInfoGigi("81-Distal")}
       onMouseLeave={() => setInfoGigi("XX-X")}
     />
   </svg>
 }

 {/* cfr */}
 {form.tooch_number.includes("81-cfr") &&
   <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
     #
   </text>
 }

 {/* rrx */}
 {form.tooch_number.includes("81-rrx") &&    
 <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 81-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
   v
 </text>
 }
       
 {/* mis */}
 {form.tooch_number.includes("81-mis") &&    
 <>  
   <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
   <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
 </> 
 }
         
</g>
 :
<g>
 {/* Atas */}
 {form.tooch_number.includes("81P-fis") || form.tooch_number.includes("81P-amf") || form.tooch_number.includes("81P-cof") || form.tooch_number.includes("81P-car") ?
   <>
     {form.tooch_number.includes("81P-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="81P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 81P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: 'url(#81P-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("81-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("81P-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 81P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("81-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("81P-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="81P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 81P"
           points="0,0 40,0 30,20 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("81-Palatal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("81P-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 81P"
         points="0,0 40,0 30,20 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("81-Palatal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 81P"
     points="0,0 40,0 30,20 10,20"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("81-Palatal")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Bawah */}
 {form.tooch_number.includes("81L-fis") || form.tooch_number.includes("81L-amf") || form.tooch_number.includes("81L-cof") || form.tooch_number.includes("81L-car") ?
   <>
     {form.tooch_number.includes("81L-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="81L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>

         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 81L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: 'url(#81L-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("81-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("81L-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 81L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("81-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("81L-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="81L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 81L"
           points="30,20 40,40 0,40 10,20"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("81-Labial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("81L-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 81L"
         points="30,20 40,40 0,40 10,20"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("81-Labial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 81L"
     points="30,20 40,40 0,40 10,20"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("81-Labial")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Kanan */}
 {form.tooch_number.includes("81M-fis") || form.tooch_number.includes("81M-amf") || form.tooch_number.includes("81M-cof") || form.tooch_number.includes("81M-car") ?
   <>
     {form.tooch_number.includes("81M-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="81M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 81M"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: 'url(#81M-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("81-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("81M-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 81M"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("81-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("81M-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="81M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 81M"
           points="30,20 40,0 40,40 35,30"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("81-Mesial")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }
   
     {form.tooch_number.includes("81M-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 81M"
         points="30,20 40,0 40,40 35,30"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("81-Mesial")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }
   
   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 81M"
     points="30,20 40,0 40,40 35,30"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("81-Mesial")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* Kiri */}
 {form.tooch_number.includes("81D-fis") || form.tooch_number.includes("81D-amf") || form.tooch_number.includes("81D-cof") || form.tooch_number.includes("81D-car") ?
   <>
     {form.tooch_number.includes("81D-fis") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="81D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
             <rect width="8" height="8" fill="#ffb8c6" />
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 81D"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: 'url(#81D-fis)', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("81-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("81D-amf") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 81D"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'black', stroke: 'black' }}
         onMouseEnter={() => setInfoGigi("81-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

     {form.tooch_number.includes("81D-cof") &&
       <svg width="40" height="40">
         <defs>
           <pattern id="81D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
             <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
           </pattern>
         </defs>
         <polygon
           onClick={onclikTooch}
           data-nomor-gigi=" 81D"
           points="0,0 5,10 10,20 0,40"
           style={{ fill: '#007610', stroke: 'black' }}
           onMouseEnter={() => setInfoGigi("81-Distal")}
           onMouseLeave={() => setInfoGigi("XX-X")}
         />
       </svg>
     }

     {form.tooch_number.includes("81D-car") &&
       <polygon
         onClick={onclikTooch}
         data-nomor-gigi=" 81D"
         points="0,0 5,10 10,20 0,40"
         style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
         onMouseEnter={() => setInfoGigi("81-Distal")}
         onMouseLeave={() => setInfoGigi("XX-X")}
       />
     }

   </>
   :
   <polygon
     onClick={onclikTooch}
     data-nomor-gigi=" 81D"
     points="0,0 5,10 10,20 0,40"
     style={{ fill: 'white', stroke: 'black' }}
     onMouseEnter={() => setInfoGigi("81-Distal")}
     onMouseLeave={() => setInfoGigi("XX-X")}
   />
 }

 {/* cfr */}
 {form.tooch_number.includes("81-cfr") &&
   <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
     #
   </text>
 }
 
 {form.tooch_number.includes("81-rrx") &&    
 <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 81-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
   v
 </text>
 }
 
 {form.tooch_number.includes("81-mis") &&    
 <>  
   <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
   <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
 </> 
 }
</g>
}    
   
</svg>
  

<div style = {{ textAlign: "center" }}> 81 </div>


{form.tooch_number.includes("81-une") || form.tooch_number.includes("81-non") || form.tooch_number.includes("81-pre") || form.tooch_number.includes("81-ano") || form.tooch_number.includes("81-ipx-poc") || form.tooch_number.includes("81-mis-prd-acr") || form.tooch_number.includes("81-mis-fld-acr") ?
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
  {form.tooch_number.includes("81-une") &&
    <div style={{ position: "absolute", color: "black" }}>UNE</div>
  }
  {form.tooch_number.includes("81-non") &&
    <div style={{ position: "absolute", color: "black" }}>NON</div>
  }      
  {form.tooch_number.includes("81-pre") &&
    <div style={{ position: "absolute", color: "black" }}>PRE</div>
  }      
  {form.tooch_number.includes("81-ano") &&
    <div style={{ position: "absolute", color: "black" }}>ANO</div>
  }
  {form.tooch_number.includes("81-ipx-poc") &&
    <div style={{ position: "absolute", color: "black" }}>IPX</div>
  }
  {form.tooch_number.includes("81-mis-prd-acr") &&
    <div style={{ position: "absolute", color: "black" }}>PRD</div>
  } 
  {form.tooch_number.includes("81-mis-fld-acr") &&
    <div style={{ position: "absolute", color: "black" }}>FLD</div>
  }      
</div>
:
<div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
</div>
}



  </div> 
</div>
  );
}
