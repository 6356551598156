import AdminHome from "../../components/AdminAppointment/AppointmentInvoiceSetup";
import React from "react";
import { useContext, useEffect } from "react";
import { UserContext } from "../../context/userContext";
import { API, setAuthToken } from "../../config/api";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { Row } from "react-bootstrap";
import { Container, Col, Table, Button } from 'react-bootstrap';
import Sidebar from "../../components/Sidebar/Sidebar";
import NavbarMobile from "../../components/Navbar/NavbarAdmin/NavbarMobile"
import NavbarDekstop from "../../components/Navbar/NavbarAdmin/NavbarDekstop"
// import NavbarMobile from "../../components/Navbar/NavbarAdminPasien/NavbarMobile"
// import NavbarDekstop from "../../components/Navbar/NavbarAdminPasien/NavbarDekstop"
// init token on axios every time the app is refreshed

// if (localStorage.token) {
//   setAuthToken(localStorage.token);
// }

export default function Home() {
  // let navigate = useNavigate();
  // useEffect(() => {
  //   if (localStorage.getItem("token") == null || undefined) {
  //       navigate("/");
  //     }
  // },[]);

  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)'})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)'})
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const [state, dispatch] = useContext(UserContext);

  return (
  <div>
    {isTabletOrMobile?
    <Row>
      <Col xl='12' sm='12'style={{minHeight:"800px", maxHeight:"100%"}}>
        <NavbarMobile/>
        <AdminHome/>
      </Col>        
    </Row>
      :
    <AdminHome/>
    }  
  </div>
  );
}
