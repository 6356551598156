import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaTimes} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../../assets/signature/signature logo.png"
import Logo_Batik from "../../../assets/signature/logo-batik.png"
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
import "../Styled.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
import LoaderHome from "../../Loader/LoaderHome"
  
export default function ModalRoleUpdate(props) {
   // let navigate = useNavigate();
  // useEffect(() => {
  //   if (localStorage.getItem("token") == null || undefined) {
  //       navigate("/");
  //     }
  // }, []);
  document.title = "SDC Apps";
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(1000);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const token = localStorage.getItem("token");
  const [getDataDokter, setGetDataDokter] = useState([]);
  const [search, setSearch] = useState("")
  
  // Untuk Close Proops Data
  const [propsData, setProopsData] = useState()
  const [loading, setLoading] = useState(false);
  const [getDataRoom, setGetDataRoom] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  // const [formSubModule, setFormTreatment] = useState({ assistants: [] });
  const [inputList, setInputList] = useState([
    {
      id_menu: "",
    },
  ]);  
  // Menghapus nilai null dalam array assistant di inputList

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const [formSubModule, setFormTreatment] = useState({
    subModule: [],
  });


  const GetResponseData = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`user-service/privileges/module-menus?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&search=${search}&id_module=${props.idTrash}`,fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetDataRoom(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  useEffect(() => {
    GetResponseData()
  },[])

  const handleSubmit = useMutation(async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
  
      const payload = {
        menus: formSubModule?.subModule.map(item => ({ id_menu: item.id_menu })),
      };
  
      // permintaan DELETE dengan payload
      const response = await API.delete(`user-service/privileges/module-menus/${props.idTrash}`, {
        data: payload,
        ...fetchParams, 
      });
  
      // Periksa respons
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false,
        });
        props.GetResponseData()
        props.onHide()
        setLoading(false)
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response?.data?.message || 'An error occurred'}`,
        icon: 'error',
        timer: 3000,
        buttons: false,
      });
      setLoading(false);
    }
  });
  
  // console.log(formSubModule.subModule)

  const handleChangeCheckbox = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { subModule } = formSubModule;
    // console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setFormTreatment({
        subModule: [...subModule, {"id_menu" : `${value}`}],
      });
    }
  
    // Case 2  : The user unchecks the box
    else {
      setFormTreatment({
        subModule: subModule.filter(e => {return e.id_menu !== `${value}`} ),

      });
    }
  };

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{fontFamily:"sans-serif",border:"none"}}>
      {loading && <LoaderHome />}
    
      <div style={{ width: "100%", display: "flex", padding: "10px 0px", backgroundColor: "#29B8FF" }}>
        <div style={{flex:"92%", fontSize:"20px",display:"flex",alignItems:"center", paddingLeft:"10px", color:"white", fontWeight:"600"}}>
         Hapus Menu Sub Module
        </div> 
        <div  style={{flex:"8%",fontSize:"30px",display:"flex",alignItems:"center",justifyContent:"center", color:"white"}}>
          <FaTimes onClick={() => setProopsData(props.onHide)} style={{cursor:"pointer"}}/>
        </div> 
      </div>
      <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px",border:"none" }}>
      <Form onSubmit={(e) => handleSubmit.mutate(e)} style={{ fontFamily: "sans-serif" }} >
        {inputList.map((x, i) => {
        return (
        <div className="box">
        {/* <div className="mt-3" style={{ display: "flex"}}>
          <div style={{flex:"20%", display:"flex",alignItems:"center"}}>
            Pilih Modules Sub Menu
          </div>
          <div style={{ flex: "80%", display:"flex"}}>
            <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <select className="form-select" aria-label="Default select example"  onChange={(e) => handleInputChange(e, i, "id_menu")}  name="id_menu" style={{ textAlign:"", cursor:"pointer"}}>
              <option value="" >Select </option>
              {getDataRoom.map((user,index) => (
                <option value={user?.id} style={{textAlign:""}}>{user?.name}</option>
              ))}         
            </select>
          </div>
        </div> */}
            
        <div className="mt-3" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex", alignItems:""}}>
           Pilih Menu yang Ingin Dihapus
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
          <p style={{ marginRight: "10px", display: "flex", alignItems: "", height: "100%" }}>:</p>
        <div>
          {getDataRoom.map((user,index) => (
            <div key={index} className="mb-3" style={{display:"flex", width:"100%", cursor:"pointer"}}>
              <input autoFofcus type='checkbox' value={user?.id_menu} name="id_menu" onChange={handleChangeCheckbox} style={{display:"flex", alignItems:"center",marginRight:"5px", border: "none", borderBottom: "1px solid #804D00", cursor:"pointer" }} />
              <div style={{display:"flex", alignItems:"center"}}>
                {user?.menu_data.name}
              </div>
            </div>
          ))}
          </div>
        </div>
      </div>
    
      </div>
      );
      })}

      <div style={{ padding: "0px 0px", marginTop: "0px", display:"flex", justifyContent:"end" }}>
        <div style={{width:"20%"}}>
          <Button className="mt-4" type='submit' color='primary' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px"}}>
            Hapus
          </Button>
        </div>
      </div>
    </Form>
        
    </Modal.Body>
    </Modal>
    );
}
