import { useContext, useEffect, useState } from "react";
import { Navigate, Routes, useNavigate, } from "react-router-dom";
import { UserContext } from "./context/userContext";
import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
// Auth
import Auth from "./pages/Auth/Auth";
import AuthStaff from "./pages/Auth/AuthAdmin";

import RegisterPasien from "./pages/RegisterPasien/Register";
import RegisterPasienOnline from "./pages/RegisterPasien/RegisterOnline";
import LoginPasienOnline from "./pages/RegisterPasien/LoginOnline";
import DashboardPasienOnline from "./pages/RegisterPasien/HomePagePasienOnline";
import DashboardPasienOnlinePenampung from "./pages/RegisterPasien/HomePagePasienOnlinepenampung";
import DashboardPasienOnlinePenampungTambah from "./pages/RegisterPasien/HomePagePasienOnlinepenampungTambah";
import DashboardPasienOnlineProfile from "./pages/RegisterPasien/HomePagePasienOnlineProfile";
import DashboardPasienOnlinePassword from "./pages/RegisterPasien/HomePagePasienOnlinePassword";
import DashboardPasienOnlineDoctor from "./pages/RegisterPasien/HomePagePasienOnlineDoctor";
import AppoinmentPasien from "./pages/AppoinmentPasien/AppoinmentPasien";
import AppoinmentPasienOnline from "./pages/AppoinmentPasien/AppoinmentPasienOnline";

// Admin Pasien Medis Record
import AdminHome from "./pages/Admin/AdminHome";
import AdminPasien from "./pages/AdminPasien/AdminPasien";
import AdminPasienPhysicalCheck from "./pages/AdminPasien/AdminPasienPhysicalCheck";
import AdminPasienMedicalAttention from "./pages/AdminPasien/AdminPasienMedicalAttention";
import AdminPasienMedicalRecord from "./pages/AdminPasien/AdminPasienMedicalRecord";
import AdminPasienOdontogram from "./pages/AdminPasien/AdminPasienOdontogram";
import AdminPasienSoap from "./pages/AdminPasien/AdminPasienSoap";

import AdminPasienSetup from "./pages/AdminPasien/AdminPasienSetup";
import AdminPasienOdontogramSetup from "./pages/AdminPasien/AdminPasienPrintoutOdontogram";

// Admin Pasien 
import AdminPasienAdd from "./pages/AdminPasien/AdminPasienAdd";
import AdminPasienUpdate from "./pages/AdminPasien/AdminPasienUpdate";
import AdminPasienAddTreatment from "./pages/Admin/AdminPasienTreatmentAdd";
import AdminPasienUpdateTreatment from "./pages/Admin/AdminPasienTreatmentUpdate";

// Admin Item
import AdminItem from "./pages/AdminItem/AdminItem";

// Admin Pasien
import Appointment from "./pages/AdminAppointment/Appointment";
import AppointmentAdd from "./pages/AdminAppointment/AppointmentAdd";
import AppointmentUpdate from "./pages/AdminAppointment/AppointmentUpdate";
import AppointmentTreatmentPlanAdd from "./pages/AdminAppointment/AppointmentTreatmentAdd";
import AppointmentPasienSetup from "./pages/AdminAppointment/AppointmentPasienSetup";

// Admin Pasien
import Treatment from "./pages/AdminTreatment/Treatment";
// import TreatmentAdd from "./pages/AdminTreatment/TreatmentAdd";
// import TreatmentUpdate from "./pages/AdminTreatment/TreatmentUpdate";

// Admin Pasien
import TreatmentPrices from "./pages/AdminTreatmentPrices/TreatmentPrices";
// import TreatmentPricesAdd from "./pages/AdminTreatmentPrices/TreatmentPricesAdd";
// import TreatmentPricesUpdate from "./pages/AdminTreatmentPrices/TreatmentPricesUpdate";

// Admin Pasien
import TreatmentCategory from "./pages/AdminTreatmentCategory/TreatmentCategory";
import TreatmentCategoryAdd from "./pages/AdminTreatmentCategory/TreatmentCategoryAdd";
import TreatmentCategoryUpdate from "./pages/AdminTreatmentCategory/TreatmentCategoryUpdate";

// Admin Agreement
import Agreement from "./pages/AdminAgreement/Agreement";
import AgreementAdd from "./pages/AdminAgreement/AgreementAdd";
import AgreementUpdate from "./pages/AdminAgreement/AgreementUpdate";

// Admin Room
import Room from "./pages/AdminRoom/Room";
import RoomAdd from "./pages/AdminRoom/RoomAdd";
import RoomUpdate from "./pages/AdminRoom/RoomUpdate";

// Admin Room
import Floor from "./pages/AdminFloor/Floor";
import FloorAdd from "./pages/AdminFloor/FloorAdd";
import FloorUpdate from "./pages/AdminFloor/FloorUpdate";

// Admin Payment
import Payment from "./pages/AdminPayment/Payment";
import PaymentAdd from "./pages/AdminPayment/PaymentAdd";
import PaymentUpdate from "./pages/AdminPayment/PaymentUpdate";

// Admin Payment
import PaymentType from "./pages/AdminPaymentType/PaymentType";
import PaymentTypeAdd from "./pages/AdminPaymentType/PaymentTypeAdd";
import PaymentTypeUpdate from "./pages/AdminPaymentType/PaymentTypeUpdate";

// Admin Payment
import Fees from "./pages/AdminFees/Fees";
import FeesAdd  from "./pages/AdminFees/FeesAdd";
import FeesUpdate from "./pages/AdminFees/FeesUpdate";

// Admin Payment
import Schedules from "./pages/AdminSchedules/Schedules";
import SchedulesAdd  from "./pages/AdminSchedules/SchedulesAdd";
import SchedulesUpdate from "./pages/AdminSchedules/SchedulesUpdate";

// Admin Consent
import Consent from "./pages/AdminConsent/Consent";
import ConsentAdd from "./pages/AdminConsent/ConsentAdd";
import ConsentUpdate from "./pages/AdminConsent/ConsentUpdate";

// Admin Role
import AdminRole from "./pages/AdminRole/AdminRole";
import AdminRoleAdd from "./pages/AdminRole/AdminRoleAdd";
import AdminRoleUpdate from "./pages/AdminRole/AdminRoleUpdate";

// Admin Role Permission
import AdminRolePermision from "./pages/AdminRolePermission/AdminRolePermission";

// Admin User Privilage
import AdminUserPrivilage from "./pages/AdminUserPrivilage/AdminUserPrivilage";
import AdminUserAddPrivilage from "./pages/AdminUserPrivilage/AdminUserAddPrivilage";
import AdminUserUpdatePrivilage from "./pages/AdminUserPrivilage/AdminUserUpdatePrivilage";

// Admin User
import AdminUser from "./pages/AdminUser/AdminUser";
import AdminUserAdd from "./pages/AdminUser/AdminUserAdd";
import AdminUserUpdate from "./pages/AdminUser/AdminUserUpdate";

// Admin Doctor Spesialis
import AdminDoctorSpecialist from "./pages/AdminUserSpecialist/AdminUserSpecialist";

// Admin Specialist
import AdminSpecialist from "./pages/AdminSpecialist/AdminSpecialist";

// Admin Specialist
import AdminPersentaseAssurance from "./pages/AdminPersentaseAssurance/AdminPersentaseAssurance";

// Admin User Permisiion
import AdminUserPermission from "./pages/AdminUserPermission/AdminUserPermission";

// Admin Room
import AdminMenus from "./pages/AdminMenus/Menus";
import AdminMenusAdd from "./pages/AdminMenus/MenusAdd";
import AdminMenusUpdate from "./pages/AdminMenus/MenusUpdate";

// Admin Role
import Invoices from "./pages/AdminInvoice/Invoices";
import InvoicesSetup from "./pages/AdminInvoice/InvoicesSetup";
import InvoicesAssuranceSetup from "./pages/AdminInvoiceAssurance/InvoiceAssuranceSetup";
import InvoicesAdd from "./pages/AdminInvoice/InvoicesAdd";
import InvoicesUpdate from "./pages/AdminInvoice/InvoicesUpdate";

// Admin Role
import MedicalInvoices from "./pages/AdminInvoiceMedical/InvoicesMedical";
import MedicalInvoicesSetup from "./pages/AdminInvoiceMedical/InvoicesMedicalSetup";
import MedicalInvoicesAdd from "./pages/AdminInvoiceMedical/InvoicesMedicalAdd";
import MedicalInvoicesUpdate from "./pages/AdminInvoiceMedical/InvoicesMedicalUpdate";

// Treatment Plan
import TreatmentPlan from "./pages/AdminTreatmentPlan/TreatmentPlan";
import TreatmentPlanAdd from "./pages/AdminTreatmentPlan/TreatmentPlanAdd";
import TreatmentPlanUpdate from "./pages/AdminTreatmentPlan/TreatmentPlanUpdate";

// Admin Modules
import AdminModules from "./pages/AdminModules/AdminModules";
import AdminModulesAdd from "./pages/AdminModules/AdminModulesAdd";
import AdminModulesUpdate from "./pages/AdminModules/AdminModulesUpdate";

// Admin Sub Modules
import AdminSUbModules from "./pages/AdminModulesSub/AdminModulesSub";

// Admin Sub Modules
import AdminDiagnosaPatient from "./pages/AdminDiagnosaPatient/AdminDiagnosaPatient";

// Admin Tooth Surface
import ToothSurfaces from "./pages/AdminOdontogramToothSurfaces/AdminOdontogramToothSurfaces";
import ToothSurfacesAdd  from "./pages/AdminOdontogramToothSurfaces/AdminOdontogramToothSurfacesAdd";
import ToothSurfacesUpdate from "./pages/AdminOdontogramToothSurfaces/AdminOdontogramToothSurfacesUpdate";

// Admin Tooth Condition
import ToothCondition from "./pages/AdminOdontogramToothCondition/AdminOdontogramToothCondition";
import ToothConditionAdd  from "./pages/AdminOdontogramToothCondition/AdminOdontogramToothConditionAdd";
import ToothConditionUpdate from "./pages/AdminOdontogramToothCondition/AdminOdontogramToothConditionUpdate";

// Admin Protesa
import Protesa from "./pages/AdminOdontogramProtesa/AdminOdontogramProtesa";
import ProtesaAdd  from "./pages/AdminOdontogramProtesa/AdminOdontogramProtesaAdd";
import ProtesaUpdate from "./pages/AdminOdontogramProtesa/AdminOdontogramProtesaUpdate";

// Admin Restorative
import Restorative from "./pages/AdminOdontogramRestorative/AdminOdontogramRestorative";
import RestorativeAdd  from "./pages/AdminOdontogramRestorative/AdminOdontogramRestorativeAdd";
import RestorativeUpdate from "./pages/AdminOdontogramRestorative/AdminOdontogramRestorativeUpdate";

// Admin Restorative Material
import RestorativeMaterial from "./pages/AdminOdontogramRestorativeMaterial/AdminOdontogramRestorativeMaterial";
import RestorativeMaterialAdd  from "./pages/AdminOdontogramRestorativeMaterial/AdminOdontogramRestorativeMaterialAdd";
import RestorativeMaterialUpdate from "./pages/AdminOdontogramRestorativeMaterial/AdminOdontogramRestorativeMaterialUpdate";

// Admin Finance User Fee
import FinanceUserFee from "./pages/AdminFinanceUserFee/FinanceUserFee";
import FinanceUserFeeAdd from "./pages/AdminFinanceUserFee/FinanceUserFeeAdd";
import FinanceUserFeeUpdate from "./pages/AdminFinanceUserFee/FinanceUserFeeUpdate";

// Admin Finance Rekap User Fee
import FinanceRekapUserFee from "./pages/AdminFinanceRekapUserFee/FinanceRekapUserFee";
import FinanceRekapUserFeeAdd from "./pages/AdminFinanceRekapUserFee/FinanceRekapUserFeeAdd";
import FinanceRekapUserFeeUpdate from "./pages/AdminFinanceRekapUserFee/FinanceRekapUserFeeUpdate";

// Admin Protesa
import MediicalItem from "./pages/AdminMedicalItem/AdminMedicalItem";
import MediicalItemAdd  from "./pages/AdminMedicalItem/AdminMedicalItemAdd";
import MediicalItemUpdate from "./pages/AdminMedicalItem/AdminMedicalItemUpdate";

// Admin Medical Item Type
import MediicalItemType from "./pages/AdminMedicalItemType/AdminMedicalItemType";

// Admin Inventory Item
import InventoryItem from "./pages/AdminInventoryItem/AdminInventoryItem";
import InventoryItemAdd  from "./pages/AdminInventoryItem/AdminInventoryItemAdd";
import InventoryItemUpdate from "./pages/AdminInventoryItem/AdminInventoryItemUpdate";

// Admin Inventory Unit
import InventoryUnit from "./pages/AdminInventoryUnit/AdminInventoryUnit";
import InventoryUnitAdd  from "./pages/AdminInventoryUnit/AdminInventoryUnitAdd";
import InventoryUnitUpdate from "./pages/AdminInventoryUnit/AdminInventoryUnitUpdate";

// Admin Inventory Vendor
import InventoryVendor from "./pages/AdminInventoryVendor/AdminInventoryVendor";
import InventoryVendorAdd  from "./pages/AdminInventoryVendor/AdminInventoryVendorAdd";
import InventoryVendorUpdate from "./pages/AdminInventoryVendor/AdminInventoryVendorUpdate";

// Admin Inventory 
import Inventory from "./pages/AdminInventory/AdminInventory";
import InventoryAdd  from "./pages/AdminInventory/AdminInventoryAdd";
import InventoryUpdate from "./pages/AdminInventory/AdminInventoryUpdate";

// Admin Inventory In
import InventoryIn from "./pages/AdminInventoryIn/AdminInventoryIn";
import InventoryInAdd  from "./pages/AdminInventoryIn/AdminInventoryInAdd";
import InventoryInUpdate from "./pages/AdminInventoryIn/AdminInventoryInUpdate";

// Admin Inventory In
import InventoryOut from "./pages/AdminInventoryOut/AdminInventoryOut";
import InventoryOutAdd  from "./pages/AdminInventoryOut/AdminInventoryOutAdd";
import InventoryOutUpdate from "./pages/AdminInventoryOut/AdminInventoryOutUpdate";

// Admin Inventory In
import PurchaseRequest from "./pages/AdminPurchaseRequest/AdminPurchaseRequest";
import PurchaseRequestAdd  from "./pages/AdminPurchaseRequest/AdminPurchaseRequestAdd";
import PurchaseRequestUpdate from "./pages/AdminPurchaseRequest/AdminPurchaseRequestUpdate";

// Admin Inventory In
import PurchaseOrder from "./pages/AdminPurchaseOrder/AdminPurchaseOrder";
import PurchaseOrderAdd  from "./pages/AdminPurchaseOrder/AdminPurchaseOrderAdd";
import PurchaseOrderUpdate from "./pages/AdminPurchaseOrder/AdminPurchaseOrderUpdate";

// Admin Inventory In
import PurchaseInvoice from "./pages/AdminPurchaseInvoice/AdminPurchaseInvoice";
import PurchaseInvoiceAdd  from "./pages/AdminPurchaseInvoice/AdminPurchaseInvoiceAdd";
import PurchaseInvoiceUpdate from "./pages/AdminPurchaseInvoice/AdminPurchaseInvoiceUpdate";

// Not Found Page
import My404Component from "./pages/NotFoundPage/PageNotFound";

import { Link } from 'react-router-dom';

// // Admin Role
// import AdminRole from "./pages/AdminRole/AdminRole";

// Config API Gateway
import { API, setAuthToken } from "./config/api";

// init token on axios every time the app is refreshed
// if (localStorage.token) {
//   setAuthToken(localStorage.token);
// }

function App() {
  const [state, dispatch] = useContext(UserContext);
  const token = localStorage.getItem("token")
  
  const [sessionToken, setSessionToken] = useState()
  
  let navigate = useNavigate();
  // useEffect(() => {
  //   if (localStorage.getItem("token") === null || undefined) {
  //       navigate("/");
  //     }
  // });



  const isAuthenticated = () => {
    return localStorage.getItem("token") === null || undefined; 
  };

  // useEffect(() => {
  //   if (localStorage.token) {
  //     setAuthToken(localStorage.token);
  //   }
  //   // Redirect Auth
  //     if (localStorage.token) {
  //       navigate("/home");
  //     }else{
  //       navigate("/")
  //     }
  // }, [state]);

//   const checkUser = async () => {
//     try {
//       const response = await API.get("/check-auth");
//       console.log(response);

//       // If the token incorrect
//       if (response.status === 404) {
//         return dispatch({
//           type: "AUTH_ERROR",
//         });
//       }
//       // Get user data
//       let payload = response.data.data;
//       // Get token from local storage
//       payload.token = localStorage.token;

//       // Send data to useContext
//       dispatch({
//         type: "USER_SUCCESS",
//         payload,
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   };

// useEffect(() => {
//   const token = localStorage.getItem("token")
//   console.log(token+"disini yahh")

//   if (token == null) {
//     console.log("itu betul")
//     navigate("/")
//   }
// },[]);

const isAuthenticatedToken = () => {
  return localStorage.getItem('token') !== null; 
};

const isAuthenticatedDocter = () => {
  return localStorage.getItem('token') !== null && localStorage; 
}


return (

  <Routes>
      {/* <div className="mx-20 flex gap-5 my-24">
        {
          data_menu.map((user) => {
            return (
              <Link to={`users/${user}`}>User {user}</Link>
            )
          })
        }
      </div> */}
    
      <Route exact path="/" element={<Auth />} />
      <Route exact path="/backyard"  element={!isAuthenticatedToken() ? <AuthStaff />: <Navigate to="/dashboard"/>}/>
      
        {/* Register Online Pasien */}
        <Route exact path="/register-pasien"  element={isAuthenticatedToken() ? <RegisterPasien />: <Navigate to="/dashboard"/>} />
        <Route exact path="/register-pasien-online"  element={isAuthenticatedToken() ? <RegisterPasienOnline />: <Navigate to="/dashboard"/>} />
        <Route exact path="/login-pasien-online"  element={isAuthenticatedToken() ? <LoginPasienOnline />: <Navigate to="/dashboard"/>} />
    
        {/* Pasien Online */}
        <Route exact path="/dashboard-pasien-online" element={isAuthenticatedToken() ? <DashboardPasienOnline />: <Navigate to="/dashboard"/>} />
        <Route exact path="/dashboard-pasien-online-penampung" element={isAuthenticatedToken() ? <DashboardPasienOnlinePenampung />: <Navigate to="/dashboard"/>} />
        <Route exact path="/dashboard-pasien-online-tambah" element={isAuthenticatedToken() ? <DashboardPasienOnlinePenampungTambah />: <Navigate to="/dashboard"/>} />

        <Route exact path="/dashboard-pasien-online-profile" element={isAuthenticatedToken() ? <DashboardPasienOnline />: <Navigate to="/dashboard"/>} />
        <Route exact path="/dashboard-pasien-online-ubah-password" element={isAuthenticatedToken() ? <DashboardPasienOnline />: <Navigate to="/dashboard"/>} />
        <Route exact path="/dashboard-pasien-list-doctor" element={isAuthenticatedToken() ? <DashboardPasienOnline />: <Navigate to="/dashboard"/>} />

        <Route exact path="/appoinment-pasien/:code/:id" element={isAuthenticatedToken() ? <AppoinmentPasien />: <Navigate to="/dashboard"/>} />
        <Route exact path="/appoinment-pasien-online" element={isAuthenticatedToken() ? <AppoinmentPasienOnline />: <Navigate to="/dashboard"/>}/>
        
        <Route exact path="/dashboard" element={isAuthenticatedToken() ? <AdminHome />: <Navigate to="/backyard"/>}/>

        {/* Admin Pasien Medis */}
        <Route exact path="/patients" element={isAuthenticatedToken() ? <AdminPasien /> : <Navigate to="/dashboard" />}/>
        <Route exact path="/phy-checks/:id" element={isAuthenticatedToken() ? <AdminPasienPhysicalCheck /> : <Navigate to="/dashboard" />}/>
        <Route exact path="/med-attentions/:id" element={isAuthenticatedToken() ? <AdminPasienMedicalAttention /> : <Navigate to="/dashboard" />}/>
        <Route exact path="/medical-record/:id/:code" element={isAuthenticatedToken() ? <AdminPasienMedicalRecord /> : <Navigate to="/dashboard" />}/>
        <Route exact path="/medical-record/:id" element={isAuthenticatedToken() ? <AdminPasienSetup /> : <Navigate to="/dashboard" />}/>
        <Route exact path="/medical-record-odontogram/:id" element={isAuthenticatedToken() ? <AdminPasienOdontogramSetup /> : <Navigate to="/dashboard" />}/>

        {/* Admin Pasien */}
        <Route exact path="/pasien-admin-add" element={isAuthenticatedToken() ? <AdminPasienAdd /> : <Navigate to="/dashboard" />}/>
        <Route exact path="/pasien-admin-update" element={isAuthenticatedToken() ? <AdminPasienUpdate /> : <Navigate to="/dashboard" />}/>
        <Route exact path="/pasien-admin-add-treatment" element={isAuthenticatedToken() ? <AdminPasienAddTreatment /> : <Navigate to="/dashboard" />}/>
        <Route exact path="/pasien-admin-update-treatment" element={isAuthenticatedToken() ? <AdminPasienUpdateTreatment /> : <Navigate to="/dashboard" />}/>

        {/* Admin Item */}
        <Route exact path="/item-admin" element={isAuthenticatedToken() ? <AdminItem /> : <Navigate to="/dashboard" />}
        />

        {/* Admin Appointment */}
        <Route exact path="/appointments" element={isAuthenticatedToken() ? <Appointment /> : <Navigate to="/dashboard" />}/>
        <Route exact path="/appointments-admin-add" element={isAuthenticatedToken() ? <AppointmentAdd /> : <Navigate to="/dashboard" />}/>
        <Route exact path="/appointments-admin-update" element={isAuthenticatedToken() ? <AppointmentUpdate /> : <Navigate to="/dashboard" />}/>
        <Route exact path="/treatment-plans/:id/:id_patient_status" element={isAuthenticatedToken() ? <AppointmentTreatmentPlanAdd /> : <Navigate to="/dashboard" />}/>
        <Route exact path="/medical-record-all/:id/:id_patient" element={isAuthenticatedToken() ? <AppointmentPasienSetup /> : <Navigate to="/dashboard" />}/>

         {/* Admin Treatment */}
        <Route exact path="/treatments" element={isAuthenticatedToken() ? <Treatment /> : <Navigate to="/dashboard" />} />
        
        {/* Admin Treatment Prices */}
        <Route exact path="/treatment-prices" element={isAuthenticatedToken() ? <TreatmentPrices /> : <Navigate to="/dashboard" />} />
        
        {/* Admin Treatment Category */}
        <Route exact path="/treatment-categories" element={isAuthenticatedToken() ? <TreatmentCategory /> : <Navigate to="/dashboard" />} />
        <Route exact path="/treatment-categories-add" element={isAuthenticatedToken() ? <TreatmentCategoryAdd /> : <Navigate to="/dashboard" />} />
        <Route exact path="/treatment-categories-update" element={isAuthenticatedToken() ? <TreatmentCategoryUpdate /> : <Navigate to="/dashboard" />} />

        {/* Admin Agreement */}
        <Route exact path="/agremeents" element={isAuthenticatedToken() ? <Agreement /> : <Navigate to="/dashboard" />} />
        
        {/* Admin Consent */}
        <Route exact path="/consents" element={isAuthenticatedToken() ? <Consent /> : <Navigate to="/dashboard" />} />
        
        {/* Admin Roles */}
        <Route exact path="/privileges/roles" element={isAuthenticatedToken() ? <AdminRole /> : <Navigate to="/dashboard" />} />
        <Route exact path="/privileges/roles-admin-add" element={isAuthenticatedToken() ? <AdminRoleAdd /> : <Navigate to="/dashboard" />} />
        <Route exact path="/privileges/roles-admin-update" element={isAuthenticatedToken() ? <AdminRoleUpdate /> : <Navigate to="/dashboard" />} />
        
        {/* Admin Role Permission */}
        <Route exact path="/privileges/roles/:id/:name" element={isAuthenticatedToken() ? <AdminRolePermision /> : <Navigate to="/dashboard" />} />

        {/* Admin User */}
        <Route exact path="/users" element={isAuthenticatedToken() ? <AdminUser /> : <Navigate to="/dashboard" />} />
        <Route exact path="/users-add" element={isAuthenticatedToken() ? <AdminUserAdd /> : <Navigate to="/dashboard" />} />
        <Route exact path="/users-update/:id" element={isAuthenticatedToken() ? <AdminUserUpdate /> : <Navigate to="/dashboard" />} />


        {/* Admin Doctor Specialist  */}
        <Route exact path="/users/specialists" element={isAuthenticatedToken() ? <AdminDoctorSpecialist /> : <Navigate to="/dashboard" />} />

        {/* Admin Specialist */}
        <Route exact path="/specialists" element={isAuthenticatedToken() ? <AdminSpecialist /> : <Navigate to="/dashboard" />} />
        
        {/* Admin Specialist  */}
        <Route exact path="/persentase-assurances" element={<AdminPersentaseAssurance />} />

        {/* Admin Diagnosis Patient */}
        <Route exact path="/diagnosa-patients" element={isAuthenticatedToken() ? <AdminDiagnosaPatient /> : <Navigate to="/dashboard" />} />

        {/* Admin User Privilege */}
        <Route exact path="/privileges/users" element={isAuthenticatedToken() ? <AdminUserPrivilage /> : <Navigate to="/dashboard" />} />
        <Route exact path="/privileges/users-add" element={isAuthenticatedToken() ? <AdminUserAddPrivilage /> : <Navigate to="/dashboard" />} />
        <Route exact path="/privileges/users-update/:id" element={isAuthenticatedToken() ? <AdminUserUpdatePrivilage /> : <Navigate to="/dashboard" />} />

        {/* Admin User Permission */}
        <Route exact path="/permissions/:id/:firstname/:lastname" element={isAuthenticatedToken() ? <AdminUserPermission/> : <Navigate to="/dashboard" />}/>

        {/* Admin Menus */}
        <Route exact path="/privileges/menus" element={isAuthenticatedToken() ? <AdminMenus /> : <Navigate to="/dashboard" />} />
        <Route exact path="/privileges/menus-add" element={isAuthenticatedToken() ? <AdminMenusAdd /> : <Navigate to="/dashboard" />} />
        <Route exact path="/privileges/menus-update/:id" element={isAuthenticatedToken() ? <AdminMenusUpdate /> : <Navigate to="/dashboard" />} />

        {/* Admin Room */}
        <Route exact path="/rooms" element={isAuthenticatedToken() ? <Room /> : <Navigate to="/dashboard" />} />

        {/* Admin Treatment Plan */}
        <Route exact path="/treatment-plans" element={isAuthenticatedToken() ? <TreatmentPlan /> : <Navigate to="/dashboard" />} />
        <Route exact path="/treatment-plans/:id" element={isAuthenticatedToken() ? <TreatmentPlanAdd /> : <Navigate to="/dashboard" />} />
        <Route exact path="/treatment-plan-update" element={isAuthenticatedToken() ? <TreatmentPlanUpdate /> : <Navigate to="/dashboard" />} />

        {/* Admin Floor  */}
        <Route exact path="/floors" element={isAuthenticatedToken() ? <Floor />  : <Navigate to="/dashboard" />} />

        {/* Admin Payment  */}
        <Route exact path="/payments" element={isAuthenticatedToken() ? <Payment />  : <Navigate to="/dashboard" />} />

        {/* Admin Payment Type A */}
        <Route exact path="/payment-types" element={isAuthenticatedToken() ? <PaymentType />  : <Navigate to="/dashboard" />} />

        {/* Admin Fee  */}
        <Route exact path="/fees" element={isAuthenticatedToken() ? <Fees />  : <Navigate to="/dashboard" />} />

        {/* Admin Fee  */}
        <Route exact path="/schedules" element={isAuthenticatedToken() ? <Schedules /> : <Navigate to="/dashboard" />} />

        {/* Admin Payment */}
        {/* <Route exact path="/payments" element={isAuthenticatedToken() ? <Payment /> : <Navigate to="/dashboard" />} />
        <Route exact path="/payments-add" element={isAuthenticatedToken() ? <PaymentAdd /> : <Navigate to="/dashboard" />} /> */}
        {/* <Route exact path="/payments-update/:id" element={isAuthenticatedToken() ? <PaymentUpdate /> : <Navigate to="/dashboard" />} /> */}

        {/* Admin Invoice */}
        <Route exact path="/sales-invoices" element={isAuthenticatedToken() ? <Invoices /> : <Navigate to="/dashboard" />} />
        <Route exact path="/sales-invoices/:id/:invoice_id/:payment_id" element={isAuthenticatedToken() ? <InvoicesSetup /> : <Navigate to="/dashboard" />} />
        <Route exact path="/sales-invoices-add" element={isAuthenticatedToken() ? <InvoicesAdd /> : <Navigate to="/dashboard" />} />
        <Route exact path="/sales-invoices-update/:id" element={isAuthenticatedToken() ? <InvoicesUpdate /> : <Navigate to="/dashboard" />} />

        {/* Admin Invoice Medical  */}
        <Route exact path="/medical-invoices" element={isAuthenticatedToken() ? <MedicalInvoices /> : <Navigate to="/dashboard" />} />
        <Route exact path="/medical-invoices/:id" element={isAuthenticatedToken() ? <MedicalInvoicesSetup /> : <Navigate to="/dashboard" />} />
        <Route exact path="/medical-invoices-add" element={isAuthenticatedToken() ? <MedicalInvoicesAdd /> : <Navigate to="/dashboard" />} />
        <Route exact path="/medical-invoices-update/:id" element={isAuthenticatedToken() ? <MedicalInvoicesUpdate /> : <Navigate to="/dashboard" />} />

        {/* Admin Modules */}
        <Route exact path="/privileges/modules" element={isAuthenticatedToken() ? <AdminModules /> : <Navigate to="/dashboard" />} />
        <Route exact path="/privileges/modules-add" element={isAuthenticatedToken() ? <AdminModulesAdd /> : <Navigate to="/dashboard" />} />
        <Route exact path="/privileges/modules-update" element={isAuthenticatedToken() ? <AdminModulesUpdate /> : <Navigate to="/dashboard" />} />

        {/* Admin Tooth Surface */}
        <Route exact path="/tooth-surfaces" element={isAuthenticatedToken() ? <ToothSurfaces /> : <Navigate to="/dashboard" />} />

        {/* Admin Tooth Condition  */}
        <Route exact path="/tooth-conditions" element={isAuthenticatedToken() ? <ToothCondition /> : <Navigate to="/dashboard" />} />

        {/* Admin Protesa  */}
        <Route exact path="/protesas" element={isAuthenticatedToken() ? <Protesa /> : <Navigate to="/dashboard" />} />

         {/* Admin Restorative  */}
         <Route exact path="/restoratives" element={isAuthenticatedToken() ? <Restorative /> : <Navigate to="/dashboard" />} />

        {/* Admin Restorative Material */}
        <Route exact path="/restorative-materials" element={isAuthenticatedToken() ? <RestorativeMaterial /> : <Navigate to="/dashboard" />} />
        <Route exact path="/restorative-materials/add" element={isAuthenticatedToken() ? <RestorativeMaterialAdd /> : <Navigate to="/dashboard" />} />
        <Route exact path="/restorative-materials/:id" element={isAuthenticatedToken() ? <RestorativeMaterialUpdate /> : <Navigate to="/dashboard" />} />

         {/* Admin Sub Modules */}
         <Route exact path="/privileges/sub-modules/:id/:slug_name" element={isAuthenticatedToken() ? <AdminSUbModules /> : <Navigate to="/dashboard" />} />

        {/* Admin Odontogram */}
        <Route exact path="/odontograms/:id/:first_name/:last_name" element={isAuthenticatedToken() ? <AdminPasienOdontogram /> : <Navigate to="/dashboard" />} />

        {/* Admin Soap Patient  */}
        <Route exact path="/soap-patients/:id/:first_name/:last_name" element={isAuthenticatedToken() ? <AdminPasienSoap /> : <Navigate to="/dashboard" />} />

        {/* Admin User Fee */}
        <Route exact path="/user-fee" element={isAuthenticatedToken() ? <FinanceUserFee /> : <Navigate to="/dashboard" />} />
        <Route exact path="/user-fee-add" element={isAuthenticatedToken() ? <FinanceUserFeeAdd /> : <Navigate to="/dashboard" />} />
        <Route exact path="/user-fee-update/:id" element={isAuthenticatedToken() ? <FinanceUserFeeUpdate /> : <Navigate to="/dashboard" />} />

        {/* Admin Floor */}
        <Route exact path="/rekap-user-fee" element={isAuthenticatedToken() ? <FinanceRekapUserFee /> : <Navigate to="/dashboard" />} />
        <Route exact path="/rekap-user-fee-add" element={isAuthenticatedToken() ? <FinanceRekapUserFeeAdd /> : <Navigate to="/dashboard" />} />
        <Route exact path="/rekap-user-fee-update/:id" element={isAuthenticatedToken() ? <FinanceRekapUserFeeUpdate /> : <Navigate to="/dashboard" />} />

        {/* Admin Protesa  */}
        <Route exact path="/medical-items" element={isAuthenticatedToken() ? <MediicalItem />: <Navigate to="/dashboard" />} />
        <Route exact path="/medical-items/add" element={isAuthenticatedToken() ? <MediicalItemAdd />: <Navigate to="/dashboard" />} />
        <Route exact path="/medical-items/:id" element={isAuthenticatedToken() ? <MediicalItemUpdate />: <Navigate to="/dashboard" />} />
    
        {/* Admin Medical Type  */}
        <Route exact path="/medical-item-types" element={isAuthenticatedToken() ? <MediicalItemType />: <Navigate to="/dashboard" />} />

        {/* Admin Inventory Items  */}
        <Route exact path="/inventory-items" element={isAuthenticatedToken() ? <InventoryItem />: <Navigate to="/dashboard" />} />

        {/* Admin Inventory Units  */}
        <Route exact path="/inventory-units" element={isAuthenticatedToken() ? <InventoryUnit />: <Navigate to="/dashboard" />} />


        {/* Admin Inventory Units  */}
        <Route exact path="/inventory-vendors" element={isAuthenticatedToken() ? <InventoryVendor />: <Navigate to="/dashboard" />} />

        {/* Admin Inventory  */}
        <Route exact path="/inventorys" element={isAuthenticatedToken() ? <Inventory /> : <Navigate to="/dashboard" />} />
        
        {/* Admin Inventory In */}
        <Route exact path="/inventory-ins" element={isAuthenticatedToken() ? <InventoryIn /> : <Navigate to="/dashboard" />} />

        {/* Admin Inventory  Out */}
        <Route exact path="/inventory-outs" element={isAuthenticatedToken() ? <InventoryOut /> : <Navigate to="/dashboard" />} />

        {/* Admin Purchase Out */}
        <Route exact path="/purchase-requests" element={isAuthenticatedToken() ? <PurchaseRequest /> : <Navigate to="/dashboard" />} />
        <Route exact path="/purchase-requests/add" element={isAuthenticatedToken() ? <PurchaseRequestAdd /> : <Navigate to="/dashboard" />} />
        <Route exact path="/purchase-requests/:id" element={isAuthenticatedToken() ? <PurchaseRequestUpdate /> : <Navigate to="/dashboard" />} />
        
        {/* Admin Inventory  Out */}
        <Route exact path="/purchase-orders" element={isAuthenticatedToken() ? <PurchaseOrder /> : <Navigate to="/dashboard" />} />
        <Route exact path="/purchase-orders/add" element={isAuthenticatedToken() ? <PurchaseOrderAdd /> : <Navigate to="/dashboard" />} />
        <Route exact path="/purchase-orders/:id" element={isAuthenticatedToken() ? <PurchaseOrderUpdate /> : <Navigate to="/dashboard" />}  />

        {/* Admin Inventory  Out */}
        <Route exact path="/purchase-invoices" element={isAuthenticatedToken() ? <PurchaseInvoice /> : <Navigate to="/dashboard" />} />
        <Route exact path="/purchase-invoices/add" element={ isAuthenticatedToken() ? <PurchaseInvoiceAdd /> : <Navigate to="/dashboard" />}/>
        <Route exact path="/purchase-invoices/:id" element={ isAuthenticatedToken() ? <PurchaseInvoiceUpdate /> : <Navigate to="/dashboard" />}/>

        {/* Not Found Page */}
        <Route path="*" element={<My404Component />} />
      </Routes>
  );
}

export default App;
