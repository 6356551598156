import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import swal from "sweetalert";
import { useMediaQuery } from 'react-responsive'
import Logo_Signature from "../../assets/signature/signature logo.png"
import Logo_Batik from "../../assets/signature/logo-batik.png"
import BackgroundBatik from "../../assets/signature/background-appoinment.jpg"
import SignatureCanvas from 'react-signature-canvas'
import { FaHandHoldingMedical, FaReply, FaSearch, FaSync, FaUndoAlt } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import { Alert } from "react-bootstrap";
import { useMutation } from "react-query";
import { API } from "../../config/api";
import { useParams } from 'react-router-dom';
import LoaderHome from "../Loader/LoaderHome"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment-timezone';
import { setDefaultTimeZone } from "date-fns-tz";
import "./Add.css"

export default function Login() {
  let navigate = useNavigate();
  document.title = "SDC Apps";
  const [state, dispatch] = useContext(UserContext);
  const [message, setMessage] = useState(null);
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)'})
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)'})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)'})
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const token = localStorage.getItem("token");
  const [idDelete, setIdDelete] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { code, id } = useParams();
  const [getData, setGetData] = useState([]);
  const [getDataTreatment, setGetDataTreatment] = useState([]);
  const [getDataAgreement, setGetDataAgreement] = useState([]);
  const [getCode, setGetCode] = useState([]);
  const { getCode2, setGetCode2 } = useState("");
  const [imageName, setImageName] = useState([])
  const [imageContent, setImageContent] = useState([])
  const [preview, setPreview] = useState(null);
  const [idRoleDokter, setIdRoleDokter] = useState("");
  const [nameRoleDoctor, setNameRoleDoktor] = useState("Doctor");
  const [getDataDokter, setGetDataDokter] = useState([]);
  const [getDataDokterschedule, setGetDataDokterSchedule] = useState([]);
  const [tanggalTerpilih, setTanggalTerpilih] = useState(""); // State untuk menyimpan tanggal yang dipilih
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(1000);
  const [ascending, setAscending] = useState(0);
  // console.log(canvasRef)
  let [cBase64, setBase64] = useState();
  let [cBase64name, setBase64Name] = useState();
  let canvasRef;
  const storageItems = JSON.parse(localStorage.getItem('user_data'));

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const [startDate, setStartDate] = useState(null); 
  
  const inputDate = new Date(startDate);
  
  // Dapatkan tahun (4 digit)
  const year = inputDate.getFullYear().toString();
  
  // Dapatkan bulan (01-12)
  const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
  
  // Dapatkan hari (01-31)
  const day = inputDate.getDate().toString().padStart(2, '0');
  
  // Format tanggal dalam format "yyyy/mm/dd"
  const formattedDate = `${year}/${month}/${day}`;

  const [allowedDates, setAllowedDates] = useState([]); // State untuk menyimpan tanggal-tanggal yang diperbolehkan
  const [allowedTimePeriods, setAllowedTimePeriods] = useState([]);


  // Untuk mendapatkan starttimenya
  const [startDateTime, setStartDateTime] = useState(null); 

  const [form, setForm] = useState({
    // store appoinment
    anamnesa: "",
    generate_code: `${getCode2}`,
    date_dokter_scedule: "",
    hours_appoinment: "",
    minute_appoinment: "",
    id_dokter: "",
    image_name : imageName,
    image_content: imageContent,
    id_treatment: [],

    // store consent
    question_allergy: "",
    question_medicine: "",
    question_condition: "",
    id_agreement: ""
  });

  // console.log(form)

  const {
      // store appoinment
      anamnesa,
      generate_code,
      date_appoinment,
      hours_appoinment,
      minute_appoinment,
      id_dokter,
      id_treatment,
  
      // store consent
      question_allergy,
      question_medicine,
      question_condition,
      id_agreement
  } = form;

  const GetResponseData = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`user-service/patient-users?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&id_role=${idRoleDokter}&name_role=${nameRoleDoctor}`,fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetDataDokter(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataScedules = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`user-service/doctor-schedules/${form?.id_dokter}?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&id_role=${idRoleDokter}&name_role=${nameRoleDoctor}`,fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetDataDokterSchedule(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  useEffect(() => {
    if (form?.id_dokter) {
      GetResponseDataScedules()
    }
  }, [form?.id_dokter])
  

  useEffect(() => {
    GetResponseData()
  }, [])


  const [formTreatment, setFormTreatment] = useState({
    treatment: [],
  });
  
  const [formAgreement, setFormAgreement] = useState({
    agreement: [],
  });
  // console.log(formTreatment.treatment)

  const handleChangeCheckbox = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { treatment } = formTreatment;
    // console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setFormTreatment({
        treatment: [...treatment, {"id_treatment" : `${value}`}],
      });
    }
  
    // Case 2  : The user unchecks the box
    else {
      setFormTreatment({
        treatment: treatment.filter(e => {return e.id_treatment !== `${value}`} ),

      });
    }
  };

  const handleChangeCheckboxAgreement = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { agreement } = formAgreement;
    // console.log(`${value} is ${checked}`);
    // Case 1 : The user checks the box
    if (checked) {
      setFormAgreement({
        agreement: [...agreement, {"id_agreement" : `${value}`, "agree" : `${value? 1 : 0}`}],
      });
    }
  
    // Case 2  : The user unchecks the box
    else {
      setFormAgreement({
        agreement: agreement.filter(e => {return e.id_agreement !== `${value}`} ),
      });
    }
  };

  const handleChange = async (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.type === "file" ? e.target.files : e.target.type === "date" ? e.target.value : e.target.value,
    });

    // Create image url for preview
    if (e.target.type === "file") {
      const file = e.target.files[0]
      // console.log(file)
      const base64 = await convertBase64(file)
      setPreview(base64)
      setImageName(e.target.files[0].name)
      setImageContent(base64.split(',')[1])
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const postUser = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
    // Data body
    // const body = JSON.stringify(form);
    const responseAppoinment = await API.post(`transaction-service/patient-appointments/store`,
    {
      id_patient: storageItems?.id,
      id_doctor: `${form?.id_dokter}`,
      code: `${getCode?.number}`,
      date: formattedDate,
      time: startDateTime,
      anamnesa: `${form?.anamnesa}`,
    },fetchParams)

    if (responseAppoinment?.status === 200) {
      swal({
        title: 'Success',
        text: "Berhasil Membuat Appoinment Pasien",
        icon: 'success',
        timer: 3000,
        buttons: false
      });
      navigate("/dashboard-pasien-online");
    }
    setLoading(false)
  } catch (error) {
    swal({
      title: 'Failed',
      text: `${error.response.data.message}`,
      icon: 'error',
      buttons: false
    });
    setLoading(false)
  }
  }

  const generateCode = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.post(`user-service/patient-master-codes/generate`,
        {
          type: "appointment",
          object_code : storageItems?.code,
          id_patient : storageItems?.id
        },
      fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetCode(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

 
// console.log(allowedTimePeriods)
  
useEffect(() => {
  // Ambil semua tanggal dari getDataDokterSchedule
  const allDates = getDataDokterschedule.reduce((dates, item) => {
    dates.push(item.ci_date, item.co_date);
    return dates;
  }, []);

  // Menghilangkan tanggal yang duplikat
  const uniqueDates = [...new Set(allDates)];

  // Format tanggal ke struktur data yang sesuai dengan komponen DatePicker
  const formattedDates = uniqueDates.map((date) => {
    return {
      value: date,
      label: date,
    };
  });

  // Set state dengan tanggal yang diizinkan
  setAllowedDates(formattedDates);
  }, [getDataDokterschedule]);

  useEffect(() => {
    if (startDate) {
      const selectedDate = startDate?.toISOString().slice(0, 10);
      const timePeriodsForSelectedDate = getDataDokterschedule
        .filter((item) => item.ci_date === selectedDate)
        .map((item) => item.time_period)
        .flat();
        
      // Set state dengan time_period yang diizinkan
      setAllowedTimePeriods(timePeriodsForSelectedDate);
    }
  }, [getDataDokterschedule, startDate]);

  console.log(getDataDokterschedule)

  const navigateHome = () => {
    navigate("/dashboard-pasien-online")
  }
  
  return (
    <div className='auth-wrapper auth-cover' >
    {loading && <LoaderHome/>}
      {isTabletOrMobile ?
  <Row style={{ backgroundImage: `url(${BackgroundBatik})`, minHeight:"100vh", display:"flex",alignItems:"center" }}>
  <Col xl='12' sm='12' style={{fontFamily:"sans-serif" }}>
    <form onSubmit={(e) =>postUser}>
      <div style={{paddingTop:"20px",paddingBottom:"20px", backgroundColor: "white", opacity: ".9", borderTopLeftRadius:"5px", borderTopRightRadius:"5px",borderBottom:"5px solid #575757",}}>
        <div style={{fontSize:"30px",display: "flex",justifyContent:"center", alignItems: "center", fontWeight: "600", color:"#575757" }}>
            Buat Appoinment
        </div>  
      </div>
      {/* <div style={{display:"flex", backgroundColor: "white", opacity: ".9", borderBottom:"5px solid #804D00",paddingTop:"30px", paddingBottom:"10px"}}>
        <div style={{ flex: "50%"}}>
                  
          <div style={{display:"flex", paddingLeft:"50px", paddingRight:"30px" }}>
            <div style={{flex:"30%"}}>
              Nama Lengkap
            </div>
            <div style={{ flex: "70%", display:"flex"}}>
              <p style={{marginRight:"10px"}}>:</p>
              <input autoFofcus type='text' value={`${getData?.first_name} ${getData?.last_name} ` } style={{width:"100%", border:"none", borderBottom:"1px solid #804D00", outline:"none", height:"28px",backgroundColor:"#F2F2F2"}} disabled/>
            </div>    
          </div>

          <div style={{display:"flex", paddingLeft:"50px", paddingRight:"30px" }}>
            <div style={{flex:"30%"}}>
              Pasien Code
            </div>
            <div style={{ flex: "70%", display:"flex"}}>
              <p style={{marginRight:"10px"}}>:</p>
              <input autoFofcus type='text' value={getData?.code}  style={{width:"100%", border:"none", borderBottom:"1px solid #804D00", outline:"none", height:"28px",backgroundColor:"#F2F2F2"}} disabled/>
            </div>    
          </div>
        </div>
                
        <div style={{flex:"50%"}}>
          <div style={{display:"flex", paddingLeft:"30px", paddingRight:"50px" }}>
            <div style={{flex:"30%"}}>
              Jenis Kelamin
            </div>
            <div style={{ flex: "70%", display:"flex"}}>
              <p style={{marginRight:"10px"}}>:</p>
              <input autoFofcus type='text' value={getData?.gender} style={{width:"100%", border:"none", borderBottom:"1px solid #804D00", outline:"none", height:"28px",backgroundColor:"#F2F2F2"}} disabled/>
            </div>    
          </div>

          <div style={{display:"flex", paddingLeft:"30px", paddingRight:"50px" }}>
            <div style={{flex:"30%"}}>
              Tgl Lahir
            </div>
            <div style={{ flex: "70%", display:"flex"}}>
              <p style={{marginRight:"10px"}}>:</p>
              <input autoFofcus type='text'
                value={`${getData.date_birth}`.split('-')[2] + (` `) + (
                `${getData.date_birth}`.split('-')[1] === "01" ? "Januari" : "" ||
                `${getData.date_birth}`.split('-')[1] === "02" ? "Februari" : "" ||
                `${getData.date_birth}`.split('-')[1] === "03" ? "Maret" : "" ||
                `${getData.date_birth}`.split('-')[1] === "04" ? "April" : "" ||  
                `${getData.date_birth}`.split('-')[1] === "05" ? "Mei" : "" ||
                `${getData.date_birth}`.split('-')[1] === "06" ? "Juni" : "" ||
                `${getData.date_birth}`.split('-')[1] === "07" ? "Juli" : "" ||
                `${getData.date_birth}`.split('-')[1] === "08" ? "Agustus" : "" ||
                `${getData.date_birth}`.split('-')[1] === "09" ? "September" : "" ||
                `${getData.date_birth}`.split('-')[1] === "10" ? "Oktober" : "" ||
                `${getData.date_birth}`.split('-')[1] === "11" ? "November" : "" ||
                `${getData.date_birth}`.split('-')[1] === "12" ? "Desember" : "") + (` `) +
                `${getData.date_birth}`.split('-')[0]
                }
                style={{ width: "100%", border: "none", borderBottom: "1px solid #804D00", outline: "none", height: "28px", backgroundColor: "#F2F2F2" }} disabled />
            </div>    
          </div>      
      </div>
    </div>
  */}

      <div style={{ backgroundColor: "white", opacity: ".9"}}>
      <p style={{  opacity: ".9",fontSize: "20px", fontWeight: "700", color: "#575757", backgroundColor:"white", padding:"10px 10px 0px 10px"}}>Detail Perawatan Gigi</p>
        <div style={{ padding: "0px 10px" }}>
                    
        <div className="mt-3" style={{ display: "flex" }}>
            <div style={{flex:"25%"}}>
              <p>Anamnesa</p>
            </div>
            <div style={{ flex: "75%", display:"flex"}}>
              <p style={{ marginRight: "5px" }}>:</p>
              <div style={{width:"100%"}}>
                <input type="text" value={anamnesa} name="anamnesa" onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"none", height:"35px", borderRadius:"5px", paddingLeft:"5px"}}/>
                <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>keluhan pasien</div>
              </div>
            
              </div>
                  </div>
                  
                  <div className="mt-4" style={{ display: "flex" }}>
            <div style={{flex:"25%"}}>
              <p>Generate Code</p>
            </div>
            <div style={{ flex: "75%", display:"flex"}}>
                      <p style={{ marginRight: "5px" }}>:</p>
                      <div style={{display:"flex", width:"100%"}}>
                      <div style={{width:"100%", marginRight:"5px"}}>
                <input disabled autoFofcus type='text' value={getCode?.number} name="generate_code" onChange={handleChange} type="text" style={{width:"100%", border:"1px solid #C9C9C9", outline:"none", height:"35px", borderRadius:"5px", paddingLeft:"5px"}}/>
               <div style={{ fontSize: "10px", color: "#898989", fontStyle: "italic", fontWeight: "bold" }}>Tekan tombol disamping untuk mendapatkan code appoinment</div>
                        
              </div>
              <div onClick={generateCode} style={{height:"50%", marginRight:"0px", paddingTop:"0px",backgroundColor:"white",padding:"0px 10px", boxShadow:"1px 1px 3px #8B8B8B", borderRadius:"3px", cursor:"pointer"}}>
                <FaSync style={{ fontSize: "15px", marginRight: "0px", marginTop: "0px", display: "flex", alignItems: "center", height:"100%", color:"#3D64FF" }} />
              </div>
                      </div>
              
            </div>
                  </div>
                  
                    
          <div className="mt-3" style={{ display: "flex"}}>
          <div style={{flex:"25%",display:"flex", alignItems:"center"}}>
            Pilih Dokter
          </div>
          <div style={{ flex: "75%", display:"flex"}}>
            <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <select className="form-select" aria-label="Default select example" onChange={handleChange}  name="id_dokter" style={{ textAlign:"", cursor:"pointer"}}>
              <option value="" hidden>Select Dokter</option>
              {getDataDokter.map((user,index) => (
                <option value={user?.id} style={{textAlign:""}}>{user?.firstname} {user?.lastname}</option>
                ))}         
            </select>
          </div>
        </div>

      {form?.id_dokter && (
        <div className="mt-4" style={{ display: "flex"}}>
        <div style={{flex:"25%"}}>
          <p>Scedule Dokter</p>
        </div>
        <div style={{ flex: "75%", display:"flex"}}>
        <p style={{ marginRight: "5px", height: "100%" }}>:</p> 
        <div style={{ display: "flex", width:"100%" }}>
          <div style={{marginRight:"10px"}}>
            <DatePicker
            className="datePicker"
            placeholderText="Tanggal"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            filterDate={(date) =>
              allowedDates.some(
                (allowedDate) =>
                  allowedDate.value === date.toISOString().slice(0, 10)
              )
            }
            />
        </div>
        <div style={{ flex: "50%" }}>
           <select
            onChange={(e) => setStartDateTime(e.target.value)}
            class="selectPicker"         
            name="hours_appointment"
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              cursor: "pointer",
              height:"40px",
              // height: "10px",
            borderRadius:"4px",
              outline: "none",
              border:"1px solid rgb(198, 198, 198)",
              // borderRadius: "none",
              marginRight: "0px",
              appearance: "none",
              width: "120px",
              color:"#494949"
            }}
            // value={selectedTimePeriod}
          >
            <option value="" hidden >Waktu</option>
            {allowedTimePeriods.map((timePeriod) => (
              <option key={timePeriod} value={timePeriod}>
                {timePeriod.split(":")[0]}:{timePeriod.split(":")[1]}
              </option>
            ))}
          </select>
        </div>                  
       
        </div>              
                     
        </div>
      </div> 
      )}
              
         
          
          <div className="" style={{display:"flex",justifyContent:"center", padding: "0px 0px 30px 0px", marginTop: "50px", marginBottom: "20px"}}>
            <div className="mr-4" style={{width:"180px"}}>
              <Button onClick={navigateHome} type='submit' color='secondary' block style={{ fontSize:"15px", borderRadius:"3px", }}>
                Kembali
              </Button>
            </div>
            <div style={{ width: "180px" }}>
              <Button onClick={postUser} type='submit' color='primary' block style={{ fontSize:"15px", borderRadius:"3px", }}>
                Buat Appointment
              </Button>
            </div>
          </div>
                  
        {/* <div className="mt-3" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex", alignItems:""}}>
            Jenis Perawatan Gigi
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
          <p style={{ marginRight: "10px", display: "flex", alignItems: "", height: "100%" }}>:</p>
        <div>
          {getDataTreatment.map((user,index) => (
            <div key={index} className="mb-3" style={{display:"flex", width:"100%", cursor:"pointer"}}>
              <input autoFofcus type='checkbox' value={user?.id} name="id_treatment" onChange={handleChangeCheckbox} style={{display:"flex", alignItems:"center",marginRight:"5px", border: "none", borderBottom: "1px solid #804D00", cursor:"pointer" }} />
              <div style={{display:"flex", alignItems:"center"}}>
                {user?.name}
              </div>
            </div>
          ))}
          </div>
        </div>
      </div> */}
                  
          
      </div>
    </div>


    </form>
    </Col>
    </Row>
        :
    <Row style={{ backgroundImage: `url(${BackgroundBatik})`, minHeight:"100vh" }}>
      <Col xl='12' sm='12' style={{ padding: "20px 300px 20px 300px", fontFamily:"sans-serif" }}>
      <form onSubmit={(e) =>postUser}>
        <div style={{paddingTop:"50px", backgroundColor: "white", borderTopLeftRadius:"5px", borderTopRightRadius:"5px"}}>
          <div style={{fontSize:"30px",display: "flex",justifyContent:"center", alignItems: "center", fontWeight: "600", color:"#8E8E8E" }}>
              Buat Appoinment
          </div>  
        </div>
        <div style={{display:"flex", backgroundColor: "white", opacity: "", borderBottom:"5px solid #8E8E8E",paddingTop:"30px", paddingBottom:"10px"}}>
          <div style={{ flex: "50%"}}>
                    
            <div style={{display:"flex", paddingLeft:"50px", paddingRight:"30px" }}>
              <div style={{flex:"30%"}}>
                Nama Lengkap
              </div>
              <div style={{ flex: "70%", display:"flex"}}>
                <p style={{marginRight:"10px"}}>:</p>
                <input autoFofcus type='text' value={`${storageItems?.first_name} ${storageItems?.last_name} ` } style={{width:"100%", border:"none", borderBottom:"1px solid #8E8E8E", outline:"none", height:"28px",backgroundColor:"#F5F5F5"}} disabled/>
              </div>    
            </div>

                    
            <div style={{display:"flex", paddingLeft:"50px", paddingRight:"30px" }}>
              <div style={{flex:"30%"}}>
                Pasien Code
              </div>
              <div style={{ flex: "70%", display:"flex"}}>
                <p style={{marginRight:"10px"}}>:</p>
                <input autoFofcus type='text' value={storageItems?.code}  style={{width:"100%", border:"none", borderBottom:"1px solid #8E8E8E", outline:"none", height:"28px",backgroundColor:"#F5F5F5"}} disabled/>
              </div>    
            </div>
          </div>
                  
          <div style={{flex:"50%"}}>
            <div style={{display:"flex", paddingLeft:"30px", paddingRight:"50px" }}>
              <div style={{flex:"30%"}}>
                Jenis Kelamin
              </div>
              <div style={{ flex: "70%", display:"flex"}}>
                <p style={{marginRight:"10px"}}>:</p>
                <input autoFofcus type='text' value={storageItems?.gender} style={{width:"100%", border:"none", borderBottom:"1px solid #8E8E8E", outline:"none", height:"28px",backgroundColor:"#F5F5F5"}} disabled/>
              </div>    
            </div>

            <div style={{display:"flex", paddingLeft:"30px", paddingRight:"50px" }}>
              <div style={{flex:"30%"}}>
                Tgl Lahir
              </div>
              <div style={{ flex: "70%", display:"flex"}}>
                <p style={{marginRight:"10px"}}>:</p>
                <input autoFofcus type='text'
                  value={`${storageItems.date_birth}`.split('-')[2] + (` `) + (
                  `${storageItems.date_birth}`.split('-')[1] === "01" ? "Januari" : "" ||
                  `${storageItems.date_birth}`.split('-')[1] === "02" ? "Februari" : "" ||
                  `${storageItems.date_birth}`.split('-')[1] === "03" ? "Maret" : "" ||
                  `${storageItems.date_birth}`.split('-')[1] === "04" ? "April" : "" ||  
                  `${storageItems.date_birth}`.split('-')[1] === "05" ? "Mei" : "" ||
                  `${storageItems.date_birth}`.split('-')[1] === "06" ? "Juni" : "" ||
                  `${storageItems.date_birth}`.split('-')[1] === "07" ? "Juli" : "" ||
                  `${storageItems.date_birth}`.split('-')[1] === "08" ? "Agustus" : "" ||
                  `${storageItems.date_birth}`.split('-')[1] === "09" ? "September" : "" ||
                  `${storageItems.date_birth}`.split('-')[1] === "10" ? "Oktober" : "" ||
                  `${storageItems.date_birth}`.split('-')[1] === "11" ? "November" : "" ||
                  `${storageItems.date_birth}`.split('-')[1] === "12" ? "Desember" : "") + (` `) +
                  `${storageItems.date_birth}`.split('-')[0]
                  }
                  style={{ width: "100%", border: "none", borderBottom: "1px solid #8E8E8E", outline: "none", height: "28px", backgroundColor: "#F5F5F5" }} disabled />
              </div>    
            </div>      
        </div>
      </div>

      <div style={{ backgroundColor: "white", opacity: ".9", borderBottom:"5px solid #8E8E8E"}}>
      <p style={{  opacity: ".9",fontSize: "20px", fontWeight: "700", color: "#8E8E8E", backgroundColor:"white", padding:"10px 10px 0px 10px"}}>Detail Perawatan Gigi</p>
        <div style={{ padding: "10px 30px" }}>
                    
          <div className="mt-3" style={{ display: "flex" }}>
            <div style={{flex:"15%"}}>
              <p>Anamnesa</p>
            </div>
            <div style={{ flex: "85%", display:"flex"}}>
              <p style={{ marginRight: "5px" }}>:</p>
              <div style={{width:"100%"}}>
                <input type="text" value={anamnesa} name="anamnesa" onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"none", height:"35px", borderRadius:"5px", paddingLeft:"5px"}}/>
                <div style={{fontSize:"12px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>keluhan pasien</div>
              </div>
            
              </div>
                  </div>
                  
                  <div className="mt-4" style={{ display: "flex" }}>
            <div style={{flex:"15%"}}>
              <p>Generate Code</p>
            </div>
            <div style={{ flex: "85%", display:"flex"}}>
                      <p style={{ marginRight: "5px" }}>:</p>
                      <div style={{display:"flex", width:"100%"}}>
                      <div style={{width:"100%", marginRight:"5px"}}>
                <input disabled autoFofcus type='text' value={getCode?.number} name="generate_code" onChange={handleChange} type="text" style={{width:"100%", border:"1px solid #C9C9C9", outline:"none", height:"35px", borderRadius:"5px", paddingLeft:"5px"}}/>
               <div style={{ fontSize: "12px", color: "#898989", fontStyle: "italic", fontWeight: "bold" }}>Tekan tombol disamping untuk mendapatkan code appoinment</div>
                        
              </div>
              <div onClick={generateCode} style={{height:"55%", marginRight:"0px", paddingTop:"0px",backgroundColor:"white",padding:"0px 10px", boxShadow:"1px 1px 3px #8B8B8B", borderRadius:"3px", cursor:"pointer"}}>
                <FaSync style={{ fontSize: "15px", marginRight: "0px", marginTop: "0px", display: "flex", alignItems: "center", height:"100%", color:"#3D64FF" }} />
              </div>
                      </div>
              
            </div>
                  </div>
                  
                    
          <div className="mt-3" style={{ display: "flex"}}>
          <div style={{flex:"15%",display:"flex", alignItems:"center"}}>
            Pilih Dokter
          </div>
          <div style={{ flex: "85%", display:"flex"}}>
            <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <select className="form-select" aria-label="Default select example" onChange={handleChange}  name="id_dokter" style={{ textAlign:"", cursor:"pointer"}}>
              <option value="" hidden>Select Dokter</option>
              {getDataDokter.map((user,index) => (
                <option value={user?.id} style={{textAlign:""}}>{user?.firstname} {user?.lastname}</option>
                ))}         
            </select>
          </div>
        </div>

      {form?.id_dokter && (
        <div className="mt-4" style={{ display: "flex"}}>
        <div style={{flex:"15%"}}>
          <p>Scedule Dokter</p>
        </div>
        <div style={{ flex: "85%", display:"flex"}}>
        <p style={{ marginRight: "5px", height: "100%" }}>:</p> 
        <div style={{ display: "flex", width:"100%" }}>
          <div style={{marginRight:"10px"}}>
            <DatePicker
            className="datePicker"
            placeholderText="Schedule Dokter"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            filterDate={(date) =>
              allowedDates.some(
                (allowedDate) =>
                  allowedDate.value === date.toISOString().slice(0, 10)
              )
            }
            />
        </div>
        <div style={{ flex: "50%" }}>
           <select
            onChange={(e) => setStartDateTime(e.target.value)}
            class="selectPicker"         
            name="hours_appointment"
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              cursor: "pointer",
              height:"40px",
              // height: "10px",
            borderRadius:"4px",
              outline: "none",
              border:"1px solid rgb(198, 198, 198)",
              // borderRadius: "none",
              marginRight: "0px",
              appearance: "none",
              width: "120px",
              color:"#494949"
            }}
            // value={selectedTimePeriod}
          >
            <option value="" hidden >Waktu</option>
            {allowedTimePeriods.map((timePeriod) => (
              <option key={timePeriod} value={timePeriod}>
                {timePeriod.split(":")[0]}:{timePeriod.split(":")[1]}
              </option>
            ))}
          </select>
        </div>                  
       
        </div>              
                     
        </div>
      </div> 
          )}
              
          <div className="" style={{display:"flex",justifyContent:"center", padding: "0px 0px", marginTop: "50px", marginBottom: "20px"}}>
            <div className="mr-4" style={{width:"180px"}}>
              <Button onClick={navigateHome} type='submit' color='secondary' block style={{ fontSize:"15px", borderRadius:"3px", }}>
                Kembali
              </Button>
            </div>
            <div style={{ width: "180px" }}>
              <Button onClick={postUser} type='submit' color='primary' block style={{ fontSize:"15px", borderRadius:"3px", }}>
                Buat Appointment
              </Button>
            </div>
          </div>
                    
        </div>
      </div>
                
      </form>
      </Col>
    </Row>
    }
    </div>
  );
}
