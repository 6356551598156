import { useState } from "react";
import {  useParams } from "react-router-dom";
import "bulma/css/bulma.css";
import "../Styled.css"

export default function ToochNumber({ form, setForm, onclikTooch, handlePolygonClick }) {
  document.title = "Login";
  const token = localStorage.getItem("token");
  // modal role add 
  const { id, code } = useParams()
  const [infoGigi, setInfoGigi] = useState("XX-X")
  return (
    <div style={{ flex: "50%", display: "flex", justifyContent: "end", paddingRight: "" }}>
    
    <div style={{ width: "42px", margin: "1px" }}>
        {form.tooch_number.includes("18-une") || form.tooch_number.includes("18-non") || form.tooch_number.includes("18-pre") || form.tooch_number.includes("18-ano") || form.tooch_number.includes("18-ipx-poc") || form.tooch_number.includes("18-mis-prd-acr") || form.tooch_number.includes("18-mis-fld-acr") ?
          <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
            {form.tooch_number.includes("18-une") &&
              <div style={{ position: "absolute", color: "black" }}>UNE</div>
            }
            {form.tooch_number.includes("18-non") &&
              <div style={{ position: "absolute", color: "black" }}>NON</div>
            }      
            {form.tooch_number.includes("18-pre") &&
              <div style={{ position: "absolute", color: "black" }}>PRE</div>
            }      
            {form.tooch_number.includes("18-ano") &&
              <div style={{ position: "absolute", color: "black" }}>ANO</div>
            }
            {form.tooch_number.includes("18-ipx-poc") &&
              <div style={{ position: "absolute", color: "black" }}>IPX</div>
            }
            {form.tooch_number.includes("18-mis-prd-acr") &&
              <div style={{ position: "absolute", color: "black" }}>PRD</div>
            } 
            {form.tooch_number.includes("18-mis-fld-acr") &&
              <div style={{ position: "absolute", color: "black" }}>FLD</div>
            }      
          </div>
          :
          <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
          </div>
        }
                  
        {form.tooch_number.includes("18-pob-17-miss-pon-pob-16-pob")
          ||form.tooch_number.includes("18-pob-17-miss-pon-pob-16-miss-pon-pob-15-pob")
          ||form.tooch_number.includes("18-pob-17-miss-pon-pob-16-miss-pon-pob-15-miss-pon-pob-14-pob")
          ||form.tooch_number.includes("18-pob-17-miss-pon-pob-16-miss-pon-pob-15-miss-pon-pob-14-miss-pon-pob-13-pob")
          ||form.tooch_number.includes("18-pob-17-miss-pon-pob-16-miss-pon-pob-15-miss-pon-pob-14-miss-pon-pob-13-miss-pon-pob-12-pob")
          ||form.tooch_number.includes("18-pob-17-miss-pon-pob-16-miss-pon-pob-15-miss-pon-pob-14-miss-pon-pob-13-miss-pon-pob-12-miss-pon-pob-11-pob") ?
            <>
          {/* meb 18 - 16 */}
          {form.tooch_number.includes("18-pob-17-miss-pon-pob-16-pob") &&
            <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
            <svg width="100%" height="20vh">
                {/* Garis ke samping dan ke bawah (L-shape) */}
                <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              </svg>
            </div>
          }
                                  
          {/* meb 18 - 15 */}
          {form.tooch_number.includes("18-pob-17-miss-pon-pob-16-miss-pon-pob-15-pob") &&
            <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
            <svg width="100%" height="20vh">

              {/* Garis ke samping dan ke bawah (L-shape) */}
              <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            </svg>
          </div>
          }
          
          {/* meb 18 - 14 */} 
          {form.tooch_number.includes("18-pob-17-miss-pon-pob-16-miss-pon-pob-15-miss-pon-pob-14-pob") &&      
         <div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
         <svg width="100%" height="20vh">

            {/* Garis ke samping dan ke bawah (L-shape) */}
            <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
            <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
          </svg>
          </div> 
          }
          
          {/* meb 18 - 13 */}
          {form.tooch_number.includes("18-pob-17-miss-pon-pob-16-miss-pon-pob-15-miss-pon-pob-14-miss-pon-pob-13-pob") &&      
           <div style={{ width: "259px", textAlign: "center", position: "absolute", height:"10vh" }}>
           <svg width="100%" height="20vh">

              {/* Garis ke samping dan ke bawah (L-shape) */}
              <line x1="20" y1="1.5" x2="241" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="241" y1="0" x2="241" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            </svg>
          </div> 
            }

          {/* meb 18 - 12 */}
          {form.tooch_number.includes("18-pob-17-miss-pon-pob-16-miss-pon-pob-15-miss-pon-pob-14-miss-pon-pob-13-miss-pon-pob-12-pob") &&          
         <div style={{ width: "302px", textAlign: "center", position: "absolute", height:"10vh" }}>
         <svg width="100%" height="20vh">

            {/* Garis ke samping dan ke bawah (L-shape) */}
            <line x1="20" y1="1.5" x2="285" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
            <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            <line x1="285" y1="0" x2="285" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
          </svg>
        </div>
          }

        {/* meb 18 - 11 */}
        {form.tooch_number.includes("18-pob-17-miss-pon-pob-16-miss-pon-pob-15-miss-pon-pob-14-miss-pon-pob-13-miss-pon-pob-12-miss-pon-pob-11-pob") &&        
          <div style={{ width: "345px", textAlign: "center", position: "absolute", height:"10vh" }}>
          <svg width="100%" height="20vh">

              {/* Garis ke samping dan ke bawah (L-shape) */}
              <line x1="20" y1="1.5" x2="329" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="329" y1="0" x2="329" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            </svg>
          </div>
          }          
            </>
              :
            <></>
          }
          
          {form.tooch_number.includes("18-meb-17-miss-pon-meb-16-meb")
          ||form.tooch_number.includes("18-meb-17-miss-pon-meb-16-miss-pon-meb-15-meb")
          ||form.tooch_number.includes("18-meb-17-miss-pon-meb-16-miss-pon-meb-15-miss-pon-meb-14-meb")
          ||form.tooch_number.includes("18-meb-17-miss-pon-meb-16-miss-pon-meb-15-miss-pon-meb-14-miss-pon-meb-13-meb")
          ||form.tooch_number.includes("18-meb-17-miss-pon-meb-16-miss-pon-meb-15-miss-pon-meb-14-miss-pon-meb-13-miss-pon-meb-12-meb")
          ||form.tooch_number.includes("18-meb-17-miss-pon-meb-16-miss-pon-meb-15-miss-pon-meb-14-miss-pon-meb-13-miss-pon-meb-12-miss-pon-meb-11-meb") ?
          <>
          {/* meb 18 - 16 */}
          {form.tooch_number.includes("18-meb-17-miss-pon-meb-16-meb") &&
            <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
            <svg width="100%" height="20vh">

                {/* Garis ke samping dan ke bawah (L-shape) */}
                <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              </svg>
            </div>
          }
                                  
          {/* meb 18 - 15 */}
          {form.tooch_number.includes("18-meb-17-miss-pon-meb-16-miss-pon-meb-15-meb") &&
            <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
            <svg width="100%" height="20vh">

              {/* Garis ke samping dan ke bawah (L-shape) */}
              <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            </svg>
          </div>
          }
          
          {/* meb 18 - 14 */} 
          {form.tooch_number.includes("18-meb-17-miss-pon-meb-16-miss-pon-meb-15-miss-pon-meb-14-meb") &&      
         <div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
         <svg width="100%" height="20vh">

            {/* Garis ke samping dan ke bawah (L-shape) */}
            <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
            <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
          </svg>
          </div> 
          }
          
          {/* meb 18 - 13 */}
          {form.tooch_number.includes("18-meb-17-miss-pon-meb-16-miss-pon-meb-15-miss-pon-meb-14-miss-pon-meb-13-meb") &&      
            <div style={{ width: "259px", textAlign: "center", position: "absolute", height:"10vh" }}>
            <svg width="100%" height="20vh">

              {/* Garis ke samping dan ke bawah (L-shape) */}
              <line x1="20" y1="1.5" x2="241" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="241" y1="0" x2="241" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            </svg>
          </div> 
            }

          {/* meb 18 - 12 */}
          {form.tooch_number.includes("18-meb-17-miss-pon-meb-16-miss-pon-meb-15-miss-pon-meb-14-miss-pon-meb-13-miss-pon-meb-12-meb") &&          
         <div style={{ width: "302px", textAlign: "center", position: "absolute", height:"10vh" }}>
         <svg width="100%" height="20vh">

            {/* Garis ke samping dan ke bawah (L-shape) */}
            <line x1="20" y1="1.5" x2="285" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
            <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            <line x1="285" y1="0" x2="285" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
          </svg>
        </div>
          }

        {/* meb 18 - 11 */}
        {form.tooch_number.includes("18-meb-17-miss-pon-meb-16-miss-pon-meb-15-miss-pon-meb-14-miss-pon-meb-13-miss-pon-meb-12-miss-pon-meb-11-meb") &&        
           <div style={{ width: "345px", textAlign: "center", position: "absolute", height:"10vh" }}>
           <svg width="100%" height="20vh">

              {/* Garis ke samping dan ke bawah (L-shape) */}
              <line x1="20" y1="1.5" x2="329" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="329" y1="0" x2="329" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            </svg>
          </div>
          }          
            </>
              :
            <></>
          }
                
            <div style={{ textAlign: "center" }}>18</div>
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("18-fmc") || form.tooch_number.includes("18-poc") || form.tooch_number.includes("18-ipx-poc") || form.tooch_number.match(/\b18-pob\b/) || form.tooch_number.match(/\b18-miss-pon-pob\b/) || form.tooch_number.match(/\b18-meb\b/) || form.tooch_number.match(/\b18-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
              {form.tooch_number.includes("18-poc") || form.tooch_number.includes("18-ipx-poc") || form.tooch_number.match(/\b18-pob\b/) || form.tooch_number.match(/\b18-miss-pon-pob\b/) ?
                <g>
                  {/* Atas */}
                  {form.tooch_number.includes("18B-fis") || form.tooch_number.includes("18B-amf") || form.tooch_number.includes("18B-cof") || form.tooch_number.includes("18B-car") ?
                    <>
                      {form.tooch_number.includes("18B-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="18B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 18B"
                            points="0,0 40,0 30,10 10,10"
                            style={{ fill: 'url(#18B-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("18-Bukal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("18B-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 18B"
                          points="0,0 40,0 30,10 10,10"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("18-Bukal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("18B-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="18B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 18B"
                            points="0,0 40,0 30,10 10,10"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("18-Bukal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
            
                      {form.tooch_number.includes("18B-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 18B"
                          points="0,0 40,0 30,10 10,10"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("18-Bukal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
                    
                    </>
                    :
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="18B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 18B"
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("18-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                  }

                  {/* Bawah */}
                  {form.tooch_number.includes("18P-fis") || form.tooch_number.includes("18P-amf") || form.tooch_number.includes("18P-cof") || form.tooch_number.includes("18P-car") ?
                    <>
                      {form.tooch_number.includes("18P-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="18P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>

                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 18P"
                            points="10,30 30,30 40,40 0,40"
                            style={{ fill: 'url(#18P-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("18-Palatal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("18P-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 18P"
                          points="10,30 30,30 40,40 0,40"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("18-Palatal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("18P-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="18P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 18P"
                            points="10,30 30,30 40,40 0,40"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("18-Palatal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }

                      {form.tooch_number.includes("18P-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 18P"
                          points="10,30 30,30 40,40 0,40"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("18-Palatal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
                    </>
                    :
                    <svg width="40" height="40">
                      <defs>
                        <pattern id="18P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                        </pattern>
                      </defs>
                      <polygon
                        onClick={onclikTooch}
                        data-nomor-gigi=" 18P"
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: '#007610', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("18-Palatal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </svg>
                  }
          
                  {/* Kanan */}
                  {form.tooch_number.includes("18M-fis") || form.tooch_number.includes("18M-amf") || form.tooch_number.includes("18M-cof") || form.tooch_number.includes("18M-car") ?
                    <>
                      {form.tooch_number.includes("18M-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="18M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 18M"
                            points="30,10 40,0 40,40 30,30"
                            style={{ fill: 'url(#18M-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("18-Mesial")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("18M-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 18M"
                          points="30,10 40,0 40,40 30,30"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("18-Mesial")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("18M-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="18M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 18M"
                            points="30,10 40,0 40,40 30,30"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("18-Mesial")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
                    
                      {form.tooch_number.includes("18M-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 18M"
                          points="30,10 40,0 40,40 30,30"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("18-Mesial")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
                    
                    </>
                    :
                    <svg width="40" height="40">
                      <defs>
                        <pattern id="18M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                        </pattern>
                      </defs>
                      <polygon
                        onClick={onclikTooch}
                        data-nomor-gigi=" 18M"
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: '#007610', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("18-Mesial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </svg>
                  }

                  {/* Kiri */}
                  {form.tooch_number.includes("18D-fis") || form.tooch_number.includes("18D-amf") || form.tooch_number.includes("18D-cof") || form.tooch_number.includes("18D-car") ?
                    <>
                      {form.tooch_number.includes("18D-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="18D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 18D"
                            points="0,0 10,10 10,30 0,40"
                            style={{ fill: 'url(#18D-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("18-Distal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("18D-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 18D"
                          points="0,0 10,10 10,30 0,40"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("18-Distal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("18D-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="18D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 18D"
                            points="0,0 10,10 10,30 0,40"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("18-Distal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }

                      {form.tooch_number.includes("18D-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 18D"
                          points="0,0 10,10 10,30 0,40"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("18-Distal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }

                    </>
                    :
                    <svg width="40" height="40">
                      <defs>
                        <pattern id="18D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                        </pattern>
                      </defs>
                      <polygon
                        onClick={onclikTooch}
                        data-nomor-gigi=" 18D"
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: '#007610', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("18-Distal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </svg>
                  }

                  {/* center */}
                  {form.tooch_number.includes("18O-fis") || form.tooch_number.includes("18O-amf") || form.tooch_number.includes("18O-cof") || form.tooch_number.includes("18O-car") ?
                    <>
                      {form.tooch_number.includes("18O-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="18O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 18O"
                            points="10,10 30,10 30,30 10,30"
                            style={{ fill: 'url(#18O-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("18-Oklusal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("18O-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 18O"
                          points="10,10 30,10 30,30 10,30"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("18-Oklusal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("18O-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="18O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 18O"
                            points="10,10 30,10 30,30 10,30"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("18-Oklusal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }

                      {form.tooch_number.includes("18O-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 18O"
                          points="10,10 30,10 30,30 10,30"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("18-Oklusal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }

                    </>
                    :
                    <svg width="40" height="40">
                      <defs>
                        <pattern id="18O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                        </pattern>
                      </defs>
                      <polygon
                        onClick={onclikTooch}
                        data-nomor-gigi=" 18O"
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: '#007610', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("18-Oklusal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </svg>
                  }

                  {/* cfr */}
                  {form.tooch_number.includes("18-cfr") &&
                    <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
                      #
                    </text>
                  }

                  {/* rrx */}
                  {form.tooch_number.includes("18-rrx") &&    
                  <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 18-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
                    v
                  </text>
                  }
                        
                  {/* mis */}
                  {form.tooch_number.includes("18-mis") &&    
                  <>  
                    <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
                    <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
                  </> 
                  }
                          
                </g>
                  :
                <g>
                  {/* Atas */}
                  {form.tooch_number.includes("18B-fis") || form.tooch_number.includes("18B-amf") || form.tooch_number.includes("18B-cof") || form.tooch_number.includes("18B-car") ?
                    <>
                      {form.tooch_number.includes("18B-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="18B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 18B"
                            points="0,0 40,0 30,10 10,10"
                            style={{ fill: 'url(#18B-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("18-Bukal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("18B-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 18B"
                          points="0,0 40,0 30,10 10,10"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("18-Bukal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("18B-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="18B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 18B"
                            points="0,0 40,0 30,10 10,10"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("18-Bukal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
            
                      {form.tooch_number.includes("18B-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 18B"
                          points="0,0 40,0 30,10 10,10"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("18-Bukal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
                    
                    </>
                    :
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 18B"
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("18-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  }

                  {/* Bawah */}
                  {form.tooch_number.includes("18P-fis") || form.tooch_number.includes("18P-amf") || form.tooch_number.includes("18P-cof") || form.tooch_number.includes("18P-car") ?
                    <>
                      {form.tooch_number.includes("18P-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="18P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>

                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 18P"
                            points="10,30 30,30 40,40 0,40"
                            style={{ fill: 'url(#18P-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("18-Palatal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("18P-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 18P"
                          points="10,30 30,30 40,40 0,40"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("18-Palatal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("18P-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="18P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 18P"
                            points="10,30 30,30 40,40 0,40"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("18-Palatal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }

                      {form.tooch_number.includes("18P-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 18P"
                          points="10,30 30,30 40,40 0,40"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("18-Palatal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
                    </>
                    :
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 18P"
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("18-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  }
          
                  {/* Kanan */}
                  {form.tooch_number.includes("18M-fis") || form.tooch_number.includes("18M-amf") || form.tooch_number.includes("18M-cof") || form.tooch_number.includes("18M-car") ?
                    <>
                      {form.tooch_number.includes("18M-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="18M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 18M"
                            points="30,10 40,0 40,40 30,30"
                            style={{ fill: 'url(#18M-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("18-Mesial")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("18M-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 18M"
                          points="30,10 40,0 40,40 30,30"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("18-Mesial")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("18M-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="18M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 18M"
                            points="30,10 40,0 40,40 30,30"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("18-Mesial")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
                    
                      {form.tooch_number.includes("18M-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 18M"
                          points="30,10 40,0 40,40 30,30"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("18-Mesial")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
                    
                    </>
                    :
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 18M"
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("18-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  }

                  {/* Kiri */}
                  {form.tooch_number.includes("18D-fis") || form.tooch_number.includes("18D-amf") || form.tooch_number.includes("18D-cof") || form.tooch_number.includes("18D-car") ?
                    <>
                      {form.tooch_number.includes("18D-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="18D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 18D"
                            points="0,0 10,10 10,30 0,40"
                            style={{ fill: 'url(#18D-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("18-Distal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("18D-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 18D"
                          points="0,0 10,10 10,30 0,40"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("18-Distal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("18D-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="18D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 18D"
                            points="0,0 10,10 10,30 0,40"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("18-Distal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }

                      {form.tooch_number.includes("18D-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 18D"
                          points="0,0 10,10 10,30 0,40"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("18-Distal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }

                    </>
                    :
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 18D"
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("18-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  }

                  {/* center */}
                  {form.tooch_number.includes("18O-fis") || form.tooch_number.includes("18O-amf") || form.tooch_number.includes("18O-cof") || form.tooch_number.includes("18O-car") ?
                    <>
                      {form.tooch_number.includes("18O-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="18O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 18O"
                            points="10,10 30,10 30,30 10,30"
                            style={{ fill: 'url(#18O-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("18-Oklusal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("18O-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 18O"
                          points="10,10 30,10 30,30 10,30"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("18-Oklusal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("18O-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="18O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 18O"
                            points="10,10 30,10 30,30 10,30"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("18-Oklusal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }

                      {form.tooch_number.includes("18O-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 18O"
                          points="10,10 30,10 30,30 10,30"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("18-Oklusal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }

                    </>
                    :
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 18O"
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("18-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  }

                  {/* cfr */}
                  {form.tooch_number.includes("18-cfr") &&
                    <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
                      #
                    </text>
                  }
                  
                  {form.tooch_number.includes("18-rrx") &&    
                  <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 18-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
                    v
                  </text>
                  }
                  
                  {form.tooch_number.includes("18-mis") &&    
                  <>  
                    <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
                    <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
                  </> 
                  }
                </g>
              }      
            </svg>
                  
      
          
        </div>
        <div style={{ width: "42px", margin: "1px" }}>
        {form.tooch_number.includes("17-une") || form.tooch_number.includes("17-non") || form.tooch_number.includes("17-pre") || form.tooch_number.includes("17-ano") || form.tooch_number.includes("17-ipx-poc") || form.tooch_number.includes("17-mis-prd-acr") || form.tooch_number.includes("17-mis-fld-acr") ?
        <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
        {form.tooch_number.includes("17-une") &&
        <div style={{ position: "absolute", color: "black" }}>UNE</div>
        }
        {form.tooch_number.includes("17-non") &&
        <div style={{ position: "absolute", color: "black" }}>NON</div>
        }      
        {form.tooch_number.includes("17-pre") &&
        <div style={{ position: "absolute", color: "black" }}>PRE</div>
        }      
        {form.tooch_number.includes("17-ano") &&
        <div style={{ position: "absolute", color: "black" }}>ANO</div>
        }
        {form.tooch_number.includes("17-ipx-poc") &&
        <div style={{ position: "absolute", color: "black" }}>IPX</div>
        }
        {form.tooch_number.includes("17-mis-prd-acr") &&
        <div style={{ position: "absolute", color: "black" }}>PRD</div>
        } 
        {form.tooch_number.includes("17-mis-fld-acr") &&
        <div style={{ position: "absolute", color: "black" }}>FLD</div>
        }      
        </div>
        :
        <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
        </div>
        }
          
        {form.tooch_number.includes("17-pob-16-miss-pon-pob-15-pob")
        ||form.tooch_number.includes("17-pob-16-miss-pon-pob-15-miss-pon-pob-14-pob")
        ||form.tooch_number.includes("17-pob-16-miss-pon-pob-15-miss-pon-pob-14-miss-pon-pob-13-pob")
        ||form.tooch_number.includes("17-pob-16-miss-pon-pob-15-miss-pon-pob-14-miss-pon-pob-13-miss-pon-pob-12-pob")
        ||form.tooch_number.includes("17-pob-16-miss-pon-pob-15-miss-pon-pob-14-miss-pon-pob-13-miss-pon-pob-12-miss-pon-pob-11-pob")
        ?
        <>
        {/* meb 17 - 16 */}
        {form.tooch_number.includes("17-pob-16-miss-pon-pob-15-pob") &&
        <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
        <svg width="100%" height="20vh">

        {/* Garis ke samping dan ke bawah (L-shape) */}
        <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        </svg>
        </div>
        }
                          
        {/* meb 17 - 15 */}
        {form.tooch_number.includes("17-pob-16-miss-pon-pob-15-miss-pon-pob-14-pob") &&
       <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
       <svg width="100%" height="20vh">

        {/* Garis ke samping dan ke bawah (L-shape) */}
        <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        </svg>
        </div>
        }

        {/* meb 17 - 14 */} 
        {form.tooch_number.includes("17-pob-16-miss-pon-pob-15-miss-pon-pob-14-miss-pon-pob-13-pob") &&      
       <div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
       <svg width="100%" height="20vh">

        {/* Garis ke samping dan ke bawah (L-shape) */}
        <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        </svg>
        </div> 
        }

        {/* meb 17 - 13 */}
        {form.tooch_number.includes("17-pob-16-miss-pon-pob-15-miss-pon-pob-14-miss-pon-pob-13-miss-pon-pob-12-pob") &&      
        <div style={{ width: "259px", textAlign: "center", position: "absolute", height:"10vh" }}>
        <svg width="100%" height="20vh">
        {/* Garis ke samping dan ke bawah (L-shape) */}
        <line x1="20" y1="1.5" x2="241" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="241" y1="0" x2="241" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        </svg>
        </div> 
        }

        {/* meb 17 - 12 */}
        {form.tooch_number.includes("17-pob-16-miss-pon-pob-15-miss-pon-pob-14-miss-pon-pob-13-miss-pon-pob-12-miss-pon-pob-11-pob") &&          
       <div style={{ width: "302px", textAlign: "center", position: "absolute", height:"10vh" }}>
       <svg width="100%" height="20vh">

        {/* Garis ke samping dan ke bawah (L-shape) */}
        <line x1="20" y1="1.5" x2="285" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="285" y1="0" x2="285" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        </svg>
        </div>
        }

        </>
        :
        <></>
        }

        {form.tooch_number.includes("17-meb-16-miss-pon-meb-15-meb")
        ||form.tooch_number.includes("17-meb-16-miss-pon-meb-15-miss-pon-meb-14-meb")
        ||form.tooch_number.includes("17-meb-16-miss-pon-meb-15-miss-pon-meb-14-miss-pon-meb-13-meb")
        ||form.tooch_number.includes("17-meb-16-miss-pon-meb-15-miss-pon-meb-14-miss-pon-meb-13-miss-pon-meb-12-meb")
        ||form.tooch_number.includes("17-meb-16-miss-pon-meb-15-miss-pon-meb-14-miss-pon-meb-13-miss-pon-meb-12-miss-pon-meb-11-meb")
        ?
        <>
        {/* meb 17 - 15 */}
        {form.tooch_number.includes("17-meb-16-miss-pon-meb-15-meb") &&
        <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
        <svg width="100%" height="20vh">

        {/* Garis ke samping dan ke bawah (L-shape) */}
        <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        </svg>
        </div>
        }
                          
        {/* meb 17 - 14 */}
        {form.tooch_number.includes("17-meb-16-miss-pon-meb-15-miss-pon-meb-14-meb") &&
       <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
       <svg width="100%" height="20vh">

        {/* Garis ke samping dan ke bawah (L-shape) */}
        <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        </svg>
        </div>
        }

        {/* meb 17 - 13 */} 
        {form.tooch_number.includes("17-meb-16-miss-pon-meb-15-miss-pon-meb-14-miss-pon-meb-13-meb") &&      
       <div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
       <svg width="100%" height="20vh">
        {/* Garis ke samping dan ke bawah (L-shape) */}
        <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        </svg>
        </div> 
        }

        {/* meb 17 - 12 */}
        {form.tooch_number.includes("17-meb-16-miss-pon-meb-15-miss-pon-meb-14-miss-pon-meb-13-miss-pon-meb-12-meb") &&      
       <div style={{ width: "259px", textAlign: "center", position: "absolute", height:"10vh" }}>
       <svg width="100%" height="20vh">

        {/* Garis ke samping dan ke bawah (L-shape) */}
        <line x1="20" y1="1.5" x2="241" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="241" y1="0" x2="241" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        </svg>
        </div> 
        }

        {/* meb 17 - 11W */}
        {form.tooch_number.includes("17-meb-16-miss-pon-meb-15-miss-pon-meb-14-miss-pon-meb-13-miss-pon-meb-12-miss-pon-meb-11-meb") &&          
       <div style={{ width: "302px", textAlign: "center", position: "absolute", height:"10vh" }}>
       <svg width="100%" height="20vh">

        {/* Garis ke samping dan ke bawah (L-shape) */}
        <line x1="20" y1="1.5" x2="285" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="285" y1="0" x2="285" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        </svg>
        </div>
        }

        </>
        :
        <></>
        }
        
        <div style={{ textAlign: "center" }}>17</div>
        <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("17-fmc") || form.tooch_number.includes("17-poc") || form.tooch_number.includes("17-ipx-poc") || form.tooch_number.match(/\b17-pob\b/) || form.tooch_number.match(/\b17-miss-pon-pob\b/) || form.tooch_number.match(/\b17-meb\b/) || form.tooch_number.match(/\b17-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
        {form.tooch_number.includes("17-poc") || form.tooch_number.includes("17-ipx-poc") || form.tooch_number.match(/\b17-pob\b/) || form.tooch_number.match(/\b17-miss-pon-pob\b/) ?
        <g>
          {/* Atas */}
          {form.tooch_number.includes("17B-fis") || form.tooch_number.includes("17B-amf") || form.tooch_number.includes("17B-cof") || form.tooch_number.includes("17B-car") ?
            <>
              {form.tooch_number.includes("17B-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="17B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 17B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: 'url(#17B-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("17-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("17B-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 17B"
                  points="0,0 40,0 30,10 10,10"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("17-Bukal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("17B-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="17B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 17B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("17-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("17B-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 17B"
                  points="0,0 40,0 30,10 10,10"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("17-Bukal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }
            
            </>
            :
          <svg width="40" height="40">
            <defs>
              <pattern id="17B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 17B"
              points="0,0 40,0 30,10 10,10"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("17-Bukal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
          }

          {/* Bawah */}
          {form.tooch_number.includes("17P-fis") || form.tooch_number.includes("17P-amf") || form.tooch_number.includes("17P-cof") || form.tooch_number.includes("17P-car") ?
            <>
              {form.tooch_number.includes("17P-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="17P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>

                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 17P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: 'url(#17P-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("17-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("17P-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 17P"
                  points="10,30 30,30 40,40 0,40"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("17-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("17P-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="17P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 17P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("17-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("17P-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 17P"
                  points="10,30 30,30 40,40 0,40"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("17-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }
            </>
            :
            <svg width="40" height="40">
              <defs>
                <pattern id="17P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                  <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                </pattern>
              </defs>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 17P"
                points="10,30 30,30 40,40 0,40"
                style={{ fill: '#007610', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("17-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            </svg>
          }

          {/* Kanan */}
          {form.tooch_number.includes("17M-fis") || form.tooch_number.includes("17M-amf") || form.tooch_number.includes("17M-cof") || form.tooch_number.includes("17M-car") ?
            <>
              {form.tooch_number.includes("17M-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="17M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 17M"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: 'url(#17M-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("17-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("17M-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 17M"
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("17-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("17M-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="17M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 17M"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("17-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }
            
              {form.tooch_number.includes("17M-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 17M"
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("17-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }
            
            </>
            :
            <svg width="40" height="40">
              <defs>
                <pattern id="17M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                  <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                </pattern>
              </defs>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 17M"
                points="30,10 40,0 40,40 30,30"
                style={{ fill: '#007610', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("17-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            </svg>
          }

          {/* Kiri */}
          {form.tooch_number.includes("17D-fis") || form.tooch_number.includes("17D-amf") || form.tooch_number.includes("17D-cof") || form.tooch_number.includes("17D-car") ?
            <>
              {form.tooch_number.includes("17D-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="17D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 17D"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: 'url(#17D-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("17-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("17D-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 17D"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("17-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("17D-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="17D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 17D"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("17-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("17D-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 17D"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("17-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

            </>
            :
            <svg width="40" height="40">
              <defs>
                <pattern id="17D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                  <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                </pattern>
              </defs>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 17D"
                points="0,0 10,10 10,30 0,40"
                style={{ fill: '#007610', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("17-Distal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            </svg>
          }

          {/* center */}
          {form.tooch_number.includes("17O-fis") || form.tooch_number.includes("17O-amf") || form.tooch_number.includes("17O-cof") || form.tooch_number.includes("17O-car") ?
            <>
              {form.tooch_number.includes("17O-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="17O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 17O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: 'url(#17O-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("17-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("17O-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 17O"
                  points="10,10 30,10 30,30 10,30"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("17-Oklusal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("17O-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="17O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 17O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("17-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("17O-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 17O"
                  points="10,10 30,10 30,30 10,30"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("17-Oklusal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

            </>
            :
            <svg width="40" height="40">
              <defs>
                <pattern id="17O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                  <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                </pattern>
              </defs>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 17O"
                points="10,10 30,10 30,30 10,30"
                style={{ fill: '#007610', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("17-Oklusal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            </svg>
          }

          {/* cfr */}
          {form.tooch_number.includes("17-cfr") &&
            <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
              #
            </text>
          }

          {/* rrx */}
          {form.tooch_number.includes("17-rrx") &&    
          <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 17-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
            v
          </text>
          }
                
          {/* mis */}
          {form.tooch_number.includes("17-mis") &&    
          <>  
            <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
            <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
          </> 
          }
                  
        </g>
          :
        <g>
          {/* Atas */}
          {form.tooch_number.includes("17B-fis") || form.tooch_number.includes("17B-amf") || form.tooch_number.includes("17B-cof") || form.tooch_number.includes("17B-car") ?
            <>
              {form.tooch_number.includes("17B-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="17B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 17B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: 'url(#17B-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("17-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("17B-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 17B"
                  points="0,0 40,0 30,10 10,10"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("17-Bukal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("17B-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="17B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 17B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("17-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("17B-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 17B"
                  points="0,0 40,0 30,10 10,10"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("17-Bukal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }
            
            </>
            :
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 17B"
              points="0,0 40,0 30,10 10,10"
              style={{ fill: 'white', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("17-Bukal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          }

          {/* Bawah */}
          {form.tooch_number.includes("17P-fis") || form.tooch_number.includes("17P-amf") || form.tooch_number.includes("17P-cof") || form.tooch_number.includes("17P-car") ?
            <>
              {form.tooch_number.includes("17P-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="17P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>

                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 17P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: 'url(#17P-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("17-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("17P-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 17P"
                  points="10,30 30,30 40,40 0,40"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("17-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("17P-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="17P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 17P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("17-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("17P-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 17P"
                  points="10,30 30,30 40,40 0,40"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("17-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }
            </>
            :
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 17P"
              points="10,30 30,30 40,40 0,40"
              style={{ fill: 'white', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("17-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          }

          {/* Kanan */}
          {form.tooch_number.includes("17M-fis") || form.tooch_number.includes("17M-amf") || form.tooch_number.includes("17M-cof") || form.tooch_number.includes("17M-car") ?
            <>
              {form.tooch_number.includes("17M-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="17M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 17M"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: 'url(#17M-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("17-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("17M-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 17M"
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("17-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("17M-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="17M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 17M"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("17-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }
            
              {form.tooch_number.includes("17M-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 17M"
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("17-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }
            
            </>
            :
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 17M"
              points="30,10 40,0 40,40 30,30"
              style={{ fill: 'white', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("17-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          }

          {/* Kiri */}
          {form.tooch_number.includes("17D-fis") || form.tooch_number.includes("17D-amf") || form.tooch_number.includes("17D-cof") || form.tooch_number.includes("17D-car") ?
            <>
              {form.tooch_number.includes("17D-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="17D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 17D"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: 'url(#17D-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("17-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("17D-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 17D"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("17-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("17D-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="17D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 17D"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("17-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("17D-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 17D"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("17-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

            </>
            :
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 17D"
              points="0,0 10,10 10,30 0,40"
              style={{ fill: 'white', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("17-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          }

          {/* center */}
          {form.tooch_number.includes("17O-fis") || form.tooch_number.includes("17O-amf") || form.tooch_number.includes("17O-cof") || form.tooch_number.includes("17O-car") ?
            <>
              {form.tooch_number.includes("17O-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="17O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 17O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: 'url(#17O-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("17-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("17O-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 17O"
                  points="10,10 30,10 30,30 10,30"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("17-Oklusal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("17O-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="17O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 17O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("17-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("17O-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 17O"
                  points="10,10 30,10 30,30 10,30"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("17-Oklusal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

            </>
            :
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 17O"
              points="10,10 30,10 30,30 10,30"
              style={{ fill: 'white', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("17-Oklusal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          }

          {/* cfr */}
          {form.tooch_number.includes("17-cfr") &&
            <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
              #
            </text>
          }
          
          {form.tooch_number.includes("17-rrx") &&    
          <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 17-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
            v
          </text>
          }
          
          {form.tooch_number.includes("17-mis") &&    
          <>  
            <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
            <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
          </> 
          }
        </g>
        }      
        </svg>
          
        {form.tooch_number.includes("17-nvt") &&   
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
        <svg width={42} height={42}>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 17"
          points="21,2 41,32 1,32" 
          style={{ fill: 'white', stroke: 'black', strokeWidth: 3 }}
          onMouseEnter={() => setInfoGigi('17')}
          onMouseLeave={() => setInfoGigi('XX-X')}
        />
        </svg> 
        </div>
        }  

        {form.tooch_number.includes("17-rct") &&   
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
        <svg width={42} height={42}>
        <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 17"
        points="21,2 41,32 1,32" 
        style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
        onMouseEnter={() => setInfoGigi('17')}
        onMouseLeave={() => setInfoGigi('XX-X')}
        />
        </svg>
        </div>
        } 
          
        {form.tooch_number.includes("17B-amf-rct") || form.tooch_number.includes("17P-amf-rct") || form.tooch_number.includes("17M-amf-rct") || form.tooch_number.includes("17D-amf-rct") || form.tooch_number.includes("17O-amf-rct") ||
        form.tooch_number.includes("17B-cof-rct") || form.tooch_number.includes("17P-cof-rct") || form.tooch_number.includes("17M-cof-rct") || form.tooch_number.includes("17D-cof-rct") || form.tooch_number.includes("17O-cof-rct") ||
        form.tooch_number.includes("17-fmc-rct") ||
        form.tooch_number.includes("17-poc-rct") ?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
        <svg width={42} height={42}>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 17B"
          points="21,2 41,32 1,32" 
          style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
          onMouseEnter={() => setInfoGigi('17-Bukal')}
          onMouseLeave={() => setInfoGigi('XX-X')}
        />
        </svg>
        </div>
        :
        <></>
        } 

        </div>

        <div style={{ width: "42px", margin: "1px" }}>
          {form.tooch_number.includes("16-une") || form.tooch_number.includes("16-non") || form.tooch_number.includes("16-pre") || form.tooch_number.includes("16-ano") || form.tooch_number.includes("16-ipx-poc") || form.tooch_number.includes("16-mis-prd-acr") || form.tooch_number.includes("16-mis-fld-acr") ?
            <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
              {form.tooch_number.includes("16-une") &&
                <div style={{ position: "absolute", color: "black" }}>UNE</div>
              }
              {form.tooch_number.includes("16-non") &&
                <div style={{ position: "absolute", color: "black" }}>NON</div>
              }      
              {form.tooch_number.includes("16-pre") &&
                <div style={{ position: "absolute", color: "black" }}>PRE</div>
              }      
              {form.tooch_number.includes("16-ano") &&
                <div style={{ position: "absolute", color: "black" }}>ANO</div>
              }
              {form.tooch_number.includes("16-ipx-poc") &&
                <div style={{ position: "absolute", color: "black" }}>IPX</div>
              }
              {form.tooch_number.includes("16-mis-prd-acr") &&
                <div style={{ position: "absolute", color: "black" }}>PRD</div>
              } 
              {form.tooch_number.includes("16-mis-fld-acr") &&
                <div style={{ position: "absolute", color: "black" }}>FLD</div>
              }      
            </div>
            :
            <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
            </div>
          }
                  
          {form.tooch_number.includes("16-pob-15-miss-pon-pob-14-pob")
          ||form.tooch_number.includes("16-pob-15-miss-pon-pob-14-miss-pon-pob-13-pob")
          ||form.tooch_number.includes("16-pob-15-miss-pon-pob-14-miss-pon-pob-13-miss-pon-pob-12-pob")
          ||form.tooch_number.includes("16-pob-15-miss-pon-pob-14-miss-pon-pob-13-miss-pon-pob-12-miss-pon-pob-11-pob") ?
          <>
          {/* meb 16 - 14 */}
          {form.tooch_number.includes("16-pob-15-miss-pon-pob-14-pob") &&
            <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
            <svg width="100%" height="20vh">

                {/* Garis ke samping dan ke bawah (L-shape) */}
                <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              </svg>
            </div>
          }
                                  
          {/* meb 16 - 13 */}
          {form.tooch_number.includes("16-pob-15-miss-pon-pob-14-miss-pon-pob-13-pob") &&
            <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
            <svg width="100%" height="20vh">

              {/* Garis ke samping dan ke bawah (L-shape) */}
              <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            </svg>
          </div>
          }
          
          {/* meb 16 - 12 */} 
          {form.tooch_number.includes("16-pob-15-miss-pon-pob-14-miss-pon-pob-13-miss-pon-pob-12-pob") &&      
          <div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
          <svg width="100%" height="20vh">

            {/* Garis ke samping dan ke bawah (L-shape) */}
            <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
            <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
          </svg>
          </div> 
          }
          
          {/* meb 16 - 11 */}
          {form.tooch_number.includes("16-pob-15-miss-pon-pob-14-miss-pon-pob-13-miss-pon-pob-12-miss-pon-pob-11-pob") &&      
           <div style={{ width: "259px", textAlign: "center", position: "absolute", height:"10vh" }}>
           <svg width="100%" height="20vh">

              {/* Garis ke samping dan ke bawah (L-shape) */}
              <line x1="20" y1="1.5" x2="241" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="241" y1="0" x2="241" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            </svg>
          </div> 
            }     
            </>
              :
            <></>
          }
          
          {form.tooch_number.includes("16-meb-15-miss-pon-meb-14-meb")
          ||form.tooch_number.includes("16-meb-15-miss-pon-meb-14-miss-pon-meb-13-meb")
          ||form.tooch_number.includes("16-meb-15-miss-pon-meb-14-miss-pon-meb-13-miss-pon-meb-12-meb")
          ||form.tooch_number.includes("16-meb-15-miss-pon-meb-14-miss-pon-meb-13-miss-pon-meb-12-miss-pon-meb-11-meb") ?
          <>
          {/* meb 16 - 14 */}
          {form.tooch_number.includes("16-meb-15-miss-pon-meb-14-meb") &&
           <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
           <svg width="100%" height="20vh">

                {/* Garis ke samping dan ke bawah (L-shape) */}
                <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              </svg>
            </div>
          }
                                  
          {/* meb 16 - 13 */}
          {form.tooch_number.includes("16-meb-15-miss-pon-meb-14-miss-pon-meb-13-meb") &&
          <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
          <svg width="100%" height="20vh">

              {/* Garis ke samping dan ke bawah (L-shape) */}
              <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            </svg>
          </div>
          }
          
          {/* meb 16 - 12 */} 
          {form.tooch_number.includes("16-meb-15-miss-pon-meb-14-miss-pon-meb-13-miss-pon-meb-12-meb") &&      
         <div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
         <svg width="100%" height="20vh">

            {/* Garis ke samping dan ke bawah (L-shape) */}
            <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
            <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
          </svg>
          </div> 
          }
          
          {/* meb 16 - 11 */}
          {form.tooch_number.includes("16-meb-15-miss-pon-meb-14-miss-pon-meb-13-miss-pon-meb-12-miss-pon-meb-11-meb") &&      
           <div style={{ width: "259px", textAlign: "center", position: "absolute", height:"10vh" }}>
           <svg width="100%" height="20vh">


              {/* Garis ke samping dan ke bawah (L-shape) */}
              <line x1="20" y1="1.5" x2="241" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="241" y1="0" x2="241" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            </svg>
          </div> 
            }     
            </>
              :
            <></>
          }
                
            <div style={{ textAlign: "center" }}>16</div>
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("16-fmc") || form.tooch_number.includes("16-poc") || form.tooch_number.includes("16-ipx-poc") || form.tooch_number.match(/\b16-pob\b/) || form.tooch_number.match(/\b16-miss-pon-pob\b/) || form.tooch_number.match(/\b16-meb\b/) || form.tooch_number.match(/\b16-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
              {form.tooch_number.includes("16-poc") || form.tooch_number.includes("16-ipx-poc") || form.tooch_number.match(/\b16-pob\b/) || form.tooch_number.match(/\b16-miss-pon-pob\b/) ?
                <g>
                  {/* Atas */}
                  {form.tooch_number.includes("16B-fis") || form.tooch_number.includes("16B-amf") || form.tooch_number.includes("16B-cof") || form.tooch_number.includes("16B-car") ?
                    <>
                      {form.tooch_number.includes("16B-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="16B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 16B"
                            points="0,0 40,0 30,10 10,10"
                            style={{ fill: 'url(#16B-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("16-Bukal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("16B-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 16B"
                          points="0,0 40,0 30,10 10,10"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("16-Bukal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("16B-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="16B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 16B"
                            points="0,0 40,0 30,10 10,10"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("16-Bukal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
            
                      {form.tooch_number.includes("16B-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 16B"
                          points="0,0 40,0 30,10 10,10"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("16-Bukal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
                    
                    </>
                    :
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="16B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 16B"
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("16-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                  }

                  {/* Bawah */}
                  {form.tooch_number.includes("16P-fis") || form.tooch_number.includes("16P-amf") || form.tooch_number.includes("16P-cof") || form.tooch_number.includes("16P-car") ?
                    <>
                      {form.tooch_number.includes("16P-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="16P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>

                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 16P"
                            points="10,30 30,30 40,40 0,40"
                            style={{ fill: 'url(#16P-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("16-Palatal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("16P-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 16P"
                          points="10,30 30,30 40,40 0,40"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("16-Palatal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("16P-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="16P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 16P"
                            points="10,30 30,30 40,40 0,40"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("16-Palatal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }

                      {form.tooch_number.includes("16P-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 16P"
                          points="10,30 30,30 40,40 0,40"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("16-Palatal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
                    </>
                    :
                    <svg width="40" height="40">
                      <defs>
                        <pattern id="16P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                        </pattern>
                      </defs>
                      <polygon
                        onClick={onclikTooch}
                        data-nomor-gigi=" 16P"
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: '#007610', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("16-Palatal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </svg>
                  }
          
                  {/* Kanan */}
                  {form.tooch_number.includes("16M-fis") || form.tooch_number.includes("16M-amf") || form.tooch_number.includes("16M-cof") || form.tooch_number.includes("16M-car") ?
                    <>
                      {form.tooch_number.includes("16M-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="16M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 16M"
                            points="30,10 40,0 40,40 30,30"
                            style={{ fill: 'url(#16M-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("16-Mesial")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("16M-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 16M"
                          points="30,10 40,0 40,40 30,30"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("16-Mesial")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("16M-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="16M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 16M"
                            points="30,10 40,0 40,40 30,30"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("16-Mesial")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
                    
                      {form.tooch_number.includes("16M-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 16M"
                          points="30,10 40,0 40,40 30,30"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("16-Mesial")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
                    
                    </>
                    :
                    <svg width="40" height="40">
                      <defs>
                        <pattern id="16M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                        </pattern>
                      </defs>
                      <polygon
                        onClick={onclikTooch}
                        data-nomor-gigi=" 16M"
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: '#007610', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("16-Mesial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </svg>
                  }

                  {/* Kiri */}
                  {form.tooch_number.includes("16D-fis") || form.tooch_number.includes("16D-amf") || form.tooch_number.includes("16D-cof") || form.tooch_number.includes("16D-car") ?
                    <>
                      {form.tooch_number.includes("16D-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="16D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 16D"
                            points="0,0 10,10 10,30 0,40"
                            style={{ fill: 'url(#16D-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("16-Distal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("16D-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 16D"
                          points="0,0 10,10 10,30 0,40"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("16-Distal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("16D-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="16D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 16D"
                            points="0,0 10,10 10,30 0,40"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("16-Distal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }

                      {form.tooch_number.includes("16D-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 16D"
                          points="0,0 10,10 10,30 0,40"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("16-Distal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }

                    </>
                    :
                    <svg width="40" height="40">
                      <defs>
                        <pattern id="16D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                        </pattern>
                      </defs>
                      <polygon
                        onClick={onclikTooch}
                        data-nomor-gigi=" 16D"
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: '#007610', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("16-Distal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </svg>
                  }

                  {/* center */}
                  {form.tooch_number.includes("16O-fis") || form.tooch_number.includes("16O-amf") || form.tooch_number.includes("16O-cof") || form.tooch_number.includes("16O-car") ?
                    <>
                      {form.tooch_number.includes("16O-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="16O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 16O"
                            points="10,10 30,10 30,30 10,30"
                            style={{ fill: 'url(#16O-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("16-Oklusal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("16O-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 16O"
                          points="10,10 30,10 30,30 10,30"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("16-Oklusal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("16O-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="16O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 16O"
                            points="10,10 30,10 30,30 10,30"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("16-Oklusal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }

                      {form.tooch_number.includes("16O-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 16O"
                          points="10,10 30,10 30,30 10,30"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("16-Oklusal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }

                    </>
                    :
                    <svg width="40" height="40">
                      <defs>
                        <pattern id="16O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                        </pattern>
                      </defs>
                      <polygon
                        onClick={onclikTooch}
                        data-nomor-gigi=" 16O"
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: '#007610', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("16-Oklusal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </svg>
                  }

                  {/* cfr */}
                  {form.tooch_number.includes("16-cfr") &&
                    <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
                      #
                    </text>
                  }

                  {/* rrx */}
                  {form.tooch_number.includes("16-rrx") &&    
                  <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 16-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
                    v
                  </text>
                  }
                        
                  {/* mis */}
                  {form.tooch_number.includes("16-mis") &&    
                  <>  
                    <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
                    <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
                  </> 
                  }
                          
                </g>
                  :
                <g>
                  {/* Atas */}
                  {form.tooch_number.includes("16B-fis") || form.tooch_number.includes("16B-amf") || form.tooch_number.includes("16B-cof") || form.tooch_number.includes("16B-car") ?
                    <>
                      {form.tooch_number.includes("16B-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="16B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 16B"
                            points="0,0 40,0 30,10 10,10"
                            style={{ fill: 'url(#16B-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("16-Bukal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("16B-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 16B"
                          points="0,0 40,0 30,10 10,10"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("16-Bukal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("16B-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="16B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 16B"
                            points="0,0 40,0 30,10 10,10"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("16-Bukal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
            
                      {form.tooch_number.includes("16B-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 16B"
                          points="0,0 40,0 30,10 10,10"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("16-Bukal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
                    
                    </>
                    :
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 16B"
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("16-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  }

                  {/* Bawah */}
                  {form.tooch_number.includes("16P-fis") || form.tooch_number.includes("16P-amf") || form.tooch_number.includes("16P-cof") || form.tooch_number.includes("16P-car") ?
                    <>
                      {form.tooch_number.includes("16P-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="16P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>

                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 16P"
                            points="10,30 30,30 40,40 0,40"
                            style={{ fill: 'url(#16P-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("16-Palatal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("16P-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 16P"
                          points="10,30 30,30 40,40 0,40"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("16-Palatal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("16P-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="16P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 16P"
                            points="10,30 30,30 40,40 0,40"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("16-Palatal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }

                      {form.tooch_number.includes("16P-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 16P"
                          points="10,30 30,30 40,40 0,40"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("16-Palatal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
                    </>
                    :
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 16P"
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("16-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  }
          
                  {/* Kanan */}
                  {form.tooch_number.includes("16M-fis") || form.tooch_number.includes("16M-amf") || form.tooch_number.includes("16M-cof") || form.tooch_number.includes("16M-car") ?
                    <>
                      {form.tooch_number.includes("16M-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="16M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 16M"
                            points="30,10 40,0 40,40 30,30"
                            style={{ fill: 'url(#16M-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("16-Mesial")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("16M-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 16M"
                          points="30,10 40,0 40,40 30,30"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("16-Mesial")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("16M-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="16M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 16M"
                            points="30,10 40,0 40,40 30,30"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("16-Mesial")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
                    
                      {form.tooch_number.includes("16M-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 16M"
                          points="30,10 40,0 40,40 30,30"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("16-Mesial")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
                    
                    </>
                    :
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 16M"
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("16-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  }

                  {/* Kiri */}
                  {form.tooch_number.includes("16D-fis") || form.tooch_number.includes("16D-amf") || form.tooch_number.includes("16D-cof") || form.tooch_number.includes("16D-car") ?
                    <>
                      {form.tooch_number.includes("16D-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="16D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 16D"
                            points="0,0 10,10 10,30 0,40"
                            style={{ fill: 'url(#16D-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("16-Distal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("16D-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 16D"
                          points="0,0 10,10 10,30 0,40"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("16-Distal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("16D-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="16D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 16D"
                            points="0,0 10,10 10,30 0,40"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("16-Distal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }

                      {form.tooch_number.includes("16D-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 16D"
                          points="0,0 10,10 10,30 0,40"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("16-Distal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }

                    </>
                    :
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 16D"
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("16-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  }

                  {/* center */}
                  {form.tooch_number.includes("16O-fis") || form.tooch_number.includes("16O-amf") || form.tooch_number.includes("16O-cof") || form.tooch_number.includes("16O-car") ?
                    <>
                      {form.tooch_number.includes("16O-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="16O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 16O"
                            points="10,10 30,10 30,30 10,30"
                            style={{ fill: 'url(#16O-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("16-Oklusal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("16O-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 16O"
                          points="10,10 30,10 30,30 10,30"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("16-Oklusal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("16O-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="16O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 16O"
                            points="10,10 30,10 30,30 10,30"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("16-Oklusal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }

                      {form.tooch_number.includes("16O-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 16O"
                          points="10,10 30,10 30,30 10,30"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("16-Oklusal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }

                    </>
                    :
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 16O"
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("16-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  }

                  {/* cfr */}
                  {form.tooch_number.includes("16-cfr") &&
                    <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
                      #
                    </text>
                  }
                  
                  {form.tooch_number.includes("16-rrx") &&    
                  <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 16-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
                    v
                  </text>
                  }
                  
                  {form.tooch_number.includes("16-mis") &&    
                  <>  
                    <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
                    <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
                  </> 
                  }
                </g>
              }      
            </svg>
                  
            {form.tooch_number.includes("16-nvt") &&   
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
              <svg width={42} height={42}>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 16"
                  points="21,2 41,32 1,32" 
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 3 }}
                  onMouseEnter={() => setInfoGigi('16')}
                  onMouseLeave={() => setInfoGigi('XX-X')}
                />
              </svg> 
            </div>
            }  
            
            {form.tooch_number.includes("16-rct") &&   
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
            <svg width={42} height={42}>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 16"
                points="21,2 41,32 1,32" 
                style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
                onMouseEnter={() => setInfoGigi('16')}
                onMouseLeave={() => setInfoGigi('XX-X')}
              />
              </svg>
            </div>
            } 
                  
            {form.tooch_number.includes("16B-amf-rct") || form.tooch_number.includes("16P-amf-rct") || form.tooch_number.includes("16M-amf-rct") || form.tooch_number.includes("16D-amf-rct") || form.tooch_number.includes("16O-amf-rct") ||
              form.tooch_number.includes("16B-cof-rct") || form.tooch_number.includes("16P-cof-rct") || form.tooch_number.includes("16M-cof-rct") || form.tooch_number.includes("16D-cof-rct") || form.tooch_number.includes("16O-cof-rct") ||
              form.tooch_number.includes("16-fmc-rct") ||
              form.tooch_number.includes("16-poc-rct") ?
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
              <svg width={42} height={42}>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 16B"
                  points="21,2 41,32 1,32" 
                  style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
                  onMouseEnter={() => setInfoGigi('16-Bukal')}
                  onMouseLeave={() => setInfoGigi('XX-X')}
                />
                </svg>
              </div>
                :
              <></>
            } 
          
        </div>  

        <div style={{ width: "42px", margin: "1px" }}>
          {form.tooch_number.includes("15-une") || form.tooch_number.includes("15-non") || form.tooch_number.includes("15-pre") || form.tooch_number.includes("15-ano") || form.tooch_number.includes("15-ipx-poc") || form.tooch_number.includes("15-mis-prd-acr") || form.tooch_number.includes("15-mis-fld-acr") ?
            <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
              {form.tooch_number.includes("15-une") &&
                <div style={{ position: "absolute", color: "black" }}>UNE</div>
              }
              {form.tooch_number.includes("15-non") &&
                <div style={{ position: "absolute", color: "black" }}>NON</div>
              }      
              {form.tooch_number.includes("15-pre") &&
                <div style={{ position: "absolute", color: "black" }}>PRE</div>
              }      
              {form.tooch_number.includes("15-ano") &&
                <div style={{ position: "absolute", color: "black" }}>ANO</div>
              }
              {form.tooch_number.includes("15-ipx-poc") &&
                <div style={{ position: "absolute", color: "black" }}>IPX</div>
              }
              {form.tooch_number.includes("15-mis-prd-acr") &&
                <div style={{ position: "absolute", color: "black" }}>PRD</div>
              } 
              {form.tooch_number.includes("15-mis-fld-acr") &&
                <div style={{ position: "absolute", color: "black" }}>FLD</div>
              }      
            </div>
            :
            <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
            </div>
          }
          
          {form.tooch_number.includes("15-pob-14-miss-pon-pob-13-pob")
          ||form.tooch_number.includes("15-pob-14-miss-pon-pob-13-miss-pon-pob-12-pob")
          ||form.tooch_number.includes("15-pob-14-miss-pon-pob-13-miss-pon-pob-12-miss-pon-pob-11-pob") ?
          <>
          {/* meb 15 - 13 */}
          {form.tooch_number.includes("15-pob-14-miss-pon-pob-13-pob") &&
          <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
          <svg width="100%" height="20vh">

                {/* Garis ke samping dan ke bawah (L-shape) */}
                <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              </svg>
            </div>
          }
                                  
          {/* meb 15 - 12 */}
          {form.tooch_number.includes("15-pob-14-miss-pon-pob-13-miss-pon-pob-12-pob") &&
           <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
           <svg width="100%" height="20vh">

              {/* Garis ke samping dan ke bawah (L-shape) */}
              <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            </svg>
          </div>
          }
          
          {/* meb 15 - 11 */} 
          {form.tooch_number.includes("15-pob-14-miss-pon-pob-13-miss-pon-pob-12-miss-pon-pob-11-pob") &&      
         <div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
         <svg width="100%" height="20vh">

            {/* Garis ke samping dan ke bawah (L-shape) */}
            <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
            <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
          </svg>
          </div> 
          }
            
            </>
              :
            <></>
          }

          {form.tooch_number.includes("15-meb-14-miss-pon-meb-13-meb")
          ||form.tooch_number.includes("15-meb-14-miss-pon-meb-13-miss-pon-meb-12-meb")
          ||form.tooch_number.includes("15-meb-14-miss-pon-meb-13-miss-pon-meb-12-miss-pon-meb-11-meb") ?
          <>
          {/* meb 15 - 13 */}
          {form.tooch_number.includes("15-meb-14-miss-pon-meb-13-meb") &&
            <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
            <svg width="100%" height="20vh">

                {/* Garis ke samping dan ke bawah (L-shape) */}
                <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
                <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              </svg>
            </div>
          }
                                  
          {/* meb 15 - 12 */}
          {form.tooch_number.includes("15-meb-14-miss-pon-meb-13-miss-pon-meb-12-meb") &&
            <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
            <svg width="100%" height="20vh">

              {/* Garis ke samping dan ke bawah (L-shape) */}
              <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            </svg>
          </div>
          }
          
          {/* meb 15 - 11 */} 
          {form.tooch_number.includes("15-meb-14-miss-pon-meb-13-miss-pon-meb-12-miss-pon-meb-11-meb") &&      
        <div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
        <svg width="100%" height="20vh">

            {/* Garis ke samping dan ke bawah (L-shape) */}
            <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
            <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
          </svg>
          </div> 
          }
            
            </>
              :
            <></>
          }
                
            <div style={{ textAlign: "center" }}>15</div>
            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("15-fmc") || form.tooch_number.includes("15-poc") || form.tooch_number.includes("15-ipx-poc") || form.tooch_number.match(/\b15-pob\b/) || form.tooch_number.match(/\b15-miss-pon-pob\b/) || form.tooch_number.match(/\b15-meb\b/) || form.tooch_number.match(/\b15-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
              {form.tooch_number.includes("15-poc") || form.tooch_number.includes("15-ipx-poc") || form.tooch_number.match(/\b15-pob\b/) || form.tooch_number.match(/\b15-miss-pon-pob\b/) ?
                <g>
                  {/* Atas */}
                  {form.tooch_number.includes("15B-fis") || form.tooch_number.includes("15B-amf") || form.tooch_number.includes("15B-cof") || form.tooch_number.includes("15B-car") ?
                    <>
                      {form.tooch_number.includes("15B-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="15B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 15B"
                            points="0,0 40,0 30,10 10,10"
                            style={{ fill: 'url(#15B-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("15-Bukal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("15B-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 15B"
                          points="0,0 40,0 30,10 10,10"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("15-Bukal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("15B-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="15B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 15B"
                            points="0,0 40,0 30,10 10,10"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("15-Bukal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
            
                      {form.tooch_number.includes("15B-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 15B"
                          points="0,0 40,0 30,10 10,10"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("15-Bukal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
                    
                    </>
                    :
                  <svg width="40" height="40">
                    <defs>
                      <pattern id="15B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                      </pattern>
                    </defs>
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 15B"
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: '#007610', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("15-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  </svg>
                  }

                  {/* Bawah */}
                  {form.tooch_number.includes("15P-fis") || form.tooch_number.includes("15P-amf") || form.tooch_number.includes("15P-cof") || form.tooch_number.includes("15P-car") ?
                    <>
                      {form.tooch_number.includes("15P-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="15P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>

                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 15P"
                            points="10,30 30,30 40,40 0,40"
                            style={{ fill: 'url(#15P-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("15-Palatal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("15P-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 15P"
                          points="10,30 30,30 40,40 0,40"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("15-Palatal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("15P-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="15P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 15P"
                            points="10,30 30,30 40,40 0,40"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("15-Palatal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }

                      {form.tooch_number.includes("15P-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 15P"
                          points="10,30 30,30 40,40 0,40"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("15-Palatal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
                    </>
                    :
                    <svg width="40" height="40">
                      <defs>
                        <pattern id="15P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                        </pattern>
                      </defs>
                      <polygon
                        onClick={onclikTooch}
                        data-nomor-gigi=" 15P"
                        points="10,30 30,30 40,40 0,40"
                        style={{ fill: '#007610', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("15-Palatal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </svg>
                  }
          
                  {/* Kanan */}
                  {form.tooch_number.includes("15M-fis") || form.tooch_number.includes("15M-amf") || form.tooch_number.includes("15M-cof") || form.tooch_number.includes("15M-car") ?
                    <>
                      {form.tooch_number.includes("15M-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="15M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 15M"
                            points="30,10 40,0 40,40 30,30"
                            style={{ fill: 'url(#15M-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("15-Mesial")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("15M-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 15M"
                          points="30,10 40,0 40,40 30,30"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("15-Mesial")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("15M-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="15M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 15M"
                            points="30,10 40,0 40,40 30,30"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("15-Mesial")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
                    
                      {form.tooch_number.includes("15M-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 15M"
                          points="30,10 40,0 40,40 30,30"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("15-Mesial")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
                    
                    </>
                    :
                    <svg width="40" height="40">
                      <defs>
                        <pattern id="15M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                        </pattern>
                      </defs>
                      <polygon
                        onClick={onclikTooch}
                        data-nomor-gigi=" 15M"
                        points="30,10 40,0 40,40 30,30"
                        style={{ fill: '#007610', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("15-Mesial")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </svg>
                  }

                  {/* Kiri */}
                  {form.tooch_number.includes("15D-fis") || form.tooch_number.includes("15D-amf") || form.tooch_number.includes("15D-cof") || form.tooch_number.includes("15D-car") ?
                    <>
                      {form.tooch_number.includes("15D-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="15D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 15D"
                            points="0,0 10,10 10,30 0,40"
                            style={{ fill: 'url(#15D-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("15-Distal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("15D-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 15D"
                          points="0,0 10,10 10,30 0,40"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("15-Distal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("15D-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="15D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 15D"
                            points="0,0 10,10 10,30 0,40"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("15-Distal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }

                      {form.tooch_number.includes("15D-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 15D"
                          points="0,0 10,10 10,30 0,40"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("15-Distal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }

                    </>
                    :
                    <svg width="40" height="40">
                      <defs>
                        <pattern id="15D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                        </pattern>
                      </defs>
                      <polygon
                        onClick={onclikTooch}
                        data-nomor-gigi=" 15D"
                        points="0,0 10,10 10,30 0,40"
                        style={{ fill: '#007610', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("15-Distal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </svg>
                  }

                  {/* center */}
                  {form.tooch_number.includes("15O-fis") || form.tooch_number.includes("15O-amf") || form.tooch_number.includes("15O-cof") || form.tooch_number.includes("15O-car") ?
                    <>
                      {form.tooch_number.includes("15O-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="15O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 15O"
                            points="10,10 30,10 30,30 10,30"
                            style={{ fill: 'url(#15O-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("15-Oklusal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("15O-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 15O"
                          points="10,10 30,10 30,30 10,30"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("15-Oklusal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("15O-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="15O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 15O"
                            points="10,10 30,10 30,30 10,30"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("15-Oklusal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }

                      {form.tooch_number.includes("15O-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 15O"
                          points="10,10 30,10 30,30 10,30"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("15-Oklusal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }

                    </>
                    :
                    <svg width="40" height="40">
                      <defs>
                        <pattern id="15O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                        </pattern>
                      </defs>
                      <polygon
                        onClick={onclikTooch}
                        data-nomor-gigi=" 15O"
                        points="10,10 30,10 30,30 10,30"
                        style={{ fill: '#007610', stroke: 'black' }}
                        onMouseEnter={() => setInfoGigi("15-Oklusal")}
                        onMouseLeave={() => setInfoGigi("XX-X")}
                      />
                    </svg>
                  }

                  {/* cfr */}
                  {form.tooch_number.includes("15-cfr") &&
                    <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
                      #
                    </text>
                  }

                  {/* rrx */}
                  {form.tooch_number.includes("15-rrx") &&    
                  <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 15-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
                    v
                  </text>
                  }
                        
                  {/* mis */}
                  {form.tooch_number.includes("15-mis") &&    
                  <>  
                    <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
                    <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
                  </> 
                  }
                          
                </g>
                  :
                <g>
                  {/* Atas */}
                  {form.tooch_number.includes("15B-fis") || form.tooch_number.includes("15B-amf") || form.tooch_number.includes("15B-cof") || form.tooch_number.includes("15B-car") ?
                    <>
                      {form.tooch_number.includes("15B-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="15B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 15B"
                            points="0,0 40,0 30,10 10,10"
                            style={{ fill: 'url(#15B-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("15-Bukal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("15B-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 15B"
                          points="0,0 40,0 30,10 10,10"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("15-Bukal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("15B-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="15B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 15B"
                            points="0,0 40,0 30,10 10,10"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("15-Bukal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
            
                      {form.tooch_number.includes("15B-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 15B"
                          points="0,0 40,0 30,10 10,10"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("15-Bukal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
                    
                    </>
                    :
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 15B"
                      points="0,0 40,0 30,10 10,10"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("15-Bukal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  }

                  {/* Bawah */}
                  {form.tooch_number.includes("15P-fis") || form.tooch_number.includes("15P-amf") || form.tooch_number.includes("15P-cof") || form.tooch_number.includes("15P-car") ?
                    <>
                      {form.tooch_number.includes("15P-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="15P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>

                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 15P"
                            points="10,30 30,30 40,40 0,40"
                            style={{ fill: 'url(#15P-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("15-Palatal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("15P-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 15P"
                          points="10,30 30,30 40,40 0,40"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("15-Palatal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("15P-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="15P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 15P"
                            points="10,30 30,30 40,40 0,40"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("15-Palatal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }

                      {form.tooch_number.includes("15P-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 15P"
                          points="10,30 30,30 40,40 0,40"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("15-Palatal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
                    </>
                    :
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 15P"
                      points="10,30 30,30 40,40 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("15-Palatal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  }
          
                  {/* Kanan */}
                  {form.tooch_number.includes("15M-fis") || form.tooch_number.includes("15M-amf") || form.tooch_number.includes("15M-cof") || form.tooch_number.includes("15M-car") ?
                    <>
                      {form.tooch_number.includes("15M-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="15M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 15M"
                            points="30,10 40,0 40,40 30,30"
                            style={{ fill: 'url(#15M-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("15-Mesial")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("15M-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 15M"
                          points="30,10 40,0 40,40 30,30"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("15-Mesial")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("15M-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="15M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 15M"
                            points="30,10 40,0 40,40 30,30"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("15-Mesial")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
                    
                      {form.tooch_number.includes("15M-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 15M"
                          points="30,10 40,0 40,40 30,30"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("15-Mesial")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
                    
                    </>
                    :
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 15M"
                      points="30,10 40,0 40,40 30,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("15-Mesial")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  }

                  {/* Kiri */}
                  {form.tooch_number.includes("15D-fis") || form.tooch_number.includes("15D-amf") || form.tooch_number.includes("15D-cof") || form.tooch_number.includes("15D-car") ?
                    <>
                      {form.tooch_number.includes("15D-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="15D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 15D"
                            points="0,0 10,10 10,30 0,40"
                            style={{ fill: 'url(#15D-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("15-Distal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("15D-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 15D"
                          points="0,0 10,10 10,30 0,40"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("15-Distal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("15D-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="15D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 15D"
                            points="0,0 10,10 10,30 0,40"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("15-Distal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }

                      {form.tooch_number.includes("15D-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 15D"
                          points="0,0 10,10 10,30 0,40"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("15-Distal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }

                    </>
                    :
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 15D"
                      points="0,0 10,10 10,30 0,40"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("15-Distal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  }

                  {/* center */}
                  {form.tooch_number.includes("15O-fis") || form.tooch_number.includes("15O-amf") || form.tooch_number.includes("15O-cof") || form.tooch_number.includes("15O-car") ?
                    <>
                      {form.tooch_number.includes("15O-fis") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="15O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                              <rect width="8" height="8" fill="#ffb8c6" />
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 15O"
                            points="10,10 30,10 30,30 10,30"
                            style={{ fill: 'url(#15O-fis)', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("15-Oklusal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }
              
                      {form.tooch_number.includes("15O-amf") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 15O"
                          points="10,10 30,10 30,30 10,30"
                          style={{ fill: 'black', stroke: 'black' }}
                          onMouseEnter={() => setInfoGigi("15-Oklusal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }
            
                      {form.tooch_number.includes("15O-cof") &&
                        <svg width="40" height="40">
                          <defs>
                            <pattern id="15O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                            </pattern>
                          </defs>
                          <polygon
                            onClick={onclikTooch}
                            data-nomor-gigi=" 15O"
                            points="10,10 30,10 30,30 10,30"
                            style={{ fill: '#007610', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("15-Oklusal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                          />
                        </svg>
                      }

                      {form.tooch_number.includes("15O-car") &&
                        <polygon
                          onClick={onclikTooch}
                          data-nomor-gigi=" 15O"
                          points="10,10 30,10 30,30 10,30"
                          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                          onMouseEnter={() => setInfoGigi("15-Oklusal")}
                          onMouseLeave={() => setInfoGigi("XX-X")}
                        />
                      }

                    </>
                    :
                    <polygon
                      onClick={onclikTooch}
                      data-nomor-gigi=" 15O"
                      points="10,10 30,10 30,30 10,30"
                      style={{ fill: 'white', stroke: 'black' }}
                      onMouseEnter={() => setInfoGigi("15-Oklusal")}
                      onMouseLeave={() => setInfoGigi("XX-X")}
                    />
                  }

                  {/* cfr */}
                  {form.tooch_number.includes("15-cfr") &&
                    <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
                      #
                    </text>
                  }
                  
                  {form.tooch_number.includes("15-rrx") &&    
                  <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 15-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
                    v
                  </text>
                  }
                  
                  {form.tooch_number.includes("15-mis") &&    
                  <>  
                    <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
                    <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
                  </> 
                  }
                </g>
              }      
            </svg>
                  
            {form.tooch_number.includes("15-nvt") &&   
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
              <svg width={42} height={42}>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 15"
                  points="21,2 41,32 1,32" 
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 3 }}
                  onMouseEnter={() => setInfoGigi('15')}
                  onMouseLeave={() => setInfoGigi('XX-X')}
                />
              </svg> 
            </div>
            }  
            
            {form.tooch_number.includes("15-rct") &&   
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
            <svg width={42} height={42}>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 15"
                points="21,2 41,32 1,32" 
                style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
                onMouseEnter={() => setInfoGigi('15')}
                onMouseLeave={() => setInfoGigi('XX-X')}
              />
              </svg>
            </div>
            } 
                  
            {form.tooch_number.includes("15B-amf-rct") || form.tooch_number.includes("15P-amf-rct") || form.tooch_number.includes("15M-amf-rct") || form.tooch_number.includes("15D-amf-rct") || form.tooch_number.includes("15O-amf-rct") ||
              form.tooch_number.includes("15B-cof-rct") || form.tooch_number.includes("15P-cof-rct") || form.tooch_number.includes("15M-cof-rct") || form.tooch_number.includes("15D-cof-rct") || form.tooch_number.includes("15O-cof-rct") ||
              form.tooch_number.includes("15-fmc-rct") ||
              form.tooch_number.includes("15-poc-rct") ?
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
              <svg width={42} height={42}>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 15B"
                  points="21,2 41,32 1,32" 
                  style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
                  onMouseEnter={() => setInfoGigi('15-Bukal')}
                  onMouseLeave={() => setInfoGigi('XX-X')}
                />
                </svg>
              </div>
                :
              <></>
            } 
          
        </div>  

        <div style={{ width: "42px", margin: "1px" }}>
        {form.tooch_number.includes("14-une") || form.tooch_number.includes("14-non") || form.tooch_number.includes("14-pre") || form.tooch_number.includes("14-ano") || form.tooch_number.includes("14-ipx-poc") || form.tooch_number.includes("14-mis-prd-acr") || form.tooch_number.includes("14-mis-fld-acr") ?
        <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
        {form.tooch_number.includes("14-une") &&
        <div style={{ position: "absolute", color: "black" }}>UNE</div>
        }
        {form.tooch_number.includes("14-non") &&
        <div style={{ position: "absolute", color: "black" }}>NON</div>
        }      
        {form.tooch_number.includes("14-pre") &&
        <div style={{ position: "absolute", color: "black" }}>PRE</div>
        }      
        {form.tooch_number.includes("14-ano") &&
        <div style={{ position: "absolute", color: "black" }}>ANO</div>
        }
        {form.tooch_number.includes("14-ipx-poc") &&
        <div style={{ position: "absolute", color: "black" }}>IPX</div>
        }
        {form.tooch_number.includes("14-mis-prd-acr") &&
        <div style={{ position: "absolute", color: "black" }}>PRD</div>
        } 
        {form.tooch_number.includes("14-mis-fld-acr") &&
        <div style={{ position: "absolute", color: "black" }}>FLD</div>
        }      
        </div>
        :
        <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
        </div>
        }
            
        {form.tooch_number.includes("14-meb-13-miss-pon-meb-12-meb")
        ||form.tooch_number.includes("14-meb-13-miss-pon-meb-12-miss-pon-meb-11-meb") ?
        <>
        {/* meb 14 - 12 */}
        {form.tooch_number.includes("14-meb-13-miss-pon-meb-12-meb") &&
      <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
      <svg width="100%" height="20vh">

          {/* Garis ke samping dan ke bawah (L-shape) */}
          <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
          <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
          <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        </svg>
        </div>
        }
                            
        {/* meb 14 - 11 */}
        {form.tooch_number.includes("14-meb-13-miss-pon-meb-12-miss-pon-meb-11-meb") &&
       <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
       <svg width="100%" height="20vh">

        {/* Garis ke samping dan ke bawah (L-shape) */}
        <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        </svg>
        </div>
        } 
        </>
        :
        <></>
        }

        {form.tooch_number.includes("14-pob-13-miss-pon-pob-12-pob")
        ||form.tooch_number.includes("14-pob-13-miss-pon-pob-12-miss-pon-pob-11-pob") ?
        <>
        {/* meb 14 - 12 */}
        {form.tooch_number.includes("14-pob-13-miss-pon-pob-12-pob") &&
       <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
       <svg width="100%" height="20vh">

          {/* Garis ke samping dan ke bawah (L-shape) */}
          <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
          <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
          <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        </svg>
        </div>
        }
                            
        {/* meb 14 - 11 */}
        {form.tooch_number.includes("14-pob-13-miss-pon-pob-12-miss-pon-pob-11-pob") &&
      <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
      <svg width="100%" height="20vh">

        {/* Garis ke samping dan ke bawah (L-shape) */}
        <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        </svg>
        </div>
        }


        </>
        :
        <></>
        }

        
        <div style={{ textAlign: "center" }}>14</div>
        <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("14-fmc") || form.tooch_number.includes("14-poc") || form.tooch_number.includes("14-ipx-poc") || form.tooch_number.match(/\b14-pob\b/) || form.tooch_number.match(/\b14-miss-pon-pob\b/) || form.tooch_number.match(/\b14-meb\b/) || form.tooch_number.match(/\b14-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
        {form.tooch_number.includes("14-poc") || form.tooch_number.includes("14-ipx-poc") || form.tooch_number.match(/\b14-pob\b/) || form.tooch_number.match(/\b14-miss-pon-pob\b/) ?
        <g>
          {/* Atas */}
          {form.tooch_number.includes("14B-fis") || form.tooch_number.includes("14B-amf") || form.tooch_number.includes("14B-cof") || form.tooch_number.includes("14B-car") ?
            <>
              {form.tooch_number.includes("14B-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="14B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 14B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: 'url(#14B-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("14-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("14B-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 14B"
                  points="0,0 40,0 30,10 10,10"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("14-Bukal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("14B-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="14B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 14B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("14-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("14B-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 14B"
                  points="0,0 40,0 30,10 10,10"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("14-Bukal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }
            
            </>
            :
          <svg width="40" height="40">
            <defs>
              <pattern id="14B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 14B"
              points="0,0 40,0 30,10 10,10"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("14-Bukal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
          }

          {/* Bawah */}
          {form.tooch_number.includes("14P-fis") || form.tooch_number.includes("14P-amf") || form.tooch_number.includes("14P-cof") || form.tooch_number.includes("14P-car") ?
            <>
              {form.tooch_number.includes("14P-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="14P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>

                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 14P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: 'url(#14P-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("14-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("14P-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 14P"
                  points="10,30 30,30 40,40 0,40"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("14-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("14P-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="14P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 14P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("14-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("14P-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 14P"
                  points="10,30 30,30 40,40 0,40"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("14-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }
            </>
            :
            <svg width="40" height="40">
              <defs>
                <pattern id="14P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                  <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                </pattern>
              </defs>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 14P"
                points="10,30 30,30 40,40 0,40"
                style={{ fill: '#007610', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("14-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            </svg>
          }

          {/* Kanan */}
          {form.tooch_number.includes("14M-fis") || form.tooch_number.includes("14M-amf") || form.tooch_number.includes("14M-cof") || form.tooch_number.includes("14M-car") ?
            <>
              {form.tooch_number.includes("14M-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="14M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 14M"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: 'url(#14M-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("14-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("14M-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 14M"
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("14-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("14M-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="14M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 14M"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("14-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }
            
              {form.tooch_number.includes("14M-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 14M"
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("14-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }
            
            </>
            :
            <svg width="40" height="40">
              <defs>
                <pattern id="14M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                  <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                </pattern>
              </defs>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 14M"
                points="30,10 40,0 40,40 30,30"
                style={{ fill: '#007610', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("14-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            </svg>
          }

          {/* Kiri */}
          {form.tooch_number.includes("14D-fis") || form.tooch_number.includes("14D-amf") || form.tooch_number.includes("14D-cof") || form.tooch_number.includes("14D-car") ?
            <>
              {form.tooch_number.includes("14D-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="14D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 14D"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: 'url(#14D-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("14-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("14D-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 14D"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("14-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("14D-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="14D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 14D"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("14-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("14D-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 14D"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("14-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

            </>
            :
            <svg width="40" height="40">
              <defs>
                <pattern id="14D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                  <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                </pattern>
              </defs>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 14D"
                points="0,0 10,10 10,30 0,40"
                style={{ fill: '#007610', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("14-Distal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            </svg>
          }

          {/* center */}
          {form.tooch_number.includes("14O-fis") || form.tooch_number.includes("14O-amf") || form.tooch_number.includes("14O-cof") || form.tooch_number.includes("14O-car") ?
            <>
              {form.tooch_number.includes("14O-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="14O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 14O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: 'url(#14O-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("14-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("14O-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 14O"
                  points="10,10 30,10 30,30 10,30"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("14-Oklusal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("14O-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="14O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 14O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("14-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("14O-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 14O"
                  points="10,10 30,10 30,30 10,30"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("14-Oklusal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

            </>
            :
            <svg width="40" height="40">
              <defs>
                <pattern id="14O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                  <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                </pattern>
              </defs>
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 14O"
                points="10,10 30,10 30,30 10,30"
                style={{ fill: '#007610', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("14-Oklusal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            </svg>
          }

          {/* cfr */}
          {form.tooch_number.includes("14-cfr") &&
            <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
              #
            </text>
          }

          {/* rrx */}
          {form.tooch_number.includes("14-rrx") &&    
          <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 14-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
            v
          </text>
          }
                
          {/* mis */}
          {form.tooch_number.includes("14-mis") &&    
          <>  
            <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
            <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
          </> 
          }
                  
        </g>
          :
        <g>
          {/* Atas */}
          {form.tooch_number.includes("14B-fis") || form.tooch_number.includes("14B-amf") || form.tooch_number.includes("14B-cof") || form.tooch_number.includes("14B-car") ?
            <>
              {form.tooch_number.includes("14B-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="14B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 14B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: 'url(#14B-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("14-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("14B-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 14B"
                  points="0,0 40,0 30,10 10,10"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("14-Bukal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("14B-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="14B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 14B"
                    points="0,0 40,0 30,10 10,10"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("14-Bukal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("14B-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 14B"
                  points="0,0 40,0 30,10 10,10"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("14-Bukal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }
            
            </>
            :
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 14B"
              points="0,0 40,0 30,10 10,10"
              style={{ fill: 'white', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("14-Bukal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          }

          {/* Bawah */}
          {form.tooch_number.includes("14P-fis") || form.tooch_number.includes("14P-amf") || form.tooch_number.includes("14P-cof") || form.tooch_number.includes("14P-car") ?
            <>
              {form.tooch_number.includes("14P-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="14P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>

                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 14P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: 'url(#14P-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("14-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("14P-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 14P"
                  points="10,30 30,30 40,40 0,40"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("14-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("14P-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="14P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 14P"
                    points="10,30 30,30 40,40 0,40"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("14-Palatal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("14P-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 14P"
                  points="10,30 30,30 40,40 0,40"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("14-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }
            </>
            :
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 14P"
              points="10,30 30,30 40,40 0,40"
              style={{ fill: 'white', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("14-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          }

          {/* Kanan */}
          {form.tooch_number.includes("14M-fis") || form.tooch_number.includes("14M-amf") || form.tooch_number.includes("14M-cof") || form.tooch_number.includes("14M-car") ?
            <>
              {form.tooch_number.includes("14M-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="14M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 14M"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: 'url(#14M-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("14-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("14M-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 14M"
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("14-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("14M-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="14M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 14M"
                    points="30,10 40,0 40,40 30,30"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("14-Mesial")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }
            
              {form.tooch_number.includes("14M-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 14M"
                  points="30,10 40,0 40,40 30,30"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("14-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }
            
            </>
            :
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 14M"
              points="30,10 40,0 40,40 30,30"
              style={{ fill: 'white', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("14-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          }

          {/* Kiri */}
          {form.tooch_number.includes("14D-fis") || form.tooch_number.includes("14D-amf") || form.tooch_number.includes("14D-cof") || form.tooch_number.includes("14D-car") ?
            <>
              {form.tooch_number.includes("14D-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="14D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 14D"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: 'url(#14D-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("14-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("14D-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 14D"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("14-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("14D-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="14D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 14D"
                    points="0,0 10,10 10,30 0,40"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("14-Distal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("14D-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 14D"
                  points="0,0 10,10 10,30 0,40"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("14-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

            </>
            :
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 14D"
              points="0,0 10,10 10,30 0,40"
              style={{ fill: 'white', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("14-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          }

          {/* center */}
          {form.tooch_number.includes("14O-fis") || form.tooch_number.includes("14O-amf") || form.tooch_number.includes("14O-cof") || form.tooch_number.includes("14O-car") ?
            <>
              {form.tooch_number.includes("14O-fis") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="14O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                      <rect width="8" height="8" fill="#ffb8c6" />
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 14O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: 'url(#14O-fis)', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("14-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("14O-amf") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 14O"
                  points="10,10 30,10 30,30 10,30"
                  style={{ fill: 'black', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("14-Oklusal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

              {form.tooch_number.includes("14O-cof") &&
                <svg width="40" height="40">
                  <defs>
                    <pattern id="14O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                      <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                    </pattern>
                  </defs>
                  <polygon
                    onClick={onclikTooch}
                    data-nomor-gigi=" 14O"
                    points="10,10 30,10 30,30 10,30"
                    style={{ fill: '#007610', stroke: 'black' }}
                    onMouseEnter={() => setInfoGigi("14-Oklusal")}
                    onMouseLeave={() => setInfoGigi("XX-X")}
                  />
                </svg>
              }

              {form.tooch_number.includes("14O-car") &&
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 14O"
                  points="10,10 30,10 30,30 10,30"
                  style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                  onMouseEnter={() => setInfoGigi("14-Oklusal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              }

            </>
            :
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 14O"
              points="10,10 30,10 30,30 10,30"
              style={{ fill: 'white', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("14-Oklusal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          }

          {/* cfr */}
          {form.tooch_number.includes("14-cfr") &&
            <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
              #
            </text>
          }
          
          {form.tooch_number.includes("14-rrx") &&    
          <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 14-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
            v
          </text>
          }
          
          {form.tooch_number.includes("14-mis") &&    
          <>  
            <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
            <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
          </> 
          }
        </g>
        }      
        </svg>
          
        {form.tooch_number.includes("14-nvt") &&   
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
        <svg width={42} height={42}>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 14"
          points="21,2 41,32 1,32" 
          style={{ fill: 'white', stroke: 'black', strokeWidth: 3 }}
          onMouseEnter={() => setInfoGigi('14')}
          onMouseLeave={() => setInfoGigi('XX-X')}
        />
        </svg> 
        </div>
        }  

        {form.tooch_number.includes("14-rct") &&   
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
        <svg width={42} height={42}>
        <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 14"
        points="21,2 41,32 1,32" 
        style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
        onMouseEnter={() => setInfoGigi('14')}
        onMouseLeave={() => setInfoGigi('XX-X')}
        />
        </svg>
        </div>
        } 
          
        {form.tooch_number.includes("14B-amf-rct") || form.tooch_number.includes("14P-amf-rct") || form.tooch_number.includes("14M-amf-rct") || form.tooch_number.includes("14D-amf-rct") || form.tooch_number.includes("14O-amf-rct") ||
        form.tooch_number.includes("14B-cof-rct") || form.tooch_number.includes("14P-cof-rct") || form.tooch_number.includes("14M-cof-rct") || form.tooch_number.includes("14D-cof-rct") || form.tooch_number.includes("14O-cof-rct") ||
        form.tooch_number.includes("14-fmc-rct") ||
        form.tooch_number.includes("14-poc-rct") ?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
        <svg width={42} height={42}>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 14B"
          points="21,2 41,32 1,32" 
          style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
          onMouseEnter={() => setInfoGigi('14-Bukal')}
          onMouseLeave={() => setInfoGigi('XX-X')}
        />
        </svg>
        </div>
        :
        <></>
        } 

        </div>   

        <div style={{ width: "42px", margin: "1px" }}>
        {form.tooch_number.includes("13-une") || form.tooch_number.includes("13-non") || form.tooch_number.includes("13-pre") || form.tooch_number.includes("13-ano") || form.tooch_number.includes("13-ipx-poc") || form.tooch_number.includes("13-mis-prd-acr") || form.tooch_number.includes("13-mis-fld-acr") ?
        <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
        {form.tooch_number.includes("13-une") &&
          <div style={{ position: "absolute", color: "black" }}>UNE</div>
        }
        {form.tooch_number.includes("13-non") &&
          <div style={{ position: "absolute", color: "black" }}>NON</div>
        }      
        {form.tooch_number.includes("13-pre") &&
          <div style={{ position: "absolute", color: "black" }}>PRE</div>
        }      
        {form.tooch_number.includes("13-ano") &&
          <div style={{ position: "absolute", color: "black" }}>ANO</div>
        }
        {form.tooch_number.includes("13-ipx-poc") &&
          <div style={{ position: "absolute", color: "black" }}>IPX</div>
        }
        {form.tooch_number.includes("13-mis-prd-acr") &&
          <div style={{ position: "absolute", color: "black" }}>PRD</div>
        } 
        {form.tooch_number.includes("13-mis-fld-acr") &&
          <div style={{ position: "absolute", color: "black" }}>FLD</div>
        }      
        </div>
        :
        <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
        </div>
        }

        {form.tooch_number.includes("13-pob-12-miss-pon-pob-11-pob") ?
        <>
        {/* meb 13 - 11 */}
        {form.tooch_number.includes("13-pob-12-miss-pon-pob-11-pob") &&
        <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
        <svg width="100%" height="20vh">

            {/* Garis ke samping dan ke bawah (L-shape) */}
            <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
            <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
          </svg>
        </div>
        }
                                    
        </>
          :
        <></>
        }

        {form.tooch_number.includes("13-meb-12-miss-pon-meb-11-meb") ?
        <>
        {/* meb 13 - 11 */}
        {form.tooch_number.includes("13-meb-12-miss-pon-meb-11-meb") &&
          <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
          <svg width="100%" height="20vh">

              {/* Garis ke samping dan ke bawah (L-shape) */}
              <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
              <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
            </svg>
          </div>
        }                           
        </>
          :
        <></>
        }

        <div style={{ textAlign: "center" }}>13</div>
        <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("13-fmc") || form.tooch_number.includes("13-poc") || form.tooch_number.includes("13-ipx-poc") || form.tooch_number.match(/\b13-pob\b/) || form.tooch_number.match(/\b13-miss-pon-pob\b/) || form.tooch_number.match(/\b13-meb\b/) || form.tooch_number.match(/\b13-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
        {form.tooch_number.includes("13-poc") || form.tooch_number.includes("13-ipx-poc") || form.tooch_number.match(/\b13-pob\b/) || form.tooch_number.match(/\b13-miss-pon-pob\b/) ?
        <g>
        {/* Atas */}
        {form.tooch_number.includes("13L-fis") || form.tooch_number.includes("13L-amf") || form.tooch_number.includes("13L-cof") || form.tooch_number.includes("13L-car") ?
          <>
            {form.tooch_number.includes("13L-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="13L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 13L"
                  points="0,0 40,0 30,20 10,20"
                  style={{ fill: 'url(#13L-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("13-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />   
              </svg>
            }

            {form.tooch_number.includes("13L-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 13L"
                points="0,0 40,0 30,20 10,20"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("13-Labial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("13L-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="13L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 13L"
                  points="0,0 40,0 30,20 10,20"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("13-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("13L-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 13L"
                points="0,0 40,0 30,20 10,20"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("13-Labial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          
          </>
          :
          <svg width="40" height="40">
            <defs>
              <pattern id="13L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 13L"
              points="0,0 40,0 30,20 10,20"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("13-Labial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {/* Bawah */}
        {form.tooch_number.includes("13P-fis") || form.tooch_number.includes("13P-amf") || form.tooch_number.includes("13P-cof") || form.tooch_number.includes("13P-car") ?
          <>
            {form.tooch_number.includes("13P-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="13P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>

                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 13P"
                  points="30,20 40,40 0,40 10,20"
                  style={{ fill: 'url(#13P-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("13-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("13P-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 13P"
                points="30,20 40,40 0,40 10,20"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("13-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("13P-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="13P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 13P"
                  points="30,20 40,40 0,40 10,20"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("13-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("13P-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 13P"
                points="30,20 40,40 0,40 10,20"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("13-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          </>
          :
          <svg width="40" height="40">
            <defs>
              <pattern id="13P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 13P"
              points="30,20 40,40 0,40 10,20"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("13-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {/* Kanan */}
        {form.tooch_number.includes("13M-fis") || form.tooch_number.includes("13M-amf") || form.tooch_number.includes("13M-cof") || form.tooch_number.includes("13M-car") ?
          <>
            {form.tooch_number.includes("13M-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="13M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 13M"
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: 'url(#13M-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("13-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("13M-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 13M"
                points="30,20 40,0 40,40 35,30"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("13-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("13M-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="13M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 13M"
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("13-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }
          
            {form.tooch_number.includes("13M-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 13M"
                points="30,20 40,0 40,40 35,30"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("13-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          
          </>
          :
          <svg width="40" height="40">
            <defs>
              <pattern id="13M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 13M"
              points="30,20 40,0 40,40 35,30"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("13-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {/* Kiri */}
        {form.tooch_number.includes("13D-fis") || form.tooch_number.includes("13D-amf") || form.tooch_number.includes("13D-cof") || form.tooch_number.includes("13D-car") ?
          <>
            {form.tooch_number.includes("13D-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="13D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 13D"
                  points="0,0 5,10 10,20 0,40"
                  style={{ fill: 'url(#13D-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("13-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("13D-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 13D"
                points="0,0 5,10 10,20 0,40"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("13-Distal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("13D-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="13D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 13D"
                  points="0,0 5,10 10,20 0,40"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("13-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("13D-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 13D"
                points="0,0 5,10 10,20 0,40"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("13-Distal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

          </>
          :
          <svg width="40" height="40">
            <defs>
              <pattern id="13D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 13D"
              points="0,0 5,10 10,20 0,40"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("13-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {/* cfr */}
        {form.tooch_number.includes("13-cfr") &&
          <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
            #
          </text>
        }

        {/* rrx */}
        {form.tooch_number.includes("13-rrx") &&    
        <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 13-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
          v
        </text>
        }
              
        {/* mis */}
        {form.tooch_number.includes("13-mis") &&    
        <>  
          <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
          <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
        </> 
        }
                
        </g>
        :
        <g>
        {/* Atas */}
        {form.tooch_number.includes("13L-fis") || form.tooch_number.includes("13L-amf") || form.tooch_number.includes("13L-cof") || form.tooch_number.includes("13L-car") ?
          <>
            {form.tooch_number.includes("13L-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="13L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 13L"
                  points="0,0 40,0 30,20 10,20"
                  style={{ fill: 'url(#13L-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("13-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("13L-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 13L"
                points="0,0 40,0 30,20 10,20"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("13-Labial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("13L-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="13L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 13L"
                  points="0,0 40,0 30,20 10,20"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("13-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("13L-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 13L"
                points="0,0 40,0 30,20 10,20"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("13-Labial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          
          </>
          :
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 13L"
            points="0,0 40,0 30,20 10,20"
            style={{ fill: 'white', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("13-Labial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {/* Bawah */}
        {form.tooch_number.includes("13P-fis") || form.tooch_number.includes("13P-amf") || form.tooch_number.includes("13P-cof") || form.tooch_number.includes("13P-car") ?
          <>
            {form.tooch_number.includes("13P-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="13P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>

                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 13P"
                  points="30,20 40,40 0,40 10,20"
                  style={{ fill: 'url(#13P-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("13-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("13P-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 13P"
                points="30,20 40,40 0,40 10,20"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("13-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("13P-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="13P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 13P"
                  points="30,20 40,40 0,40 10,20"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("13-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("13P-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 13P"
                points="30,20 40,40 0,40 10,20"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("13-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          </>
          :
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 13P"
            points="30,20 40,40 0,40 10,20"
            style={{ fill: 'white', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("13-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {/* Kanan */}
        {form.tooch_number.includes("13M-fis") || form.tooch_number.includes("13M-amf") || form.tooch_number.includes("13M-cof") || form.tooch_number.includes("13M-car") ?
          <>
            {form.tooch_number.includes("13M-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="13M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 13M"
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: 'url(#13M-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("13-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("13M-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 13M"
                points="30,20 40,0 40,40 35,30"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("13-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("13M-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="13M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 13M"
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("13-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }
          
            {form.tooch_number.includes("13M-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 13M"
                points="30,20 40,0 40,40 35,30"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("13-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          
          </>
          :
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 13M"
            points="30,20 40,0 40,40 35,30"
            style={{ fill: 'white', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("13-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {/* Kiri */}
        {form.tooch_number.includes("13D-fis") || form.tooch_number.includes("13D-amf") || form.tooch_number.includes("13D-cof") || form.tooch_number.includes("13D-car") ?
          <>
            {form.tooch_number.includes("13D-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="13D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 13D"
                  points="0,0 5,10 10,20 0,40"
                  style={{ fill: 'url(#13D-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("13-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("13D-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 13D"
                points="0,0 5,10 10,20 0,40"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("13-Distal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("13D-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="13D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 13D"
                  points="0,0 5,10 10,20 0,40"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("13-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("13D-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 13D"
                points="0,0 5,10 10,20 0,40"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("13-Distal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

          </>
          :
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 13D"
            points="0,0 5,10 10,20 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("13-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {/* cfr */}
        {form.tooch_number.includes("13-cfr") &&
          <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
            #
          </text>
        }

        {form.tooch_number.includes("13-rrx") &&    
        <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 13-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
          v
        </text>
        }

        {form.tooch_number.includes("13-mis") &&    
        <>  
          <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
          <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
        </> 
        }
        </g>
        }    
          
        </svg>

        {form.tooch_number.includes("13-nvt") &&   
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
        <svg width={42} height={42}>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 13"
          points="21,2 41,32 1,32" 
          style={{ fill: 'white', stroke: 'black', strokeWidth: 3 }}
          onMouseEnter={() => setInfoGigi('13')}
          onMouseLeave={() => setInfoGigi('XX-X')}
        />
        </svg> 
        </div>
        }  

        {form.tooch_number.includes("13-rct") &&   
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
        <svg width={42} height={42}>
        <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 13"
        points="21,2 41,32 1,32" 
        style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
        onMouseEnter={() => setInfoGigi('13')}
        onMouseLeave={() => setInfoGigi('XX-X')}
        />
        </svg>
        </div>
        } 

        {form.tooch_number.includes("13B-amf-rct") || form.tooch_number.includes("13P-amf-rct") || form.tooch_number.includes("13M-amf-rct") || form.tooch_number.includes("13D-amf-rct") || form.tooch_number.includes("13L-amf-rct") ||
        form.tooch_number.includes("13B-cof-rct") || form.tooch_number.includes("13P-cof-rct") || form.tooch_number.includes("13M-cof-rct") || form.tooch_number.includes("13D-cof-rct") || form.tooch_number.includes("13L-cof-rct") ||
        form.tooch_number.includes("13-fmc-rct") ||
        form.tooch_number.includes("13-poc-rct") ?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
        <svg width={42} height={42}>
        <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 13B"
        points="21,2 41,32 1,32" 
        style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
        onMouseEnter={() => setInfoGigi('13-Bukal')}
        onMouseLeave={() => setInfoGigi('XX-X')}
        />
        </svg>
        </div>
        :
        <></>
        } 

        </div>    

        <div style={{ width: "42px", margin: "1px" }}>
        {form.tooch_number.includes("12-une") || form.tooch_number.includes("12-non") || form.tooch_number.includes("12-pre") || form.tooch_number.includes("12-ano") || form.tooch_number.includes("12-ipx-poc") || form.tooch_number.includes("12-mis-prd-acr") || form.tooch_number.includes("12-mis-fld-acr") ?
        <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
        {form.tooch_number.includes("12-une") &&
          <div style={{ position: "absolute", color: "black" }}>UNE</div>
        }
        {form.tooch_number.includes("12-non") &&
          <div style={{ position: "absolute", color: "black" }}>NON</div>
        }      
        {form.tooch_number.includes("12-pre") &&
          <div style={{ position: "absolute", color: "black" }}>PRE</div>
        }      
        {form.tooch_number.includes("12-ano") &&
          <div style={{ position: "absolute", color: "black" }}>ANO</div>
        }
        {form.tooch_number.includes("12-ipx-poc") &&
          <div style={{ position: "absolute", color: "black" }}>IPX</div>
        }
        {form.tooch_number.includes("12-mis-prd-acr") &&
          <div style={{ position: "absolute", color: "black" }}>PRD</div>
        } 
        {form.tooch_number.includes("12-mis-fld-acr") &&
          <div style={{ position: "absolute", color: "black" }}>FLD</div>
        }      
        </div>
        :
        <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
        </div>
        }


        <div style={{ textAlign: "center" }}>12</div>
        <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("12-fmc") || form.tooch_number.includes("12-poc") || form.tooch_number.includes("12-ipx-poc") || form.tooch_number.match(/\b12-pob\b/) || form.tooch_number.match(/\b12-miss-pon-pob\b/) || form.tooch_number.match(/\b12-meb\b/) || form.tooch_number.match(/\b12-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
        {form.tooch_number.includes("12-poc") || form.tooch_number.includes("12-ipx-poc") || form.tooch_number.match(/\b12-pob\b/) || form.tooch_number.match(/\b12-miss-pon-pob\b/) ?
        <g>
        {/* Atas */}
        {form.tooch_number.includes("12L-fis") || form.tooch_number.includes("12L-amf") || form.tooch_number.includes("12L-cof") || form.tooch_number.includes("12L-car") ?
          <>
            {form.tooch_number.includes("12L-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="12L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 12L"
                  points="0,0 40,0 30,20 10,20"
                  style={{ fill: 'url(#12L-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("12-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />   
              </svg>
            }

            {form.tooch_number.includes("12L-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 12L"
                points="0,0 40,0 30,20 10,20"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("12-Labial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("12L-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="12L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 12L"
                  points="0,0 40,0 30,20 10,20"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("12-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("12L-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 12L"
                points="0,0 40,0 30,20 10,20"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("12-Labial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          
          </>
          :
          <svg width="40" height="40">
            <defs>
              <pattern id="12L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 12L"
              points="0,0 40,0 30,20 10,20"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("12-Labial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {/* Bawah */}
        {form.tooch_number.includes("12P-fis") || form.tooch_number.includes("12P-amf") || form.tooch_number.includes("12P-cof") || form.tooch_number.includes("12P-car") ?
          <>
            {form.tooch_number.includes("12P-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="12P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>

                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 12P"
                  points="30,20 40,40 0,40 10,20"
                  style={{ fill: 'url(#12P-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("12-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("12P-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 12P"
                points="30,20 40,40 0,40 10,20"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("12-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("12P-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="12P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 12P"
                  points="30,20 40,40 0,40 10,20"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("12-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("12P-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 12P"
                points="30,20 40,40 0,40 10,20"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("12-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          </>
          :
          <svg width="40" height="40">
            <defs>
              <pattern id="12P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 12P"
              points="30,20 40,40 0,40 10,20"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("12-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {/* Kanan */}
        {form.tooch_number.includes("12M-fis") || form.tooch_number.includes("12M-amf") || form.tooch_number.includes("12M-cof") || form.tooch_number.includes("12M-car") ?
          <>
            {form.tooch_number.includes("12M-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="12M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 12M"
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: 'url(#12M-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("12-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("12M-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 12M"
                points="30,20 40,0 40,40 35,30"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("12-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("12M-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="12M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 12M"
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("12-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }
          
            {form.tooch_number.includes("12M-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 12M"
                points="30,20 40,0 40,40 35,30"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("12-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          
          </>
          :
          <svg width="40" height="40">
            <defs>
              <pattern id="12M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 12M"
              points="30,20 40,0 40,40 35,30"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("12-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {/* Kiri */}
        {form.tooch_number.includes("12D-fis") || form.tooch_number.includes("12D-amf") || form.tooch_number.includes("12D-cof") || form.tooch_number.includes("12D-car") ?
          <>
            {form.tooch_number.includes("12D-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="12D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 12D"
                  points="0,0 5,10 10,20 0,40"
                  style={{ fill: 'url(#12D-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("12-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("12D-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 12D"
                points="0,0 5,10 10,20 0,40"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("12-Distal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("12D-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="12D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 12D"
                  points="0,0 5,10 10,20 0,40"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("12-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("12D-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 12D"
                points="0,0 5,10 10,20 0,40"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("12-Distal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

          </>
          :
          <svg width="40" height="40">
            <defs>
              <pattern id="12D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 12D"
              points="0,0 5,10 10,20 0,40"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("12-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {/* cfr */}
        {form.tooch_number.includes("12-cfr") &&
          <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
            #
          </text>
        }

        {/* rrx */}
        {form.tooch_number.includes("12-rrx") &&    
        <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 12-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
          v
        </text>
        }
              
        {/* mis */}
        {form.tooch_number.includes("12-mis") &&    
        <>  
          <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
          <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
        </> 
        }
                
        </g>
        :
        <g>
        {/* Atas */}
        {form.tooch_number.includes("12L-fis") || form.tooch_number.includes("12L-amf") || form.tooch_number.includes("12L-cof") || form.tooch_number.includes("12L-car") ?
          <>
            {form.tooch_number.includes("12L-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="12L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 12L"
                  points="0,0 40,0 30,20 10,20"
                  style={{ fill: 'url(#12L-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("12-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("12L-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 12L"
                points="0,0 40,0 30,20 10,20"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("12-Labial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("12L-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="12L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 12L"
                  points="0,0 40,0 30,20 10,20"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("12-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("12L-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 12L"
                points="0,0 40,0 30,20 10,20"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("12-Labial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          
          </>
          :
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 12L"
            points="0,0 40,0 30,20 10,20"
            style={{ fill: 'white', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("12-Labial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {/* Bawah */}
        {form.tooch_number.includes("12P-fis") || form.tooch_number.includes("12P-amf") || form.tooch_number.includes("12P-cof") || form.tooch_number.includes("12P-car") ?
          <>
            {form.tooch_number.includes("12P-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="12P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>

                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 12P"
                  points="30,20 40,40 0,40 10,20"
                  style={{ fill: 'url(#12P-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("12-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("12P-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 12P"
                points="30,20 40,40 0,40 10,20"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("12-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("12P-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="12P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 12P"
                  points="30,20 40,40 0,40 10,20"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("12-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("12P-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 12P"
                points="30,20 40,40 0,40 10,20"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("12-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          </>
          :
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 12P"
            points="30,20 40,40 0,40 10,20"
            style={{ fill: 'white', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("12-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {/* Kanan */}
        {form.tooch_number.includes("12M-fis") || form.tooch_number.includes("12M-amf") || form.tooch_number.includes("12M-cof") || form.tooch_number.includes("12M-car") ?
          <>
            {form.tooch_number.includes("12M-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="12M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 12M"
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: 'url(#12M-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("12-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("12M-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 12M"
                points="30,20 40,0 40,40 35,30"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("12-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("12M-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="12M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 12M"
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("12-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }
          
            {form.tooch_number.includes("12M-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 12M"
                points="30,20 40,0 40,40 35,30"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("12-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          
          </>
          :
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 12M"
            points="30,20 40,0 40,40 35,30"
            style={{ fill: 'white', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("12-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {/* Kiri */}
        {form.tooch_number.includes("12D-fis") || form.tooch_number.includes("12D-amf") || form.tooch_number.includes("12D-cof") || form.tooch_number.includes("12D-car") ?
          <>
            {form.tooch_number.includes("12D-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="12D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 12D"
                  points="0,0 5,10 10,20 0,40"
                  style={{ fill: 'url(#12D-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("12-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("12D-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 12D"
                points="0,0 5,10 10,20 0,40"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("12-Distal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("12D-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="12D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 12D"
                  points="0,0 5,10 10,20 0,40"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("12-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("12D-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 12D"
                points="0,0 5,10 10,20 0,40"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("12-Distal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

          </>
          :
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 12D"
            points="0,0 5,10 10,20 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("12-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {/* cfr */}
        {form.tooch_number.includes("12-cfr") &&
          <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
            #
          </text>
        }

        {form.tooch_number.includes("12-rrx") &&    
        <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 12-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
          v
        </text>
        }

        {form.tooch_number.includes("12-mis") &&    
        <>  
          <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
          <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
        </> 
        }
        </g>
        }    
          
        </svg>

        {form.tooch_number.includes("12-nvt") &&   
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
        <svg width={42} height={42}>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 12"
          points="21,2 41,32 1,32" 
          style={{ fill: 'white', stroke: 'black', strokeWidth: 3 }}
          onMouseEnter={() => setInfoGigi('12')}
          onMouseLeave={() => setInfoGigi('XX-X')}
        />
        </svg> 
        </div>
        }  

        {form.tooch_number.includes("12-rct") &&   
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
        <svg width={42} height={42}>
        <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 12"
        points="21,2 41,32 1,32" 
        style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
        onMouseEnter={() => setInfoGigi('12')}
        onMouseLeave={() => setInfoGigi('XX-X')}
        />
        </svg>
        </div>
        } 

        {form.tooch_number.includes("12B-amf-rct") || form.tooch_number.includes("12P-amf-rct") || form.tooch_number.includes("12M-amf-rct") || form.tooch_number.includes("12D-amf-rct") || form.tooch_number.includes("12L-amf-rct") ||
        form.tooch_number.includes("12B-cof-rct") || form.tooch_number.includes("12P-cof-rct") || form.tooch_number.includes("12M-cof-rct") || form.tooch_number.includes("12D-cof-rct") || form.tooch_number.includes("12L-cof-rct") ||
        form.tooch_number.includes("12-fmc-rct") ||
        form.tooch_number.includes("12-poc-rct") ?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
        <svg width={42} height={42}>
        <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 12B"
        points="21,2 41,32 1,32" 
        style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
        onMouseEnter={() => setInfoGigi('12-Bukal')}
        onMouseLeave={() => setInfoGigi('XX-X')}
        />
        </svg>
        </div>
        :
        <></>
        } 

        </div>
                
        <div style={{ width: "42px", margin: "1px" }}>
        {form.tooch_number.includes("11-une") || form.tooch_number.includes("11-non") || form.tooch_number.includes("11-pre") || form.tooch_number.includes("11-ano") || form.tooch_number.includes("11-ipx-poc") || form.tooch_number.includes("11-mis-prd-acr") || form.tooch_number.includes("11-mis-fld-acr") ?
        <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
        {form.tooch_number.includes("11-une") &&
          <div style={{ position: "absolute", color: "black" }}>UNE</div>
        }
        {form.tooch_number.includes("11-non") &&
          <div style={{ position: "absolute", color: "black" }}>NON</div>
        }      
        {form.tooch_number.includes("11-pre") &&
          <div style={{ position: "absolute", color: "black" }}>PRE</div>
        }      
        {form.tooch_number.includes("11-ano") &&
          <div style={{ position: "absolute", color: "black" }}>ANO</div>
        }
        {form.tooch_number.includes("11-ipx-poc") &&
          <div style={{ position: "absolute", color: "black" }}>IPX</div>
        }
        {form.tooch_number.includes("11-mis-prd-acr") &&
          <div style={{ position: "absolute", color: "black" }}>PRD</div>
        } 
        {form.tooch_number.includes("11-mis-fld-acr") &&
          <div style={{ position: "absolute", color: "black" }}>FLD</div>
        }      
        </div>
        :
        <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
        </div>
        }

        <div style={{ textAlign: "center" }}>11</div>
        <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("11-fmc") || form.tooch_number.includes("11-poc") || form.tooch_number.includes("11-ipx-poc") || form.tooch_number.match(/\b11-pob\b/) || form.tooch_number.match(/\b11-miss-pon-pob\b/) || form.tooch_number.match(/\b11-meb\b/) || form.tooch_number.match(/\b11-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
        {form.tooch_number.includes("11-poc") || form.tooch_number.includes("11-ipx-poc") || form.tooch_number.match(/\b11-pob\b/) || form.tooch_number.match(/\b11-miss-pon-pob\b/) ?
        <g>
        {/* Atas */}
        {form.tooch_number.includes("11L-fis") || form.tooch_number.includes("11L-amf") || form.tooch_number.includes("11L-cof") || form.tooch_number.includes("11L-car") ?
          <>
            {form.tooch_number.includes("11L-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="11L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 11L"
                  points="0,0 40,0 30,20 10,20"
                  style={{ fill: 'url(#11L-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("11-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />   
              </svg>
            }

            {form.tooch_number.includes("11L-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 11L"
                points="0,0 40,0 30,20 10,20"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("11-Labial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("11L-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="11L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 11L"
                  points="0,0 40,0 30,20 10,20"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("11-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("11L-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 11L"
                points="0,0 40,0 30,20 10,20"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("11-Labial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          
          </>
          :
          <svg width="40" height="40">
            <defs>
              <pattern id="11L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 11L"
              points="0,0 40,0 30,20 10,20"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("11-Labial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {/* Bawah */}
        {form.tooch_number.includes("11P-fis") || form.tooch_number.includes("11P-amf") || form.tooch_number.includes("11P-cof") || form.tooch_number.includes("11P-car") ?
          <>
            {form.tooch_number.includes("11P-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="11P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>

                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 11P"
                  points="30,20 40,40 0,40 10,20"
                  style={{ fill: 'url(#11P-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("11-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("11P-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 11P"
                points="30,20 40,40 0,40 10,20"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("11-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("11P-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="11P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 11P"
                  points="30,20 40,40 0,40 10,20"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("11-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("11P-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 11P"
                points="30,20 40,40 0,40 10,20"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("11-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          </>
          :
          <svg width="40" height="40">
            <defs>
              <pattern id="11P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 11P"
              points="30,20 40,40 0,40 10,20"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("11-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {/* Kanan */}
        {form.tooch_number.includes("11M-fis") || form.tooch_number.includes("11M-amf") || form.tooch_number.includes("11M-cof") || form.tooch_number.includes("11M-car") ?
          <>
            {form.tooch_number.includes("11M-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="11M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 11M"
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: 'url(#11M-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("11-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("11M-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 11M"
                points="30,20 40,0 40,40 35,30"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("11-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("11M-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="11M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 11M"
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("11-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }
          
            {form.tooch_number.includes("11M-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 11M"
                points="30,20 40,0 40,40 35,30"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("11-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          
          </>
          :
          <svg width="40" height="40">
            <defs>
              <pattern id="11M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 11M"
              points="30,20 40,0 40,40 35,30"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("11-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {/* Kiri */}
        {form.tooch_number.includes("11D-fis") || form.tooch_number.includes("11D-amf") || form.tooch_number.includes("11D-cof") || form.tooch_number.includes("11D-car") ?
          <>
            {form.tooch_number.includes("11D-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="11D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 11D"
                  points="0,0 5,10 10,20 0,40"
                  style={{ fill: 'url(#11D-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("11-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("11D-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 11D"
                points="0,0 5,10 10,20 0,40"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("11-Distal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("11D-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="11D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 11D"
                  points="0,0 5,10 10,20 0,40"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("11-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("11D-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 11D"
                points="0,0 5,10 10,20 0,40"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("11-Distal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

          </>
          :
          <svg width="40" height="40">
            <defs>
              <pattern id="11D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 11D"
              points="0,0 5,10 10,20 0,40"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("11-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {/* cfr */}
        {form.tooch_number.includes("11-cfr") &&
          <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
            #
          </text>
        }

        {/* rrx */}
        {form.tooch_number.includes("11-rrx") &&    
        <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 11-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
          v
        </text>
        }
              
        {/* mis */}
        {form.tooch_number.includes("11-mis") &&    
        <>  
          <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
          <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
        </> 
        }
                
        </g>
        :
        <g>
        {/* Atas */}
        {form.tooch_number.includes("11L-fis") || form.tooch_number.includes("11L-amf") || form.tooch_number.includes("11L-cof") || form.tooch_number.includes("11L-car") ?
          <>
            {form.tooch_number.includes("11L-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="11L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 11L"
                  points="0,0 40,0 30,20 10,20"
                  style={{ fill: 'url(#11L-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("11-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("11L-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 11L"
                points="0,0 40,0 30,20 10,20"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("11-Labial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("11L-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="11L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 11L"
                  points="0,0 40,0 30,20 10,20"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("11-Labial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("11L-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 11L"
                points="0,0 40,0 30,20 10,20"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("11-Labial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          
          </>
          :
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 11L"
            points="0,0 40,0 30,20 10,20"
            style={{ fill: 'white', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("11-Labial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {/* Bawah */}
        {form.tooch_number.includes("11P-fis") || form.tooch_number.includes("11P-amf") || form.tooch_number.includes("11P-cof") || form.tooch_number.includes("11P-car") ?
          <>
            {form.tooch_number.includes("11P-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="11P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>

                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 11P"
                  points="30,20 40,40 0,40 10,20"
                  style={{ fill: 'url(#11P-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("11-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("11P-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 11P"
                points="30,20 40,40 0,40 10,20"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("11-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("11P-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="11P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 11P"
                  points="30,20 40,40 0,40 10,20"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("11-Palatal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("11P-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 11P"
                points="30,20 40,40 0,40 10,20"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("11-Palatal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          </>
          :
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 11P"
            points="30,20 40,40 0,40 10,20"
            style={{ fill: 'white', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("11-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {/* Kanan */}
        {form.tooch_number.includes("11M-fis") || form.tooch_number.includes("11M-amf") || form.tooch_number.includes("11M-cof") || form.tooch_number.includes("11M-car") ?
          <>
            {form.tooch_number.includes("11M-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="11M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 11M"
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: 'url(#11M-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("11-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("11M-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 11M"
                points="30,20 40,0 40,40 35,30"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("11-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("11M-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="11M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 11M"
                  points="30,20 40,0 40,40 35,30"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("11-Mesial")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }
          
            {form.tooch_number.includes("11M-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 11M"
                points="30,20 40,0 40,40 35,30"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("11-Mesial")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }
          
          </>
          :
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 11M"
            points="30,20 40,0 40,40 35,30"
            style={{ fill: 'white', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("11-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {/* Kiri */}
        {form.tooch_number.includes("11D-fis") || form.tooch_number.includes("11D-amf") || form.tooch_number.includes("11D-cof") || form.tooch_number.includes("11D-car") ?
          <>
            {form.tooch_number.includes("11D-fis") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="11D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                    <rect width="8" height="8" fill="#ffb8c6" />
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 11D"
                  points="0,0 5,10 10,20 0,40"
                  style={{ fill: 'url(#11D-fis)', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("11-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("11D-amf") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 11D"
                points="0,0 5,10 10,20 0,40"
                style={{ fill: 'black', stroke: 'black' }}
                onMouseEnter={() => setInfoGigi("11-Distal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

            {form.tooch_number.includes("11D-cof") &&
              <svg width="40" height="40">
                <defs>
                  <pattern id="11D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                    <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
                  </pattern>
                </defs>
                <polygon
                  onClick={onclikTooch}
                  data-nomor-gigi=" 11D"
                  points="0,0 5,10 10,20 0,40"
                  style={{ fill: '#007610', stroke: 'black' }}
                  onMouseEnter={() => setInfoGigi("11-Distal")}
                  onMouseLeave={() => setInfoGigi("XX-X")}
                />
              </svg>
            }

            {form.tooch_number.includes("11D-car") &&
              <polygon
                onClick={onclikTooch}
                data-nomor-gigi=" 11D"
                points="0,0 5,10 10,20 0,40"
                style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
                onMouseEnter={() => setInfoGigi("11-Distal")}
                onMouseLeave={() => setInfoGigi("XX-X")}
              />
            }

          </>
          :
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 11D"
            points="0,0 5,10 10,20 0,40"
            style={{ fill: 'white', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("11-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {/* cfr */}
        {form.tooch_number.includes("11-cfr") &&
          <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
            #
          </text>
        }

        {form.tooch_number.includes("11-rrx") &&    
        <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 11-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
          v
        </text>
        }

        {form.tooch_number.includes("11-mis") &&    
        <>  
          <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
          <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
        </> 
        }
        </g>
        }    
          
        </svg>

        {form.tooch_number.includes("11-nvt") &&   
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
        <svg width={42} height={42}>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 11"
          points="21,2 41,32 1,32" 
          style={{ fill: 'white', stroke: 'black', strokeWidth: 3 }}
          onMouseEnter={() => setInfoGigi('11')}
          onMouseLeave={() => setInfoGigi('XX-X')}
        />
        </svg> 
        </div>
        }  

        {form.tooch_number.includes("11-rct") &&   
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
        <svg width={42} height={42}>
        <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 11"
        points="21,2 41,32 1,32" 
        style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
        onMouseEnter={() => setInfoGigi('11')}
        onMouseLeave={() => setInfoGigi('XX-X')}
        />
        </svg>
        </div>
        } 

        {form.tooch_number.includes("11B-amf-rct") || form.tooch_number.includes("11P-amf-rct") || form.tooch_number.includes("11M-amf-rct") || form.tooch_number.includes("11D-amf-rct") || form.tooch_number.includes("11L-amf-rct") ||
        form.tooch_number.includes("11B-cof-rct") || form.tooch_number.includes("11P-cof-rct") || form.tooch_number.includes("11M-cof-rct") || form.tooch_number.includes("11D-cof-rct") || form.tooch_number.includes("11L-cof-rct") ||
        form.tooch_number.includes("11-fmc-rct") ||
        form.tooch_number.includes("11-poc-rct") ?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
        <svg width={42} height={42}>
        <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 11B"
        points="21,2 41,32 1,32" 
        style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
        onMouseEnter={() => setInfoGigi('11-Bukal')}
        onMouseLeave={() => setInfoGigi('XX-X')}
        />
        </svg>
        </div>
        :
        <></>
        } 

        </div>

          </div>
  );
}
