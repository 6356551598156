import React, { useState, useEffect } from 'react'
import { Sidebar, Menu, MenuItem,SubMenu,useProSidebar } from 'react-pro-sidebar';
import Nav from 'react-bootstrap/Nav';
import { Link} from "react-router-dom";
import {FaDotCircle} from "react-icons/fa";
import {  useNavigate } from "react-router-dom";
import {  Navbar as NavbarComp, NavDropdown } from 'react-bootstrap'
import Logonav from '../../assets/signature/signatureNewIcon.png'
import { FaArrowRight } from 'react-icons/fa';
import "./Sidebar.css"
import { setAuthToken } from '../../config/api';
import { useMediaQuery } from 'react-responsive'
import BackgroundBatik from "../../assets/signature/logo-sidebar-2.jpg"
import BackgroundBatik2 from "../../assets/signature/background-submenu.jpg"
import { useLocation } from 'react-router-dom';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

// Helo

export default function SidebarHome(){
  const navigate = useNavigate();
  const storageItems = JSON.parse(localStorage.getItem('menus'));
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)'})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)'})
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const urlColor = ""
  const location = useLocation(""); 
  const levelUser = localStorage.getItem('level');
  console.log(storageItems)
  
  const logout = (e)=>{
    e.preventDefault();  
    localStorage.clear();
    navigate("/");
  };

  return (
    <Sidebar backgroundColor={`url${urlColor}`} className='sidebar' style={{width:"100%",height:"100%",position:"",border:"none", boxShadow:"1px 1px 10px #9E9E9E",backgroundImage: `url(${BackgroundBatik})`}}>
      <Menu style={{ marginTop: "2vh" }} closeOnClick>
        <div style={{padding:"0px 40px"}}> 
          <NavbarComp.Brand as={Link} to="/dashboard" style={{display:"flex",justifyContent:"center", textAlign:"center",  backgroundColor:"white", borderRadius:"5px", boxShadow:"1px 1px 10px #B0B0B0", opacity:".8", cursor:"pointer"}}>
            <img src={Logonav} style={{width:"100%", margin:"30px", padding:"5px"}}/>
          </NavbarComp.Brand> 
        </div>
    
        <div className='mt-3 mb-3' style={{ opacity:".7", display:"flex",justifyContent:"center", fontSize:"13px", backgroundColor:"#FFDC77", padding:"10px 0px"}}>PT. Signature Anugerah Sentosa</div>
        {storageItems.map((item, index) => (
        (item.url === "" || item.url == null) ? (
          (item.name === "User Previlege" && levelUser !== "developer") ? (
            <></>
          ) : (
            <SubMenu  key={index} style={{fontFamily:"sans-serif",paddingLeft:"30px", display:"flex",alignItems:"center", height:"5vh", borderTop:"1px solid #D5D5D5", borderBottom:"1px solid #D5D5D5", fontWeight:"bold",color:"#666666", fontSize:"14px"}} label={item.name}  
              icon={<i className={item.icon_name} style={{display:"flex",height:"100%",alignItems:"center", paddingLeft:"10px",marginLeft:"10px", color:"#666666" , fontSize:"20px"}}/>}>
              {item.menus.map((itemss) => (
                <MenuItem backgroundColor={`url${urlColor}`} href={itemss.url} style={{borderTop:"1px solid #D5D5D5",height:"5vh",fontSize:"12px", fontWeight:"", borderBottom:"1px solid #D5D5D5", fontFamily: "sans-serif", paddingLeft: "55px", backgroundColor:"" ,backgroundImage: `url(${BackgroundBatik2})`}} >
                  <FaDotCircle style={{ marginRight: "10px", marginBottom: "3px", fontSize: "10px", color: "#666666"}} />
                  {itemss.name}
                </MenuItem>
              ))}
            </SubMenu>
          )
        ) : (
            <MenuItem href={item.url} style={{ fontFamily: "sans-serif", marginLeft: "0px" }} icon={<i className={item.icon_name} style={{ marginLeft: "15px", color:"#666666", fontSize: "20px" }} />}>
              {item.name}
            </MenuItem>
          )
        ))}

      </Menu>
    </Sidebar>
  )
}
