import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {Row,Col,Button} from 'reactstrap'
import { API } from "../../config/api";
import {FaPrint} from 'react-icons/fa'
import "bulma/css/bulma.css";
import { useMutation } from "react-query";
import "../../index.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
import arsiran from "../../assets/signature/arsiran.png"
// Modal Role
import ModalAddMedis from "./ModalMedicalRecord/ModalAdd"
import ModalUpdateMedis from "./ModalMedicalRecord/ModalUpdate"
import { ScaleLoader } from "react-spinners";
import LoaderHome from "../Loader/LoaderHome"
import logo_amf from "../../assets/signature/logo/amf.png"
import logo_cof from "../../assets/signature/logo/cof.png"
import logo_fis from "../../assets/signature/logo/fis.png"
import logo_nvt from "../../assets/signature/logo/nvt.png"
import logo_rct from "../../assets/signature/logo/rct.png"
import logo_car from "../../assets/signature/logo/car.png"
import logo_cfr from "../../assets/signature/logo/cfr.png"
import logo_amf_rct from "../../assets/signature/logo/amf-rct.png"
import logo_cof_rct from "../../assets/signature/logo/cof-rct.png"
import logo_fmc from "../../assets/signature/logo/fmc.png"
import logo_fmc_rct from "../../assets/signature/logo/fmc-rct.png"
import logo_poc from "../../assets/signature/logo/poc.png"
import logo_poc_rct from "../../assets/signature/logo/poc-rct.png"
import logo_rrx from "../../assets/signature/logo/rrx.png"
import logo_mis from "../../assets/signature/logo/mis.png"
import logo_ipx_poc from "../../assets/signature/logo/ipx-poc.png"
import logo_meb from "../../assets/signature/logo/meb.png"
import logo_pob from "../../assets/signature/logo/pob.png"
import logo_mis_prd from "../../assets/signature/logo/miss-prd.png"
import logo_mis_fld from "../../assets/signature/logo/miss-fld.png"
import logo_sou from "../../assets/signature/logo/sou.png"
import axios from "axios";
// Component gigi
import RahangAtasKananDewasa from "./Tooch/RahangAtasKananDewasaTooch"
import RahangAtasKiriDewasa from "./Tooch/RahangAtasKiriDewasaTooch"
import RahangAtasKananAnak from "./Tooch/RahangAtasKananAnakTooch"
import RahangAtasKiriAnak from "./Tooch/RahangAtasKiriAnakTooch"
import RahangBawahKananAnak from "./Tooch/RahangBawahKananAnakTooch"
import RahangBawahKiriAnak from "./Tooch/RahangBawahKiriAnakTooch"
import RahangBawahKananDewasa from "./Tooch/RahangBawahKananDewasaTooch"
import RahangBawahKiriDewasa from "./Tooch/RahangBawahKiriDewasaTooch"

export default function Odontogram() {
  // let navigate = useNavigate();
  // useEffect(() => {
  //   if (localStorage.getItem("token") == null || undefined) {
  //       navigate("/");
  //     }
  // }, []);
  document.title = "SDC Apps";
  const navigate = useNavigate()
  const [getData, setGetData] = useState([]);
  const [getDataRekamMedis, setGetDataRekamMedis] = useState([]);
  const [pagination,setPagination] = useState([])
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const [rows, setRows] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");
  const token = localStorage.getItem("token");
  const [idDelete, setIdDelete] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [show, setShow] = useState(false);
  const [search,setSearch] = useState("")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  // Responsive to mobile or dekstop
  // modal role add 
  const [idModal, setIdModalUpdate] = useState();
  const [idModalAdd, setIdModalAdd] = useState();
  const [codeGenerata, setCodeGenerate] = useState()
  const { id, first_name, last_name } = useParams()
  const [modalAdd, setModalAdd] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [getDataDokter, setGetDataDokter] = useState([]);
  const [idRoleDokter, setIdRoleDokter] = useState("");
  const [nameRoleDoctor, setNameRoleDoktor] = useState("Doctor");

  const [infoGigi, setInfoGigi] = useState("XX-X")

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const GetResponseData = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`emr-service/odontogram-news?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&id_patient=${id}`, fetchParams)

      // Checking process
      if (response?.status === 200) {
        if (response.data.data.length === 0) {
          // Tangani kasus array kosong di sini
          setGetData([]);
        } else {
          // Ambil elemen pertama dari array
          setGetData(response.data.data[0]);
        }
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataDokter = async () => {
    try {
      // e.preventDefault();
      const response = await API.get(`user-service/users?page=${page}&limit=${limit}&ascending=${ascending}&id_role=${idRoleDokter}&name_role=${nameRoleDoctor}`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataDokter(response.data.data)
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  useEffect(() => {
    GetResponseData()
    GetResponseDataDokter()
  },[])

  const [amfSimbol, setAmfSimbol] = useState(false);
  const [cofSimbol, setCofSimbol] = useState(false);
  const [fisSimbol, setFisSimbol] = useState(false);
  const [nvtSimbol, setNvtSimbol] = useState(false);
  const [rctSimbol, setRctSimbol] = useState(false);
  const [carSimbol, setCarSimbol] = useState(false);
  const [cfrSimbol, setCfrSimbol] = useState(false);
  const [amfRctSimbol, setAmfRctSimbol] = useState(false);
  const [cofRctSimbol, setCofRctSimbol] = useState(false);
  const [fmcSimbol, setFmcSimbol] = useState(false);
  const [fmcRctSimbol, setFmcRctSimbol] = useState(false);
  const [pocSimbol, setPocSimbol] = useState(false);
  const [pocRctSimbol, setPocRctSimbol] = useState(false);
  const [rrxSimbol, setRrxSimbol] = useState(false);
  const [misSimbol, setMisSimbol] = useState(false);
  const [ipxPocSimbol, setIpxPocSimbol] = useState(false);
  const [mebSimbol, setMebSimbol] = useState(false);
  const [pobSimbol, setPobSimbol] = useState(false);
  const [misPrdSimbol, setMisPrdSimbol] = useState(false);
  const [misFldSimbol, setMisFldSimbol] = useState(false);
  const [souSimbol, setSouSimbol] = useState(false);
  const [nonSimbol, setNonSimbol] = useState(false);
  const [uneSimbol, setUneSimbol] = useState(false);
  const [preSimbol, setPreSimbol] = useState(false);
  const [anoSimbol, setAnoSimbol] = useState(false);

  const addAmf = () => {
    setAmfSimbol(true);
    setCofSimbol(false)
    setFisSimbol(false)
    setNvtSimbol(false)
    setRctSimbol(false)
    setCarSimbol(false)
    setCfrSimbol(false)
    setAmfRctSimbol(false)
    setCofRctSimbol(false)
    setFmcSimbol(false)
    setFmcRctSimbol(false)
    setPocSimbol(false)
    setPocRctSimbol(false)
    setRrxSimbol(false)
    setMisSimbol(false)
    setIpxPocSimbol(false)
    setMebSimbol(false)
    setPobSimbol(false)
    setMisPrdSimbol(false)
    setMisFldSimbol(false)
    setSouSimbol(false)
    setNonSimbol(false)
    setUneSimbol(false)
    setPreSimbol(false)
    setAnoSimbol(false)
  };
    
  const addCof = () => {
    setAmfSimbol(false);
    setCofSimbol(true)
    setFisSimbol(false)
    setNvtSimbol(false)
    setRctSimbol(false)
    setCarSimbol(false)
    setCfrSimbol(false)
    setAmfRctSimbol(false)
    setCofRctSimbol(false)
    setFmcSimbol(false)
    setFmcRctSimbol(false)
    setPocSimbol(false)
    setPocRctSimbol(false)
    setRrxSimbol(false)
    setMisSimbol(false)
    setIpxPocSimbol(false)
    setMebSimbol(false)
    setPobSimbol(false)
    setMisPrdSimbol(false)
    setMisFldSimbol(false)
    setSouSimbol(false)
    setNonSimbol(false)
    setUneSimbol(false)
    setPreSimbol(false)
    setAnoSimbol(false)
  };
  
  const addFis = () => {
  setAmfSimbol(false);
  setCofSimbol(false)
  setFisSimbol(true)
  setNvtSimbol(false)
  setRctSimbol(false)
  setCarSimbol(false)
  setCfrSimbol(false)
  setAmfRctSimbol(false)
  setCofRctSimbol(false)
  setFmcSimbol(false)
    setFmcRctSimbol(false)
    setPocSimbol(false)
  setPocRctSimbol(false)
  setRrxSimbol(false)
  setMisSimbol(false)
  setIpxPocSimbol(false)
  setMebSimbol(false)
  setPobSimbol(false)
  setMisPrdSimbol(false)
  setMisFldSimbol(false)
  setSouSimbol(false)
  setNonSimbol(false)
  setUneSimbol(false)
  setPreSimbol(false)
  setAnoSimbol(false)
  }; 
  
  const addNvt = () => {
    setAmfSimbol(false);
    setCofSimbol(false)
    setFisSimbol(false)
    setNvtSimbol(true)
    setRctSimbol(false)
    setCarSimbol(false)
    setCfrSimbol(false)
    setAmfRctSimbol(false)
    setCofRctSimbol(false)
    setFmcSimbol(false)
    setFmcRctSimbol(false)
    setPocSimbol(false)

    setPocRctSimbol(false)
    setRrxSimbol(false)
    setMisSimbol(false)
    setIpxPocSimbol(false)
    setMebSimbol(false)
    setPobSimbol(false)
    setMisPrdSimbol(false)
    setMisFldSimbol(false)
    setSouSimbol(false)
    setNonSimbol(false)
    setUneSimbol(false)
    setPreSimbol(false)
    setAnoSimbol(false)
  };

  const addRct = () => {
    setAmfSimbol(false);
    setCofSimbol(false)
    setFisSimbol(false)
    setNvtSimbol(false)
    setRctSimbol(true)
    setCarSimbol(false)
    setCfrSimbol(false)
    setAmfRctSimbol(false)
    setCofRctSimbol(false)
    setFmcSimbol(false)
    setFmcRctSimbol(false)
    setPocSimbol(false)
    setPocRctSimbol(false)
    setRrxSimbol(false)
    setMisSimbol(false)
    setIpxPocSimbol(false)
    setMebSimbol(false)
    setPobSimbol(false)
    setMisPrdSimbol(false)
    setMisFldSimbol(false)
    setSouSimbol(false)
    setNonSimbol(false)
    setUneSimbol(false)
    setPreSimbol(false)
    setAnoSimbol(false)
  };

  const addCar = () => {
    setAmfSimbol(false);
    setCofSimbol(false)
    setFisSimbol(false)
    setNvtSimbol(false)
    setRctSimbol(false)
    setCarSimbol(true)
    setCfrSimbol(false)
    setAmfRctSimbol(false)
    setCofRctSimbol(false)
    setFmcSimbol(false)
    setFmcRctSimbol(false)
    setPocSimbol(false)
    setPocRctSimbol(false)
    setRrxSimbol(false)
    setMisSimbol(false)
    setIpxPocSimbol(false)
    setMebSimbol(false)
    setPobSimbol(false)
    setMisPrdSimbol(false)
    setMisFldSimbol(false)
    setSouSimbol(false)
    setNonSimbol(false)
    setUneSimbol(false)
    setPreSimbol(false)
    setAnoSimbol(false)
  };

  const addCfr = () => {
    setAmfSimbol(false);
    setCofSimbol(false)
    setFisSimbol(false)
    setNvtSimbol(false)
    setRctSimbol(false)
    setCarSimbol(false)
    setCfrSimbol(true)
    setAmfRctSimbol(false)
    setCofRctSimbol(false)
    setFmcSimbol(false)
    setFmcRctSimbol(false)
    setPocSimbol(false)
    setPocRctSimbol(false)
    setRrxSimbol(false)
    setMisSimbol(false)
    setIpxPocSimbol(false)
    setMebSimbol(false)
    setPobSimbol(false)
    setMisPrdSimbol(false)
    setMisFldSimbol(false)
    setSouSimbol(false)
    setNonSimbol(false)
    setUneSimbol(false)
    setPreSimbol(false)
    setAnoSimbol(false)
  };

  const addAmfRct = () => {
    setAmfSimbol(false);
    setCofSimbol(false)
    setFisSimbol(false)
    setNvtSimbol(false)
    setRctSimbol(false)
    setCarSimbol(false)
    setCfrSimbol(false)
    setAmfRctSimbol(true)
    setCofRctSimbol(false)
    setFmcSimbol(false)
    setFmcRctSimbol(false)
    setPocSimbol(false)
    setPocRctSimbol(false)
    setRrxSimbol(false)
    setMisSimbol(false)
    setIpxPocSimbol(false)
    setMebSimbol(false)
    setPobSimbol(false)
    setMisPrdSimbol(false)
    setMisFldSimbol(false)
    setSouSimbol(false)
    setNonSimbol(false)
    setUneSimbol(false)
    setPreSimbol(false)
    setAnoSimbol(false)
  };

  const addCofRct = () => {
    setAmfSimbol(false);
    setCofSimbol(false)
    setFisSimbol(false)
    setNvtSimbol(false)
    setRctSimbol(false)
    setCarSimbol(false)
    setCfrSimbol(false)
    setAmfRctSimbol(false)
    setCofRctSimbol(true)
    setFmcSimbol(false)
    setFmcRctSimbol(false)
    setPocSimbol(false)
    setPocRctSimbol(false)
    setRrxSimbol(false)
    setMisSimbol(false)
    setIpxPocSimbol(false)
    setMebSimbol(false)
    setPobSimbol(false)
    setMisPrdSimbol(false)
    setMisFldSimbol(false)
    setSouSimbol(false)
    setNonSimbol(false)
    setUneSimbol(false)
    setPreSimbol(false)
    setAnoSimbol(false)
  };

  const addFmc = () => {
    setAmfSimbol(false);
    setCofSimbol(false)
    setFisSimbol(false)
    setNvtSimbol(false)
    setRctSimbol(false)
    setCarSimbol(false)
    setCfrSimbol(false)
    setAmfRctSimbol(false)
    setCofRctSimbol(false)
    setFmcSimbol(true)
    setFmcRctSimbol(false)
    setPocSimbol(false)
    setPocRctSimbol(false)
    setRrxSimbol(false)
    setMisSimbol(false)
    setIpxPocSimbol(false)
    setMebSimbol(false)
    setPobSimbol(false)
    setMisPrdSimbol(false)
    setMisFldSimbol(false)
    setSouSimbol(false)
    setNonSimbol(false)
    setUneSimbol(false)
    setPreSimbol(false)
    setAnoSimbol(false)
  };

  const addFmcRct = () => {
    setAmfSimbol(false);
    setCofSimbol(false)
    setFisSimbol(false)
    setNvtSimbol(false)
    setRctSimbol(false)
    setCarSimbol(false)
    setCfrSimbol(false)
    setAmfRctSimbol(false)
    setCofRctSimbol(false)
    setFmcSimbol(false)
    setFmcRctSimbol(true)
    setPocSimbol(false)
    setPocRctSimbol(false)
    setRrxSimbol(false)
    setMisSimbol(false)
    setIpxPocSimbol(false)
    setMebSimbol(false)
    setPobSimbol(false)
    setMisPrdSimbol(false)
    setMisFldSimbol(false)
    setSouSimbol(false)
    setNonSimbol(false)
    setUneSimbol(false)
    setPreSimbol(false)
    setAnoSimbol(false)
  };

  const addPoc = () => {
    setAmfSimbol(false);
    setCofSimbol(false)
    setFisSimbol(false)
    setNvtSimbol(false)
    setRctSimbol(false)
    setCarSimbol(false)
    setCfrSimbol(false)
    setAmfRctSimbol(false)
    setCofRctSimbol(false)
    setFmcSimbol(false)
    setFmcRctSimbol(false)
    setPocSimbol(true)
    setPocRctSimbol(false)
    setRrxSimbol(false)
    setMisSimbol(false)
    setIpxPocSimbol(false)
    setMebSimbol(false)
    setPobSimbol(false)
    setMisPrdSimbol(false)
    setMisFldSimbol(false)
    setSouSimbol(false)
    setNonSimbol(false)
    setUneSimbol(false)
    setPreSimbol(false)
    setAnoSimbol(false)
  };

  const addPocRct = () => {
    setAmfSimbol(false);
    setCofSimbol(false)
    setFisSimbol(false)
    setNvtSimbol(false)
    setRctSimbol(false)
    setCarSimbol(false)
    setCfrSimbol(false)
    setAmfRctSimbol(false)
    setCofRctSimbol(false)
    setFmcSimbol(false)
    setFmcRctSimbol(false)
    setPocSimbol(false)
    setPocRctSimbol(true)
    setRrxSimbol(false)
    setMisSimbol(false)
    setIpxPocSimbol(false)
    setMebSimbol(false)
    setPobSimbol(false)
    setMisPrdSimbol(false)
    setMisFldSimbol(false)
    setSouSimbol(false)
    setNonSimbol(false)
    setUneSimbol(false)
    setPreSimbol(false)
    setAnoSimbol(false)
  };

  const addRrx = () => {
    setAmfSimbol(false);
    setCofSimbol(false)
    setFisSimbol(false)
    setNvtSimbol(false)
    setRctSimbol(false)
    setCarSimbol(false)
    setCfrSimbol(false)
    setAmfRctSimbol(false)
    setCofRctSimbol(false)
    setFmcSimbol(false)
    setFmcRctSimbol(false)
    setPocSimbol(false)
    setPocRctSimbol(false)
    setRrxSimbol(true)
    setMisSimbol(false)
    setIpxPocSimbol(false)
    setMebSimbol(false)
    setPobSimbol(false)
    setMisPrdSimbol(false)
    setMisFldSimbol(false)
    setSouSimbol(false)
    setNonSimbol(false)
    setUneSimbol(false)
    setPreSimbol(false)
    setAnoSimbol(false)
  };

  const addMis = () => {
    setAmfSimbol(false);
    setCofSimbol(false)
    setFisSimbol(false)
    setNvtSimbol(false)
    setRctSimbol(false)
    setCarSimbol(false)
    setCfrSimbol(false)
    setAmfRctSimbol(false)
    setCofRctSimbol(false)
    setFmcSimbol(false)
    setFmcRctSimbol(false)
    setPocSimbol(false)
    setPocRctSimbol(false)
    setRrxSimbol(false)
    setMisSimbol(true)
    setIpxPocSimbol(false)
    setMebSimbol(false)
    setPobSimbol(false)
    setMisPrdSimbol(false)
    setMisFldSimbol(false)
    setSouSimbol(false)
    setNonSimbol(false)
    setUneSimbol(false)
    setPreSimbol(false)
    setAnoSimbol(false)
  };

  const addIpxPoc = () => {
    setAmfSimbol(false);
    setCofSimbol(false)
    setFisSimbol(false)
    setNvtSimbol(false)
    setRctSimbol(false)
    setCarSimbol(false)
    setCfrSimbol(false)
    setAmfRctSimbol(false)
    setCofRctSimbol(false)
    setFmcSimbol(false)
    setFmcRctSimbol(false)
    setPocSimbol(false)
    setPocRctSimbol(false)
    setRrxSimbol(false)
    setMisSimbol(false)
    setIpxPocSimbol(true)
    setMebSimbol(false)
    setPobSimbol(false)
    setMisPrdSimbol(false)
    setMisFldSimbol(false)
    setSouSimbol(false)
    setNonSimbol(false)
    setUneSimbol(false)
    setPreSimbol(false)
    setAnoSimbol(false)
  };

  const addMeb = () => {
    setAmfSimbol(false);
    setCofSimbol(false)
    setFisSimbol(false)
    setNvtSimbol(false)
    setRctSimbol(false)
    setCarSimbol(false)
    setCfrSimbol(false)
    setAmfRctSimbol(false)
    setCofRctSimbol(false)
    setFmcSimbol(false)
    setFmcRctSimbol(false)
    setPocSimbol(false)
    setPocRctSimbol(false)
    setRrxSimbol(false)
    setMisSimbol(false)
    setIpxPocSimbol(false)
    setMebSimbol(true)
    setPobSimbol(false)
    setMisPrdSimbol(false)
    setMisFldSimbol(false)
    setSouSimbol(false)
    setNonSimbol(false)
    setUneSimbol(false)
    setPreSimbol(false)
    setAnoSimbol(false)
  };

  const addPob = () => {
    setAmfSimbol(false);
    setCofSimbol(false)
    setFisSimbol(false)
    setNvtSimbol(false)
    setRctSimbol(false)
    setCarSimbol(false)
    setCfrSimbol(false)
    setAmfRctSimbol(false)
    setCofRctSimbol(false)
    setFmcSimbol(false)
    setFmcRctSimbol(false)
    setPocSimbol(false)
    setPocRctSimbol(false)
    setRrxSimbol(false)
    setMisSimbol(false)
    setIpxPocSimbol(false)
    setMebSimbol(false)
    setPobSimbol(true)
    setMisPrdSimbol(false)
    setMisFldSimbol(false)
    setSouSimbol(false)
    setNonSimbol(false)
    setUneSimbol(false)
    setPreSimbol(false)
    setAnoSimbol(false)
  };

  const addMisPrd = () => {
    setAmfSimbol(false);
    setCofSimbol(false)
    setFisSimbol(false)
    setNvtSimbol(false)
    setRctSimbol(false)
    setCarSimbol(false)
    setCfrSimbol(false)
    setAmfRctSimbol(false)
    setCofRctSimbol(false)
    setFmcSimbol(false)
    setFmcRctSimbol(false)
    setPocSimbol(false)
    setPocRctSimbol(false)
    setRrxSimbol(false)
    setMisSimbol(false)
    setIpxPocSimbol(false)
    setMebSimbol(false)
    setPobSimbol(false)
    setMisPrdSimbol(true)
    setMisFldSimbol(false)
    setSouSimbol(false)
    setNonSimbol(false)
    setUneSimbol(false)
    setPreSimbol(false)
    setAnoSimbol(false)
  };

  const addMisFld = () => {
    setAmfSimbol(false);
    setCofSimbol(false)
    setFisSimbol(false)
    setNvtSimbol(false)
    setRctSimbol(false)
    setCarSimbol(false)
    setCfrSimbol(false)
    setAmfRctSimbol(false)
    setCofRctSimbol(false)
    setFmcSimbol(false)
    setFmcRctSimbol(false)
    setPocSimbol(false)
    setPocRctSimbol(false)
    setRrxSimbol(false)
    setMisSimbol(false)
    setIpxPocSimbol(false)
    setMebSimbol(false)
    setPobSimbol(false)
    setMisPrdSimbol(false)
    setMisFldSimbol(true)
    setSouSimbol(false)
    setNonSimbol(false)
    setUneSimbol(false)
    setPreSimbol(false)
    setAnoSimbol(false)
  };

  const addSou = () => {
    setAmfSimbol(false);
    setCofSimbol(false)
    setFisSimbol(false)
    setNvtSimbol(false)
    setRctSimbol(false)
    setCarSimbol(false)
    setCfrSimbol(false)
    setAmfRctSimbol(false)
    setCofRctSimbol(false)
    setFmcSimbol(false)
    setFmcRctSimbol(false)
    setPocSimbol(false)
    setPocRctSimbol(false)
    setRrxSimbol(false)
    setMisSimbol(false)
    setIpxPocSimbol(false)
    setMebSimbol(false)
    setPobSimbol(false)
    setMisPrdSimbol(false)
    setMisFldSimbol(false)
    setSouSimbol(true)
    setNonSimbol(false)
    setUneSimbol(false)
    setPreSimbol(false)
    setAnoSimbol(false)
  };

  const addNon = () => {
    setAmfSimbol(false);
    setCofSimbol(false)
    setFisSimbol(false)
    setNvtSimbol(false)
    setRctSimbol(false)
    setCarSimbol(false)
    setCfrSimbol(false)
    setAmfRctSimbol(false)
    setCofRctSimbol(false)
    setFmcSimbol(false)
    setFmcRctSimbol(false)
    setPocSimbol(false)
    setPocRctSimbol(false)
    setRrxSimbol(false)
    setMisSimbol(false)
    setIpxPocSimbol(false)
    setMebSimbol(false)
    setPobSimbol(false)
    setMisPrdSimbol(false)
    setMisFldSimbol(false)
    setSouSimbol(false)
    setNonSimbol(true)
    setUneSimbol(false)
    setPreSimbol(false)
    setAnoSimbol(false)
  };

  const addUne = () => {
    setAmfSimbol(false);
    setCofSimbol(false)
    setFisSimbol(false)
    setNvtSimbol(false)
    setRctSimbol(false)
    setCarSimbol(false)
    setCfrSimbol(false)
    setAmfRctSimbol(false)
    setCofRctSimbol(false)
    setFmcSimbol(false)
    setFmcRctSimbol(false)
    setPocSimbol(false)
    setPocRctSimbol(false)
    setRrxSimbol(false)
    setMisSimbol(false)
    setIpxPocSimbol(false)
    setMebSimbol(false)
    setPobSimbol(false)
    setMisPrdSimbol(false)
    setMisFldSimbol(false)
    setSouSimbol(false)
    setNonSimbol(false)
    setUneSimbol(true)
    setPreSimbol(false)
    setAnoSimbol(false)
  };

  const addPre = () => {
    setAmfSimbol(false);
    setCofSimbol(false)
    setFisSimbol(false)
    setNvtSimbol(false)
    setRctSimbol(false)
    setCarSimbol(false)
    setCfrSimbol(false)
    setAmfRctSimbol(false)
    setCofRctSimbol(false)
    setFmcSimbol(false)
    setFmcRctSimbol(false)
    setPocSimbol(false)
    setPocRctSimbol(false)
    setRrxSimbol(false)
    setMisSimbol(false)
    setIpxPocSimbol(false)
    setMebSimbol(false)
    setPobSimbol(false)
    setMisPrdSimbol(false)
    setMisFldSimbol(false)
    setSouSimbol(false)
    setNonSimbol(false)
    setUneSimbol(false)
    setPreSimbol(true)
    setAnoSimbol(false)
  };

  const addAno = () => {
    setAmfSimbol(false);
    setCofSimbol(false)
    setFisSimbol(false)
    setNvtSimbol(false)
    setRctSimbol(false)
    setCarSimbol(false)
    setCfrSimbol(false)
    setAmfRctSimbol(false)
    setCofRctSimbol(false)
    setFmcSimbol(false)
    setFmcRctSimbol(false)
    setPocSimbol(false)
    setPocRctSimbol(false)
    setRrxSimbol(false)
    setMisSimbol(false)
    setIpxPocSimbol(false)
    setMebSimbol(false)
    setPobSimbol(false)
    setMisPrdSimbol(false)
    setMisFldSimbol(false)
    setSouSimbol(false)
    setNonSimbol(false)
    setUneSimbol(false)
    setPreSimbol(false)
    setAnoSimbol(true)
  };
  
  const [form, setForm] = useState({
    id_odontogram: '',
    id_patient: '',
    id_doctor : '',
    date_check : '',
    tooch_number : '',
    qty_photo : '',
    photo_type : '',
    qty_rontgen_photo : '',
    rontgen_photo_type : '',
    occlusion : '',
    torus_palatinus : '',
    torus_mandibularis : '',
    palatum : '',
    diastema : '',
    diastema_note : '',
    anomaly : '',
    anomaly_note : '',
    other : '',
    decayed : '',
    missing : '',
    filled: '',
    tooch_position_pob: '',
    tooch_position_meb:''
  });

  useEffect(() => {
    setForm({
      ...form, 
      id_odontogram: `${getData.length === 0 || getData?.id === null || getData?.id === "null" ? "" : getData?.id}`,
      id_patient: `${getData.length === 0 || getData?.id_patient === null || getData?.id_patient === "null" ? "" : getData?.id_patient}`,
      id_doctor : `${getData.length === 0 || getData?.id_doctor === null || getData?.id_doctor === "null" ? "" : getData?.id_doctor}`,
      date_check : `${getData.length === 0 || getData?.date_check === null || getData?.date_check === "null" ? "" : getData?.date_check}`,
      tooch_number : `${getData.length === 0 || getData?.tooch_number === null || getData?.tooch_number === "null" ? "" :  getData.tooch_number
      .split(" ")
      .map(item => item ? ` ${item}` : "")
      .join("")}`,
      qty_photo : `${getData.length === 0 || getData?.qty_photo === null || getData?.qty_photo === "null" ? "" : getData?.qty_photo}`,
      photo_type : `${getData.length === 0 || getData?.photo_type === null || getData?.photo_type === "null" ? "" : getData?.photo_type}`,
      qty_rontgen_photo : `${getData.length === 0 || getData?.qty_rontgen_photo === null || getData?.qty_rontgen_photo === "null" ? "" : getData?.qty_rontgen_photo}`,
      rontgen_photo_type : `${getData.length === 0 || getData?.rontgen_photo_type === null || getData?.rontgen_photo_type === "null" ? "" : getData?.rontgen_photo_type}`,
      occlusion : `${getData.length === 0 || getData?.occlusion === null || getData?.occlusion === "null" ? "" : getData?.occlusion}`,
      torus_palatinus : `${getData.length === 0 || getData?.torus_palatinus === null || getData?.torus_palatinus === "null" ? "" : getData?.torus_palatinus}`,
      torus_mandibularis : `${getData.length === 0 || getData?.torus_mandibularis === null || getData?.torus_mandibularis === "null" ? "" : getData?.torus_mandibularis}`,
      palatum : `${getData.length === 0 || getData?.palatum === null || getData?.palatum === "null" ? "" : getData?.palatum}`,
      diastema : `${getData.length === 0 || getData?.diastema === null || getData?.diastema === "null" ? "" : getData?.diastema}`,
      diastema_note : `${getData.length === 0 || getData?.diastema_note === null || getData?.diastema_note === "null" ? "" : getData?.diastema_note}`,
      anomaly : `${getData.length === 0 || getData?.anomaly === null || getData?.anomaly === "null" ? "" : getData?.anomaly}`,
      anomaly_note : `${getData.length === 0 || getData?.anomaly_note === null || getData?.anomaly_note === "null" ? "" : getData?.anomaly_note}`,
      other : `${getData.length === 0 || getData?.other === null || getData?.other === "null" ? "" : getData?.other}`,
      decayed : `${getData.length === 0 || getData?.decayed === null || getData?.decayed === "null" ? "" : getData?.decayed}`,
      missing : `${getData.length === 0 || getData?.missing === null || getData?.missing === "null" ? "" : getData?.missing}`,
      filled : `${getData.length === 0 || getData?.filled === null || getData?.filled === "null" ? "" : getData?.filled}`
    });
    
  }, [getData])
  
  console.log(form);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangePositionTouchPob = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangePositionTouchMeb = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangePob = (e) => {
    const polygon = e.target;
  // Dapatkan nomor gigi dari elemen yang diklik
  let nomorGigi = polygon.getAttribute('data-nomor-gigi')
    // Periksa apakah nomor gigi sudah ada dalam form.tooch_number
    if (form.tooch_number.includes(nomorGigi)) {
      // Nomor gigi sudah ada, maka hilangkan dari form.tooch_number beserta spasi
      setForm({
        ...form,
        tooch_number: form.tooch_number.replace(nomorGigi, ""),
      });
    } else {
      // Nomor gigi belum ada, maka tambahkan ke form.tooch_number beserta spasi
      setForm({
        ...form,
        tooch_number: form.tooch_number + nomorGigi,
      });
    }
  }

  const handleChangeMeb = (e) => {
    const polygon = e.target;
  // Dapatkan nomor gigi dari elemen yang diklik
  let nomorGigi = polygon.getAttribute('data-nomor-gigi')
    // Periksa apakah nomor gigi sudah ada dalam form.tooch_number
    if (form.tooch_number.includes(nomorGigi)) {
      // Nomor gigi sudah ada, maka hilangkan dari form.tooch_number beserta spasi
      setForm({
        ...form,
        tooch_number: form.tooch_number.replace(nomorGigi, ""),
      });
    } else {
      // Nomor gigi belum ada, maka tambahkan ke form.tooch_number beserta spasi
      setForm({
        ...form,
        tooch_number: form.tooch_number + nomorGigi,
      });
    }
  }

  const onclikTooch = (e) => {
    if (
      amfSimbol === true || cofSimbol === true || fisSimbol === true || nvtSimbol === true ||
      rctSimbol === true || carSimbol === true || cfrSimbol === true || amfRctSimbol === true ||
      cofRctSimbol === true || fmcSimbol === true || fmcRctSimbol === true || pocSimbol === true || pocRctSimbol === true ||
      rrxSimbol === true || misSimbol === true || ipxPocSimbol === true || misPrdSimbol === true ||
      misFldSimbol === true || souSimbol === true || nonSimbol === true || uneSimbol === true ||
      preSimbol === true || anoSimbol === true
    ) {
      handlePolygonClick(e);
    } else {
      Swal.fire(
        'Pilih Simbol',
        'Mohon Periksa',
        'warning'
      );
    }
  };

  const handlePolygonClick = (e) => {
    const polygon = e.target;
    // const newFillColor = polygon.style.fill === 'black' ? 'white' : 'black';

    // polygon.style.fill = newFillColor;

    // Dapatkan nomor gigi dari elemen yang diklik
    const simbolSuffix = 
    amfSimbol ? "-amf" : cofSimbol ? "-cof" : fisSimbol ? "-fis" : carSimbol ? "-car" :
    amfRctSimbol ? "-amf-rct" : cofRctSimbol ? "-cof-rct" : mebSimbol ? "-meb" : pobSimbol ? "-pob" :
    "";

  // Dapatkan nomor gigi dari elemen yang diklik
    let nomorGigi = polygon.getAttribute('data-nomor-gigi') + simbolSuffix;
    
    // Memotong bagian tertentu dari nomor gigi jika nvtSimbol adalah true

  if (nvtSimbol && /[OPBMLD]/.test(nomorGigi)) {
    nomorGigi = nomorGigi.split(/[OPBMLD]/).join('') + "-nvt";
  }
    
  if (rctSimbol && /[OPBMLD]/.test(nomorGigi)) {
    nomorGigi = nomorGigi.split(/[OPBMLD]/).join('') + "-rct";
  }
    
  if (nonSimbol && /[OPBMLD]/.test(nomorGigi)) {
    nomorGigi = nomorGigi.split(/[OPBMLD]/).join('') + "-non";
  }
    
  if (uneSimbol && /[OPBMLD]/.test(nomorGigi)) {
    nomorGigi = nomorGigi.split(/[OPBMLD]/).join('') + "-une";
  }
    
  if (preSimbol && /[OPBMLD]/.test(nomorGigi)) {
    nomorGigi = nomorGigi.split(/[OPBMLD]/).join('') + "-pre";
  }

  if (anoSimbol && /[OPBMLD]/.test(nomorGigi)) {
    nomorGigi = nomorGigi.split(/[OPBMLD]/).join('') + "-ano";
  }
    
  if (cfrSimbol && /[OPBMLD]/.test(nomorGigi)) {
    nomorGigi = nomorGigi.split(/[OPBMLD]/).join('') + "-cfr";
  }
    
  if (fmcSimbol && /[OPBMLD]/.test(nomorGigi)) {
    nomorGigi = nomorGigi.split(/[OPBMLD]/).join('') + "-fmc";
  }
    
  if (fmcRctSimbol && /[OPBMLD]/.test(nomorGigi)) {
    nomorGigi = nomorGigi.split(/[OPBMLD]/).join('') + "-fmc-rct";
  }
    
  if (pocSimbol && /[OPBMLD]/.test(nomorGigi)) {
    nomorGigi = nomorGigi.split(/[OPBMLD]/).join('') + "-poc";
  }
    
  if (pocRctSimbol && /[OPBMLD]/.test(nomorGigi)) {
    nomorGigi = nomorGigi.split(/[OPBMLD]/).join('') + "-poc-rct";
  }
    
  if (rrxSimbol && /[OPBMLD]/.test(nomorGigi)) {
    nomorGigi = nomorGigi.split(/[OPBMLD]/).join('') + "-rrx";
  }
    
  if (misSimbol && /[OPBMLD]/.test(nomorGigi)) {
    nomorGigi = nomorGigi.split(/[OPBMLD]/).join('') + "-mis";
  }

  if (misPrdSimbol && /[OPBMLD]/.test(nomorGigi)) {
    nomorGigi = nomorGigi.split(/[OPBMLD]/).join('') + "-mis-prd";
  }

  if (misFldSimbol && /[OPBMLD]/.test(nomorGigi)) {
    nomorGigi = nomorGigi.split(/[OPBMLD]/).join('') + "-mis-fld";
  }

  if (ipxPocSimbol && /[OPBMLD]/.test(nomorGigi)) {
    nomorGigi = nomorGigi.split(/[OPBMLD]/).join('') + "-ipx-poc";
  }
    // Periksa apakah nomor gigi sudah ada dalam form.tooch_number
    if (form.tooch_number.includes(nomorGigi)) {
      // Nomor gigi sudah ada, maka hilangkan dari form.tooch_number beserta spasi
      setForm({
        ...form,
        tooch_number: form.tooch_number.replace(nomorGigi, ""),
      });
    } else {
      // Nomor gigi belum ada, maka tambahkan ke form.tooch_number beserta spasi
      setForm({
        ...form,
        tooch_number: form.tooch_number + nomorGigi,
      });
    }
  };

  const handleSubmit = useMutation(async (e) => {
  try {
    e.preventDefault();
    setLoading(true)
  
    // Insert data for login process
    const response = await API.post(`emr-service/odontogram-news/store`,
      {
        id_patient: `${id}`,
        id_doctor: form?.id_doctor,
        date_check: form?.date_check,
        tooch_number: form?.tooch_number,
        qty_photo: form?.qty_photo,
        photo_type: form?.photo_type,
        qty_rontgen_photo: form?.qty_rontgen_photo,
        rontgen_photo_type: form?.rontgen_photo_type,
        occlusion: form?.occlusion,
        torus_palatinus: form?.torus_palatinus,
        torus_mandibularis: form?.torus_mandibularis,
        palatum: form?.palatum,
        diastema: form?.diastema,
        diastema_note: form?.diastema_note,
        anomaly: form?.anomaly,
        anomaly_note: form?.anomaly_note,
        other: form?.other,
        decayed: form?.decayed,
        missing: form?.missing,
        filled: form?.filled,
      }, fetchParams);

      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        })
        GetResponseData()
        setLoading(false)
      }

      }catch (error) {
        setLoading(false)
        swal({
          title: 'Failed',
          text: `${error.response.data.message}`,
          icon: 'error',
          timer: 3000,
          buttons: false
        });
      }
  });

  const handleSubmitUpdate = useMutation(async (e) => {
    try {
      e.preventDefault();
      setLoading(true)
  
      // Insert data for login process
      const response = await API.put(`emr-service/odontogram-news/${form.id_odontogram}`, {
      id_patient: form?.id_patient,
      id_doctor: form?.id_doctor,
      date_check: form?.date_check,
      tooch_number: form?.tooch_number,
      qty_photo: form?.qty_photo,
      photo_type: form?.photo_type,
      qty_rontgen_photo: form?.qty_rontgen_photo,
      rontgen_photo_type: form?.rontgen_photo_type,
      occlusion: form?.occlusion,
      torus_palatinus: form?.torus_palatinus,
      torus_mandibularis: form?.torus_mandibularis,
      palatum: form?.palatum,
      diastema: form?.diastema,
      diastema_note: form?.diastema_note,
      anomaly: form?.anomaly,
      anomaly_note: form?.anomaly_note,
      other: form?.other,
      decayed: form?.decayed,
      missing: form?.missing,
      filled: form?.filled,
      }, fetchParams);
  
      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 3000,
          buttons: false
        });
        GetResponseData()
        setLoading(false)
      }
    } catch (error) {
      // setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
      setLoading(false)
    }
  });

  const deleteById = async (id) => {
    Swal.fire({
      title: 'Apakah Kamu Yakin?',
      text: `Menghapus data ini`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus'
    }).then( async (result) => {
      if(result.isConfirmed) {
        const response =  await API.delete(`emr-service/med-attentions/${id}`,fetchParams);
        if (response.data.error == false) {
          // GetResponseData()
          swal({
            title: 'Success',
            text: "Your data has been successfully deleted",
            icon: 'success',
            timer: 3000,
            buttons: false
          });
        }  
      }
    })
  };

  const changePage = ({ selected }) => {
    setPage(selected+1);
    if (selected === 10) {
      setMsg(
        ""
      );
    } else {
      setMsg("");
    }
  };
 
  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setMsg("");
    setKeyword(query);
  };

  const navigateHome = () => {
    navigate('/patients');
  }

  const buttonRefresh = () => {
    window.location.reload();
  }
  
  let printoutOdontogram = async () => {
    navigate('/medical-record-odontogram/'+ id )
  };

  const dataGigiAtas = [18, 17, 16, 15, 14]; 

  return (
  <div style={{ backgroundColor: "white", marginTop: "15px", marginLeft: "10px", marginRight: "10px", boxShadow: "2px 2px 10px #BFBFBF" }}>
    {modalAdd  && <ModalAddMedis codeGenerata={codeGenerata} idModalAdd={idModalAdd} show={modalAdd} onHide={() => setModalAdd(false)} />}
    {modalUpdate && <ModalUpdateMedis idModal={idModal} show={modalUpdate} onHide={() => setModalUpdate(false)} />}
    {loading && <LoaderHome />}
   
    <div style={{ paddingLeft: "0px", width: "100%", borderBottom: "2px solid #EEEEEE", display: "flex", padding: "10px 0px", backgroundColor:"#409AFF" }}>
      <div style={{flex:"50%",fontSize:"16px",display:"flex", aunlocklignItems:"center", paddingLeft:"10px", color:"white"}}>
          Odontogram || {first_name} {last_name}
        </div>
        <div style={{ flex: "50%", display: "flex", justifyContent: "end" }}>
        {getData.length === 0? 
             <></>
            :
            <div onClick={printoutOdontogram} style={{display:"flex",alignItems:"center",marginRight:"15px",backgroundColor:"#FF9A00",color:"white",padding:"0px 10px", borderRadius:"3px", cursor:"pointer", fontSize:"12px"}}>
            <FaPrint style={{marginRight:"3px"}}/>
            Cetak
          </div>
          }
          {/* <div onClick={printoutOdontogram} style={{display:"flex",alignItems:"center",marginRight:"15px",backgroundColor:"#FF9A00",color:"white",padding:"0px 10px", borderRadius:"3px", cursor:"pointer", fontSize:"12px"}}>
            <FaPrint style={{marginRight:"3px"}}/>
            Cetak
          </div> */}
      </div>
    </div>
      
      
    <Col className="mt-2" xl='12' sm='12' style={{padding:"0px 0px", fontSize:"12px"}} >
      {/* Gigi Atas Dewasa */}
      <div style={{ display: "flex", height:"18vh", alignItems:"start"}}> 
        <RahangAtasKananDewasa form={form} setForm={setForm} onclikTooch={onclikTooch}/>
        <RahangAtasKiriDewasa form={form} setForm={setForm} onclikTooch={onclikTooch}/>
      </div>

      {/* Gigi Atas Anak */}
      <div style={{ display: "flex", height:"18vh", alignItems:"start"}}> 
        <RahangAtasKananAnak form={form} setForm={setForm} onclikTooch={onclikTooch}/>
        <RahangAtasKiriAnak form={form} setForm={setForm} onclikTooch={onclikTooch}/>
      </div>

      {/* Gigi Bawah Anak */}
      <div style={{ display: "flex", height:"18vh", alignItems:"start"}}> 
        <RahangBawahKananAnak form={form} setForm={setForm} onclikTooch={onclikTooch}/>
        <RahangBawahKiriAnak form={form} setForm={setForm} onclikTooch={onclikTooch}/>
      </div>

      {/* Gigi Bawah Dewasa */}
      <div style={{ display: "flex", height:"18vh", alignItems:"start"}}> 
        <RahangBawahKananDewasa form={form} setForm={setForm} onclikTooch={onclikTooch}/>
        <RahangBawahKiriDewasa form={form} setForm={setForm} onclikTooch={onclikTooch}/>
      </div>
    </Col>  

    <Col className="mt-4" xl='12' sm='12' style={{ padding: "10px 0px" }} >
      <div style={{ paddingLeft: "0px", width: "100%", display: "flex", justifyContent: "center", padding: "10px 0px" }}>
        <div onClick={addAmf} style={{border:"1px solid black", padding:"5px 5px 10px 5px", borderRadius:"3px", backgroundColor: amfSimbol === true? "#83FFF8" : "" , margin:"3px",cursor:"pointer"}}>
            <div style={{display:"flex", justifyContent:"center"}}>amf</div>  
            <div style={{display:"flex", justifyContent:"center"}}>
              <img src={logo_amf} style={{width:"40px", height:"40px"}} ></img>   
            </div>
        </div>

        <div onClick={addCof} style={{border:"1px solid black", padding:"5px 5px 10px 5px", borderRadius:"3px", backgroundColor: cofSimbol === true? "#83FFF8" : "" , margin:"3px",cursor:"pointer"}}>
            <div style={{display:"flex", justifyContent:"center"}}>cof</div>  
            <div style={{display:"flex", justifyContent:"center"}}>
              <img src={logo_cof} style={{width:"40px", height:"40px"}} ></img>   
            </div>
        </div>

        <div onClick={addFis} style={{border:"1px solid black", padding:"5px 5px 10px 5px", borderRadius:"3px", backgroundColor: fisSimbol === true? "#83FFF8" : "" , margin:"3px",cursor:"pointer"}}>
            <div style={{display:"flex", justifyContent:"center"}}>fis</div>  
            <div style={{display:"flex", justifyContent:"center"}}>
              <img src={logo_fis} style={{width:"40px", height:"40px"}} ></img>   
            </div>
        </div>

        
        <div onClick={addNvt} style={{border:"1px solid black", padding:"5px 5px 10px 5px", borderRadius:"3px", backgroundColor: nvtSimbol === true? "#83FFF8" : "" , margin:"3px",cursor:"pointer"}}>
            <div style={{display:"flex", justifyContent:"center"}}>nvt</div>  
            <div style={{display:"flex", justifyContent:"center"}}>
              <img src={logo_nvt} style={{width:"40px", height:"40px"}} ></img>   
            </div>
        </div>

        <div onClick={addRct} style={{border:"1px solid black", padding:"5px 5px 10px 5px", borderRadius:"3px", backgroundColor: rctSimbol === true? "#83FFF8" : "" , margin:"3px",cursor:"pointer"}}>
            <div style={{display:"flex", justifyContent:"center"}}>rct</div>  
            <div style={{display:"flex", justifyContent:"center"}}>
              <img src={logo_rct} style={{width:"40px", height:"40px"}} ></img>   
            </div>
        </div>

        <div onClick={addNon} style={{border:"1px solid black", padding:"5px 5px 10px 5px", borderRadius:"3px", backgroundColor: nonSimbol === true? "#83FFF8" : "" , margin:"3px",cursor:"pointer"}}>
            <div style={{display:"flex", justifyContent:"center"}}>non</div>  
            <div style={{display:"flex", justifyContent:"center"}}>
              <img src={logo_sou} style={{width:"40px", height:"40px"}} ></img>   
            </div>
        </div>

        <div onClick={addUne} style={{border:"1px solid black", padding:"5px 5px 10px 5px", borderRadius:"3px", backgroundColor: uneSimbol === true? "#83FFF8" : "" , margin:"3px",cursor:"pointer"}}>
            <div style={{display:"flex", justifyContent:"center"}}>une</div>  
            <div style={{display:"flex", justifyContent:"center"}}>
              <img src={logo_sou} style={{width:"40px", height:"40px"}} ></img>   
            </div>
        </div>

        <div onClick={addPre} style={{border:"1px solid black", padding:"5px 5px 10px 5px", borderRadius:"3px", backgroundColor: preSimbol === true? "#83FFF8" : "" , margin:"3px",cursor:"pointer"}}>
            <div style={{display:"flex", justifyContent:"center"}}>pre</div>  
            <div style={{display:"flex", justifyContent:"center"}}>
              <img src={logo_sou} style={{width:"40px", height:"40px"}} ></img>   
            </div>
        </div>

        <div onClick={addAno} style={{border:"1px solid black", padding:"5px 5px 10px 5px", borderRadius:"3px", backgroundColor: anoSimbol === true? "#83FFF8" : "" , margin:"3px",cursor:"pointer"}}>
            <div style={{display:"flex", justifyContent:"center"}}>ano</div>  
            <div style={{display:"flex", justifyContent:"center"}}>
              <img src={logo_sou} style={{width:"40px", height:"40px"}} ></img>   
            </div>
        </div>

        <div onClick={addCar} style={{border:"1px solid black", padding:"5px 5px 10px 5px", borderRadius:"3px", backgroundColor: carSimbol === true? "#83FFF8" : "" , margin:"3px",cursor:"pointer"}}>
            <div style={{display:"flex", justifyContent:"center"}}>car</div>  
            <div style={{display:"flex", justifyContent:"center"}}>
              <img src={logo_car} style={{width:"40px", height:"40px"}} ></img>   
            </div>
        </div>
      </div>

      <div style={{ paddingLeft: "0px", width: "100%", display: "flex", justifyContent: "center", padding: "10px 0px" }}>
<div onClick={addCfr} style={{border:"1px solid black", padding:"5px 5px 10px 5px", borderRadius:"3px", backgroundColor: cfrSimbol === true? "#83FFF8" : "" , margin:"3px",cursor:"pointer"}}>
    <div style={{display:"flex", justifyContent:"center"}}>cfr</div>  
    <div style={{display:"flex", justifyContent:"center"}}>
      <img src={logo_cfr} style={{width:"40px", height:"40px"}} ></img>   
    </div>
</div>

<div onClick={addAmfRct} style={{border:"1px solid black", padding:"5px 5px 10px 5px", borderRadius:"3px", backgroundColor: amfRctSimbol === true? "#83FFF8" : "" , margin:"3px",cursor:"pointer"}}>
    <div style={{display:"flex", justifyContent:"center"}}>amf-rct</div>  
    <div style={{display:"flex", justifyContent:"center"}}>
      <img src={logo_amf_rct} style={{width:"40px", height:"40px"}} ></img>   
    </div>
</div>

<div onClick={addCofRct} style={{border:"1px solid black", padding:"5px 5px 10px 5px", borderRadius:"3px", backgroundColor: cofRctSimbol === true? "#83FFF8" : "" , margin:"3px",cursor:"pointer"}}>
    <div style={{display:"flex", justifyContent:"center"}}>cof-rct</div>  
    <div style={{display:"flex", justifyContent:"center"}}>
      <img src={logo_cof_rct} style={{width:"40px", height:"40px"}} ></img>   
    </div>
</div>

<div onClick={addFmc} style={{border:"1px solid black", padding:"5px 5px 10px 5px", borderRadius:"3px", backgroundColor: fmcSimbol === true? "#83FFF8" : "" , margin:"3px",cursor:"pointer"}}>
    <div style={{display:"flex", justifyContent:"center"}}>fmc</div>  
    <div style={{display:"flex", justifyContent:"center"}}>
      <img src={logo_fmc} style={{width:"40px", height:"40px"}} ></img>   
    </div>
</div>

<div onClick={addFmcRct} style={{border:"1px solid black", padding:"5px 5px 10px 5px", borderRadius:"3px", backgroundColor: fmcRctSimbol === true? "#83FFF8" : "" , margin:"3px",cursor:"pointer"}}>
    <div style={{display:"flex", justifyContent:"center"}}>fmc-rct</div>  
    <div style={{display:"flex", justifyContent:"center"}}>
      <img src={logo_fmc_rct} style={{width:"40px", height:"40px"}} ></img>   
    </div>
</div>

<div onClick={addPoc} style={{border:"1px solid black", padding:"5px 5px 10px 5px", borderRadius:"3px", backgroundColor: pocSimbol === true? "#83FFF8" : "" , margin:"3px",cursor:"pointer"}}>
    <div style={{display:"flex", justifyContent:"center"}}>poc</div>  
    <div style={{display:"flex", justifyContent:"center"}}>
      <img src={logo_poc} style={{width:"40px", height:"40px"}} ></img>   
    </div>
</div>

<div onClick={addPocRct} style={{border:"1px solid black", padding:"5px 5px 10px 5px", borderRadius:"3px", backgroundColor: pocRctSimbol === true? "#83FFF8" : "" , margin:"3px",cursor:"pointer"}}>
    <div style={{display:"flex", justifyContent:"center"}}>poc-rct</div>  
    <div style={{display:"flex", justifyContent:"center"}}>
      <img src={logo_poc_rct} style={{width:"40px", height:"40px"}} ></img>   
    </div>
</div>

<div onClick={addRrx} style={{border:"1px solid black", padding:"5px 5px 10px 5px", borderRadius:"3px", backgroundColor: rrxSimbol === true? "#83FFF8" : "" , margin:"3px",cursor:"pointer"}}>
    <div style={{display:"flex", justifyContent:"center"}}>rrx</div>  
    <div style={{display:"flex", justifyContent:"center"}}>
      <img src={logo_rrx} style={{width:"40px", height:"40px"}} ></img>   
    </div>
</div>

<div onClick={addMis} style={{border:"1px solid black", padding:"5px 5px 10px 5px", borderRadius:"3px", backgroundColor: misSimbol === true? "#83FFF8" : "" , margin:"3px",cursor:"pointer"}}>
    <div style={{display:"flex", justifyContent:"center"}}>mis</div>  
    <div style={{display:"flex", justifyContent:"center"}}>
      <img src={logo_mis} style={{width:"40px", height:"40px"}} ></img>   
    </div>
</div>

<div onClick={addIpxPoc} style={{border:"1px solid black", padding:"5px 5px 10px 5px", borderRadius:"3px", backgroundColor: ipxPocSimbol === true? "#83FFF8" : "" , margin:"3px",cursor:"pointer"}}>
    <div style={{display:"flex", justifyContent:"center"}}>ipx-poc</div>  
    <div style={{display:"flex", justifyContent:"center"}}>
      <img src={logo_ipx_poc} style={{width:"40px", height:"40px"}} ></img>   
    </div>
</div>

      </div>
        {/* <div className="dropdown-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}> */}
      <div style={{ paddingLeft: "0px", width: "100%", display: "flex", justifyContent: "center", padding: "10px 0px" }}>

      {pobSimbol === true? 
        <div style={{padding:"5px 5px 10px 5px", borderRadius:"3px", backgroundColor: addPob === true? "#83FFF8" : "" , margin:"3px", border:"1px solid black"}}>
          <div style={{display:"flex", justifyContent:"center"}}>pob</div>  
          <div style={{display:"flex", justifyContent:"center"}}>
            <img src={logo_ipx_poc} style={{width:"40px", height:"40px"}} ></img>   
          </div>
              
          <div className="mt-2" style={{display:"flex", justifyContent:"center"}}>
            <select className="form-select" aria-label="Default select example" type='text' value={form?.tooch_position_pob} name="tooch_position_pob" onChange={handleChangePositionTouchPob} style={{ textAlign:"", cursor:"pointer", width:"105px"}}>
              <option value="" hidden>Select Position</option>
              <option value="rahang_atas_kanan_dewasa" style={{textAlign:""}}>Rahang Atas Kanan Dewasa</option>
              <option value="rahang_atas_kiri_dewasa" style={{textAlign:""}}>Rahang Atas Kiri Dewasa</option>
              <option value="rahang_atas_kanan_anak" style={{textAlign:""}}>Rahang Atas Kanan Anak</option>
              <option value="rahang_atas_kiri_anak" style={{textAlign:""}}>Rahang Atas Kiri Anak</option>
              <option value="rahang_bawah_kanan_anak" style={{textAlign:""}}>Rahang Bawah Kanan Anak</option>
              <option value="rahang_bawah_kiri_anak" style={{textAlign:""}}>Rahang Bawah Kiri Anak</option>
              <option value="rahang_bawah_kanan_dewasa" style={{textAlign:""}}>Rahang Bawah Kanan Dewasa</option>  
              <option value="rahang_bawah_kiri_dewasa" style={{textAlign:""}}>Rahang Bawah Kiri Dewasa</option>                                 
            </select>
          </div>
              
          <div className="mt-2" style={{display:"flex", justifyContent:"center"}}>
            <div className="dropdown-container">
            <button className="btn btn-primary">Nomor Gigi</button>
              {form?.tooch_position_pob.includes("rahang_atas_kanan_dewasa") && (
              <ul className="dropdown-menu">
                <li className="submenu">
                  <a href="#">18</a>
                  <ul className="sub-menu" style={{top:"8px"}}>
                  <li><a data-nomor-gigi=" 18-pob-17-miss-pon-pob-16-pob" onClick={handleChangePob}>18-16</a></li>
                  <li><a data-nomor-gigi=" 18-pob-17-miss-pon-pob-16-miss-pon-pob-15-pob" onClick={handleChangePob}>18-15</a></li>
                  <li><a data-nomor-gigi=" 18-pob-17-miss-pon-pob-16-miss-pon-pob-15-miss-pon-pob-14-pob" onClick={handleChangePob}>18-14</a></li>
                  <li><a data-nomor-gigi=" 18-pob-17-miss-pon-pob-16-miss-pon-pob-15-miss-pon-pob-14-miss-pon-pob-13-pob" onClick={handleChangePob}>18-13</a></li>
                  <li><a data-nomor-gigi=" 18-pob-17-miss-pon-pob-16-miss-pon-pob-15-miss-pon-pob-14-miss-pon-pob-13-miss-pon-pob-12-pob" onClick={handleChangePob}>18-12</a></li>
                  <li><a data-nomor-gigi=" 18-pob-17-miss-pon-pob-16-miss-pon-pob-15-miss-pon-pob-14-miss-pon-pob-13-miss-pon-pob-12-miss-pon-pob-11-pob" onClick={handleChangePob}>18-11</a></li>
                  </ul>
                </li>
                <li className="submenu">
                  <a href="#">17</a>
                  <ul className="sub-menu" style={{top:"32px"}}>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 17-pob-16-miss-pon-pob-15-pob">17-15</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 17-pob-16-miss-pon-pob-15-miss-pon-pob-14-pob">17-14</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 17-pob-16-miss-pon-pob-15-miss-pon-pob-14-miss-pon-pob-13-pob">17-13</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 17-pob-16-miss-pon-pob-15-miss-pon-pob-14-miss-pon-pob-13-miss-pon-pob-12-pob">17-12</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 17-pob-16-miss-pon-pob-15-miss-pon-pob-14-miss-pon-pob-13-miss-pon-pob-12-miss-pon-pob-11-pob">17-11</a></li>
                  </ul>
                </li>
                <li className="submenu">
                  <a href="#">16</a>
                  <ul className="sub-menu" style={{top:"56px"}}>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 16-pob-15-miss-pon-pob-14-pob">16-14</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 16-pob-15-miss-pon-pob-14-miss-pon-pob-13-pob">16-13</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 16-pob-15-miss-pon-pob-14-miss-pon-pob-13-miss-pon-pob-12-pob">16-12</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 16-pob-15-miss-pon-pob-14-miss-pon-pob-13-miss-pon-pob-12-miss-pon-pob-11-pob">16-11</a></li>
                  </ul>
                </li>
                <li className="submenu">
                  <a href="#">15</a>
                  <ul className="sub-menu" style={{top:"80px"}}>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 15-pob-14-miss-pon-pob-13-pob">15-13</a></li>
                    <li><a onClick={handleChangePob} data-nomor-gigi=" 15-pob-14-miss-pon-pob-13-miss-pon-pob-12-pob">15-12</a></li>
                    <li><a onClick={handleChangePob} data-nomor-gigi=" 15-pob-14-miss-pon-pob-13-miss-pon-pob-12-miss-pon-pob-11-pob">15-11</a></li>
                  </ul>
                    </li>
                    <li className="submenu">
                  <a href="#">14</a>
                  <ul className="sub-menu" style={{top:"104px"}}>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 14-pob-13-miss-pon-pob-12-pob">14-12</a></li>
                    <li><a onClick={handleChangePob} data-nomor-gigi=" 14-pob-13-miss-pon-pob-12-miss-pon-pob-11-pob">14-11</a></li>
                  </ul>
                    </li>
                    <li className="submenu">
                  <a href="#">13</a>
                  <ul className="sub-menu" style={{top:"128px"}}>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 13-pob-12-miss-pon-pob-11-pob">13-11</a></li>
                  </ul>
                </li>
              </ul>
                )}
                
              {form?.tooch_position_pob.includes("rahang_atas_kiri_dewasa") && (
              <ul className="dropdown-menu">
                <li className="submenu">
                  <a href="#">21</a>
                  <ul className="sub-menu" style={{top:"8px"}}>
                  <li><a data-nomor-gigi=" 21-pob-22-miss-pon-pob-23-pob" onClick={handleChangePob}>21-23</a></li>
                  <li><a data-nomor-gigi=" 21-pob-22-miss-pon-pob-23-miss-pon-pob-24-pob" onClick={handleChangePob}>21-24</a></li>
                  <li><a data-nomor-gigi=" 21-pob-22-miss-pon-pob-23-miss-pon-pob-24-miss-pon-pob-25-pob" onClick={handleChangePob}>21-25</a></li>
                  <li><a data-nomor-gigi=" 21-pob-22-miss-pon-pob-23-miss-pon-pob-24-miss-pon-pob-25-miss-pon-pob-26-pob" onClick={handleChangePob}>21-26</a></li>
                  <li><a data-nomor-gigi=" 21-pob-22-miss-pon-pob-23-miss-pon-pob-24-miss-pon-pob-25-miss-pon-pob-26-miss-pon-pob-27-pob" onClick={handleChangePob}>21-27</a></li>
                  <li><a data-nomor-gigi=" 21-pob-22-miss-pon-pob-23-miss-pon-pob-24-miss-pon-pob-25-miss-pon-pob-26-miss-pon-pob-27-miss-pon-pob-28-pob" onClick={handleChangePob}>21-28</a></li>
                  </ul>
                </li>
                <li className="submenu">
                  <a href="#">22</a>
                  <ul className="sub-menu" style={{top:"32px"}}>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 22-pob-23-miss-pon-pob-24-pob">22-24</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 22-pob-23-miss-pon-pob-24-miss-pon-pob-25-pob">22-25</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 22-pob-23-miss-pon-pob-24-miss-pon-pob-25-miss-pon-pob-26-pob">22-26</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 22-pob-23-miss-pon-pob-24-miss-pon-pob-25-miss-pon-pob-26-miss-pon-pob-27-pob">22-27</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 22-pob-23-miss-pon-pob-24-miss-pon-pob-25-miss-pon-pob-26-miss-pon-pob-27-miss-pon-pob-28-pob">22-28</a></li>
                  </ul>
                </li>
                <li className="submenu">
                  <a href="#">23</a>
                  <ul className="sub-menu" style={{top:"56px"}}>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 23-pob-24-miss-pon-pob-25-pob">23-25</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 23-pob-24-miss-pon-pob-25-miss-pon-pob-26-pob">23-26</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 23-pob-24-miss-pon-pob-25-miss-pon-pob-26-miss-pon-pob-27-pob">23-27</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 23-pob-24-miss-pon-pob-25-miss-pon-pob-26-miss-pon-pob-27-miss-pon-pob-28-pob">23-28</a></li>
                  </ul>
                </li>
                <li className="submenu">
                  <a href="#">24</a>
                  <ul className="sub-menu" style={{top:"80px"}}>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 24-pob-25-miss-pon-pob-26-pob">24-26</a></li>
                    <li><a onClick={handleChangePob} data-nomor-gigi=" 24-pob-25-miss-pon-pob-26-miss-pon-pob-27-pob">24-27</a></li>
                    <li><a onClick={handleChangePob} data-nomor-gigi=" 24-pob-25-miss-pon-pob-26-miss-pon-pob-27-miss-pon-pob-28-pob">24-28</a></li>
                  </ul>
                    </li>
                    <li className="submenu">
                  <a href="#">25</a>
                  <ul className="sub-menu" style={{top:"104px"}}>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 25-pob-26-miss-pon-pob-27-pob">25-27</a></li>
                    <li><a onClick={handleChangePob} data-nomor-gigi=" 25-pob-26-miss-pon-pob-27-miss-pon-pob-28-pob">25-28</a></li>
                  </ul>
                    </li>
                    <li className="submenu">
                  <a href="#">26</a>
                  <ul className="sub-menu" style={{top:"128px"}}>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 26-pob-27-miss-pon-pob-28-pob">26-28</a></li>
                  </ul>
                </li>
              </ul>
                )}
                
              {form?.tooch_position_pob.includes("rahang_atas_kanan_anak") && (
              <ul className="dropdown-menu">
                <li className="submenu">
                  <a href="#">55</a>
                  <ul className="sub-menu" style={{top:"8px"}}>
                  <li><a data-nomor-gigi=" 55-pob-54-miss-pon-pob-53-pob" onClick={handleChangePob}>55-53</a></li>
                  <li><a data-nomor-gigi=" 55-pob-54-miss-pon-pob-53-miss-pon-pob-52-pob" onClick={handleChangePob}>55-52</a></li>
                  <li><a data-nomor-gigi=" 55-pob-54-miss-pon-pob-53-miss-pon-pob-52-miss-pon-pob-51-pob" onClick={handleChangePob}>55-51</a></li>
                  </ul>
                </li>
                <li className="submenu">
                  <a href="#">54</a>
                  <ul className="sub-menu" style={{top:"32px"}}>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 54-pob-53-miss-pon-pob-52-pob">54-52</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 54-pob-53-miss-pon-pob-52-miss-pon-pob-51-pob">54-51</a></li>
                  </ul>
                </li>
                <li className="submenu">
                  <a href="#">53</a>
                  <ul className="sub-menu" style={{top:"56px"}}>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 53-pob-52-miss-pon-pob-51-pob">53-51</a></li>
                  </ul>
                </li>
            
              </ul>
                )}

              {form?.tooch_position_pob.includes("rahang_atas_kiri_anak") && (
              <ul className="dropdown-menu">
                <li className="submenu">
                  <a href="#">61</a>
                  <ul className="sub-menu" style={{top:"8px"}}>
                  <li><a data-nomor-gigi=" 61-pob-62-miss-pon-pob-63-pob" onClick={handleChangePob}>61-63</a></li>
                  <li><a data-nomor-gigi=" 61-pob-62-miss-pon-pob-63-miss-pon-pob-64-pob" onClick={handleChangePob}>61-64</a></li>
                  <li><a data-nomor-gigi=" 61-pob-62-miss-pon-pob-63-miss-pon-pob-64-miss-pon-pob-65-pob" onClick={handleChangePob}>61-65</a></li>
                  </ul>
                </li>
                <li className="submenu">
                  <a href="#">62</a>
                  <ul className="sub-menu" style={{top:"32px"}}>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 62-pob-63-miss-pon-pob-64-pob">62-64</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 62-pob-63-miss-pon-pob-64-miss-pon-pob-65-pob">62-65</a></li>
                  </ul>
                </li>
                <li className="submenu">
                  <a href="#">63</a>
                  <ul className="sub-menu" style={{top:"56px"}}>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 63-pob-64-miss-pon-pob-65-pob">63-65</a></li>
                  </ul>
                </li>
            
              </ul>
                )}
                
              {form?.tooch_position_pob.includes("rahang_bawah_kanan_anak") && (
              <ul className="dropdown-menu">
                <li className="submenu">
                  <a href="#">85</a>
                  <ul className="sub-menu" style={{top:"8px"}}>
                  <li><a data-nomor-gigi=" 85-pob-84-miss-pon-pob-83-pob" onClick={handleChangePob}>85-83</a></li>
                  <li><a data-nomor-gigi=" 85-pob-84-miss-pon-pob-83-miss-pon-pob-82-pob" onClick={handleChangePob}>85-82</a></li>
                  <li><a data-nomor-gigi=" 85-pob-84-miss-pon-pob-83-miss-pon-pob-82-miss-pon-pob-81-pob" onClick={handleChangePob}>85-81</a></li>
                  </ul>
                </li>
                <li className="submenu">
                  <a href="#">84</a>
                  <ul className="sub-menu" style={{top:"32px"}}>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 84-pob-83-miss-pon-pob-82-pob">84-82</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 84-pob-83-miss-pon-pob-82-miss-pon-pob-81-pob">84-81</a></li>
                  </ul>
                </li>
                <li className="submenu">
                  <a href="#">83</a>
                  <ul className="sub-menu" style={{top:"56px"}}>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 83-pob-82-miss-pon-pob-81-pob">83-81</a></li>
                  </ul>
                </li>
            
              </ul>
                )}
                
              {form?.tooch_position_pob.includes("rahang_bawah_kiri_anak") && (
              <ul className="dropdown-menu">
                <li className="submenu">
                  <a href="#">71</a>
                  <ul className="sub-menu" style={{top:"8px"}}>
                  <li><a data-nomor-gigi=" 71-pob-72-miss-pon-pob-73-pob" onClick={handleChangePob}>71-73</a></li>
                  <li><a data-nomor-gigi=" 71-pob-72-miss-pon-pob-73-miss-pon-pob-74-pob" onClick={handleChangePob}>71-74</a></li>
                  <li><a data-nomor-gigi=" 71-pob-72-miss-pon-pob-73-miss-pon-pob-74-miss-pon-pob-75-pob" onClick={handleChangePob}>71-75</a></li>
                  </ul>
                </li>
                <li className="submenu">
                  <a href="#">72</a>
                  <ul className="sub-menu" style={{top:"32px"}}>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 72-pob-73-miss-pon-pob-74-pob">72-74</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 72-pob-73-miss-pon-pob-74-miss-pon-pob-75-pob">72-75</a></li>
                  </ul>
                </li>
                <li className="submenu">
                  <a href="#">73</a>
                  <ul className="sub-menu" style={{top:"56px"}}>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 73-pob-74-miss-pon-pob-75-pob">73-75</a></li>
                  </ul>
                </li>
            
              </ul>
                )}
                
              {form?.tooch_position_pob.includes("rahang_bawah_kanan_dewasa") && (
            <ul className="dropdown-menu">
              <li className="submenu">
                <a href="#">48</a>
                <ul className="sub-menu" style={{top:"8px"}}>
                <li><a data-nomor-gigi=" 48-pob-47-miss-pon-pob-46-pob" onClick={handleChangePob}>48-46</a></li>
                <li><a data-nomor-gigi=" 48-pob-47-miss-pon-pob-46-miss-pon-pob-45-pob" onClick={handleChangePob}>48-45</a></li>
                <li><a data-nomor-gigi=" 48-pob-47-miss-pon-pob-46-miss-pon-pob-45-miss-pon-pob-44-pob" onClick={handleChangePob}>48-44</a></li>
                <li><a data-nomor-gigi=" 48-pob-47-miss-pon-pob-46-miss-pon-pob-45-miss-pon-pob-44-miss-pon-pob-43-pob" onClick={handleChangePob}>48-43</a></li>
                <li><a data-nomor-gigi=" 48-pob-47-miss-pon-pob-46-miss-pon-pob-45-miss-pon-pob-44-miss-pon-pob-43-miss-pon-pob-42-pob" onClick={handleChangePob}>48-42</a></li>
                <li><a data-nomor-gigi=" 48-pob-47-miss-pon-pob-46-miss-pon-pob-45-miss-pon-pob-44-miss-pon-pob-43-miss-pon-pob-42-miss-pon-pob-41-pob" onClick={handleChangePob}>48-41</a></li>
                </ul>
              </li>
              <li className="submenu">
                <a href="#">47</a>
                <ul className="sub-menu" style={{top:"32px"}}>
                <li><a onClick={handleChangePob} data-nomor-gigi=" 47-pob-46-miss-pon-pob-45-pob">47-45</a></li>
                <li><a onClick={handleChangePob} data-nomor-gigi=" 47-pob-46-miss-pon-pob-45-miss-pon-pob-44-pob">47-44</a></li>
                <li><a onClick={handleChangePob} data-nomor-gigi=" 47-pob-46-miss-pon-pob-45-miss-pon-pob-44-miss-pon-pob-43-pob">47-43</a></li>
                <li><a onClick={handleChangePob} data-nomor-gigi=" 47-pob-46-miss-pon-pob-45-miss-pon-pob-44-miss-pon-pob-43-miss-pon-pob-42-pob">47-42</a></li>
                <li><a onClick={handleChangePob} data-nomor-gigi=" 47-pob-46-miss-pon-pob-45-miss-pon-pob-44-miss-pon-pob-43-miss-pon-pob-42-miss-pon-pob-41-pob">47-41</a></li>
                </ul>
              </li>
              <li className="submenu">
                <a href="#">46</a>
                <ul className="sub-menu" style={{top:"56px"}}>
                <li><a onClick={handleChangePob} data-nomor-gigi=" 46-pob-45-miss-pon-pob-44-pob">46-44</a></li>
                <li><a onClick={handleChangePob} data-nomor-gigi=" 46-pob-45-miss-pon-pob-44-miss-pon-pob-43-pob">46-43</a></li>
                <li><a onClick={handleChangePob} data-nomor-gigi=" 46-pob-45-miss-pon-pob-44-miss-pon-pob-43-miss-pon-pob-42-pob">46-42</a></li>
                <li><a onClick={handleChangePob} data-nomor-gigi=" 46-pob-45-miss-pon-pob-44-miss-pon-pob-43-miss-pon-pob-42-miss-pon-pob-41-pob">46-41</a></li>
                </ul>
              </li>
              <li className="submenu">
                <a href="#">45</a>
                <ul className="sub-menu" style={{top:"80px"}}>
                <li><a onClick={handleChangePob} data-nomor-gigi=" 45-pob-44-miss-pon-pob-43-pob">45-43</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 45-pob-44-miss-pon-pob-43-miss-pon-pob-42-pob">45-42</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 45-pob-44-miss-pon-pob-43-miss-pon-pob-42-miss-pon-pob-41-pob">45-41</a></li>
                </ul>
                  </li>
                  <li className="submenu">
                <a href="#">44</a>
                <ul className="sub-menu" style={{top:"104px"}}>
                <li><a onClick={handleChangePob} data-nomor-gigi=" 44-pob-43-miss-pon-pob-42-pob">44-42</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 44-pob-43-miss-pon-pob-42-miss-pon-pob-41-pob">44-41</a></li>
                </ul>
                  </li>
                  <li className="submenu">
                <a href="#">43</a>
                <ul className="sub-menu" style={{top:"128px"}}>
                <li><a onClick={handleChangePob} data-nomor-gigi=" 43-pob-42-miss-pon-pob-41-pob">43-41</a></li>
                </ul>
              </li>
            </ul>
              )}

              {form?.tooch_position_pob.includes("rahang_bawah_kiri_dewasa") && (
              <ul className="dropdown-menu">
                <li className="submenu">
                  <a href="#">31</a>
                  <ul className="sub-menu" style={{top:"8px"}}>
                  <li><a data-nomor-gigi=" 31-pob-32-miss-pon-pob-33-pob" onClick={handleChangePob}>31-33</a></li>
                  <li><a data-nomor-gigi=" 31-pob-32-miss-pon-pob-33-miss-pon-pob-34-pob" onClick={handleChangePob}>31-34</a></li>
                  <li><a data-nomor-gigi=" 31-pob-32-miss-pon-pob-33-miss-pon-pob-34-miss-pon-pob-35-pob" onClick={handleChangePob}>31-35</a></li>
                  <li><a data-nomor-gigi=" 31-pob-32-miss-pon-pob-33-miss-pon-pob-34-miss-pon-pob-35-miss-pon-pob-36-pob" onClick={handleChangePob}>31-36</a></li>
                  <li><a data-nomor-gigi=" 31-pob-32-miss-pon-pob-33-miss-pon-pob-34-miss-pon-pob-35-miss-pon-pob-36-miss-pon-pob-37-pob" onClick={handleChangePob}>31-37</a></li>
                  <li><a data-nomor-gigi=" 31-pob-32-miss-pon-pob-33-miss-pon-pob-34-miss-pon-pob-35-miss-pon-pob-36-miss-pon-pob-37-miss-pon-pob-38-pob" onClick={handleChangePob}>31-38</a></li>
                  </ul>
                </li>
                <li className="submenu">
                  <a href="#">32</a>
                  <ul className="sub-menu" style={{top:"32px"}}>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 32-pob-33-miss-pon-pob-34-pob">32-34</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 32-pob-33-miss-pon-pob-34-miss-pon-pob-35-pob">32-35</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 32-pob-33-miss-pon-pob-34-miss-pon-pob-35-miss-pon-pob-36-pob">32-36</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 32-pob-33-miss-pon-pob-34-miss-pon-pob-35-miss-pon-pob-36-miss-pon-pob-37-pob">32-37</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 32-pob-33-miss-pon-pob-34-miss-pon-pob-35-miss-pon-pob-36-miss-pon-pob-37-miss-pon-pob-38-pob">32-38</a></li>
                  </ul>
                </li>
                <li className="submenu">
                  <a href="#">33</a>
                  <ul className="sub-menu" style={{top:"56px"}}>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 33-pob-34-miss-pon-pob-35-pob">33-35</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 33-pob-34-miss-pon-pob-35-miss-pon-pob-36-pob">33-36</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 33-pob-34-miss-pon-pob-35-miss-pon-pob-36-miss-pon-pob-37-pob">33-37</a></li>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 33-pob-34-miss-pon-pob-35-miss-pon-pob-36-miss-pon-pob-37-miss-pon-pob-38-pob">33-38</a></li>
                  </ul>
                </li>
                <li className="submenu">
                  <a href="#">34</a>
                  <ul className="sub-menu" style={{top:"80px"}}>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 34-pob-35-miss-pon-pob-36-pob">34-36</a></li>
                    <li><a onClick={handleChangePob} data-nomor-gigi=" 34-pob-35-miss-pon-pob-36-miss-pon-pob-37-pob">34-37</a></li>
                    <li><a onClick={handleChangePob} data-nomor-gigi=" 34-pob-35-miss-pon-pob-36-miss-pon-pob-37-miss-pon-pob-38-pob">34-38</a></li>
                  </ul>
                    </li>
                    <li className="submenu">
                  <a href="#">35</a>
                  <ul className="sub-menu" style={{top:"104px"}}>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 35-pob-36-miss-pon-pob-37-pob">35-37</a></li>
                    <li><a onClick={handleChangePob} data-nomor-gigi=" 35-pob-36-miss-pon-pob-37-miss-pon-pob-38-pob">35-38</a></li>
                  </ul>
                    </li>
                    <li className="submenu">
                  <a href="#">36</a>
                  <ul className="sub-menu" style={{top:"128px"}}>
                  <li><a onClick={handleChangePob} data-nomor-gigi=" 36-pob-37-miss-pon-pob-38-pob">36-38</a></li>
                  </ul>
                </li>
              </ul>
                )}
                
            </div>
          </div>
        </div>
        :  
        <div onClick={addPob} style={{border:"1px solid black", padding:"5px 5px 10px 5px", borderRadius:"3px", backgroundColor: pobSimbol === true? "#83FFF8" : "" , margin:"3px",cursor:"pointer"}}>
          <div style={{display:"flex", justifyContent:"center"}}>pob</div>  
          <div style={{display:"flex", justifyContent:"center"}}>
            <img src={logo_pob} style={{width:"40px", height:"40px"}} ></img>   
          </div>
        </div>
      } 

      {mebSimbol === true? 
        <div style={{padding:"5px 5px 10px 5px", borderRadius:"3px", backgroundColor: addMeb === true? "#83FFF8" : "" , margin:"3px", border:"1px solid black"}}>
        <div style={{display:"flex", justifyContent:"center"}}>meb</div>  
        <div style={{display:"flex", justifyContent:"center"}}>
          <img src={logo_ipx_poc} style={{width:"40px", height:"40px"}} ></img>   
        </div>
      
        <div className="mt-2" style={{display:"flex", justifyContent:"center"}}>
          <select className="form-select" aria-label="Default select example" type='text' value={form?.tooch_position_meb} name="tooch_position_meb" onChange={handleChangePositionTouchMeb} style={{ textAlign:"", cursor:"pointer", width:"105px"}}>
            <option value="" hidden>Select Position</option>
            <option value="rahang_atas_kanan_dewasa" style={{textAlign:""}}>Rahang Atas Kanan Dewasa</option>
            <option value="rahang_atas_kiri_dewasa" style={{textAlign:""}}>Rahang Atas Kiri Dewasa</option>
            <option value="rahang_atas_kanan_anak" style={{textAlign:""}}>Rahang Atas Kanan Anak</option>
            <option value="rahang_atas_kiri_anak" style={{textAlign:""}}>Rahang Atas Kiri Anak</option>
            <option value="rahang_bawah_kanan_anak" style={{textAlign:""}}>Rahang Bawah Kanan Anak</option>
            <option value="rahang_bawah_kiri_anak" style={{textAlign:""}}>Rahang Bawah Kiri Anak</option>
            <option value="rahang_bawah_kanan_dewasa" style={{textAlign:""}}>Rahang Bawah Kanan Dewasa</option>  
            <option value="rahang_bawah_kiri_dewasa" style={{textAlign:""}}>Rahang Bawah Kiri Dewasa</option>                                 
          </select>
        </div>
            
        <div className="mt-2" style={{display:"flex", justifyContent:"center"}}>
          <div className="dropdown-container">
          <button className="btn btn-primary">Nomor Gigi</button>
            {form?.tooch_position_meb.includes("rahang_atas_kanan_dewasa") && (
            <ul className="dropdown-menu">
              <li className="submenu">
                <a href="#">18</a>
                <ul className="sub-menu" style={{top:"8px"}}>
                <li><a data-nomor-gigi=" 18-meb-17-miss-pon-meb-16-meb" onClick={handleChangeMeb}>18-16</a></li>
                <li><a data-nomor-gigi=" 18-meb-17-miss-pon-meb-16-miss-pon-meb-15-meb" onClick={handleChangeMeb}>18-15</a></li>
                <li><a data-nomor-gigi=" 18-meb-17-miss-pon-meb-16-miss-pon-meb-15-miss-pon-meb-14-meb" onClick={handleChangeMeb}>18-14</a></li>
                <li><a data-nomor-gigi=" 18-meb-17-miss-pon-meb-16-miss-pon-meb-15-miss-pon-meb-14-miss-pon-meb-13-meb" onClick={handleChangeMeb}>18-13</a></li>
                <li><a data-nomor-gigi=" 18-meb-17-miss-pon-meb-16-miss-pon-meb-15-miss-pon-meb-14-miss-pon-meb-13-miss-pon-meb-12-meb" onClick={handleChangeMeb}>18-12</a></li>
                <li><a data-nomor-gigi=" 18-meb-17-miss-pon-meb-16-miss-pon-meb-15-miss-pon-meb-14-miss-pon-meb-13-miss-pon-meb-12-miss-pon-meb-11-meb" onClick={handleChangeMeb}>18-11</a></li>
                </ul>
              </li>
              <li className="submenu">
                <a href="#">17</a>
                <ul className="sub-menu" style={{top:"32px"}}>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 17-meb-16-miss-pon-meb-15-meb">17-15</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 17-meb-16-miss-pon-meb-15-miss-pon-meb-14-meb">17-14</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 17-meb-16-miss-pon-meb-15-miss-pon-meb-14-miss-pon-meb-13-meb">17-13</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 17-meb-16-miss-pon-meb-15-miss-pon-meb-14-miss-pon-meb-13-miss-pon-meb-12-meb">17-12</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 17-meb-16-miss-pon-meb-15-miss-pon-meb-14-miss-pon-meb-13-miss-pon-meb-12-miss-pon-meb-11-meb">17-11</a></li>
                </ul>
              </li>
              <li className="submenu">
                <a href="#">16</a>
                <ul className="sub-menu" style={{top:"56px"}}>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 16-meb-15-miss-pon-meb-14-meb">16-14</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 16-meb-15-miss-pon-meb-14-miss-pon-meb-13-meb">16-13</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 16-meb-15-miss-pon-meb-14-miss-pon-meb-13-miss-pon-meb-12-meb">16-12</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 16-meb-15-miss-pon-meb-14-miss-pon-meb-13-miss-pon-meb-12-miss-pon-meb-11-meb">16-11</a></li>
                </ul>
              </li>
              <li className="submenu">
                <a href="#">15</a>
                <ul className="sub-menu" style={{top:"80px"}}>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 15-meb-14-miss-pon-meb-13-meb">15-13</a></li>
                  <li><a onClick={handleChangeMeb} data-nomor-gigi=" 15-meb-14-miss-pon-meb-13-miss-pon-meb-12-meb">15-12</a></li>
                  <li><a onClick={handleChangeMeb} data-nomor-gigi=" 15-meb-14-miss-pon-meb-13-miss-pon-meb-12-miss-pon-meb-11-meb">15-11</a></li>
                </ul>
                  </li>
                  <li className="submenu">
                <a href="#">14</a>
                <ul className="sub-menu" style={{top:"104px"}}>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 14-meb-13-miss-pon-meb-12-meb">14-12</a></li>
                  <li><a onClick={handleChangeMeb} data-nomor-gigi=" 14-meb-13-miss-pon-meb-12-miss-pon-meb-11-meb">14-11</a></li>
                </ul>
                  </li>
                  <li className="submenu">
                <a href="#">13</a>
                <ul className="sub-menu" style={{top:"128px"}}>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 13-meb-12-miss-pon-meb-11-meb">13-11</a></li>
                </ul>
              </li>
            </ul>
              )}
              
            {form?.tooch_position_meb.includes("rahang_atas_kiri_dewasa") && (
            <ul className="dropdown-menu">
              <li className="submenu">
                <a href="#">21</a>
                <ul className="sub-menu" style={{top:"8px"}}>
                <li><a data-nomor-gigi=" 21-meb-22-miss-pon-meb-23-meb" onClick={handleChangeMeb}>21-23</a></li>
                <li><a data-nomor-gigi=" 21-meb-22-miss-pon-meb-23-miss-pon-meb-24-meb" onClick={handleChangeMeb}>21-24</a></li>
                <li><a data-nomor-gigi=" 21-meb-22-miss-pon-meb-23-miss-pon-meb-24-miss-pon-meb-25-meb" onClick={handleChangeMeb}>21-25</a></li>
                <li><a data-nomor-gigi=" 21-meb-22-miss-pon-meb-23-miss-pon-meb-24-miss-pon-meb-25-miss-pon-meb-26-meb" onClick={handleChangeMeb}>21-26</a></li>
                <li><a data-nomor-gigi=" 21-meb-22-miss-pon-meb-23-miss-pon-meb-24-miss-pon-meb-25-miss-pon-meb-26-miss-pon-meb-27-meb" onClick={handleChangeMeb}>21-27</a></li>
                <li><a data-nomor-gigi=" 21-meb-22-miss-pon-meb-23-miss-pon-meb-24-miss-pon-meb-25-miss-pon-meb-26-miss-pon-meb-27-miss-pon-meb-28-meb" onClick={handleChangeMeb}>21-28</a></li>
                </ul>
              </li>
              <li className="submenu">
                <a href="#">22</a>
                <ul className="sub-menu" style={{top:"32px"}}>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 22-meb-23-miss-pon-meb-24-meb">22-24</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 22-meb-23-miss-pon-meb-24-miss-pon-meb-25-meb">22-25</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 22-meb-23-miss-pon-meb-24-miss-pon-meb-25-miss-pon-meb-26-meb">22-26</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 22-meb-23-miss-pon-meb-24-miss-pon-meb-25-miss-pon-meb-26-miss-pon-meb-27-meb">22-27</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 22-meb-23-miss-pon-meb-24-miss-pon-meb-25-miss-pon-meb-26-miss-pon-meb-27-miss-pon-meb-28-meb">22-28</a></li>
                </ul>
              </li>
              <li className="submenu">
                <a href="#">23</a>
                <ul className="sub-menu" style={{top:"56px"}}>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 23-meb-24-miss-pon-meb-25-meb">23-25</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 23-meb-24-miss-pon-meb-25-miss-pon-meb-26-meb">23-26</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 23-meb-24-miss-pon-meb-25-miss-pon-meb-26-miss-pon-meb-27-meb">23-27</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 23-meb-24-miss-pon-meb-25-miss-pon-meb-26-miss-pon-meb-27-miss-pon-meb-28-meb">23-28</a></li>
                </ul>
              </li>
              <li className="submenu">
                <a href="#">24</a>
                <ul className="sub-menu" style={{top:"80px"}}>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 24-meb-25-miss-pon-meb-26-meb">24-26</a></li>
                  <li><a onClick={handleChangeMeb} data-nomor-gigi=" 24-meb-25-miss-pon-meb-26-miss-pon-meb-27-meb">24-27</a></li>
                  <li><a onClick={handleChangeMeb} data-nomor-gigi=" 24-meb-25-miss-pon-meb-26-miss-pon-meb-27-miss-pon-meb-28-meb">24-28</a></li>
                </ul>
                  </li>
                  <li className="submenu">
                <a href="#">25</a>
                <ul className="sub-menu" style={{top:"104px"}}>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 25-meb-26-miss-pon-meb-27-meb">25-27</a></li>
                  <li><a onClick={handleChangeMeb} data-nomor-gigi=" 25-meb-26-miss-pon-meb-27-miss-pon-meb-28-meb">25-28</a></li>
                </ul>
                  </li>
                  <li className="submenu">
                <a href="#">26</a>
                <ul className="sub-menu" style={{top:"128px"}}>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 26-meb-27-miss-pon-meb-28-meb">26-28</a></li>
                </ul>
              </li>
            </ul>
              )}
              
            {form?.tooch_position_meb.includes("rahang_atas_kanan_anak") && (
            <ul className="dropdown-menu">
              <li className="submenu">
                <a href="#">55</a>
                <ul className="sub-menu" style={{top:"8px"}}>
                <li><a data-nomor-gigi=" 55-meb-54-miss-pon-meb-53-meb" onClick={handleChangeMeb}>55-53</a></li>
                <li><a data-nomor-gigi=" 55-meb-54-miss-pon-meb-53-miss-pon-meb-52-meb" onClick={handleChangeMeb}>55-52</a></li>
                <li><a data-nomor-gigi=" 55-meb-54-miss-pon-meb-53-miss-pon-meb-52-miss-pon-meb-51-meb" onClick={handleChangeMeb}>55-51</a></li>
                </ul>
              </li>
              <li className="submenu">
                <a href="#">54</a>
                <ul className="sub-menu" style={{top:"32px"}}>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 54-meb-53-miss-pon-meb-52-meb">54-52</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 54-meb-53-miss-pon-meb-52-miss-pon-meb-51-meb">54-51</a></li>
                </ul>
              </li>
              <li className="submenu">
                <a href="#">53</a>
                <ul className="sub-menu" style={{top:"56px"}}>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 53-meb-52-miss-pon-meb-51-meb">53-51</a></li>
                </ul>
              </li>
          
            </ul>
              )}
  
            {form?.tooch_position_meb.includes("rahang_atas_kiri_anak") && (
            <ul className="dropdown-menu">
              <li className="submenu">
                <a href="#">61</a>
                <ul className="sub-menu" style={{top:"8px"}}>
                <li><a data-nomor-gigi=" 61-meb-62-miss-pon-meb-63-meb" onClick={handleChangeMeb}>61-63</a></li>
                <li><a data-nomor-gigi=" 61-meb-62-miss-pon-meb-63-miss-pon-meb-64-meb" onClick={handleChangeMeb}>61-64</a></li>
                <li><a data-nomor-gigi=" 61-meb-62-miss-pon-meb-63-miss-pon-meb-64-miss-pon-meb-65-meb" onClick={handleChangeMeb}>61-65</a></li>
                </ul>
              </li>
              <li className="submenu">
                <a href="#">62</a>
                <ul className="sub-menu" style={{top:"32px"}}>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 62-meb-63-miss-pon-meb-64-meb">62-64</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 62-meb-63-miss-pon-meb-64-miss-pon-meb-65-meb">62-65</a></li>
                </ul>
              </li>
              <li className="submenu">
                <a href="#">63</a>
                <ul className="sub-menu" style={{top:"56px"}}>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 63-meb-64-miss-pon-meb-65-meb">63-65</a></li>
                </ul>
              </li>
          
            </ul>
              )}
              
            {form?.tooch_position_meb.includes("rahang_bawah_kanan_anak") && (
            <ul className="dropdown-menu">
              <li className="submenu">
                <a href="#">85</a>
                <ul className="sub-menu" style={{top:"8px"}}>
                <li><a data-nomor-gigi=" 85-meb-84-miss-pon-meb-83-meb" onClick={handleChangeMeb}>85-83</a></li>
                <li><a data-nomor-gigi=" 85-meb-84-miss-pon-meb-83-miss-pon-meb-82-meb" onClick={handleChangeMeb}>85-82</a></li>
                <li><a data-nomor-gigi=" 85-meb-84-miss-pon-meb-83-miss-pon-meb-82-miss-pon-meb-81-meb" onClick={handleChangeMeb}>85-81</a></li>
                </ul>
              </li>
              <li className="submenu">
                <a href="#">84</a>
                <ul className="sub-menu" style={{top:"32px"}}>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 84-meb-83-miss-pon-meb-82-meb">84-82</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 84-meb-83-miss-pon-meb-82-miss-pon-meb-81-meb">84-81</a></li>
                </ul>
              </li>
              <li className="submenu">
                <a href="#">83</a>
                <ul className="sub-menu" style={{top:"56px"}}>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 83-meb-82-miss-pon-meb-81-meb">83-81</a></li>
                </ul>
              </li>
          
            </ul>
              )}
              
            {form?.tooch_position_meb.includes("rahang_bawah_kiri_anak") && (
            <ul className="dropdown-menu">
              <li className="submenu">
                <a href="#">71</a>
                <ul className="sub-menu" style={{top:"8px"}}>
                <li><a data-nomor-gigi=" 71-meb-72-miss-pon-meb-73-meb" onClick={handleChangeMeb}>71-73</a></li>
                <li><a data-nomor-gigi=" 71-meb-72-miss-pon-meb-73-miss-pon-meb-74-meb" onClick={handleChangeMeb}>71-74</a></li>
                <li><a data-nomor-gigi=" 71-meb-72-miss-pon-meb-73-miss-pon-meb-74-miss-pon-meb-75-meb" onClick={handleChangeMeb}>71-75</a></li>
                </ul>
              </li>
              <li className="submenu">
                <a href="#">72</a>
                <ul className="sub-menu" style={{top:"32px"}}>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 72-meb-73-miss-pon-meb-74-meb">72-74</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 72-meb-73-miss-pon-meb-74-miss-pon-meb-75-meb">72-75</a></li>
                </ul>
              </li>
              <li className="submenu">
                <a href="#">73</a>
                <ul className="sub-menu" style={{top:"56px"}}>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 73-meb-74-miss-pon-meb-75-meb">73-75</a></li>
                </ul>
              </li>
          
            </ul>
              )}
              
            {form?.tooch_position_meb.includes("rahang_bawah_kanan_dewasa") && (
          <ul className="dropdown-menu">
            <li className="submenu">
              <a href="#">48</a>
              <ul className="sub-menu" style={{top:"8px"}}>
              <li><a data-nomor-gigi=" 48-meb-47-miss-pon-meb-46-meb" onClick={handleChangeMeb}>48-46</a></li>
              <li><a data-nomor-gigi=" 48-meb-47-miss-pon-meb-46-miss-pon-meb-45-meb" onClick={handleChangeMeb}>48-45</a></li>
              <li><a data-nomor-gigi=" 48-meb-47-miss-pon-meb-46-miss-pon-meb-45-miss-pon-meb-44-meb" onClick={handleChangeMeb}>48-44</a></li>
              <li><a data-nomor-gigi=" 48-meb-47-miss-pon-meb-46-miss-pon-meb-45-miss-pon-meb-44-miss-pon-meb-43-meb" onClick={handleChangeMeb}>48-43</a></li>
              <li><a data-nomor-gigi=" 48-meb-47-miss-pon-meb-46-miss-pon-meb-45-miss-pon-meb-44-miss-pon-meb-43-miss-pon-meb-42-meb" onClick={handleChangeMeb}>48-42</a></li>
              <li><a data-nomor-gigi=" 48-meb-47-miss-pon-meb-46-miss-pon-meb-45-miss-pon-meb-44-miss-pon-meb-43-miss-pon-meb-42-miss-pon-meb-41-meb" onClick={handleChangeMeb}>48-41</a></li>
              </ul>
            </li>
            <li className="submenu">
              <a href="#">47</a>
              <ul className="sub-menu" style={{top:"32px"}}>
              <li><a onClick={handleChangeMeb} data-nomor-gigi=" 47-meb-46-miss-pon-meb-45-meb">47-45</a></li>
              <li><a onClick={handleChangeMeb} data-nomor-gigi=" 47-meb-46-miss-pon-meb-45-miss-pon-meb-44-meb">47-44</a></li>
              <li><a onClick={handleChangeMeb} data-nomor-gigi=" 47-meb-46-miss-pon-meb-45-miss-pon-meb-44-miss-pon-meb-43-meb">47-43</a></li>
              <li><a onClick={handleChangeMeb} data-nomor-gigi=" 47-meb-46-miss-pon-meb-45-miss-pon-meb-44-miss-pon-meb-43-miss-pon-meb-42-meb">47-42</a></li>
              <li><a onClick={handleChangeMeb} data-nomor-gigi=" 47-meb-46-miss-pon-meb-45-miss-pon-meb-44-miss-pon-meb-43-miss-pon-meb-42-miss-pon-meb-41-meb">47-41</a></li>
              </ul>
            </li>
            <li className="submenu">
              <a href="#">46</a>
              <ul className="sub-menu" style={{top:"56px"}}>
              <li><a onClick={handleChangeMeb} data-nomor-gigi=" 46-meb-45-miss-pon-meb-44-meb">46-44</a></li>
              <li><a onClick={handleChangeMeb} data-nomor-gigi=" 46-meb-45-miss-pon-meb-44-miss-pon-meb-43-meb">46-43</a></li>
              <li><a onClick={handleChangeMeb} data-nomor-gigi=" 46-meb-45-miss-pon-meb-44-miss-pon-meb-43-miss-pon-meb-42-meb">46-42</a></li>
              <li><a onClick={handleChangeMeb} data-nomor-gigi=" 46-meb-45-miss-pon-meb-44-miss-pon-meb-43-miss-pon-meb-42-miss-pon-meb-41-meb">46-41</a></li>
              </ul>
            </li>
            <li className="submenu">
              <a href="#">45</a>
              <ul className="sub-menu" style={{top:"80px"}}>
              <li><a onClick={handleChangeMeb} data-nomor-gigi=" 45-meb-44-miss-pon-meb-43-meb">45-43</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 45-meb-44-miss-pon-meb-43-miss-pon-meb-42-meb">45-42</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 45-meb-44-miss-pon-meb-43-miss-pon-meb-42-miss-pon-meb-41-meb">45-41</a></li>
              </ul>
                </li>
                <li className="submenu">
              <a href="#">44</a>
              <ul className="sub-menu" style={{top:"104px"}}>
              <li><a onClick={handleChangeMeb} data-nomor-gigi=" 44-meb-43-miss-pon-meb-42-meb">44-42</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 44-meb-43-miss-pon-meb-42-miss-pon-meb-41-meb">44-41</a></li>
              </ul>
                </li>
                <li className="submenu">
              <a href="#">43</a>
              <ul className="sub-menu" style={{top:"128px"}}>
              <li><a onClick={handleChangeMeb} data-nomor-gigi=" 43-meb-42-miss-pon-meb-41-meb">43-41</a></li>
              </ul>
            </li>
          </ul>
            )}
  
            {form?.tooch_position_meb.includes("rahang_bawah_kiri_dewasa") && (
            <ul className="dropdown-menu">
              <li className="submenu">
                <a href="#">31</a>
                <ul className="sub-menu" style={{top:"8px"}}>
                <li><a data-nomor-gigi=" 31-meb-32-miss-pon-meb-33-meb" onClick={handleChangeMeb}>31-33</a></li>
                <li><a data-nomor-gigi=" 31-meb-32-miss-pon-meb-33-miss-pon-meb-34-meb" onClick={handleChangeMeb}>31-34</a></li>
                <li><a data-nomor-gigi=" 31-meb-32-miss-pon-meb-33-miss-pon-meb-34-miss-pon-meb-35-meb" onClick={handleChangeMeb}>31-35</a></li>
                <li><a data-nomor-gigi=" 31-meb-32-miss-pon-meb-33-miss-pon-meb-34-miss-pon-meb-35-miss-pon-meb-36-meb" onClick={handleChangeMeb}>31-36</a></li>
                <li><a data-nomor-gigi=" 31-meb-32-miss-pon-meb-33-miss-pon-meb-34-miss-pon-meb-35-miss-pon-meb-36-miss-pon-meb-37-meb" onClick={handleChangeMeb}>31-37</a></li>
                <li><a data-nomor-gigi=" 31-meb-32-miss-pon-meb-33-miss-pon-meb-34-miss-pon-meb-35-miss-pon-meb-36-miss-pon-meb-37-miss-pon-meb-38-meb" onClick={handleChangeMeb}>31-38</a></li>
                </ul>
              </li>
              <li className="submenu">
                <a href="#">32</a>
                <ul className="sub-menu" style={{top:"32px"}}>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 32-meb-33-miss-pon-meb-34-meb">32-34</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 32-meb-33-miss-pon-meb-34-miss-pon-meb-35-meb">32-35</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 32-meb-33-miss-pon-meb-34-miss-pon-meb-35-miss-pon-meb-36-meb">32-36</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 32-meb-33-miss-pon-meb-34-miss-pon-meb-35-miss-pon-meb-36-miss-pon-meb-37-meb">32-37</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 32-meb-33-miss-pon-meb-34-miss-pon-meb-35-miss-pon-meb-36-miss-pon-meb-37-miss-pon-meb-38-meb">32-38</a></li>
                </ul>
              </li>
              <li className="submenu">
                <a href="#">33</a>
                <ul className="sub-menu" style={{top:"56px"}}>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 33-meb-34-miss-pon-meb-35-meb">33-35</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 33-meb-34-miss-pon-meb-35-miss-pon-meb-36-meb">33-36</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 33-meb-34-miss-pon-meb-35-miss-pon-meb-36-miss-pon-meb-37-meb">33-37</a></li>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 33-meb-34-miss-pon-meb-35-miss-pon-meb-36-miss-pon-meb-37-miss-pon-meb-38-meb">33-38</a></li>
                </ul>
              </li>
              <li className="submenu">
                <a href="#">34</a>
                <ul className="sub-menu" style={{top:"80px"}}>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 34-meb-35-miss-pon-meb-36-meb">34-36</a></li>
                  <li><a onClick={handleChangeMeb} data-nomor-gigi=" 34-meb-35-miss-pon-meb-36-miss-pon-meb-37-meb">34-37</a></li>
                  <li><a onClick={handleChangeMeb} data-nomor-gigi=" 34-meb-35-miss-pon-meb-36-miss-pon-meb-37-miss-pon-meb-38-meb">34-38</a></li>
                </ul>
                  </li>
                  <li className="submenu">
                <a href="#">35</a>
                <ul className="sub-menu" style={{top:"104px"}}>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 35-meb-36-miss-pon-meb-37-meb">35-37</a></li>
                  <li><a onClick={handleChangeMeb} data-nomor-gigi=" 35-meb-36-miss-pon-meb-37-miss-pon-meb-38-meb">35-38</a></li>
                </ul>
                  </li>
                  <li className="submenu">
                <a href="#">36</a>
                <ul className="sub-menu" style={{top:"128px"}}>
                <li><a onClick={handleChangeMeb} data-nomor-gigi=" 36-meb-37-miss-pon-meb-38-meb">36-38</a></li>
                </ul>
              </li>
            </ul>
              )}
              
          </div>
        </div>
        </div>
        :  
        <div onClick={addMeb} style={{border:"1px solid black", padding:"5px 5px 10px 5px", borderRadius:"3px", backgroundColor: mebSimbol === true? "#83FFF8" : "" , margin:"3px",cursor:"pointer"}}>
        <div style={{display:"flex", justifyContent:"center"}}>meb</div>  
          <div style={{display:"flex", justifyContent:"center"}}>
            <img src={logo_meb} style={{width:"40px", height:"40px"}} ></img>   
          </div>
        </div>
      } 
  
      </div>
    </Col>
      
    <Col className="mt-4" xl='12' sm='12' style={{ padding: "0px 0px 10px 0px" , backgroundColor:"#B3E4FF" }} >
      <div style={{ paddingLeft: "0px", width: "100%", display: "flex", padding: "10px 0px", backgroundColor:"#EBF5FF" }}>
      <div style={{flex:"50%",fontSize:"16px",display:"flex", aunlocklignItems:"center", paddingLeft:"10px", color:"#001F8B"}}>
          Keterangan Tambahan
          {/* <div style={{ color: "black", marginLeft: "5px", fontFamily: "revert", textDecoration: "underline" }}>{getData.length !== 0 ? `${getDataRekamMedis?.first_name} ${getDataRekamMedis?.last_name}` : "Kosong"}</div> */}
        </div>
        <div style={{ flex: "50%", display: "flex", justifyContent: "end" }}>
        </div>
      </div>

      <div style={{padding:"10px"}}> 
          
      <div className="mt-3" style={{ display: "flex" }}>
        <div style={{flex:"15%", display:"flex",alignItems:"center"}}>
          Occlusi
        </div>
        <div style={{ flex: "85%", display:"flex"}}>
          <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
          <select className="form-select mr-2" aria-label="Default select example" type='text' value={form?.occlusion} name="occlusion" onChange={handleChange} style={{ textAlign:"", cursor:"pointer", width:"200px"}}>
            <option value="" hidden>Select</option>
            <option value="normal_bite" style={{textAlign:""}}>Normal Bite</option>
            <option value="cross_bite" style={{textAlign:""}}>Cross Bite</option> 
            <option value="steep_bite" style={{textAlign:""}}>Steep Bite</option>                           
          </select>
        </div>   
      </div>
          
      <div className="mt-3" style={{ display: "flex"}}>
        <div style={{flex:"15%", display:"flex",alignItems:"center"}}>
          Torus Platinus
        </div>
        <div style={{ flex: "85%", display:"flex"}}>
          <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
          <select className="form-select mr-2" aria-label="Default select example" type='text' value={form?.torus_palatinus} name="torus_palatinus" onChange={handleChange} style={{ textAlign:"", cursor:"pointer", width:"200px"}}>
            <option value="" hidden>Select</option>
            <option value="tidak_ada" style={{textAlign:""}}>Tidak Ada</option>
            <option value="kecil" style={{textAlign:""}}>Kecil</option>
            <option value="sedang" style={{textAlign:""}}>Sedang</option> 
            <option value="besar" style={{textAlign:""}}>Besar</option>
            <option value="multiple" style={{textAlign:""}}>Multiple</option>                           
          </select>
        </div>  
      </div>
      
      <div className="mt-3" style={{ display: "flex"}}>
        <div style={{flex:"15%", display:"flex",alignItems:"center"}}>
          Torus Mandibularis
        </div>
        <div style={{ flex: "85%", display:"flex"}}>
          <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
          <select className="form-select mr-2" aria-label="Default select example" type='text' value={form?.torus_mandibularis} name="torus_mandibularis" onChange={handleChange} style={{ textAlign:"", cursor:"pointer", width:"200px"}}>
            <option value="" hidden>Select</option>
            <option value="tidak_ada" style={{textAlign:""}}>Tidak Ada</option>
            <option value="sisi_kiri" style={{textAlign:""}}>sisi kiri</option>
            <option value="sisi_kanan" style={{textAlign:""}}>siki kanan</option> 
            <option value="kedua_sisi" style={{textAlign:""}}>kedua sisi</option>
          </select>
        </div>   
      </div>
      
      <div className="mt-3" style={{ display: "flex"}}>
        <div style={{flex:"15%", display:"flex",alignItems:"center"}}>
          Palatum
        </div>
        <div style={{ flex: "85%", display:"flex"}}>
          <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
          <select className="form-select mr-2" aria-label="Default select example" type='text' value={form?.palatum} name="palatum" onChange={handleChange} style={{ textAlign:"", cursor:"pointer", width:"200px"}}>
            <option value="" hidden>Select</option>
            <option value="dalam" style={{textAlign:""}}>Dalam</option>
            <option value="sedang" style={{textAlign:""}}>Sedang</option> 
            <option value="rendah" style={{textAlign:""}}>Rendah</option>
          </select>
        </div>    
      </div>
          
      <div className="mt-3" style={{ display: "flex" }}>
        <div style={{flex:"40%",display: "flex"}}>
          <div style={{flex:"37.5%", display:"flex",alignItems:"center"}}>
            Diastema
          </div>
          <div style={{ flex: "62.5%", display:"flex"}}>
            <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <select className="form-select mr-2" aria-label="Default select example"    type='text' value={form?.diastema} name="diastema" onChange={handleChange} style={{ textAlign:"", cursor:"pointer", width:"200px"}}>
              <option value="" hidden>Select</option>
              <option value="tidak_ada" style={{textAlign:""}}>Tidak Ada</option>
              <option value="ada" style={{textAlign:""}}>Ada</option> 
            </select>
          </div>         
        </div>

        <div style={{flex:"60%",display: "flex"}}>
          <div style={{flex:"25%", display:"flex",alignItems:"center"}}>
            Lokasi Dan Lebar
          </div>
          <div style={{ flex: "75%", display:"flex"}}>
            <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <input autoFofcus type='text' value={form?.diastema_note} name="diastema_note" onChange={handleChange}  style={{height:"100%", borderRadius:"5px", border:"1px solid #D2D2D2", width:"300px" }}/>
          </div>         
        </div>

      </div>

      <div className="mt-3" style={{ display: "flex" }}>
        <div style={{flex:"40%",display: "flex"}}>
          <div style={{flex:"37.5%", display:"flex",alignItems:"center"}}>
            Gigi Anomali
          </div>
          <div style={{ flex: "62.5%", display:"flex"}}>
            <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <select className="form-select mr-2" aria-label="Default select example"  type='text' value={form?.anomaly} name="anomaly" onChange={handleChange} style={{ textAlign:"", cursor:"pointer", width:"200px"}}>
              <option value="" hidden>Select</option>
              <option value="tidak_ada" style={{textAlign:""}}>Tidak Ada</option>
              <option value="ada" style={{textAlign:""}}>Ada</option> 
            </select>
          </div>         
        </div>

        <div style={{flex:"60%",display: "flex"}}>
          <div style={{flex:"25%", display:"flex",alignItems:"center"}}>
            No. Gigi Dan Bentuk
          </div>
          <div style={{ flex: "75%", display:"flex"}}>
            <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <input autoFofcus type='text' value={form?.anomaly_note} name="anomaly_note" onChange={handleChange}  style={{height:"100%", borderRadius:"5px", border:"1px solid #D2D2D2", width:"300px" }}/>
          </div>         
        </div>

      </div>
          
      <div className="mt-3" style={{ display: "flex"}}>
        <div style={{flex:"15%", display:"flex",alignItems:"center"}}>
          Lain-lain
        </div>
        <div style={{ flex: "44%", display:"flex"}}>
          <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
          <div  style={{ width:"100%", padding:"0px 0px"}}>
            <input autoFofcus type='text' value={form?.other} name="other"  onChange={handleChange} style={{width:"500px",height:"100%", borderRadius:"5px", border:"1px solid #D2D2D2" }}/>
          </div>
          </div>   
        <div style={{ flex: "41%", display:"flex"}}>
         
        </div>   
      </div>

      <div className="mt-3" style={{ display: "flex"}}>
        <div style={{flex:"14%", display:"flex",alignItems:"center"}}>
        
        </div>
        <div style={{ flex: "86%", display:"flex"}}>
          <div style={{display:"flex"}}>
            <div style={{display:"flex", alignItems:"center",marginRight:"2px"}}>
              D 
            </div>
            <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <div  style={{ width:"100%", padding:"0px 0px"}}>
              <input autoFofcus type='text' value={form?.decayed} name="decayed" onChange={handleChange}  style={{width:"50px",height:"100%", borderRadius:"5px", border:"1px solid #D2D2D2" }}/>
            </div>
          </div> 

          <div style={{display:"flex", marginLeft:"10px"}}>
            <div style={{display:"flex", alignItems:"center",marginRight:"2px"}}>
              M 
            </div>
            <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <div  style={{ width:"100%", padding:"0px 0px"}}>
              <input autoFofcus type='text' value={form?.missing} name="missing" onChange={handleChange}  style={{width:"50px",height:"100%", borderRadius:"5px", border:"1px solid #D2D2D2" }}/>
            </div>
          </div>  

          <div style={{display:"flex", marginLeft:"10px"}}>
            <div style={{display:"flex", alignItems:"center",marginRight:"2px"}}>
              F 
            </div>
            <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <div  style={{ width:"100%", padding:"0px 0px"}}>
              <input autoFofcus type='text' value={form?.filled} name="filled" onChange={handleChange} style={{width:"50px",height:"100%", borderRadius:"5px", border:"1px solid #D2D2D2" }}/>
            </div>
          </div>  
        </div>   
        
      </div>
      
      <div className="mt-3" style={{ display: "flex"}}>
        <div style={{flex:"15%", display:"flex",alignItems:"center"}}>
          Jumlah Foto
        </div>
        <div style={{ flex: "85%", display: "flex" }}>
          <div style={{display:"flex", marginRight:"10px"}}>
            <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <div  style={{ width:"100%", padding:"0px 0px"}}>
              <input autoFofcus type='number' value={form?.qty_photo} name="qty_photo" onChange={handleChange} style={{width:"50px",height:"100%", borderRadius:"5px", border:"1px solid #D2D2D2" }}/>
            </div>
          </div> 
          <div style={{display:"flex"}}>
            <select className="form-select mr-2" aria-label="Default select example"  type='text' value={form?.photo_type} name="photo_type" onChange={handleChange} style={{ textAlign:"", cursor:"pointer", width:"200px"}}>
              <option value="" hidden>Selec Type</option>
              <option value="digital" style={{textAlign:""}}>digital</option>
              <option value="intraoral" style={{textAlign:""}}>intraoral</option> 
            </select>
          </div> 
        </div>   
      </div>

      <div className="mt-3" style={{ display: "flex"}}>
        <div style={{flex:"15%", display:"flex",alignItems:"center"}}>
          Jumlah Rontgen
        </div>
        <div style={{ flex: "85%", display: "flex" }}>
          <div style={{display:"flex", marginRight:"10px"}}>
            <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <div  style={{ width:"100%", padding:"0px 0px"}}>
              <input autoFofcus type='number' value={form?.qty_rontgen_photo} name="qty_rontgen_photo" onChange={handleChange} style={{width:"50px",height:"100%", borderRadius:"5px", border:"1px solid #D2D2D2" }}/>
            </div>
          </div> 
          <div style={{display:"flex"}}>
            <select className="form-select mr-2" aria-label="Default select example" type='text' value={form?.rontgen_photo_type} name="rontgen_photo_type" onChange={handleChange} style={{ textAlign:"", cursor:"pointer", width:"200px"}}>
              <option value="" hidden>Select</option>
              <option value="dental" style={{textAlign:""}}>Dental</option>
              <option value="pa" style={{textAlign:""}}>PA</option> 
              <option value="opg" style={{ textAlign: "" }}>OPG</option> 
              <option value="ceph" style={{textAlign:""}}>Ceph</option>                               
            </select>
          </div> 
        </div>   
      </div>
      
      <div className="mt-3" style={{ display: "flex"}}>
        <div style={{flex:"15%", display:"flex",alignItems:"center"}}>
          Diperiksa Oleh
        </div>
        <div style={{ flex: "85%", display: "flex" }}>
          <div style={{display:"flex", marginRight:"10px"}}>
            <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <select className="form-select mr-2" aria-label="Default select example"  type='text' value={form?.id_doctor} name="id_doctor" onChange={handleChange} style={{ textAlign:"", cursor:"pointer", width:"200px"}}>
              <option value="" hidden>Select Dokter</option>
                  {getDataDokter.map((user, index) => (
                      <option value={user?.id} style={{textAlign: ""}}>{user?.firstname} {user?.lastname}</option>
                    ))}
              </select>
          </div> 
        </div>   
      </div>
      
      <div className="mt-3" style={{ display: "flex"}}>
        <div style={{flex:"15%", display:"flex",alignItems:"center"}}>
          Tanggal Periksa
        </div>
        <div style={{ flex: "85%", display: "flex" }}>
          <div style={{display:"flex", marginRight:"10px"}}>
            <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <div  style={{ width:"100%", padding:"0px 0px"}}>
              <input autoFofcus type='date' value={form?.date_check} name="date_check" onChange={handleChange}
                style={{
                  width: "260px", height: "100%", borderRadius: "5px", border: "1px solid #D2D2D2", paddingLeft: "10px", backgroundImage: 'url("https://png.pngtree.com/png-clipart/20230322/ourmid/pngtree-black-and-white-calendar-logo-simple-black-png-image_6655100.png")',
                  backgroundPosition: 'calc(100% - 10px) center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '25px' }}/>
            </div>
          </div> 
        </div>   
      </div>
          
          <div style={{ padding: "0px 0px", marginTop: "0px", display:"flex", justifyContent:"center" }}>
            <div>
              {getData.length === 0? 
              <Button className="mt-4" onClick={(e) => handleSubmit.mutate(e)} color='primary' style={{ padding: "8px 20px", fontSize: "12px", borderRadius: "5px"}}>
                Simpan
              </Button>
              :
              <Button className="mt-4" onClick={(e) => handleSubmitUpdate.mutate(e)} color='primary' style={{ padding: "8px 20px", fontSize: "12px", borderRadius: "5px"}}>
                Update
              </Button>
              }
            </div>
          </div>

      </div>
    </Col>
      
  </div>
  );
}
