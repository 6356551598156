import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { useMutation } from "react-query";
import { API } from "../../config/api";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip,CardBody} from 'reactstrap'
import Logo_Dashboard from '../../assets/signature/signatureNewIcon.png'
// import Logo_Dashboard from '../../assets/signature/signature logo.png'
import source from '../../assets/signature/logo-dental-login.jpg'
import { useForm, Controller } from 'react-hook-form'
import { Link } from 'react-router-dom'
import InputPasswordToggle from './InputToggle'
import swal from "sweetalert";
import { useMediaQuery } from 'react-responsive'
import ShowMoreText from 'react-show-more-text';
import Card from 'react-bootstrap/Card';
import {FaUser, FaBirthdayCake, FaMailBulk, FaTransgender, FaMap, FaAddressCard,FaSave, FaAddressBook, FaUserCircle, FaLevelUpAlt, FaMobile, FaChevronLeft, FaBoxTissue, FaFileMedical, FaPumpMedical, FaClinicMedical, FaBriefcaseMedical, FaHandHoldingMedical} from 'react-icons/fa'
import { useParams } from "react-router";
// import Caro1 from '../../assets/signature/logo-login-dental.png'

// he
import Caro1 from '../../assets/signature/logoLoginDentalNew.jpeg'
import Caro2 from '../../assets/signature/background-appoinment.jpg'
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import { FadeLoader, HashLoader } from 'react-spinners';
import { Transition } from "react-transition-group";
import Dropdown from "./Dropdown";


function MyVerticallyCenteredModal(props) {
  const navigate = useNavigate()
  const registerBaru = () => {      
    navigate("/register-pasien");
  };

  const [backgroundImage, setBackgroundImage] = useState("linear-gradient( #00EFFF, #0044FF)");

  const styles = {
    cursor: "pointer",
    flex: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "150px",
    fontSize: "25px",
    backgroundImage: backgroundImage,
    color: "white",
    marginRight: "5px", borderRadius: "5px",
    transition:"all 5"
  };

  const styles2 = {
    cursor: "pointer",
    flex: "100%",
    display: "flex", justifyContent: "center", alignItems: "center", height: "150px", fontSize: "25px", backgroundImage: "linear-gradient( #61FF7B, #00FF2B)", color: "white", marginRight: "5px", borderRadius: "5px"
  }

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{backgroundColor:"#DCFFFC"}}>
          <Modal.Title id="contained-modal-title-vcenter">
            Pilih Status Pasien
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{backgroundColor:"#DCFFFC", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px"}}>
          <div style={{display:"flex"}}>
            <div onClick={registerBaru} onMouseEnter={() => setBackgroundImage("linear-gradient( #0044FF, #00EFFF)")} onMouseLeave={() => setBackgroundImage("linear-gradient( #00EFFF, #0044FF)")}  style={styles} >
              <FaHandHoldingMedical style={{fontSize:"50px",marginRight:"10px", color:"white"}}/>Pasien Baru
            </div>
            <div  style={styles2}>
              <FaHandHoldingMedical style={{fontSize:"50px",marginRight:"10px", color:"white"}}/>Pasien Lama
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    );
}
  
function MyVerticallyCenteredModal2(props) {
  const navigate = useNavigate()
  const registerBaru = () => {      
    navigate("/register-pasien");
  };

  const [backgroundImage, setBackgroundImage] = useState("linear-gradient( #00EFFF, #0044FF)");

  const styles = {
    cursor: "pointer",
    flex: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "150px",
    fontSize: "15px",
    backgroundImage: backgroundImage,
    color: "white",
    marginRight: "5px", borderRadius: "5px",
    transition:"all 5"
  };

  const styles2 = {
    cursor: "pointer",
    flex: "100%",
    display: "flex", justifyContent: "center", alignItems: "center", height: "150px", fontSize: "15px", backgroundImage: "linear-gradient( #61FF7B, #00FF2B)", color: "white", marginRight: "5px", borderRadius: "5px"
  }

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{backgroundColor:"#DCFFFC"}}>
          <Modal.Title id="contained-modal-title-vcenter">
            Pilih Status Pasien
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{backgroundColor:"#DCFFFC", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px"}}>
          <div style={{display:"flex"}}>
            <div onClick={registerBaru} onMouseEnter={() => setBackgroundImage("linear-gradient( #0044FF, #00EFFF)")} onMouseLeave={() => setBackgroundImage("linear-gradient( #00EFFF, #0044FF)")}  style={styles} >
              <FaHandHoldingMedical style={{ fontSize: "50px", marginRight: "10px", color: "white" }} />Pasien Baru
            </div>
            <div  style={styles2}>
              <FaHandHoldingMedical style={{fontSize:"50px",marginRight:"10px", color:"white"}}/>Pasien Lama
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    );
  }

export default function Login() {
  let navigate = useNavigate();
  document.title = "SDC Apps";
  const [state, dispatch] = useContext(UserContext);
  const [message, setMessage] = useState(null);
  
  const sweetAlert = () => {
    swal({
      title: 'Login Success',
      text: ' ',
      icon: 'success',
      timer: 3000,
      buttons: false
    });
  }

  const registerBaru = () => {      
    navigate("/register-pasien");
  };

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const { email, password } = form;

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = useMutation(async (e) => {
    try {
      e.preventDefault();
      setLoading(true)
      // Configuration
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      // Data body
      const body = JSON.stringify(form);

      // Insert data for login process
      const response = await API.post("user-service/auth/login", body, config);
      localStorage.setItem('token', response.data.data.token);
      localStorage.setItem('name-admin', response.data.data.user.username)
      localStorage.setItem('photo-admin', response.data.data.user.photo_profile)
      localStorage.setItem('id_admin', response.data.data.user.id);
      localStorage.setItem('level', response.data.data.user.level);
      localStorage.setItem('menus', JSON.stringify(response.data.data.user.modules)? JSON.stringify(response.data.data.user.modules) : "" )
      localStorage.setItem('roles', JSON.stringify(response.data.data.user.roles).length === 0 ? "" : JSON.stringify(response.data.data.user.roles))
      // localStorage.setItem('user_data', JSON.stringify(response.data.data.user))
      // console.log(JSON.parse(response.data.data.user.menus))

      // Checking process
      if (response?.status === 200) {
        // Send data to useContext
        dispatch({
          type: "LOGIN_SUCCESS",
          payload: response.data.data,
        })
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 3000,
          buttons: false
        });
        // setLoading(false)
        navigate("/dashboard");
        // // Status check
        // if (response.data.data.data.username === "dwiki_wantara" && response.data.data.data.username === "davy" ) {
        //   navigate("/home");
        // } else {
        //   navigate("/");
        // }
      }
    } catch (error) {
      // setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
    setLoading(false)
  });

  const [loading, setLoading] = useState(false);
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)'})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)'})
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);

  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const dropdownStyle = {
    opacity: isDropdownVisible ? 1 : 0,
    visibility: isDropdownVisible ? 'visible' : 'hidden',
    position: 'absolute',
    backgroundColor: '#f1f1f1',
    minWidth: '300px',
    boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.2)',
    zIndex: 1,
    left: isDropdownVisible ? '0' : '-250px',
    transition: 'left 2s ease-in-out, opacity 2s ease-in-out, visibility 2s ease-in-out',
  };
  
  return (
    <div>
      {isTabletOrMobile ?
     <div>
     <div className='auth-wrapper auth-cover' >
       <MyVerticallyCenteredModal2
         show={modalShow2}
         onHide={() => setModalShow2(false)}
       />
    
       <Row>
         <Col xl='12' sm='12'>
           {/* <Card.Img  style={{ width: "100%", height: "100%" }} /> */}
           <Card.ImgOverlay style={{ display: "flex", backgroundImage:"linear-gradient(white 50%,#0089FF)"  }}>
             <Col xl="12" style={{ backgroundColor: "#000", opacity: ".5", height: "100%", paddingBottom: "20px",paddingTop:"20px"}}>
               <div style={{ display: "flex", justifyContent: "center" }}>
                 <img style={{ width: "50%" }} src={Logo_Dashboard} alt='Login Cover' />
               </div>
               <div style={{ color: "#505050", textAlign: "center", backgroundColor: "#84FFD3", height: "50px", display: "flex", justifyContent: "center", alignContent: "center", alignItems: "Center" }}>
                 Welcome To Signature Dental Care
               </div>
               <Form className='auth-login-form' onSubmit={(e) => handleSubmit.mutate(e)} style={{ marginTop: "40px", padding: "0px 20px" }}>
                 <div className='mb-2'>
                   <Label className='form-label' for='login-email' style={{ fontSize: "15px", fontWeight: "bold", color: "white", fontFamily: "sans-serif" }}>
                     Email
                   </Label>
                   <Input autoFofcus type='email' placeholder='Enter your email...' value={email} name="email" onChange={handleChange} />
                 </div>
                 <div className='mb-1'>
                   <div className='d-flex justify-content-between'>
                     <Label className='form-label' for='login-password' style={{ fontSize: "15px", fontFamily: "", fontWeight: "bold", color: "white", fontFamily: "sans-serif" }}>
                       Password
                     </Label>
                   </div>
                   <InputPasswordToggle placeholder="*********" className='input-group-merge' value={password} name="password" onChange={handleChange} />
                 </div>
                   
                 <div style={{ padding: "0px 0px" }}>
                   <Button className="mt-4" type='submit' color='primary' block style={{ padding: "10px 0px", fontSize: "15px", borderRadius: "5px", }}>
                     Sign in
                   </Button>
                 </div>
               </Form>
               <div style={{ display: "flex", justifyContent: "end", marginTop: "10px", padding: "0px 20px", fontFamily: "initial", fontSize: "15px", color: "#0022FF" }}>
                 <a onClick={() => setModalShow2(true)} style={{ textDecoration: "underline", cursor: "pointer" }}>register pasien</a>
               </div>
 
               <p className='text-center mt-3' style={{ fontFamily: "initial" }}>
                 <span className='me-25' style={{color:"#C7C7C7"}}>Forgot Password?</span>
                 <Link style={{ marginLeft: "2px" }}>
                   <span>Click</span>
                 </Link>
               </p>
             </Col>
           </Card.ImgOverlay>
         </Col>
       </Row>
     </div>
    </div>
        :
        <div>
        <div className='auth-wrapper auth-cover' >
          <MyVerticallyCenteredModal
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
       
          <Row>
            {loading ?   
                <Col xl='12' sm='12' className="fade-in">
                    <MyVerticallyCenteredModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                      <Card.Img src={Caro1} alt="Card image" style={{ width: "100%", opacity: ".5", height: "100vh" }} />
                      <Card.ImgOverlay className="fade-in" style={{ marginTop: "0px", marginLeft: "0px", display: "flex", alignItems: "center" }}>
                      <Col xl="12" style={{ backgroundColor: "#000", opacity: ".75", width: "26%",marginTop:"0px", marginLeft: "40px",paddingBottom:"10px", borderRadius: "10px", boxShadow:"1px 1px 10px black",borderTopLeftRadius: "5px", borderTopRightRadius:"5px", }}>
                        <div style={{display:"flex", justifyContent:"center", backgroundColor:"white", borderTopLeftRadius: "5px", borderTopRightRadius:"5px",}}>
                          <img  style={{width:"65%",borderTopLeftRadius: "10px", borderTopRightRadius:"10px", backgroundColor:"white",  margin:"50px"}} src={Logo_Dashboard} alt='Login Cover' />
                        </div>
                        {/* <div style={{color:"#505050",  textAlign:"center", backgroundColor:"#84FFD3", height:"50px",display:"flex", justifyContent:"center", alignContent:"center",alignItems:"Center"}}>
                          Welcome To Signature Dental Care
                        </div> */}
                        <Form className='auth-login-form' onSubmit={(e) => handleSubmit.mutate(e)}  style={{ marginTop:"20px", padding:"0px 20px"}}>
                          <div className='mb-2'>
                            <Label className='form-label' for='login-email' style={{fontSize:"15px", fontWeight:"bold", color:"white", fontFamily:"sans-serif"}}>
                              Email
                            </Label>
                            <Input autoFofcus type='email' placeholder='Enter your email...'  value={email} name="email" onChange={handleChange}/>
                          </div>
                          <div className='mb-1'>
                            <div className='d-flex justify-content-between'>
                              <Label className='form-label' for='login-password' style={{fontSize:"15px", fontFamily:"", fontWeight:"bold", color:"white", fontFamily:"sans-serif"}}>
                                Password
                              </Label>
                            </div>
                            <InputPasswordToggle placeholder="*********" className='input-group-merge' value={password} name="password" onChange={handleChange} />
                          </div>

                          <div style={{padding:"0px 0px"}}>
                            <Button className="mt-4" type='submit' color='primary' block style={{padding:"10px 0px", fontSize:"15px", borderRadius:"5px", }}>
                              Sign in
                            </Button>
                          </div>
                        </Form>
                    
                        <p className='text-center mt-3' style={{fontFamily:"initial"}}>
                          <span className='me-25' style={{color:"#E1E1E1"}}>Forgot Password?</span>
                          <Link style={{marginLeft:"2px"}}>
                            <span>Click</span>
                          </Link>
                        </p>
                      </Col>
                    </Card.ImgOverlay>
                </Col>
                :
                <Col xl='12' sm='12'>  
                  <Card.Img src={Caro1} alt="Card image" style={{ width: "100%", opacity: ".9", height: "100vh" }} />
                  <Card.ImgOverlay style={{ marginTop: "0px", marginLeft: "0px",display:"flex", alignItems:"center"}}>
                      <Col xl="12" style={{ backgroundColor: "#000", opacity: ".75", width: "26%",marginTop:"0px", marginLeft: "40px",paddingBottom:"10px", borderRadius: "10px", boxShadow:"1px 1px 10px black",borderTopLeftRadius: "5px", borderTopRightRadius:"5px", }}>
                        <div style={{display:"flex", justifyContent:"center", backgroundColor:"white", borderTopLeftRadius: "5px", borderTopRightRadius:"5px",}}>
                          <img  style={{width:"65%",borderTopLeftRadius: "10px", borderTopRightRadius:"10px", backgroundColor:"white", margin:"50px"}} src={Logo_Dashboard} alt='Login Cover' />
                        </div>
                        {/* <div style={{color:"#505050",  textAlign:"center", backgroundColor:"#84FFD3", height:"50px",display:"flex", justifyContent:"center", alignContent:"center",alignItems:"Center"}}>
                          Welcome To Signature Dental Care
                        </div> */}
                        <Form className='auth-login-form' onSubmit={(e) => handleSubmit.mutate(e)}  style={{ marginTop:"20px", padding:"0px 20px"}}>
                          <div className='mb-2'>
                            <Label className='form-label' for='login-email' style={{fontSize:"15px", fontWeight:"bold", color:"white", fontFamily:"sans-serif"}}>
                              Email
                            </Label>
                            <Input autoFofcus type='email' placeholder='Enter your email...'  value={email} name="email" onChange={handleChange}/>
                          </div>
                          <div className='mb-1'>
                            <div className='d-flex justify-content-between'>
                              <Label className='form-label' for='login-password' style={{fontSize:"15px", fontFamily:"", fontWeight:"bold", color:"white", fontFamily:"sans-serif"}}>
                                Password
                              </Label>
                            </div>
                            <InputPasswordToggle placeholder="*********" className='input-group-merge' value={password} name="password" onChange={handleChange} />
                          </div>

                          <div style={{padding:"0px 0px"}}>
                            <Button className="mt-4" type='submit' color='primary' block style={{padding:"10px 0px", fontSize:"15px", borderRadius:"5px", }}>
                              Sign in
                            </Button>
                          </div>
                        </Form>
                    
                        <p className='text-center mt-3' style={{fontFamily:"initial"}}>
                          <span className='me-25' style={{color:"#E1E1E1"}}>Forgot Password?</span>
                          <Link style={{marginLeft:"2px"}}>
                            <span>Click</span>
                          </Link>
                        </p>
                      </Col>
                  </Card.ImgOverlay>
                </Col>
              }
          </Row>
        </div>
      </div>
        }
    </div>
    


    
    // <div className='auth-wrapper auth-cover' >
    //   {loading? 
    //     <div style={style}>
    //       <HashLoader color={"#123abc"} loading={loading}/>
    //       <h1>Loading...</h1>
    //     </div>
    //   :
    //   <Row className='auth-inner m-0' style={{height:"100vh"}}>
    //     <Col className='d-flex align-items-center auth-bg px-5' lg='4' sm='12' >
    //       <Col className='px-xl-2 mx-auto' sm='8' md='6' lg='12'>

    //         <Col className='d-none d-lg-flex' style={{justifyContent:"center",textAlign:"center"}}>
    //           <div>
    //             <img className='img-fluid' style={{width:"70%"}} src={Logo_Dashboard} alt='Login Cover' />
    //           </div>
    //         </Col>

    //         <CardTitle tag='h2' className='fw-bold mb-2' style={{fontWeight:"bold",fontFamily:"Poppins", fontSize:"50px", textAlign:"center"}}>
    //           Sign In 
    //         </CardTitle>
    //         {message && message}
    //         <Form className='auth-login-form mt-2'  onSubmit={(e) => handleSubmit.mutate(e)}>
    //           <div className='mb-2'>
    //             <Label className='form-label' for='login-email' style={{fontSize:"15px", fontWeight:"bold"}}>
    //               Email
    //             </Label>
    //             <Input autoFofcus type='email' placeholder='Enter your email...'  value={email} name="email" onChange={handleChange}/>
    //           </div>
    //           <div className='mb-1'>
    //             <div className='d-flex justify-content-between'>
    //               <Label className='form-label' for='login-password' style={{fontSize:"15px", fontFamily:"", fontWeight:"bold"}}>
    //                 Password
    //               </Label>
    //             </div>
    //             <InputPasswordToggle className='input-group-merge' value={password}  name="password"  onChange={handleChange}/>
    //           </div>

    //           <Button className="mt-4" type='submit' color='primary' block style={{padding:"15px 0px", fontSize:"20px", borderRadius:"25px"}}>
    //             Sign in
    //           </Button>
    //         </Form>
    //         <p className='text-center mt-3'>
    //           <span className='me-25'>Forgot Password?</span>
    //           <Link to='/forgotpassword'>
    //             <span>Click</span>
    //           </Link>
    //         </p>
    //       </Col>
    //       </Col>
          

    //     <Col className=' d-flex align-items-center auth-bg' lg='8' sm='12' style={{backgroundColor:"#E0E0E0", justifyContent:"center",textAlign:"center"}}>
    //       <div className='w-100 d-lg-flex align-items-center justify-content-center'>
    //         <img className='img-fluid' style={{width:"50%"}} src={source} alt='Login Cover' />
    //       </div>
    //     </Col>
    //   </Row>
    //   }
    // </div>
  );
}
