import AdminHome from "../../components/AdminPurchaseRequest/AdminPurchaseRequest";
import React from "react";
import { useContext, useEffect } from "react";
import { UserContext } from "../../context/userContext";
import { API, setAuthToken } from "../../config/api";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { Row } from "react-bootstrap";
import { Container, Col, Table, Button } from 'react-bootstrap';
import Sidebar from "../../components/Sidebar/Sidebar";
import NavbarMobile from "../../components/Navbar/NavbarAdmin/NavbarMobile"
import NavbarDekstop from "../../components/Navbar/NavbarAdmin/NavbarDekstop"
// import NavbarMobile from "../../components/Navbar/NavbarAdminPasien/NavbarMobile"
// import NavbarDekstop from "../../components/Navbar/NavbarAdminPasien/NavbarDekstop"
// init token on axios every time the app is refreshed

// if (localStorage.token) {
//   setAuthToken(localStorage.token);
// }

export default function Home() {
  // let navigate = useNavigate();
  // useEffect(() => {
  //   if (localStorage.getItem("token") == null || undefined) {
  //       navigate("/");
  //     }
  // },[]);

  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 980px)'})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)'})
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const [state, dispatch] = useContext(UserContext);
  
  return (
    <div style={{ minHeight:isTabletOrMobile? "100vh" : "100vh", display:isTabletOrMobile? "" : "flex" }}>
      {isTabletOrMobile ? (
      <div style={{display:""}}>
        <NavbarMobile />         
        <AdminHome />
      </div>
    ) : (
      <div style={{ display: "flex", flex: "1" }}>
        <div style={{ flex: "10%" }}>
          <Sidebar />
        </div>
        <div style={{ flex: "90%" }}>
          <NavbarDekstop />
          <AdminHome />
        </div>
      </div>
    )}
  </div>
  );
}



