import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaClosedCaptioning, FaTimes} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../../assets/signature/signature logo.png"
import Logo_Batik from "../../../assets/signature/logo-batik.png"
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
import "../Styled.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
import LoaderHome from "../../Loader/LoaderHome"

export default function ModalRoleAdd(props) {
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const token = localStorage.getItem("token");
  const [getDataDokter, setGetDataDokter] = useState([]);
  // Untuk Close Proops Data
  const [propsData, setProopsData] = useState()
  const [loading, setLoading] = useState(false);
  const [getData, setGetData] = useState([]);

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  // const GetResponseData = async () => {
  //   const response = await API.get(`transaction-service/treatments?page=${page}&limit=${limit}&ascending=${ascending}`,fetchParams)
  //   setGetData(response.data.data)
  // }

  // useEffect(() => {
  //   GetResponseData()
  // }, [])

  const [form, setForm] = useState({
    id_category: "",
    min_price: "",
    max_price: ""
  });

  const { id_category, min_price , max_price } = form;

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = useMutation(async (e) => {
    try {
      e.preventDefault();
      setLoading(true)
      // Insert data for login process
      const response = await API.post("transaction-service/treatment-prices/store", {
        id: props.id,
        min_price: form?.min_price,
        max_price: form?.max_price
      }, fetchParams);
  
      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        })
        props.GetResponseData()
        props.onHide()
        setLoading(false)
      }

    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  });

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{fontFamily:"sans-serif",border:"none"}}>
      {loading && <LoaderHome />}
      <div style={{ width: "100%", display: "flex", padding: "10px 0px", backgroundColor: "#29B8FF" }}>
        <div style={{flex:"92%", fontSize:"20px",display:"flex",alignItems:"center", paddingLeft:"10px", color:"white", fontWeight:"600"}}>
          Tambah Treatment Prices || {props.treatmentPriceName}
        </div> 
        <div  style={{flex:"8%",fontSize:"30px",display:"flex",alignItems:"center",justifyContent:"center", color:"white"}}>
          <FaTimes onClick={() => setProopsData(props.onHide)} style={{cursor:"pointer"}}/>
        </div> 
      </div>
      <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px",border:"none" }}>
     
      <Form onSubmit={(e) => handleSubmit.mutate(e)} className="mt-3" >
        {/* <div className="mt-3" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Treatment
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <select className="form-select" aria-label="Default select example" onChange={handleChange} value={id_category}  name="id_category" style={{ textAlign:"", cursor:"pointer"}}>
                <option value="" hidden>Select</option>
                {getData.map((user,index) => (
                  <option value={user?.id} style={{ textAlign: "" }}>{user?.treatment_name} ( {user?.min_price} - {user?.max_price} )</option>                         
                ))}
            </select>
          </div>
        </div> */}

          <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Min Price
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
              <input autoFofcus type='number' value={min_price} name="min_price" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
            </div>
          </div>
          </div>

          <div className="mt-2" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Max Price
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
            <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
              <input autoFofcus type='number' value={max_price} name="max_price" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
            </div>
          </div>
          </div>

          <div style={{ padding: "0px 0px", marginTop: "0px", display:"flex", justifyContent:"end" }}>
            <div>
              <Button className="mt-4" type='submit' color='primary' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px"}}>
                Tambahkan
              </Button>
            </div>
          </div>
        </Form>
        
      </Modal.Body>
      {/* <Modal.Footer>
        <div style={{ display: "flex" }}>
          <Button onClick={props.onHide} style={{marginRight:"8px"}}>Close</Button>
        </div>
      </Modal.Footer> */}
    </Modal>
    );
}
  