import { useState } from "react";
import {  useParams } from "react-router-dom";
import "bulma/css/bulma.css";
import "../Styled.css"

export default function ToochNumber({ form, setForm, onclikTooch, handlePolygonClick }) {
  document.title = "SDC Apps";
  const token = localStorage.getItem("token");
  // modal role add 
  const { id, code } = useParams()
  const [infoGigi, setInfoGigi] = useState("XX-X")

  return (
  <div style={{flex:"50%", display:"flex", justifyContent:"start", paddingLeft:""}}>

    <div style={{ width: "42px", margin: "1px" }}>
    {form.tooch_number.includes("61-une") || form.tooch_number.includes("61-non") || form.tooch_number.includes("61-pre") || form.tooch_number.includes("61-ano") || form.tooch_number.includes("61-ipx-poc") || form.tooch_number.includes("61-mis-prd-acr") || form.tooch_number.includes("61-mis-fld-acr") ?
    <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
    {form.tooch_number.includes("61-une") &&
    <div style={{ position: "absolute", color: "black" }}>UNE</div>
    }
    {form.tooch_number.includes("61-non") &&
    <div style={{ position: "absolute", color: "black" }}>NON</div>
    }      
    {form.tooch_number.includes("61-pre") &&
    <div style={{ position: "absolute", color: "black" }}>PRE</div>
    }      
    {form.tooch_number.includes("61-ano") &&
    <div style={{ position: "absolute", color: "black" }}>ANO</div>
    }
    {form.tooch_number.includes("61-ipx-poc") &&
    <div style={{ position: "absolute", color: "black" }}>IPX</div>
    }
    {form.tooch_number.includes("61-mis-prd-acr") &&
    <div style={{ position: "absolute", color: "black" }}>PRD</div>
    } 
    {form.tooch_number.includes("61-mis-fld-acr") &&
    <div style={{ position: "absolute", color: "black" }}>FLD</div>
    }      
    </div>
    :
    <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
    </div>
    }

    {form.tooch_number.includes("61-meb-62-miss-pon-meb-63-meb")
    ||form.tooch_number.includes("61-meb-62-miss-pon-meb-63-miss-pon-meb-64-meb")
    ||form.tooch_number.includes("61-meb-62-miss-pon-meb-63-miss-pon-meb-64-miss-pon-meb-65-meb") ?
    <>
    {/* meb 18 - 16 */}
    {form.tooch_number.includes("61-meb-62-miss-pon-meb-63-meb") &&
   <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
   <svg width="100%" height="20vh">

        {/* Garis ke samping dan ke bawah (L-shape) */}
        <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
      </svg>
    </div>
    }
                          
    {/* meb 18 - 15 */}
    {form.tooch_number.includes("61-meb-62-miss-pon-meb-63-miss-pon-meb-64-meb") &&
   <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
   <svg width="100%" height="20vh">

      {/* Garis ke samping dan ke bawah (L-shape) */}
      <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
      <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
      <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    </svg>
    </div>
    }

    {/* meb 18 - 14 */} 
    {form.tooch_number.includes("61-meb-62-miss-pon-meb-63-miss-pon-meb-64-miss-pon-meb-65-meb") &&      
   <div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
   <svg width="100%" height="20vh">

    {/* Garis ke samping dan ke bawah (L-shape) */}
    <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    </svg>
    </div> 
    }      
    </>
      :
    <></>
    }

    {form.tooch_number.includes("61-pob-62-miss-pon-pob-63-pob")
    ||form.tooch_number.includes("61-pob-62-miss-pon-pob-63-miss-pon-pob-64-pob")
    ||form.tooch_number.includes("61-pob-62-miss-pon-pob-63-miss-pon-pob-64-miss-pon-pob-65-pob") ?
    <>
    {/* pob 18 - 16 */}
    {form.tooch_number.includes("61-pob-62-miss-pon-pob-63-pob") &&
    <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
    <svg width="100%" height="20vh">

        {/* Garis ke samping dan ke bawah (L-shape) */}
        <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
      </svg>
    </div>
    }
                          
    {/* pob 18 - 15 */}
    {form.tooch_number.includes("61-pob-62-miss-pon-pob-63-miss-pon-pob-64-pob") &&
    <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
    <svg width="100%" height="20vh">

      {/* Garis ke samping dan ke bawah (L-shape) */}
      <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
      <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
      <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    </svg>
    </div>
    }

    {/* pob 18 - 14 */} 
    {form.tooch_number.includes("61-pob-62-miss-pon-pob-63-miss-pon-pob-64-miss-pon-pob-65-pob") &&      
   <div style={{ width: "216px", textAlign: "center", position: "absolute", height:"10vh" }}>
   <svg width="100%" height="20vh">

    {/* Garis ke samping dan ke bawah (L-shape) */}
    <line x1="20" y1="1.5" x2="197" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    <line x1="197" y1="0" x2="197" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    </svg>
    </div> 
    }      
    </>
      :
    <></>
    }


    <div style={{ textAlign: "center" }}>61</div>
    <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("61-fmc") || form.tooch_number.includes("61-poc") || form.tooch_number.includes("61-ipx-poc") || form.tooch_number.match(/\b61-pob\b/) || form.tooch_number.match(/\b61-miss-pon-pob\b/) || form.tooch_number.match(/\b61-meb\b/) || form.tooch_number.match(/\b61-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
    {form.tooch_number.includes("61-poc") || form.tooch_number.includes("61-ipx-poc") || form.tooch_number.match(/\b61-pob\b/) || form.tooch_number.match(/\b61-miss-pon-pob\b/) ?
    <g>
    {/* Atas */}
    {form.tooch_number.includes("61L-fis") || form.tooch_number.includes("61L-amf") || form.tooch_number.includes("61L-cof") || form.tooch_number.includes("61L-car") ?
    <>
      {form.tooch_number.includes("61L-fis") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="61L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
              <rect width="8" height="8" fill="#ffb8c6" />
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 61L"
            points="0,0 40,0 30,20 10,20"
            style={{ fill: 'url(#61L-fis)', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("61-Labial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />   
        </svg>
      }

      {form.tooch_number.includes("61L-amf") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 61L"
          points="0,0 40,0 30,20 10,20"
          style={{ fill: 'black', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("61-Labial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

      {form.tooch_number.includes("61L-cof") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="61L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 61L"
            points="0,0 40,0 30,20 10,20"
            style={{ fill: '#007610', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("61-Labial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("61L-car") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 61L"
          points="0,0 40,0 30,20 10,20"
          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
          onMouseEnter={() => setInfoGigi("61-Labial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

    </>
    :
    <svg width="40" height="40">
      <defs>
        <pattern id="61L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
        </pattern>
      </defs>
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 61L"
        points="0,0 40,0 30,20 10,20"
        style={{ fill: '#007610', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("61-Labial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    </svg>
    }

    {/* Bawah */}
    {form.tooch_number.includes("61P-fis") || form.tooch_number.includes("61P-amf") || form.tooch_number.includes("61P-cof") || form.tooch_number.includes("61P-car") ?
    <>
      {form.tooch_number.includes("61P-fis") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="61P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
              <rect width="8" height="8" fill="#ffb8c6" />
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>

          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 61P"
            points="30,20 40,40 0,40 10,20"
            style={{ fill: 'url(#61P-fis)', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("61-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("61P-amf") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 61P"
          points="30,20 40,40 0,40 10,20"
          style={{ fill: 'black', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("61-Palatal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

      {form.tooch_number.includes("61P-cof") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="61P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 61P"
            points="30,20 40,40 0,40 10,20"
            style={{ fill: '#007610', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("61-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("61P-car") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 61P"
          points="30,20 40,40 0,40 10,20"
          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
          onMouseEnter={() => setInfoGigi("61-Palatal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }
    </>
    :
    <svg width="40" height="40">
      <defs>
        <pattern id="61P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
        </pattern>
      </defs>
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 61P"
        points="30,20 40,40 0,40 10,20"
        style={{ fill: '#007610', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("61-Palatal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    </svg>
    }

    {/* Kanan */}
    {form.tooch_number.includes("61D-fis") || form.tooch_number.includes("61D-amf") || form.tooch_number.includes("61D-cof") || form.tooch_number.includes("61D-car") ?
    <>
      {form.tooch_number.includes("61D-fis") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="61D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
              <rect width="8" height="8" fill="#ffb8c6" />
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 61D"
            points="30,20 40,0 40,40 35,30"
            style={{ fill: 'url(#61D-fis)', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("61-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("61D-amf") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 61D"
          points="30,20 40,0 40,40 35,30"
          style={{ fill: 'black', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("61-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

      {form.tooch_number.includes("61D-cof") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="61D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 61D"
            points="30,20 40,0 40,40 35,30"
            style={{ fill: '#007610', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("61-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("61D-car") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 61D"
          points="30,20 40,0 40,40 35,30"
          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
          onMouseEnter={() => setInfoGigi("61-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

    </>
    :
    <svg width="40" height="40">
      <defs>
        <pattern id="61D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
        </pattern>
      </defs>
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 61D"
        points="30,20 40,0 40,40 35,30"
        style={{ fill: '#007610', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("61-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    </svg>
    }

    {/* Kiri */}
    {form.tooch_number.includes("61M-fis") || form.tooch_number.includes("61M-amf") || form.tooch_number.includes("61M-cof") || form.tooch_number.includes("61M-car") ?
      <>
        {form.tooch_number.includes("61M-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="61M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 61M"
              points="0,0 5,10 10,20 0,40"
              style={{ fill: 'url(#61M-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("61-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("61M-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 61M"
            points="0,0 5,10 10,20 0,40"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("61-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("61M-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="61M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 61M"
              points="0,0 5,10 10,20 0,40"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("61-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("61M-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 61M"
            points="0,0 5,10 10,20 0,40"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("61-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

      </>
      :
      <svg width="40" height="40">
        <defs>
          <pattern id="61M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 61M"
          points="0,0 5,10 10,20 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("61-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {/* cfr */}
    {form.tooch_number.includes("61-cfr") &&
    <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
      #
    </text>
    }

    {/* rrx */}
    {form.tooch_number.includes("61-rrx") &&    
    <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 61-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
    v
    </text>
    }
        
    {/* mis */}
    {form.tooch_number.includes("61-mis") &&    
    <>  
    <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
    <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
    </> 
    }
          
    </g>
    :
    <g>
    {/* Atas */}
    {form.tooch_number.includes("61L-fis") || form.tooch_number.includes("61L-amf") || form.tooch_number.includes("61L-cof") || form.tooch_number.includes("61L-car") ?
    <>
      {form.tooch_number.includes("61L-fis") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="61L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
              <rect width="8" height="8" fill="#ffb8c6" />
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 61L"
            points="0,0 40,0 30,20 10,20"
            style={{ fill: 'url(#61L-fis)', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("61-Labial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("61L-amf") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 61L"
          points="0,0 40,0 30,20 10,20"
          style={{ fill: 'black', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("61-Labial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

      {form.tooch_number.includes("61L-cof") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="61L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 61L"
            points="0,0 40,0 30,20 10,20"
            style={{ fill: '#007610', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("61-Labial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("61L-car") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 61L"
          points="0,0 40,0 30,20 10,20"
          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
          onMouseEnter={() => setInfoGigi("61-Labial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

    </>
    :
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 61L"
      points="0,0 40,0 30,20 10,20"
      style={{ fill: 'white', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("61-Labial")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
    }

    {/* Bawah */}
    {form.tooch_number.includes("61P-fis") || form.tooch_number.includes("61P-amf") || form.tooch_number.includes("61P-cof") || form.tooch_number.includes("61P-car") ?
    <>
      {form.tooch_number.includes("61P-fis") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="61P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
              <rect width="8" height="8" fill="#ffb8c6" />
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>

          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 61P"
            points="30,20 40,40 0,40 10,20"
            style={{ fill: 'url(#61P-fis)', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("61-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("61P-amf") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 61P"
          points="30,20 40,40 0,40 10,20"
          style={{ fill: 'black', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("61-Palatal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

      {form.tooch_number.includes("61P-cof") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="61P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 61P"
            points="30,20 40,40 0,40 10,20"
            style={{ fill: '#007610', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("61-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("61P-car") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 61P"
          points="30,20 40,40 0,40 10,20"
          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
          onMouseEnter={() => setInfoGigi("61-Palatal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }
    </>
    :
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 61P"
      points="30,20 40,40 0,40 10,20"
      style={{ fill: 'white', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("61-Palatal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
    }

    {/* Kanan */}
    {form.tooch_number.includes("61D-fis") || form.tooch_number.includes("61D-amf") || form.tooch_number.includes("61D-cof") || form.tooch_number.includes("61D-car") ?
    <>
      {form.tooch_number.includes("61D-fis") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="61D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
              <rect width="8" height="8" fill="#ffb8c6" />
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 61D"
            points="30,20 40,0 40,40 35,30"
            style={{ fill: 'url(#61D-fis)', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("61-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("61D-amf") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 61D"
          points="30,20 40,0 40,40 35,30"
          style={{ fill: 'black', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("61-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

      {form.tooch_number.includes("61D-cof") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="61D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 61D"
            points="30,20 40,0 40,40 35,30"
            style={{ fill: '#007610', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("61-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("61D-car") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 61D"
          points="30,20 40,0 40,40 35,30"
          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
          onMouseEnter={() => setInfoGigi("61-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

    </>
    :
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 61D"
      points="30,20 40,0 40,40 35,30"
      style={{ fill: 'white', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("61-Distal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
    }

    {/* Kiri */}
    {form.tooch_number.includes("61M-fis") || form.tooch_number.includes("61M-amf") || form.tooch_number.includes("61M-cof") || form.tooch_number.includes("61M-car") ?
    <>
    {form.tooch_number.includes("61M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="61M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 61M"
          points="0,0 5,10 10,20 0,40"
          style={{ fill: 'url(#61M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("61-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("61M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 61M"
        points="0,0 5,10 10,20 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("61-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("61M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="61M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 61M"
          points="0,0 5,10 10,20 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("61-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("61M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 61M"
        points="0,0 5,10 10,20 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("61-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    </>
    :
    <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 61M"
    points="0,0 5,10 10,20 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("61-Mesial")}
    onMouseLeave={() => setInfoGigi("XX-X")}
    />
    }

    {/* cfr */}
    {form.tooch_number.includes("61-cfr") &&
    <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
      #
    </text>
    }

    {form.tooch_number.includes("61-rrx") &&    
    <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 61-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
    v
    </text>
    }

    {form.tooch_number.includes("61-mis") &&    
    <>  
    <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
    <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
    </> 
    }
    </g>
    }    

    </svg>

    {form.tooch_number.includes("61-nvt") &&   
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
    <svg width={42} height={42}>
    <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 61"
    points="21,2 41,32 1,32" 
    style={{ fill: 'white', stroke: 'black', strokeWidth: 3 }}
    onMouseEnter={() => setInfoGigi('61')}
    onMouseLeave={() => setInfoGigi('XX-X')}
    />
    </svg> 
    </div>
    }  

    {form.tooch_number.includes("61-rct") &&   
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
    <svg width={42} height={42}>
    <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 61"
    points="21,2 41,32 1,32" 
    style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
    onMouseEnter={() => setInfoGigi('61')}
    onMouseLeave={() => setInfoGigi('XX-X')}
    />
    </svg>
    </div>
    } 

    {form.tooch_number.includes("61B-amf-rct") || form.tooch_number.includes("61P-amf-rct") || form.tooch_number.includes("61M-amf-rct") || form.tooch_number.includes("61D-amf-rct") || form.tooch_number.includes("61L-amf-rct") ||
    form.tooch_number.includes("61B-cof-rct") || form.tooch_number.includes("61P-cof-rct") || form.tooch_number.includes("61M-cof-rct") || form.tooch_number.includes("61D-cof-rct") || form.tooch_number.includes("61L-cof-rct") ||
    form.tooch_number.includes("61-fmc-rct") ||
    form.tooch_number.includes("61-poc-rct") ?
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
    <svg width={42} height={42}>
    <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 61B"
    points="21,2 41,32 1,32" 
    style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
    onMouseEnter={() => setInfoGigi('61-Bukal')}
    onMouseLeave={() => setInfoGigi('XX-X')}
    />
    </svg>
    </div>
    :
    <></>
    } 

    </div>   

    <div style={{ width: "42px", margin: "1px" }}>
    {form.tooch_number.includes("62-une") || form.tooch_number.includes("62-non") || form.tooch_number.includes("62-pre") || form.tooch_number.includes("62-ano") || form.tooch_number.includes("62-ipx-poc") || form.tooch_number.includes("62-mis-prd-acr") || form.tooch_number.includes("62-mis-fld-acr") ?
    <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
    {form.tooch_number.includes("62-une") &&
    <div style={{ position: "absolute", color: "black" }}>UNE</div>
    }
    {form.tooch_number.includes("62-non") &&
    <div style={{ position: "absolute", color: "black" }}>NON</div>
    }      
    {form.tooch_number.includes("62-pre") &&
    <div style={{ position: "absolute", color: "black" }}>PRE</div>
    }      
    {form.tooch_number.includes("62-ano") &&
    <div style={{ position: "absolute", color: "black" }}>ANO</div>
    }
    {form.tooch_number.includes("62-ipx-poc") &&
    <div style={{ position: "absolute", color: "black" }}>IPX</div>
    }
    {form.tooch_number.includes("62-mis-prd-acr") &&
    <div style={{ position: "absolute", color: "black" }}>PRD</div>
    } 
    {form.tooch_number.includes("62-mis-fld-acr") &&
    <div style={{ position: "absolute", color: "black" }}>FLD</div>
    }      
    </div>
    :
    <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
    </div>
    }

    {form.tooch_number.includes("62-meb-63-miss-pon-meb-64-meb")
    ||form.tooch_number.includes("62-meb-63-miss-pon-meb-64-miss-pon-meb-65-meb") ?
    <>
    {/* meb 18 - 16 */}
    {form.tooch_number.includes("62-meb-63-miss-pon-meb-64-meb") &&
   <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
   <svg width="100%" height="20vh">


        {/* Garis ke samping dan ke bawah (L-shape) */}
        <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
      </svg>
    </div>
    }
                          
    {/* meb 18 - 15 */}
    {form.tooch_number.includes("62-meb-63-miss-pon-meb-64-miss-pon-meb-65-meb") &&
    <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
    <svg width="100%" height="20vh">

      {/* Garis ke samping dan ke bawah (L-shape) */}
      <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
      <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
      <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    </svg>
    </div>
    } 
    </>
      :
    <></>
    }

    {form.tooch_number.includes("62-pob-63-miss-pon-pob-64-pob")
    ||form.tooch_number.includes("62-pob-63-miss-pon-pob-64-miss-pon-pob-65-pob") ?
    <>
    {/* pob 18 - 16 */}
    {form.tooch_number.includes("62-pob-63-miss-pon-pob-64-pob") &&
    <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
    <svg width="100%" height="20vh">


        {/* Garis ke samping dan ke bawah (L-shape) */}
        <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
      </svg>
    </div>
    }
                          
    {/* pob 18 - 15 */}
    {form.tooch_number.includes("62-pob-63-miss-pon-pob-64-miss-pon-pob-65-pob") &&
   <div style={{ width: "173px", textAlign: "center", position: "absolute", height:"10vh" }}>
   <svg width="100%" height="20vh">

      {/* Garis ke samping dan ke bawah (L-shape) */}
      <line x1="20" y1="1.5" x2="153" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
      <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
      <line x1="153" y1="0" x2="153" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
    </svg>
    </div>
    }  
    </>
      :
    <></>
    }


    <div style={{ textAlign: "center" }}>62</div>
    <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("62-fmc") || form.tooch_number.includes("62-poc") || form.tooch_number.includes("62-ipx-poc") || form.tooch_number.match(/\b62-pob\b/) || form.tooch_number.match(/\b62-miss-pon-pob\b/) || form.tooch_number.match(/\b62-meb\b/) || form.tooch_number.match(/\b62-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
    {form.tooch_number.includes("62-poc") || form.tooch_number.includes("62-ipx-poc") || form.tooch_number.match(/\b62-pob\b/) || form.tooch_number.match(/\b62-miss-pon-pob\b/) ?
    <g>
    {/* Atas */}
    {form.tooch_number.includes("62L-fis") || form.tooch_number.includes("62L-amf") || form.tooch_number.includes("62L-cof") || form.tooch_number.includes("62L-car") ?
    <>
      {form.tooch_number.includes("62L-fis") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="62L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
              <rect width="8" height="8" fill="#ffb8c6" />
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 62L"
            points="0,0 40,0 30,20 10,20"
            style={{ fill: 'url(#62L-fis)', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("62-Labial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />   
        </svg>
      }

      {form.tooch_number.includes("62L-amf") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 62L"
          points="0,0 40,0 30,20 10,20"
          style={{ fill: 'black', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("62-Labial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

      {form.tooch_number.includes("62L-cof") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="62L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 62L"
            points="0,0 40,0 30,20 10,20"
            style={{ fill: '#007610', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("62-Labial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("62L-car") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 62L"
          points="0,0 40,0 30,20 10,20"
          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
          onMouseEnter={() => setInfoGigi("62-Labial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

    </>
    :
    <svg width="40" height="40">
      <defs>
        <pattern id="62L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
        </pattern>
      </defs>
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 62L"
        points="0,0 40,0 30,20 10,20"
        style={{ fill: '#007610', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("62-Labial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    </svg>
    }

    {/* Bawah */}
    {form.tooch_number.includes("62P-fis") || form.tooch_number.includes("62P-amf") || form.tooch_number.includes("62P-cof") || form.tooch_number.includes("62P-car") ?
    <>
      {form.tooch_number.includes("62P-fis") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="62P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
              <rect width="8" height="8" fill="#ffb8c6" />
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>

          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 62P"
            points="30,20 40,40 0,40 10,20"
            style={{ fill: 'url(#62P-fis)', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("62-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("62P-amf") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 62P"
          points="30,20 40,40 0,40 10,20"
          style={{ fill: 'black', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("62-Palatal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

      {form.tooch_number.includes("62P-cof") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="62P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 62P"
            points="30,20 40,40 0,40 10,20"
            style={{ fill: '#007610', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("62-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("62P-car") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 62P"
          points="30,20 40,40 0,40 10,20"
          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
          onMouseEnter={() => setInfoGigi("62-Palatal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }
    </>
    :
    <svg width="40" height="40">
      <defs>
        <pattern id="62P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
        </pattern>
      </defs>
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 62P"
        points="30,20 40,40 0,40 10,20"
        style={{ fill: '#007610', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("62-Palatal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    </svg>
    }

    {/* Kanan */}
    {form.tooch_number.includes("62D-fis") || form.tooch_number.includes("62D-amf") || form.tooch_number.includes("62D-cof") || form.tooch_number.includes("62D-car") ?
    <>
      {form.tooch_number.includes("62D-fis") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="62D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
              <rect width="8" height="8" fill="#ffb8c6" />
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 62D"
            points="30,20 40,0 40,40 35,30"
            style={{ fill: 'url(#62D-fis)', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("62-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("62D-amf") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 62D"
          points="30,20 40,0 40,40 35,30"
          style={{ fill: 'black', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("62-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

      {form.tooch_number.includes("62D-cof") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="62D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 62D"
            points="30,20 40,0 40,40 35,30"
            style={{ fill: '#007610', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("62-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("62D-car") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 62D"
          points="30,20 40,0 40,40 35,30"
          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
          onMouseEnter={() => setInfoGigi("62-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

    </>
    :
    <svg width="40" height="40">
      <defs>
        <pattern id="62D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
        </pattern>
      </defs>
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 62D"
        points="30,20 40,0 40,40 35,30"
        style={{ fill: '#007610', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("62-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    </svg>
    }

    {/* Kiri */}
    {form.tooch_number.includes("62M-fis") || form.tooch_number.includes("62M-amf") || form.tooch_number.includes("62M-cof") || form.tooch_number.includes("62M-car") ?
      <>
        {form.tooch_number.includes("62M-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="62M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 62M"
              points="0,0 5,10 10,20 0,40"
              style={{ fill: 'url(#62M-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("62-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("62M-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 62M"
            points="0,0 5,10 10,20 0,40"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("62-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("62M-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="62M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 62M"
              points="0,0 5,10 10,20 0,40"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("62-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("62M-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 62M"
            points="0,0 5,10 10,20 0,40"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("62-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

      </>
      :
      <svg width="40" height="40">
        <defs>
          <pattern id="62M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 62M"
          points="0,0 5,10 10,20 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("62-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {/* cfr */}
    {form.tooch_number.includes("62-cfr") &&
    <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
      #
    </text>
    }

    {/* rrx */}
    {form.tooch_number.includes("62-rrx") &&    
    <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 62-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
    v
    </text>
    }
        
    {/* mis */}
    {form.tooch_number.includes("62-mis") &&    
    <>  
    <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
    <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
    </> 
    }
          
    </g>
    :
    <g>
    {/* Atas */}
    {form.tooch_number.includes("62L-fis") || form.tooch_number.includes("62L-amf") || form.tooch_number.includes("62L-cof") || form.tooch_number.includes("62L-car") ?
    <>
      {form.tooch_number.includes("62L-fis") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="62L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
              <rect width="8" height="8" fill="#ffb8c6" />
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 62L"
            points="0,0 40,0 30,20 10,20"
            style={{ fill: 'url(#62L-fis)', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("62-Labial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("62L-amf") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 62L"
          points="0,0 40,0 30,20 10,20"
          style={{ fill: 'black', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("62-Labial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

      {form.tooch_number.includes("62L-cof") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="62L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 62L"
            points="0,0 40,0 30,20 10,20"
            style={{ fill: '#007610', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("62-Labial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("62L-car") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 62L"
          points="0,0 40,0 30,20 10,20"
          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
          onMouseEnter={() => setInfoGigi("62-Labial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

    </>
    :
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 62L"
      points="0,0 40,0 30,20 10,20"
      style={{ fill: 'white', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("62-Labial")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
    }

    {/* Bawah */}
    {form.tooch_number.includes("62P-fis") || form.tooch_number.includes("62P-amf") || form.tooch_number.includes("62P-cof") || form.tooch_number.includes("62P-car") ?
    <>
      {form.tooch_number.includes("62P-fis") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="62P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
              <rect width="8" height="8" fill="#ffb8c6" />
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>

          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 62P"
            points="30,20 40,40 0,40 10,20"
            style={{ fill: 'url(#62P-fis)', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("62-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("62P-amf") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 62P"
          points="30,20 40,40 0,40 10,20"
          style={{ fill: 'black', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("62-Palatal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

      {form.tooch_number.includes("62P-cof") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="62P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 62P"
            points="30,20 40,40 0,40 10,20"
            style={{ fill: '#007610', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("62-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("62P-car") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 62P"
          points="30,20 40,40 0,40 10,20"
          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
          onMouseEnter={() => setInfoGigi("62-Palatal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }
    </>
    :
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 62P"
      points="30,20 40,40 0,40 10,20"
      style={{ fill: 'white', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("62-Palatal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
    }

    {/* Kanan */}
    {form.tooch_number.includes("62D-fis") || form.tooch_number.includes("62D-amf") || form.tooch_number.includes("62D-cof") || form.tooch_number.includes("62D-car") ?
    <>
      {form.tooch_number.includes("62D-fis") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="62D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
              <rect width="8" height="8" fill="#ffb8c6" />
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 62D"
            points="30,20 40,0 40,40 35,30"
            style={{ fill: 'url(#62D-fis)', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("62-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("62D-amf") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 62D"
          points="30,20 40,0 40,40 35,30"
          style={{ fill: 'black', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("62-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

      {form.tooch_number.includes("62D-cof") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="62D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 62D"
            points="30,20 40,0 40,40 35,30"
            style={{ fill: '#007610', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("62-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("62D-car") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 62D"
          points="30,20 40,0 40,40 35,30"
          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
          onMouseEnter={() => setInfoGigi("62-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

    </>
    :
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 62D"
      points="30,20 40,0 40,40 35,30"
      style={{ fill: 'white', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("62-Distal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
    }

    {/* Kiri */}
    {form.tooch_number.includes("62M-fis") || form.tooch_number.includes("62M-amf") || form.tooch_number.includes("62M-cof") || form.tooch_number.includes("62M-car") ?
    <>
    {form.tooch_number.includes("62M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="62M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 62M"
          points="0,0 5,10 10,20 0,40"
          style={{ fill: 'url(#62M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("62-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("62M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 62M"
        points="0,0 5,10 10,20 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("62-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("62M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="62M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 62M"
          points="0,0 5,10 10,20 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("62-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("62M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 62M"
        points="0,0 5,10 10,20 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("62-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    </>
    :
    <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 62M"
    points="0,0 5,10 10,20 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("62-Mesial")}
    onMouseLeave={() => setInfoGigi("XX-X")}
    />
    }

    {/* cfr */}
    {form.tooch_number.includes("62-cfr") &&
    <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
      #
    </text>
    }

    {form.tooch_number.includes("62-rrx") &&    
    <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 62-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
    v
    </text>
    }

    {form.tooch_number.includes("62-mis") &&    
    <>  
    <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
    <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
    </> 
    }
    </g>
    }    

    </svg>

    {form.tooch_number.includes("62-nvt") &&   
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
    <svg width={42} height={42}>
    <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 62"
    points="21,2 41,32 1,32" 
    style={{ fill: 'white', stroke: 'black', strokeWidth: 3 }}
    onMouseEnter={() => setInfoGigi('62')}
    onMouseLeave={() => setInfoGigi('XX-X')}
    />
    </svg> 
    </div>
    }  

    {form.tooch_number.includes("62-rct") &&   
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
    <svg width={42} height={42}>
    <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 62"
    points="21,2 41,32 1,32" 
    style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
    onMouseEnter={() => setInfoGigi('62')}
    onMouseLeave={() => setInfoGigi('XX-X')}
    />
    </svg>
    </div>
    } 

    {form.tooch_number.includes("62B-amf-rct") || form.tooch_number.includes("62P-amf-rct") || form.tooch_number.includes("62M-amf-rct") || form.tooch_number.includes("62D-amf-rct") || form.tooch_number.includes("62L-amf-rct") ||
    form.tooch_number.includes("62B-cof-rct") || form.tooch_number.includes("62P-cof-rct") || form.tooch_number.includes("62M-cof-rct") || form.tooch_number.includes("62D-cof-rct") || form.tooch_number.includes("62L-cof-rct") ||
    form.tooch_number.includes("62-fmc-rct") ||
    form.tooch_number.includes("62-poc-rct") ?
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
    <svg width={42} height={42}>
    <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 62B"
    points="21,2 41,32 1,32" 
    style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
    onMouseEnter={() => setInfoGigi('62-Bukal')}
    onMouseLeave={() => setInfoGigi('XX-X')}
    />
    </svg>
    </div>
    :
    <></>
    } 

    </div>  

    <div style={{ width: "42px", margin: "1px" }}>
    {form.tooch_number.includes("63-une") || form.tooch_number.includes("63-non") || form.tooch_number.includes("63-pre") || form.tooch_number.includes("63-ano") || form.tooch_number.includes("63-ipx-poc") || form.tooch_number.includes("63-mis-prd-acr") || form.tooch_number.includes("63-mis-fld-acr") ?
    <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
    {form.tooch_number.includes("63-une") &&
    <div style={{ position: "absolute", color: "black" }}>UNE</div>
    }
    {form.tooch_number.includes("63-non") &&
    <div style={{ position: "absolute", color: "black" }}>NON</div>
    }      
    {form.tooch_number.includes("63-pre") &&
    <div style={{ position: "absolute", color: "black" }}>PRE</div>
    }      
    {form.tooch_number.includes("63-ano") &&
    <div style={{ position: "absolute", color: "black" }}>ANO</div>
    }
    {form.tooch_number.includes("63-ipx-poc") &&
    <div style={{ position: "absolute", color: "black" }}>IPX</div>
    }
    {form.tooch_number.includes("63-mis-prd-acr") &&
    <div style={{ position: "absolute", color: "black" }}>PRD</div>
    } 
    {form.tooch_number.includes("63-mis-fld-acr") &&
    <div style={{ position: "absolute", color: "black" }}>FLD</div>
    }      
    </div>
    :
    <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
    </div>
    }

    {form.tooch_number.includes("63-meb-64-miss-pon-meb-65-meb") ?
    <>
    {/* meb 18 - 16 */}
    {form.tooch_number.includes("63-meb-64-miss-pon-meb-65-meb") &&
   <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
   <svg width="100%" height="20vh">

        {/* Garis ke samping dan ke bawah (L-shape) */}
        <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
      </svg>
    </div>
    }
                          
    </>
      :
    <></>
    }

    {form.tooch_number.includes("63-pob-64-miss-pon-pob-65-pob") ?
    <>
    {/* pob 18 - 16 */}
    {form.tooch_number.includes("63-pob-64-miss-pon-pob-65-pob") &&
    <div style={{ width: "130px", textAlign: "center", position: "absolute", height:"10vh" }}>
    <svg width="100%" height="20vh">

        {/* Garis ke samping dan ke bawah (L-shape) */}
        <line x1="20" y1="1.5" x2="110" y2="1.5" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="20" y1="0" x2="20" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
        <line x1="110" y1="0" x2="110" y2="12%" style={{ stroke: 'black', strokeWidth: 2 }} />
      </svg>
    </div>
    }
                          
    </>
      :
    <></>
    }


    <div style={{ textAlign: "center" }}>63</div>
    <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("63-fmc") || form.tooch_number.includes("63-poc") || form.tooch_number.includes("63-ipx-poc") || form.tooch_number.match(/\b63-pob\b/) || form.tooch_number.match(/\b63-miss-pon-pob\b/) || form.tooch_number.match(/\b63-meb\b/) || form.tooch_number.match(/\b63-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
    {form.tooch_number.includes("63-poc") || form.tooch_number.includes("63-ipx-poc") || form.tooch_number.match(/\b63-pob\b/) || form.tooch_number.match(/\b63-miss-pon-pob\b/) ?
    <g>
    {/* Atas */}
    {form.tooch_number.includes("63L-fis") || form.tooch_number.includes("63L-amf") || form.tooch_number.includes("63L-cof") || form.tooch_number.includes("63L-car") ?
    <>
      {form.tooch_number.includes("63L-fis") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="63L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
              <rect width="8" height="8" fill="#ffb8c6" />
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 63L"
            points="0,0 40,0 30,20 10,20"
            style={{ fill: 'url(#63L-fis)', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("63-Labial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />   
        </svg>
      }

      {form.tooch_number.includes("63L-amf") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 63L"
          points="0,0 40,0 30,20 10,20"
          style={{ fill: 'black', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("63-Labial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

      {form.tooch_number.includes("63L-cof") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="63L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 63L"
            points="0,0 40,0 30,20 10,20"
            style={{ fill: '#007610', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("63-Labial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("63L-car") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 63L"
          points="0,0 40,0 30,20 10,20"
          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
          onMouseEnter={() => setInfoGigi("63-Labial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

    </>
    :
    <svg width="40" height="40">
      <defs>
        <pattern id="63L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
        </pattern>
      </defs>
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 63L"
        points="0,0 40,0 30,20 10,20"
        style={{ fill: '#007610', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("63-Labial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    </svg>
    }

    {/* Bawah */}
    {form.tooch_number.includes("63P-fis") || form.tooch_number.includes("63P-amf") || form.tooch_number.includes("63P-cof") || form.tooch_number.includes("63P-car") ?
    <>
      {form.tooch_number.includes("63P-fis") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="63P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
              <rect width="8" height="8" fill="#ffb8c6" />
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>

          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 63P"
            points="30,20 40,40 0,40 10,20"
            style={{ fill: 'url(#63P-fis)', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("63-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("63P-amf") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 63P"
          points="30,20 40,40 0,40 10,20"
          style={{ fill: 'black', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("63-Palatal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

      {form.tooch_number.includes("63P-cof") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="63P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 63P"
            points="30,20 40,40 0,40 10,20"
            style={{ fill: '#007610', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("63-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("63P-car") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 63P"
          points="30,20 40,40 0,40 10,20"
          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
          onMouseEnter={() => setInfoGigi("63-Palatal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }
    </>
    :
    <svg width="40" height="40">
      <defs>
        <pattern id="63P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
        </pattern>
      </defs>
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 63P"
        points="30,20 40,40 0,40 10,20"
        style={{ fill: '#007610', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("63-Palatal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    </svg>
    }

    {/* Kanan */}
    {form.tooch_number.includes("63D-fis") || form.tooch_number.includes("63D-amf") || form.tooch_number.includes("63D-cof") || form.tooch_number.includes("63D-car") ?
    <>
      {form.tooch_number.includes("63D-fis") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="63D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
              <rect width="8" height="8" fill="#ffb8c6" />
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 63D"
            points="30,20 40,0 40,40 35,30"
            style={{ fill: 'url(#63D-fis)', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("63-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("63D-amf") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 63D"
          points="30,20 40,0 40,40 35,30"
          style={{ fill: 'black', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("63-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

      {form.tooch_number.includes("63D-cof") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="63D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 63D"
            points="30,20 40,0 40,40 35,30"
            style={{ fill: '#007610', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("63-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("63D-car") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 63D"
          points="30,20 40,0 40,40 35,30"
          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
          onMouseEnter={() => setInfoGigi("63-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

    </>
    :
    <svg width="40" height="40">
      <defs>
        <pattern id="63D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
        </pattern>
      </defs>
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 63D"
        points="30,20 40,0 40,40 35,30"
        style={{ fill: '#007610', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("63-Distal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    </svg>
    }

    {/* Kiri */}
    {form.tooch_number.includes("63M-fis") || form.tooch_number.includes("63M-amf") || form.tooch_number.includes("63M-cof") || form.tooch_number.includes("63M-car") ?
      <>
        {form.tooch_number.includes("63M-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="63M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 63M"
              points="0,0 5,10 10,20 0,40"
              style={{ fill: 'url(#63M-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("63-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("63M-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 63M"
            points="0,0 5,10 10,20 0,40"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("63-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("63M-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="63M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 63M"
              points="0,0 5,10 10,20 0,40"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("63-Mesial")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("63M-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 63M"
            points="0,0 5,10 10,20 0,40"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("63-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

      </>
      :
      <svg width="40" height="40">
        <defs>
          <pattern id="63M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 63M"
          points="0,0 5,10 10,20 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("63-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {/* cfr */}
    {form.tooch_number.includes("63-cfr") &&
    <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
      #
    </text>
    }

    {/* rrx */}
    {form.tooch_number.includes("63-rrx") &&    
    <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 63-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
    v
    </text>
    }
        
    {/* mis */}
    {form.tooch_number.includes("63-mis") &&    
    <>  
    <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
    <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
    </> 
    }
          
    </g>
    :
    <g>
    {/* Atas */}
    {form.tooch_number.includes("63L-fis") || form.tooch_number.includes("63L-amf") || form.tooch_number.includes("63L-cof") || form.tooch_number.includes("63L-car") ?
    <>
      {form.tooch_number.includes("63L-fis") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="63L-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
              <rect width="8" height="8" fill="#ffb8c6" />
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 63L"
            points="0,0 40,0 30,20 10,20"
            style={{ fill: 'url(#63L-fis)', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("63-Labial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("63L-amf") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 63L"
          points="0,0 40,0 30,20 10,20"
          style={{ fill: 'black', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("63-Labial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

      {form.tooch_number.includes("63L-cof") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="63L-cof" width="2" height="4" patternUnits="userSpaceOnUse">
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 63L"
            points="0,0 40,0 30,20 10,20"
            style={{ fill: '#007610', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("63-Labial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("63L-car") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 63L"
          points="0,0 40,0 30,20 10,20"
          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
          onMouseEnter={() => setInfoGigi("63-Labial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

    </>
    :
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 63L"
      points="0,0 40,0 30,20 10,20"
      style={{ fill: 'white', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("63-Labial")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
    }

    {/* Bawah */}
    {form.tooch_number.includes("63P-fis") || form.tooch_number.includes("63P-amf") || form.tooch_number.includes("63P-cof") || form.tooch_number.includes("63P-car") ?
    <>
      {form.tooch_number.includes("63P-fis") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="63P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
              <rect width="8" height="8" fill="#ffb8c6" />
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>

          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 63P"
            points="30,20 40,40 0,40 10,20"
            style={{ fill: 'url(#63P-fis)', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("63-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("63P-amf") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 63P"
          points="30,20 40,40 0,40 10,20"
          style={{ fill: 'black', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("63-Palatal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

      {form.tooch_number.includes("63P-cof") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="63P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 63P"
            points="30,20 40,40 0,40 10,20"
            style={{ fill: '#007610', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("63-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("63P-car") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 63P"
          points="30,20 40,40 0,40 10,20"
          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
          onMouseEnter={() => setInfoGigi("63-Palatal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }
    </>
    :
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 63P"
      points="30,20 40,40 0,40 10,20"
      style={{ fill: 'white', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("63-Palatal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
    }

    {/* Kanan */}
    {form.tooch_number.includes("63D-fis") || form.tooch_number.includes("63D-amf") || form.tooch_number.includes("63D-cof") || form.tooch_number.includes("63D-car") ?
    <>
      {form.tooch_number.includes("63D-fis") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="63D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
              <rect width="8" height="8" fill="#ffb8c6" />
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 63D"
            points="30,20 40,0 40,40 35,30"
            style={{ fill: 'url(#63D-fis)', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("63-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("63D-amf") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 63D"
          points="30,20 40,0 40,40 35,30"
          style={{ fill: 'black', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("63-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

      {form.tooch_number.includes("63D-cof") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="63D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 63D"
            points="30,20 40,0 40,40 35,30"
            style={{ fill: '#007610', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("63-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("63D-car") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 63D"
          points="30,20 40,0 40,40 35,30"
          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
          onMouseEnter={() => setInfoGigi("63-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

    </>
    :
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 63D"
      points="30,20 40,0 40,40 35,30"
      style={{ fill: 'white', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("63-Distal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
    }

    {/* Kiri */}
    {form.tooch_number.includes("63M-fis") || form.tooch_number.includes("63M-amf") || form.tooch_number.includes("63M-cof") || form.tooch_number.includes("63M-car") ?
    <>
    {form.tooch_number.includes("63M-fis") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="63M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
            <rect width="8" height="8" fill="#ffb8c6" />
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 63M"
          points="0,0 5,10 10,20 0,40"
          style={{ fill: 'url(#63M-fis)', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("63-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("63M-amf") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 63M"
        points="0,0 5,10 10,20 0,40"
        style={{ fill: 'black', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("63-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {form.tooch_number.includes("63M-cof") &&
      <svg width="40" height="40">
        <defs>
          <pattern id="63M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 63M"
          points="0,0 5,10 10,20 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("63-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {form.tooch_number.includes("63M-car") &&
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 63M"
        points="0,0 5,10 10,20 0,40"
        style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
        onMouseEnter={() => setInfoGigi("63-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    </>
    :
    <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 63M"
    points="0,0 5,10 10,20 0,40"
    style={{ fill: 'white', stroke: 'black' }}
    onMouseEnter={() => setInfoGigi("63-Mesial")}
    onMouseLeave={() => setInfoGigi("XX-X")}
    />
    }

    {/* cfr */}
    {form.tooch_number.includes("63-cfr") &&
    <text x="50%" y="24" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
      #
    </text>
    }

    {form.tooch_number.includes("63-rrx") &&    
    <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 63-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
    v
    </text>
    }

    {form.tooch_number.includes("63-mis") &&    
    <>  
    <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
    <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
    </> 
    }
    </g>
    }    

    </svg>

    {form.tooch_number.includes("63-nvt") &&   
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
    <svg width={42} height={42}>
    <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 63"
    points="21,2 41,32 1,32" 
    style={{ fill: 'white', stroke: 'black', strokeWidth: 3 }}
    onMouseEnter={() => setInfoGigi('63')}
    onMouseLeave={() => setInfoGigi('XX-X')}
    />
    </svg> 
    </div>
    }  

    {form.tooch_number.includes("63-rct") &&   
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
    <svg width={42} height={42}>
    <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 63"
    points="21,2 41,32 1,32" 
    style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
    onMouseEnter={() => setInfoGigi('63')}
    onMouseLeave={() => setInfoGigi('XX-X')}
    />
    </svg>
    </div>
    } 

    {form.tooch_number.includes("63B-amf-rct") || form.tooch_number.includes("63P-amf-rct") || form.tooch_number.includes("63M-amf-rct") || form.tooch_number.includes("63D-amf-rct") || form.tooch_number.includes("63L-amf-rct") ||
    form.tooch_number.includes("63B-cof-rct") || form.tooch_number.includes("63P-cof-rct") || form.tooch_number.includes("63M-cof-rct") || form.tooch_number.includes("63D-cof-rct") || form.tooch_number.includes("63L-cof-rct") ||
    form.tooch_number.includes("63-fmc-rct") ||
    form.tooch_number.includes("63-poc-rct") ?
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
    <svg width={42} height={42}>
    <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 63B"
    points="21,2 41,32 1,32" 
    style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
    onMouseEnter={() => setInfoGigi('63-Bukal')}
    onMouseLeave={() => setInfoGigi('XX-X')}
    />
    </svg>
    </div>
    :
    <></>
    } 

    </div>  

    <div style={{ width: "42px", margin: "1px" }}>
    {form.tooch_number.includes("64-une") || form.tooch_number.includes("64-non") || form.tooch_number.includes("64-pre") || form.tooch_number.includes("64-ano") || form.tooch_number.includes("64-ipx-poc") || form.tooch_number.includes("64-mis-prd-acr") || form.tooch_number.includes("64-mis-fld-acr") ?
    <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
    {form.tooch_number.includes("64-une") &&
    <div style={{ position: "absolute", color: "black" }}>UNE</div>
    }
    {form.tooch_number.includes("64-non") &&
    <div style={{ position: "absolute", color: "black" }}>NON</div>
    }      
    {form.tooch_number.includes("64-pre") &&
    <div style={{ position: "absolute", color: "black" }}>PRE</div>
    }      
    {form.tooch_number.includes("64-ano") &&
    <div style={{ position: "absolute", color: "black" }}>ANO</div>
    }
    {form.tooch_number.includes("64-ipx-poc") &&
    <div style={{ position: "absolute", color: "black" }}>IPX</div>
    }
    {form.tooch_number.includes("64-mis-prd-acr") &&
    <div style={{ position: "absolute", color: "black" }}>PRD</div>
    } 
    {form.tooch_number.includes("64-mis-fld-acr") &&
    <div style={{ position: "absolute", color: "black" }}>FLD</div>
    }      
    </div>
    :
    <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
    </div>
    }


    <div style={{ textAlign: "center" }}>64</div>
    <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("64-fmc") || form.tooch_number.includes("64-poc") || form.tooch_number.includes("64-ipx-poc") || form.tooch_number.match(/\b64-pob\b/) || form.tooch_number.match(/\b64-miss-pon-pob\b/) || form.tooch_number.match(/\b64-meb\b/) || form.tooch_number.match(/\b64-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
    {form.tooch_number.includes("64-poc") || form.tooch_number.includes("64-ipx-poc") || form.tooch_number.match(/\b64-pob\b/) || form.tooch_number.match(/\b64-miss-pon-pob\b/) ?
    <g>
    {/* Atas */}
    {form.tooch_number.includes("64B-fis") || form.tooch_number.includes("64B-amf") || form.tooch_number.includes("64B-cof") || form.tooch_number.includes("64B-car") ?
      <>
        {form.tooch_number.includes("64B-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="64B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 64B"
              points="0,0 40,0 30,10 10,10"
              style={{ fill: 'url(#64B-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("64-Bukal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("64B-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 64B"
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("64-Bukal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("64B-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="64B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 64B"
              points="0,0 40,0 30,10 10,10"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("64-Bukal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("64B-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 64B"
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("64-Bukal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      
      </>
      :
    <svg width="40" height="40">
      <defs>
        <pattern id="64B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
        </pattern>
      </defs>
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 64B"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: '#007610', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("64-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    </svg>
    }

    {/* Bawah */}
    {form.tooch_number.includes("64P-fis") || form.tooch_number.includes("64P-amf") || form.tooch_number.includes("64P-cof") || form.tooch_number.includes("64P-car") ?
      <>
        {form.tooch_number.includes("64P-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="64P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>

            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 64P"
              points="10,30 30,30 40,40 0,40"
              style={{ fill: 'url(#64P-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("64-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("64P-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 64P"
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("64-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("64P-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="64P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 64P"
              points="10,30 30,30 40,40 0,40"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("64-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("64P-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 64P"
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("64-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      </>
      :
      <svg width="40" height="40">
        <defs>
          <pattern id="64P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 64P"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("64-Palatal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {/* Kanan */}
      {form.tooch_number.includes("64D-fis") || form.tooch_number.includes("64D-amf") || form.tooch_number.includes("64D-cof") || form.tooch_number.includes("64D-car") ?
      <>
        {form.tooch_number.includes("64D-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="64D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 64D"
              points="30,10 40,0 40,40 30,30"
              style={{ fill: 'url(#64D-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("64-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("64D-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 64D"
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("64-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("64D-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="64D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 64D"
              points="30,10 40,0 40,40 30,30"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("64-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }
      
        {form.tooch_number.includes("64D-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 64D"
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("64-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      
      </>
      :
      <svg width="40" height="40">
        <defs>
          <pattern id="64D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 64D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("64-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {/* Kiri */}
    {form.tooch_number.includes("64M-fis") || form.tooch_number.includes("64M-amf") || form.tooch_number.includes("64M-cof") || form.tooch_number.includes("64M-car") ?
    <>
      {form.tooch_number.includes("64M-fis") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="64M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
              <rect width="8" height="8" fill="#ffb8c6" />
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 64M"
            points="0,0 10,10 10,30 0,40"
            style={{ fill: 'url(#64M-fis)', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("64-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("64M-amf") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 64M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'black', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("64-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

      {form.tooch_number.includes("64M-cof") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="64M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 64M"
            points="0,0 10,10 10,30 0,40"
            style={{ fill: '#007610', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("64-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("64M-car") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 64M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
          onMouseEnter={() => setInfoGigi("64-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

    </>
    :
    <svg width="40" height="40">
      <defs>
        <pattern id="64M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
        </pattern>
      </defs>
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 64M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: '#007610', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("64-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    </svg>
    }

    {/* center */}
    {form.tooch_number.includes("64O-fis") || form.tooch_number.includes("64O-amf") || form.tooch_number.includes("64O-cof") || form.tooch_number.includes("64O-car") ?
      <>
        {form.tooch_number.includes("64O-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="64O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 64O"
              points="10,10 30,10 30,30 10,30"
              style={{ fill: 'url(#64O-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("64-Oklusal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("64O-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 64O"
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("64-Oklusal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("64O-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="64O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 64O"
              points="10,10 30,10 30,30 10,30"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("64-Oklusal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("64O-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 64O"
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("64-Oklusal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

      </>
      :
      <svg width="40" height="40">
        <defs>
          <pattern id="64O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 64O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("64-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {/* cfr */}
    {form.tooch_number.includes("64-cfr") &&
      <text x="50%" y="64" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
        #
      </text>
    }

    {/* rrx */}
    {form.tooch_number.includes("64-rrx") &&    
    <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 64-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
      v
    </text>
    }
          
    {/* mis */}
    {form.tooch_number.includes("64-mis") &&    
    <>  
      <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
      <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
    </> 
    }
            
    </g>
    :
    <g>
    {/* Atas */}
    {form.tooch_number.includes("64B-fis") || form.tooch_number.includes("64B-amf") || form.tooch_number.includes("64B-cof") || form.tooch_number.includes("64B-car") ?
      <>
        {form.tooch_number.includes("64B-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="64B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 64B"
              points="0,0 40,0 30,10 10,10"
              style={{ fill: 'url(#64B-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("64-Bukal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("64B-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 64B"
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("64-Bukal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("64B-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="64B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 64B"
              points="0,0 40,0 30,10 10,10"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("64-Bukal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("64B-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 64B"
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("64-Bukal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      
      </>
      :
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 64B"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("64-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {/* Bawah */}
    {form.tooch_number.includes("64P-fis") || form.tooch_number.includes("64P-amf") || form.tooch_number.includes("64P-cof") || form.tooch_number.includes("64P-car") ?
      <>
        {form.tooch_number.includes("64P-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="64P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>

            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 64P"
              points="10,30 30,30 40,40 0,40"
              style={{ fill: 'url(#64P-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("64-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("64P-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 64P"
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("64-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("64P-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="64P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 64P"
              points="10,30 30,30 40,40 0,40"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("64-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("64P-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 64P"
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("64-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      </>
      :
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 64P"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("64-Palatal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {/* Kanan */}
    {form.tooch_number.includes("64D-fis") || form.tooch_number.includes("64D-amf") || form.tooch_number.includes("64D-cof") || form.tooch_number.includes("64D-car") ?
    <>
      {form.tooch_number.includes("64D-fis") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="64D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
              <rect width="8" height="8" fill="#ffb8c6" />
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 64D"
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'url(#64D-fis)', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("64-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("64D-amf") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 64D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'black', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("64-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

      {form.tooch_number.includes("64D-cof") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="64D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 64D"
            points="30,10 40,0 40,40 30,30"
            style={{ fill: '#007610', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("64-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("64D-car") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 64D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
          onMouseEnter={() => setInfoGigi("64-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

    </>
    :
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 64D"
      points="30,10 40,0 40,40 30,30"
      style={{ fill: 'white', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("64-Distal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
    }

    {/* Kiri */}
    {form.tooch_number.includes("64M-fis") || form.tooch_number.includes("64M-amf") || form.tooch_number.includes("64M-cof") || form.tooch_number.includes("64M-car") ?
    <>
      {form.tooch_number.includes("64M-fis") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="64M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
              <rect width="8" height="8" fill="#ffb8c6" />
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 64M"
            points="0,0 10,10 10,30 0,40"
            style={{ fill: 'url(#64M-fis)', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("64-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("64M-amf") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 64M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'black', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("64-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

      {form.tooch_number.includes("64M-cof") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="64M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 64M"
            points="0,0 10,10 10,30 0,40"
            style={{ fill: '#007610', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("64-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("64M-car") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 64M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
          onMouseEnter={() => setInfoGigi("64-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

    </>
    :
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 64M"
      points="0,0 10,10 10,30 0,40"
      style={{ fill: 'white', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("64-Mesial")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
    }

    {/* center */}
    {form.tooch_number.includes("64O-fis") || form.tooch_number.includes("64O-amf") || form.tooch_number.includes("64O-cof") || form.tooch_number.includes("64O-car") ?
      <>
        {form.tooch_number.includes("64O-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="64O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 64O"
              points="10,10 30,10 30,30 10,30"
              style={{ fill: 'url(#64O-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("64-Oklusal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("64O-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 64O"
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("64-Oklusal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("64O-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="64O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 64O"
              points="10,10 30,10 30,30 10,30"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("64-Oklusal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("64O-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 64O"
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("64-Oklusal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

      </>
      :
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 64O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("64-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {/* cfr */}
    {form.tooch_number.includes("64-cfr") &&
      <text x="50%" y="64" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
        #
      </text>
    }

    {form.tooch_number.includes("64-rrx") &&    
    <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 64-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
      v
    </text>
    }

    {form.tooch_number.includes("64-mis") &&    
    <>  
      <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
      <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
    </> 
    }
    </g>
    }      
    </svg>

    {form.tooch_number.includes("64-nvt") &&   
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
    <svg width={42} height={42}>
    <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 64"
    points="21,2 41,32 1,32" 
    style={{ fill: 'white', stroke: 'black', strokeWidth: 3 }}
    onMouseEnter={() => setInfoGigi('64')}
    onMouseLeave={() => setInfoGigi('XX-X')}
    />
    </svg> 
    </div>
    }  

    {form.tooch_number.includes("64-rct") &&   
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
    <svg width={42} height={42}>
    <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 64"
    points="21,2 41,32 1,32" 
    style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
    onMouseEnter={() => setInfoGigi('64')}
    onMouseLeave={() => setInfoGigi('XX-X')}
    />
    </svg>
    </div>
    } 

    {form.tooch_number.includes("64B-amf-rct") || form.tooch_number.includes("64P-amf-rct") || form.tooch_number.includes("64M-amf-rct") || form.tooch_number.includes("64D-amf-rct") || form.tooch_number.includes("64O-amf-rct") ||
    form.tooch_number.includes("64B-cof-rct") || form.tooch_number.includes("64P-cof-rct") || form.tooch_number.includes("64M-cof-rct") || form.tooch_number.includes("64D-cof-rct") || form.tooch_number.includes("64O-cof-rct") ||
    form.tooch_number.includes("64-fmc-rct") ||
    form.tooch_number.includes("64-poc-rct") ?
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
    <svg width={42} height={42}>
    <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 64B"
    points="21,2 41,32 1,32" 
    style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
    onMouseEnter={() => setInfoGigi('64-Bukal')}
    onMouseLeave={() => setInfoGigi('XX-X')}
    />
    </svg>
    </div>
    :
    <></>
    } 

    </div>

    <div style={{ width: "42px", margin: "1px" }}>
    {form.tooch_number.includes("65-une") || form.tooch_number.includes("65-non") || form.tooch_number.includes("65-pre") || form.tooch_number.includes("65-ano") || form.tooch_number.includes("65-ipx-poc") || form.tooch_number.includes("65-mis-prd-acr") || form.tooch_number.includes("65-mis-fld-acr") ?
    <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
    {form.tooch_number.includes("65-une") &&
    <div style={{ position: "absolute", color: "black" }}>UNE</div>
    }
    {form.tooch_number.includes("65-non") &&
    <div style={{ position: "absolute", color: "black" }}>NON</div>
    }      
    {form.tooch_number.includes("65-pre") &&
    <div style={{ position: "absolute", color: "black" }}>PRE</div>
    }      
    {form.tooch_number.includes("65-ano") &&
    <div style={{ position: "absolute", color: "black" }}>ANO</div>
    }
    {form.tooch_number.includes("65-ipx-poc") &&
    <div style={{ position: "absolute", color: "black" }}>IPX</div>
    }
    {form.tooch_number.includes("65-mis-prd-acr") &&
    <div style={{ position: "absolute", color: "black" }}>PRD</div>
    } 
    {form.tooch_number.includes("65-mis-fld-acr") &&
    <div style={{ position: "absolute", color: "black" }}>FLD</div>
    }      
    </div>
    :
    <div style={{ position: "relative", width: "100%", textAlign: "center", display: "flex", justifyContent: "center", paddingBottom: "15px" }}>
    </div>
    }


    <div style={{ textAlign: "center" }}>65</div>
    <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom: "2px solid black", boxShadow: form.tooch_number.includes("65-fmc") || form.tooch_number.includes("65-poc") || form.tooch_number.includes("65-ipx-poc") || form.tooch_number.match(/\b65-pob\b/) || form.tooch_number.match(/\b65-miss-pon-pob\b/) || form.tooch_number.match(/\b65-meb\b/) || form.tooch_number.match(/\b65-miss-pon-meb\b/) ? "0px 0px 0px 2px black" : "" }}>
    {form.tooch_number.includes("65-poc") || form.tooch_number.includes("65-ipx-poc") || form.tooch_number.match(/\b65-pob\b/) || form.tooch_number.match(/\b65-miss-pon-pob\b/) ?
    <g>
    {/* Atas */}
    {form.tooch_number.includes("65B-fis") || form.tooch_number.includes("65B-amf") || form.tooch_number.includes("65B-cof") || form.tooch_number.includes("65B-car") ?
      <>
        {form.tooch_number.includes("65B-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="65B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 65B"
              points="0,0 40,0 30,10 10,10"
              style={{ fill: 'url(#65B-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("65-Bukal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("65B-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 65B"
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("65-Bukal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("65B-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="65B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 65B"
              points="0,0 40,0 30,10 10,10"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("65-Bukal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("65B-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 65B"
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("65-Bukal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      
      </>
      :
    <svg width="40" height="40">
      <defs>
        <pattern id="65B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
        </pattern>
      </defs>
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 65B"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: '#007610', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("65-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    </svg>
    }

    {/* Bawah */}
    {form.tooch_number.includes("65P-fis") || form.tooch_number.includes("65P-amf") || form.tooch_number.includes("65P-cof") || form.tooch_number.includes("65P-car") ?
      <>
        {form.tooch_number.includes("65P-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="65P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>

            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 65P"
              points="10,30 30,30 40,40 0,40"
              style={{ fill: 'url(#65P-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("65-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("65P-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 65P"
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("65-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("65P-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="65P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 65P"
              points="10,30 30,30 40,40 0,40"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("65-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("65P-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 65P"
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("65-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      </>
      :
      <svg width="40" height="40">
        <defs>
          <pattern id="65P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 65P"
          points="10,30 30,30 40,40 0,40"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("65-Palatal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {/* Kanan */}
      {form.tooch_number.includes("65D-fis") || form.tooch_number.includes("65D-amf") || form.tooch_number.includes("65D-cof") || form.tooch_number.includes("65D-car") ?
      <>
        {form.tooch_number.includes("65D-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="65D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 65D"
              points="30,10 40,0 40,40 30,30"
              style={{ fill: 'url(#65D-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("65-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("65D-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 65D"
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("65-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("65D-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="65D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 65D"
              points="30,10 40,0 40,40 30,30"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("65-Distal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }
      
        {form.tooch_number.includes("65D-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 65D"
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("65-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      
      </>
      :
      <svg width="40" height="40">
        <defs>
          <pattern id="65D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 65D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("65-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {/* Kiri */}
    {form.tooch_number.includes("65M-fis") || form.tooch_number.includes("65M-amf") || form.tooch_number.includes("65M-cof") || form.tooch_number.includes("65M-car") ?
    <>
      {form.tooch_number.includes("65M-fis") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="65M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
              <rect width="8" height="8" fill="#ffb8c6" />
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 65M"
            points="0,0 10,10 10,30 0,40"
            style={{ fill: 'url(#65M-fis)', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("65-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("65M-amf") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 65M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'black', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("65-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

      {form.tooch_number.includes("65M-cof") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="65M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 65M"
            points="0,0 10,10 10,30 0,40"
            style={{ fill: '#007610', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("65-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("65M-car") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 65M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
          onMouseEnter={() => setInfoGigi("65-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

    </>
    :
    <svg width="40" height="40">
      <defs>
        <pattern id="65M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
          <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
        </pattern>
      </defs>
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 65M"
        points="0,0 10,10 10,30 0,40"
        style={{ fill: '#007610', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("65-Mesial")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    </svg>
    }

    {/* center */}
    {form.tooch_number.includes("65O-fis") || form.tooch_number.includes("65O-amf") || form.tooch_number.includes("65O-cof") || form.tooch_number.includes("65O-car") ?
      <>
        {form.tooch_number.includes("65O-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="65O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 65O"
              points="10,10 30,10 30,30 10,30"
              style={{ fill: 'url(#65O-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("65-Oklusal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("65O-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 65O"
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("65-Oklusal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("65O-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="65O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 65O"
              points="10,10 30,10 30,30 10,30"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("65-Oklusal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("65O-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 65O"
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("65-Oklusal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

      </>
      :
      <svg width="40" height="40">
        <defs>
          <pattern id="65O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
            <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
          </pattern>
        </defs>
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 65O"
          points="10,10 30,10 30,30 10,30"
          style={{ fill: '#007610', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("65-Oklusal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      </svg>
    }

    {/* cfr */}
    {form.tooch_number.includes("65-cfr") &&
      <text x="50%" y="65" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
        #
      </text>
    }

    {/* rrx */}
    {form.tooch_number.includes("65-rrx") &&    
    <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 65-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
      v
    </text>
    }
          
    {/* mis */}
    {form.tooch_number.includes("65-mis") &&    
    <>  
      <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
      <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
    </> 
    }
            
    </g>
    :
    <g>
    {/* Atas */}
    {form.tooch_number.includes("65B-fis") || form.tooch_number.includes("65B-amf") || form.tooch_number.includes("65B-cof") || form.tooch_number.includes("65B-car") ?
      <>
        {form.tooch_number.includes("65B-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="65B-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 65B"
              points="0,0 40,0 30,10 10,10"
              style={{ fill: 'url(#65B-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("65-Bukal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("65B-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 65B"
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("65-Bukal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("65B-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="65B-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 65B"
              points="0,0 40,0 30,10 10,10"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("65-Bukal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("65B-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 65B"
            points="0,0 40,0 30,10 10,10"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("65-Bukal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      
      </>
      :
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 65B"
        points="0,0 40,0 30,10 10,10"
        style={{ fill: 'white', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("65-Bukal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {/* Bawah */}
    {form.tooch_number.includes("65P-fis") || form.tooch_number.includes("65P-amf") || form.tooch_number.includes("65P-cof") || form.tooch_number.includes("65P-car") ?
      <>
        {form.tooch_number.includes("65P-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="65P-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>

            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 65P"
              points="10,30 30,30 40,40 0,40"
              style={{ fill: 'url(#65P-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("65-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("65P-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 65P"
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("65-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("65P-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="65P-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 65P"
              points="10,30 30,30 40,40 0,40"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("65-Palatal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("65P-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 65P"
            points="10,30 30,30 40,40 0,40"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("65-Palatal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }
      </>
      :
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 65P"
        points="10,30 30,30 40,40 0,40"
        style={{ fill: 'white', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("65-Palatal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {/* Kanan */}
    {form.tooch_number.includes("65D-fis") || form.tooch_number.includes("65D-amf") || form.tooch_number.includes("65D-cof") || form.tooch_number.includes("65D-car") ?
    <>
      {form.tooch_number.includes("65D-fis") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="65D-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
              <rect width="8" height="8" fill="#ffb8c6" />
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 65D"
            points="30,10 40,0 40,40 30,30"
            style={{ fill: 'url(#65D-fis)', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("65-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("65D-amf") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 65D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'black', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("65-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

      {form.tooch_number.includes("65D-cof") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="65D-cof" width="2" height="4" patternUnits="userSpaceOnUse">
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 65D"
            points="30,10 40,0 40,40 30,30"
            style={{ fill: '#007610', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("65-Distal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("65D-car") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 65D"
          points="30,10 40,0 40,40 30,30"
          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
          onMouseEnter={() => setInfoGigi("65-Distal")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

    </>
    :
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 65D"
      points="30,10 40,0 40,40 30,30"
      style={{ fill: 'white', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("65-Distal")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
    }

    {/* Kiri */}
    {form.tooch_number.includes("65M-fis") || form.tooch_number.includes("65M-amf") || form.tooch_number.includes("65M-cof") || form.tooch_number.includes("65M-car") ?
    <>
      {form.tooch_number.includes("65M-fis") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="65M-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
              <rect width="8" height="8" fill="#ffb8c6" />
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 65M"
            points="0,0 10,10 10,30 0,40"
            style={{ fill: 'url(#65M-fis)', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("65-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("65M-amf") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 65M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'black', stroke: 'black' }}
          onMouseEnter={() => setInfoGigi("65-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

      {form.tooch_number.includes("65M-cof") &&
        <svg width="40" height="40">
          <defs>
            <pattern id="65M-cof" width="2" height="4" patternUnits="userSpaceOnUse">
              <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 65M"
            points="0,0 10,10 10,30 0,40"
            style={{ fill: '#007610', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("65-Mesial")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        </svg>
      }

      {form.tooch_number.includes("65M-car") &&
        <polygon
          onClick={onclikTooch}
          data-nomor-gigi=" 65M"
          points="0,0 10,10 10,30 0,40"
          style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
          onMouseEnter={() => setInfoGigi("65-Mesial")}
          onMouseLeave={() => setInfoGigi("XX-X")}
        />
      }

    </>
    :
    <polygon
      onClick={onclikTooch}
      data-nomor-gigi=" 65M"
      points="0,0 10,10 10,30 0,40"
      style={{ fill: 'white', stroke: 'black' }}
      onMouseEnter={() => setInfoGigi("65-Mesial")}
      onMouseLeave={() => setInfoGigi("XX-X")}
    />
    }

    {/* center */}
    {form.tooch_number.includes("65O-fis") || form.tooch_number.includes("65O-amf") || form.tooch_number.includes("65O-cof") || form.tooch_number.includes("65O-car") ?
      <>
        {form.tooch_number.includes("65O-fis") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="65O-fis" width="2" height="4" background-color={"black"} patternUnits="userSpaceOnUse" patternTransform="rotate(45)" style={{ backgroundColor: "black" }}>
                <rect width="8" height="8" fill="#ffb8c6" />
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 65O"
              points="10,10 30,10 30,30 10,30"
              style={{ fill: 'url(#65O-fis)', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("65-Oklusal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("65O-amf") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 65O"
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'black', stroke: 'black' }}
            onMouseEnter={() => setInfoGigi("65-Oklusal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

        {form.tooch_number.includes("65O-cof") &&
          <svg width="40" height="40">
            <defs>
              <pattern id="65O-cof" width="2" height="4" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="4" style={{ stroke: 'black', strokeWidth: 1 }} />
              </pattern>
            </defs>
            <polygon
              onClick={onclikTooch}
              data-nomor-gigi=" 65O"
              points="10,10 30,10 30,30 10,30"
              style={{ fill: '#007610', stroke: 'black' }}
              onMouseEnter={() => setInfoGigi("65-Oklusal")}
              onMouseLeave={() => setInfoGigi("XX-X")}
            />
          </svg>
        }

        {form.tooch_number.includes("65O-car") &&
          <polygon
            onClick={onclikTooch}
            data-nomor-gigi=" 65O"
            points="10,10 30,10 30,30 10,30"
            style={{ fill: 'white', stroke: 'black', strokeWidth: 5 }}
            onMouseEnter={() => setInfoGigi("65-Oklusal")}
            onMouseLeave={() => setInfoGigi("XX-X")}
          />
        }

      </>
      :
      <polygon
        onClick={onclikTooch}
        data-nomor-gigi=" 65O"
        points="10,10 30,10 30,30 10,30"
        style={{ fill: 'white', stroke: 'black' }}
        onMouseEnter={() => setInfoGigi("65-Oklusal")}
        onMouseLeave={() => setInfoGigi("XX-X")}
      />
    }

    {/* cfr */}
    {form.tooch_number.includes("65-cfr") &&
      <text x="50%" y="65" textAnchor="middle" dominantBaseline="middle" fontSize="45" fill="green" fontFamily="arial">
        #
      </text>
    }

    {form.tooch_number.includes("65-rrx") &&    
    <text x="50%" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="86" fill="black"
                  fontFamily="calibri" fontWeight="100" data-nomor-gigi=" 65-rrx" onClick={onclikTooch} style={{ cursor: "pointer" }}>
      v
    </text>
    }

    {form.tooch_number.includes("65-mis") &&    
    <>  
      <line x1="0" y1="0" x2="40" y2="40" style={{ stroke: 'black', strokeWidth: 3.5 }} />
      <line x1="0" y1="40" x2="40" y2="0" style={{ stroke: 'black', strokeWidth: 3.5 }} />
    </> 
    }
    </g>
    }      
    </svg>

    {form.tooch_number.includes("65-nvt") &&   
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
    <svg width={42} height={42}>
    <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 65 "
    points="21,2 41,32 1,32" 
    style={{ fill: 'white', stroke: 'black', strokeWidth: 3 }}
    onMouseEnter={() => setInfoGigi('65')}
    onMouseLeave={() => setInfoGigi('XX-X')}
    />
    </svg> 
    </div>
    }  

    {form.tooch_number.includes("65-rct") &&   
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
    <svg width={42} height={42}>
    <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 65 "
    points="21,2 41,32 1,32" 
    style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
    onMouseEnter={() => setInfoGigi('65')}
    onMouseLeave={() => setInfoGigi('XX-X')}
    />
    </svg>
    </div>
    } 

    {form.tooch_number.includes("65B-amf-rct") || form.tooch_number.includes("65P-amf-rct") || form.tooch_number.includes("65M-amf-rct") || form.tooch_number.includes("65D-amf-rct") || form.tooch_number.includes("65O-amf-rct") ||
    form.tooch_number.includes("65B-cof-rct") || form.tooch_number.includes("65P-cof-rct") || form.tooch_number.includes("65M-cof-rct") || form.tooch_number.includes("65D-cof-rct") || form.tooch_number.includes("65O-cof-rct") ||
    form.tooch_number.includes("65-fmc-rct") ||
    form.tooch_number.includes("65-poc-rct") ?
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"25px", zoom:"50%", transform:"rotate(180deg)"}}>
    <svg width={42} height={42}>
    <polygon
    onClick={onclikTooch}
    data-nomor-gigi=" 65B"
    points="21,2 41,32 1,32" 
    style={{ fill: 'black', stroke: 'black', strokeWidth: 3, backgroundColor:"black" }}
    onMouseEnter={() => setInfoGigi('65-Bukal')}
    onMouseLeave={() => setInfoGigi('XX-X')}
    />
    </svg>
    </div>
    :
    <></>
    } 

    </div>

      </div>
  );
}
