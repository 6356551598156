import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaFacebook, FaGoogle, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
// import List from './Component/Home'
import { useLocation } from 'react-router-dom';
// import masgan from "../../assets/admin-logo.png";

function OffcanvasExample() {
  let navigate = useNavigate();
  const username = localStorage.getItem("username")

  const logout = (e)=>{
    e.preventDefault();
    localStorage.clear();
    navigate("/admin");
  };
  const location = useLocation();
  

  return (
    <div bgColor='light' className='text-center text-lg-start text-muted' style={{backgroundColor:"white", borderTop:"1px solid #DEDEDE"}}>
        <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
          <div className='me-5 d-none d-lg-block' style={{fontSize:"15px"}}>
            <span>Get connected with us on social networks:</span>
          </div>

          <div>
            <a href='#' className='me-4 text-reset'>
              <FaFacebook/>
            </a>
            <a href='#' className='me-4 text-reset'>
              <FaTwitter/>
            </a>
            <a href='#' className='me-4 text-reset'>
              <FaGoogle/>
            </a>
            <a href='#' className='me-4 text-reset'>
              <FaInstagram/>
            </a>
            <a href='#' className='me-4 text-reset'>
              <FaLinkedin/>
            </a>
           
          </div>
        </section>

        <section className='text-center text-md-start mt-5'>
            <Row className='mt-3'>
              <Col md='3' lg='4' xl='3' className='mx-auto mb-4' style={{display:"flex", justifyContent:"center"}}>    
              <div>
                <h4>
                    SIGNATURE  
                  </h4>
                  <p>
                    Welcome to dental clinic PT. Signature Anugerah Sentosa, We are ready to serve your needs.                  </p>
                </div>
              </Col>

              <Col md='2' lg='2' xl='2' className='mx-auto mb-4' style={{textAlign:"center"}}>
                <h6 className='text-uppercase fw-bold mb-4'>Products</h6>
                <p>
                  <a href='#!' className='text-reset' style={{textDecoration:"none"}}>
                    Node
                  </a>
                </p>
                <p>
                  <a href='#!' className='text-reset' style={{textDecoration:"none"}}>
                    React
                  </a>
                </p>
                <p>
                  <a href='#!' className='text-reset' style={{textDecoration:"none"}}>
                    Vue
                  </a>
                </p>
                <p>
                  <a href='#!' className='text-reset' style={{textDecoration:"none"}}>
                    Golang
                  </a>
                </p>
              </Col>

              <Col md='3' lg='2' xl='2' className='mx-auto mb-4' style={{textAlign:"center"}}>
                <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
                <p>
                  <a href='#!' className='text-reset' style={{textDecoration:"none"}}>
                    Pricing
                  </a>
                </p>
                <p>
                  <a href='#!' className='text-reset' style={{textDecoration:"none"}}>
                    Settings
                  </a>
                </p>
                <p>
                  <a href='#!' className='text-reset' style={{textDecoration:"none"}}>
                    Orders
                  </a>
                </p>
                <p>
                  <a href='#!' className='text-reset' style={{textDecoration:"none"}}>
                    Help
                  </a>
                </p>
              </Col>

              <Col md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
                <p>
                Tangerang, Indonesia
                </p>
                <p>
                  signaturedentalcare@gmail.co.id
                </p>
                <p>
                  +62 812 8475 9191
                </p>
              
              </Col>
            </Row>
        </section>

        <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
          © 2021 Copyright
        </div>
      </div> 
  );
}

export default OffcanvasExample;